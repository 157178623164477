<template>
    <div class="flex flex-col prose-summary"
         :class="{ 'mt-12 md:mt-32': participant.status === 'REGISTERED_COMPLETE' }"
    >
        <div v-if="participant.status !== 'REGISTERED_COMPLETE'"
             class="flex items-center justify-between mt-2 mb-6 md:mt-8 md:mb-20 print:hidden"
        >
            <div class="flex items-center cursor-pointer w-fit" @click="previousStep">
                <Icon name="chevron-left" class="w-3 h-3 text-black-300 stroke-current" />
                <span class="text-black-600 text-para-xs ml-4">{{ labels.backTo }} {{ labels.travelDetails }}</span>
            </div>
            <div class="hidden md:flex items-center group cursor-pointer" @click="toOverview">
                <span class="text-para-s font-semibold">{{ labels.personalOverview }}</span>
                <ArrowButton direction="person" class="w-10 h-10" />
            </div>
        </div>
        <h2 class="text-heading-2 md:text-heading-1">{{ labels.summary }}</h2>
        <p class="text-para-s md:text-heading-3 mt-4 md:mt-2 mb-10 md:mb-14" v-html="labels.summaryDescription" />
        <!-- personal data -->
        <span class="font-semibold text-para-lg">{{ labels.personalData }}</span>
        <div class="flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2">
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.firstName }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.firstName) }}</span>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.lastName }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.lastName) }}</span>
            </div>
            <div v-if="participant.title" class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.namePrefix }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.title) }}</span>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.gender }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(getGender(participant.gender)) }}</span>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.company }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.company) }}</span>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.mobilePhone }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.phone) }}</span>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.mailAddress }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.mail) }}</span>
            </div>
            <div v-if="participant.paMail" class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.paMail }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.paMail) }}</span>
            </div>
        </div>
        <!-- bookings -->
        <span class="font-semibold text-para-lg mt-16">{{ labels.yourBookings }}</span>
        <div class="flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2">
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.participation }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(bookings) }}</span>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.accommodationCost }}:</span>
                <span class="font-semibold ml-5 md:ml-0">
                    {{ isAccommodationCostCoverage ? labels.yes : labels.no }}
                </span>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.dietaryRestrictions }}:</span>
                <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(dietaryRestrictions) }}</span>
            </div>
            <div v-if="participant.dietaryRestrictionsOther"
                 class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
            >
                <span class="bullet">{{ labels.other }}:</span>
                <span class="font-semibold ml-5 md:ml-0">
                    {{ valueOrFallback(participant.dietaryRestrictionsOther) }}
                </span>
            </div>
        </div>
        <!-- golf tournament -->
        <template v-if="participant.golfTournament">
            <span class="font-semibold text-para-lg mt-16">{{ labels.golfTournament }}</span>
            <div class="flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2">
                <div v-if="participant.golfHandicap"
                     class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
                >
                    <span class="bullet">{{ labels.handicap }}:</span>
                    <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.golfHandicap) }}</span>
                </div>
                <div v-if="participant.golfClubMembership"
                     class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
                >
                    <span class="bullet">{{ labels.clubMember }}:</span>
                    <span class="font-semibold ml-5 md:ml-0">
                        {{ valueOrFallback(participant.golfClubMembership) }}
                    </span>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                    <span class="bullet">{{ labels.clubRental }}:</span>
                    <span class="font-semibold ml-5 md:ml-0">
                        {{ valueOrFallback(getRental(participant.golfClubsRental)) }}
                    </span>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                    <span class="bullet">{{ labels.clothingSize }}:</span>
                    <span class="font-semibold ml-5 md:ml-0">
                        {{ valueOrFallback(getSize(participant.clothingSize)) }}
                    </span>
                </div>
            </div>
        </template>
        <!-- bike tour -->
        <template v-if="participant.bikeTour">
            <span class="font-semibold text-para-lg mt-16">{{ labels.bikeTour }}</span>
            <div class="flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2">
                <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                    <span class="bullet">{{ labels.skillLevel }}:</span>
                    <span class="font-semibold ml-5 md:ml-0">
                        {{ valueOrFallback(getSkillLevel(participant.bikeSkillLevel)) }}
                    </span>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                    <span class="bullet">{{ labels.height }}:</span>
                    <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.height) }}</span>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                    <span class="bullet">{{ labels.weight }}:</span>
                    <span class="font-semibold ml-5 md:ml-0">{{ valueOrFallback(participant.weight) }}</span>
                </div>
            </div>
        </template>
        <!-- travel details -->
        <span class="font-semibold text-para-lg mt-16">{{ labels.travelDetails }}</span>
        <div class="flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2">
            <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                <span class="bullet">{{ labels.meansOFTravel }}:</span>
                <span class="font-semibold ml-5 md:ml-0">
                    {{ valueOrFallback(getMeansOfTravel(participant.meansOfTravel)) }}
                </span>
            </div>
            <template v-if="participant.meansOfTravel === 'TRAIN' || participant.meansOfTravel === 'AIRPLANE'">
                <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                    <span class="bullet">
                        {{ participant.meansOfTravel === 'TRAIN' ? labels.transportationNeeded : labels.shuttleNeeded }}:
                    </span>
                    <span class="font-semibold ml-5 md:ml-0">{{ isShuttleNeeded ? labels.yes : labels.no }}</span>
                </div>
                <template v-if="isShuttleNeeded">
                    <div v-if="participant.meansOfTravel === 'AIRPLANE'"
                         class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
                    >
                        <span class="bullet">{{ labels.arrivalFlightNo }}:</span>
                        <span class="font-semibold ml-5 md:ml-0">
                            {{ valueOrFallback(participant.arrivalFlightNo) }}
                        </span>
                    </div>
                    <div v-if="participant.meansOfTravel === 'AIRPLANE'"
                         class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
                    >
                        <span class="bullet">{{ labels.arrivalFlightFrom }}:</span>
                        <span class="font-semibold ml-5 md:ml-0">
                            {{ valueOrFallback(participant.arrivalFlightFrom) }}
                        </span>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                        <span class="bullet">{{ labels.arrivalDate }}:</span>
                        <span class="font-semibold ml-5 md:ml-0">{{ getSwissDate(participant.arrivalTime) }}</span>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                        <span class="bullet">{{ labels.arrivalTime }}:</span>
                        <span class="font-semibold ml-5 md:ml-0">{{ getSwissTime(participant.arrivalTime) }}</span>
                    </div>
                    <div v-if="participant.meansOfTravel === 'AIRPLANE'"
                         class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                        <span class="bullet">{{ labels.departureFlightNo }}:</span>
                        <span class="font-semibold ml-5 md:ml-0">
                            {{ valueOrFallback(participant.departureFlightNo) }}
                        </span>
                    </div>
                    <div v-if="participant.meansOfTravel === 'AIRPLANE'"
                         class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                        <span class="bullet">{{ labels.departureFlightTo }}:</span>
                        <span class="font-semibold ml-5 md:ml-0">
                            {{ valueOrFallback(participant.departureFlightTo) }}
                        </span>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                        <span class="bullet">{{ labels.departureDate }}:</span>
                        <span class="font-semibold ml-5 md:ml-0">{{ getSwissDate(participant.departureTime) }}</span>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14">
                        <span class="bullet">{{ labels.departureTime }}:</span>
                        <span class="font-semibold ml-5 md:ml-0">{{ getSwissTime(participant.departureTime) }}</span>
                    </div>
                </template>
            </template>
        </div>
        <!-- confirmation -->
        <Form v-if="participant.status !== 'REGISTERED_COMPLETE'"
              v-slot="{ handleSubmit }"
              class="flex flex-col print:hidden"
              as="div"
        >
            <span class="font-semibold text-para-lg mt-16">{{ labels.bindingConfirmation }}</span>
            <VueFormCheckbox name="confirmation" required class="mt-4"
                             text-classes="text-para-s md:text-para-base pl-6 md:pl-7"
                             v-model="confirmation"
            >
                <span v-html="labels.bindingConfirmationOption1 + '*'" />
            </VueFormCheckbox>
            <VueFormCheckbox name="policyConfirmation" required class="mt-4"
                             text-classes="text-para-s md:text-para-base pl-6 md:pl-7"
                             v-model="policyConfirmation">
                <span v-html="labels.bindingConfirmationOption2 + '*'" />
            </VueFormCheckbox>
            <div class="text-para-s md:text-para-lg mt-10">
                <div>{{ labels.requiredHint }}</div>
                <div class="font-semibold mt-10">{{ labels.bindingConfirmationHint }}:</div>
                <div class="mt-2">{{ labels.mail }}: {{ event.paMail }}</div>
                <div>{{ labels.phone }}: {{ event.paPhone }}</div>
                <div>{{ labels.mobile }}: {{ event.paMobile }}</div>
            </div>
            <div class="flex flex-col gap-8 md:flex-row md:justify-between md:items-center mt-10">
                <button class="flex items-center order-2 md:order-1 group"
                        @click="print"
                >
                    <span class="text-para-s font-semibold">{{ labels.printPage }}</span>
                    <ArrowButton class="w-10 h-10 flex-shrink-0" show-ring-on-mobile direction="print" />
                </button>
                <GcButton v-if="!!participant.meansOfTravel && participant.meansOfTravel !== 'UNKNOWN'"
                          class="order-1 md:order-2" :label="labels.bookBindingly"
                          @click="handleSubmit(confirmBooking)" />
                <span v-else class="order-1 md:order-2 text-error text-para-xs">
                    {{ labels.noTravelData }}
                </span>
            </div>
            <div class="md:hidden border-t border-black-200 mt-12">
                <button type="button"
                        class="flex items-center group cursor-pointer mt-6"
                        @click="toOverview"
                >
                    <span class="text-para-s font-semibold">{{ labels.personalOverview }}</span>
                    <ArrowButton direction="person" class="w-10 h-10" show-ring-on-mobile />
                </button>
            </div>
        </Form>
        <div v-else>
            <div class="text-para-s md:text-para-lg mt-16">
                <div class="font-semibold">{{ labels.bookedHint }}:</div>
                <div class="mt-2">{{ labels.mail }}: {{ event.paMail }}</div>
                <div>{{ labels.phone }}: {{ event.paPhone }}</div>
                <div>{{ labels.mobile }}: {{ event.paMobile }}</div>
            </div>
            <button class="flex items-center group mt-10 print:hidden"
                    @click="print"
            >
                <span class="text-para-s font-semibold">{{ labels.printPage }}</span>
                <ArrowButton class="w-10 h-10 flex-shrink-0" show-ring-on-mobile direction="print" />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {GolfEventLabels} from './GolfEventTypes';
import VueFormCheckbox from "../form/VueFormCheckbox.vue";
import Icon from "../base/Icon.vue";
import ArrowButton from "../base/ArrowButton.vue";
import GcButton from "../base/GcButton.vue";
import {Form} from "vee-validate";

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {Form, GcButton, ArrowButton, Icon, VueFormCheckbox},
    inject: ['participant', 'event', 'previousStep', 'confirmBooking', 'toOverview'],
    props: {
        labels: {type: Object as PropType<GolfEventLabels>, required: true}
    },
    data() {
        return {
            confirmation: false,
            policyConfirmation: false,
            swissDate: new Intl.DateTimeFormat(undefined, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                timeZone: 'Europe/Zurich'
            }),
            swissTime: new Intl.DateTimeFormat(undefined, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
                timeZone: 'Europe/Zurich'
            })
        };
    },
    computed: {
        isShuttleNeeded(): boolean {
            if (typeof this.participant.shuttleNeeded === 'boolean') {
                return this.participant.shuttleNeeded;
            }
            return this.participant.shuttleNeeded === 'true';
        },
        isAccommodationCostCoverage(): boolean {
            if (typeof this.participant.accommodationCostCoverage === 'boolean') {
                return this.participant.accommodationCostCoverage;
            }
            return this.participant.accommodationCostCoverage === 'true';
        },
        bookings(): string {
            const bookings: string[] = [];
            if (this.participant.dinnerDay1) {
                bookings.push(this.labels.dinnerWednesday);
            }
            if (this.participant.dinnerDay2) {
                bookings.push(this.labels.dinnerThursday);
            }
            if (this.participant.golfTournament) {
                bookings.push(this.labels.golfTournament);
            }
            if (this.participant.bikeTour) {
                bookings.push(this.labels.bikeTour);
            }
            return bookings.join(', ');
        },
        dietaryRestrictions(): string {
            const restrictions: string[] = [];
            if (this.participant.dietaryRestrictions.includes('VEGETARIAN')) {
                restrictions.push(this.labels.vegetarian);
            }
            if (this.participant.dietaryRestrictions.includes('NO_PORK')) {
                restrictions.push(this.labels.noPork);
            }
            if (this.participant.dietaryRestrictions.includes('NO_SHELLFISH')) {
                restrictions.push(this.labels.noShellfish);
            }
            if (this.participant.dietaryRestrictions.includes('NUT_ALLERGY')) {
                restrictions.push(this.labels.nutsAllergy);
            }
            if (this.participant.dietaryRestrictions.includes('GLUTEN_ALLERGY')) {
                restrictions.push(this.labels.glutenAllergy);
            }
            if (this.participant.dietaryRestrictions.includes('OTHER')) {
                restrictions.push(this.labels.other);
            }
            return restrictions.join(', ');
        }
    },
    mounted(): void {
        this.$el.scrollIntoView({behavior: 'smooth'});
    },
    methods: {
        getGender(gender: string): string {
            switch (gender) {
                case 'MALE':
                    return this.labels.genderMale;
                case 'FEMALE':
                    return this.labels.genderFemale;
                case 'UNKNOWN':
                default:
                    return gender;
            }
        },
        getRental(rental: string): string {
            switch (rental) {
                case 'NO':
                    return this.labels.noRental;
                case 'RIGHT_HANDED':
                    return this.labels.rightHanded;
                case 'RIGHT_HANDED_LONG':
                    return this.labels.rightHandedLong;
                case 'LEFT_HANDED':
                    return this.labels.leftHanded;
                case 'LEFT_HANDED_LONG':
                    return this.labels.leftHandedLong;
                default:
                    return rental;
            }
        },
        getSize(size: string): string {
            switch (size) {
                case 'S':
                    return this.labels.s;
                case 'M':
                    return this.labels.m;
                case 'L':
                    return this.labels.l;
                case 'XL':
                    return this.labels.xl;
                case 'XXL':
                    return this.labels.xxl;
                default:
                    return size;
            }
        },
        getSkillLevel(level: string): string {
            switch (level) {
                case 'BEGINNER':
                    return this.labels.beginner;
                case 'INTERMEDIATE':
                    return this.labels.intermediate;
                default:
                    return level;
            }
        },
        getMeansOfTravel(means: string): string {
            switch (means) {
                case 'UNKNOWN':
                    return this.labels.unknown;
                case 'CAR':
                    return this.labels.car;
                case 'TRAIN':
                    return this.labels.train;
                case 'AIRPLANE':
                    return this.labels.airplane;
                default:
                    return means;
            }
        },
        getSwissDate(time: number): string {
            if (!time) return '-';
            const date = new Date(time);
            return this.swissDate.format(date);
        },
        getSwissTime(time: number): string {
            if (!time) return '-';
            const date = new Date(time);
            return this.swissTime.format(date);
        },
        valueOrFallback(value: any): any {
            if (typeof value === 'undefined' || value === '') {
                return '-';
            }
            return value;
        },
        print(): void {
            window.print();
        }
    }
};
</script>
