import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocioEconomicMapStaticFeature = _resolveComponent("SocioEconomicMapStaticFeature")!
  const _component_custom_marker = _resolveComponent("custom-marker")!
  const _component_marker_cluster = _resolveComponent("marker-cluster")!
  const _component_SocioEconomicMapZoomButtons = _resolveComponent("SocioEconomicMapZoomButtons")!
  const _component_google_map = _resolveComponent("google-map")!

  return (_openBlock(), _createBlock(_component_google_map, {
    ref: "mapRef",
    "api-key": $data.apiKeyGM,
    styles: $data.mapStyles,
    center: $data.center,
    zoom: $data.zoom,
    "min-zoom": $data.minZoom,
    "max-zoom": $data.maxZoom,
    "disable-default-ui": true,
    "street-view-control": false,
    "fullscreen-control": false,
    "map-type-control": false,
    class: "w-full h-full",
    style: {"width":"100%","height":"100%"},
    onIdle: $options.onMapIdle
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.countryConfig.features, (feature, i) => {
        return (_openBlock(), _createBlock(_component_SocioEconomicMapStaticFeature, {
          key: i,
          feature: feature,
          visible: $options.showStaticFeatures
        }, null, 8, ["feature", "visible"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.operations, (operation, i) => {
        return (_openBlock(), _createBlock(_component_marker_cluster, {
          key: i,
          options: {
                algorithm: $options.clusterAlgorithm(),
                renderer: $data.clusterRenderer,
                onClusterClick: $options.onClusterClick,
            }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getLocationsForOperation(operation), (marker, index) => {
              return (_openBlock(), _createBlock(_component_custom_marker, {
                key: index,
                options: {
                    position: marker.position,
                    offsetY: -40,
                },
                clickable: true,
                label: {
                    color: 'black',
                    fontFamily:
                        'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
                    fontSize: '12px',
                    fontWeight: '100',
                    text: ' ',
                },
                onClick: ($event: any) => ($options.onMarkerClicked(marker, operation))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "relative bg-white px-4 py-2 flex items-center justify-center border rounded-full text-para-xs",
                    style: _normalizeStyle($options.getMarkerStyle(marker, operation))
                  }, [
                    _createTextVNode(_toDisplayString(marker.title || marker.name || "") + " ", 1),
                    _createElementVNode("div", {
                      class: "absolute border-1 w-0.5 top-full h-10",
                      style: _normalizeStyle({
                            backgroundColor: $options.getColor(operation.color),
                            borderColor: $options.getColor(operation.color),
                            left: 'calc(50% - 2px)',
                        })
                    }, null, 4)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["options", "onClick"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["options"]))
      }), 128)),
      _createVNode(_component_SocioEconomicMapZoomButtons, {
        onZoomIn: $options.zoomIn,
        onZoomOut: $options.zoomOut
      }, null, 8, ["onZoomIn", "onZoomOut"])
    ]),
    _: 1
  }, 8, ["api-key", "styles", "center", "zoom", "min-zoom", "max-zoom", "onIdle"]))
}