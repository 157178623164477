<template>
    <Field v-slot="{ field, errors }"
           v-model="model[name]"
           class="flex flex-col items-center border border-black-200 rounded p-4"
           :rules="rules"
           :name="title"
           as="div"
           mode="eager"
           :disabled="validationDisabled"
    >
        <NumberInput class="appearance-none text-para-lg font-semibold focus:outline-none w-full text-center"
                     :class="{'text-black-600': model[name] === 0}"
                     v-bind="field"
                     v-model="model[name]"
                     :name="name"
                     :aria-required="required"
                     :aria-invalid="errors.length > 0"
                     :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
                     :options="options"
        />
        <span class="text-para-s">
            {{ title }}
        </span>
        <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-4 text-center">
            {{ errors[0] }}
        </span>
    </Field>
</template>

<script lang="ts">
import {CurrencyInputOptions} from 'vue-currency-input';
import NumberInput from '../../base/NumberInput.vue';
import {formattingOptions} from './MultiStepForm.vue';
import {Field} from "vee-validate";

export default {
    components: {Field, NumberInput},
    inject: ['model', 'currentStep'],
    props: {
        title: {type: String, required: true},
        name: {type: String, required: true},
        default: {type: Number, default: 0},
        min: {type: Number},
        max: {type: Number},
        required: {type: Boolean, default: false},
        stepIndex: {type: Number, required: true}
    },
    computed: {
        validationDisabled(): boolean {
            return this.currentStep !== this.stepIndex;
        },
        options(): CurrencyInputOptions {
            return formattingOptions;
        },
        rules(): Record<string, unknown> {
            /* eslint-disable */
            const rules = {
                is_number: true,
                required: this.required
            };
            if (typeof this.min !== 'undefined') {
                rules['min_value'] = this.min;
            }
            if (typeof this.max !== 'undefined') {
                rules['max_value'] = this.max;
            }
            /* eslint-enable */
            return rules;
        }
    },
    created(): void {
        if (!this.model[this.name]) {
            this.model[this.name] = this.default;
        }
    }
};
</script>
