import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "fixed left-3 md:left-auto right-3 md:right-4 bottom-3 md:bottom-5 md:w-80 max-h-policy-m md:max-h-policy rounded bg-white shadow-lg px-6 py-8 overflow-y-auto z-40" }
const _hoisted_2 = { class: "font-semibold mb-2" }
const _hoisted_3 = { class: "text-para-s mb-3" }
const _hoisted_4 = {
  key: 2,
  class: "prose"
}
const _hoisted_5 = ["aria-label", "href"]
const _hoisted_6 = {
  key: 3,
  class: "text-para-s mb-4"
}
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { class: "text-para-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_CheckboxAccordion = _resolveComponent("CheckboxAccordion")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        (!$options.advanced)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "absolute right-2 top-2 w-4 h-4 cursor-pointer",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.close && $options.close(...args)))
            }, [
              _createVNode(_component_Icon, {
                name: "close",
                class: "w-full h-full stroke-current"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString($props.text), 1),
        ($options.simple && !!$props.link.url)
          ? (_openBlock(), _createBlock(_component_GcButton, {
              key: 1,
              class: "w-full",
              label: $props.link.label,
              "aria-label": $props.link.ariaLabel,
              url: $props.link.url
            }, null, 8, ["label", "aria-label", "url"]))
          : _createCommentVNode("", true),
        ($options.subtle && !!$props.link.url)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                class: "link text-para-s",
                "aria-label": $props.link.ariaLabel,
                href: $props.link.url
              }, _toDisplayString($props.link.label), 9, _hoisted_5)
            ]))
          : _createCommentVNode("", true),
        ($data.opened)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($props.advText), 1))
          : _createCommentVNode("", true),
        ($data.opened && $options.advanced)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
                return (_openBlock(), _createBlock(_component_CheckboxAccordion, {
                  id: option.type,
                  key: option.type,
                  modelValue: $data.model[option.type],
                  "onUpdate:modelValue": ($event: any) => (($data.model[option.type]) = $event),
                  title: option.label,
                  "show-checkbox": option.checkbox,
                  check: $data.grantModel[option.type],
                  small: "",
                  onChange: ($event: any) => ($data.grantModel[option.type] = $event)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(option.text), 1)
                  ]),
                  _: 2
                }, 1032, ["id", "modelValue", "onUpdate:modelValue", "title", "show-checkbox", "check", "onChange"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        ($data.opened)
          ? (_openBlock(), _createBlock(_component_GcButton, {
              key: 5,
              class: "w-full mb-3 mt-1",
              color: "primary",
              secondary: true,
              label: $props.labels.rejectAll,
              onClick: $options.rejectAll
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true),
        ($data.opened)
          ? (_openBlock(), _createBlock(_component_GcButton, {
              key: 6,
              class: "w-full",
              label: $props.labels.confirm,
              onClick: $options.updateConsent
            }, null, 8, ["label", "onClick"]))
          : _createCommentVNode("", true),
        (!$data.opened && $options.advanced)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
              _createVNode(_component_GcButton, {
                class: "w-full mb-3",
                color: "primary",
                secondary: true,
                label: $props.labels.settings,
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.opened = true))
              }, null, 8, ["label"]),
              _createVNode(_component_GcButton, {
                class: "w-full mb-3",
                label: $props.labels.reject,
                onClick: $options.rejectAll
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_GcButton, {
                class: "w-full",
                label: $props.labels.accept,
                onClick: $options.acceptAll
              }, null, 8, ["label", "onClick"])
            ], 64))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, $data.show]
      ])
    ]),
    _: 1
  }))
}