<template>
    <div :id="anchor"
         :class="{
             'border-b': !checkNestedAccordion || !isLast,
             'border-black-900': !checkNestedAccordion,
             'border-black-500': checkNestedAccordion
         }"
    >
        <button class="focus-primary w-full text-left group"
                :aria-label="modelValue ? $globalLabels.close : $globalLabels.open"
                @click="toggle"
        >
            <span class="flex justify-between items-center py-3 md:py-5">
                <span class="transition-colors text-para-s md:text-para-base group-hover:text-primary-500 group-focus:text-primary-500"
                      :class="{ 'font-semibold': !checkNestedAccordion }"
                >
                    {{ title }}
                </span>
                <ArrowButton direction="down"
                             class="flex-shrink-0 w-10 h-10 transition-transform transform origin-center stroke-current mx-4"
                             :class="{ 'rotate-180': modelValue, 'text-black-500': checkNestedAccordion }"
                />
            </span>
        </button>
        <CollapseTransition v-show="modelValue">
            <div>
                <slot />
            </div>
        </CollapseTransition>
    </div>
</template>

<script lang="ts">

import ArrowButton from "./ArrowButton.vue";
import CollapseTransition from "./CollapseTransition.vue";

export default {
    components: {CollapseTransition, ArrowButton},
    inject: ['checkNestedAccordion', '$globalLabels'],
    props: {
        title: {type: String},
        modelValue: {type: Boolean},
        anchor: {type: String},
        isLast: {type: Boolean, default: false}
    },
    emits: ['update:modelValue'],
    methods: {
        setDeeplink(value: string): void {
            if (value) {
                if (window.history.pushState) {
                    window.history.pushState(null, null, `#${value}`);
                } else {
                    window.location.hash = `#${value}`;
                }
            }
        },
        toggle() {
            if (!this.modelValue) {
                this.setDeeplink(this.anchor);
            }

            this.$emit('update:modelValue', !this.modelValue);
        }
    }
};
</script>
