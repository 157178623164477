import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, vShow as _vShow, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([$props.textClasses, "cursor-pointer text-black-900 px-4 py-3 md:py-2.5 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white"]),
    tabindex: "0",
    role: "option",
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.selectValue($props.value))),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => ($options.selectValue($props.value)), ["enter"]))
  }, [
    _createTextVNode(_toDisplayString($props.showLabel ? $props.label : '') + " ", 1),
    _renderSlot(_ctx.$slots, "default")
  ], 34)), [
    [_vShow, $data.shouldRender]
  ])
}