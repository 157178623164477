<template>
    <GcSwiper v-if="render"
              v-cloak class="mb-9"
              :slide-classes="slideClasses"
              :container-classes="containerClasses"
              :gap="{ default: 4, lg: 8 }"
    >
        <GcSwiperSlide v-for="(image, index) in slides" :key="index">
            <img class="w-full object-cover object-center"
                 :srcset="(image.sourceSet || '')"
                 :sizes="(image.sizes || '')"
                 loading="lazy"
            >
        </GcSwiperSlide>
        <template #pagination="{ index, total, teaser, go }">
            <ul v-if="total > 1"
                class="flex flex-row justify-center space-x-3 text-black mt-4 space-x-4 px-4 md:px-10 xl:px-0">
                <li v-for="(dot, i) in total"
                    :key="i"
                    class="relative w-2 h-2 rounded cursor-pointer"
                    :class="[index === (i + 1) ? `bg-${pagerColor} after:content after:w-4 after:h-4 after:rounded-full after:absolute after:-top-1 after:-left-1 after:border after:border-${pagerColor}` : `bg-${pagerColor}`]"
                    @click="go(i)"
                />
            </ul>
        </template>
    </GcSwiper>
</template>

<script lang="ts">

import GcSwiperSlide from "../base/GcSwiperSlide.vue";
import GcSwiper from "../base/GcSwiper.vue";
import {nextTick} from "vue";

export default {
    components: {GcSwiper, GcSwiperSlide},
    props: {
        slides: {type: Array, default: () => ([])},
        slideClasses: {type: String, default: 'w-2/3'},
        containerClasses: {type: String},
        // tailwind comment to prevent purge: after:border-white bg-white
        pagerColor: {type: String, default: 'white'}
    },
    data() {
        return {
            render: false
        };
    },
    watch: {
        /** Hack: We want rerender when new slides arrive **/
        slides() {
            this.render = false;
            nextTick(() => {
                this.render = true;
            });
        }
    },
    mounted(): void {
        this.render = true;
    }
};
</script>
