<template>
    <div v-show="visible"
         class="fixed bottom-4 md:bottom-12 right-4 md:right-20 flex justify-center items-center w-12 md:w-16 h-12 md:h-16 rounded bg-black-900 cursor-pointer"
         @click="scrollToTop"
    >
        <Icon name="chevron-up" class="w-4 h-4 stroke-current text-white" />
    </div>
</template>

<script lang="ts">

import Icon from "./Icon.vue";

export default {
    components: {Icon},
    props: {
        threshold: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            contentHeight: 0,
            viewportHeight: 0,
            scrollingToTop: false,
            /*
             * the higher the negative delta (= the lower the number) the faster the user needs to scroll to the top
             * to trigger the back button (if height threshold is reached)
             */
            MAX_NEGATIVE_DELTA: -5
        };
    },
    computed: {
        visible(): boolean {
            return this.thresholdReached && this.scrollingToTop;
        },
        thresholdReached(): boolean {
            return this.contentHeight > (this.threshold * this.viewportHeight);
        }

    },
    mounted(): void {
        this.contentHeight = document.body.clientHeight;
        this.viewportHeight = window.innerHeight;

        if (this.thresholdReached) {
            this.addScrollDetection();
        }
    },
    methods: {
        scrollToTop(): void {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        },
        addScrollDetection() {
            const checkScrollSpeed = ((_delay) => {
                let lastPos, newPos, timer, delta;
                const delay = _delay || 50;

                function clear() {
                    lastPos = null;
                    delta = 0;
                }

                clear();

                return () => {
                    newPos = window.scrollY;

                    if (lastPos != null) {
                        delta = newPos - lastPos;
                    }

                    lastPos = newPos;
                    clearTimeout(timer);
                    timer = setTimeout(clear, delay);
                    return delta;
                };
            })();

            window.onscroll = () => {
                const delta = checkScrollSpeed();
                // don't show back button when scrolling down or when too close to the top
                // after that, only show the button if the delta is reached and don't reset it as long as it's true and
                // the first precondition isn't met
                if (delta > 0 || window.scrollY < (window.innerHeight / 2)) {
                    this.scrollingToTop = false;
                } else if (!this.scrollingToTop) {
                    this.scrollingToTop = delta <= this.MAX_NEGATIVE_DELTA;
                }
            };
        }
    }
};
</script>
