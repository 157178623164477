<template>
    <div class="relative w-full h-full bg-white overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out">
        <div class="min-h-30" :class="[!isVertical ? 'h-60 md:h-auto' : '']" />
        <div class="text-para-s px-6 pt-4 pb-6">
            <h6 v-if="data.title" class="font-semibold">{{ data.title }}</h6>
            <p>{{ data.text }}</p>
        </div>
        <a :href="data.link"
           class="absolute left-0 right-0 top-0 bottom-0"
           :aria-label="data.title"
           target="_blank"
        />
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';

export default {
    inject: ['isVertical'],
    props: {
        data: {type: Object as PropType<Record<string, string>>}
    }
};
</script>
