import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full bg-primary-500 z-30 flex flex-col xl:w-90 xl:h-180 xl:overflow-x-hidden xl:overflow-y-scroll xl:shadow-2xl" }
const _hoisted_2 = {
  key: 0,
  class: "font-semibold text-para-s text-white"
}
const _hoisted_3 = {
  key: 2,
  class: "text-white w-full flex flex-col text-para-base break-word"
}
const _hoisted_4 = {
  key: 0,
  class: "flex flex-grow justify-center items-center mb-4 -mx-10"
}
const _hoisted_5 = {
  key: 1,
  class: "text-para-xl font-semibold"
}
const _hoisted_6 = {
  key: 2,
  class: "font-semibold mb-6"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 4 }
const _hoisted_10 = { key: 5 }
const _hoisted_11 = { class: "w-full break-words mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_SocioEconomicMapDetailSlider = _resolveComponent("SocioEconomicMapDetailSlider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["w-full h-full py-12 px-4 md:py-16 px-4 md:px-10 xl:px-6", { 'pb-60 md:pb-60 xl:pb-16': $data.openState }])
    }, [
      ($props.offices.length > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.labelSelectOffice), 1))
        : _createCommentVNode("", true),
      ($props.offices.length > 1)
        ? (_openBlock(), _createBlock(_component_Dropdown, {
            key: 1,
            modelValue: $data.office,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => (($data.office) = $event)),
              _cache[3] || (_cache[3] = ($event: any) => ($options.selectOffice($data.office)))
            ],
            class: _normalizeClass(["w-full md:w-auto min-w-40", [$props.selectedOffice ? 'mb-6' : 'mb-0']]),
            "text-classes": "text-black-600 text-para-s h-6 truncate",
            "default-value": null,
            title: `${$props.labelSelectOffice}...`,
            white: true,
            onOpen: _cache[1] || (_cache[1] = ($event: any) => ($data.openState = true)),
            onClose: _cache[2] || (_cache[2] = ($event: any) => ($data.openState = false))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.offices, (item, i) => {
                return (_openBlock(), _createBlock(_component_DropdownItem, {
                  key: i,
                  label: item.area,
                  value: item,
                  "text-classes": "text-para-xs md:text-para-s"
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "class", "title"]))
        : _createCommentVNode("", true),
      ($props.selectedOffice)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            ($props.selectedOffice.images && Object.keys($props.selectedOffice.images).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_SocioEconomicMapDetailSlider, {
                    "slide-classes": "w-full",
                    "container-classes": "px-10",
                    slides: $props.selectedOffice.images
                  }, null, 8, ["slides"])
                ]))
              : _createCommentVNode("", true),
            ($props.selectedOffice.title)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString($props.selectedOffice.title), 1))
              : _createCommentVNode("", true),
            ($props.selectedOffice.area)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString($props.selectedOffice.area), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "mb-4",
              innerHTML: $props.selectedOffice.fullAddress
            }, null, 8, _hoisted_7),
            ($props.selectedOffice.phoneNumber)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($props.labelPhone) + ": " + _toDisplayString($props.selectedOffice.phoneNumber), 1))
              : _createCommentVNode("", true),
            ($props.selectedOffice.mobileNumber)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString($props.labelMobile) + ": " + _toDisplayString($props.selectedOffice.mobileNumber), 1))
              : _createCommentVNode("", true),
            ($props.selectedOffice.faxNumber)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString($props.labelFax) + ": " + _toDisplayString($props.selectedOffice.faxNumber), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, _toDisplayString($props.selectedOffice.email), 1)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}