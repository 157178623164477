import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["cursor-pointer", $props.classes]),
    "aria-label": $props.ariaLabel,
    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.click && $options.click(...args)), ["stop"]))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_Overlay, {
      url: $data.targetUrl,
      onClose: _cache[0] || (_cache[0] = ($event: any) => ($data.targetUrl=''))
    }, null, 8, ["url"])
  ], 10, _hoisted_1))
}