<template>
    <a v-if="!overlay" :href="url">
        <slot />
    </a>
    <OverlayLink v-else :url="url">
        <slot />
    </OverlayLink>
</template>

<script lang="ts">

import OverlayLink from "../portalNavigator/OverlayLink.vue";

export default {
    components: {OverlayLink},
    props: {
        url: {type: String},
        overlay: {type: Boolean}
    }
};
</script>
