import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mt-2 mb-6 md:mt-8 md:mb-20" }
const _hoisted_2 = { class: "text-black-600 text-para-xs ml-4" }
const _hoisted_3 = { class: "text-para-s font-semibold" }
const _hoisted_4 = { class: "text-heading-2 md:text-heading-1" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "mt-10 md:mt-14" }
const _hoisted_7 = { class: "font-semibold text-para-xs md:text-para-s" }
const _hoisted_8 = { class: "text-para-xs md:text-para-s text-black-500 font-normal" }
const _hoisted_9 = { class: "grid grid-cols-1 md:grid-cols-2 gap-8 mt-4" }
const _hoisted_10 = { class: "grid grid-cols-1 md:grid-cols-2 gap-8 mt-4" }
const _hoisted_11 = { class: "grid grid-cols-1 md:grid-cols-2 gap-8 mt-4" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "font-semibold text-para-s line-clamp-1" }
const _hoisted_15 = { class: "md:hidden border-t border-black-200 mt-12" }
const _hoisted_16 = { class: "text-para-s font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_VueFormSelection = _resolveComponent("VueFormSelection")!
  const _component_VueFormInput = _resolveComponent("VueFormInput")!
  const _component_ToggleButtons = _resolveComponent("ToggleButtons")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, { as: "div" }, {
    default: _withCtx(({ validate }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          class: "flex items-center cursor-pointer w-fit",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.previousStep && $options.previousStep(...args)))
        }, [
          _createVNode(_component_Icon, {
            name: "chevron-left",
            class: "w-3 h-3 text-black-300 stroke-current"
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString($props.labels.backTo) + " " + _toDisplayString($props.labels.personalData), 1)
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "hidden md:flex items-center group cursor-pointer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.toOverview && $options.toOverview(...args)))
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString($props.labels.personalOverview), 1),
          _createVNode(_component_ArrowButton, {
            direction: "person",
            class: "w-10 h-10"
          })
        ])
      ]),
      _createElementVNode("h2", _hoisted_4, _toDisplayString($props.labels.participation), 1),
      _createElementVNode("p", {
        class: "text-para-s md:text-para-xl mt-4 md:mt-2",
        innerHTML: $props.labels.participationDescription
      }, null, 8, _hoisted_5),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", null, [
          _createElementVNode("span", _hoisted_7, [
            _createTextVNode(_toDisplayString($props.labels.participateInPrefix) + " ", 1),
            _cache[26] || (_cache[26] = _createElementVNode("dfn", { title: "required" }, "*", -1))
          ]),
          _createElementVNode("span", _hoisted_8, _toDisplayString($props.labels.participateInSuffix), 1)
        ]),
        _createVNode(_component_ToggleButtons, {
          modelValue: $options.dinnerModel,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($options.dinnerModel) = $event)),
          items: { dinner1: $props.labels.dinnerWednesday, dinner2: $props.labels.dinnerThursday },
          views: { dinner1: 'dinnerView', dinner2: 'dinnerView' },
          "btn-classes": "min-w-60",
          "multi-select": "",
          "grow-mobile": "",
          "col-mobile": "",
          class: "mt-4"
        }, {
          dinnerView: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_VueFormSelection, {
                modelValue: $options.participant.dietaryRestrictions,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => (($options.participant.dietaryRestrictions) = $event)),
                  _cache[3] || (_cache[3] = ($event: any) => ($options.onFocusChange(true, 'dietaryRestrictions', $options.participant.dietaryRestrictions)))
                ],
                name: "dietaryRestrictions",
                type: "combobox",
                "pill-items": true,
                title: $props.labels.dietaryRestrictions,
                placeholder: $props.labels.pleaseSelect,
                labels: [
                                              $props.labels.vegetarian + ':VEGETARIAN',
                                              $props.labels.noPork + ':NO_PORK',
                                              $props.labels.noShellfish + ':NO_SHELLFISH',
                                              $props.labels.nutsAllergy + ':NUT_ALLERGY',
                                              $props.labels.glutenAllergy + ':GLUTEN_ALLERGY',
                                              $props.labels.other + ':OTHER'
                                          ]
              }, null, 8, ["modelValue", "title", "placeholder", "labels"]),
              _withDirectives(_createVNode(_component_VueFormInput, {
                modelValue: $options.participant.dietaryRestrictionsOther,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($options.participant.dietaryRestrictionsOther) = $event)),
                name: "dietaryOther",
                title: $props.labels.otherPleaseSpecify,
                onBlur: _cache[5] || (_cache[5] = ($event: any) => ($options.onFocusChange($event, 'dietaryRestrictionsOther', $options.participant.dietaryRestrictionsOther)))
              }, null, 8, ["modelValue", "title"]), [
                [_vShow, $options.participant.dietaryRestrictions.indexOf('OTHER') >= 0]
              ]),
              _createVNode(_component_VueFormSelection, {
                modelValue: $options.participant.accommodationCostCoverage,
                "onUpdate:modelValue": [
                  _cache[6] || (_cache[6] = ($event: any) => (($options.participant.accommodationCostCoverage) = $event)),
                  _cache[7] || (_cache[7] = ($event: any) => ($options.onFocusChange(true, 'accommodationCostCoverage', $options.participant.accommodationCostCoverage)))
                ],
                name: "accommodationCostCoverage",
                class: "col-start-1",
                type: "select",
                required: true,
                title: $props.labels.accommodationCost,
                placeholder: $props.labels.pleaseChoose,
                labels: [
                                              $props.labels.yes + ':true',
                                              $props.labels.no + ':false'
                                          ]
              }, null, 8, ["modelValue", "title", "placeholder", "labels"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "items"]),
        _cache[27] || (_cache[27] = _createElementVNode("hr", { class: "w-full my-8 md:my-10" }, null, -1)),
        _createVNode(_component_ToggleButtons, {
          modelValue: $options.golfModel,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($options.golfModel) = $event)),
          items: { golf: $props.labels.golfTournament },
          views: { golf: 'golfView' },
          "btn-classes": "min-w-60",
          "multi-select": "",
          "grow-mobile": "",
          class: "mt-4"
        }, {
          golfView: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_VueFormInput, {
                modelValue: $options.participant.golfHandicap,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($options.participant.golfHandicap) = $event)),
                name: "golfHandicap",
                title: $props.labels.handicap,
                onBlur: _cache[10] || (_cache[10] = ($event: any) => ($options.onFocusChange($event, 'golfHandicap', $options.participant.golfHandicap)))
              }, null, 8, ["modelValue", "title"]),
              _createVNode(_component_VueFormInput, {
                modelValue: $options.participant.golfClubMembership,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($options.participant.golfClubMembership) = $event)),
                name: "golfClubMembership",
                title: $props.labels.clubMember,
                onBlur: _cache[12] || (_cache[12] = ($event: any) => ($options.onFocusChange($event, 'golfClubMembership', $options.participant.golfClubMembership)))
              }, null, 8, ["modelValue", "title"]),
              _createVNode(_component_VueFormSelection, {
                modelValue: $options.participant.golfClubsRental,
                "onUpdate:modelValue": [
                  _cache[13] || (_cache[13] = ($event: any) => (($options.participant.golfClubsRental) = $event)),
                  _cache[14] || (_cache[14] = ($event: any) => ($options.onFocusChange(true, 'golfClubsRental', $options.participant.golfClubsRental)))
                ],
                name: "golfClubsRental",
                type: "select",
                required: true,
                title: $props.labels.clubRental,
                placeholder: $props.labels.pleaseChoose,
                labels: [
                                              $props.labels.noRental + ':NO',
                                              $props.labels.rightHanded + ':RIGHT_HANDED',
                                              $props.labels.rightHandedLong + ':RIGHT_HANDED_LONG',
                                              $props.labels.leftHanded + ':LEFT_HANDED',
                                              $props.labels.leftHandedLong + ':LEFT_HANDED_LONG'
                                          ]
              }, null, 8, ["modelValue", "title", "placeholder", "labels"]),
              _createVNode(_component_VueFormSelection, {
                modelValue: $options.participant.clothingSize,
                "onUpdate:modelValue": [
                  _cache[15] || (_cache[15] = ($event: any) => (($options.participant.clothingSize) = $event)),
                  _cache[16] || (_cache[16] = ($event: any) => ($options.onFocusChange(true, 'clothingSize', $options.participant.clothingSize)))
                ],
                name: "clothingSize",
                type: "select",
                required: true,
                title: $props.labels.clothingSize,
                placeholder: $props.labels.pleaseChoose,
                labels: [
                                              $props.labels.s + ':S',
                                              $props.labels.m + ':M',
                                              $props.labels.l + ':L',
                                              $props.labels.xl + ':XL',
                                              $props.labels.xxl + ':XXL'
                                          ]
              }, null, 8, ["modelValue", "title", "placeholder", "labels"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "items"]),
        _cache[28] || (_cache[28] = _createElementVNode("hr", { class: "w-full my-8 md:my-10" }, null, -1)),
        _createVNode(_component_ToggleButtons, {
          modelValue: $options.bikeModel,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($options.bikeModel) = $event)),
          items: { bike: $props.labels.bikeTour },
          views: { bike: 'bikeView' },
          "btn-classes": "min-w-60",
          "multi-select": "",
          "grow-mobile": "",
          class: "mt-4"
        }, {
          bikeView: _withCtx(() => [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_VueFormSelection, {
                modelValue: $options.participant.bikeSkillLevel,
                "onUpdate:modelValue": [
                  _cache[18] || (_cache[18] = ($event: any) => (($options.participant.bikeSkillLevel) = $event)),
                  _cache[19] || (_cache[19] = ($event: any) => ($options.onFocusChange(true, 'bikeSkillLevel', $options.participant.bikeSkillLevel)))
                ],
                name: "bikeSkillLevel",
                type: "select",
                required: true,
                title: $props.labels.skillLevel,
                placeholder: $props.labels.pleaseChoose,
                labels: [
                                              $props.labels.beginner + ':BEGINNER',
                                              $props.labels.intermediate + ':INTERMEDIATE'
                                          ]
              }, null, 8, ["modelValue", "title", "placeholder", "labels"]),
              _createVNode(_component_VueFormInput, {
                modelValue: $options.participant.height,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($options.participant.height) = $event)),
                name: "height",
                required: true,
                rules: {required: true, numeric: true},
                title: $props.labels.height,
                class: "col-start-1",
                onBlur: _cache[21] || (_cache[21] = ($event: any) => ($options.onFocusChange($event, 'height', $options.participant.height)))
              }, null, 8, ["modelValue", "title"]),
              _createVNode(_component_VueFormInput, {
                modelValue: $options.participant.weight,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($options.participant.weight) = $event)),
                name: "weight",
                required: true,
                rules: {required: true, numeric: true},
                title: $props.labels.weight,
                onBlur: _cache[23] || (_cache[23] = ($event: any) => ($options.onFocusChange($event, 'weight', $options.participant.weight)))
              }, null, 8, ["modelValue", "title"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "items"])
      ]),
      _createElementVNode("p", {
        class: "text-para-s md:text-para-base mt-10 md:mt-16",
        innerHTML: $props.labels.requiredHint
      }, null, 8, _hoisted_12),
      _createElementVNode("button", {
        type: "button",
        class: "flex flex-row-reverse items-center group w-full mt-8 md:mt-12",
        onClick: ($event: any) => ($options.onSubmit(validate))
      }, [
        _createVNode(_component_ArrowButton, {
          class: "w-10 h-10 flex-shrink-0",
          "show-ring-on-mobile": ""
        }),
        _createElementVNode("span", _hoisted_14, _toDisplayString($props.labels.next), 1)
      ], 8, _hoisted_13),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", {
          type: "button",
          class: "flex items-center group cursor-pointer mt-6",
          onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => ($options.toOverview && $options.toOverview(...args)))
        }, [
          _createElementVNode("span", _hoisted_16, _toDisplayString($props.labels.personalOverview), 1),
          _createVNode(_component_ArrowButton, {
            direction: "person",
            class: "w-10 h-10",
            "show-ring-on-mobile": ""
          })
        ])
      ])
    ]),
    _: 1
  }))
}