<template>
    <div class="relative container">
        <div class="xl:w-2/3 mx-auto bg-white py-4 md:py-6 px-2 md:px-28">
            <Form v-slot="{ handleSubmit }" as="div">
                <form v-if="step <= currentDuty.steps.length + 1"
                      @submit.prevent="handleSubmit($event, onSubmit)"
                >
                    <h2 v-if="title" class="text-heading-3 md:text-heading-2 mt-10">
                        {{ title }}
                    </h2>
                    <div class="mt-4 mb-12 prose md:prose-md lg:prose-lg" v-html="intro" />
                    <MultiStepFormHeader :steps="stepHeader" :current-step="step" />
                    <!-- Back navigation -->
                    <button type="button"
                            class="flex items-center cursor-pointer w-fit mb-6"
                            :class="[ showBackNavigation ? '' : 'invisible' ]"
                            @click="back"
                    >
                        <Icon name="chevron-left" class="w-3 h-3 text-black-300 stroke-current" />
                        <span class="text-black-600 text-para-xs ml-4">{{ labelBack }}</span>
                    </button>
                    <!-- first step -->
                    <div v-if="step === 0" class="flex flex-col gap-6 w-full mb-4">
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="commodity" class="md:w-48">
                                {{ translate('commodity') }}
                            </label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Dropdown id="commodity"
                                          v-model="commodity"
                                          :title="commodities.find(x => x.value === commodity).label"
                                          :white="true"
                                          :disabled="step > 0"
                                          class="w-full md:w-auto min-w-40"
                                >
                                    <DropdownItem v-for="c in commodities"
                                                  :key="c.value"
                                                  :value="c.value"
                                                  :label="c.label"
                                    />
                                </Dropdown>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="duty" class="md:w-48">
                                {{ translate('duty') }}
                            </label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Dropdown id="duty"
                                          v-model="duty"
                                          :title="translate(duty)"
                                          :white="true"
                                          :disabled="step > 0"
                                          class="w-full md:w-auto min-w-40"
                                >
                                    <DropdownItem v-for="(d, i) in duties"
                                                  :key="i"
                                                  :value="d.key"
                                                  :label="translate(d.key)"
                                    />
                                </Dropdown>
                            </div>
                        </div>

                        <!-- next step -->
                        <button type="submit"
                                class="flex justify-end items-center group cursor-pointer appearance-none focus:outline-none"
                        >
                            <span class="text-para-s font-semibold">{{ step > currentDuty.steps.length ? labelRequest : labelNext }}</span>
                            <ArrowButton direction="right" class="w-10 h-10" />
                        </button>
                    </div>
                    <!-- configured steps -->
                    <div v-for="(stp, s) in currentDuty.steps" v-show="(step - 1) === s" :key="stp.key">
                        <div v-show="step > s" class="mb-4">
                            <div class="flex flex-col gap-6 relative w-full">
                                <div v-if="getStepDescription(stp.key)" class="mb-4" v-html="getStepDescription(stp.key)" />
                                <CalculatorInput v-for="field in stp.fields"
                                                 :key="field.key"
                                                 v-model="calculationValues[field.key]"
                                                 :name="field.key"
                                                 :disabled="s < step - 1 || !getDependency(field.enableIf)"
                                                 :values="field.values"
                                                 :interval="field.interval"
                                                 :dependency="getDependency(field.filterValues)"
                                                 :label="translate(field.label)"
                                                 :yes-label="translate('yes')"
                                                 :type="field.type"
                                                 :observable="calcValuesStr"
                                />
                                <JamesonCellResults v-if="getStepResults(s).length > 0"
                                                    :group-labels="getResultGroups(s)"
                                                    :label-results-suffix="labelResultsSuffix"
                                                    :results="getStepResults(s)"
                                />

                                <!-- next step(s) -->
                                <button type="submit"
                                        class="flex justify-end items-center group cursor-pointer appearance-none focus:outline-none"
                                >
                                    <span class="text-para-s font-semibold">{{ step > currentDuty.steps.length ? labelRequest : labelNext }}</span>
                                    <ArrowButton direction="right" class="w-10 h-10" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- last step -->
                    <div v-if="step > currentDuty.steps.length" class="flex flex-col gap-6 w-full mb-4">
                        <div v-if="getStepDescription('send')" class="mb-4" v-html="getStepDescription('send')" />
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="firstName" class="md:w-48">{{ labelFirstName }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Field v-slot="{ field, errors, meta }"
                                       v-model="firstName"
                                       vid="firstName"
                                       rules="required"
                                       :name="labelFirstName"
                                >
                                    <input id="firstName"
                                           type="text"
                                           class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'firstName-error' : ''"
                                           v-bind="field"
                                           :class="[ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']"
                                           data-vv-scope="contact"
                                    >
                                    <Icon v-if="getIcon(errors, meta.valid)" :name="getIcon(errors, meta.valid)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
                                    <span v-if="errors.length > 0" id="firstName-error" class="text-error font-normal text-para-xs mt-1 md:mt-2">
                                        {{ errors[0] }}
                                    </span>
                                </Field>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="lastName" class="md:w-48">{{ labelLastName }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Field v-slot="{ field, errors, meta }"
                                       v-model="lastName"
                                       vid="lastName"
                                       rules="required"
                                       :name="labelLastName"
                                >
                                    <input id="lastName"
                                           type="text"
                                           class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'lastName-error' : ''"
                                           v-bind="field"
                                           :class="[ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']"
                                           data-vv-scope="contact"
                                    >
                                    <Icon v-if="getIcon(errors, meta.valid)" :name="getIcon(errors, meta.valid)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
                                    <span v-if="errors.length > 0" id="lastName-error" class="text-error font-normal text-para-xs mt-1 md:mt-2">
                                        {{ errors[0] }}
                                    </span>
                                </Field>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="company" class="md:w-48">{{ labelCompany }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Field v-slot="{ field, errors, meta }"
                                       v-model="company"
                                       vid="company"
                                       :name="labelCompany"
                                       rules="required"
                                >
                                    <input id="company"
                                           type="text"
                                           class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'company-error' : ''"
                                           :class="[ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']"
                                           data-vv-scope="contact"
                                           v-bind="field"
                                    >
                                    <Icon v-if="getIcon(errors, meta.valid)" :name="getIcon(errors, meta.valid)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
                                    <span v-if="errors.length > 0" id="company-error" class="text-error text-para-xs mt-1 md:mt-2">
                                        {{ errors[0] }}
                                    </span>
                                </Field>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="email" class="md:w-48">{{ labelEmail }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Field v-slot="{ field, errors, meta }"
                                       v-model="email"
                                       vid="email"
                                       :name="labelEmail"
                                       :rules="{ required: true, email: true }"
                                >
                                    <input id="email"
                                           type="email"
                                           class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white placeholder-improvedContrast"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'email-error' : ''"
                                           :class="[ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']"
                                           v-bind="field"
                                           data-vv-scope="contact"
                                           :placeholder="labelEmailPlaceholder"
                                    >
                                    <Icon v-if="getIcon(errors, meta.valid)" :name="getIcon(errors, meta.valid)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
                                    <span v-if="errors.length > 0" id="email-error" class="text-error text-para-xs mt-1 md:mt-2">
                                        {{ errors[0] }}
                                    </span>
                                </Field>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="country" class="md:w-48">{{ labelCountry }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Dropdown id="country"
                                          v-model="country"
                                          name="country"
                                          :white="true"
                                          :searchable="true"
                                          :title="countryOptions.find(x => x.value === country) ? countryOptions.find(x => x.value === country).label : ''"
                                          class="w-full md:w-auto min-w-40"
                                >
                                    <DropdownItem v-for="(c, i) in countryOptions"
                                                  :key="i"
                                                  :value="c.value"
                                                  :label="c.label"
                                    />
                                </Dropdown>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <div class="flex relative w-full max-w-full md:ml-1/3 md:max-w-8/12">
                                <Field v-slot="{ field, errors }"
                                       v-model="privacyAccepted"
                                       name="labelPrivacy"
                                       vid="acceptPrivacy"
                                       rules="required"
                                       :unchecked-value="false"
                                >
                                    <input id="acceptPrivacy"
                                           name="labelPrivacy"
                                           type="checkbox"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'acceptPrivacy-error' : ''"
                                           data-vv-scope="contact"
                                           class="mb-0 absolute opacity-0 text-black-900 text-para-xs md:text-para-s"
                                           v-bind="field"
                                           :value="true"
                                    >
                                    <label class="inline-block relative pl-6 checkbox-label-calculator" for="acceptPrivacy" v-html="privacy" />
                                    <p v-if="errors.length > 0" id="acceptPrivacy-error" class="text-error text-para-xs mt-1 md:mt-2">
                                        {{ errorAcceptPrivacy }}
                                    </p>
                                </Field>
                            </div>
                        </div>

                        <!-- submit form -->
                        <button type="submit"
                                class="flex justify-end items-center group cursor-pointer appearance-none focus:outline-none"
                        >
                            <span class="text-para-s font-semibold">{{ step > currentDuty.steps.length ? labelRequest : labelNext }}</span>
                            <ArrowButton direction="right" class="w-10 h-10" />
                        </button>
                    </div>
                </form>
                <div v-else class="relative w-full py-11 flex flex-col">
                    <h4 v-if="title" class="font-semibold">
                        {{ title }}
                    </h4>
                    <div class="flex flex-col py-4">
                        <span v-if="httpError" class="text-para-s text-error text-center mt-6">{{ httpError }}</span>
                        <template v-else>
                            <p>{{ confirmation1 }}</p>
                            <p>{{ confirmation2 }}</p>
                        </template>
                    </div>
                    <!-- Back navigation -->
                    <button type="button"
                            class="flex items-center cursor-pointer w-fit mt-4"
                            :class="[ step === 0 ? 'hidden md:block md:invisible' : 'visible' ]"
                            @click="step--"
                    >
                        <Icon name="chevron-left" class="w-3 h-3 text-black-300 stroke-current" />
                        <span class="text-black-600 text-para-xs ml-4">{{ labelBack }}</span>
                    </button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import axios from 'axios';
import {
    CalcResult,
    Commodity,
    Duty,
    DutyFieldType,
    DutyStep,
    DutyStepField,
    JamesonCellDuties,
    nestObjectKeys,
    objectKeysToCamelCase,
    round
} from './calculations';
import {Step} from './albion/MultiStepForm.vue';
import Utils from '../../utils/Utils';
import CalculatorInput from "./CalculatorInput.vue";
import JamesonCellResults from "./JamesonCellResults.vue";
import MultiStepFormHeader from "./albion/MultiStepFormHeader.vue";
import Icon from "../base/Icon.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import ArrowButton from "../base/ArrowButton.vue";
import {Field, Form} from "vee-validate";
import DynamicsUtils from "../../utils/DynamicsUtils";
import {DynamicsFieldMapping} from "../form/VueForm.vue";

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {Field, Form, ArrowButton, DropdownItem, Dropdown, Icon, MultiStepFormHeader, JamesonCellResults, CalculatorInput},
    inject: ['$contextPath'],
    props: {
        title: {type: String, required: true},
        labelTranslation: {type: Array, default: () => []},
        commodities: {type: Array as PropType<Array<Commodity>>, default: () => []},
        isamillCommodities: {type: Array as PropType<Array<Commodity>>, default: () => []},
        confirmation1: {type: String, required: true},
        confirmation2: {type: String, required: true},
        intro: {type: String, required: true},
        privacy: {type: String, required: true},
        steps: {type: Array, default: () => []},
        labelNext: {type: String, required: true},
        labelBack: {type: String, required: true},
        labelStep: {type: String, required: true},
        labelRequest: {type: String, required: true},
        labelFirstName: {type: String, required: true},
        labelLastName: {type: String, required: true},
        labelCompany: {type: String, required: true},
        labelEmail: {type: String, required: true},
        labelEmailPlaceholder: {type: String, required: true},
        labelCountry: {type: String, required: true},
        countries: {type: Array as PropType<Array<string>>, default: () => []},
        labelResultsSuffix: {type: String, default: 'Results'},
        errorAcceptPrivacy: {type: String, required: true},
        dynamicsSend: {type: Boolean, default: false},
        dynamicsFormId: {type: String},
        dynamicsApiUrl: {type: String},
        dynamicsLibUrl: {type: String},
        dynamicsFieldMappings: {type: Array as PropType<Array<DynamicsFieldMapping>>, default: () => []}
    },
    data() {
        return {
            step: 0,
            commodity: 'copper',
            duty: 'jameson_rougher_scalper',
            duties: JamesonCellDuties,
            calculationValues: {},
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            country: '',
            recommendation: null,
            results: [],
            httpError: null,
            privacyAccepted: false,
            genericDynamicsFieldMappings: undefined
        };
    },
    computed: {
        calcValuesStr(): string {
            return Object.entries(this.calculationValues).map(x => x.join(':')).join('/');
        },
        currentDuty(): Duty {
            return this.duties.find(x => x.key === this.duty);
        },
        stepHeader(): Step[] {
            const steps: Step[] = [
                {
                    title: this.translate('commodity'),
                    type: 'normal'
                }
            ];

            this.currentDuty.steps.forEach((step: DutyStep, i: number) => {
                steps.push({
                    title: this.getStepTitle(step.key),
                    type: i === 0 ? 'normal' : 'intermediate'
                });
            });

            steps.push({
                title: this.getStepTitle('send'),
                type: 'normal'
            });

            return steps;
        },
        showBackNavigation(): boolean {
            return (this.step > 0) && (this.step < this.stepHeader.length) && (this.stepHeader.length > 1);
        },
        countryOptions(): Array<{ label: string, value: string }> {
            const result: Array<{ label: string; value: string }> = [];
            if (this.countries) {
                this.countries.forEach(s => {
                    const keyValue = s.split(':');
                    if (keyValue.length === 2) {
                        result.push({ label: keyValue[0], value: keyValue[1] });
                    }
                });
            }
            return result;
        }
    },
    created(): void {
        if (this.dynamicsSend && this.dynamicsLibUrl) {
            Utils.addScript(this.dynamicsLibUrl);
        }
    },
    mounted(): void {
        DynamicsUtils.loadGenericFieldMappings().then((mappings) => this.genericDynamicsFieldMappings = mappings);
    },
    methods: {
        getIcon(errors: string[], passed: boolean): string {
            if (errors.length > 0) {
                return 'invalid';
            } else if (passed) {
                return 'valid';
            }
            return '';
        },
        /**
         * returns the translation with the given key
         * logs an error and returns the key itself if it is not found
         */
        translate(key: string): string {
            const translation = this.labelTranslation.find(x => x.key === key);
            if (translation) {
                return translation.value;
            }
            // missing translations throw errors that are hard to track down
            console.error(`key "${key}" not found`);
            return key;
        },
        // gets the title with the given step key from the steps array
        getStepTitle(key: string): string {
            const step = this.steps.find(x => x.key === key);
            return step ? step.title : '';
        },
        // gets the description with the given step key from the steps array
        getStepDescription(key): string {
            const step = this.steps.find(x => x.key === key);
            return step ? step.description : '';
        },
        /**
         * form submit (on next button or enter key)
         * go to next step or send form
         */
        onSubmit(values): void {
            // duty selected => setup calculation values (only once)
            if (this.step === 0) {
                this.calculationValues['commodity'] = this.commodity;
                this.currentDuty.steps.forEach((step: DutyStep) => {
                    step.fields.forEach((field: DutyStepField) => {
                        if (field.type === DutyFieldType.DROPDOWN || field.type === DutyFieldType.RANGE_SLIDER) {
                            this.calculationValues[field.key] = field.values[0];
                        } else {
                            this.calculationValues[field.key] = undefined;
                        }
                    });
                });
            }
            // make calculations for current step and push to results
            if (this.step > 0 && this.step <= this.currentDuty.steps.length) {
                const calc = this.currentDuty.steps[this.step - 1].calculation(this.calculationValues, [].concat(...this.results));
                this.results.push(calc);
            }

            this.step++;

            // last step: check if contact form is filled and validate
            if (this.step === this.currentDuty.steps.length + 1) {
                // reduce calculation results into one object
                this.recommendation = this.results.reduce((rec, step) => {
                    const tmp = rec;
                    step.forEach((result: CalcResult) => {
                        if (result.display || result.labelKey.includes('cell_selection')) {
                            tmp[result.labelKey] = typeof result.value === 'number' ? round(result.value, 1, true) : result.value;
                        }
                    });
                    return tmp;
                }, {});
            } else if (this.step > this.currentDuty.steps.length + 1) {
                if (this.dynamicsSend) {
                    // dynamics form capture
                    const form = this.$el.querySelector('form');

                    DynamicsUtils.submitDynamicsForm(
                        form,
                        this.genericDynamicsFieldMappings,
                        this.dynamicsFieldMappings,
                        this.dynamicsFormId,
                        this.dynamicsApiUrl,
                        this.doSend // callback which is called after submitting dynamics form
                    );
                } else {
                    // normal submit
                    this.doSend();
                }
            }
        },
        doSend(): void {
            // after last step. send to backend
            const { firstName, lastName, company, email, country } = this;
            this.httpError = null;
            try {
                axios.post(`${this.$contextPath}/.rest/api/v1/mill-calculation`, {
                    type: 'jameson-cell',
                    recommendation: nestObjectKeys(objectKeysToCamelCase(this.recommendation)),
                    duty: this.translate(this.currentDuty.key),
                    inputs: this.calculationValues,
                    firstName,
                    lastName,
                    company,
                    email,
                    country
                });
            } catch (e) {
                console.error(e);
                this.httpError = (e.response && e.response.message) ? e.response.message : this.translate('request_error');
            }
        },
        // go back one step and delete last result
        back(): void {
            this.step--;
            this.results.pop();
        },
        // get calculation results of given step
        getStepResults(step): CalcResult[] {
            if (this.results.length <= step) {
                return [];
            }
            return this.results[step].filter(res => res.display).map(x => ({ ...x, label: this.translate(x.labelKey) }));
        },
        // get translations of result groups
        getResultGroups(step): { key: string; value: string }[] {
            if (this.results.length <= step) {
                return [];
            }
            return Object.keys(
                this.results[step]
                    .filter(res => res.display)
                    .reduce((g, r) => {
                        const tmp = g;
                        tmp[r.labelKey.split('.').shift().replace(/\d/g, '')] = '';
                        return tmp;
                    }, {})
            )
                .map(x => ({ key: x, value: this.translate(x) }));
        },
        getDependency(dependency): (x) => boolean {
            if (!dependency) {
                return () => true;
            }
            return x => dependency.comparator(x, ...dependency.keys.map(key => this.calculationValues[key]));
        }
    }
};
</script>
