/* eslint-disable camelcase */
import { AreaCalc, getCell, getIsaMill, getPrediction } from './jameson-cell-lookup-tables';
import {
    circuitSolidsValues, cleanerCircuitSolidsValues,
    conGradeValues,
    feedGrades, inputFeedGrades, isaMillP80Values, IsaRegrindCommodities,
    p80Values,
    recoveryValues, rougherScavengerConGradeValues,
    tphValues,
    tssgValues
} from './dropdown-values';

const IsaCalculationBase = {
    gold: {
        slope: -1.12643930588903,
        intercept: 1122.08433964165
    },
    copper: {
        slope: -1.37972613825814,
        intercept: 2089.71369605512
    },
    sizeBasis: 120,
    SER: {
        finer: 10,
        coarser: 250,
        ratio: 8
    }
};

/*******************
 * Interfaces
 ******************/
export interface Commodity {
    value: string;
    label: string;
}

// eslint-disable-next-line no-unused-vars
export enum DutyFieldType { DROPDOWN, PERCENTAGE, CHECKBOX, KVP_DROPDOWN, RANGE_SLIDER }

export interface DutyStepField {
    label: string;
    type: DutyFieldType;
    key: string;
    values?: number[] | { value: string; label: string }[];
    interval?: number;
    enableIf?: { keys: string[]; comparator: (comparable, ...keys) => boolean };
    filterValues?: { keys: string[]; comparator: (comparable, ...keys) => boolean };
}

export interface DutyStep {
    key: string;
    fields: DutyStepField[];
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    calculation?: (values: any, results?: CalcResult[]) => CalcResult[];
}

export interface CalcResult {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    value: any;
    labelKey: string;
    display: boolean;
    label?: string;
    unit?: string;
}

export interface Duty {
    key: string;
    recovery: number;
    steps: DutyStep[];
}

export interface IsaMillRecommendation {
    ser: 'finer' | 'coarser' | 'ratio' | null;
    powerRequirement: number;
}

export interface JamesonCellSize {
    downcomers: number;
    base_metals: string;
    mineral_type?: number;
    volumetric_throughput?: number;
    feed_flowrate_at_50?: number;
    surface_area?: number;
    lip_length?: number;
    internal_launders?: number;
    lip_length_launders?: number;
}

export interface IsaMillSize {
    min: number;
    max: number;
    mill_size: string;
}

/*******************
 * helper functions
 ******************/

/**
 * rounds a number to a given number of digits
 * @param value - the value to round
 * @param precision - number of digits to round
 * @param fixed - keep zeros after comma (returns string)
 */
export function round(value, precision, fixed = false): number | string {
    const multiplier = Math.pow(10, precision || 0);
    const val = Math.round(value * multiplier) / multiplier;

    return fixed ? val.toFixed(precision) : val;
}

/**
 * strips all keys off an object that are not included in keys
 * @param keys - string array of keys
 * @param values - object
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function getValues(keys: string[], values: object): any {
    return Object.keys(values).reduce((sum, key) => {
        const tmp = sum;
        if (keys.includes(key)) {
            tmp[key] = values[key];
        }
        return tmp;
    }, {});
}

// converts snake_case and kebab-case keys to camelCase
export function objectKeysToCamelCase(object, recursive = true) {
    return Object.keys(object).reduce((obj, key) => {
        const tmp = obj;
        const ccKey = key.replace(/[-_]([a-z])/ig, (_, $1) => $1.toUpperCase());
        if (recursive && typeof object[key] === 'object') {
            tmp[ccKey] = objectKeysToCamelCase(object[key]);
        } else {
            tmp[ccKey] = object[key];
        }
        return tmp;
    }, {});
}

// converts object keys with dots to nested objects
// e.g. { 'a.b': 2 } becomes { a: { b: 2 } }
export function nestObjectKeys(object) {
    const nestKey = (obj, key, value) => {
        const tmp = obj;
        const keys = key.split('.');
        if (keys.length === 0) {
            return value;
        }
        if (keys.length === 1) {
            tmp[keys[0]] = value;
        } else {
            const nextKey = keys.shift();
            tmp[nextKey] = nestKey(obj[nextKey] || {}, keys.join('.'), value);
        }
        return tmp;
    };

    return Object.keys(object).reduce((obj, key) => nestKey(obj, key, object[key]), {});
}

/*******************
 * Calculations
 ******************/

/**
 * JamesonCell calculation formulas
 */
export const JCC = {
    /**
     * Oil sands are a special case. returns 1 for oil sands, 0 otherwise
     * @param commodity string value of a commodity
     */
    getMineralType(commodity): number {
        return commodity === 'oil-sands' ? 1 : 0;
    },

    /**
     * first step calculation is the same for every duty
     * calculates con tph and mass pull %
     * @param values for simplicity all parameters are stored in one object
     */
    firstStepCalc(values): CalcResult[] {
        const conTph = this.calcConTph(values.tphFeed, values.feedGrade, values.recovery / 100, values.targetGrade);
        const massPull = this.calcMassPull(conTph, values.tphFeed);
        return [{ labelKey: 'con_tph', value: conTph, display: true, unit: 'tph' }, {
            labelKey: 'mass_pull',
            value: massPull,
            display: true,
            unit: '%'
        }];
    },
    rougherScavengerJamos(
        mineralType,
        feedGrade,
        tphFeed,
        rougherCircuitSolids,
        rougherTargetGrade,
        isAtFinalGrade,
        scavengerTargetGrade,
        solidsSg,
        p80
    ): CalcResult[] {
        const waterTph = JCC.calcWaterTph(tphFeed, rougherCircuitSolids / 100);
        const feedSlurry = JCC.calcFeedSlurry(tphFeed, solidsSg, waterTph);
        const rougherConTph = JCC.calcConTph(tphFeed, feedGrade, JCC.getSplitRougherRecovery(isAtFinalGrade), rougherTargetGrade);
        const rougherMassPull = JCC.calcMassPull(rougherConTph, tphFeed);
        const ca = JCC.calcCa(solidsSg, p80);

        const scvngr1WaterTph = JCC.calcWaterTph(tphFeed - rougherConTph, rougherCircuitSolids / 100);
        const scvngr1FeedSlurry = JCC.calcFeedSlurry(tphFeed - rougherConTph, solidsSg, scvngr1WaterTph);
        const scvngr1ConTph = JCC.calcConTph(((tphFeed * feedGrade) * 0.96) - (rougherConTph * rougherTargetGrade), 1, 0.65, scavengerTargetGrade);
        const scvngr1MassPull = JCC.calcMassPull(scvngr1ConTph, tphFeed);

        // const scvngr2WaterTph = JCC.calcWaterTph(tphFeed - rougherConTph - scvngr1ConTph, rougherCircuitSolids / 100);
        // const scvngr2FeedSlurry = JCC.calcFeedSlurry(feedSlurry - scvngr1ConTph, waterTph, scvngr2WaterTph);
        // const scvngr2ConTph = JCC.calcConTphVariant(tphFeed, feedGrade, 0.96, rougherConTph, rougherTargetGrade, scvngr1ConTph, scavengerTargetGrade);
        // const scvngr2MassPull = JCC.calcMassPull(scvngr2ConTph, tphFeed);

        const rougherSurfaceArea = JCC.calcSurfaceArea(rougherConTph, ca);
        const scvngr1SurfaceArea = JCC.calcSurfaceArea(scvngr1ConTph, ca);
        // const scvngr2SurfaceArea = JCC.calcSurfaceArea(scvngr2ConTph, ca);

        const rougherCellSelection = JCC.calcCell(mineralType, rougherSurfaceArea, feedSlurry);
        const scvngr1CellSelection = JCC.calcCell(mineralType, scvngr1SurfaceArea, scvngr1FeedSlurry);
        // const scvngr2CellSelection = JCC.calcCell(mineralType, scvngr2SurfaceArea, scvngr2FeedSlurry);

        const results = [];
        results.push({ labelKey: 'rougher.feed_slurry', value: feedSlurry, display: true, unit: 'm3/h' });
        results.push({ labelKey: 'rougher.con_tph', value: rougherConTph, display: true, unit: 'tph' });
        results.push({ labelKey: 'rougher.mass_pull', value: rougherMassPull, display: true, unit: '%' });
        results.push({ labelKey: 'rougher.ca', value: ca, display: false });
        results.push({ labelKey: 'rougher.water_tph', value: waterTph, display: false });

        results.push({ labelKey: 'scvngr1.feed_slurry', value: scvngr1FeedSlurry, display: true, unit: 'm3/h' });
        results.push({ labelKey: 'scvngr1.con_tph', value: scvngr1ConTph, display: true, unit: 'tph' });
        results.push({ labelKey: 'scvngr1.mass_pull', value: scvngr1MassPull, display: true, unit: '%' });
        results.push({ labelKey: 'scvngr1.water_tph', value: scvngr1WaterTph, display: false });

        // results.push({ labelKey: 'scvngr2.feed_slurry', value: scvngr2FeedSlurry, display: true, unit: 'm3/h' });
        // results.push({ labelKey: 'scvngr2.con_tph', value: scvngr2ConTph, display: true, unit: 'tph' });
        // results.push({ labelKey: 'scvngr2.mass_pull', value: scvngr2MassPull, display: true, unit: '%' });
        // results.push({ labelKey: 'scvngr2.water_tph', value: scvngr2WaterTph, display: false });

        results.push({ labelKey: 'rougher.surface_area', value: rougherSurfaceArea, display: false });
        results.push({ labelKey: 'scvngr1.surface_area', value: scvngr1SurfaceArea, display: false });
        // results.push({ labelKey: 'scvngr2.surface_area', value: scvngr2SurfaceArea, display: false });

        results.push({ labelKey: 'rougher.cell_selection', value: rougherCellSelection, display: false });
        results.push({ labelKey: 'scvngr1.cell_selection', value: scvngr1CellSelection, display: false });
        // results.push({ labelKey: 'scvngr2.cell_selection', value: scvngr2CellSelection, display: false });
        return results;
    },
    fullCleanerJamos(mineralType, rougherConTph, circuitSolids, solidsSg, finalGrade, p80, targetGrade, tphFeed) {
        // cleaner 1
        const cleaner1WaterTph = JCC.calcWaterTph(rougherConTph, circuitSolids / 100);
        const cleaner1FeedSlurry = JCC.calcFeedSlurry(rougherConTph, solidsSg, cleaner1WaterTph);
        const cleaner1ConTph = JCC.calcConTph(rougherConTph, targetGrade, 0.7, finalGrade);
        const cleaner1MassPull = JCC.calcMassPull(cleaner1ConTph, tphFeed);
        const ca = JCC.calcCa(solidsSg, p80);

        // cleaner 2
        const cleaner2WaterTph = JCC.calcWaterTph(rougherConTph - cleaner1ConTph, circuitSolids / 100);
        const cleaner2FeedSlurry = JCC.calcFeedSlurry(rougherConTph - cleaner1ConTph, solidsSg, cleaner2WaterTph);
        const cleaner2ConTph = JCC.calcConTphVariant2(rougherConTph, targetGrade, 0.97, cleaner1ConTph, finalGrade);
        const cleaner2MassPull = JCC.calcMassPull(cleaner2ConTph, tphFeed);

        // scavenger
        const scavengerResults = JCC.calcCleanerScavenger(
            rougherConTph, cleaner1ConTph, cleaner2ConTph, circuitSolids, solidsSg, ca, cleaner2FeedSlurry, tphFeed
        );
        const cleaner1SurfaceArea = scavengerResults.find(x => x.labelKey === 'cleaner1.surface_area').value;
        const cleaner2SurfaceArea = scavengerResults.find(x => x.labelKey === 'cleaner2.surface_area').value;
        const scvngrSurfaceArea = scavengerResults.find(x => x.labelKey === 'cleaner_scvngr.surface_area').value;
        const scvngrFeedSlurry = scavengerResults.find(x => x.labelKey === 'cleaner_scvngr.feed_slurry').value;
        //
        // cells
        const cleaner1Cell = JCC.calcCell(mineralType, cleaner1SurfaceArea, cleaner1FeedSlurry, true);
        const cleaner2Cell = JCC.calcCell(mineralType, cleaner2SurfaceArea, cleaner2FeedSlurry, true);
        const scvngrCell = JCC.calcCell(mineralType, scvngrSurfaceArea, scvngrFeedSlurry, true);

        // result array
        const result: CalcResult[] = [];
        result.push({ labelKey: 'cleaner1.feed_slurry', value: cleaner1FeedSlurry, display: true, unit: 'm3/h' });
        result.push({ labelKey: 'cleaner1.con_tph', value: cleaner1ConTph, display: true, unit: 'tph' });
        result.push({ labelKey: 'cleaner1.mass_pull', value: cleaner1MassPull, display: true, unit: '%' });
        result.push({ labelKey: 'ca', value: ca, display: false });
        result.push({ labelKey: 'cleaner1.water_tph', value: cleaner1WaterTph, display: false });
        result.push({ labelKey: 'cleaner2.feed_slurry', value: cleaner2FeedSlurry, display: true, unit: 'm3/h' });
        result.push({ labelKey: 'cleaner2.con_tph', value: cleaner2ConTph, display: true, unit: 'tph' });
        result.push({ labelKey: 'cleaner2.mass_pull', value: cleaner2MassPull, display: true, unit: '%' });
        result.push({ labelKey: 'cleaner2.water_tph', value: cleaner2WaterTph, display: false });
        result.push(...scavengerResults);
        result.push({ labelKey: 'cleaner1.cell_selection', value: cleaner1Cell, display: false });
        result.push({ labelKey: 'cleaner2.cell_selection', value: cleaner2Cell, display: false });
        result.push({ labelKey: 'cleaner_scvngr.cell_selection', value: scvngrCell, display: false });
        return result;
    },
    getSplitRougherRecovery(finalConcGrade): number {
        return finalConcGrade ? 0.5 : 0.65;
    },
    calcConTph(tphFeed, feedGrade, recovery, expectedGrade) {
        return (tphFeed * feedGrade) * recovery / expectedGrade;
    },
    calcConTphVariant(tphFeed, feedGrade, recovery1, conTph1, targetGrade1, conTph2, targetGrade2) {
        return ((((tphFeed * (feedGrade)) * (recovery1)) - (conTph1 * (targetGrade1))) - (conTph2 * targetGrade2)) / (targetGrade2);
    },
    calcConTphVariant2(tphFeed, feedGrade, recovery, conTph, targetGrade) {
        return ((tphFeed * feedGrade * recovery) - (conTph * targetGrade)) / targetGrade;
    },
    calcCleanerScavengerConTph(conTph1, conTph2, conTph3) {
        return (conTph1 - conTph2 - conTph3) * 0.15;
    },
    calcMassPull(conTph, tphFeed) {
        return conTph / tphFeed * 100;
    },
    calcWaterTph(tphFeed, circuitSolids) {
        return tphFeed * (1 - circuitSolids) / circuitSolids;
    },
    calcFeedSlurry(tphFeed, solidsSg, waterTph): number {
        return tphFeed / solidsSg + waterTph / 1.05;
    },
    calcCleanerFeedSlurry(isAtFinalGrade, rougherConTph, scvngr1ConTph, circuitSolids, solidsSg) {
        let feedSlurry = (scvngr1ConTph / solidsSg + scvngr1ConTph / (circuitSolids / 100));
        if (!isAtFinalGrade) {
            feedSlurry += (rougherConTph / solidsSg + rougherConTph / (circuitSolids / 100));
        }
        return feedSlurry;
    },
    calcCleanerScavenger(throughput, conTph1, conTph2, circuitSolids, solidsSg, ca, feedSlurry, tphFeed) {
        const cleanerScvngrWaterTph = JCC.calcWaterTph(throughput - conTph1 - conTph2, circuitSolids / 100);
        const cleanerScvngrFeedSlurry = JCC.calcFeedSlurry(feedSlurry, solidsSg, cleanerScvngrWaterTph);
        const cleanerScvngrConTph = JCC.calcCleanerScavengerConTph(throughput, conTph1, conTph2);
        const cleanerScvngrMassPull = JCC.calcMassPull(cleanerScvngrConTph, tphFeed);

        const cleaner2RecycFeedSlurry = JCC.calcRecycFeedSlurry(cleanerScvngrConTph);
        const cleaner1SurfaceArea = JCC.calcSurfaceArea(conTph1, ca);
        const cleaner2SurfaceArea = JCC.calcSurfaceArea(conTph2, ca);
        const cleanerScavengerSurfaceArea = JCC.calcSurfaceArea(cleanerScvngrConTph, ca);

        const results = [];
        results.push({
            labelKey: 'cleaner2.recyc_feed_slurry',
            value: cleaner2RecycFeedSlurry,
            display: true,
            unit: 'm3/h'
        });

        results.push({
            labelKey: 'cleaner_scvngr.feed_slurry',
            value: cleanerScvngrFeedSlurry,
            display: true,
            unit: 'm3/h'
        });
        results.push({ labelKey: 'cleaner_scvngr.con_tph', value: cleanerScvngrConTph, display: true, unit: 'tph' });
        results.push({ labelKey: 'cleaner_scvngr.mass_pull', value: cleanerScvngrMassPull, display: true, unit: '%' });
        results.push({ labelKey: 'cleaner_scvngr.water_tph', value: cleanerScvngrWaterTph, display: false });

        results.push({ labelKey: 'cleaner1.surface_area', value: cleaner1SurfaceArea, display: false });
        results.push({ labelKey: 'cleaner2.surface_area', value: cleaner2SurfaceArea, display: false });
        results.push({ labelKey: 'cleaner_scvngr.surface_area', value: cleanerScavengerSurfaceArea, display: false });
        return results;
    },
    calcRecycFeedSlurry(conTph) {
        return conTph / 3 + (conTph * 0.35) / 1.05;
    },
    calcCa(sg, p80) {
        return AreaCalc[sg][p80] * sg * 0.9078 / Math.pow(0.3048, 2);
    },
    calcSurfaceArea(conTph, ca) {
        return conTph / ca;
    },
    calcCell(mineralType, surfaceArea, feedSlurry, cleaner = false): JamesonCellSize {
        return getCell(mineralType, surfaceArea, feedSlurry, cleaner);
    },
    calcThroughput(isAtFinalGrade, rougherConTph, scvngr1ConTph) {
        return isAtFinalGrade ? scvngr1ConTph : (rougherConTph + scvngr1ConTph);
    },
    calcSpecificEnergyRequirement(commodity, f80, p80): number {
        if (f80 > 120) {
            return getPrediction(commodity, f80, p80, 120);
        }
        if (f80 >= 100 && f80 <= 120) {
            return Math.pow(getPrediction(commodity, f80, p80, 100), (1 - (f80 - 100) / (120 - 100))) *
                Math.pow(getPrediction(commodity, f80, p80, 120), ((f80 - 100) / (120 - 100)));
        }
        if (f80 >= 80 && f80 <= 100) {
            return Math.pow(getPrediction(commodity, f80, p80, 80), (1 - (f80 - 80) / (100 - 80))) *
                Math.pow(getPrediction(commodity, f80, p80, 100), ((f80 - 80) / (100 - 80)));
        }
        if (f80 >= 60 && f80 <= 80) {
            return Math.pow(getPrediction(commodity, f80, p80, 60), (1 - (f80 - 60) / (80 - 60))) *
                Math.pow(getPrediction(commodity, f80, p80, 80), ((f80 - 60) / (80 - 60)));
        }
        if (f80 >= 40 && f80 <= 60) {
            return Math.pow(getPrediction(commodity, f80, p80, 40), (1 - (f80 - 40) / (60 - 40))) *
                Math.pow(getPrediction(commodity, f80, p80, 60), ((f80 - 40) / (60 - 40)));
        }
        return getPrediction(commodity, f80, p80, 40);
    },
    calcIsaMill(f80, p80, powerRequirement): string {
        return getIsaMill(f80, p80, powerRequirement);
    }
};

/**
 * IsaMill Calculation
 */
export function isaMillCalculation(commodity: string, f80: number, p80: number, tph: number): IsaMillRecommendation {
    try {
        // eslint-disable-next-line  @typescript-eslint/consistent-type-assertions
        const recommendation = <IsaMillRecommendation>{ ser: null, powerRequirement: 0 };

        if (p80 < IsaCalculationBase.SER.finer) {
            recommendation.ser = 'finer';
        } else if (f80 > IsaCalculationBase.SER.coarser) {
            recommendation.ser = 'coarser';
        } else if ((f80 / p80) > IsaCalculationBase.SER.ratio) {
            recommendation.ser = 'ratio';
        } else {
            const ser = JCC.calcSpecificEnergyRequirement(commodity, f80, p80);
            recommendation.powerRequirement = Math.round(tph * ser);
            recommendation.ser = null;
        }

        if (recommendation.ser) {
            recommendation.powerRequirement = 0;
        }
        return recommendation;
    } catch (error) {
        throw new Error('ERROR - calculation: error');
    }
}

/**
 * Jameson Cell calculations
 * fields and calculations for each form step
 */
export const JamesonCellDuties: Duty[] = [
    {
        key: 'jameson_rougher_scalper',
        recovery: 0.65,
        steps: [
            {
                key: 'rougher_scalper',
                fields: [
                    {
                        key: 'feedGrade',
                        label: 'feed_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: feedGrades
                    },
                    { key: 'tphFeed', label: 'tph_feed', type: DutyFieldType.DROPDOWN, values: tphValues },
                    {
                        key: 'rougherCircuitSolids',
                        label: 'rougher.circuit_solids',
                        type: DutyFieldType.RANGE_SLIDER,
                        interval: 5,
                        values: circuitSolidsValues
                    },
                    {
                        key: 'targetGrade',
                        label: 'target_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: feedGrades.slice(7),
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    },
                    { key: 'solidsSg', label: 'solids_sg', type: DutyFieldType.DROPDOWN, values: tssgValues },
                    { key: 'p80', label: 'p80', type: DutyFieldType.DROPDOWN, values: p80Values }
                ],
                calculation(values) {
                    const mineralType = JCC.getMineralType(values.commodity);
                    const vals = getValues(this.fields.map(x => x.key), values);
                    vals.recovery = 60;
                    const result = JCC.firstStepCalc(vals).map(res => ({
                        ...res,
                        labelKey: `scalper.${res.labelKey}`
                    }));
                    const waterTph = JCC.calcWaterTph(vals.tphFeed, vals.rougherCircuitSolids / 100);
                    const feedSlurry = JCC.calcFeedSlurry(vals.tphFeed, vals.solidsSg, waterTph);
                    result.push({ labelKey: 'scalper.feed_slurry', value: feedSlurry, display: true, unit: 'm3/h' });
                    const ca = JCC.calcCa(vals.solidsSg, vals.p80);
                    const surfaceArea = JCC.calcSurfaceArea(result.find(r => r.labelKey === 'scalper.con_tph').value, ca);

                    result.push({ labelKey: 'ca', value: ca, display: false });
                    result.push({ labelKey: 'water_tph', value: waterTph, display: false });
                    result.push({ labelKey: 'surface_area', value: surfaceArea, display: false });

                    result.push({
                        labelKey: 'cell_selection',
                        value: JCC.calcCell(mineralType, surfaceArea, feedSlurry),
                        display: false
                    });
                    return result;
                }
            }
        ]
    },
    {
        key: 'jameson_rougher_scavenger',
        recovery: 0.96,
        steps: [
            {
                key: 'rougher_scvngr',
                fields: [
                    {
                        key: 'feedGrade',
                        label: 'feed_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: inputFeedGrades
                    },
                    { key: 'tphFeed', label: 'tph_feed', type: DutyFieldType.DROPDOWN, values: tphValues },
                    {
                        key: 'rougherCircuitSolids',
                        label: 'rougher.circuit_solids',
                        type: DutyFieldType.RANGE_SLIDER,
                        interval: 5,
                        values: circuitSolidsValues
                    },
                    {
                        key: 'targetGrade',
                        label: 'rougher.target_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    },
                    { key: 'isAtFinalGrade', label: 'rougher.at_final_grade', type: DutyFieldType.CHECKBOX },
                    {
                        key: 'scavengerTargetGrade',
                        label: 'scvngr.target_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: rougherScavengerConGradeValues
                    },
                    { key: 'solidsSg', label: 'solids_sg', type: DutyFieldType.DROPDOWN, values: tssgValues },
                    { key: 'p80', label: 'p80', type: DutyFieldType.DROPDOWN, values: p80Values }
                ],
                calculation(values) {
                    const mineralType = JCC.getMineralType(values.commodity);
                    const vals = getValues(this.fields.map(x => x.key), values);

                    const {
                        feedGrade,
                        tphFeed,
                        rougherCircuitSolids,
                        targetGrade: rougherTargetGrade,
                        isAtFinalGrade,
                        scavengerTargetGrade,
                        solidsSg,
                        p80
                    } = vals;
                    return JCC.rougherScavengerJamos(
                        mineralType, feedGrade, tphFeed, rougherCircuitSolids, rougherTargetGrade, isAtFinalGrade, scavengerTargetGrade, solidsSg, p80
                    );
                }
            }
        ]
    },
    {
        recovery: 0.7,
        key: 'jameson_cleaner_scalper',
        steps: [
            {
                key: 'roughers',
                fields: [
                    {
                        key: 'feedGrade',
                        label: 'feed_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: feedGrades
                    },
                    { key: 'tphFeed', label: 'tph_feed', type: DutyFieldType.DROPDOWN, values: tphValues },
                    { key: 'recovery', label: 'recovery', type: DutyFieldType.DROPDOWN, values: recoveryValues },
                    {
                        key: 'targetGrade',
                        label: 'expected_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    }
                ],
                calculation(values) {
                    const vals = getValues(this.fields.map(x => x.key), values);
                    return JCC.firstStepCalc(vals).map(res => ({
                        ...res,
                        labelKey: `rougher.${res.labelKey}`
                    }));
                }
            },
            {
                key: 'cleaner_scalper',
                fields: [
                    {
                        key: 'cleanerCircuitSolids',
                        label: 'cleaner.circuit_solids',
                        type: DutyFieldType.RANGE_SLIDER,
                        interval: 1,
                        values: cleanerCircuitSolidsValues
                    },
                    { key: 'solidsSg', label: 'solids_sg', type: DutyFieldType.DROPDOWN, values: tssgValues },
                    {
                        key: 'expectedGrade',
                        label: 'target_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    },
                    { key: 'p80', label: 'p80', type: DutyFieldType.DROPDOWN, values: p80Values }
                ],
                calculation(values, results) {
                    // 1 for oil sands, 0 otherwise
                    const mineralType = JCC.getMineralType(values.commodity);
                    const result = [];
                    // extract step fields from calculation values (+ one field from previous step)
                    const vals = getValues(this.fields.map(x => x.key).concat(['targetGrade', 'tphFeed']), values);
                    // calculated tph from previous step
                    const tphFeed = results.find(x => x.labelKey === 'rougher.con_tph').value;

                    /**
                     * calculations
                     * convert percantages & use fixed cleaner recovery value
                     */
                    const waterTph = JCC.calcWaterTph(tphFeed, vals.cleanerCircuitSolids / 100);
                    const feedSlurry = JCC.calcFeedSlurry(tphFeed, vals.solidsSg, waterTph);
                    const ca = JCC.calcCa(vals.solidsSg, vals.p80);
                    const conTph = JCC.calcConTph(tphFeed, vals.targetGrade / 100, 0.7, vals.expectedGrade / 100);
                    const massPull = JCC.calcMassPull(conTph, vals.tphFeed);
                    const surfaceArea = JCC.calcSurfaceArea(conTph, ca);

                    // push to result array
                    result.push({ labelKey: 'scalper.feed_slurry', value: feedSlurry, display: true, unit: 'm3/h' });
                    result.push({ labelKey: 'scalper.con_tph', value: conTph, display: true, unit: 'tph' });
                    result.push({ labelKey: 'scalper.mass_pull', value: massPull, display: true, unit: '%' });
                    result.push({ labelKey: 'scalper.ca', value: ca, display: false });
                    result.push({ labelKey: 'scalper.water_tph', value: waterTph, display: false });
                    result.push({ labelKey: 'scalper.surface_area', value: surfaceArea, display: false });
                    result.push({
                        labelKey: 'cell_selection',
                        value: JCC.calcCell(mineralType, surfaceArea, feedSlurry, true),
                        display: false
                    });
                    return result;
                }
            }]
    },
    {
        key: 'jameson_cleaner_cleaner_scavenger',
        recovery: 0.97,
        steps: [
            {
                key: 'roughers',
                fields: [
                    {
                        key: 'feedGrade',
                        label: 'feed_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: feedGrades
                    },
                    { key: 'tphFeed', label: 'tph_feed', type: DutyFieldType.DROPDOWN, values: tphValues },
                    { key: 'recovery', label: 'recovery', type: DutyFieldType.DROPDOWN, values: recoveryValues },
                    {
                        key: 'targetGrade',
                        label: 'expected_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    }
                ],
                calculation(values) {
                    const vals = getValues(this.fields.map(x => x.key), values);
                    return JCC.firstStepCalc(vals).map(res => ({
                        ...res,
                        labelKey: `rougher.${res.labelKey}`
                    }));
                }
            },
            {
                key: 'full_cleaner',
                fields: [
                    {
                        key: 'cleanerCircuitSolids',
                        label: 'cleaner.circuit_solids',
                        type: DutyFieldType.RANGE_SLIDER,
                        interval: 1,
                        values: cleanerCircuitSolidsValues
                    },
                    { key: 'solidsSg', label: 'solids_sg', type: DutyFieldType.DROPDOWN, values: tssgValues },
                    {
                        key: 'finalGrade',
                        label: 'final_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    },
                    { key: 'p80', label: 'p80', type: DutyFieldType.DROPDOWN, values: p80Values }
                ],
                calculation(values, results) {
                    const mineralType = JCC.getMineralType(values.commodity);
                    const vals = getValues(this.fields.map(x => x.key).concat(['targetGrade', 'tphFeed']), values);
                    const { cleanerCircuitSolids, solidsSg, finalGrade, p80, targetGrade, tphFeed } = vals;
                    const rougherConTph = results.find(x => x.labelKey === 'rougher.con_tph').value;
                    return JCC.fullCleanerJamos(mineralType, rougherConTph, cleanerCircuitSolids, solidsSg, finalGrade, p80, targetGrade, tphFeed);
                }
            }
        ]
    },
    {
        recovery: 0.93,
        key: 'jameson_full_jameson_circuit',
        steps: [
            {
                key: 'rougher_scvngr',
                fields: [
                    {
                        key: 'feedGrade',
                        label: 'feed_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: inputFeedGrades
                    },
                    { key: 'tphFeed', label: 'tph_feed', type: DutyFieldType.DROPDOWN, values: tphValues },
                    {
                        key: 'rougherCircuitSolids',
                        label: 'rougher.circuit_solids',
                        type: DutyFieldType.RANGE_SLIDER,
                        interval: 5,
                        values: circuitSolidsValues
                    },
                    {
                        key: 'rougherTargetGrade',
                        label: 'rougher.target_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    },
                    { key: 'isAtFinalGrade', label: 'rougher.at_final_grade', type: DutyFieldType.CHECKBOX },
                    {
                        key: 'scavengerTargetGrade',
                        label: 'scvngr.target_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: rougherScavengerConGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    },
                    { key: 'solidsSg', label: 'solids_sg', type: DutyFieldType.DROPDOWN, values: tssgValues },
                    { key: 'p80', label: 'p80', type: DutyFieldType.DROPDOWN, values: p80Values }
                ],
                calculation(values) {
                    const mineralType = JCC.getMineralType(values.commodity);
                    const vals = getValues(this.fields.map(x => x.key), values);

                    const {
                        feedGrade,
                        tphFeed,
                        rougherCircuitSolids,
                        rougherTargetGrade,
                        isAtFinalGrade,
                        scavengerTargetGrade,
                        solidsSg,
                        p80
                    } = vals;
                    return JCC.rougherScavengerJamos(
                        mineralType, feedGrade, tphFeed, rougherCircuitSolids, rougherTargetGrade, isAtFinalGrade, scavengerTargetGrade, solidsSg, p80
                    );
                }
            },
            {
                key: 'isa_mill',
                fields: [
                    {
                        key: 'isRegrindRequired',
                        label: 'im.regrind_required',
                        type: DutyFieldType.CHECKBOX,
                        enableIf: {
                            keys: ['p80', 'targetCleanerGrindSizeP80'],
                            comparator: (a, b, c) => b !== c
                        }
                    },
                    {
                        key: 'isaCommodity',
                        label: 'commodity',
                        type: DutyFieldType.KVP_DROPDOWN,
                        values: IsaRegrindCommodities,
                        enableIf: {
                            keys: ['isRegrindRequired'],
                            comparator: (a, b) => b
                        }
                    },
                    {
                        key: 'targetCleanerGrindSizeP80',
                        label: 'cleaner.targetp80',
                        type: DutyFieldType.DROPDOWN,
                        values: isaMillP80Values,
                        filterValues: {
                            keys: ['p80'],
                            comparator: (a, b) => a < b
                        },
                        enableIf: {
                            keys: ['isRegrindRequired'],
                            comparator: (a, b) => b
                        }
                    }
                ],
                calculation(values, result) {
                    const vals = getValues(this.fields.map(x => x.key).concat(['p80', 'isAtFinalGrade']), values);
                    const rougherConTph = result.find(x => x.labelKey === 'rougher.con_tph').value;
                    const scvngr1ConTph = result.find(x => x.labelKey === 'scvngr1.con_tph').value;
                    // const scvngr2ConTph = result.find(x => x.labelKey === 'scvngr2.con_tph').value;
                    const results = [];

                    const throughput = JCC.calcThroughput(vals.isAtFinalGrade, rougherConTph, scvngr1ConTph);
                    const specificEnergyRequirement = JCC.calcSpecificEnergyRequirement(vals.isaCommodity, vals.p80, vals.targetCleanerGrindSizeP80);
                    const powerRequirement = throughput * specificEnergyRequirement;
                    const isaMill = {
                        mill: JCC.calcIsaMill(vals.p80, vals.targetCleanerGrindSizeP80, powerRequirement)
                    };

                    results.push({ labelKey: 'im.regrind_feed_f80', value: vals.p80, display: true });
                    results.push({ labelKey: 'im.throughput', value: throughput, display: true, unit: 'tph' });
                    results.push({
                        labelKey: 'im.energy_requirement',
                        value: specificEnergyRequirement,
                        display: false
                    });
                    results.push({ labelKey: 'im.power_requirement', value: powerRequirement, display: false });
                    results.push({ labelKey: 'im.cell_selection', value: isaMill, display: false });

                    return results;
                }
            },
            {
                key: 'full_jameson',
                fields: [
                    {
                        key: 'cleanerCircuitSolids',
                        label: 'cleaner.circuit_solids',
                        type: DutyFieldType.RANGE_SLIDER,
                        interval: 1,
                        values: cleanerCircuitSolidsValues
                    },
                    { key: 'cleanerSolidsSg', label: 'solids_sg', type: DutyFieldType.DROPDOWN, values: tssgValues },
                    {
                        key: 'targetFinalGrade',
                        label: 'target_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    }
                ],
                calculation(values, result) {
                    const mineralType = JCC.getMineralType(values.commodity);
                    const vals = getValues(
                        this.fields.map(x => x.key).concat([
                            'rougherTargetGrade',
                            'tphFeed',
                            'isAtFinalGrade',
                            'scavengerTargetGrade',
                            'targetCleanerGrindSizeP80',
                            'p80',
                            'isRegrindRequired']),
                        values
                    );
                    const {
                        cleanerCircuitSolids,
                        cleanerSolidsSg,
                        tphFeed,
                        targetFinalGrade,
                        rougherTargetGrade,
                        scavengerTargetGrade,
                        isAtFinalGrade,
                        targetCleanerGrindSizeP80,
                        p80,
                        isRegrindRequired
                    } = vals;

                    const rougherConTph = result.find(x => x.labelKey === 'rougher.con_tph').value;
                    const scvngr1ConTph = result.find(x => x.labelKey === 'scvngr1.con_tph').value;
                    // const scvngr2ConTph = result.find(x => x.labelKey === 'scvngr2.con_tph').value;
                    const throughput = result.find(x => x.labelKey === 'im.throughput').value;

                    const conTph = JCC.calcThroughput(isAtFinalGrade, rougherConTph, scvngr1ConTph);
                    const ca = JCC.calcCa(cleanerSolidsSg, isRegrindRequired ? targetCleanerGrindSizeP80 : p80);

                    // cleaner 1
                    const cleaner1WaterTph = JCC.calcWaterTph(conTph, cleanerCircuitSolids / 100);
                    const cleaner1ConTph = isAtFinalGrade
                        ? (((scvngr1ConTph * scavengerTargetGrade) * 0.7) / targetFinalGrade)
                        : (((rougherConTph * rougherTargetGrade) +
                            (scvngr1ConTph * scavengerTargetGrade) * 0.7) / targetFinalGrade);
                    const cleaner1FeedSlurry = JCC.calcCleanerFeedSlurry(
                        isAtFinalGrade, rougherConTph, scvngr1ConTph, cleanerCircuitSolids, cleanerSolidsSg
                    );
                    const cleaner1MassPull = JCC.calcMassPull(cleaner1ConTph, tphFeed);

                    // cleaner 2
                    const cleaner2WaterTph = JCC.calcWaterTph(throughput - cleaner1ConTph, cleanerCircuitSolids / 100);
                    const cleaner2ConTph = (isAtFinalGrade
                        ? (((scvngr1ConTph * scavengerTargetGrade) -
                            (cleaner1ConTph * targetFinalGrade)) * 0.97) : 0) / targetFinalGrade *
                        ((((rougherConTph * rougherTargetGrade) + (scvngr1ConTph * scavengerTargetGrade)) * 0.97) - (cleaner1ConTph * targetFinalGrade)) / targetFinalGrade;
                    const cleaner2MassPull = JCC.calcMassPull(cleaner2ConTph, tphFeed);
                    const cleaner2FeedSlurry = JCC.calcFeedSlurry(throughput - cleaner1ConTph, cleanerSolidsSg, cleaner2WaterTph);

                    // cleaner scavenger
                    const scavengerResults = JCC.calcCleanerScavenger(
                        throughput, cleaner1ConTph, cleaner2ConTph, cleanerCircuitSolids, cleanerSolidsSg, ca, cleaner2FeedSlurry, tphFeed
                    );
                    const cleaner1SurfaceArea = scavengerResults.find(x => x.labelKey === 'cleaner1.surface_area').value;
                    const cleaner2SurfaceArea = scavengerResults.find(x => x.labelKey === 'cleaner2.surface_area').value;
                    const cleanerScvngrSurfaceArea = scavengerResults.find(x => x.labelKey === 'cleaner_scvngr.surface_area').value;
                    const cleanerScvngrFeedSlurry = scavengerResults.find(x => x.labelKey === 'cleaner_scvngr.feed_slurry').value;

                    const cleaner1Cell = JCC.calcCell(mineralType, cleaner1SurfaceArea, cleaner1FeedSlurry, true);
                    const cleaner2Cell = JCC.calcCell(mineralType, cleaner2SurfaceArea, cleaner2FeedSlurry, true);
                    const cleanerScvngrCell = JCC.calcCell(mineralType, cleanerScvngrSurfaceArea, cleanerScvngrFeedSlurry, true);
                    const results = [];

                    results.push({
                        labelKey: 'cleaner1.feed_slurry',
                        value: cleaner1FeedSlurry,
                        display: true,
                        unit: 'm3/h'
                    });
                    results.push({ labelKey: 'cleaner1.con_tph', value: cleaner1ConTph, display: true, unit: 'tph' });
                    results.push({ labelKey: 'cleaner1.mass_pull', value: cleaner1MassPull, display: true, unit: '%' });
                    results.push({ labelKey: 'cleaner.ca', value: ca, display: false });
                    results.push({ labelKey: 'cleaner1.water_tph', value: cleaner1WaterTph, display: false });

                    results.push({
                        labelKey: 'cleaner2.feed_slurry',
                        value: cleaner2FeedSlurry,
                        display: true,
                        unit: 'm3/h'
                    });
                    results.push({ labelKey: 'cleaner2.con_tph', value: cleaner2ConTph, display: true, unit: 'tph' });
                    results.push({ labelKey: 'cleaner2.mass_pull', value: cleaner2MassPull, display: true, unit: '%' });
                    results.push({ labelKey: 'cleaner2.water_tph', value: cleaner2WaterTph, display: false });

                    results.push({ labelKey: 'cleaner1.cell_selection', value: cleaner1Cell, display: false });
                    results.push({ labelKey: 'cleaner2.cell_selection', value: cleaner2Cell, display: false });
                    results.push({
                        labelKey: 'cleaner_scvngr.cell_selection',
                        value: cleanerScvngrCell,
                        display: false
                    });

                    results.push(...scavengerResults);
                    return results;
                }
            }
        ]
    },
    {
        recovery: 0.8,
        key: 'jameson_re_cleaner',
        steps: [
            {
                key: 'roughers',
                fields: [
                    {
                        key: 'feedGrade',
                        label: 'feed_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: feedGrades
                    },
                    { key: 'tphFeed', label: 'tph_feed', type: DutyFieldType.DROPDOWN, values: tphValues },
                    { key: 'recovery', label: 'recovery', type: DutyFieldType.DROPDOWN, values: recoveryValues },
                    {
                        key: 'targetGrade',
                        label: 'expected_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: rougherScavengerConGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    }
                ],
                calculation(values) {
                    const vals = getValues(this.fields.map(x => x.key), values);
                    return JCC.firstStepCalc(vals).map(res => ({
                        ...res,
                        labelKey: `rougher.${res.labelKey}`
                    }));
                }
            },
            {
                key: 'cleaners',
                fields: [
                    {
                        key: 'cleanerCircuitSolids',
                        label: 'cleaner.circuit_solids',
                        type: DutyFieldType.RANGE_SLIDER,
                        interval: 1,
                        values: cleanerCircuitSolidsValues
                    },
                    {
                        key: 'cleanerRecovery',
                        label: 'cleaner.recovery',
                        type: DutyFieldType.DROPDOWN,
                        values: recoveryValues
                    },
                    {
                        key: 'cleanerExpectedGrade',
                        label: 'cleaner.expected_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    }
                ],
                calculation(values, results) {
                    // extract step fields from calculation values (+ one field from previous step)
                    const vals = getValues(this.fields.map(x => x.key).concat(['targetGrade', 'tphFeed']), values);
                    // calculated tph from previous step
                    const tphFeed = results.find(x => x.labelKey === 'rougher.con_tph').value;
                    // calculations
                    const conTph = JCC.calcConTph(tphFeed, vals.targetGrade / 100, vals.cleanerRecovery / 100, vals.cleanerExpectedGrade / 100);
                    const massPull = JCC.calcMassPull(conTph, vals.tphFeed);

                    return [
                        { labelKey: 'cleaner.con_tph', value: conTph, display: true, unit: 'tph' },
                        { labelKey: 'cleaner.mass_pull', value: massPull, display: true, unit: '%' }
                    ];
                }
            },
            {
                key: 'recleaner',
                fields: [
                    {
                        key: 'reCleanerCircuitSolids',
                        label: 'recleaner.circuit_solids',
                        type: DutyFieldType.RANGE_SLIDER,
                        interval: 5,
                        values: circuitSolidsValues
                    },
                    { key: 'solidsSg', label: 'solids_sg', type: DutyFieldType.DROPDOWN, values: tssgValues },
                    {
                        key: 'reCleanerExpectedGrade',
                        label: 'target_grade',
                        type: DutyFieldType.DROPDOWN,
                        values: conGradeValues,
                        filterValues: {
                            keys: ['feedGrade'],
                            comparator: (a, b) => a > b
                        }
                    },
                    { key: 'p80', label: 'p80', type: DutyFieldType.DROPDOWN, values: p80Values }
                ],
                calculation(values, results) {
                    // 1 for oil sands, 0 otherwise
                    const mineralType = JCC.getMineralType(values.commodity);
                    const result = [];
                    // extract step fields from calculation values (+ one field from previous step)
                    const vals = getValues(this.fields.map(x => x.key).concat(['tphFeed', 'targetGrade', 'cleanerExpectedGrade']), values);
                    // calculated tph from previous step
                    const tphFeed = results.find(x => x.labelKey === 'cleaner.con_tph').value;

                    /**
                     * calculations
                     * convert percantages & use fixed cleaner recovery value
                     */
                    const waterTph = JCC.calcWaterTph(tphFeed, vals.reCleanerCircuitSolids / 100);
                    const feedSlurry = JCC.calcFeedSlurry(tphFeed, vals.solidsSg, waterTph);
                    const ca = JCC.calcCa(vals.solidsSg, vals.p80);
                    const conTph = JCC.calcConTph(tphFeed, vals.cleanerExpectedGrade / 100, 0.8, vals.reCleanerExpectedGrade / 100);
                    const massPull = JCC.calcMassPull(conTph, vals.tphFeed);
                    const surfaceArea = JCC.calcSurfaceArea(tphFeed, ca);

                    // push to result array
                    result.push({ labelKey: 'scalper.feed_slurry', value: feedSlurry, display: true, unit: 'm3/h' });
                    result.push({ labelKey: 'scalper.con_tph', value: conTph, display: true, unit: 'tph' });
                    result.push({ labelKey: 'scalper.mass_pull', value: massPull, display: true, unit: '%' });
                    result.push({
                        labelKey: 'cell_selection',
                        value: JCC.calcCell(mineralType, surfaceArea, feedSlurry),
                        display: false
                    });

                    return result;
                }
            }
        ]
    }
];
