import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["poster"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "absolute top-0 left-0 w-full h-full bg-stage-gradient"
}
const _hoisted_4 = { class: "flex justify-center items-center mt-18 xl:mt-28 z-10" }
const _hoisted_5 = { class: "text-para-xs font-semibold mr-2" }
const _hoisted_6 = { class: "text-para-xs font-semibold ml-2" }
const _hoisted_7 = {
  key: 4,
  class: "absolute bottom-0 w-full md:mb-35 mb-24 text-white"
}
const _hoisted_8 = { class: "container" }
const _hoisted_9 = { class: "inline-grid grid-cols-1 xl:gap-y-4" }
const _hoisted_10 = {
  key: 0,
  class: "text-stage-title-mobile md:text-stage-title-tablet xl:text-stage-title-desktop order-2 md:pb-0 pb-2"
}
const _hoisted_11 = {
  key: 1,
  class: "xl:text-heading-4 text-heading-5 order-1"
}
const _hoisted_12 = {
  key: 2,
  class: "md:text-para-lg max-w-xl order-3"
}
const _hoisted_13 = { class: "container" }
const _hoisted_14 = { class: "inline-grid grid-cols-1 xl:gap-y-4 gap-y-2" }
const _hoisted_15 = {
  key: 0,
  class: "text-stage-title-mobile md:text-stage-title-tablet xl:text-stage-title-desktop order-2 md:pb-0 pb-2"
}
const _hoisted_16 = {
  key: 1,
  class: "xl:text-heading-4 text-heading-5 order-1"
}
const _hoisted_17 = {
  key: 2,
  class: "md:text-para-lg max-w-xl order-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return ($props.video || Object.keys($props.urls).length > 1)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["w-full relative", {'h-screen': !$props.editMode}])
      }, [
        ($props.video)
          ? (_openBlock(), _createElementBlock("video", {
              key: 0,
              poster: $props.poster,
              autoplay: "",
              muted: "",
              loop: "",
              class: "object-cover object-center w-full h-full"
            }, [
              _createElementVNode("source", { src: $props.video }, null, 8, _hoisted_2)
            ], 8, _hoisted_1))
          : (_openBlock(), _createBlock(_component_ResponsiveImage, {
              key: 1,
              urls: $props.urls,
              class: "w-full h-full",
              "img-class": 'object-cover object-center w-full h-full' + ($props.editMode ? '': ' absolute top-0 left-0'),
              "show-wide": true,
              caption: $props.title,
              "alt-title": $props.imageAlt
            }, null, 8, ["urls", "img-class", "caption", "alt-title"])),
        ($props.showGradient)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true),
        ($props.showLanguageSwitcher)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(["absolute w-full text-white", _ctx.additionalTopMarginContent({prefix: 'top', sm: 14, md: 20, xl: 20})])
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString($props.languageSwitcherPrefix), 1),
                _createVNode(_component_Dropdown, {
                  modelValue: $data.currentLang,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentLang) = $event)),
                  class: "min-w-40 md:min-w-50",
                  title: "language"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.locales, (lang, locale) => {
                      return (_openBlock(), _createBlock(_component_DropdownItem, {
                        key: locale,
                        label: lang,
                        value: locale
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("p", _hoisted_6, _toDisplayString($props.languageSwitcherSuffix), 1)
              ])
            ], 2))
          : _createCommentVNode("", true),
        ($props.title || $props.description || $props.subTitle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  ($props.title)
                    ? (_openBlock(), _createElementBlock("h1", _hoisted_10, _toDisplayString($props.title), 1))
                    : _createCommentVNode("", true),
                  ($props.subTitle)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_11, _toDisplayString($props.subTitle), 1))
                    : _createCommentVNode("", true),
                  ($props.description)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString($props.description), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(_ctx.additionalTopMarginContent({prefix: 'mt', sm: 20, md: 44, xl: 44}))
      }, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            ($props.title)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_15, _toDisplayString($props.title), 1))
              : _createCommentVNode("", true),
            ($props.subTitle)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_16, _toDisplayString($props.subTitle), 1))
              : _createCommentVNode("", true),
            ($props.description)
              ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString($props.description), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ], 2))
}