import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute left-0 top-full bg-white border border-black-200 rounded w-full md:w-auto md:min-w-60 max-w-full py-2 mt-2 z-10"
}
const _hoisted_3 = ["onClick", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", { toggle: $options.toggle }),
    ($data.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute left-6 -top-1.25 w-2 h-2 border-l border-t border-black-200 bg-white transform rotate-45" }, null, -1)),
          _renderSlot(_ctx.$slots, "items", { toggle: $options.toggle }, () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (label, value) => {
              return (_openBlock(), _createElementBlock("div", {
                key: value,
                class: "text-para-s cursor-pointer text-black-900 px-4 py-2 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white",
                onClick: ($event: any) => ($options.select(value)),
                onKeyup: _withKeys(($event: any) => ($options.select(value)), ["enter"])
              }, _toDisplayString(label), 41, _hoisted_3))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}