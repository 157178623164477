import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex flex-wrap xl:flex-nowrap xl:flex-row-reverse overflow-hidden xl:overflow-visible" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapDetail = _resolveComponent("MapDetail")!
  const _component_MapEmbed = _resolveComponent("MapEmbed")!
  const _component_MapCategories = _resolveComponent("MapCategories")!
  const _component_MapOffice = _resolveComponent("MapOffice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$options.showOfficeSection)
      ? (_openBlock(), _createBlock(_component_MapDetail, {
          key: 0,
          location: $data.selectedMarker,
          "label-visit-website": $props.labelVisitWebsite,
          "hide-categories": $props.hideCategories,
          onCloseLocation: $options.closeLocation
        }, null, 8, ["location", "label-visit-website", "hide-categories", "onCloseLocation"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MapEmbed, {
      markers: $data.mapData,
      "region-option": $data.regionOption,
      "is-world-map": $data.isWorldMap,
      "selected-marker": $data.selectedMarker,
      "hide-categories": !$options.showCategories,
      "is-office-map": $props.dataSource === 'offices',
      onOpenLocation: $options.openLocation,
      onOfficeSelect: $options.setOffice
    }, null, 8, ["markers", "region-option", "is-world-map", "selected-marker", "hide-categories", "is-office-map", "onOpenLocation", "onOfficeSelect"]),
    ($options.showCategories)
      ? (_openBlock(), _createBlock(_component_MapCategories, {
          key: 1,
          categories: $props.categories,
          "label-show-all": $props.labelShowAll,
          "label-search": $props.labelSearch,
          "label-back": $props.labelBack,
          "search-entries": $options.searchEntries,
          onFilterUpdate: $options.filter,
          onSearched: $options.openLocationByUUID
        }, null, 8, ["categories", "label-show-all", "label-search", "label-back", "search-entries", "onFilterUpdate", "onSearched"]))
      : _createCommentVNode("", true),
    ($options.showOfficeSection)
      ? (_openBlock(), _createBlock(_component_MapOffice, {
          key: 2,
          offices: $props.points,
          "selected-office": $data.selectedMarker,
          "label-select-office": $props.labelSelectOffice,
          "label-phone": $props.labelPhone,
          "label-mobile": $props.labelMobile,
          "label-fax": $props.labelFax,
          onOfficeSelect: $options.setOffice
        }, null, 8, ["offices", "selected-office", "label-select-office", "label-phone", "label-mobile", "label-fax", "onOfficeSelect"]))
      : _createCommentVNode("", true)
  ]))
}