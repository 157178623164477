<template>
    <div v-if="editMode">
        <div class="text-para-s font-semibold">
            <span>{{ title }}</span>
            <span class="text-black-500 font-normal italic"> -- hidden --</span>
        </div>
        <div class="relative text-black-500">
            <input v-model="model[name]"
                   class="w-full text-para-s border rounded focus:outline-none py-2.5 pr-4 pl-4 border-black-200 focus:border-primary-500"
                   type="text"
                   :name="name"
            >
        </div>
    </div>
    <input v-else
           v-model="model[name]"
           type="hidden"
           :name="name"
    >
</template>

<script lang="ts">

export default {
    inject: ['model'],
    props: {
        name: {type: String},
        title: {type: String},
        value: {type: String},
        editMode: {type: Boolean, default: false}
    },
    created(): void {
        if (!this.model[this.name] && this.value) {
            this.model[this.name] = this.value;
        }
    }
};
</script>
