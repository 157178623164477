<template>
    <section class="section">
        <div :id="anchor" class="container mt-16 mb-32">
            <div class="xl:w-2/3 mx-auto">
                <h3 v-if="title" class="text-heading-3 md:text-heading-2 tracking-tight mb-4 md:mb-6">
                    {{ title }}
                </h3>
                <p class="text-heading-5 md:text-heading-4 tracking-tight">{{ subtitle }}</p>
            </div>
        </div>
        <ul>
            <li v-for="(question, i) in questions"
                :key="i"
                class="relative container"
                :class="{'bg-black-50 xl:bg-transparent': i === questionIndex}"
            >
                <div class="mr-9 xl:mr-0" :class="{'xl:bg-black-50 border-l-4 border-primary-400': i === questionIndex}">
                    <div class="flex md:items-center gap-6 md:gap-10 xl:w-2/3 mx-auto py-6 md:py-12"
                         :class="{
                             'flex-col md:flex-row': i >= questionIndex,
                             'items-center': i < questionIndex,
                             'border-b border-black-400': i !== questionIndex && i !== (questionIndex - 1)
                         }"
                    >
                        <p class="flex-grow text-para-base md:text-para-xl font-semibold"
                           :class="{'text-black-600': i > questionIndex}"
                        >
                            {{ question }}
                        </p>
                        <div class="flex md:items-center gap-6 md:gap-10">
                            <GcButton v-if="i === questionIndex" class="whitespace-nowrap" :label="yesLabel" secondary @click="nextStep" />
                            <GcButton v-if="i === questionIndex" class="whitespace-nowrap" :label="noLabel" secondary @click="contactOpen = true" />
                            <div v-if="i < questionIndex" class="flex items-center justify-center rounded-full border border-primary-500 w-10 h-10">
                                <Icon name="checkmark" class="w-10 h-10 text-primary-500 stroke-current" />
                            </div>
                        </div>
                    </div>
                </div>
                <button v-show="!complete && i === questionIndex"
                        type="button"
                        class="absolute top-1/2 right-4.5 xl:right-9 flex items-center gap-4 xl:gap-7
                               transform translate-x-1/2 -translate-y-1/2 -rotate-90 bg-primary-400 px-4 xl:px-6 py-1.5 xl:py-4"
                        @click="openModal"
                >
                    <span class="text-para-xs text-white font-semibold">{{ modalLabel }}</span>
                    <Icon name="chat" class="w-6 h-6 xl:w-10 xl:h-10 text-white stroke-current fill-current transform rotate-90" />
                </button>
            </li>
        </ul>
        <div v-show="!complete" class="mb-18" />
        <CollapseTransition v-show="complete">
            <div class="container bg-black-50 xl:bg-transparent mt-16">
                <div class="xl:bg-black-50 py-6 md:py-12">
                    <div class="flex items-center gap-6 md:gap-12 xl:w-1/2 mx-auto">
                        <div class="flex items-center justify-center bg-primary-500 w-18 h-18 rounded-full">
                            <Icon name="checkmark" class="w-18 h-18 text-white stroke-current" />
                        </div>
                        <div>
                            <h4 v-if="resetTitle" class="text-para-base md:text-para-xl font-semibold">
                                {{ resetTitle }}
                            </h4>
                            <p class="text-para-s md:text-para-lg mt-6">
                                {{ resetText }}
                            </p>
                        </div>
                    </div>
                    <div class="flex gap-6 md:gap-12 xl:w-1/2 mx-auto mt-8">
                        <div class="w-18" />
                        <GcButton :label="resetLabel" icon="arrow-up" @click="reset" />
                    </div>
                </div>
            </div>
        </CollapseTransition>
        <ContentOverlay v-model="modalOpen" narrow>
            <div class="py-4 md:pb-0 md:pt-12">
                <h3 v-if="modalTitle" class="text-para-base md:text-para-xl font-semibold">{{ modalTitle }}</h3>
                <p class="text-para-s md:text-para-lg" v-html="modalText" />
            </div>
        </ContentOverlay>
        <ContentOverlay v-model="contactOpen" :button-label="contactLabel" narrow>
            <div class="pt-4 md:pt-12">
                <h3 v-if="contactTitle" class="text-para-base md:text-para-xl font-semibold">{{ contactTitle }}</h3>
                <p class="text-para-s md:text-para-lg" v-html="contactText" />
            </div>
        </ContentOverlay>
    </section>
</template>

<script lang="ts">
import {PropType} from 'vue';
import GcButton from "../base/GcButton.vue";
import Icon from "../base/Icon.vue";
import ContentOverlay from "../base/ContentOverlay.vue";
import CollapseTransition from "../base/CollapseTransition.vue";

export default {
    components: {CollapseTransition, ContentOverlay, Icon, GcButton},
    props: {
        anchor: {type: String},
        resourcePath: {type: String},
        title: {type: String},
        subtitle: {type: String},
        resetTitle: {type: String},
        resetText: {type: String},
        resetLabel: {type: String},
        questions: {type: Array as PropType<Array<string>>},
        yesLabel: {type: String},
        noLabel: {type: String},
        modalTitle: {type: String},
        modalText: {type: String},
        modalLabel: {type: String},
        contactTitle: {type: String},
        contactText: {type: String},
        contactLabel: {type: String}
    },
    data() {
        return {
            questionIndex : 0,
            modalOpen : false,
            contactOpen : false
        };
    },
    computed: {
        complete(): boolean {
            return this.questionIndex === this.questions.length;
        }
    },
    methods: {
        openModal(): void {
            window.requestAnimationFrame(() => {
                this.modalOpen = true;
            });
        },
        nextStep(): void {
            this.questionIndex++;
        },
        reset(): void {
            this.questionIndex = 0;
        }
    }
};
</script>
