import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mt-2 mb-6 md:mt-8 md:mb-20" }
const _hoisted_2 = { class: "text-black-600 text-para-xs ml-4" }
const _hoisted_3 = { class: "text-para-s font-semibold" }
const _hoisted_4 = { class: "text-heading-2 md:text-heading-1" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "mt-10 md:mt-14" }
const _hoisted_7 = { class: "font-semibold text-para-xs md:text-para-s" }
const _hoisted_8 = { class: "grid grid-cols-1 md:grid-cols-2 gap-8 mt-10" }
const _hoisted_9 = { class: "grid grid-cols-1 md:grid-cols-2 gap-8 mt-10" }
const _hoisted_10 = {
  key: 0,
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_11 = { class: "font-black mt-20 mb-4" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "font-semibold text-para-s line-clamp-1" }
const _hoisted_15 = { class: "md:hidden border-t border-black-200 mt-12" }
const _hoisted_16 = { class: "text-para-s font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_VueFormSelection = _resolveComponent("VueFormSelection")!
  const _component_VueFormInput = _resolveComponent("VueFormInput")!
  const _component_ToggleButtons = _resolveComponent("ToggleButtons")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, { as: "div" }, {
    default: _withCtx(({ validate }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          class: "flex items-center cursor-pointer w-fit",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.previousStep && $options.previousStep(...args)))
        }, [
          _createVNode(_component_Icon, {
            name: "chevron-left",
            class: "w-3 h-3 text-black-300 stroke-current"
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString($props.labels.backTo) + " " + _toDisplayString($props.labels.participation), 1)
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "hidden md:flex items-center group cursor-pointer",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.toOverview && $options.toOverview(...args)))
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString($props.labels.personalOverview), 1),
          _createVNode(_component_ArrowButton, {
            direction: "person",
            class: "w-10 h-10"
          })
        ])
      ]),
      _createElementVNode("h2", _hoisted_4, _toDisplayString($props.labels.travelDetails), 1),
      _createElementVNode("p", {
        class: "text-para-s md:text-para-xl mt-4 md:mt-2",
        innerHTML: $props.labels.travelDetailsDescription
      }, null, 8, _hoisted_5),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", null, [
          _createElementVNode("span", _hoisted_7, [
            _createTextVNode(_toDisplayString($props.labels.meansOFTravel) + " ", 1),
            _cache[32] || (_cache[32] = _createElementVNode("dfn", { title: "required" }, "*", -1))
          ])
        ]),
        _createVNode(_component_ToggleButtons, {
          modelValue: $options.meansOfTravelModel,
          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (($options.meansOfTravelModel) = $event)),
          items: { CAR: $props.labels.car, TRAIN: $props.labels.train, AIRPLANE: $props.labels.airplane },
          views: { TRAIN: 'train', AIRPLANE: 'airplane' },
          "empty-value": "UNKNOWN",
          grow: "",
          "col-mobile": "",
          class: "mt-4"
        }, {
          train: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_VueFormSelection, {
                modelValue: $options.participant.shuttleNeeded,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => (($options.participant.shuttleNeeded) = $event)),
                  _cache[3] || (_cache[3] = ($event: any) => ($options.onFocusChange(true, 'shuttleNeeded', $options.participant.shuttleNeeded)))
                ],
                name: "shuttleNeeded",
                type: "select",
                required: true,
                title: $props.labels.transportationNeeded,
                placeholder: $props.labels.pleaseChoose,
                description: $props.labels.transportationNeededTrainHint,
                labels: [
                                              $props.labels.yes + ':true',
                                              $props.labels.no + ':false'
                                          ]
              }, null, 8, ["modelValue", "title", "placeholder", "description", "labels"]),
              ($options.participant.shuttleNeeded === 'true')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.arrivalDateModel,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($options.arrivalDateModel) = $event)),
                      name: "arrivalDate",
                      title: $props.labels.arrivalDate,
                      type: "date",
                      class: "col-start-1",
                      onBlur: _cache[5] || (_cache[5] = ($event: any) => ($options.onFocusChange($event, 'arrivalTime', $options.participant.arrivalTime)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.arrivalTimeModel,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($options.arrivalTimeModel) = $event)),
                      name: "arrivalTime",
                      title: $props.labels.arrivalTime,
                      type: "time",
                      onBlur: _cache[7] || (_cache[7] = ($event: any) => ($options.onFocusChange($event, 'arrivalTime', $options.participant.arrivalTime)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.departureDateModel,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($options.departureDateModel) = $event)),
                      name: "departureDate",
                      title: $props.labels.departureDate,
                      type: "date",
                      class: "col-start-1",
                      onBlur: _cache[9] || (_cache[9] = ($event: any) => ($options.onFocusChange($event, 'departureTime', $options.participant.departureTime)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.departureTimeModel,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($options.departureTimeModel) = $event)),
                      name: "departureTime",
                      title: $props.labels.departureTime,
                      type: "time",
                      onBlur: _cache[11] || (_cache[11] = ($event: any) => ($options.onFocusChange($event, 'departureTime', $options.participant.departureTime)))
                    }, null, 8, ["modelValue", "title"])
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          airplane: _withCtx(() => [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_VueFormSelection, {
                modelValue: $options.participant.shuttleNeeded,
                "onUpdate:modelValue": [
                  _cache[12] || (_cache[12] = ($event: any) => (($options.participant.shuttleNeeded) = $event)),
                  _cache[13] || (_cache[13] = ($event: any) => ($options.onFocusChange(true, 'shuttleNeeded', $options.participant.shuttleNeeded)))
                ],
                name: "shuttleNeeded",
                type: "select",
                required: true,
                title: $props.labels.transportationNeeded,
                placeholder: $props.labels.pleaseChoose,
                description: $props.labels.transportationNeededPlaneHint,
                labels: [
                                              $props.labels.yes + ':true',
                                              $props.labels.no + ':false'
                                          ]
              }, null, 8, ["modelValue", "title", "placeholder", "description", "labels"]),
              ($options.participant.shuttleNeeded === 'true')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.participant.arrivalFlightNo,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($options.participant.arrivalFlightNo) = $event)),
                      name: "arrivalFlightNo",
                      title: $props.labels.arrivalFlightNo,
                      class: "col-start-1",
                      onBlur: _cache[15] || (_cache[15] = ($event: any) => ($options.onFocusChange($event, 'arrivalFlightNo', $options.participant.arrivalFlightNo)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.participant.arrivalFlightFrom,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($options.participant.arrivalFlightFrom) = $event)),
                      name: "arrivalFlightFrom",
                      title: $props.labels.arrivalFlightFrom,
                      onBlur: _cache[17] || (_cache[17] = ($event: any) => ($options.onFocusChange($event, 'arrivalFlightFrom', $options.participant.arrivalFlightFrom)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.arrivalDateModel,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($options.arrivalDateModel) = $event)),
                      name: "arrivalDate",
                      title: $props.labels.arrivalDate,
                      type: "date",
                      onBlur: _cache[19] || (_cache[19] = ($event: any) => ($options.onFocusChange($event, 'arrivalTime', $options.participant.arrivalTime)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.arrivalTimeModel,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($options.arrivalTimeModel) = $event)),
                      name: "arrivalTime",
                      title: $props.labels.arrivalTime,
                      type: "time",
                      onBlur: _cache[21] || (_cache[21] = ($event: any) => ($options.onFocusChange($event, 'arrivalTime', $options.participant.arrivalTime)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.participant.departureFlightNo,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($options.participant.departureFlightNo) = $event)),
                      name: "departureFlightNo",
                      title: $props.labels.departureFlightNo,
                      class: "col-start-1",
                      onBlur: _cache[23] || (_cache[23] = ($event: any) => ($options.onFocusChange($event, 'departureFlightNo', $options.participant.departureFlightNo)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.participant.departureFlightTo,
                      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($options.participant.departureFlightTo) = $event)),
                      name: "departureFlightTo",
                      title: $props.labels.departureFlightTo,
                      onBlur: _cache[25] || (_cache[25] = ($event: any) => ($options.onFocusChange($event, 'departureFlightTo', $options.participant.departureFlightTo)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.departureDateModel,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($options.departureDateModel) = $event)),
                      name: "departureDate",
                      title: $props.labels.departureDate,
                      type: "date",
                      class: "col-start-1",
                      onBlur: _cache[27] || (_cache[27] = ($event: any) => ($options.onFocusChange($event, 'departureTime', $options.participant.departureTime)))
                    }, null, 8, ["modelValue", "title"]),
                    _createVNode(_component_VueFormInput, {
                      modelValue: $options.departureTimeModel,
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (($options.departureTimeModel) = $event)),
                      name: "departureTime",
                      title: $props.labels.departureTime,
                      type: "time",
                      onBlur: _cache[29] || (_cache[29] = ($event: any) => ($options.onFocusChange($event, 'departureTime', $options.participant.departureTime)))
                    }, null, 8, ["modelValue", "title"])
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "items"]),
        ($data.showRequired)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($props.labels.travelRequired), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, _toDisplayString($props.labels.contactDetails) + ":", 1),
      _createElementVNode("div", null, _toDisplayString($options.event.paName), 1),
      _createElementVNode("div", null, _toDisplayString($props.labels.mail) + ": " + _toDisplayString($options.event.paMail), 1),
      _createElementVNode("div", null, _toDisplayString($props.labels.phone) + ": " + _toDisplayString($options.event.paPhone), 1),
      _createElementVNode("div", null, _toDisplayString($props.labels.mobile) + ": " + _toDisplayString($options.event.paMobile), 1),
      _createElementVNode("p", {
        class: "text-para-s md:text-para-base mt-10 md:mt-16",
        innerHTML: $props.labels.requiredHint
      }, null, 8, _hoisted_12),
      _createElementVNode("button", {
        class: "flex flex-row-reverse items-center group w-full mt-8 md:mt-12",
        onClick: ($event: any) => ($options.onSubmit(validate))
      }, [
        _createVNode(_component_ArrowButton, {
          class: "w-10 h-10 flex-shrink-0",
          "show-ring-on-mobile": ""
        }),
        _createElementVNode("span", _hoisted_14, _toDisplayString($props.labels.next), 1)
      ], 8, _hoisted_13),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", {
          type: "button",
          class: "flex items-center group cursor-pointer mt-6",
          onClick: _cache[31] || (_cache[31] = 
//@ts-ignore
(...args) => ($options.toOverview && $options.toOverview(...args)))
        }, [
          _createElementVNode("span", _hoisted_16, _toDisplayString($props.labels.personalOverview), 1),
          _createVNode(_component_ArrowButton, {
            direction: "person",
            class: "w-10 h-10",
            "show-ring-on-mobile": ""
          })
        ])
      ])
    ]),
    _: 1
  }))
}