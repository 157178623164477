<template>
    <div>
        <div class="flex flex-col md:flex-row md:items-center bg-primary-50 text-para-s py-2 px-2 md:px-4 xl:px-8">
            <span class="font-semibold mb-1 md:mb-0 md:mr-4">{{ labels.headline }}</span>
            <div class="relative w-full md:w-auto mb-2 md:mb-0">
                <input v-model="searchTerm"
                       class="w-full text-para-s rounded focus:outline-none py-2 pl-8 md:pl-12 pr-4"
                       type="text"
                >
                <Icon name="search"
                      class="absolute left-2 md:left-4 top-2 md:top-3 w-4 h-4 text-primary-500 stroke-current"
                />
            </div>
            <span class="hidden md:inline flex-grow" />
            <p class="self-end md:self-auto">
                <span>{{ labels.prefix }} </span>
                <span class="font-semibold">{{ paged.length }}</span>
                <span> {{ labels.suffix }} </span>
                <span class="font-semibold">{{ filtered.length }}</span>
            </p>
        </div>
        <div class="flex flex-col overflow-x-auto mb-4">
            <table class="w-full text-para-xs md:text-para-s text-left table-fixed">
                <thead class="border-b border-black-200">
                <tr>
                    <th class="w-40 md:w-auto py-2 px-1 md:px-2 xl:px-3" @click="sortBy('name')">
                        <div class="flex items-center font-semibold">
                            <div class="mr-1" :class="getTextColor('name')">
                                <Icon name="arrow-right" class="w-4 h-4 transform origin-center stroke-current"
                                      :class="getRotation('name')"
                                />
                            </div>
                            <span>{{ labels.column1 }}</span>
                        </div>
                    </th>
                    <th class="w-32 py-2 px-1 md:px-2 xl:px-3" @click="sortBy('ownership')">
                        <div class="flex items-center font-semibold">
                            <div class="mr-1" :class="getTextColor('ownership')">
                                <Icon name="arrow-right" class="w-4 h-4 transform origin-center stroke-current"
                                      :class="getRotation('ownership')"
                                />
                            </div>
                            <span>{{ labels.column2 }}</span>
                        </div>
                    </th>
                    <th class="w-24 py-2 px-1 md:px-2 xl:px-3" @click="sortBy('status')">
                        <div class="flex items-center font-semibold">
                            <div class="mr-1" :class="getTextColor('status')">
                                <Icon name="arrow-right" class="w-4 h-4 transform origin-center stroke-current"
                                      :class="getRotation('status')"
                                />
                            </div>
                            <span>{{ labels.column3 }}</span>
                        </div>
                    </th>
                    <th class="w-32 xl:w-64 py-2 px-1 md:px-2 xl:px-3" @click="sortBy('dateinitial')">
                        <div class="flex items-center font-semibold">
                            <div class="mr-1" :class="getTextColor('dateinitial')">
                                <Icon name="arrow-right" class="w-4 h-4 transform origin-center stroke-current"
                                      :class="getRotation('dateinitial')"
                                />
                            </div>
                            <span>{{ labels.column4 }}</span>
                        </div>
                    </th>
                    <th class="w-32 xl:w-56 py-2 px-1 md:px-2 xl:px-3" @click="sortBy('currmaxheight')">
                        <div class="flex items-center justify-end font-semibold">
                            <div class="mr-1" :class="getTextColor('currmaxheight')">
                                <Icon name="arrow-right" class="w-4 h-4 transform origin-center stroke-current"
                                      :class="getRotation('currmaxheight')"
                                />
                            </div>
                            <span class="text-right">{{ labels.column5 }}</span>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(row, i) in paged" :key="i">
                    <tr :class="{
                            'hover:bg-primary-100 transition duration-100 ease-out': !detailModel[i],
                            'bg-black-50': i % 2 === 0 && !detailModel[i],
                            'bg-primary-500 text-white': detailModel[i]
                        }"
                    >
                        <td class="py-2 px-1 md:px-2 xl:px-3">
                            <div class="flex items-center">
                                <div class="cursor-pointer p-1 mr-1" @click="toggle(i)">
                                    <Icon name="chevron-down"
                                          class="w-2 h-2 stroke-current"
                                          :class="{'transform rotate-180 text-white': detailModel[i], 'text-black-400': !detailModel[i]}"
                                    />
                                </div>
                                <span>{{ row.name }}</span>
                            </div>
                        </td>
                        <td class="py-2 pl-6 md:pl-7 xl:pl-8 pr-1 md:pr-2 xl:pr-3">{{ row.ownership }}</td>
                        <td class="py-2 pl-6 md:pl-7 xl:pl-8 pr-1 md:pr-2 xl:pr-3">{{ row.status }}</td>
                        <td class="py-2 pl-6 md:pl-7 xl:pl-8 pr-1 md:pr-2 xl:pr-3">{{ row.dateinitial }}</td>
                        <td class="py-2 pl-6 md:pl-7 xl:pl-8 pr-1 md:pr-2 xl:pr-3 text-right">
                            {{ row.currmaxheight }}
                        </td>
                    </tr>
                    <tr v-show="detailModel[i]">
                        <td colspan="5" class="py-0 px-1 md:px-4 xl:px-8">
                            <table class="table-fixed">
                                <tr v-for="(value, key, j) in questions" :key="key">
                                    <td class="border-b border-primary-500 py-2">
                                        <div class="flex flex-col md:flex-row ">
                                            <div class="flex-grow flex font-semibold md:pr-4">
                                                <span class="w-4 mr-1 md:mr-2 xl:mr-3">{{ j + 1 }}.</span>
                                                <span>{{ value }}</span>
                                            </div>
                                            <div class="flex-shrink-0 w-full md:w-55 xl:w-107 pl-5 md:px-2 xl:px-3">
                                                {{ row[key] }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <Pagination v-model="currentPage" :pages="pages" />
    </div>
</template>

<script lang="ts">
import {TailingsData, TailingsLabels, TailingsQuestion, TailingsRow} from './TailingsTypes';
import Pagination from '../base/Pagination.vue';
import axios from 'axios';
import {PropType} from "vue";
import Icon from "../base/Icon.vue";

export default {
    components: {Icon, Pagination},
    props: {
        labels: {
            type: Object as PropType<TailingsLabels>, default: () => {
            }
        },
        questions: {type: Object as PropType<Array<TailingsQuestion>>, default: () => {}},
        pageSize: {type: Number, default: 20}
    },
    data() {
        return {
            region: '',
            data: [],
            currentPage: 1,
            detailModel: {},
            searchTerm: '',
            ascending: true,
            sortProperty: '',
            filterKeys: ['name', 'ownership', 'status', 'dateinitial', 'currmaxheight']
        };
    },
    computed: {
        paged(): TailingsRow[] {
            if (this.sorted.length <= this.pageSize) {
                return this.sorted;
            }
            return this.sorted.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
        },
        sorted(): TailingsRow[] {
            const sorted = this.filtered.slice(0).sort((j1, j2) => {
                if (j1[this.sortProperty] && j2[this.sortProperty]) {
                    if (!isNaN(j1[this.sortProperty]) && !isNaN(j2[this.sortProperty])) {
                        return Number(j1[this.sortProperty] - Number(j2[this.sortProperty]));
                    }
                    return j1[this.sortProperty].localeCompare(j2[this.sortProperty]);
                }
                return 0;
            });
            if (!this.ascending) {
                sorted.reverse();
            }
            return sorted;
        },
        filtered(): TailingsRow[] {
            if (this.searchTerm) {
                return this.data.filter(d => {
                    let result = false;
                    this.filterKeys.forEach(k => {
                        if (d[k] && d[k].toLowerCase().includes(this.searchTerm.toLowerCase())) {
                            result = true;
                        }
                    });
                    return result;
                });
            }
            return this.data;
        },
        pages(): number {
            return Math.ceil(this.filtered.length / this.pageSize);
        }
    },
    watch: {
        async searchTerm() {
            this.detailModel = [];
            this.currentPage = 1;
        },
        async currentPage() {
            this.detailModel = [];
        }
    },
    mounted(): void {
        axios.get('/.resources/gc/webresources/js/data.json')
            .then(res => {
                const data: TailingsData = res.data;
                this.region = this.getQueryVariable('r');
                this.data = data.data.filter(row => !this.region || row.region === this.region);
            })
            .catch(err => {
                console.log('Couldn\'t fetch table data');
                console.log(err);
            });
    },
    methods: {
        getRotation(property: string): Record<string, boolean> {
            return {
                'rotate-90': this.sortProperty === property && !this.ascending,
                '-rotate-90': (this.sortProperty === property && this.ascending) || this.sortProperty !== property
            };
        },
        getTextColor(property: string): Record<string, boolean> {
            return {
                'text-primary-500': this.sortProperty === property,
                'text-black-400': this.sortProperty !== property
            };
        },
        /**
         * Retrieves the specified query parameter's value from the url string.
         *
         * @param variable The desired parameter name
         */
        getQueryVariable(variable: string): string {
            const params = new URLSearchParams(window.location.search);
            return params.has(variable) ? params.get(variable) : '';
        },
        toggle(i: number): void {
            this.detailModel[i] = !this.detailModel[i];
        },
        sortBy(property: string) {
            if (property === this.sortProperty) {
                this.ascending = !this.ascending;
            } else {
                this.sortProperty = property;
                this.ascending = true;
            }
        }
    }
};
</script>
