import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["name", "onSubmit"]
const _hoisted_2 = { class: "text-black-600 text-para-xs ml-4" }
const _hoisted_3 = {
  ref: "stepContainer",
  class: "relative"
}
const _hoisted_4 = { class: "flex justify-center items-center" }
const _hoisted_5 = { class: "flex flex-col items-center" }
const _hoisted_6 = {
  class: "w-14 h-14 stroke-current text-primary-500",
  viewBox: "0 0 58 58",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  class: "w-14 h-14 stroke-current text-error",
  viewBox: "0 0 60 60",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 0,
  class: "text-para-s text-error text-center mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiStepFormHeader = _resolveComponent("MultiStepFormHeader")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, { as: "div" }, {
    default: _withCtx(({ handleSubmit }) => [
      _createElementVNode("form", {
        ref: "form",
        name: $props.name,
        onSubmit: _withModifiers(($event: any) => (handleSubmit($event, $options.next)), ["prevent"])
      }, [
        _createVNode(_component_MultiStepFormHeader, {
          steps: $props.steps,
          "current-step": $data.theCurrentStep
        }, null, 8, ["steps", "current-step"]),
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass(["flex items-center cursor-pointer w-fit mb-6", {'invisible': !$options.showBackNavigation || $data.sending}]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.previous && $options.previous(...args)))
        }, [
          _createVNode(_component_Icon, {
            name: "chevron-left",
            class: "w-3 h-3 text-black-300 stroke-current"
          }),
          _createElementVNode("span", _hoisted_2, _toDisplayString($props.labels.back) + " " + _toDisplayString($options.lastStepTitle), 1)
        ], 2),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {
            currentStep: $data.theCurrentStep,
            showNext: $data.theCurrentStep < ($props.steps.length - 1),
            handleNext: () => $options.next(),
            showSubmit: $data.theCurrentStep === ($props.steps.length - 1) && !$data.sending
          })
        ], 512), [
          [_vShow, !$data.sending && ($data.theCurrentStep < $props.steps.length || $props.steps.length === 0)]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(_component_LoadingSpinner, { class: "w-20 h-20 fill-current text-primary-500" })
        ], 512), [
          [_vShow, $data.sending]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          ($data.success)
            ? _renderSlot(_ctx.$slots, "result", {
                key: 0,
                data: $data.theResultData
              }, () => [
                (_openBlock(), _createElementBlock("svg", _hoisted_6, [
                  _createElementVNode("path", {
                    d: "m13.852 32 6.186 8.506c.234.34.55.62.918.818a2.714 2.714 0 0 0 2.424.07c.38-.176.712-.437.967-.763l19.8-24.276",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    class: _normalizeClass(["transition-all duration-1000 dasharray-25", {
                                    'dashoffset-25': !$data.animateIcon,
                                    'dashoffset-0': $data.animateIcon,
                                }])
                  }, null, 2),
                  _createElementVNode("path", {
                    d: "M29 57c15.464 0 28-12.536 28-28S44.464 1 29 1 1 13.536 1 29s12.536 28 28 28Z",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    class: _normalizeClass(["transition-all duration-1000 dasharray-50", {
                                      'dashoffset-50': !$data.animateIcon,
                                      'dashoffset-0': $data.animateIcon,
                                  }])
                  }, null, 2)
                ])),
                _createElementVNode("div", {
                  class: "prose md:prose-md xl:prose-xl mt-6",
                  innerHTML: $props.successMessage
                }, null, 8, _hoisted_7)
              ])
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_openBlock(), _createElementBlock("svg", _hoisted_8, [
                  _createElementVNode("path", {
                    d: "M30 58.123c15.533 0 28.125-12.592 28.125-28.125C58.125 14.464 45.533 1.873 30 1.873S1.875 14.464 1.875 29.998C1.875 45.53 14.467 58.123 30 58.123ZM18.75 41.248l22.5-22.5M41.25 41.248l-22.5-22.5",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    class: _normalizeClass(["transition-all duration-1000 dasharray-50", {
                                  'dashoffset-50': !$data.animateIcon,
                                  'dashoffset-0': $data.animateIcon,
                              }])
                  }, null, 2)
                ])),
                _createElementVNode("div", {
                  class: "prose md:prose-md xl:prose-xl mt-6",
                  innerHTML: $props.errorMessage
                }, null, 8, _hoisted_9),
                ($data.serverError)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString($data.serverError), 1))
                  : _createCommentVNode("", true)
              ], 64))
        ], 512), [
          [_vShow, !$data.sending && $data.theCurrentStep === $props.steps.length && $props.steps.length > 0]
        ])
      ], 40, _hoisted_1)
    ]),
    _: 3
  }))
}