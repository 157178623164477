<template>
    <a v-if="url"
       class="flex flex-row items-center justify-center text-para-xs md:text-para-s text-white rounded border border-transparent focus:outline-none transition-all duration-300 ease-out appearance-none"
       :class="classes"
       :aria-label="ariaLabel"
       :href="url"
    >
        {{ label }}
        <Icon v-if="!secondary"
              :name="icon"
              class="w-4 h-4 stroke-current transform transition-transform duration-100 ease-out group-hover:translate-x-1 group-focus:translate-x-1 ml-2" />
    </a>
    <button v-else
            :type="type"
            :disabled="disabled"
            class="flex flex-row items-center justify-center text-para-xs md:text-para-s text-white font-semibold rounded border border-transparent focus:outline-none transition-all duration-300 ease-out"
            :class="classes"
            :aria-label="ariaLabel"
            @click="$emit('click')"
            @keyup.enter="$emit('click')"
    >
        {{ label }}
        <Icon v-if="!secondary"
              :name="icon"
              class="w-4 h-4 stroke-current transform transition-transform duration-100 ease-out group-hover:translate-x-1 group-focus:translate-x-1 ml-2"/>
    </button>
</template>

<script lang="ts">

import Icon from "./Icon.vue";

export default {
    components: {Icon},
    props: {
        type: {type: String, default: 'button', validator(value: string) {
            // The value must match one of these strings
            return ['button', 'submit', 'reset'].includes(value);
        }},
        color: {type: String, default: 'black'},
        icon: {type: String, default: 'arrow-right'},
        secondary: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        url: {type: String},
        label: {type: String},
        ariaLabel: {type: String}
    },
    emits: ['click'],
    computed: {
        classes(): Record<string, boolean> {
            return {
                group: !this.disabled,
                'px-12 py-2.5': !this.secondary,
                'px-8 py-2': this.secondary,
                'bg-black-900 hover:bg-black-600 focus:bg-black-600 focus:border-primary-500 active:bg-black-500': !this.primaryColor,
                'bg-primary-500 hover:bg-primary-600 focus:bg-primary-600 focus:border-black-900 active:bg-primary-700': this.primaryColor,
                'disabled:bg-black-100 disabled:text-black-400': this.disabled && !this.primaryColor,
                'disabled:bg-primary-300 disabled:text-primary-500': this.disabled && this.primaryColor
            };
        },
        primaryColor(): boolean {
            return this.color === 'primary';
        }
    }
};
</script>
