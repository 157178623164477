<template>
    <div v-if="currentStep === 0">
        <h2 class="text-heading-2 md:text-heading-1 mt-12 md:mt-32">{{ labels.registration }}</h2>
        <p class="text-para-s md:text-para-xl mt-4 md:mt-2" v-html="labels.registrationDescription" />
        <div class="font-semibold text-para-s md:text-para-xl mt-10 md:mt-14 mb-4 md:mb-2">{{ labels.willParticipate }}:</div>
        <ToggleButtons v-model="yesNoModel" :items="{ yes: labels.yes, no: labels.no }" grow @yes="onParticipate" @no="onCancel" />
    </div>
    <div v-else-if="currentStep === 1">
        <GolfEventPersonalDataStep :labels="labels" />
    </div>
    <div v-else-if="currentStep === 2">
        <GolfEventParticipationStep :labels="labels" />
    </div>
    <div v-else-if="currentStep === 3">
        <GolfEventTravelDataStep :labels="labels" />
    </div>
    <div v-else-if="currentStep === 4">
        <GolfEventSummary :labels="labels" />
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {GolfEventLabels} from './GolfEventTypes';
import GolfEventSummary from "./GolfEventSummary.vue";
import GolfEventTravelDataStep from "./GolfEventTravelDataStep.vue";
import GolfEventParticipationStep from "./GolfEventParticipationStep.vue";
import GolfEventPersonalDataStep from "./GolfEventPersonalDataStep.vue";
import ToggleButtons from "../base/ToggleButtons.vue";

export default {
    components: {
        ToggleButtons,
        GolfEventPersonalDataStep, GolfEventParticipationStep, GolfEventTravelDataStep, GolfEventSummary},
    inject: ['currentStep', 'participant'],
    props: {
        labels: {
            type: Object as PropType<GolfEventLabels>,
            required: true
        }
    },
    emits: ['participate', 'cancel'],
    data() {
        return {
            yesNoModel: []
        };
    },
    methods: {
        onParticipate(): void {
            this.$emit('participate');
        },
        onCancel(): void {
            this.$emit('cancel');
        }
    }
};
</script>
