import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, Transition as _Transition, resolveDirective as _resolveDirective, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  ref: "wrapper",
  class: "relative flex flex-col text-white bg-black-800 w-full h-mobile-nav md:h-tablet-nav mt-14 md:mt-20"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full opacity-50 bg-black-900 pointer-events-none"
}
const _hoisted_4 = {
  key: 1,
  class: "absolute w-full h-full"
}
const _hoisted_5 = { class: "container h-full" }
const _hoisted_6 = {
  ref: "languageSelect",
  class: "flex justify-center items-center mt-18 xl:mt-28 z-10"
}
const _hoisted_7 = { class: "text-para-xs font-semibold mr-2" }
const _hoisted_8 = { class: "text-para-xs font-semibold ml-2" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  ref: "playButton",
  class: "mt-8 md:mt-6 xl:mt-7"
}
const _hoisted_12 = ["aria-label"]
const _hoisted_13 = {
  ref: "scrollIndicator",
  class: "absolute left-1/2 bottom-12 md:bottom-18 transform -translate-x-1/2 w-8",
  viewBox: "0 0 33 57",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_14 = { class: "absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none" }
const _hoisted_15 = {
  key: 2,
  class: "absolute w-full bottom-0 text-center z-10 transform translate-y-1/2"
}
const _hoisted_16 = ["aria-label"]
const _hoisted_17 = {
  key: 0,
  class: "w-16 h-16",
  viewBox: "0 0 64 64",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink"
}
const _hoisted_18 = {
  key: 1,
  class: "w-16 h-16",
  viewBox: "0 0 64 64",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_PlayButton = _resolveComponent("PlayButton")!
  const _component_VideoModal = _resolveComponent("VideoModal")!
  const _directive_scroll_reveal = _resolveDirective("scroll-reveal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      class: _normalizeClass(["absolute w-full h-full object-cover left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-500 -mt-px", [{ 'opacity-100': $data.active },{ 'opacity-0': !$data.active }]]),
      draggable: "false",
      autoplay: "",
      loop: "",
      muted: "",
      playsinline: "",
      src: $props.bgVideoLink
    }, null, 10, _hoisted_2),
    (!$props.hideGradient)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    (!$data.overlayOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(["relative flex flex-col items-center h-full transition-all duration-500", [{ 'opacity-100': $data.active },{ 'opacity-0': !$data.active }]])
            }, [
              _withDirectives(_createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString($props.seeLabel), 1),
                _createVNode(_component_Dropdown, {
                  modelValue: $data.currentLang,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentLang) = $event)),
                  class: "min-w-40 md:min-w-50",
                  title: "language"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.locales, (lang, locale) => {
                      return (_openBlock(), _createBlock(_component_DropdownItem, {
                        key: locale,
                        label: lang,
                        value: locale
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createElementVNode("p", _hoisted_8, _toDisplayString($props.see2Label), 1)
              ], 512), [
                [_vShow, !$props.hideLangSwitch]
              ]),
              ($props.title)
                ? (_openBlock(), _createElementBlock("h1", {
                    key: 0,
                    ref: "title",
                    class: "text-center text-heading-4 md:text-heading-2 xl:text-stage-title-desktop tracking-tighter md:tracking-tightest mt-12 xl:mt-24",
                    innerHTML: $props.title
                  }, null, 8, _hoisted_9))
                : _createCommentVNode("", true),
              _createElementVNode("p", {
                ref: "note",
                class: "text-center text-para-xs md:text-para-base xl:text-para-lg mt-5 xl:mt-8",
                innerHTML: $props.subTitle
              }, null, 8, _hoisted_10),
              ($props.video || $props.link)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_PlayButton, {
                        absolute: false,
                        "aria-label": $options.$globalLabels.play,
                        onClick: $options.openVideoModal
                      }, null, 8, ["aria-label", "onClick"])
                    ], 512),
                    _createElementVNode("span", {
                      ref: "watchVideo",
                      class: "text-para-xs md:text-para-s mt-2 md:mt-4"
                    }, _toDisplayString($props.watchLabel), 513)
                  ], 64))
                : _createCommentVNode("", true),
              _withDirectives(_createElementVNode("button", {
                type: "button",
                "aria-label": $options.$globalLabels.next,
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.scrollDown && $options.scrollDown(...args)))
              }, [
                (_openBlock(), _createElementBlock("svg", _hoisted_13, _cache[4] || (_cache[4] = [
                  _createStaticVNode("<path d=\"M11 42.5L15.7929 47.2929C16.1834 47.6834 16.8166 47.6834 17.2071 47.2929L22 42.5\" stroke=\"white\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><rect x=\"0.75\" y=\"0.75\" width=\"31.5\" height=\"55.5\" rx=\"15.75\" stroke=\"white\" stroke-width=\"1.5\"></rect><circle cx=\"16.5\" cy=\"15.5\" r=\"1.5\" fill=\"white\"></circle><circle cx=\"16.5\" cy=\"23.5\" r=\"1.5\" fill=\"white\"></circle><circle cx=\"16.5\" cy=\"31.5\" r=\"1.5\" fill=\"white\"></circle>", 5)
                ]), 512))
              ], 8, _hoisted_12), [
                [_vShow, !$props.hideScrollDown]
              ])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_VideoModal, {
      "video-id": $data.currentVideo,
      "account-id": $props.accountId,
      "player-id": $props.playerId,
      onClose: _cache[2] || (_cache[2] = ($event: any) => ($data.currentVideo = null))
    }, null, 8, ["video-id", "account-id", "player-id"]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_Transition, { name: "slide" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", {
            class: _normalizeClass(["absolute left-0 top-0 w-full h-full bg-black-900 opacity-50 pointer-events-auto z-10", { 'grid grid-cols-2': !$props.editMode }])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2), [
            [_vShow, $data.overlayOpen]
          ])
        ]),
        _: 3
      })
    ]),
    ((_ctx.$slots.default && _ctx.$slots.default.length > 0) || $props.editMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _withDirectives((_openBlock(), _createElementBlock("button", {
            class: "focus-primary w-22 h-22 p-3 rounded-full shadow-xl bg-white",
            "aria-label": $options.$globalLabels.open,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.openOverlay && $options.openOverlay(...args)))
          }, [
            (!$data.overlayOpen)
              ? (_openBlock(), _createElementBlock("svg", _hoisted_17, _cache[5] || (_cache[5] = [
                  _createStaticVNode("<defs><filter id=\"filter-1\"><feColorMatrix in=\"SourceGraphic\" type=\"matrix\" values=\"0 0 0 0 0.000000 0 0 0 0 0.686275 0 0 0 0 0.666667 0 0 0 1.000000 0\"></feColorMatrix></filter></defs><g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(-687.000000, -822.000000)\"><g transform=\"translate(675.000000, 810.000000)\"><g transform=\"translate(12.000000, 12.000000)\"><rect stroke=\"#00AFAA\" stroke-width=\"1.01587302\" fill=\"#FFFFFF\" x=\"0.507936508\" y=\"0.507936508\" width=\"62.984127\" height=\"62.984127\" rx=\"31.4920635\"></rect><g filter=\"url(#filter-1)\"><g transform=\"translate(17.000000, 17.000000)\"><rect stroke=\"#00AFAA\" x=\"0.5\" y=\"0.5\" width=\"7\" height=\"7\"></rect><rect stroke=\"#00AFAA\" x=\"11.5\" y=\"0.5\" width=\"7\" height=\"7\"></rect><rect stroke=\"#00AFAA\" x=\"22.5\" y=\"0.5\" width=\"7\" height=\"7\"></rect><rect stroke=\"#00AFAA\" x=\"0.5\" y=\"11.5\" width=\"7\" height=\"7\"></rect><rect stroke=\"#00AFAA\" x=\"11.5\" y=\"11.5\" width=\"7\" height=\"7\"></rect><rect stroke=\"#00AFAA\" x=\"22.5\" y=\"11.5\" width=\"7\" height=\"7\"></rect><rect stroke=\"#00AFAA\" x=\"0.5\" y=\"22.5\" width=\"7\" height=\"7\"></rect><rect stroke=\"#00AFAA\" x=\"11.5\" y=\"22.5\" width=\"7\" height=\"7\"></rect><rect stroke=\"#00AFAA\" x=\"22.5\" y=\"22.5\" width=\"7\" height=\"7\"></rect></g></g></g></g></g></g>", 2)
                ])))
              : (_openBlock(), _createElementBlock("svg", _hoisted_18, _cache[6] || (_cache[6] = [
                  _createStaticVNode("<g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(-687.000000, -822.000000)\" stroke=\"#00AFAA\"><g transform=\"translate(675.000000, 810.000000)\"><g transform=\"translate(12.000000, 12.000000)\"><rect stroke-width=\"1.01587302\" fill=\"#FFFFFF\" x=\"0.507936508\" y=\"0.507936508\" width=\"62.984127\" height=\"62.984127\" rx=\"31.4920635\"></rect><g transform=\"translate(16.000000, 16.000000)\" stroke-linecap=\"square\" stroke-width=\"1.06666667\"><line id=\"Line-2\" x1=\"-5.70114943\" y1=\"16.3678161\" x2=\"37.7011494\" y2=\"15.6321839\" transform=\"translate(16.000000, 16.000000) rotate(-45.000000) translate(-16.000000, -16.000000) \"></line><line id=\"Line-2\" x1=\"-5.70114943\" y1=\"15.6321839\" x2=\"37.7011494\" y2=\"16.3678161\" transform=\"translate(16.000000, 16.000000) rotate(45.000000) translate(-16.000000, -16.000000) \"></line></g></g></g></g></g>", 1)
                ])))
          ], 8, _hoisted_16)), [
            [_directive_scroll_reveal]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}