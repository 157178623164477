import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-para-s font-semibold" }
const _hoisted_2 = {
  key: 0,
  "aria-hidden": "true"
}
const _hoisted_3 = {
  key: 1,
  class: "text-black-500 font-normal"
}
const _hoisted_4 = { class: "relative text-black-500" }
const _hoisted_5 = ["name", "aria-required", "aria-invalid", "aria-describedby"]
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: $options.model[$props.name],
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.model[$props.name]) = $event)),
    name: $props.title,
    rules: $options.rules,
    mode: "eager",
    disabled: $options.validationDisabled,
    as: "div"
  }, {
    default: _withCtx(({ field, errors, value }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString($props.title), 1),
        ($props.required)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, " *"))
          : _createCommentVNode("", true),
        ($props.suffix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.suffix), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        ($props.type !== 'number')
          ? (_openBlock(), _createElementBlock("input", _mergeProps({
              key: 0,
              class: ["w-full text-para-s border rounded focus:outline-none py-2.5 pr-4", {
                       'pl-4': $props.type !== 'email',
                       'pl-10': $props.type === 'email',
                       'border-black-200 focus:border-primary-500': errors.length === 0,
                       'border-error': errors.length > 0
                   }],
              type: "text",
              name: $props.name,
              "aria-required": $props.required,
              "aria-invalid": errors.length > 0,
              "aria-describedby": errors.length > 0 ? `${$props.name}-error` : ''
            }, field), null, 16, _hoisted_5))
          : (_openBlock(), _createBlock(_component_NumberInput, _mergeProps({ key: 1 }, field, {
              modelValue: $options.model[$props.name],
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.model[$props.name]) = $event)),
              class: ["w-full text-para-s border rounded focus:outline-none py-2.5 pr-4", {
                             'pl-4': $props.type !== 'email',
                             'pl-10': $props.type === 'email',
                             'border-black-200 focus:border-primary-500': errors.length === 0,
                             'border-error': errors.length > 0
                         }],
              name: $props.name,
              "aria-required": $props.required,
              "aria-invalid": errors.length > 0,
              "aria-describedby": errors.length > 0 ? `${$props.name}-error` : '',
              options: $options.options
            }), null, 16, ["modelValue", "class", "name", "aria-required", "aria-invalid", "aria-describedby", "options"])),
        ($props.type === 'email')
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 2,
              name: "mail",
              class: "absolute stroke-current w-4 h-10 md:h-11 left-4 top-px"
            }))
          : _createCommentVNode("", true)
      ]),
      (errors.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            id: `${$props.name}-error`,
            class: "text-error text-para-xs mt-1 md:mt-2"
          }, _toDisplayString(errors[0]), 9, _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "name", "rules", "disabled"]))
}