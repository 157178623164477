import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "hidden md:flex items-center justify-end mt-2 mb-6 md:mt-8 md:mb-20" }
const _hoisted_2 = { class: "text-para-s font-semibold" }
const _hoisted_3 = { class: "text-heading-2 md:text-heading-1 mt-12 md:mt-0" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "grid grid-cols-1 md:grid-cols-2 gap-8 mt-10 md:mt-14" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "font-semibold text-para-s line-clamp-1" }
const _hoisted_9 = { class: "md:hidden border-t border-black-200 mt-12" }
const _hoisted_10 = { class: "text-para-s font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_VueFormSelection = _resolveComponent("VueFormSelection")!
  const _component_VueFormInput = _resolveComponent("VueFormInput")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, { as: "div" }, {
    default: _withCtx(({ validate }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          type: "button",
          class: "flex items-center group cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toOverview && $options.toOverview(...args)))
        }, [
          _createElementVNode("span", _hoisted_2, _toDisplayString($props.labels.personalOverview), 1),
          _createVNode(_component_ArrowButton, {
            direction: "person",
            class: "w-10 h-10"
          })
        ])
      ]),
      _createElementVNode("h2", _hoisted_3, _toDisplayString($props.labels.personalData), 1),
      _createElementVNode("p", {
        class: "text-para-s md:text-para-xl mt-4 md:mt-2",
        innerHTML: $props.labels.personalDataDescription
      }, null, 8, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_VueFormSelection, {
          name: "gender",
          title: $props.labels.gender,
          placeholder: $props.labels.pleaseChoose,
          type: "select",
          labels: [
                                  $props.labels.genderFemale + ':FEMALE',
                                  $props.labels.genderMale + ':MALE',
                                  $props.labels.unknown + ':UNKNOWN'
                              ],
          modelValue: $options.participant.gender,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => (($options.participant.gender) = $event)),
            _cache[2] || (_cache[2] = ($event: any) => ($options.onFocusChange(true, 'gender', $options.participant.gender)))
          ]
        }, null, 8, ["title", "placeholder", "labels", "modelValue"]),
        _createVNode(_component_VueFormInput, {
          name: "title",
          title: $props.labels.namePrefix,
          modelValue: $options.participant.title,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($options.participant.title) = $event)),
          onBlur: _cache[4] || (_cache[4] = ($event: any) => ($options.onFocusChange($event, 'title', $options.participant.title)))
        }, null, 8, ["title", "modelValue"]),
        _createVNode(_component_VueFormInput, {
          name: "firstName",
          title: $props.labels.firstName,
          disabled: true,
          modelValue: $options.participant.firstName,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($options.participant.firstName) = $event))
        }, null, 8, ["title", "modelValue"]),
        _createVNode(_component_VueFormInput, {
          name: "lastName",
          title: $props.labels.lastName,
          disabled: true,
          modelValue: $options.participant.lastName,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($options.participant.lastName) = $event))
        }, null, 8, ["title", "modelValue"]),
        _createVNode(_component_VueFormInput, {
          name: "company",
          title: $props.labels.company,
          disabled: true,
          modelValue: $options.participant.company,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($options.participant.company) = $event))
        }, null, 8, ["title", "modelValue"]),
        _createVNode(_component_VueFormInput, {
          name: "mail",
          title: $props.labels.mailAddress,
          disabled: true,
          type: "email",
          modelValue: $options.participant.mail,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($options.participant.mail) = $event))
        }, null, 8, ["title", "modelValue"]),
        _createVNode(_component_VueFormInput, {
          name: "phone",
          title: $props.labels.mobilePhone,
          description: $props.labels.mobilePhoneHint,
          required: true,
          rules: {required: true},
          type: "tel",
          modelValue: $options.participant.phone,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($options.participant.phone) = $event)),
          onBlur: _cache[10] || (_cache[10] = ($event: any) => ($options.onFocusChange($event, 'phone', $options.participant.phone)))
        }, null, 8, ["title", "description", "modelValue"]),
        _createVNode(_component_VueFormInput, {
          name: "paMail",
          title: $props.labels.paMail,
          type: "email",
          rules: {email: true},
          modelValue: $options.participant.paMail,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($options.participant.paMail) = $event)),
          onBlur: _cache[12] || (_cache[12] = ($event: any) => ($options.onFocusChange($event, 'paMail', $options.participant.paMail)))
        }, null, 8, ["title", "modelValue"])
      ]),
      _createElementVNode("p", {
        class: "text-para-s md:text-para-base mt-10 md:mt-16",
        innerHTML: $props.labels.requiredHint
      }, null, 8, _hoisted_6),
      _createElementVNode("button", {
        type: "button",
        class: "flex flex-row-reverse items-center group w-full mt-8 md:mt-12",
        onClick: ($event: any) => ($options.onSubmit(validate))
      }, [
        _createVNode(_component_ArrowButton, {
          class: "w-10 h-10 flex-shrink-0",
          "show-ring-on-mobile": ""
        }),
        _createElementVNode("span", _hoisted_8, _toDisplayString($props.labels.next), 1)
      ], 8, _hoisted_7),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          type: "button",
          class: "flex items-center group cursor-pointer mt-6",
          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => ($options.toOverview && $options.toOverview(...args)))
        }, [
          _createElementVNode("span", _hoisted_10, _toDisplayString($props.labels.personalOverview), 1),
          _createVNode(_component_ArrowButton, {
            direction: "person",
            class: "w-10 h-10",
            "show-ring-on-mobile": ""
          })
        ])
      ])
    ]),
    _: 1
  }))
}