<template>
    <button v-if="show && isTab" @click="onClick">
        <slot :active="active" />
    </button>
    <div v-else-if="show && !isTab">
        <slot :active="active" />
    </div>
</template>

<script lang="ts">

let uuid = 0;

export default {
    inject: ['registerSlide', '$contextPath'],
    props: {
        id: {type: String},
        title: {type: String},
        subTitle: {type: String},
        kicker: {type: String},
        text: {type: String},
        url: {type: String},
        label: {type: String},
        image: {type: String},
        editMode: {type: Boolean, default: false},
        isTab: {type: Boolean, default: false}
    },
    emits: ['click'],
    data() {
        return {
            show: true,
            active: false
        };
    },
    beforeCreate() {
        this.uuid = uuid.toString();
        uuid += 1;
    },
    mounted(): void {
        if (!this.editMode) {
            this.registerSlide(this);
        }
    },
    methods: {
        onClick() {
            this.$emit('click', this);
        },
        /**
         * This method is used in GcSwiper#slideClicked.
         */
        _id(): string {
            return this.id ? this.id : `${this.uuid}`;
        }
    }
};
</script>
