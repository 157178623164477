import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-center md:justify-end pt-2.5 pb-4 md:py-0" }
const _hoisted_2 = { class: "font-semibold text-para-xs mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return ($props.accounts.length > 1)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({'container': !$props.inContentGroup})
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString($props.labelSocialFilter), 1),
          _createVNode(_component_Dropdown, {
            modelValue: $data.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.model) = $event)),
            class: "min-w-50 -my-px",
            name: "year",
            title: $data.model,
            rounded: $options.mobile,
            "default-value": $props.modelValue
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.accounts, (account, index) => {
                return (_openBlock(), _createBlock(_component_DropdownItem, {
                  key: index,
                  label: account.label,
                  value: account.value
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue", "title", "rounded", "default-value"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}