import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-para-s font-semibold" }
const _hoisted_2 = {
  key: 0,
  "aria-hidden": "true"
}
const _hoisted_3 = {
  key: 1,
  class: "text-black-500 font-normal"
}
const _hoisted_4 = { class: "flex flex-row items-center text-black-500" }
const _hoisted_5 = ["value", "name", "aria-required", "aria-invalid", "aria-describedby"]
const _hoisted_6 = ["for"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: $options.model[$props.name],
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.model[$props.name]) = $event)),
    name: $props.name,
    rules: $options.rules,
    mode: "eager",
    disabled: $options.validationDisabled,
    as: "div"
  }, {
    default: _withCtx(({ field, errors, value }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString($props.title), 1),
        ($props.required)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, " *"))
          : _createCommentVNode("", true),
        ($props.suffix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.suffix), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("input", _mergeProps({
          class: ["w-5 h-5 relative text-black-900 text-para-xs md:text-para-s", {
                       'border-black-200 focus:border-primary-500': errors.length === 0,
                       'border-error': errors.length > 0
                   }],
          type: "checkbox",
          value: $props.checkboxValue,
          name: $props.name,
          "aria-required": $props.required,
          "aria-invalid": errors.length > 0,
          "aria-describedby": errors.length > 0 ? `${$props.name}-error` : ''
        }, field), null, 16, _hoisted_5),
        _createElementVNode("label", {
          for: $props.name,
          class: "relative"
        }, [
          _createElementVNode("span", {
            class: "text-para-xs pl-5 md:pl-6",
            innerHTML: $props.label
          }, null, 8, _hoisted_7)
        ], 8, _hoisted_6)
      ]),
      (errors.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            id: `${$props.name}-error`,
            class: "text-error text-para-xs mt-1 md:mt-2"
          }, _toDisplayString(errors[0]), 9, _hoisted_8))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "name", "rules", "disabled"]))
}