import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col bg-white" }
const _hoisted_2 = { class: "flex justify-end" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { ref: "contentContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_GcButton = _resolveComponent("GcButton")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("dialog", {
          ref: "dialogEL",
          class: _normalizeClass(["w-full max-h-full backdrop:bg-black-900 backdrop:bg-opacity-20 backdrop:backdrop-filter backdrop:backdrop-blur", {'max-w-content-modal': !$props.narrow, 'max-w-content-modal-narrow': $props.narrow}])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                class: "flex justify-center items-center w-10 h-10 bg-primary-500 hover:bg-black-900 focus:bg-black-900 cursor-pointer",
                autofocus: "",
                "aria-label": $options.$globalLabels.close,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.hide && $options.hide(...args)))
              }, [
                _createVNode(_component_Icon, {
                  name: "close",
                  class: "w-4 h-4 text-white stroke-current"
                })
              ], 8, _hoisted_3)
            ]),
            _createElementVNode("div", {
              ref: "parentContainer",
              class: _normalizeClass(["flex flex-col max-h-full prose md:prose-md overflow-y-auto px-4 md:px-6 xl:px-32", {'pb-4 md:pb-12 xl:pb-16': !$props.buttonLabel}]),
              tabindex: "-1"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default")
              ], 512)
            ], 2),
            _renderSlot(_ctx.$slots, "buttons", {}, () => [
              ($props.buttonLabel)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["flex px-4 md:px-6 xl:px-32 pt-4.5 md:pt-12 pb-4.5 md:pb-12 xl:pb-16", {'justify-center': !$props.narrow, 'shadow-top': $data.scrollable}])
                  }, [
                    _createVNode(_component_GcButton, {
                      class: _normalizeClass({'px-14 md:px-18': !$props.narrow}),
                      label: $props.buttonLabel,
                      secondary: "",
                      onClick: $options.ctaClick
                    }, null, 8, ["class", "label", "onClick"])
                  ], 2))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2), [
          [_vShow, $data.visible]
        ])
      ]),
      _: 3
    })
  ]))
}