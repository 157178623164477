<script lang="ts">
import {h, PropType} from 'vue';
import Swiper, {EffectFade} from 'swiper';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js';

Swiper.use([EffectFade]);

export default {
    provide() {
        return {
            registerSlide: this.register
        };
    },
    props: {
        direction: {type: String, default: 'horizontal', validator(value: string) {
            // The value must match one of these strings
            return ['horizontal', 'vertical'].includes(value);
        }},
        contrast: {type: Boolean, default: false},
        loop: {type: Boolean, default: false},
        gap: {  // @Prop({ default: 0 }) gap: number | { [breakpoint: string]: number };
            type: [Number, Object as PropType<{ [breakpoint: string]: number }>],
            default: 0
        },
        containerClasses: {type: String, default: ''},
        slideClasses: {type: String, default: ''},
        start: {type: Number, default: 0},
        speed: {type: Number, default: 300},
        slidesOffsetAfter: {  // @Prop({ default: 0 }) slidesOffsetAfter!: number | { [breakpoint: string]: number };
            type: [Number, Object as PropType<{ [breakpoint: string]: number }>],
            default: 0
        },
        showThumbs: {type: Boolean, default: false},
        fullHeightSlides: {type: Boolean, default: false},
        fullWidthSlides: {type: Boolean, default: false},
        static: {type: Boolean, default: false},
        editMode: {type: Boolean, default: false},
        slidesPerView: {  // @Prop({ default: 'auto' }) slidesPerView: number | 'auto' | { [breakpoint: string]: number | 'auto' };
            type: [Number, String, Object as PropType<{ [breakpoint: string]: number | 'auto' }>],
            default: 'auto'
        },
        hideOverflow: {type: Boolean, default: true},
        isTab: {type: Boolean, default: false}
    },
    data() {
        return {
            slides: [],
            swiper: null,
            index: this.start,
            options: {
                watchOverflow: true,
                observer: true,
                loop: this.loop,
                speed: this.speed,
                direction: this.direction,
                touchReleaseOnEdges: true,
                allowSlidePrev: false,
                initialSlide: this.start,
                observeParents: true,
                watchSlidesProgress: true,
                watchSlidesVisibility: true,
                shortSwipes: false,
                longSwipesRatio: 0.1,
                longSwipesMs: 100,
                noSwipingSelector: 'video',
                effect: this.direction === 'vertical' ? 'fade' : 'slide',
                fadeEffect: {
                    crossFade: true
                }
            },

            twConfig: resolveConfig(tailwindConfig),

            observing: false,
            prevVisible: false,
            nextVisible: false,
            isPlaying: false
        };
    },
    computed: {
        numSlides(): number {
            return this.$slots.default ? this.$slots.default().filter(x => x.type).length : 0;
        },
        currentSlide(): any {
            const index = (this.index - this.start) < 0 ? this.slides.length - 1 : this.index - this.start;
            if (this.slides.length && this.slides.length > index) {
                return this.slides[index];
            }
            return null;
        },
        additionalContainerClasses(): string {
            const classes = [this.containerClasses];
            if (this.hideOverflow) {
                classes.push('overflow-hidden');
            }
            return classes.filter(x => x.length).join(' ');
        },
        wrapperClasses(): string {
            if (typeof this.direction === 'string') {
                return this.direction === 'vertical' ? 'flex-col w-full h-full' : 'flex-row';
            }
            // md:flex-col lg:flex-col xl:flex-col md:flex-row lg:flex-row xl:flex-row
            const classes = [];
            Object.entries(this.direction).forEach(x => {
                const bp = this.breakpointSize(x[0]);
                const dir = x[1] === 'vertical' ? 'flex-col' : 'flex-row';
                if (bp > 0) {
                    classes.push(`${x[0]}:${dir}`);
                } else {
                    classes.push(dir);
                }
            });
            return classes.join(' ');
        }
    },
    mounted(): void {
        if (this.editMode) {
            return;
        }

        this.setBreakpointOption(this.direction, 'direction');
        this.setBreakpointOption(this.gap, 'spaceBetween');
        this.setBreakpointOption(this.slidesPerView, 'slidesPerView');
        this.setBreakpointOption(this.slidesOffsetAfter, 'slidesOffsetAfter');

        this.swiper = new Swiper(this.$refs['container'], this.options);
        this.swiper.on('activeIndexChange', this.indexChanged);

        this.observeSlideVisibility();
    },
    updated(): void {
        if (!this.observing) {
            this.observeSlideVisibility();
        }
    },
    methods: {
        indexChanged({ activeIndex, realIndex }): void {
            this.index = this.loop ? realIndex : activeIndex;
        },
        next(): void {
            if (this.swiper) {
                if (this.loop) {
                    this.index = this.index + 1 >= this.slides.length ? 0 : this.index + 1;
                } else if (this.index + 1 < this.slides.length) {
                    this.index++;
                }
                this.swiper.slideNext();
            }
        },
        prev(): void {
            if (this.swiper) {
                if (this.loop) {
                    this.index = this.index > 0 ? this.index - 1 : this.slides.length - 1;
                } else if (this.index > 0) {
                    this.index--;
                }
                this.swiper.slidePrev();
            }
        },
        goTo(index: number): void {
            if (this.swiper) {
                this.swiper.slideTo(index);
                this.index = index;
            }
        },
        /**
         * This method is called by slides.
         *
         * @param slide A slide that wants to register itself
         */
        register(slide: any): void {
            this.slides.push(slide);
        },
        slideClicked(e) {
            const index = this.slides.findIndex(x => x._id() === e.target.dataset.id);
            if (index >= 0 && index < this.slides.length) {
                this.goTo(index);
            }
        },
        breakpointSize(name: string): number {
            if (Object.prototype.hasOwnProperty.call(this.twConfig.theme.screens, name)) {
                return parseInt(this.twConfig.theme.screens[name]);
            }
            return -1;
        },
        setBreakpointOption(value: string | number | object, name: string): void {
            const breakpoints = this.options.breakpoints || {};
            if (typeof value === 'number' || typeof value === 'string') {
                this.options[name] = value;
                return;
            }
            Object.entries(value).forEach(x => {
                const bp = this.breakpointSize(x[0]);
                if (bp > 0) {
                    if (!Object.prototype.hasOwnProperty.call(breakpoints, bp)) {
                        breakpoints[bp] = {};
                    }
                    breakpoints[bp][name] = x[1];
                } else {
                    this.options[name] = x[1];
                }
            });
            if (!this.options.breakpoints) {
                this.options.breakpoints = breakpoints;
            }
        },
        observeSlideVisibility(): void {
            if (this.slides && this.slides.length > 0) {
                this.slides
                    .map(slide => slide.$el.parentElement)
                    .forEach(el => {
                        const options: IntersectionObserverInit = {
                            threshold: 0.95,
                            root: this.$refs['container']
                        };
                        // eslint-disable-next-line no-undef
                        const observer = new IntersectionObserver(entries => {
                            const isLeft = entries[0].target.isSameNode(this.slides[0].$el.parentElement);
                            const isRight = entries[0].target.isSameNode(this.slides[this.slides.length - 1].$el.parentElement);
                            if (isLeft) this.prevVisible = !entries[0].isIntersecting;
                            if (isRight) this.nextVisible = !entries[0].isIntersecting;
                        }, options);
                        observer.observe(el);
                    });
                this.observing = true;
            }
        },
        childElements(): [] {
            if (Array.isArray(this.$slots.default())) {
                return this.$slots.default();
            } else {
                return this.$slots.default().children;
            }
        }
    },
    render() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        return h('div', { class: 'w-full' }, [
            // child 1
            h('div', {
                class: `swiper-container w-full ${this.additionalContainerClasses}`,
                ref: 'container'
            }, [
                h('ul', {
                    class: `swiper-wrapper flex flex-nowrap ${this.wrapperClasses}`,
                    role: this.isTab ? 'tablist' : null
                }, this.childElements()
                    .filter(node => node.type)
                    .map((el, i) => {
                        return h('li', {
                            class: `swiper-slide flex-shrink-0 max-w-full ${this.slideClasses}${this.index === i ? ' slide-active' : ''}`,
                            inert: this.fullWidthSlides && this.index !== i,
                            onClick: (e) => {
                                this.slideClicked(e);
                            }
                        },
                        [el]);
                    })
                )
            ]),
            // child 2
            (this.$slots.pagination && !this.editMode) ? this.$slots.pagination({
                index: this.index + 1,
                total: this.numSlides,
                teaser: this.currentSlide,
                go: this.goTo,
                isPlaying: this.isPlaying
            }) : null,
            // child 3
            (this.$slots.controls) ? this.$slots.controls({
                prev: this.prev,
                next: this.next,
                index: this.index,
                show: this.prevVisible || this.nextVisible,
                prevVisible: this.prevVisible,
                nextVisible: this.nextVisible,
                isPlaying: this.isPlaying
            }) : null
        ]);
    }
};
</script>
