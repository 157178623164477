<template>
    <div ref="observable"
         class="relative w-full h-full bg-white overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out"
    >
        <div class="relative min-h-30 overflow-hidden" :class="[!isVertical ? 'h-60 md:h-auto' : '']">
            <img v-if="hasImage && show"
                 alt=""
                 class="object-cover w-full transform group-hover:scale-110 transition-all duration-300 ease-out"
                 :class="[!isVertical ? 'h-full md:h-auto' : 'h-auto']"
                 :src="data.asset || data.url"
                 loading="lazy"
                 @load="imageLoaded"
            >
            <div class="absolute left-0 top-0 w-full h-full group-hover:bg-none bg-gradient-to-b opacity-40 from-black-900 pointer-events-none" />
        </div>
        <div class="absolute left-6 top-6 right-6 flex flex-row text-para-xs group-hover:text-primary-500"
             :class="classes"
        >
            <svg class="w-4 h-4 fill-current mr-2 transition-all duration-300 ease-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.786 0H1.296C.557 0 0 .554 0 1.292v21.323C0 23.446.556 24 1.297 24h11.578v-9.323h-3.15v-3.6h3.15V8.4c0-3.138 1.945-4.8 4.631-4.8 1.297 0 2.501.092 2.779.185v3.23H18.34c-1.482 0-1.76.739-1.76 1.754v2.308h3.612l-.463 3.6h-3.15V24h6.114c.741 0 1.297-.554 1.297-1.292V1.292C24.083.554 23.527 0 22.786 0z" />
            </svg>
            <h6 v-if="data.accountName"
                :class="{'invisible': !showChannel}"
                class="flex-grow font-semibold transition-all duration-300 ease-out"
            >
                {{ data.accountName }}
            </h6>
            <span class="transition-all duration-300 ease-out" :class="differenceClasses">{{ data.difference }}</span>
        </div>
        <div class="px-6 pt-4 pb-6">
            <p class="text-para-s tracking-tight line-clamp-6" v-html="data.caption" />
        </div>
        <a :href="data.link"
           class="absolute left-0 right-0 top-0 bottom-0"
           :aria-label="data.accountName"
           target="_blank"
        />
    </div>
</template>

<script lang="ts">

import {PropType} from 'vue';
import debounce from 'lodash/debounce';
import Utils from '../../../utils/Utils';

export default {
    inject: ['isVertical', 'showChannel'],
    props: {
        data: {type: Object as PropType<Record<string, string>>}
    },
    data() {
        return {
            redraw: debounce(this.$redrawVueMasonry, 300),
            show: false
        };
    },
    computed: {
        classes(): Record<string, boolean> {
            return {
                'text-black-900': !this.hasImage,
                'text-white': this.hasImage
            };
        },
        differenceClasses(): Record<string, boolean> {
            return {
                'text-black-500 group-hover:text-primary-500': !this.hasImage,
                'text-white group-hover:text-primary-500': this.hasImage
            };
        },
        hasImage(): boolean {
            return this.data?.mediaType === 'photo' || this.data?.mediaType === 'video' || this.data?.mediaType === 'album';
        }
    },
    mounted(): void {
        Utils.observeIntersection(this.$refs.observable, () => { this.show = true; });
    },
    methods: {
        imageLoaded(): void {
            this.redraw();
        }
    }
};
</script>
