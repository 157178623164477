<template>
    <div>
        <div v-if="title" class="font-semibold text-para-xs md:text-para-s">
            {{ title }}
        </div>
        <div class="flex flex-col gap-4">
            <Field v-for="key in keys"
                   :key="key"
                   v-slot="{ field, errors, value }"
                   v-model="files"
                   ref="provider"
                   :name="title"
                   mode="eager"
                   :rules="rules"
                   :vid="name"
                   as="div"
            >
                <div v-show="!files[key]" class="relative border border-dashed border-black-100">
                    <label :for="name + key" class="flex flex-col items-center cursor-pointer pt-5 pb-2.5 px-4">
                        <input :id="name + key"
                               ref="input"
                               type="file"
                               class="hidden"
                               v-bind="field"
                               :name="name + key"
                               accept=".pdf"
                               :aria-invalid="errors.length > 0"
                               :aria-describedby="errors.length > 0 ? `${name + key}-error` : ''"
                               @change="onFileChange($event, key)"
                        >
                        <Icon name="upload" class="w-10 h-10" />
                        <span class="text-para-xs md:text-para-s mt-4">
                            {{ description }}
                        </span>
                        <span class="text-para-xxs md:text-para-xs text-black-600 mt-1">
                            {{ limitation }}
                        </span>
                    </label>
                </div>
                <div v-show="files[key]" class="flex items-center gap-4 border border-black-100 rounded py-5 px-4">
                    <Icon name="file2" class="w-4 h-4 stroke-current" />
                    <div class="flex flex-col md:flex-row md:items-center gap-x-4 flex-grow">
                        <span class="text-para-xs md:text-para-s">{{ files[key] }}</span>
                        <span class="text-para-xxs md:text-para-xs text-black-600 whitespace-nowrap">
                            {{ getFileSize(key) }}
                        </span>
                    </div>
                    <div @click="reset(key)">
                        <Icon name="close" class="w-4 h-4 stroke-current cursor-pointer" />
                    </div>
                </div>
                <span v-if="errors.length > 0" :id="`${name + key}-error`" class="text-error text-para-xs mt-1 md:mt-2">
                    {{ errors[0] }}
                </span>
            </Field>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Icon from "../base/Icon.vue";
import {Field} from "vee-validate";

export default {
    components: {Field, Icon},
    props: {
        name: {type: String},
        title: {type: String},
        description: {type: String},
        limitation: {type: String},
        isError: {type: Boolean, default: false},
        rules: {type: Object as PropType<Record<string, unknown>>}
    },
    data() {
        return {
            files: {},
            sizes: {},
            keys: [1]
        };
    },
    methods: {
        async onFileChange(ev: Event, key: number): Promise<void> {
            const validationResult = await this.$refs.provider[this.keys.indexOf(key)].validate(ev);
            if (validationResult.valid) {
                const target = ev.target as HTMLInputElement;
                this.files[key] = target.files[0].name;
                this.sizes[key] = target.files[0].size;
                // allow max 10 files
                if (this.keys.length < 10) {
                    const current = this.keys.slice(-1)[0];
                    this.keys.push(current + 1);
                }
            }
        },
        reset(key: number): void {
            this.$refs.input.value = '';
            delete this.files[key];
            delete this.sizes[key];
            if (this.keys.length > 1) {
                this.keys.splice(this.keys.indexOf(key), 1);
            }
        },
        hasFile(key: number): boolean {
            return Object.prototype.hasOwnProperty.call(this.files, key);
        },
        getFileSize(key: number): string {
            if (this.sizes[key] < 1000000) {
                return Math.round(this.sizes[key] / 1000) + ' KB';
            } else {
                return Math.round(this.sizes[key] / 1000000) + ' MB';
            }
        }
    }
};
</script>
