<template>
    <div class="relative w-full bg-white flex flex-col xl:w-1/4 xl:h-180 xl:overflow-x-hidden xl:shadow-2xl z-10">
        <div class="relative w-full flex flex-wrap items-center justify-start p-4 md:mb-6 xl:mb-0 md:px-10 md:py-5 xl:p-6">
            <div class="w-full flex flex-col md:flex-row xl:flex-col">
                <div class="cursor-pointer" @click="searchOpen = true" @keyup.enter="searchOpen = true">
                    <SearchInputDropdown class="cursor-pointer mb-4 md:mb-0 md:mr-4 xl:mb-4 xl:mr-0"
                                         :placeholder="labelSearch"
                                         :tab-index="0"
                                         readonly
                    />
                </div>
                <button class="flex items-center"
                        @click="selectAllCategories"
                >
                    <span class="w-4 h-4">
                        <Checkbox class="w-full h-full rounded-sm border border-black-400"
                                  :model="selectedCategories.length < 1 && !searched"
                                  :icon-styles="{ stroke: 'white' }"
                        />
                    </span>
                    <span class="ml-2 block text-para-xs font-semibold">{{ labelShowAll }}</span>
                </button>
            </div>
            <hr class="absolute bottom-0 left-0 h-px w-screen bg-black-100 xl:block xl:w-full">
        </div>
        <div class="w-full flex flex-col md:flex-row xl:flex-col h-auto xl:h-full overflow-auto md:px-6 xl:px-0" tabindex="-1">
            <div v-for="(category, index) in categories"
                 :key="index"
                 class="relative flex-shrink-0 w-full p-4 md:pb-8 md:pt-0 xl:p-6 md:w-4/12 xl:w-full"
            >
                <ul class="w-full flex flex-wrap pl-3 border-l-2" :style="{borderColor: category.color}">
                    <li class="w-full flex items-center justify-start mb-2 last:mb-0">
                        <button class="flex items-center justify-center"
                                @click="selectedCategories.includes(category) ? deselectCategory(category) : selectCategory(category)"
                        >
                            <span class="w-4 h-4">
                                <Checkbox class="w-full h-full rounded-sm border border-black-400"
                                          :model="selectedCategories.includes(category)"
                                          :checked-styles="{ backgroundColor: category.color }"
                                          :icon-styles="{ stroke: 'white', borderColor: category.color }"
                                />
                            </span>
                            <span class="ml-2 block font-semibold text-para-xs">{{ category.label }}</span>
                        </button>
                    </li>
                    <li v-for="(subCategory, i) in category.subCategories"
                        :key="i"
                        class="w-full flex items-center justify-start mb-2 last:mb-0"
                    >
                        <button class="flex items-center justify-center"
                                @click="selectedCategories.includes(subCategory) ? deselectCategory(subCategory) : selectCategory(subCategory)"
                        >
                            <span class="w-4 h-4">
                                <Checkbox class="w-4 h-4 rounded-sm border border-black-400"
                                          :model="selectedCategories.includes(subCategory)"
                                          :checked-styles="{ backgroundColor: category.color }"
                                          :icon-styles="{ stroke: 'white', borderColor: category.color }"
                                />
                            </span>
                            <span class="ml-2 block text-para-xs">{{ subCategory.label }}</span>
                        </button>
                    </li>
                </ul>
                <hr class="absolute bottom-0 left-0 h-px w-screen md:hidden bg-black-100 xl:w-full"
                    :class="{'xl:hidden' : index === Object.keys(categories)[Object.keys(categories).length - 1], 'xl:block': index !== Object.keys(categories)[Object.keys(categories).length - 1]}"
                >
            </div>
        </div>
        <!-- search overlay -->
        <div class="absolute w-full h-full bg-white transition-all duration-500 ease-out px-4 md:px-10 xl:px-6 pb-4 md:pb-10 xl:pb-6 pt-8 md:pt-6 xl:pt-12"
             :class="{'left-0 -top-full xl:-left-full xl:top-0': !searchOpen, 'left-0 top-0': searchOpen}"
        >
            <div class="flex flex-col overflow-hidden max-h-full">
                <div class="flex gap-2 items-center cursor-pointer mb-3" @click="searchOpen = false">
                    <Icon name="chevron-left" class="w-2.5 h-2.5 stroke-current" />
                    <span class="font-semibold text-para-xs">{{ labelBack }}</span>
                </div>
                <SearchInputDropdown :entries="searchEntries"
                                     :placeholder="labelSearch"
                                     :focus="searchOpen"
                                     :tab-index="searchOpen ? 0 : -1"
                                     @selected="handleSearch"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import SearchInputDropdown from "../base/SearchInputDropdown.vue";
import Checkbox from "../base/Checkbox.vue";
import Icon from "../base/Icon.vue";

export default {
    components: {Icon, Checkbox, SearchInputDropdown},
    props: {
        categories: {
            type: Object, default: () => {
            }
        },
        labelShowAll: {type: String, default: ''},
        labelSearch: {type: String, default: ''},
        labelBack: {type: String, default: ''},
        searchEntries: {
            type: Object as PropType<Record<string, string>>, default: () => {
            }
        },
    },
    emits: ['filter-update', 'searched'],
    data() {
        return {
            selectedCategories: [],
            searched: false,
            searchOpen: false
        };
    },
    methods: {
        selectCategory(category): void {
            if (category.subCategories === undefined) {
                this.deselectParent(category);
                this.selectedCategories.push(category);
            } else {
                this.deselectChildren(category);
                this.selectedCategories.push(category);
            }
            this.searched = false;
            this.filter();
        },
        deselectCategory(category): void {
            this.selectedCategories.splice(this.selectedCategories.indexOf(category), 1);
            this.filter();
        },
        deselectChildren(category): void {
            category.subCategories.forEach(subCategory => {
                if (this.selectedCategories.includes(subCategory)) {
                    this.selectedCategories.splice(this.selectedCategories.indexOf(subCategory), 1);
                }
            });
        },
        deselectParent(category): void {
            const parent = this.findParent(category);
            if (!this.selectedCategories.includes(parent)) return;
            this.selectedCategories.splice(this.selectedCategories.indexOf(parent), 1);
        },
        selectAllCategories(): void {
            this.selectedCategories = [];
            this.searched = false;
            this.filter();
        },
        findParent(category): any {
            for (const key of Object.keys(this.categories)) {
                if (this.categories[key].subCategories.includes(category)) {
                    return this.categories[key];
                }
            }
        },
        filter(): void {
            this.$emit('filter-update', this.selectedCategories);
        },
        handleSearch(uuid: string): void {
            this.searched = true;
            this.searchOpen = false;
            this.selectedCategories = [];
            this.$emit('filter-update', this.selectedCategories);
            this.$emit('searched', uuid);
        }
    }
};
</script>
