import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full px-4 md:px-10 xl:pl-28 xl:pr-12 py-4 md:pb-15 overflow-y-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fixed left-0 top-0 h-full w-full bg-white transform transition-all duration-500 ease-out pt-14 md:pt-20", {'-translate-x-full': !$props.isOpened, 'invisible': !$props.isOpened}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", { hasOpenSubmenu: $data.hasOpenSubMenu })
    ])
  ], 2))
}