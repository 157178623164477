import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["srcset", "sizes"]
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row justify-center space-x-3 text-black mt-4 space-x-4 px-4 md:px-10 xl:px-0"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcSwiperSlide = _resolveComponent("GcSwiperSlide")!
  const _component_GcSwiper = _resolveComponent("GcSwiper")!

  return ($data.render)
    ? (_openBlock(), _createBlock(_component_GcSwiper, {
        key: 0,
        class: "mb-9",
        "slide-classes": $props.slideClasses,
        "container-classes": $props.containerClasses,
        gap: { default: 4, lg: 8 }
      }, {
        pagination: _withCtx(({ index, total, teaser, go }) => [
          (total > 1)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(total, (dot, i) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: i,
                    class: _normalizeClass(["relative w-2 h-2 rounded cursor-pointer", [index === (i + 1) ? `bg-${$props.pagerColor} after:content after:w-4 after:h-4 after:rounded-full after:absolute after:-top-1 after:-left-1 after:border after:border-${$props.pagerColor}` : `bg-${$props.pagerColor}`]]),
                    onClick: ($event: any) => (go(i))
                  }, null, 10, _hoisted_3))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.slides, (image, index) => {
            return (_openBlock(), _createBlock(_component_GcSwiperSlide, { key: index }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "w-full object-cover object-center",
                  srcset: (image.sourceSet || ''),
                  sizes: (image.sizes || ''),
                  loading: "lazy"
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["slide-classes", "container-classes"]))
    : _createCommentVNode("", true)
}