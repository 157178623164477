import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-para-s text-white mb-6" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "text",
      class: _normalizeClass(["mb-2", { 'line-clamp-3 break-words': $data.descriptionOpen === false }])
    }, _toDisplayString($props.description || ""), 3),
    ($data.isTextClamped || $data.descriptionOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "flex flex-row items-center cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.descriptionOpen = !$data.descriptionOpen))
        }, [
          (!$data.descriptionOpen)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($props.showMoreLabel), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.showLessLabel), 1)),
          (!$data.descriptionOpen)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 2,
                class: "ml-2 w-3 h-2 stroke-current",
                name: "chevron-down"
              }))
            : (_openBlock(), _createBlock(_component_Icon, {
                key: 3,
                class: "ml-2 w-3 h-2 stroke-current transform rotate-180",
                name: "chevron-down"
              }))
        ]))
      : _createCommentVNode("", true)
  ]))
}