import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  ref: "observable",
  class: "relative w-full h-full bg-white overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "absolute left-4 top-4 flex items-center text-para-xs text-white group-hover:text-primary-500" }
const _hoisted_4 = {
  key: 0,
  class: "md:hidden px-6 py-4"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayButton = _resolveComponent("PlayButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative", [!$options.isVertical ? 'h-60 md:h-auto' : '']])
    }, [
      ($data.show)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: "",
            class: _normalizeClass(["object-cover w-full transform group-hover:scale-110 transition-all duration-300 ease-out", [!$options.isVertical ? 'h-full md:h-auto' : 'h-auto']]),
            src: $props.data.asset || $props.data.url,
            loading: "lazy",
            onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.imageLoaded && $options.imageLoaded(...args)))
          }, null, 42, _hoisted_2))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute left-0 top-0 w-full h-full group-hover:bg-none bg-gradient-to-b opacity-40 from-black-900 pointer-events-none" }, null, -1)),
      _createVNode(_component_PlayButton, {
        "desktop-size": 16,
        "tablet-size": 10,
        "aria-label": $options.$globalLabels.play,
        onClick: $options.go
      }, null, 8, ["aria-label", "onClick"])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex justify-center items-center w-8 h-8 text-white bg-black-900 rounded-full group-hover:bg-primary-500 transition-all duration-300 ease-out mr-2" }, [
        _createElementVNode("svg", {
          class: "w-4 h-4 fill-current",
          viewBox: "0 0 24 17",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", { d: "M23.484 2.645a2.965 2.965 0 00-2.13-2.129C19.485 0 12 0 12 0S4.516 0 2.645.516a2.965 2.965 0 00-2.129 2.13C0 4.515 0 8.386 0 8.386s0 3.871.516 5.742a2.965 2.965 0 002.13 2.13c1.87.515 9.354.515 9.354.515s7.484 0 9.355-.516a2.965 2.965 0 002.129-2.129C24 12.259 24 8.387 24 8.387s-.064-3.935-.516-5.742zM9.548 12V4.774l6.194 3.613L9.548 12z" })
        ])
      ], -1)),
      ($props.data.accountName)
        ? (_openBlock(), _createElementBlock("h6", {
            key: 0,
            class: _normalizeClass([{'invisible': !$options.showChannel}, "flex-grow font-semibold transition-all duration-300 ease-out"])
          }, _toDisplayString($props.data.accountName), 3))
        : _createCommentVNode("", true)
    ]),
    (!$options.isVertical)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", {
            class: "text-para-s tracking-tight line-clamp-6",
            innerHTML: $props.data.caption
          }, null, 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("a", {
      href: $props.data.link,
      class: "absolute left-0 right-0 top-0 bottom-0",
      "aria-label": $props.data.accountName,
      target: "_blank"
    }, null, 8, _hoisted_6)
  ], 512))
}