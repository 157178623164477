import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row md:items-center bg-primary-50 text-para-s py-2 px-2 md:px-4 xl:px-8" }
const _hoisted_2 = { class: "font-semibold mb-1 md:mb-0 md:mr-4" }
const _hoisted_3 = { class: "relative w-full md:w-auto mb-2 md:mb-0" }
const _hoisted_4 = { class: "self-end md:self-auto" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "flex flex-col overflow-x-auto mb-4" }
const _hoisted_8 = { class: "w-full text-para-xs md:text-para-s text-left table-fixed" }
const _hoisted_9 = { class: "border-b border-black-200" }
const _hoisted_10 = { class: "flex items-center font-semibold" }
const _hoisted_11 = { class: "flex items-center font-semibold" }
const _hoisted_12 = { class: "flex items-center font-semibold" }
const _hoisted_13 = { class: "flex items-center font-semibold" }
const _hoisted_14 = { class: "flex items-center justify-end font-semibold" }
const _hoisted_15 = { class: "text-right" }
const _hoisted_16 = { class: "py-2 px-1 md:px-2 xl:px-3" }
const _hoisted_17 = { class: "flex items-center" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "py-2 pl-6 md:pl-7 xl:pl-8 pr-1 md:pr-2 xl:pr-3" }
const _hoisted_20 = { class: "py-2 pl-6 md:pl-7 xl:pl-8 pr-1 md:pr-2 xl:pr-3" }
const _hoisted_21 = { class: "py-2 pl-6 md:pl-7 xl:pl-8 pr-1 md:pr-2 xl:pr-3" }
const _hoisted_22 = { class: "py-2 pl-6 md:pl-7 xl:pl-8 pr-1 md:pr-2 xl:pr-3 text-right" }
const _hoisted_23 = {
  colspan: "5",
  class: "py-0 px-1 md:px-4 xl:px-8"
}
const _hoisted_24 = { class: "table-fixed" }
const _hoisted_25 = { class: "border-b border-primary-500 py-2" }
const _hoisted_26 = { class: "flex flex-col md:flex-row" }
const _hoisted_27 = { class: "flex-grow flex font-semibold md:pr-4" }
const _hoisted_28 = { class: "w-4 mr-1 md:mr-2 xl:mr-3" }
const _hoisted_29 = { class: "flex-shrink-0 w-full md:w-55 xl:w-107 pl-5 md:px-2 xl:px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString($props.labels.headline), 1),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.searchTerm) = $event)),
          class: "w-full text-para-s rounded focus:outline-none py-2 pl-8 md:pl-12 pr-4",
          type: "text"
        }, null, 512), [
          [_vModelText, $data.searchTerm]
        ]),
        _createVNode(_component_Icon, {
          name: "search",
          class: "absolute left-2 md:left-4 top-2 md:top-3 w-4 h-4 text-primary-500 stroke-current"
        })
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "hidden md:inline flex-grow" }, null, -1)),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString($props.labels.prefix), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString($options.paged.length), 1),
        _createElementVNode("span", null, _toDisplayString($props.labels.suffix), 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString($options.filtered.length), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("table", _hoisted_8, [
        _createElementVNode("thead", _hoisted_9, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", {
              class: "w-40 md:w-auto py-2 px-1 md:px-2 xl:px-3",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.sortBy('name')))
            }, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: _normalizeClass(["mr-1", $options.getTextColor('name')])
                }, [
                  _createVNode(_component_Icon, {
                    name: "arrow-right",
                    class: _normalizeClass(["w-4 h-4 transform origin-center stroke-current", $options.getRotation('name')])
                  }, null, 8, ["class"])
                ], 2),
                _createElementVNode("span", null, _toDisplayString($props.labels.column1), 1)
              ])
            ]),
            _createElementVNode("th", {
              class: "w-32 py-2 px-1 md:px-2 xl:px-3",
              onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.sortBy('ownership')))
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: _normalizeClass(["mr-1", $options.getTextColor('ownership')])
                }, [
                  _createVNode(_component_Icon, {
                    name: "arrow-right",
                    class: _normalizeClass(["w-4 h-4 transform origin-center stroke-current", $options.getRotation('ownership')])
                  }, null, 8, ["class"])
                ], 2),
                _createElementVNode("span", null, _toDisplayString($props.labels.column2), 1)
              ])
            ]),
            _createElementVNode("th", {
              class: "w-24 py-2 px-1 md:px-2 xl:px-3",
              onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.sortBy('status')))
            }, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", {
                  class: _normalizeClass(["mr-1", $options.getTextColor('status')])
                }, [
                  _createVNode(_component_Icon, {
                    name: "arrow-right",
                    class: _normalizeClass(["w-4 h-4 transform origin-center stroke-current", $options.getRotation('status')])
                  }, null, 8, ["class"])
                ], 2),
                _createElementVNode("span", null, _toDisplayString($props.labels.column3), 1)
              ])
            ]),
            _createElementVNode("th", {
              class: "w-32 xl:w-64 py-2 px-1 md:px-2 xl:px-3",
              onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.sortBy('dateinitial')))
            }, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", {
                  class: _normalizeClass(["mr-1", $options.getTextColor('dateinitial')])
                }, [
                  _createVNode(_component_Icon, {
                    name: "arrow-right",
                    class: _normalizeClass(["w-4 h-4 transform origin-center stroke-current", $options.getRotation('dateinitial')])
                  }, null, 8, ["class"])
                ], 2),
                _createElementVNode("span", null, _toDisplayString($props.labels.column4), 1)
              ])
            ]),
            _createElementVNode("th", {
              class: "w-32 xl:w-56 py-2 px-1 md:px-2 xl:px-3",
              onClick: _cache[5] || (_cache[5] = ($event: any) => ($options.sortBy('currmaxheight')))
            }, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", {
                  class: _normalizeClass(["mr-1", $options.getTextColor('currmaxheight')])
                }, [
                  _createVNode(_component_Icon, {
                    name: "arrow-right",
                    class: _normalizeClass(["w-4 h-4 transform origin-center stroke-current", $options.getRotation('currmaxheight')])
                  }, null, 8, ["class"])
                ], 2),
                _createElementVNode("span", _hoisted_15, _toDisplayString($props.labels.column5), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paged, (row, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              _createElementVNode("tr", {
                class: _normalizeClass({
                            'hover:bg-primary-100 transition duration-100 ease-out': !$data.detailModel[i],
                            'bg-black-50': i % 2 === 0 && !$data.detailModel[i],
                            'bg-primary-500 text-white': $data.detailModel[i]
                        })
              }, [
                _createElementVNode("td", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", {
                      class: "cursor-pointer p-1 mr-1",
                      onClick: ($event: any) => ($options.toggle(i))
                    }, [
                      _createVNode(_component_Icon, {
                        name: "chevron-down",
                        class: _normalizeClass(["w-2 h-2 stroke-current", {'transform rotate-180 text-white': $data.detailModel[i], 'text-black-400': !$data.detailModel[i]}])
                      }, null, 8, ["class"])
                    ], 8, _hoisted_18),
                    _createElementVNode("span", null, _toDisplayString(row.name), 1)
                  ])
                ]),
                _createElementVNode("td", _hoisted_19, _toDisplayString(row.ownership), 1),
                _createElementVNode("td", _hoisted_20, _toDisplayString(row.status), 1),
                _createElementVNode("td", _hoisted_21, _toDisplayString(row.dateinitial), 1),
                _createElementVNode("td", _hoisted_22, _toDisplayString(row.currmaxheight), 1)
              ], 2),
              _withDirectives(_createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_23, [
                  _createElementVNode("table", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.questions, (value, key, j) => {
                      return (_openBlock(), _createElementBlock("tr", { key: key }, [
                        _createElementVNode("td", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("span", _hoisted_28, _toDisplayString(j + 1) + ".", 1),
                              _createElementVNode("span", null, _toDisplayString(value), 1)
                            ]),
                            _createElementVNode("div", _hoisted_29, _toDisplayString(row[key]), 1)
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ], 512), [
                [_vShow, $data.detailModel[i]]
              ])
            ], 64))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_Pagination, {
      modelValue: $data.currentPage,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.currentPage) = $event)),
      pages: $options.pages
    }, null, 8, ["modelValue", "pages"])
  ]))
}