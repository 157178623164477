import { createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "dialogEL",
  class: "min-w-full min-h-full overflow-x-hidden bg-white backdrop:bg-white"
}
const _hoisted_2 = { ref: "embedded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("dialog", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, null, 512)
        ], 512)
      ]),
      _: 1
    })
  ]))
}