<template>
    <div>
        <table class="w-full table-fixed">
            <thead class="text-para-xs uppercase text-black-600 border-b border-black-900">
                <tr>
                    <th v-if="!hideTrafficLight" scope="col" class="font-semibold text-left w-14 p-0 pb-2">
                        {{ labels.col1 }}
                    </th>
                    <th scope="col" class="font-semibold text-left p-0 pb-2">{{ labels.col2 }}</th>
                    <th scope="col" class="font-semibold text-right w-16 md:w-32 p-0 pb-2">{{ labels.col3 }}</th>
                    <th scope="col" class="font-semibold text-right w-16 md:w-32 p-0 pb-2">{{ labels.col4 }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="h-2 md:h-4 p-0" />
                </tr>
                <tr v-for="(item, index) in data" :key="index">
                    <td v-if="!hideTrafficLight" class="p-0 pb-2">
                        <div class="w-6 h-6" :class="getColor(item.value60)" />
                    </td>
                    <td class="p-0 pb-2">{{ item.label }}</td>
                    <td class="text-right p-0 pb-2">{{ item.value5.toFixed(2) }}</td>
                    <td class="text-right p-0 pb-2">{{ item.value60.toFixed(2) }}</td>
                </tr>
            </tbody>
        </table>
        <div class="flex flex-col md:flex-row md:justify-end text-para-xs mt-2 md:mt-4">
            <span class="mr-1">{{ labels.dataCollected }}:</span>
            <span>
                <span>{{ labels.min5Time }}: </span>
                <span class="font-semibold">{{ _fiveMinTime }} - </span>
                <span>{{ labels.hourlyTime }}: </span>
                <span class="font-semibold">{{ _hourlyTime }}</span>
            </span>
        </div>
        <div class="border-t border-black-900 mt-6 pt-6">
            <p class="text-para-xs uppercase font-semibold mb-2 md:mb-4">{{ labels.legend }}</p>
            <ul class="flex flex-col md:flex-row gap-y-2 md:gap-x-4 xl:gap-x-5">
                <li v-for="item in scale" :key="item.min" class="flex">
                    <div class="w-1 h-6 md:h-full mr-2" :class="`bg-graph-${item.color}`" />
                    <div class="text-para-xs font-semibold bg-opacity-20 rounded-xl px-3 py-1"
                         :class="`bg-graph-${item.color}`"
                    >
                        {{ !!item.max ? `${item.min}-${item.max}` : `>= ${item.min}` }} {{ item.label }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {DataPoint, MonitoringLabels, Scale} from './MonitoringGraph.vue';

export default {
    props: {
        labels: {type: Object as PropType<MonitoringLabels>, required: true},
        scale: {type: Array as PropType<Array<Scale>>, required: true},
        data: {type: Array as PropType<Array<DataPoint>>, required: true},
        hideTrafficLight: {type: Boolean, default: false}
    },
    computed: {
        _fiveMinTime(): string {
            if (this.data.length > 0) {
                return this.formatDate(this.data[0].value5Time);
            }
            return '-';
        },
        _hourlyTime(): string {
            if (this.data.length > 0) {
                return this.formatDate(this.data[0].value60Time);
            }
            return '-';
        }
    },
    methods: {
        formatDate(dateStr: string): string {
            return dateStr.replace(/.*\s(\d{1,2}:\d{2}).*/, (a, b) => b);
        },
        /**
         * Determine color according to value.
         * For tailwind preservation: bg-graph-blue bg-graph-green bg-graph-yellow bg-graph-orange bg-graph-red
         * @param value A numeric value
         */
        getColor(value: number): string {
            return 'bg-graph-' + this.scale.find(s => value <= s.max || !s.max).color;
        }
    }
};
</script>
