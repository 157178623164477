<template>
    <div class="w-full order-2 min-h-screen h-auto mt-socio-economic-map overflow-hidden relative max-h-full rounded-t-xl
                md:rounded-none md:order-1 md:min-h-0
                bg-primary-500 md:transition-all md:block md:static z-30 flex flex-col md:mt-0 md:w-1/3 xl:w-1/4 md:h-120
                xl:h-180 xl:overflow-x-hidden xl:overflow-y-auto xl:shadow-2xl"
         :class="{ 'pt-14': !showChevronUp }"
    >
        <div class="flex md:hidden mx-auto mt-0 w-full h-14 p-5 text-white"
             :class="!showChevronUp ? 'bg-primary-500 justify-start' : 'justify-center'"
             :style="specialChevronStyles"
        >
            <Icon v-show="showChevronUp" name="double-chevron-up" class="w-8 h-8 stroke-current" />
            <Icon v-show="!showChevronUp" name="chevron-down" class="w-6 h-6 ml-5 stroke-current" />
        </div>
        <!-- Operation Data: displayed if an location or its operation is selected -->
        <SocioEconomicMapDetailContent
            v-if="operation"
            scope="Operation"
            :entity="operation"
            :download-factsheet-label="downloadFactsheetLabel"
            :show-more-label="showMoreLabel"
            :show-less-label="showLessLabel"
            :operations-label="operationsLabel"
        />
        <!-- Region Data: displayed if a new Region has been selected -->
        <SocioEconomicMapDetailContent
            v-else-if="region"
            scope="Region"
            :entity="region"
            :operations="operations"
            :download-factsheet-label="downloadFactsheetLabel"
            :show-more-label="showMoreLabel"
            :show-less-label="showLessLabel"
            :operations-label="operationsLabel"
            @set-operation="setOperation"
        />
        <!-- Section Data: displayed when no location is selected -->
        <SocioEconomicMapDetailContent
            v-else-if="sectionData"
            :entity="sectionData"
            scope="Initial"
            :download-factsheet-label="downloadFactsheetLabel"
            :show-more-label="showMoreLabel"
            :show-less-label="showLessLabel"
            :operations-label="operationsLabel"
        />
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {Location, Operation, SubOperation, SubRegion} from './SocioEconomicMapUtils';
import SocioEconomicMapDetailContent from "./SocioEconomicMapDetailContent.vue";
import Icon from "../base/Icon.vue";

export default {
    components: {Icon, SocioEconomicMapDetailContent},
    props: {
        sectionData: {type: Object as PropType<SubRegion | SubOperation>, required: true, default: () => {}},
        sectionDisclaimer: {type: String, default: ''},
        location: {type: Object as PropType<Location>, default: null},
        region: {type: Object as PropType<SubRegion>, default: null},
        operation: {type: Object as PropType<SubOperation>, default: null},
        operations: {type: Array as PropType<Array<Operation>>, default: () => ([])},
        downloadFactsheetLabel: {type: String, default: ''},
        operationsLabel: {type: String, default: ''},
        showMoreLabel: {type: String, default: ''},
        showLessLabel: {type: String, default: ''}
    },
    emits: ['setOperation'],
    data() {
        return {
            detailOpen: false,
            detailScrollable: false,
            detailOffset: '0px',
            showChevronUp: true,
            specialChevronStyles: {}
        };
    },
    methods: {
        setOperation(operation: SubOperation) {
            this.$emit('setOperation', operation);
        },
        toggleOverlayOffset(offset: number): void {
            if (offset < 0) return;
            this.detailOffset = offset.toString().concat('px');
        },
        setChevronDown(show: boolean): void {
            this.showChevronUp = !show;
            if (show) {
                this.specialChevronStyles = {
                    top: '56px',
                    position: 'fixed',
                    'z-index': '5000',
                    margin: 0
                };
            } else {
                this.specialChevronStyles = { };
            }
        }
    }
};
</script>
