import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col overflow-x-auto pb-4 md:pb-0 px-4 md:px-0 -mx-4 md:mx-0" }
const _hoisted_2 = { class: "w-full text-para-xs md:text-para-s md:tracking-tight text-left" }
const _hoisted_3 = { class: "border-b border-black-200" }
const _hoisted_4 = { class: "flex items-center font-semibold" }
const _hoisted_5 = {
  key: 0,
  scope: "col",
  class: "py-2 px-1 md:py-2 md:px-3 xl:px-4 font-semibold"
}
const _hoisted_6 = { class: "flex items-center justify-end font-semibold" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "py-2 px-1 md:py-2 md:px-3 xl:px-4" }
const _hoisted_9 = {
  key: 0,
  class: "py-2 px-1 md:py-2 md:px-3 xl:px-4"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "py-2 px-1 md:py-2 md:px-3 xl:px-4 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", {
            scope: "col",
            class: "py-2 px-1 md:py-2 md:px-3 xl:px-4",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.sortBy('position')))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["mr-1", [{'text-primary-500': $data.sortProperty === 'position'},{'text-black-600': $data.sortProperty !== 'position'}]])
              }, [
                _createVNode(_component_Icon, {
                  name: "arrow-right",
                  class: _normalizeClass(["w-4 h-4 transform origin-center stroke-current", [{'rotate-90': $data.sortProperty === 'position' && !$data.ascending },{'-rotate-90': ($data.sortProperty === 'position' && $data.ascending) || $data.sortProperty !== 'position' }]])
                }, null, 8, ["class"])
              ], 2),
              _createElementVNode("span", null, _toDisplayString($props.labels.position), 1)
            ])
          ]),
          ($props.showLocalisation)
            ? (_openBlock(), _createElementBlock("th", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString($props.labels.localisation), 1)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.addColumns, (label, key, i) => {
            return (_openBlock(), _createElementBlock("th", {
              key: i,
              scope: "col",
              class: "py-2 px-1 md:py-2 md:px-3 xl:px-4 font-semibold"
            }, [
              _createElementVNode("span", null, _toDisplayString(label), 1)
            ]))
          }), 128)),
          _createElementVNode("th", {
            scope: "col",
            class: "py-2 px-1 md:py-2 md:px-3 xl:px-4",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.sortBy('date')))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: _normalizeClass(["mr-1", [{'text-primary-500': $data.sortProperty === 'date'},{'text-black-600': $data.sortProperty !== 'date'}]])
              }, [
                _createVNode(_component_Icon, {
                  name: "arrow-right",
                  class: _normalizeClass(["w-4 h-4 transform origin-center stroke-current", [{'rotate-90': $data.sortProperty === 'date' && !$data.ascending },{'-rotate-90': ($data.sortProperty === 'date' && $data.ascending) || $data.sortProperty !== 'date' }]])
                }, null, 8, ["class"])
              ], 2),
              _createElementVNode("span", null, _toDisplayString($props.labels.deadline), 1)
            ])
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedJobs, (job) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: job.jobId,
            class: "even:bg-black-50 hover:bg-primary-100 transition duration-100 ease-out cursor-pointer",
            onClick: ($event: any) => ($options.openCareer(job.jobId))
          }, [
            _createElementVNode("td", _hoisted_8, _toDisplayString(job.position), 1),
            ($props.showLocalisation)
              ? (_openBlock(), _createElementBlock("td", _hoisted_9, _toDisplayString(job.localisation), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.addColumns, (label, key, i) => {
              return (_openBlock(), _createElementBlock("td", {
                key: i,
                class: "py-2 px-1 md:py-2 md:px-3 xl:px-4",
                innerHTML: job[key] ? job[key] : ''
              }, null, 8, _hoisted_10))
            }), 128)),
            _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.$filters.formatDate(job.date)), 1)
          ], 8, _hoisted_7))
        }), 128))
      ])
    ])
  ]))
}