<template>
    <section class="mx-auto max-w-container relative">
        <div v-if="open" class="fixed top-0 left-0 w-screen h-screen backdrop-filter backdrop-blur z-10" />
        <PositionSticky :offset-top="offset">
            <div class="absolute top-0 right-0 z-20" :class="{'w-full': open}">
                <div class="flex flex-row-reverse md:float-right">
                    <button
                        :aria-label="ariaLabel"
                        class="focus-primary md:w-20 md:h-20 w-14 h-14 bg-black-50 flex justify-center items-center md:float-right focus:outline-none"
                        @click="toggle"
                    >
                        <Icon v-if="open" name="close" class="w-4 h-4 stroke-current" />
                        <Icon v-else name="icon_link" class="w-4 h-4 stroke-current" />
                    </button>
                    <h2 v-if="title" :class="[open && isMobile ? 'bg-black-50 text-para-base font-semibold p-4 flex-grow' : 'hidden']">
                        {{ title }}
                    </h2>
                </div>
                <div
                    class="float-right w-full md:w-80 h-full text-para-s bg-black-50 md:pt-10 xl:pt-4 md:pb-8 md:px-8 p-4 overflow-x-hidden"
                    :class="[open ? 'flex flex-col' : 'hidden']"
                    :style="{ maxHeight: computedHeight }"
                    @click="click"
                >
                    <h2 v-if="open && !isMobile && title" class="bg-black-50 text-para-base font-semibold pt-4">
                        {{ title }}
                    </h2>
                    <ul class="flex flex-col">
                        <slot name="links" />
                    </ul>
                </div>
            </div>
        </PositionSticky>
    </section>
</template>

<script lang="ts">
import breakpoints from '../../plugins/breakpoints';
import Icon from "../base/Icon.vue";
import PositionSticky from "../base/PositionSticky.vue";

export default {
    components: {PositionSticky, Icon},
    props: {
        closeDelay: {type: Number, default: 0},
        title: {type: String, default: ''},
        ariaLabel: {type: String, default: 'quicklinks'}
    },
    data() {
        return {
            open: this.closeDelay > 0
        };
    },
    computed: {
        computedHeight(): string {
            return `calc(100vh - ${this.isMobile ? this.offset * 2 : this.offset}px)`;
        },
        isMobile(): boolean {
            return breakpoints.mobile;
        },
        offset(): number {
            return this.isMobile ? 56 : 118;
        }
    },
    mounted(): void {
        if (this.closeDelay > 0) {
            setTimeout(() => {
                this.open = false;
            }, this.closeDelay);
        }
    },
    methods: {
        click(event) {
            // close overlay if any link was clicked
            if (event.target.closest('a')) {
                this.toggle();
            }
        },
        toggle() {
            this.open = !this.open;
        }
    }
};
</script>
