<template>
    <div class="relative">
        <div class="relative">
            <button :id="name"
                    type="button"
                    role="combobox"
                    class="flex justify-between items-center gap-2 w-full border transition-colors cursor-pointer border-black-100 focus:border-primary-500 hover:text-black-900 rounded-t px-4 py-3 md:py-2.5"
                    :class="{'rounded-b': !open}"
                    :aria-label="open ? $globalLabels.close : $globalLabels.open"
                    @click="doClose"
            >
                <div v-if="pillItems" class="flex flex-wrap gap-2 min-h-4 md:min-h-6">
                    <div v-if="!currentSelection || !currentSelection.length" class="text-para-xs md:text-para-s text-black-600 truncate" :title="title"> {{ title }} </div>
                    <div v-for="item in currentSelection"
                         :key="item"
                         class="flex items-center gap-1 text-para-xs truncate bg-black-50 px-3 py-1 rounded-full"
                         :title="items[item].key"
                         tabindex="0"
                         @click="toggle(item)"
                         @keyup.delete="toggle(item)"
                    >
                        <Icon name="close" class="w-2 h-2 stroke-current" />
                        <div>{{ items[item] }}</div>
                    </div>
                </div>
                <div v-else class="text-para-xs md:text-para-s text-black-600 h-4 md:h-6 truncate"
                     :title="title"
                >
                    {{ title }}
                </div>
                <div class="flex items-center flex-shrink-0 gap-4">
                    <span v-if="!pillItems && currentSelection.length > 1" class="text-para-xs md:text-para-s text-black-600">
                        + {{ currentSelection.length - 1 }}
                    </span>
                    <Icon name="chevron-down"
                          class="w-3 h-3 flex-shrink-0 transition-transform transform origin-center stroke-current"
                          :class="{ 'rotate-180': open }"
                    />
                </div>
            </button>
            <CollapseTransition>
                <div v-show="open" class="absolute top-full z-10 w-full bg-white border-r border-b border-l border-black-100 rounded-b" role="listbox">
                    <div class="overflow-y-scroll max-h-96">
                        <div v-if="searchable" class="cursor-pointer text-black-900 mx-2 mb-4 mt-3 text-para-s flex items-center justify-start relative border border-black-200 rounded">
                            <input v-model="currentSearch"
                                   type="text"
                                   :placeholder="searchLabel"
                                   class="w-10/12 h-full py-2 px-2 md:py-2.5 placeholder:text-para-s focus:outline-none rounded placeholder-improvedContrast"
                            >
                            <div class="absolute top-1/2 transform -translate-y-1/2 right-4 text-black-200">
                                <Icon name="search" class="stroke-current  w-4 h-4" />
                            </div>
                        </div>
                        <div v-for="(label, iValue) in items"
                             v-show="shouldItemRender(iValue)"
                             :key="iValue"
                             role="option"
                             class="flex gap-2 cursor-pointer hover:bg-primary-500 focus:bg-primary-500 px-4 py-3 md:py-2.5 group"
                             tabindex="0"
                             @click="toggle(iValue)"
                             @keyup.enter="toggle(iValue)"
                        >
                            <div class="group-hover:text-white group-focus:text-white" :class="isSelected(iValue) ? 'text-primary-500' : 'text-black-200'">
                                <Icon :name="isSelected(iValue) ? 'checkbox-active' : 'checkbox'"
                                      class="relative top-1.5 stroke-current w-3 h-3"
                                />
                            </div>
                            <span class="block text-para-xs md:text-para-s text-black-600 group-hover:text-white group-focus:text-white">
                                {{ label }}
                            </span>
                        </div>
                    </div>
                    <div v-if="showCta" class="flex justify-end py-2 px-4 shadow-top-sm">
                        <button type="button"
                                class="flex flex-row items-center justify-center text-para-xs text-white font-semibold rounded border border-transparent focus:outline-none
                                       bg-black-900 hover:bg-black-600 focus:border-primary-500 active:bg-black-500 transition-all duration-300 ease-out px-4 py-1"
                                @click="emitCta"
                        >
                            {{ ctaLabel }}
                        </button>
                    </div>
                </div>
            </CollapseTransition>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import Icon from "./Icon.vue";
import CollapseTransition from "./CollapseTransition.vue";
import Utils from "../../utils/Utils";

export default {
    components: {CollapseTransition, Icon},
    inject: ['$globalLabels'],
    props: {
        name: {type: String},
        items: {type: Object as PropType<Record<string, string>>, default: () => {}},
        placeholder: {type: String, default: ''},
        searchLabel: {type: String, default: ''},
        showCta: {type: Boolean, default: false},
        ctaLabel: {type: String, default: 'Save'},
        searchable: {type: Boolean, default: false},
        pillItems: {type: Boolean, default: false},
        modelValue: {type: [String, Array as PropType<Array<string>>]}
    },
    emits: ['cta', 'update:modelValue'],
    data() {
        return {
            currentSelection: [],
            currentSearch: '',
            open: false
        };
    },
    computed: {
        title(): string {
            return this.currentSelection.length > 0 ? this.items[this.currentSelection[0]] : this.placeholder;
        }
    },
    watch: {
        modelValue(newValue: string) {
            this.currentSelection = newValue;
        }
    },
    mounted(): void {
        document.addEventListener('click', this.onOutsideClick);
        // respect the initial v-model value
        if (this.modelValue) {
            this.currentSelection = this.modelValue;
        }
    },
    beforeUnmount(): void {
        document.removeEventListener('click', this.onOutsideClick);
    },
    methods: {
        emitCta(): void {
            this.open = false;
            this.$emit('cta');
        },
        shouldItemRender(item: string): boolean {
            if (item === '​') return false;
            if (!this.currentSearch) return true;
            return item.toLowerCase().includes(this.currentSearch.toLowerCase());
        },
        isSelected(item: string): boolean {
            return this.currentSelection.indexOf(item) >= 0;
        },
        toggle(item: string) {
            const index = this.currentSelection.indexOf(item);
            if (index >= 0) {
                // remove from selection
                this.currentSelection.splice(index, 1);
            } else {
                // add to selection
                this.currentSelection.push(item);
            }
            this.$emit('update:modelValue', this.currentSelection);
        },
        onOutsideClick(evt) {
            let targetElement = evt.target;
            do {
                if (targetElement === this.$el) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            this.open = false;
        },
        doClose(): void {
            this.open = !this.open;
        }
    }
};
</script>
