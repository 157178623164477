<template>
    <div :class="{'bg-black-50': highlight}">
        <tab-header v-if="tags.length > 0"
                    v-model="currentTag"
                    class="mb-18"
                    :tabs="tags.map(t => t.tag)"
                    :component-uid="$.uid"
        />
        <ul class="container grid grid-cols-1 gap-4 xl:gap-8"
            :class="[
                shownArticles.length > 2 ? 'md:grid-cols-3' : 'md:grid-cols-2',
                {'pb-6 md:pb-8 xl:pb-12': highlight}
            ]"
        >
            <li v-for="(article, index) in shownArticles" :key="index" class="flex flex-col">
                <img v-if="article.asset"
                     :srcset="article.asset.sourceSet"
                     :sizes="article.asset.sizes"
                     :src="article.asset.source"
                     alt="article.asset.title"
                     class="w-full object-cover object-center"
                >
                <span class="flex-grow text-para-base mt-6 mb-4">{{ article.title }}</span>
                <TeaserLink :url="article.link"
                            :type="article.type"
                            :title="article.title"
                            :aria-label="article.title"
                            classes="flex items-center gap-2 group"
                >
                    <span class="text-para-s font-semibold">{{ labels.readMore }}</span>
                    <ArrowButton :direction="getIcon(article.type)" class="w-10 h-10" />
                </TeaserLink>
            </li>
        </ul>
        <div class="container">
            <GcButton v-if="hasMore"
                      class="font-semibold mx-auto mt-8"
                      :label="labels.readMore"
                      :secondary="true"
                      @click="loadMore"
            />
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {Article, ArticleLabels} from './ArticleFilterGrid.vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import TabHeader from "../base/TabHeader.vue";
import TeaserLink from "../teaser/TeaserLink.vue";
import ArrowButton from "../base/ArrowButton.vue";
import GcButton from "../base/GcButton.vue";

export default {
    components: {GcButton, ArrowButton, TeaserLink, TabHeader},
    inject: ['$lang'],
    props: {
        highlight: {type: Boolean, default: false},
        maxItems: {type: Number, default: 24},
        chunkSize: {type: Number, default: 18},
        labels: {type: Object as PropType<ArticleLabels>, default: () => {}},
        articles: {type: Array as PropType<Array<Article>>, default: () => []},
        categories: {type: Object as PropType<Record<string, Array<Record<string, string>>>>, default: () => {}},
        uuid: {type: String, required: true}
    },
    data() {
        return {
            apiUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.LOCATION_V2 + Enums.API.ARTICLES,

            shownArticles: [],
            currentTag: 0,
            targetUrl: '',
            init: false,
            hasMore: false,
            offset: 0
        };
    },
    computed: {
        /**
         * Since we can only use one tag category in this layout, we use the first one
         */
        tags(): Record<string, string>[] {
            const tagCategories = Object.keys(this.categories);
            if (tagCategories.length > 0) {
                const tags = this.categories[tagCategories[0]];
                tags.unshift({
                    uuid: '0',
                    tag: this.labels.defaultFilter
                });
                return tags;
            }
            return [];
        }
    },
    watch: {
        async currentTag(newVal: number) {
            if (newVal > 0) {
                this.init = true;
            }
            if (this.init) {
                this.offset = 0;
                this.getArticles(this.maxItems);
            }
        }
    },
    created(): void {
        this.shownArticles = this.articles;
        this.offset = this.shownArticles.length;
        this.hasMore = this.shownArticles.length === this.maxItems;
        this.setTagFromQuery();
    },
    methods: {
        getUrl(limit: number): string {
            return `${this.apiUrl}uuid=${this.uuid}&locale=${this.$lang}${this.currentTag > 0 ? '&tag=' + this.tags[this.currentTag].uuid : ''}&offset=${this.offset}&limit=${limit}`;
        },
        setTagFromQuery(): void {
            const params = new URLSearchParams(window.location.search);
            if (params.has('tag')) {
                const tag = params.get('tag');
                const index = this.tags.findIndex(t => t.uuid === tag);
                if (index >= 0) {
                    this.currentTag = index;
                }
            }
        },
        getIcon(type: string): string {
            return type === 'overlay' ? type : 'right';
        },
        getArticles(limit: number): void {
            axios.get(this.getUrl(limit)).then(res => {
                if (this.offset === 0) {
                    this.shownArticles = res.data;
                } else {
                    this.shownArticles = [
                        ...this.shownArticles,
                        ...res.data
                    ];
                }
                this.offset += limit;
                // if number of delivered articles is same as limit, assume there are more articles to load
                this.hasMore = res.data.length === limit;
            });
        },
        loadMore(): void {
            this.getArticles(this.chunkSize);
        }
    }
};
</script>
