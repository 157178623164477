import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-center md:justify-end xl:w-2/3 mx-auto pt-3 md:py-0" }
const _hoisted_2 = { class: "font-semibold text-para-xs mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1),
    _createVNode(_component_Dropdown, {
      modelValue: $data.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.model) = $event)),
      class: "min-w-50 -my-px",
      name: "year",
      title: $data.model,
      "default-value": $props.modelValue,
      rounded: $options.isMobile
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (item, index) => {
          return (_openBlock(), _createBlock(_component_DropdownItem, {
            key: index,
            label: item,
            value: item
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "title", "default-value", "rounded"])
  ]))
}