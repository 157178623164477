<template>
    <div class="overflow-hidden relative w-full" :class="[fullScreen ? 'h-full' : `h-0 ${padding}`]">
        <div :id="videoContainerId"
             mi24-video-player
             class="object-cover object-center absolute top-0 left-0 w-full h-full"
             :video-id="videoId"
             :player-id="playerId"
             config-type="vmpro"
             flash-path="https://e.video-cdn.net/v2/"
             api-url="https://d.video-cdn.net/play"
        />
        <PlayButton v-if="firstPlay"
                    :tablet-size="tabletPlayButtonSize"
                    :desktop-size="desktopPlayButtonSize"
                    :aria-label="$globalLabels.play"
                    @click="playVideo"
        />
        <img class="object-cover object-center absolute top-0 left-0 w-full h-full z-5"
             :class="{'hidden' : !showPlaceHolderImage}"
             :src="placeholderImageUrl"
             alt=""
        >
        <slot v-if="firstPlay && fullScreen" :play="playVideo" />
    </div>
</template>

<script lang="ts">
import Utils from '../../utils/Utils';
import PlayButton from "./PlayButton.vue";
import {nextTick} from "vue";

export default {
    components: {PlayButton},
    inject: ['$globalLabels'],
    props: {
        id: {type: String, default: ''},
        playerId: {type: String, required: true},
        videoId: {type: String, required: true},
        placeholderImageUrl: {type: String, default: ''},
        numOfComponents: {type: Number, default: 1},
        rendition: {type: String, default: 'landscape'},
        fullScreen: {type: Boolean, default: false},
        narrow: {type: Boolean, default: false},
        isSlider: {type: Boolean, default: false}
    },
    data() {
        return {
            firstPlay: true,
            videoContainerId: ''
        };
    },
    computed: {
        showPlaceHolderImage() {
            return this.firstPlay &&
                this.placeholderImageUrl !== undefined &&
                this.placeholderImageUrl !== '';
        },
        padding(): string {
            return this.rendition === 'landscape' ? 'pt-16/9' : 'pt-3/4';
        },
        tabletPlayButtonSize(): number {
            // preserve comment: md:h-24 md:w-24 md:h-16 md:w-16 md:h-10 md:w-10
            if (this.fullScreen) {
                return 24;
            } else if (this.isSlider || (this.numOfComponents % 2 === 0)) {
                return 16;
            } else if (this.numOfComponents % 3 === 0) {
                return 10;
            }
            return 20;
        },
        desktopPlayButtonSize(): number {
            // preserve comment: xl:h-20 xl:w-20 xl:h-16 xl:w-16 xl:h-10 xl:w-10
            if (this.fullScreen) {
                return 24;
            } else if (this.narrow) {
                if (this.isSlider || (this.numOfComponents % 2 === 0)) {
                    return 16;
                } else if (this.numOfComponents % 3 === 0) {
                    return 10;
                } else {
                    return 20;
                }
            } else if (this.isSlider || (this.numOfComponents % 2 === 0)) {
                return 20;
            } else if (this.numOfComponents % 3 === 0) {
                return 16;
            }
            return 24;
        }
    },
    /***********************
     * Vue lifecycle
     ***********************/
    mounted() {
        this.videoContainerId = this.id === '' ? 'video-player-' + Math.random().toString(16).slice(2) : this.id;

        nextTick(() => {
            Utils.addScript(
                'https://e.video-cdn.net/v2/embed.js',
                {},
                document.getElementsByTagName('head')[0]
            );
        });
    },
    methods: {
        playVideo(): void {
            //@ts-expect-error VideoPlayer object is of unknown type
            const player = VideoPlayer.Collection.getPlayerById(this.videoContainerId);
            // see https://help.movingimage.com/docs/controlling-the-player
            player.play();

            this.firstPlay = false;
        }
    }
};
</script>
