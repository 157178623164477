import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Accordion = _resolveComponent("Accordion")!

  return ($options.checkAccordion)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createVNode(_component_Accordion, {
          modelValue: $data.open,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.open) = $event)),
          title: $props.title,
          anchor: $options.safeAnchor,
          "is-last": $props.isLast
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", { visible: $options.visible })
          ]),
          _: 3
        }, 8, ["modelValue", "title", "anchor", "is-last"])
      ]))
    : _withDirectives((_openBlock(), _createElementBlock("li", {
        key: 1,
        id: $options.safeAnchor,
        role: "tabpanel",
        class: "-mt-14 pt-14 focus:outline-none",
        tabindex: "0"
      }, [
        _renderSlot(_ctx.$slots, "default", { visible: $options.visible })
      ], 8, _hoisted_2)), [
        [_vShow, $options.active]
      ])
}