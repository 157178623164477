<template>
    <ul class="border-t border-black-200">
        <li v-for="option in options" :key="option.type" class="border-b border-black-200 pt-4 pb-8">
            <div class="flex justify-between items-center gap-4">
                <span class="font-semibold text-para-s md:text-para-lg">{{ option.label }}</span>
                <span class="mr-2 md:mr-6">
                    <ToggleButton v-model="grantModel[option.type]"
                                  :disabled="!option.checkbox"
                                  :color="colors"
                                  :width="54"
                                  :height="28"
                                  :labels="true"
                    >
                        <template #checked>
                            <svg viewBox="0 0 512 512" class="relative right-0.5 w-4 h-7 text-white stroke-current"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                                      d="M416 128 192 384l-96-96"
                                />
                            </svg>
                        </template>
                        <template #unchecked>
                            <svg viewBox="0 0 16 16" class="w-3 h-7 text-white stroke-current" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M2.375 13.624l11.25-11.25M13.625 13.624L2.375 2.374" stroke-linecap="round"
                                      stroke-linejoin="round"
                                />
                            </svg>
                        </template>
                    </ToggleButton>
                </span>
            </div>
            <p class="text-para-s md:text-para-base mt-4 md:mt-2">{{ option.text }}</p>
        </li>
    </ul>
</template>

<script lang="ts">
import CookiePolicy from './CookiePolicy.vue';
import Utils from '../../utils/Utils';
import ToggleButton from "../base/ToggleButton.vue";
import {nextTick} from "vue";

export default {
    components: {ToggleButton},
    extends: CookiePolicy,
    data() {
        return {
            init: false
        };
    },
    computed: {
        colors(): Record<string, string> {
            return {
                checked: 'rgb(var(--color-primary-500))',
                unchecked: 'rgb(var(--color-black-400))',
                disabled: 'rgb(var(--color-black-200))'
            };
        }
    },
    watch: {
        grantModel: {
            async handler() {
                if (this.init) {
                    this.updateConsent();
                    Utils.setLocalStorage(this.consentKey, JSON.stringify(this.grantModel));
                }
            },
            deep: true  // deep watch, because object
        }
    },
    created(): void {
        // load saved state if applicable
        const savedConsent = Utils.getLocalStorage(this.consentKey);
        if (savedConsent) {
            // set model from storage
            this.grantModel = JSON.parse(savedConsent);
            nextTick(() => {
                this.init = true;
            });
        }
        // set all necessary options to true
        this.options.filter(o => !o.checkbox).forEach(o => this.grantModel[o.type] = true);
    }
};
</script>
