<template>
    <div class="relative">
        <Dropdown v-model="currentSelection"
                  class="w-full md:w-auto min-w-40 md:flex md:flex-auto"
                  :flex="true"
                  text-classes="text-para-xs md:text-para-s h-4 md:h-6 text-black-600"
                  :title="placeholder"
                  :higher-menu="true"
                  @delete-pressed="unsetValue"
        >
            <div class="cursor-pointer text-black-900 mx-2 mb-4 mt-3 text-para-s flex items-center justify-start relative border border-black-200 rounded">
                <input v-model="currentSearch"
                       type="text"
                       :placeholder="searchLabel"
                       class="w-10/12 h-full py-2 px-2 md:py-2.5 placeholder:text-para-s focus:outline-none placeholder-improvedContrast"
                >
                <div class="absolute top-1/2 transform -translate-y-1/2 right-4 text-black-200">
                    <Icon name="search" class="stroke-current  w-4 h-4" />
                </div>
            </div>
            <div v-for="(gValue, group, index) in sortedGroups()"
                 v-show="shouldGroupRender(group)"
                 :key="index"
                 class="mb-4"
            >
                <div class="px-4 w-full flex items-center justify-start mb-2 cursor-pointer" @click="toggleGroup(group)">
                    <span class="text-para-s text-black-400 mr-4 md:mr-3 xl:mr-4">{{ group }}</span>
                    <div class="w-3 h-3 md:w-2 md:h-2 xl:w-3 xl:h-3 text-black-600 flex items-center justify-start">
                        <Icon name="chevron-down" class="stroke-current w-full h-full transition-transform transform origin-center" :class="{ 'rotate-180': isGroupOpen(group) }" />
                    </div>
                </div>
                <CollapseTransition>
                    <div v-show="isGroupOpen(group)" class="pl-2">
                        <DropdownItem
                            v-for="item in gValue"
                            v-show="shouldItemRender(item)"
                            :key="item"
                            :value="item"
                            :label="item"
                            :show-label="false"
                            text-classes="pb-2 pt-2 group"
                        >
                            <span class="block text-para-s text-black-400 transition-colors group-hover:text-white group-focus:text-white">{{ item }}</span>
                        </DropdownItem>
                    </div>
                </CollapseTransition>
            </div>
        </Dropdown>
        <div v-show="currentSelection" class="absolute w-3 h-full top-0 right-9 flex justify-center items-center text-black-500 cursor-pointer" @click="unsetValue">
            <Icon name="close" class="stroke-current w-full" />
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import DropdownItem from "./DropdownItem.vue";
import Icon from "./Icon.vue";
import Dropdown from "./Dropdown.vue";
import CollapseTransition from "./CollapseTransition.vue";

export default {
    components: {CollapseTransition, Dropdown, Icon, DropdownItem},
    props: {
        groups: {type: Object as PropType<Record<string, string[]>>, default: () => {}},
        modelValue: {type: String},
        placeholder: {type: String, default: ''},
        searchLabel: {type: String, default: ''},
        sortGroupEntries: {type: Boolean, default: false}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            openGroups: [],
            currentSelection: '',
            currentSearch: '',
        };
    },
    watch: {
        async currentSelection() {
            this.$emit('update:modelValue', this.currentSelection);
        }
    },
    mounted(): void {
        if (Object.keys(this.groups).length > 0) {
            this.openGroup(Object.keys(this.groups)[0]);
        }
        // respect the initial v-model value
        if (this.modelValue) {
            this.currentSelection = this.modelValue;
        }
    },
    methods: {
        shouldGroupRender(group: string): boolean {
            if (!this.currentSearch) return true;
            return this.groups[group].some(item => this.shouldItemRender(item));
        },
        shouldItemRender(item: string): boolean {
            if (item === '​') return false;
            if (!this.currentSearch) return true;
            return item.toLowerCase().includes(this.currentSearch.toLowerCase());
        },
        unsetValue(): void {
            this.currentSelection = '';
            this.currentSearch = '';
        },
        toggleGroup(group: string) {
            if (group) {
                if (this.isGroupOpen(group)) {
                    this.closeGroup(group);
                } else {
                    this.openGroup(group);
                }
            }
        },
        openGroup(group: string): void {
            if (group) {
                this.openGroups.push(group);
            }
        },
        closeGroup(group: string): void {
            if (group) {
                this.openGroups.splice(this.openGroups.indexOf(group), 1);
            }
        },
        isGroupOpen(group: string): boolean {
            return group ? this.openGroups.includes(group) : false;
        },
        /**
         * If 'sortGroups' is true, this method returns the entries of 'groups' sorted alphabetically (ignoring case),
         * thereby not touching the sort order of the group keys.
         * If 'sortGroups' is false, this method returns the entries of 'groups' unsorted.
         */
        sortedGroups(): Record<string, string[]> {
            if (this.sortGroupEntries) {
                const keys: string[] = (Object.keys(this.groups) as Array<string>);
                type GroupEntries = Record<string, string[]>;
                const result: GroupEntries = {};

                for (const key of keys) {
                    const entries: string[] = this.groups[key];

                    // see https://stackoverflow.com/questions/8996963/how-to-perform-case-insensitive-sorting-array-of-string-in-javascript
                    result[key] = [...entries].sort((a, b) =>
                        a.localeCompare(b, undefined, { sensitivity: 'base' })
                    );
                }

                return result;
            } else {
                return this.groups;
            }
        }
    }
};
</script>
