import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-para-s font-semibold" }
const _hoisted_2 = {
  key: 0,
  "aria-hidden": "true"
}
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: $options.model[$props.name],
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($options.model[$props.name]) = $event)),
    name: $props.title,
    rules: $options.rules,
    as: "div",
    mode: "eager",
    disabled: $options.validationDisabled
  }, {
    default: _withCtx(({ errors, field }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString($props.title), 1),
        ($props.required)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, " *"))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Dropdown, _mergeProps({ id: $props.name }, field, {
        modelValue: $options.model[$props.name],
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => (($options.model[$props.name]) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => ($options.openArea($options.model[$props.name])))
        ],
        name: $props.name,
        title: $props.placeholder,
        value: $props.defaultValue,
        required: $props.required,
        error: errors.length > 0,
        "described-by": errors.length > 0 ? `${$props.name}-error` : '',
        white: true,
        class: "w-full min-w-40"
      }), {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.options, (label, value, index) => {
            return (_openBlock(), _createBlock(_component_DropdownItem, {
              key: index,
              value: value,
              label: label
            }, null, 8, ["value", "label"]))
          }), 128))
        ]),
        _: 2
      }, 1040, ["id", "modelValue", "name", "title", "value", "required", "error", "described-by"]),
      _withDirectives(_createVNode(_component_CollapseTransition, {
        onAfterEnter: $options.calcHeight,
        onAfterLeave: $options.calcHeight
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["onAfterEnter", "onAfterLeave"]), [
        [_vShow, $data.opened || $props.editMode]
      ]),
      (errors.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            id: `${$props.name}-error`,
            class: "text-error text-para-xs mt-4"
          }, _toDisplayString(errors[0]), 9, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["modelValue", "name", "rules", "disabled"]))
}