import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_marker = _resolveComponent("custom-marker")!
  const _component_marker_cluster = _resolveComponent("marker-cluster")!
  const _component_google_map = _resolveComponent("google-map")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["map relative w-full h-80 md:h-120 xl:h-180 z-20 xl:z-auto", { 'xl:w-full': !$options.fitMap, 'xl:w-3/4': $options.fitMap }])
  }, [
    _createVNode(_component_google_map, {
      ref: "mapRef",
      "api-key": $data.apiKeyGM,
      styles: $data.mapStyles,
      center: $data.center,
      zoom: $data.zoom,
      "min-zoom": 2,
      "max-zoom": 15,
      class: "w-full h-full",
      style: {"width":"100%","height":"100%"},
      "street-view-control": false,
      "fullscreen-control": false,
      "map-type-control": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_marker_cluster, {
          options: {
                    algorithm: $options.clusterAlgorithm(),
                    renderer: $data.clusterRenderer,
                }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.markers, (marker, i) => {
              return (_openBlock(), _createBlock(_component_custom_marker, {
                key: i,
                options: {
                        position: marker.position,
                        anchorPoint: 'BOTTOM_CENTER',
                        offsetY: -40,
                    },
                clickable: true,
                label: {
                        color: marker.label ? 'white' : 'transparent',
                        fontFamily:
                            'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
                        fontSize: '12px',
                        fontWeight: '100',
                        text: marker.label || '',
                    },
                onClick: ($event: any) => (
                        !$props.isOfficeMap
                            ? $options.openLocation(marker)
                            : $options.selectOffice(marker)
                    )
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "relative bg-white px-4 py-2 flex items-center justify-center border rounded-full text-para-xs",
                    style: _normalizeStyle($options.getMarkerStyle(marker))
                  }, [
                    _createTextVNode(_toDisplayString(marker.label || marker.area || "") + " ", 1),
                    _createElementVNode("div", {
                      class: "absolute border-1 w-0.5 top-full h-10",
                      style: _normalizeStyle({
                                backgroundColor: !$props.isOfficeMap
                                    ? marker?.category.color
                                    : '#00928E',
                                borderColor: !$props.isOfficeMap
                                    ? marker?.category.color
                                    : '#00928E',
                                left: 'calc(50% - 2px)',
                            })
                    }, null, 4)
                  ], 4)
                ]),
                _: 2
              }, 1032, ["options", "label", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["options"])
      ]),
      _: 1
    }, 8, ["api-key", "styles", "center", "zoom"])
  ], 2))
}