<template></template>

<script lang="ts">
import {PropType} from "vue";

export default {
    inject: ['registerCarouselItem'],
    props: {
        title: {type: String},
        subTitle: {type: String},
        description: {type: String},
        // *** begin used in carousel ***
        link: {type: String},
        target: {type: String},
        label: {type: String},
        ariaLabel: {type: String},
        // *** end used in carousel ***
        video: {type: String},
        urls: {type: Object as PropType<Record<string, string>>, required: true},
        variationSm: {type: String, default: 'sm'},
        variationMd: {type: String, default: 'md'},
        variationLg: {type: String, default: 'lg'},
        variationXl: {type: String, default: 'xl'},
        original: {type: String, default: 'original'}
    },
    computed: {
        videoType() {
            return this.video ? this.video.substring(this.video.lastIndexOf('.') + 1) : 'mp4';
        }
    },
    mounted(): void {
        if (this.registerCarouselItem) {
            const { title, subTitle, description, link, target, label, ariaLabel, video, urls, variationSm, variationMd, variationLg, variationXl, original } = this;
            this.registerCarouselItem({
                title,
                subTitle,
                description,
                link,
                target,
                label,
                ariaLabel,
                video,
                urls,
                variationSm,
                variationMd,
                variationLg,
                variationXl,
                original,
                videoType: this.videoType
            });
        }
    }
};
</script>
