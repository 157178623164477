<template>
    <div v-if="visible" class="top-0 w-full bg-secondary-200 z-50" :class="{ 'fixed': !editMode }">
        <div class="relative container h-38 md:h-35 py-4 md:py-8">
            <div class="absolute top-4 md:top-8 right-4 md:right-8 text-black-900 cursor-pointer" @click="hide">
                <Icon name="close" class="w-4 h-4 stroke-current" />
            </div>
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import {mapState} from "pinia";
import {useNavigationStore} from "../../stores/navigation";
import Icon from "./Icon.vue";

export default {
    components: {Icon},
    props: {
        modelValue: {type: Boolean, default: false},
        showOnLoad: {type: Boolean, default: false},
        showAlways: {type: Boolean, default: false},
        editMode: {type: Boolean, default: false},
        delay: {type: Number, default: 500}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            visible: false
        };
    },
    computed: {
        ...mapState(useNavigationStore, ['infoBannerOpened'])
    },
    watch: {
        async modelValue(newVal: boolean) {
            if (newVal) {
                this.show();
            } else {
                this.hide();
            }
        }
    },
    mounted(): void {
        const alreadyShown = !!Cookies.get('gc-banner-shown');
        if (this.editMode) {
            this.visible = true;
        } else if (this.showAlways || (this.showOnLoad && !alreadyShown)) {
            setTimeout(() => this.show(), this.delay);
        }
    },
    methods: {
        hide(): void {
            if (!this.editMode && !this.showAlways) {
                this.visible = false;
                this.navStore.setInfoBannerOpened(false);
                Cookies.set('gc-banner-shown', 'true');
            }
            this.$emit('update:modelValue', false);
        },
        show(): void {
            if (!this.editMode) {
                this.visible = true;
                this.infoBannerOpened = true;
            }
            this.$emit('update:modelValue', true);
        }
    }
};
</script>
