import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-1 h-1 md:w-2 md:h-2 relative flex items-center justify-center flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_marker = _resolveComponent("custom-marker")!

  return ($props.visible)
    ? (_openBlock(), _createBlock(_component_custom_marker, {
        key: 0,
        options: {
            position: $props.feature.position
        },
        clickable: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "w-full h-full rounded-full",
              style: _normalizeStyle({ backgroundColor: $props.feature.dotColor })
            }, null, 4),
            _createElementVNode("div", {
              class: "absolute h-full flex items-center justify-center top-3.5",
              style: _normalizeStyle({ color: $props.feature.fontColor })
            }, [
              _createElementVNode("span", {
                class: _normalizeClass($props.feature.fontClasses)
              }, _toDisplayString($props.feature.name), 3)
            ], 4)
          ])
        ]),
        _: 1
      }, 8, ["options"]))
    : _createCommentVNode("", true)
}