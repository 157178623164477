import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "flex justify-between items-center py-3 md:py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!

  return (_openBlock(), _createElementBlock("div", {
    id: $props.anchor,
    class: _normalizeClass({
             'border-b': !$options.checkNestedAccordion || !$props.isLast,
             'border-black-900': !$options.checkNestedAccordion,
             'border-black-500': $options.checkNestedAccordion
         })
  }, [
    _createElementVNode("button", {
      class: "focus-primary w-full text-left group",
      "aria-label": $props.modelValue ? $options.$globalLabels.close : $options.$globalLabels.open,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toggle && $options.toggle(...args)))
    }, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["transition-colors text-para-s md:text-para-base group-hover:text-primary-500 group-focus:text-primary-500", { 'font-semibold': !$options.checkNestedAccordion }])
        }, _toDisplayString($props.title), 3),
        _createVNode(_component_ArrowButton, {
          direction: "down",
          class: _normalizeClass(["flex-shrink-0 w-10 h-10 transition-transform transform origin-center stroke-current mx-4", { 'rotate-180': $props.modelValue, 'text-black-500': $options.checkNestedAccordion }])
        }, null, 8, ["class"])
      ])
    ], 8, _hoisted_2),
    _withDirectives(_createVNode(_component_CollapseTransition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _: 3
    }, 512), [
      [_vShow, $props.modelValue]
    ])
  ], 10, _hoisted_1))
}