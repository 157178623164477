import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "languageHint",
  class: "hidden xl:block absolute left-0 top-full bottom-0 w-full h-fit text-right bg-black-50 text-black-900 py-4 xl:px-28"
}
const _hoisted_2 = {
  key: 0,
  class: "absolute top-0 right-32 transform rotate-45 -translate-y-1/2 h-6 w-6 bg-black-50"
}
const _hoisted_3 = {
  key: 0,
  class: "fixed left-0 top-0 w-full h-full bg-black-900 bg-opacity-30"
}
const _hoisted_4 = { class: "container h-full py-22 md:py-28" }
const _hoisted_5 = { class: "relative xl:w-2/3 mx-auto bg-primary-50 py-6 pl-8 pr-18" }
const _hoisted_6 = { class: "text-para-s text-black-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["relative flex items-center w-full h-14 md:h-20 text-para-xs transition-colors", {'text-black-700 bg-white': $options.showBackground, 'text-white': !$options.showBackground}])
  }, [
    _renderSlot(_ctx.$slots, "default", {
      navigationOpened: _ctx.navigationOpened,
      globalNavOpened: _ctx.globalNavOpened,
      searchOpened: _ctx.searchOpened,
      overlayOpened: $options.overlayOpened,
      showBackground: $options.showBackground,
      showBlackLogo: $options.showBlackLogo,
      toggleNavigation: $options.toggleNavigation,
      toggleGlobalNav: $options.toggleGlobalNavigation,
      toggleSearch: $options.toggleSearch,
      closeOverlays: $options.closeOverlays,
      status: $options.status,
      showLanguageBanner: $props.showLanguageBanner
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($data.bannerModel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              ($props.languageSwitcherShown)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString($props.hint), 1)
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!$props.editMode)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 0,
          name: "fade"
        }, {
          default: _withCtx(() => [
            ($data.noContentModel && $data.prevSelectedLocale)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", {
                        class: "absolute top-0 right-0 flex justify-center items-center w-10 h-10 bg-primary-500 hover:bg-black-900 cursor-pointer",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.noContentModel = false))
                      }, [
                        _createVNode(_component_Icon, {
                          name: "close",
                          class: "w-4 h-4 text-white stroke-current"
                        })
                      ]),
                      _createElementVNode("span", _hoisted_6, _toDisplayString($options.noContentText), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}