<template>
    <div>
        <div v-for="(group, g) in groups"
             :key="g"
             class="mt-12 flex-wrap justify-between items-center -mx-1 mb-4 lg:flex"
        >
            <div class="py-2 self-start px-1 max-w-full lg:max-w-4/12">
                <span v-if="Object.keys(groups).length > 1">{{ getGroupLabel(g) }}</span><span>{{ labelResultsSuffix }}</span>
            </div>
            <div class="relative pr-1 w-full max-w-full lg:pl-4 lg:max-w-8/12">
                <table class="border-collapse table-fixed w-full">
                    <tbody>
                        <tr v-for="(result, res) in group" :key="'res' + res" class="odd:bg-black-50 even:bg-white">
                            <td class="py-2.5 px-4">{{ result.label }}</td>
                            <td class="w-35 py-2.5 px-4">{{ formatValue(result.value) }} {{ result.unit }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {CalcResult} from './calculations';

export default {
    props: {
        results: {type: Array as PropType<Array<CalcResult>>},
        groupLabels: {type: Array as PropType<Array<{ value: string, key: string }>>},
        labelResultsSuffix: {type: String, default: 'Results'}
    },
    computed: {
        groups() {
            return this.results.reduce((groups, result) => {
                const tmp = groups;
                const g = result.labelKey.split('.').shift();
                if (!Object.prototype.hasOwnProperty.call(tmp, g)) {
                    tmp[g] = [];
                }
                tmp[g].push(result);
                return tmp;
            }, {});
        }
    },
    methods: {
        getGroupLabel(key) {
            const label = this.groupLabels.find(x => x.key === key.replace(/\d/g, ''));
            if (label) {
                return `${key.replace(/[a-zA-Z_]+/, label.value).replace(/(\d)$/, (_, $1) => ` ${$1}`)} - `;
            }
            return '';
        },
        // rounds to 1 decimal and inserts thousand separators
        formatValue(value) {
            return value.toFixed(1)
                // insert , as thousands separators
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
};
</script>
