import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "border-t border-black-200" }
const _hoisted_2 = { class: "flex justify-between items-center gap-4" }
const _hoisted_3 = { class: "font-semibold text-para-s md:text-para-lg" }
const _hoisted_4 = { class: "mr-2 md:mr-6" }
const _hoisted_5 = { class: "text-para-s md:text-para-base mt-4 md:mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButton = _resolveComponent("ToggleButton")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("li", {
        key: option.type,
        class: "border-b border-black-200 pt-4 pb-8"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(option.label), 1),
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_ToggleButton, {
              modelValue: _ctx.grantModel[option.type],
              "onUpdate:modelValue": ($event: any) => ((_ctx.grantModel[option.type]) = $event),
              disabled: !option.checkbox,
              color: $options.colors,
              width: 54,
              height: 28,
              labels: true
            }, {
              checked: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("svg", {
                  viewBox: "0 0 512 512",
                  class: "relative right-0.5 w-4 h-7 text-white stroke-current",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "32",
                    d: "M416 128 192 384l-96-96"
                  })
                ], -1)
              ])),
              unchecked: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("svg", {
                  viewBox: "0 0 16 16",
                  class: "w-3 h-7 text-white stroke-current",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M2.375 13.624l11.25-11.25M13.625 13.624L2.375 2.374",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  })
                ], -1)
              ])),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "color"])
          ])
        ]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(option.text), 1)
      ]))
    }), 128))
  ]))
}