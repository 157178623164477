import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["read-more mb-6 md:mb-8", { inactive: !$data.opened }])
  }, [
    _withDirectives(_createVNode(_component_CollapseTransition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _: 3
    }, 512), [
      [_vShow, $data.opened]
    ]),
    _createElementVNode("span", {
      class: "cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.opened = !$data.opened))
    }, _toDisplayString($options.label), 1)
  ], 2))
}