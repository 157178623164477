<template>
    <Form v-slot="{ validate }" as="div">
        <div class="flex items-center justify-between mt-2 mb-6 md:mt-8 md:mb-20">
            <button type="button"
                    class="flex items-center cursor-pointer w-fit"
                    @click="previousStep"
            >
                <Icon name="chevron-left" class="w-3 h-3 text-black-300 stroke-current" />
                <span class="text-black-600 text-para-xs ml-4">{{ labels.backTo }} {{ labels.participation }}</span>
            </button>
            <button type="button"
                    class="hidden md:flex items-center group cursor-pointer"
                    @click="toOverview"
            >
                <span class="text-para-s font-semibold">{{ labels.personalOverview }}</span>
                <ArrowButton direction="person" class="w-10 h-10" />
            </button>
        </div>
        <h2 class="text-heading-2 md:text-heading-1">{{ labels.travelDetails }}</h2>
        <p class="text-para-s md:text-para-xl mt-4 md:mt-2" v-html="labels.travelDetailsDescription" />
        <div class="mt-10 md:mt-14">
            <label>
                <span class="font-semibold text-para-xs md:text-para-s">
                    {{ labels.meansOFTravel }}
                    <dfn title="required">*</dfn>
                </span>
            </label>
            <ToggleButtons v-model="meansOfTravelModel"
                           :items="{ CAR: labels.car, TRAIN: labels.train, AIRPLANE: labels.airplane }"
                           :views="{ TRAIN: 'train', AIRPLANE: 'airplane' }"
                           empty-value="UNKNOWN"
                           grow
                           col-mobile
                           class="mt-4"
            >
                <template #train>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
                        <VueFormSelection v-model="participant.shuttleNeeded"
                                          name="shuttleNeeded"
                                          type="select"
                                          :required="true"
                                          :title="labels.transportationNeeded"
                                          :placeholder="labels.pleaseChoose"
                                          :description="labels.transportationNeededTrainHint"
                                          :labels="[
                                              labels.yes + ':true',
                                              labels.no + ':false'
                                          ]"
                                          @update:modelValue="onFocusChange(true, 'shuttleNeeded', participant.shuttleNeeded)"
                        />
                        <template v-if="participant.shuttleNeeded === 'true'">
                            <VueFormInput v-model="arrivalDateModel"
                                          name="arrivalDate"
                                          :title="labels.arrivalDate"
                                          type="date"
                                          class="col-start-1"
                                          @blur="onFocusChange($event, 'arrivalTime', participant.arrivalTime)"
                            />
                            <VueFormInput v-model="arrivalTimeModel"
                                          name="arrivalTime"
                                          :title="labels.arrivalTime"
                                          type="time"
                                          @blur="onFocusChange($event, 'arrivalTime', participant.arrivalTime)"
                            />
                            <VueFormInput v-model="departureDateModel"
                                          name="departureDate"
                                          :title="labels.departureDate"
                                          type="date"
                                          class="col-start-1"
                                          @blur="onFocusChange($event, 'departureTime', participant.departureTime)"
                            />
                            <VueFormInput v-model="departureTimeModel"
                                          name="departureTime"
                                          :title="labels.departureTime"
                                          type="time"
                                          @blur="onFocusChange($event, 'departureTime', participant.departureTime)"
                            />
                        </template>
                    </div>
                </template>
                <template #airplane>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
                        <VueFormSelection v-model="participant.shuttleNeeded"
                                          name="shuttleNeeded"
                                          type="select"
                                          :required="true"
                                          :title="labels.transportationNeeded"
                                          :placeholder="labels.pleaseChoose"
                                          :description="labels.transportationNeededPlaneHint"
                                          :labels="[
                                              labels.yes + ':true',
                                              labels.no + ':false'
                                          ]"
                                          @update:modelValue="onFocusChange(true, 'shuttleNeeded', participant.shuttleNeeded)"
                        />
                        <template v-if="participant.shuttleNeeded === 'true'">
                            <VueFormInput v-model="participant.arrivalFlightNo"
                                          name="arrivalFlightNo"
                                          :title="labels.arrivalFlightNo"
                                          class="col-start-1"
                                          @blur="onFocusChange($event, 'arrivalFlightNo', participant.arrivalFlightNo)"
                            />
                            <VueFormInput v-model="participant.arrivalFlightFrom"
                                          name="arrivalFlightFrom"
                                          :title="labels.arrivalFlightFrom"
                                          @blur="onFocusChange($event, 'arrivalFlightFrom', participant.arrivalFlightFrom)"
                            />
                            <VueFormInput v-model="arrivalDateModel"
                                          name="arrivalDate"
                                          :title="labels.arrivalDate"
                                          type="date"
                                          @blur="onFocusChange($event, 'arrivalTime', participant.arrivalTime)"
                            />
                            <VueFormInput v-model="arrivalTimeModel"
                                          name="arrivalTime"
                                          :title="labels.arrivalTime"
                                          type="time"
                                          @blur="onFocusChange($event, 'arrivalTime', participant.arrivalTime)"
                            />
                            <VueFormInput v-model="participant.departureFlightNo"
                                          name="departureFlightNo"
                                          :title="labels.departureFlightNo"
                                          class="col-start-1"
                                          @blur="onFocusChange($event, 'departureFlightNo', participant.departureFlightNo)"
                            />
                            <VueFormInput v-model="participant.departureFlightTo"
                                          name="departureFlightTo"
                                          :title="labels.departureFlightTo"
                                          @blur="onFocusChange($event, 'departureFlightTo', participant.departureFlightTo)"
                            />
                            <VueFormInput v-model="departureDateModel"
                                          name="departureDate"
                                          :title="labels.departureDate"
                                          type="date"
                                          class="col-start-1"
                                          @blur="onFocusChange($event, 'departureTime', participant.departureTime)"
                            />
                            <VueFormInput v-model="departureTimeModel"
                                          name="departureTime"
                                          :title="labels.departureTime"
                                          type="time"
                                          @blur="onFocusChange($event, 'departureTime', participant.departureTime)"
                            />
                        </template>
                    </div>
                </template>
            </ToggleButtons>
            <span v-if="showRequired" class="text-error text-para-xs mt-1 md:mt-2">{{ labels.travelRequired }}</span>
        </div>
        <div class="font-black mt-20 mb-4">{{ labels.contactDetails }}:</div>
        <div>{{ event.paName }}</div>
        <div>{{ labels.mail }}: {{ event.paMail }}</div>
        <div>{{ labels.phone }}: {{ event.paPhone }}</div>
        <div>{{ labels.mobile }}: {{ event.paMobile }}</div>
        <p class="text-para-s md:text-para-base mt-10 md:mt-16" v-html="labels.requiredHint" />
        <button class="flex flex-row-reverse items-center group w-full mt-8 md:mt-12"
                @click="onSubmit(validate)"
        >
            <ArrowButton class="w-10 h-10 flex-shrink-0" show-ring-on-mobile />
            <span class="font-semibold text-para-s line-clamp-1">{{ labels.next }}</span>
        </button>
        <div class="md:hidden border-t border-black-200 mt-12">
            <button type="button"
                    class="flex items-center group cursor-pointer mt-6"
                    @click="toOverview"
            >
                <span class="text-para-s font-semibold">{{ labels.personalOverview }}</span>
                <ArrowButton direction="person" class="w-10 h-10" show-ring-on-mobile />
            </button>
        </div>
    </Form>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { GolfEventLabels } from './GolfEventTypes';
import Icon from "../base/Icon.vue";
import ArrowButton from "../base/ArrowButton.vue";
import ToggleButtons from "../base/ToggleButtons.vue";
import VueFormSelection from "../form/VueFormSelection.vue";
import VueFormInput from "../form/VueFormInput.vue";
import { Form } from "vee-validate";

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {Form, VueFormInput, VueFormSelection, ToggleButtons, ArrowButton, Icon},
    inject: ['participant', 'event', 'updateData', 'updateProperty', 'nextStep', 'previousStep', 'toOverview'],
    props: {
        labels: {type: Object as PropType<GolfEventLabels>, required: true}
    },
    data() {
        return {
            dataModel: [],
            showRequired: false
        };
    },
    computed: {
        meansOfTravelModel: {
            get() {
                return [this.participant.meansOfTravel];
            },
            set(newVal: string[]) {
                if (newVal.length) {
                    this.participant['meansOfTravel'] = newVal[0];
                    if (newVal[0] !== 'UNKNOWN') {
                        this.showRequired = false;
                    }
                }
                this.updateProperty('meansOfTravel', this.participant.meansOfTravel);
            }
        },
        arrivalDateModel: {
            get() {
                if (!this.participant.arrivalTime) return undefined;
                const date = new Date(this.participant.arrivalTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone

                const year = date.getUTCFullYear();
                const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
                const day = ('0' + date.getUTCDate()).slice(-2);
                return `${year}-${month}-${day}`;
            },
            set(newVal: string) {
                let timeStr = '00:00';
                if (this.participant.arrivalTime) {
                    const old = new Date(this.participant.arrivalTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone
                    const hours = ('0' + old.getUTCHours()).slice(-2);
                    const minutes = ('0' + old.getUTCMinutes()).slice(-2);
                    timeStr = `${hours}:${minutes}`;
                }
                const date = new Date(`${newVal}T${timeStr}:00.000+02:00`); // in Swiss timezone
                if (!isNaN(date.getTime())) {
                    this.participant['arrivalTime'] = date.getTime();
                }
            }
        },
        arrivalTimeModel: {
            get() {
                if (!this.participant.arrivalTime) return undefined;
                const date = new Date(this.participant.arrivalTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone

                const hours = ('0' + date.getUTCHours()).slice(-2);
                const minutes = ('0' + date.getUTCMinutes()).slice(-2);
                return `${hours}:${minutes}`;
            },
            set(newVal: string) {
                const old = this.participant.arrivalTime
                    ? new Date(this.participant.arrivalTime + 2 * 60 * 60 * 1000) // add two hours to get to Swiss timezone
                    : new Date();
                const dateStr = old.toISOString().split('T')[0];
                const date = new Date(`${dateStr}T${newVal}:00.000+02:00`); // in Swiss timezone
                if (!isNaN(date.getTime())) {
                    this.participant['arrivalTime'] = date.getTime();
                }
            }
        },
        departureDateModel: {
            get() {
                if (!this.participant.departureTime) return undefined;
                const date = new Date(this.participant.departureTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone

                const year = date.getUTCFullYear();
                const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
                const day = ('0' + date.getUTCDate()).slice(-2);
                return `${year}-${month}-${day}`;
            },
            set(newVal: string) {
                let timeStr = '00:00';
                if (this.participant.departureTime) {
                    const old = new Date(this.participant.departureTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone
                    const hours = ('0' + old.getUTCHours()).slice(-2);
                    const minutes = ('0' + old.getUTCMinutes()).slice(-2);
                    timeStr = `${hours}:${minutes}`;
                }
                const date = new Date(`${newVal}T${timeStr}:00.000+02:00`); // in Swiss timezone
                if (!isNaN(date.getTime())) {
                    this.participant['departureTime'] = date.getTime();
                }
            }
        },
        departureTimeModel: {
            get() {
                if (!this.participant.departureTime) return undefined;
                const date = new Date(this.participant.departureTime + 2 * 60 * 60 * 1000); // add two hours to get to Swiss timezone

                const hours = ('0' + date.getUTCHours()).slice(-2);
                const minutes = ('0' + date.getUTCMinutes()).slice(-2);
                return `${hours}:${minutes}`;
            },
            set(newVal: string) {
                const old = this.participant.departureTime
                    ? new Date(this.participant.departureTime + 2 * 60 * 60 * 1000) // add two hours to get to Swiss timezone
                    : new Date();
                const dateStr = old.toISOString().split('T')[0];
                const date = new Date(`${dateStr}T${newVal}:00.000+02:00`); // in Swiss timezone
                if (!isNaN(date.getTime())) {
                    this.participant['departureTime'] = date.getTime();
                }
            }
        }
    },
    created(): void {
        // this should preset the 'now' choice once a user selected at least the means of travel
        if (this.participant.meansOfTravel) {
            this.dataModel = ['now'];
        }
    },
    mounted(): void {
        this.$el.scrollIntoView({behavior: 'smooth'});
    },
    methods: {
        onFocusChange(passed: boolean, name: string, value: any): void {
            if (passed) {
                this.updateProperty(name, value);
            }
        },
        onSubmit(validate: () => Promise<boolean>): void {
            if (!(this.participant.meansOfTravel && this.participant.meansOfTravel !== 'UNKNOWN')) {
                this.showRequired = true;
                return;
            }
            this.showRequired = false;

            validate().then((valid) => {
                if (valid) {
                    this.nextStep();
                }
            });
        }
    }
};
</script>
