import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  class: "text-black-300 group-hover:text-primary-500 group-focus:text-primary-500 transform",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "rounded-full group focus-primary-out",
    "aria-label": $props.ariaLabel,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("circle", {
        cx: "20",
        cy: "20",
        r: "18.5",
        fill: "#fff",
        stroke: "#949494"
      }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("circle", {
        class: "stroke-current transition-all duration-500 ease-in-out dasharray-30 dashoffset-30 group-hover:dashoffset-0 group-focus:dashoffset-0",
        cx: "20",
        cy: "20",
        r: "18.5",
        fill: "#fff"
      }, null, -1)),
      _createElementVNode("path", {
        class: _normalizeClass(["stroke-current transform origin-center transition-colors duration-500 ease-in-out", $options.rotationClass]),
        d: "m16 18.354 3.573 3.573a.25.25 0 0 0 .354 0l3.573-3.573",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }, null, 2)
    ]))
  ], 8, _hoisted_1))
}