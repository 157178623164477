<template>
    <div class="relative flex flex-col overflow-hidden">
        <div class="relative flex justify-between items-center text-para-s border transition-colors cursor-pointer border-black-100 hover:text-black-900 rounded-t"
             :class="{'rounded-b': !open}"
        >
            <input ref="input"
                   v-model="keyword"
                   class="w-full disabled:bg-white pl-4 pr-12 py-2 xl:py-2.5 placeholder-improvedContrast"
                   type="text"
                   :placeholder="placeholder"
                   :readonly="readonly"
                   :tabindex="tabIndex"
                   @input="handleSearch"
                   @keyup.enter="handleSearch"
            >
            <Icon name="search"
                  class="absolute top-1/2 transform -translate-y-1/2 right-4 w-4 h-4 stroke-current"
                  :class="{'text-black-200 ': !open, 'text-black-500': open}"
            />
        </div>
        <CollapseTransition>
            <div v-if="open && hasResults"
                 class="overflow-y-auto w-full bg-white border-r border-b border-l border-black-100 rounded-b z-10"
                 :class="{'absolute top-full max-h-72': absolute}"
            >
                <div v-for="(label, uuid) in filteredEntries"
                     :key="uuid"
                     class="text-black-600 hover:text-white hover:bg-primary-500 text-para-s px-4 py-2 xl:py-2.5"
                     @click="handleClick(uuid)"
                >
                    {{ label }}
                </div>
            </div>
        </CollapseTransition>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Icon from "./Icon.vue";
import CollapseTransition from "./CollapseTransition.vue";

export default {
    components: {CollapseTransition, Icon},
    props: {
        entries: {type: Object as PropType<Record<string, string>>, default: () => {}},
        placeholder: {type: String, default: ''},
        readonly: {type: Boolean, default: false},
        absolute: {type: Boolean, default: false},
        focus: {type: Boolean, default: false},
        tabIndex: {type: Number, default: 0}
    },
    emits: ['selected'],
    data() {
        return {
            keyword: '',
            currentSearch: '',
            open: false
        };
    },
    computed: {
        hasResults(): boolean {
            return Object.keys(this.filteredEntries).length > 0;
        },
        filteredEntries(): Record<string, string> {
            return Object
                .keys(this.entries)
                .filter(key => this.entries[key].toLowerCase().indexOf(this.currentSearch.toLowerCase()) >= 0)
                .reduce((cur, key) => { return Object.assign(cur, { [key]: this.entries[key] }); }, {});
        }
    },
    watch: {
        async focus(newVal: boolean) {
            if (newVal) {
                setTimeout(() => this.$refs.input.focus(), 500);
            }
        }
    },
    methods: {
        handleSearch(): void {
            if (this.keyword.length >= 3) {
                this.currentSearch = this.keyword;
                this.open = true;
            } else {
                this.open = false;
            }
        },
        handleClick(uuid: string): void {
            this.currentSearch = '';
            this.keyword = '';
            this.open = false;
            this.$emit('selected', uuid);
        }
    }
};
</script>
