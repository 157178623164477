import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "w-full md:w-auto min-w-40 md:w-1/3 md:flex md:flex-auto relative md:mr-2 last:mr-0 mb-3 md:mb-0" }
const _hoisted_2 = { class: "cursor-pointer text-black-900 mx-2 mb-4 mt-3 text-para-s flex items-center justify-start relative border border-black-200 rounded" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "w-2/12 h-full text-black-200 flex items-center justify-center absolute right-0 bottom-0" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "w-3 h-3 md:w-2 md:h-2 xl:w-3 xl:h-3 text-black-600 flex items-center justify-start"
}
const _hoisted_7 = { class: "pl-2" }
const _hoisted_8 = { class: "block text-para-s text-black-400 transition-colors group-hover:text-white group-focus:text-white" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      ref: "currentSelectionEL",
      modelValue: $data.currentSelection,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.currentSelection) = $event)),
      class: "w-full md:w-auto min-w-40 md:flex md:flex-auto",
      flex: true,
      "text-classes": "text-para-xs md:text-para-s h-4 md:h-6 text-black-500 truncate pr-8",
      title: ($props.placeholder ? $props.placeholder : $props.scope),
      "default-value": null,
      "higher-menu": true,
      onOpen: $options.focusSearch
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            ref: "searchField",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentSearch) = $event)),
            type: "text",
            placeholder: $props.searchLabel,
            class: "w-10/12 h-full py-2 px-2 md:py-2.5 placeholder:text-para-s placeholder-improvedContrast"
          }, null, 8, _hoisted_3), [
            [_vModelText, $data.currentSearch]
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Icon, {
              name: "search",
              class: "stroke-current w-4 h-4"
            })
          ])
        ]),
        ($data.currentSearch.length === 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.groups, (group) => {
              return (_openBlock(), _createElementBlock("div", {
                key: $props.groups.indexOf(group),
                class: _normalizeClass({ 'mb-4' : $props.groups[$props.groups.indexOf(group) + 1] && !$options.isSingle($props.groups[$props.groups.indexOf(group) + 1]) })
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["px-4 w-full flex items-center justify-start mb-2 cursor-pointer", { 'group px-4 py-3 md:py-2.5 pb-2 pt-2 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white' : $options.isSingle(group) }]),
                  onClick: ($event: any) => ($options.handleGroupClick(group))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["text-para-s text-black-400 mr-4 md:mr-3 xl:mr-4", { 'transition-colors group-hover:text-white group-focus:text-white' : $options.isSingle(group) }])
                  }, _toDisplayString(group.title || group.name), 3),
                  (!$options.isSingle(group))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_Icon, {
                          name: "chevron-down",
                          class: _normalizeClass(["stroke-current w-full h-full transition-transform transform origin-center", { 'rotate-180': $options.isGroupOpen(group) }])
                        }, null, 8, ["class"])
                      ]))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_5),
                (!$options.isSingle(group))
                  ? (_openBlock(), _createBlock(_component_CollapseTransition, { key: 0 }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("div", _hoisted_7, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item, i) => {
                            return (_openBlock(), _createBlock(_component_DropdownItem, {
                              key: i,
                              value: item,
                              label: (item.title || item.name),
                              "show-label": false,
                              "text-classes": "pb-2 pt-2 group",
                              onClick: ($event: any) => ($options.setQuery(item))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_8, _toDisplayString((item.title || item.name)), 1)
                              ]),
                              _: 2
                            }, 1032, ["value", "label", "onClick"]))
                          }), 128))
                        ], 512), [
                          [_vShow, $options.isGroupOpen(group)]
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($options.allItems, (item) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: $options.allItems.indexOf(item)
              }, [
                ($options.shouldItemRender(item))
                  ? (_openBlock(), _createBlock(_component_DropdownItem, {
                      key: 0,
                      value: item,
                      label: (item.title || item.name),
                      "show-label": false,
                      "text-classes": "text-para-s text-black-400 mb-4",
                      onClick: ($event: any) => ($options.setQuery(item))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          innerHTML: $options.highlightSearch((item.title || item.name))
                        }, null, 8, _hoisted_9)
                      ]),
                      _: 2
                    }, 1032, ["value", "label", "onClick"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onOpen"]),
    ($props.currentQuery === $props.scope)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "absolute w-3 h-full top-0 right-9 flex justify-center items-center text-black-500 cursor-pointer",
          "aria-label": $options.$globalLabels.close,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.unsetQuery && $options.unsetQuery(...args))),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => ($options.unsetQuery && $options.unsetQuery(...args)), ["delete"]))
        }, [
          _createVNode(_component_Icon, {
            name: "close",
            class: "stroke-current w-full"
          })
        ], 40, _hoisted_10))
      : _createCommentVNode("", true)
  ]))
}