<template>
    <ul class="hidden md:flex flex-row flex-wrap items-center text-para-xs gap-y-2 h-min">
        <template v-for="(item, index) in items" :key="index">
            <li v-show="isItemVisible(index)"
                class="flex items-center text-black-600 max-w-50 whitespace-nowrap py-2"
                :title="item.label"
            >
                <a :href="item.url" class="overflow-hidden overflow-ellipsis" :aria-label="item.label">{{ item.label }}</a>
                <span v-if="index < (items.length - 1)" class="text-black-300 mx-4">
                    <Icon name="chevron-right" class="stroke-current w-2.5 h-2.5" />
                </span>
            </li>
            <li v-if="showDots && index === 1" class="flex items-center text-black-600 cursor-pointer py-2" @click="opened = true">
                <span>...</span>
                <span v-if="index < (items.length - 1)" class="text-black-300 mx-4">
                    <Icon name="chevron-right" class="stroke-current w-2.5 h-2.5" />
                </span>
            </li>
        </template>
        <template v-if="items.length > 0">
            <li class="text-black-300 mx-4">
                <Icon name="chevron-right" class="stroke-current w-2.5 h-2.5" />
            </li>
            <li class="text-black-900 bg-black-100 rounded-full max-w-50 overflow-hidden whitespace-nowrap overflow-ellipsis px-3 py-2" :title="currentPageLabel">
                {{ currentPageLabel }}
            </li>
        </template>
    </ul>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Icon from "./Icon.vue";

export interface BreadcrumbItem {
    url: string;
    label: string;
}

export default {
    components: {Icon},
    props: {
        items: {type: Array as PropType<Array<BreadcrumbItem>>, required: true},
        currentPageLabel: {type: String, required: true},
        charThreshold: {type: Number, default: 75},
        itemThreshold: {type: Number, default: 3}
    },
    data() {
        return {
            opened: false
        };
    },
    computed: {
        showDots(): boolean {
            return !this.opened && (this.length > this.charThreshold || this.items.length > this.itemThreshold);
        },
        length(): number {
            return this.items.reduce((prev, current) => prev + Math.min(current.label.length, 25), 0) + this.currentPageLabel.length;
        }
    },
    methods: {
        isItemVisible(index: number): boolean {
            return !this.showDots || index === 0 || index === (this.items.length - 1);
        }
    }
};
</script>
