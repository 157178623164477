import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "border-t border-black-900" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "flex items-center py-3" }
const _hoisted_5 = { class: "flex-grow font-semibold transition-colors text-para-s group-hover:text-primary-500 group-focus:text-primary-500" }
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "flex-grow text-left focus:outline-none group",
        "aria-label": $props.modelValue ? $options.$globalLabels.close : $options.$globalLabels.open,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toggle && $options.toggle(...args)))
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_Icon, {
            name: "chevron-down",
            class: _normalizeClass(["flex-shrink-0 w-2 h-2 transition-transform transform origin-center stroke-current ml-1 mr-2", { 'rotate-180': $props.modelValue }])
          }, null, 8, ["class"]),
          _createElementVNode("span", _hoisted_5, _toDisplayString($props.title), 1)
        ])
      ], 8, _hoisted_3),
      ($props.showCheckbox)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "flex items-center",
            for: $props.id
          }, [
            _withDirectives(_createElementVNode("input", {
              id: $props.id,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.checkModel) = $event)),
              class: "absolute opacity-0 text-black-900 text-para-xs",
              type: "checkbox"
            }, null, 8, _hoisted_7), [
              [_vModelCheckbox, $data.checkModel]
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "relative checkbox-label w-4 h-4 text-para-xs" }, null, -1))
          ], 8, _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createVNode(_component_CollapseTransition, { class: "mb-4" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 512), [
      [_vShow, $props.modelValue]
    ])
  ]))
}