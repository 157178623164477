import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["srcset", "sizes", "src"]
const _hoisted_2 = { class: "flex-grow text-para-base mt-6 mb-4" }
const _hoisted_3 = { class: "text-para-s font-semibold" }
const _hoisted_4 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab_header = _resolveComponent("tab-header")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_TeaserLink = _resolveComponent("TeaserLink")!
  const _component_GcButton = _resolveComponent("GcButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'bg-black-50': $props.highlight})
  }, [
    ($options.tags.length > 0)
      ? (_openBlock(), _createBlock(_component_tab_header, {
          key: 0,
          modelValue: $data.currentTag,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentTag) = $event)),
          class: "mb-18",
          tabs: $options.tags.map(t => t.tag),
          "component-uid": _ctx.$.uid
        }, null, 8, ["modelValue", "tabs", "component-uid"]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", {
      class: _normalizeClass(["container grid grid-cols-1 gap-4 xl:gap-8", [
                $data.shownArticles.length > 2 ? 'md:grid-cols-3' : 'md:grid-cols-2',
                {'pb-6 md:pb-8 xl:pb-12': $props.highlight}
            ]])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.shownArticles, (article, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "flex flex-col"
        }, [
          (article.asset)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                srcset: article.asset.sourceSet,
                sizes: article.asset.sizes,
                src: article.asset.source,
                alt: "article.asset.title",
                class: "w-full object-cover object-center"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_2, _toDisplayString(article.title), 1),
          _createVNode(_component_TeaserLink, {
            url: article.link,
            type: article.type,
            title: article.title,
            "aria-label": article.title,
            classes: "flex items-center gap-2 group"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString($props.labels.readMore), 1),
              _createVNode(_component_ArrowButton, {
                direction: $options.getIcon(article.type),
                class: "w-10 h-10"
              }, null, 8, ["direction"])
            ]),
            _: 2
          }, 1032, ["url", "type", "title", "aria-label"])
        ]))
      }), 128))
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      ($data.hasMore)
        ? (_openBlock(), _createBlock(_component_GcButton, {
            key: 0,
            class: "font-semibold mx-auto mt-8",
            label: $props.labels.readMore,
            secondary: true,
            onClick: $options.loadMore
          }, null, 8, ["label", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}