<template>
    <div ref="wrapper"
         class="relative flex flex-col text-white bg-black-800 w-full h-mobile-nav md:h-tablet-nav mt-14 md:mt-20"
    >
        <video
            class="absolute w-full h-full object-cover left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-500 -mt-px"
            :class="[{ 'opacity-100': active },{ 'opacity-0': !active }]"
            draggable="false"
            autoplay
            loop
            muted
            playsinline
            :src="bgVideoLink"
        />
        <div v-if="!hideGradient"
             class="absolute top-0 left-0 w-full h-full opacity-50 bg-black-900 pointer-events-none"
        />
        <div v-if="!overlayOpen" class="absolute w-full h-full">
            <div class="container h-full">
                <div class="relative flex flex-col items-center h-full transition-all duration-500"
                     :class="[{ 'opacity-100': active },{ 'opacity-0': !active }]"
                >
                    <div v-show="!hideLangSwitch" ref="languageSelect"
                         class="flex justify-center items-center mt-18 xl:mt-28 z-10"
                    >
                        <p class="text-para-xs font-semibold mr-2">
                            {{ seeLabel }}
                        </p>
                        <Dropdown v-model="currentLang"
                                  class="min-w-40 md:min-w-50"
                                  title="language"
                        >
                            <DropdownItem v-for="(lang, locale) in locales"
                                          :key="locale"
                                          :label="lang"
                                          :value="locale"
                            />
                        </Dropdown>
                        <p class="text-para-xs font-semibold ml-2">
                            {{ see2Label }}
                        </p>
                    </div>

                    <h1 v-if="title"
                        ref="title"
                        class="text-center text-heading-4 md:text-heading-2 xl:text-stage-title-desktop tracking-tighter md:tracking-tightest mt-12 xl:mt-24"
                        v-html="title"
                    />
                    <p ref="note"
                       class="text-center text-para-xs md:text-para-base xl:text-para-lg mt-5 xl:mt-8"
                       v-html="subTitle"
                    />

                    <template v-if="video || link">
                        <div ref="playButton" class="mt-8 md:mt-6 xl:mt-7">
                            <PlayButton :absolute="false" :aria-label="$globalLabels.play" @click="openVideoModal" />
                        </div>
                        <span ref="watchVideo" class="text-para-xs md:text-para-s mt-2 md:mt-4">
                            {{ watchLabel }}
                        </span>
                    </template>

                    <button v-show="!hideScrollDown"
                            type="button"
                            :aria-label="$globalLabels.next"
                            @click="scrollDown"
                    >
                        <svg ref="scrollIndicator"
                             class="absolute left-1/2 bottom-12 md:bottom-18 transform -translate-x-1/2 w-8"
                             viewBox="0 0 33 57"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M11 42.5L15.7929 47.2929C16.1834 47.6834 16.8166 47.6834 17.2071 47.2929L22 42.5"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                            />
                            <rect x="0.75"
                                  y="0.75"
                                  width="31.5"
                                  height="55.5"
                                  rx="15.75"
                                  stroke="white"
                                  stroke-width="1.5"
                            />
                            <circle cx="16.5"
                                    cy="15.5"
                                    r="1.5"
                                    fill="white"
                            />
                            <circle cx="16.5"
                                    cy="23.5"
                                    r="1.5"
                                    fill="white"
                            />
                            <circle cx="16.5"
                                    cy="31.5"
                                    r="1.5"
                                    fill="white"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <VideoModal :video-id="currentVideo"
                    :account-id="accountId"
                    :player-id="playerId"
                    @close="currentVideo = null"
        />

        <div class="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
            <Transition name="slide">
                <div v-show="overlayOpen"
                     class="absolute left-0 top-0 w-full h-full bg-black-900 opacity-50 pointer-events-auto z-10"
                     :class="{ 'grid grid-cols-2': !editMode }"
                >
                    <slot />
                </div>
            </Transition>
        </div>

        <div v-if="($slots.default && $slots.default.length > 0) || editMode"
             class="absolute w-full bottom-0 text-center z-10 transform translate-y-1/2"
        >
            <button v-scroll-reveal
                    class="focus-primary w-22 h-22 p-3 rounded-full shadow-xl bg-white"
                    :aria-label="$globalLabels.open"
                    @click="openOverlay"
            >
                <svg v-if="!overlayOpen"
                     class="w-16 h-16"
                     viewBox="0 0 64 64"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <defs>
                        <filter id="filter-1">
                            <feColorMatrix in="SourceGraphic"
                                           type="matrix"
                                           values="0 0 0 0 0.000000 0 0 0 0 0.686275 0 0 0 0 0.666667 0 0 0 1.000000 0"
                            />
                        </filter>
                    </defs>
                    <g stroke="none"
                       stroke-width="1"
                       fill="none"
                       fill-rule="evenodd"
                    >
                        <g transform="translate(-687.000000, -822.000000)">
                            <g transform="translate(675.000000, 810.000000)">
                                <g transform="translate(12.000000, 12.000000)">
                                    <rect stroke="#00AFAA"
                                          stroke-width="1.01587302"
                                          fill="#FFFFFF"
                                          x="0.507936508"
                                          y="0.507936508"
                                          width="62.984127"
                                          height="62.984127"
                                          rx="31.4920635"
                                    />
                                    <g filter="url(#filter-1)">
                                        <g transform="translate(17.000000, 17.000000)">
                                            <rect stroke="#00AFAA"
                                                  x="0.5"
                                                  y="0.5"
                                                  width="7"
                                                  height="7"
                                            />
                                            <rect stroke="#00AFAA"
                                                  x="11.5"
                                                  y="0.5"
                                                  width="7"
                                                  height="7"
                                            />
                                            <rect stroke="#00AFAA"
                                                  x="22.5"
                                                  y="0.5"
                                                  width="7"
                                                  height="7"
                                            />
                                            <rect stroke="#00AFAA"
                                                  x="0.5"
                                                  y="11.5"
                                                  width="7"
                                                  height="7"
                                            />
                                            <rect stroke="#00AFAA"
                                                  x="11.5"
                                                  y="11.5"
                                                  width="7"
                                                  height="7"
                                            />
                                            <rect stroke="#00AFAA"
                                                  x="22.5"
                                                  y="11.5"
                                                  width="7"
                                                  height="7"
                                            />
                                            <rect stroke="#00AFAA"
                                                  x="0.5"
                                                  y="22.5"
                                                  width="7"
                                                  height="7"
                                            />
                                            <rect stroke="#00AFAA"
                                                  x="11.5"
                                                  y="22.5"
                                                  width="7"
                                                  height="7"
                                            />
                                            <rect stroke="#00AFAA"
                                                  x="22.5"
                                                  y="22.5"
                                                  width="7"
                                                  height="7"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <svg v-else
                     class="w-16 h-16"
                     viewBox="0 0 64 64"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <g stroke="none"
                       stroke-width="1"
                       fill="none"
                       fill-rule="evenodd"
                    >
                        <g transform="translate(-687.000000, -822.000000)" stroke="#00AFAA">
                            <g transform="translate(675.000000, 810.000000)">
                                <g transform="translate(12.000000, 12.000000)">
                                    <rect stroke-width="1.01587302"
                                          fill="#FFFFFF"
                                          x="0.507936508"
                                          y="0.507936508"
                                          width="62.984127"
                                          height="62.984127"
                                          rx="31.4920635"
                                    />
                                    <g transform="translate(16.000000, 16.000000)"
                                       stroke-linecap="square"
                                       stroke-width="1.06666667"
                                    >
                                        <line id="Line-2"
                                              x1="-5.70114943"
                                              y1="16.3678161"
                                              x2="37.7011494"
                                              y2="15.6321839"
                                              transform="translate(16.000000, 16.000000) rotate(-45.000000) translate(-16.000000, -16.000000) "
                                        />
                                        <line id="Line-2"
                                              x1="-5.70114943"
                                              y1="15.6321839"
                                              x2="37.7011494"
                                              y2="16.3678161"
                                              transform="translate(16.000000, 16.000000) rotate(45.000000) translate(-16.000000, -16.000000) "
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import gsap from 'gsap';
import VideoModal from "./VideoModal.vue";
import { PropType } from "vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import PlayButton from "./PlayButton.vue";

const easeOut = 'Power4.out';

export default {
    components: {PlayButton, DropdownItem, Dropdown, VideoModal},
    inject: ['$globalLabels'],
    props: {
        bgVideoLink: {type: String},
        seeLabel: {type: String},
        see2Label: {type: String},
        watchLabel: {type: String},
        locales: {type: Object as PropType<Record<string, string>>},
        language: {type: String, required: true},
        title: {type: String},
        subTitle: {type: String},
        video: {type: String},
        link: {type: String},
        linkTarget: {type: String, default: '_blank'},
        hideLangSwitch: {type: Boolean, default: false},
        hideScrollDown: {type: Boolean, default: false},
        hideGradient: {type: Boolean, default: false},
        editMode: {type: Boolean},
        accountId: {type: String, required: true},
        playerId: {type: String, required: true}
    },
    data() {
        return {
            currentLang: this.language,
            currentVideo: '',
            active: false,
            overlayOpen: false
        };
    },
    watch: {
        async currentLang() {
            const path = window.location.pathname;
            const localeSegment = `/${this.currentLang}/`;
            window.location.href = path.replace(/\/([a-z]{2,3})\//, localeSegment);
        }
    },
    mounted(): void {
        this.active = true;
        const tl = gsap.timeline({paused: false});
        tl.fromTo(this.$refs.title, {opacity: 0, y: 50}, {opacity: 1, y: 0, duration: 1.0, ease: easeOut}, 0.8);

        if (!this.hideLangSwitch && this.$refs.languageSelect) {
            tl.fromTo(this.$refs.languageSelect, {opacity: 0, y: -20}, {
                opacity: 1,
                y: 0,
                duration: 1.0,
                ease: easeOut
            }, 1.1);
        }

        tl.fromTo(this.$refs.playButton, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.3, ease: easeOut}, 1.6);

        tl.fromTo(this.$refs.note, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.6, ease: easeOut}, 1.4);
        tl.fromTo(this.$refs.watchVideo, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.6, ease: easeOut}, 1.6);

        tl.fromTo(this.$refs.scrollIndicator, {opacity: 0}, {opacity: 1, duration: 0.6}, 1.9);
        this.animateScrollDown(this.$refs.scrollIndicator);
    },
    methods: {
        openVideoModal(): void {
            if (this.video) {
                this.currentVideo = this.video;
            } else if (this.link) {
                window.open(this.link, this.linkTarget).focus();
            }
        },
        openOverlay(): void {
            this.overlayOpen = !this.overlayOpen;
        },
        scrollDown(): void {
            if (!this.hideScrollDown) {
                window.scroll({
                    top: this.$refs.wrapper.clientHeight,
                    behavior: 'smooth'
                });
            }
        },
        animateScrollDown(element): void {
            const tl = gsap.timeline({paused: false, repeat: -1});

            const circles: HTMLCollection = element.querySelectorAll('circle');
            const arrow = element.querySelector('path');
            let timingOffset = 0;

            Array.from(circles).forEach(circle => {
                tl.to(circle, {opacity: 0, duration: 0.2}, timingOffset);
                tl.to(circle, {opacity: 1, duration: 0.2}, timingOffset + 0.2);
                timingOffset += 0.2;
            });

            tl.to(arrow, {y: 3, duration: 0.5}, 0.0);
            tl.to(arrow, {y: 0, duration: 0.5}, 0.5);
        }
    }
};
</script>
