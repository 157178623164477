/* eslint-disable dot-notation */
import { createApp } from "vue";
import { useNavigationStore } from "./stores/navigation";
import { VueMasonryPlugin } from "vue-masonry";
import CountUp from "./directives/count-up";
import ScrollDownDir from "./directives/scroll-down-dir";
import { createScrollRevealDirective } from "vue-scroll-reveal";
import VueGtm from "@gtm-support/vue-gtm";
import { configure, defineRule } from "vee-validate";
import { localize, setLocale } from "@vee-validate/i18n";
import {
    confirmed,
    email,
    integer,
    max,
    max_value,
    mimes,
    min,
    min_value,
    numeric,
    regex,
    required,
    size,
} from "@vee-validate/rules";
import en from "@vee-validate/i18n/dist/locale/en.json";
import de from "@vee-validate/i18n/dist/locale/de.json";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
import es from "@vee-validate/i18n/dist/locale/es.json";
import pt from "@vee-validate/i18n/dist/locale/pt_PT.json";
import ru from "@vee-validate/i18n/dist/locale/ru.json";
import no from "@vee-validate/i18n/dist/locale/nb_NO.json";
import "../node_modules/focus-visible/dist/focus-visible.min.js";
import Vue3SocialSharingPlugin from "../node_modules/vue3-social-sharing";
import Vue3TouchEvents from "vue3-touch-events";
import TabActivator from "./utils/TabActivator";
import { createPinia } from "pinia";
import VueLoader from "./VueLoader";
import flatPickr from "vue-flatpickr-component";
import VueGoogleMaps from "@fawmi/vue-google-maps";

const app = createApp({});

app.config.errorHandler = function(err, vm, info) {
    // Verify if it is production
    if (process.env.NODE_ENV !== "production") {
        // if not, show errors
        console.error(`[Vue error]: ${err}\n${info}`);
    }
};
app.config.warnHandler = function(err, vm, info) {
    // Verify if it is production
    if (process.env.NODE_ENV !== "production") {
        // if not, show warnings
        console.warn(`[Vue warn]: ${err}\n${info}`);
    }
};

// google maps (get key from data attributes)
const gMapsAPIKey = document.body.dataset.gmapsApiKey;

/* Plugins */
app.use(createPinia());
app.use(Vue3TouchEvents);
app.use(VueMasonryPlugin);
app.use(Vue3SocialSharingPlugin);

app.use(VueGoogleMaps, {
    load: {
        key: gMapsAPIKey || "AIzaSyCSrasp7xoSYNhdBANpj51-QEtNjlH0MyY",
    },
});

// tag manager integration
if (document.body.dataset.gtmId) {
    const gtmOptions: Record<string, unknown> = {
        id: document.body.dataset.gtmId,
        defer: false,
        loadScript: true,
        parentElement: document.head,
    };
    if (document.body.dataset.gtmPreview) {
        gtmOptions.queryParams = {
            gtm_auth: document.body.dataset.gtmAuth,
            gtm_preview: document.body.dataset.gtmPreview,
            gtm_cookies_win: "x",
        };
    }
    app.use(VueGtm, gtmOptions);
}

const currentLang = document.documentElement.lang;

// configure vee-validate, i18n: https://vee-validate.logaretm.com/v4/guide/i18n/
setLocale(currentLang);
configure({
    generateMessage: localize({
        en,
        de,
        fr,
        es,
        pt,
        ru,
        no,
    }),
});
// configure vee-validate, rules: https://vee-validate.logaretm.com/v4/guide/global-validators/
defineRule("required", required);
defineRule("email", email);
defineRule("max", max);
defineRule("min", min);
defineRule("numeric", numeric);
defineRule("regex", regex);
defineRule("mimes", mimes);
defineRule("integer", integer);
defineRule("min_value", min_value);
defineRule("max_value", max_value);
defineRule("confirmed", confirmed);
defineRule("size", size);
defineRule("is_number", (value) => {
    if (!isNaN(value)) return true;
    return "{_field_} must be a number";
});

app.directive("count-up", CountUp);
app.directive("scroll-down", ScrollDownDir);
app.directive("scroll-reveal",
    // for options, see https://www.npmjs.com/package/vue-scroll-reveal and https://scrollrevealjs.org/api/reveal.html
    createScrollRevealDirective({
        distance: "3rem",
        duration: 800,
        easing: "ease-out",
        viewFactor: 0.2,
    }));

// linking from tab to tab
/* eslint-disable no-new */
new TabActivator();

// declare types for global variables or prod build will fail
declare module "vue" {
    interface Vue {
        $contextPath: string;
        $resourcePath: string;
        $site: string;
        $lang: string;
        $globalLabels: Record<string, string>;
    }
}

// Init pinia before components
useNavigationStore();

/* Project components */
app.use(VueLoader);
/* External components */
app.component("FlatPickr", flatPickr);

// IMPORTANT FOR GREENBARS
app.config.compilerOptions.comments = true;

app.mount("#app");
