import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-para-s font-semibold" }
const _hoisted_3 = { class: "relative text-black-500" }
const _hoisted_4 = ["name"]
const _hoisted_5 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.editMode)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString($props.title), 1),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-black-500 font-normal italic" }, " -- hidden --", -1))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.model[$props.name]) = $event)),
            class: "w-full text-para-s border rounded focus:outline-none py-2.5 pr-4 pl-4 border-black-200 focus:border-primary-500",
            type: "text",
            name: $props.name
          }, null, 8, _hoisted_4), [
            [_vModelText, $options.model[$props.name]]
          ])
        ])
      ]))
    : _withDirectives((_openBlock(), _createElementBlock("input", {
        key: 1,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.model[$props.name]) = $event)),
        type: "hidden",
        name: $props.name
      }, null, 8, _hoisted_5)), [
        [_vModelText, $options.model[$props.name]]
      ])
}