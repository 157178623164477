import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full mb-4"
}
const _hoisted_2 = { class: "flex flex-col space-y-2 md:flex-row md:justify-between md:items-end md:space-y-0" }
const _hoisted_3 = { class: "w-full md:w-auto pb-0.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueFormCheckbox = _resolveComponent("VueFormCheckbox")!
  const _component_VueRecaptcha = _resolveComponent("VueRecaptcha")!
  const _component_GcButton = _resolveComponent("GcButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($options.hasPrivacy)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_VueFormCheckbox, {
            modelValue: $data.checked,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.checked) = $event)),
            name: "privacy",
            required: ""
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "privacytext")
            ]),
            _: 3
          }, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!$options.isDev())
        ? (_openBlock(), _createBlock(_component_VueRecaptcha, {
            key: 0,
            sitekey: $props.siteKey,
            onVerify: $options.onVerify,
            onExpired: $options.onExpire
          }, null, 8, ["sitekey", "onVerify", "onExpired"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_GcButton, {
          class: "w-full md:w-auto font-semibold disabled:text-black-600",
          type: "submit",
          label: $props.label,
          disabled: $props.disabled || !$data.verified || !$data.checked
        }, null, 8, ["label", "disabled"])
      ])
    ])
  ]))
}