<template>
    <Field v-slot="{ field, errors }"
           v-model="model[name]"
           class="flex flex-col border border-black-200 rounded p-4 md:pr-28 mt-4 mb-8"
           :rules="rules"
           :name="title"
           as="div"
           :disabled="validationDisabled"
    >
        <span class="text-para-s font-semibold mb-4">
            {{ title }} <span aria-hidden="true">*</span>
        </span>
        <div class="flex flex-col md:flex-row gap-x-6 gap-y-2">
            <NumberInput v-bind="field"
                         v-model="model[name]"
                         class="appearance-none text-para-lg font-semibold focus:outline-none w-full md:w-28"
                         :name="name"
                         aria-required="true"
                         :aria-invalid="errors.length > 0"
                         :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
                         :options="options"
                         @update:model-value="updateSliderValue"
            />
            <div class="flex-grow">
                <!-- Slider is bound to sliderModel and not to model[name] directly, as v-bind doesn't seem to work here -->
                <VueSlider v-if="initialised"
                           v-model="sliderModel"
                           :height="2"
                           :min="min"
                           :max="max"
                           :adsorb="true"
                           :interval="interval"
                           :process-style="{backgroundColor: '#00928e'}"
                           :dot-style="{backgroundColor: '#00928e'}"
                           tooltip="none"
                           @change="(value: number) => model[name] = value"
                           @dragging="(value: number) => model[name] = value"
                />
                <div class="flex justify-between mt-px">
                    <span class="text-para-xs text-black-500">{{ _min }} {{ unit }}</span>
                    <span class="text-para-xs text-black-500">{{ _max }} {{ unit }}</span>
                </div>
            </div>
        </div>
        <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-4">
            {{ errors[0] }}
        </span>
    </Field>
</template>

<script lang="ts">
import {CurrencyInputOptions} from 'vue-currency-input';
import {formattingOptions} from './MultiStepForm.vue';
import NumberInput from "../../base/NumberInput.vue";
import {Field} from "vee-validate";
import VueSlider from "vue-3-slider-component";
import {nextTick} from "vue";

export default {
    components: {Field, NumberInput, VueSlider},
    inject: ['model', 'currentStep'],
    props: {
        title: {type: String, required: true},
        name: {type: String, required: true},
        min: {type: Number, default: 0},
        max: {type: Number, default: 1000},
        interval: {type: Number, default: 10},
        stepIndex: {type: Number, required: true},
        unit: {type: String}
    },
    data() {
        return {
            sliderModel: 0,
            initialised: false
        };
    },
    computed: {
        validationDisabled(): boolean {
            return this.currentStep !== this.stepIndex;
        },
        options(): CurrencyInputOptions {
            return formattingOptions;
        },
        _min(): string {
            return new Intl.NumberFormat().format(this.min);
        },
        _max(): string {
            return new Intl.NumberFormat().format(this.max);
        },
        rules(): Record<string, unknown> {
            /* eslint-disable */
            return {
                min_value: this.min,
                max_value: this.max,
                required: true
            };
            /* eslint-enable */
        }
    },
    mounted(): void {
        if (!this.model[this.name]) {
            this.model[this.name] = ((this.max - this.min) / 2) + this.min;
        }

        this.sliderModel = this.model[this.name];
        this.initialised = true;
    },
    methods: {
        updateSliderValue(newValue: number): void {
            if (newValue &&
                newValue >= this.min &&
                newValue <= this.max) {
                // in range!
                nextTick(() => this.sliderModel = newValue);
            } else {
                // illegal value: set slider to min value
                nextTick(() => this.sliderModel = this.min);
            }
        }
    }
};
</script>
