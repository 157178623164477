<template>
    <Field v-slot="{ field, errors, handleChange, meta }"
           ref="field"
           v-model="model"
           :name="name"
           mode="eager"
           :rules="rules"
           :disabled="disabled"
           :vid="name"
           as="div"
    >
        <label v-if="title" :for="name">
            <span class="font-semibold text-para-xs md:text-para-s">
                {{ title }}
                <dfn v-if="required" aria-hidden="true" title="required">{{ requiredSymbol }}</dfn>
            </span>
        </label>

        <div class="relative text-black-500">
            <input v-if="rows === 1 || type === 'number'"
                   :id="name"
                   v-bind="field"
                   :name="name"
                   :type="type"
                   :title="patternDescription"
                   :readonly="readonly"
                   :disabled="disabled"
                   :autofocus="autofocus"
                   :autocomplete="autocomplete"
                   :placeholder="placeholder"
                   :aria-required="required"
                   :aria-invalid="hasError(errors)"
                   :aria-describedby="getDescribedById(errors)"
                   class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 placeholder-improvedContrast"
                   :class="getClasses(meta)"
                   @change="handleChange"
                   @blur="onBlur"
            >
            <textarea v-if="rows > 1 && type === 'text'"
                      :id="name"
                      v-bind="field"
                      :name="name"
                      :rows="rows"
                      :title="patternDescription"
                      :readonly="readonly"
                      :disabled="disabled"
                      :autofocus="autofocus"
                      :autocomplete="autocomplete"
                      :placeholder="placeholder"
                      :aria-invalid="hasError(errors)"
                      :aria-describedby="getDescribedById(errors)"
                      class="w-full text-para-xs md:text-para-s border focus:border-primary-500 rounded resize-none focus:outline-none pr-10 py-2 md:py-2.5 placeholder-improvedContrast"
                      :class="getClasses(meta)"
                      @change="handleChange"
                      @blur="onBlur(meta.valid)"
            />
            <Icon v-if="type === 'email'" name="mail" class="absolute stroke-current w-4 h-10 md:h-11 left-4 top-px" />
            <Icon v-if="meta.validated" :name="determineIconName(meta)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
        </div>

        <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-1 md:mt-2">
            {{ patternDescription || errors[0] }}
        </span>
        <span v-else-if="description" :id="`${name}-description`" class="text-para-xs mt-1 md:mt-2">
            {{ description }}
        </span>
    </Field>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Icon from "../base/Icon.vue";
import {Field, FieldMeta} from "vee-validate";

export default {
    components: {Field, Icon},
    props: {
        name: {type: String},
        title: {type: String},
        patternDescription: {type: String},
        description: {type: String},
        defaultValue: {type: String},
        type: {type: String, default: 'text'},
        readonly: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        autofocus: {type: Boolean, default: false},
        required: {type: Boolean, default: false},
        isError: {type: Boolean, default: false},
        requiredSymbol: {type: String, default: '*'},
        autocomplete: {type: String, default: 'on'},
        rows: {type: Number, default: 1},
        placeholder: {type: String},
        lowerCase: {type: Boolean, default: false},
        rules: {type: Object as PropType<Record<string, unknown>>},
        modelValue: {type: [String, Number, Object]}
    },
    emits: ['blur', 'update:modelValue'],
    data() {
        return {
            model: ''
        };
    },
    watch: {
        async model() {
            if (this.model && this.lowerCase) {
                this.model = this.model.toLowerCase();
            }
            this.$emit('update:modelValue', this.model);
        }
    },
    mounted(): void {
        if (this.modelValue) {
            this.model = this.modelValue;
        } else if (this.defaultValue) {
            this.model = this.defaultValue;
        }
    },
    methods: {
        getClasses(meta: FieldMeta<any>): Record<string, boolean> {
            return {
                'pl-10': this.type === 'email',
                'pl-4': this.type !== 'email',
                'text-black-300 bg-black-50': this.disabled,
                'text-black-900': !this.disabled,
                'md:border-error': meta.validated && !meta.valid,
                'md:border-black-900': meta.validated && meta.valid,
                'border-black-100': !meta.validated
            };
        },
        determineIconName(meta: FieldMeta<any>): string {
            if (meta.valid) {
                return 'valid';
            } else {
                return 'invalid';
            }
        },
        hasError(errors: string[]) {
            return errors.length > 0 || this.isError;
        },
        getDescribedById(errors: string[]): string {
            if (this.hasError(errors)) {
                return `${this.name}-error`;
            } else if (this.description) {
                return `${this.name}-description`;
            }
            return '';
        },
        async onBlur(): Promise<void> {
            // https://github.com/logaretm/vee-validate/discussions/3544
            // trigger manual validation, as the 'passed' variable will always be false on first call
            const result = await this.$refs.field.validate();
            this.$emit('blur', result.valid);
        }
    }
};
</script>
