<template>
    <div
        class="w-full flex flex-col pt-7 px-5 md:w-2/3 md:ml-auto md:flex-row md:flex-wrap md:justify-start md:items-center xl:w-3/4"
    >
        <span class="text-para-xs uppercase md:w-full md:pb-1">
            {{ label }}
        </span>
        <div v-for="(operation, index) in operations"
             :key="index"
             class="w-full relative flex flex-row items-center justify-start mt-2 md:w-auto md:mr-6 md:mt-3"
        >
            <div class="w-1 h-full absolute top-0 left-0"
                 :style="{ 'backgroundColor': cssColor(operation.color) }"
            />
            <div class="ml-2 py-1 px-3 rounded-xl text-para-xs font-semibold"
                 :style="{ 'backgroundColor': cssColor(lightenColor(operation.color)) }"
            >
                {{ operation.title || operation.name || "" }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {Operation} from './SocioEconomicMapUtils';

export default {
    props: {
        label: {type: String, default: 'Glencore\'s Operations'},
        operations: {type: Array as PropType<Array<Operation>>, default: () => ([])}
    },
    data() {
        return {
            lightenFactor: '34'
        };
    },
    methods: {
        cssColor(color: string): string {
            return color[0] === '#' ? color : '#' + color;
        },
        lightenColor(color: string): string {
            return color + this.lightenFactor;
        }
    }
};
</script>
