<template>
    <div class="border-t border-black-900">
        <div class="flex">
            <button class="flex-grow text-left focus:outline-none group" :aria-label="modelValue ? $globalLabels.close : $globalLabels.open" @click="toggle">
                <span class="flex items-center py-3">
                    <Icon name="chevron-down"
                          class="flex-shrink-0 w-2 h-2 transition-transform transform origin-center stroke-current ml-1 mr-2"
                          :class="{ 'rotate-180': modelValue }"
                    />
                    <span class="flex-grow font-semibold transition-colors text-para-s group-hover:text-primary-500 group-focus:text-primary-500">
                        {{ title }}
                    </span>
                </span>
            </button>
            <label v-if="showCheckbox" class="flex items-center" :for="id">
                <input :id="id"
                       v-model="checkModel"
                       class="absolute opacity-0 text-black-900 text-para-xs"
                       type="checkbox"
                >
                <span class="relative checkbox-label w-4 h-4 text-para-xs" />
            </label>
        </div>
        <CollapseTransition v-show="modelValue" class="mb-4">
            <slot />
        </CollapseTransition>
    </div>
</template>

<script lang="ts">

import Icon from "../base/Icon.vue";
import CollapseTransition from "../base/CollapseTransition.vue";

export default {
    components: {CollapseTransition, Icon},
    inject: ['$globalLabels'],
    props: {
        id: {type: String},
        title: {type: String},
        modelValue: {type: Boolean},
        showCheckbox: {type: Boolean, default: true},
        check: {type: Boolean, required: true}
    },
    emits: ['change', 'update:modelValue'],
    data() {
        return {
            checkModel : this.check
        };
    },
    watch: {
        async check() {
            this.checkModel = this.check;
        },
        async checkModel() {
            this.$emit('change', this.checkModel);
        }
    },
    methods: {
        toggle(): void {
            this.$emit('update:modelValue', !this.modelValue);
        }
    }
};
</script>
