import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative hidden md:flex justify-center text-white text-para-xs md:pt-4 md:pb-8 xl:pb-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabHeader = _resolveComponent("TabHeader")!
  const _component_SocialFilter = _resolveComponent("SocialFilter")!
  const _component_SocialTabSection = _resolveComponent("SocialTabSection")!
  const _component_GcButton = _resolveComponent("GcButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.showFilter)
      ? (_openBlock(), _createBlock(_component_TabHeader, {
          key: 0,
          modelValue: $data.tabActive,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.tabActive) = $event)),
          tabs: $data.tabItems,
          "in-content-group": $props.inContentGroup,
          narrow: "",
          "component-uid": _ctx.$.uid
        }, null, 8, ["modelValue", "tabs", "in-content-group", "component-uid"]))
      : _createCommentVNode("", true),
    ($props.showFilter && $data.isFilterActive)
      ? (_openBlock(), _createBlock(_component_SocialFilter, {
          key: 1,
          accounts: $data.dataRef,
          "label-social-filter": $props.labels.labelFilter,
          "in-content-group": $props.inContentGroup
        }, null, 8, ["accounts", "label-social-filter", "in-content-group"]))
      : _createCommentVNode("", true),
    _createVNode(_component_SocialTabSection, {
      data: $data.data,
      layout: $props.layout,
      class: _normalizeClass({'bg-black-50': $props.highlight}),
      "show-label": true,
      "in-content-group": $props.inContentGroup,
      visible: $props.visible,
      "label-tab-section": $props.labels.labelSocialMedia
    }, null, 8, ["data", "layout", "class", "in-content-group", "visible", "label-tab-section"]),
    ($data.isLoadMoreActive)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass({'bg-black-50': $props.highlight})
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["absolute hidden md:block left-0 -top-38 w-full h-30 bg-gradient-to-t pointer-events-none", [{'from-black-50': $props.highlight},{'from-white': !$props.highlight}]])
            }, null, 2),
            _createVNode(_component_GcButton, {
              class: "font-semibold",
              label: $props.labels.labelLoadMore,
              secondary: true,
              onClick: $options.loadMore
            }, null, 8, ["label", "onClick"])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}