import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["aria-label", "href"]
const _hoisted_2 = ["type", "disabled", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return ($props.url)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: _normalizeClass(["flex flex-row items-center justify-center text-para-xs md:text-para-s text-white rounded border border-transparent focus:outline-none transition-all duration-300 ease-out appearance-none", $options.classes]),
        "aria-label": $props.ariaLabel,
        href: $props.url
      }, [
        _createTextVNode(_toDisplayString($props.label) + " ", 1),
        (!$props.secondary)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              name: $props.icon,
              class: "w-4 h-4 stroke-current transform transition-transform duration-100 ease-out group-hover:translate-x-1 group-focus:translate-x-1 ml-2"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        type: $props.type,
        disabled: $props.disabled,
        class: _normalizeClass(["flex flex-row items-center justify-center text-para-xs md:text-para-s text-white font-semibold rounded border border-transparent focus:outline-none transition-all duration-300 ease-out", $options.classes]),
        "aria-label": $props.ariaLabel,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('click')), ["enter"]))
      }, [
        _createTextVNode(_toDisplayString($props.label) + " ", 1),
        (!$props.secondary)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              name: $props.icon,
              class: "w-4 h-4 stroke-current transform transition-transform duration-100 ease-out group-hover:translate-x-1 group-focus:translate-x-1 ml-2"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ], 42, _hoisted_2))
}