<template>
    <div class="relative w-full h-full bg-white overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out">
        <div class="absolute left-6 top-6 right-6 flex flex-row text-para-xs  group-hover:text-primary-500">
            <svg class="w-4 h-4 fill-current mr-2 transition-all duration-300 ease-out" viewBox="0 0 1200 1227" xmlns="http://www.w3.org/2000/svg">
                <path d="M714.163 519.284 1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026ZM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026Z" />
            </svg>
            <h6 v-if="data.accountName"
                :class="{'invisible': !showChannel}"
                class="flex-grow font-semibold transition-all duration-300 ease-out"
            >
                {{ data.accountName }}
            </h6>
            <span class="text-black-500 group-hover:text-primary-500 transition-all duration-300 ease-out">
                {{ data.difference }}
            </span>
        </div>
        <div class="min-h-30" :class="[!isVertical ? 'h-60 md:h-auto' : '']" />
        <div class="px-6 pt-4 pb-6">
            <p class="text-para-s tracking-tight line-clamp-6" v-html="data.caption" />
        </div>
        <a :href="data.link"
           class="absolute left-0 right-0 top-0 bottom-0"
           :aria-label="data.accountName"
           target="_blank"
        />
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';

export default {
    inject: ['isVertical', 'showChannel'],
    props: {
        data: {type: Object as PropType<Record<string, string>>}
    }
};
</script>
