<template>
    <Field v-slot="{ field, errors, value }"
           v-model="model[name]"
           :name="name"
           :rules="rules"
           mode="eager"
           :disabled="validationDisabled"
           as="div"
    >
        <div class="text-para-s font-semibold">
            <span>{{ title }}</span>
            <span v-if="required" aria-hidden="true"> *</span>
            <span v-if="suffix" class="text-black-500 font-normal"> {{ suffix }}</span>
        </div>
        <div class="flex flex-row items-center text-black-500">
            <input
                   class="w-5 h-5 relative text-black-900 text-para-xs md:text-para-s"
                   :class="{
                       'border-black-200 focus:border-primary-500': errors.length === 0,
                       'border-error': errors.length > 0
                   }"
                   type="checkbox"
                   :value="checkboxValue"
                   :name="name"
                   :aria-required="required"
                   :aria-invalid="errors.length > 0"
                   :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
                   v-bind="field"
            >
            <label :for="name" class="relative">
                <span class="text-para-xs pl-5 md:pl-6" v-html="label" />
            </label>
        </div>
        <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-1 md:mt-2">
            {{ errors[0] }}
        </span>
    </Field>
</template>

<script lang="ts">
import {Field} from "vee-validate";

export default {
    components: {Field},
    inject: ['model', 'currentStep'],
    props: {
        name: {type: String},
        title: {type: String},
        label: {type: String},
        suffix: {type: String},
        required: {type: Boolean, default: false},
        stepIndex: {type: [Number, String], required: true},
        checkboxValue: {type: String}
    },
    computed: {
        validationDisabled(): boolean {
            return this.currentStep !== this.stepIndex;
        },
        rules(): Record<string, unknown> {
            /* eslint-disable */
            return {
                required: this.required
            };
            /* eslint-enable */
        },
        options(): Record<string, unknown> {
            const result = {};
            if (this.model[this.name]) {
                result[this.name] = this.value;
            }
            return result;
        }
    },
    created(): void {
        if (!this.model[this.name] && this.value) {
            this.model[this.name] = this.value;
        }
    }
};
</script>
