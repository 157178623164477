import {useNavigationStore} from "../stores/navigation";

export default class TabActivator {
    constructor() {
        // select all links which contain a #
        const _links = document.querySelectorAll('a[href*="#"]');
        if (_links.length > 0) {
            const links = Array.prototype.slice.call(_links);
            links.forEach(elem => elem.addEventListener('click', ev => {
                this.activate(ev);
            }));
        }
    }

    activate(event) {
        if (event.currentTarget != null && event.currentTarget.hash != null) {
            const id = event.currentTarget.hash.substring(1);
            const navigationStore = useNavigationStore();
            navigationStore.activatedTab = id;
        }
    }
}
