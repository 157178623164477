import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row justify-end items-center mb-8 md:mb-6 xl:mb-8" }
const _hoisted_2 = { class: "text-para-xs font-semibold w-full md:w-auto md:mr-6" }
const _hoisted_3 = { class: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-6 xl:gap-y-16 gap-x-4 xl:gap-x-8 mb-8 xl:mb-20" }
const _hoisted_4 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass('overflow-hidden relative flex-shrink-0 w-16 h-16 mr-4 md:w-full md:h-0 md:pt-16/9 md:mb-4 md:mr-0')
}
const _hoisted_5 = ["href", "title"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "flex flex-col md:flex-row md:justify-between md:items-center md:mb-2" }
const _hoisted_9 = {
  key: 0,
  class: "md:order-2 text-para-xs py-1"
}
const _hoisted_10 = {
  key: 0,
  class: "px-3 py-1 bg-black-100 rounded-full mr-1"
}
const _hoisted_11 = {
  key: 1,
  class: "px-3 py-1 bg-black-100 rounded-full"
}
const _hoisted_12 = { class: "md:order-1" }
const _hoisted_13 = { class: "text-para-xs text-black-600" }
const _hoisted_14 = { class: "text-para-s md:text-para-base" }
const _hoisted_15 = {
  key: 0,
  class: "md:h-12 mt-3 md:mt-8"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "flex items-center h-full group" }
const _hoisted_18 = ["href", "onClick", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_PlayButton = _resolveComponent("PlayButton")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString($props.labels.title) + ":", 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filters, (filter) => {
        return (_openBlock(), _createBlock(_component_Dropdown, {
          key: filter.type,
          modelValue: $data.currentFilters[filter.type],
          "onUpdate:modelValue": ($event: any) => (($data.currentFilters[filter.type]) = $event),
          class: "w-full md:w-auto min-w-40 md:mr-2 last:mr-0 mb-1 md:mb-0",
          "text-classes": "text-para-xs md:text-para-s h-4 md:h-6",
          title: filter.type,
          "default-value": $data.currentFilters[filter.type]
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.data, (item) => {
              return (_openBlock(), _createBlock(_component_DropdownItem, {
                key: item.type,
                label: item.label,
                value: item.type,
                "text-classes": "text-para-xs md:text-para-s"
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue", "title", "default-value"]))
      }), 128))
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "flex flex-col"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['flex', 'md:flex-col', {'flex-col': item.videoLink }])
          }, [
            (!item.videoLink)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_ResponsiveImage, {
                    urls: item.imageUrls,
                    class: "absolute top-0 left-0 object-cover object-center w-full h-full",
                    "show-lightbox": $props.showLightboxes,
                    "alt-title": item.title,
                    "hide-mobile-lightbox-icon": true
                  }, null, 8, ["urls", "show-lightbox", "alt-title"])
                ]))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: $options.getUrl(item, ''),
                  title: item.title,
                  class: _normalizeClass('overflow-hidden relative flex-shrink-0 w-16 h-16 mr-4 w-full h-0 pt-16/9 mb-4 mr-0'),
                  tabindex: "-1"
                }, [
                  _createVNode(_component_ResponsiveImage, {
                    urls: item.imageUrls,
                    class: "absolute top-0 left-0 object-cover object-center w-full h-full"
                  }, null, 8, ["urls"]),
                  (item.videoLink)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_PlayButton, {
                          "desktop-size": 16,
                          "tablet-size": 10,
                          "fill-default": true,
                          "aria-label": $options.$globalLabels.play
                        }, null, 8, ["aria-label"])
                      ]))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_5)),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (!item.videoLink)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (item.country)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.country), 1))
                        : _createCommentVNode("", true),
                      (item.commodity)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(item.commodity), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(item.dateString), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, _toDisplayString(item.title), 1)
            ])
          ], 2),
          (!item.videoLink)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_ContextMenu, null, {
                  default: _withCtx(({ toggle }) => [
                    _createElementVNode("button", {
                      type: "button",
                      class: "h-full text-para-s font-semibold cursor-pointer",
                      onClick: toggle
                    }, [
                      _createElementVNode("span", _hoisted_17, [
                        _createElementVNode("span", null, _toDisplayString($props.labels.download), 1),
                        _createVNode(_component_ArrowButton, {
                          direction: "download",
                          class: "w-10 h-10 group"
                        })
                      ])
                    ], 8, _hoisted_16)
                  ]),
                  items: _withCtx(({ toggle }) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.availableResolution, (res) => {
                      return (_openBlock(), _createElementBlock("a", {
                        key: res,
                        href: $options.getUrl(item, res),
                        target: "_blank",
                        class: "block text-para-s text-black-900 px-4 py-2 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white",
                        onClick: toggle,
                        onKeyup: _withKeys(toggle, ["enter"]),
                        download: ""
                      }, _toDisplayString($props.labels[res]), 41, _hoisted_18))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _createVNode(_component_Pagination, {
      modelValue: $data.currentPage,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentPage) = $event)),
      pages: $data.pages
    }, null, 8, ["modelValue", "pages"])
  ]))
}