import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  ref: "wrapper",
  class: "relative w-full flex flex-col text-white mt-14 md:mt-20"
}
const _hoisted_2 = { class: "container flex flex-col items-center" }
const _hoisted_3 = { class: "relative flex flex-col items-center min-h-mobile-nav md:min-h-tablet-nav" }
const _hoisted_4 = {
  ref: "langSelect",
  class: "flex justify-center items-center mt-18 xl:mt-28 z-10"
}
const _hoisted_5 = { class: "text-para-xs font-semibold mr-2" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "inline-flex justify-center items-center w-10 h-10 md:w-20 md:h-20 xl:w-24 xl:h-24 bg-white rounded-full transform transition-all duration-300 ease-out text-greyAccessibility group-hover:scale-115 group-hover:text-primary-500 group-focus:scale-115 group-focus:text-primary-500" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = {
  ref: "scrollIndicator",
  viewBox: "0 0 33 57",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_11 = {
  ref: "quoteSection",
  class: "flex flex-col justify-center items-center min-h-mobile-nav md:min-h-tablet-nav"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  ref: "ceoSignatureRef",
  class: "mt-8 xl:mt-12 z-1"
}
const _hoisted_14 = {
  key: 0,
  viewBox: "0 0 137 102",
  fill: "none",
  style: {"width":"137px","height":"102px"},
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 0,
  class: "mt-8 xl:mt-12 mb-18 xl:mb-28"
}
const _hoisted_17 = {
  ref: "playBtn",
  class: "flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_PlayButton = _resolveComponent("PlayButton")!
  const _component_VideoModal = _resolveComponent("VideoModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ResponsiveImage, {
        urls: $props.backgroundImageUrls,
        class: "absolute top-0 left-0 object-cover object-center w-full h-full",
        "alt-title": $props.backgroundImageAlt
      }, null, 8, ["urls", "alt-title"]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString($props.seeLabel), 1),
          _createVNode(_component_Dropdown, {
            modelValue: $data.currentLang,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentLang) = $event)),
            class: "min-w-40 md:min-w-50",
            title: "language"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.locales, (lang, locale) => {
                return (_openBlock(), _createBlock(_component_DropdownItem, {
                  key: locale,
                  label: lang,
                  value: locale
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ], 512), [
          [_vShow, Object.keys($props.locales).length > 1 && $props.seeLabel]
        ]),
        _withDirectives(_createElementVNode("h1", {
          ref: "h1",
          class: "text-center text-heading-4 md:text-heading-2 xl:text-stage-title-desktop tracking-tighter md:tracking-tightest mt-12 xl:mt-24",
          innerHTML: $props.title
        }, null, 8, _hoisted_6), [
          [_vShow, $props.title]
        ]),
        _withDirectives(_createElementVNode("a", {
          ref: "downloadPdfBtn",
          href: $props.pdfLink,
          class: "flex flex-col items-center mt-12 xl:mt-24 mb-40 group",
          download: ""
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Icon, {
              name: "download_",
              class: "stroke-current w-3 h-3 md:w-6 md:h-6"
            })
          ]),
          _createElementVNode("span", {
            ref: "pdfLabel",
            class: "text-para-xs md:text-para-s font-semibold mt-2 xl:mt-4"
          }, _toDisplayString($props.downloadPdfLabel), 513)
        ], 8, _hoisted_7), [
          [_vShow, $props.pdfLink]
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "absolute left-1/2 bottom-12 md:bottom-18 transform -translate-x-1/2 w-8",
          "aria-label": $options.$globalLabels.next,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.scrollDown && $options.scrollDown(...args)))
        }, [
          (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[4] || (_cache[4] = [
            _createStaticVNode("<path d=\"M11 42.5L15.7929 47.2929C16.1834 47.6834 16.8166 47.6834 17.2071 47.2929L22 42.5\" stroke=\"white\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><rect x=\"0.75\" y=\"0.75\" width=\"31.5\" height=\"55.5\" rx=\"15.75\" stroke=\"white\" stroke-width=\"1.5\"></rect><circle cx=\"16.5\" cy=\"15.5\" r=\"1.5\" fill=\"white\"></circle><circle cx=\"16.5\" cy=\"23.5\" r=\"1.5\" fill=\"white\"></circle><circle cx=\"16.5\" cy=\"31.5\" r=\"1.5\" fill=\"white\"></circle>", 5)
          ]), 512))
        ], 8, _hoisted_9)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_11, [
        _withDirectives(_createElementVNode("p", {
          ref: "ceoQuoteRef",
          class: "md:w-1/2 text-center text-para-base xl:text-para-lg mt-12 xl:mt-24",
          innerHTML: $props.quote
        }, null, 8, _hoisted_12), [
          [_vShow, $props.quote]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_13, [
          (!$props.customSignature)
            ? (_openBlock(), _createElementBlock("svg", _hoisted_14, _cache[5] || (_cache[5] = [
                _createElementVNode("path", {
                  d: "M84.8289 15.2162C63.6271 21.2424 3.86895 52.7827 2.04906 57.0751C0.229536 61.3675 49.5542 39.4902 76.8376 37.8316C104.121 36.173 118.346 49.7328 72.9781 78.9364C27.61 108.14 0.594901 99.0067 6.72325 73.4235C13.1232 46.7069 67.2008 7.14058 80.235 2.59633C93.5202 -2.03515 70.665 21.023 65.8784 27.3806C61.0918 33.7381 4.02553 91.3786 14.4937 98.4599C24.9619 105.542 39.5539 87.6731 77.3354 59.948C105.368 39.3774 124.859 27.2882 135.872 21.2281",
                  stroke: "white",
                  "stroke-width": "3"
                }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                innerHTML: $props.customSignature
              }, null, 8, _hoisted_15))
        ], 512), [
          [_vShow, !$props.hideSignature]
        ]),
        _withDirectives(_createElementVNode("p", {
          ref: "ceoNameRef",
          class: "text-para-base xl:text-para-lg mt-8 xl:mt-12"
        }, _toDisplayString($props.ceoName), 513), [
          [_vShow, $props.ceoName]
        ]),
        _withDirectives(_createElementVNode("p", {
          ref: "ceoTitleRef",
          class: "text-para-base xl:text-para-lg font-semibold"
        }, _toDisplayString($props.ceoTitle), 513), [
          [_vShow, $props.ceoTitle]
        ]),
        ($props.video)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_PlayButton, {
                  absolute: false,
                  "aria-label": $options.$globalLabels.play,
                  onClick: $options.openVideoModal
                }, null, 8, ["aria-label", "onClick"])
              ], 512),
              _withDirectives(_createElementVNode("button", {
                ref: "playVideoLabel",
                class: "text-para-xs md:text-para-s font-semibold mt-2 md:mt-4",
                tabindex: "-1",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.openVideoModal && $options.openVideoModal(...args)))
              }, _toDisplayString($props.watchLabel), 513), [
                [_vShow, $props.watchLabel]
              ])
            ]))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, $options.showQuoteSection]
      ])
    ]),
    ($props.video && $data.currentVideo)
      ? (_openBlock(), _createBlock(_component_VideoModal, {
          key: 0,
          "video-id": $data.currentVideo,
          "account-id": $props.accountId,
          "player-id": $props.playerId,
          onClose: _cache[3] || (_cache[3] = ($event: any) => ($data.currentVideo = null))
        }, null, 8, ["video-id", "account-id", "player-id"]))
      : _createCommentVNode("", true)
  ], 512))
}