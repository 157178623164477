<template>
    <div ref="wrapper" class="relative w-full flex flex-col text-white mt-14 md:mt-20">
        <div class="container flex flex-col items-center">
            <!-- responsive background image -->
            <ResponsiveImage :urls="backgroundImageUrls"
                             class="absolute top-0 left-0 object-cover object-center w-full h-full"
                             :alt-title="backgroundImageAlt"
            />
            <!-- title section -->
            <div class="relative flex flex-col items-center min-h-mobile-nav md:min-h-tablet-nav">
                <!-- lang switcher -->
                <div v-show="Object.keys(locales).length > 1 && seeLabel"
                     ref="langSelect" class="flex justify-center items-center mt-18 xl:mt-28 z-10"
                >
                    <p class="text-para-xs font-semibold mr-2">
                        {{ seeLabel }}
                    </p>
                    <Dropdown v-model="currentLang"
                              class="min-w-40 md:min-w-50"
                              title="language"
                    >
                        <DropdownItem v-for="(lang, locale) in locales" :key="locale" :label="lang" :value="locale" />
                    </Dropdown>
                </div>
                <!-- main title -->
                <h1 v-show="title"
                    ref="h1"
                    class="text-center text-heading-4 md:text-heading-2 xl:text-stage-title-desktop tracking-tighter md:tracking-tightest mt-12 xl:mt-24"
                    v-html="title"
                />
                <!-- download button -->
                <a v-show="pdfLink"
                   ref="downloadPdfBtn"
                   :href="pdfLink"
                   class="flex flex-col items-center mt-12 xl:mt-24 mb-40 group"
                   download
                >
                    <div class="inline-flex justify-center items-center w-10 h-10 md:w-20 md:h-20 xl:w-24 xl:h-24 bg-white rounded-full
                            transform transition-all duration-300 ease-out text-greyAccessibility group-hover:scale-115 group-hover:text-primary-500 group-focus:scale-115 group-focus:text-primary-500">
                        <Icon name="download_" class="stroke-current w-3 h-3 md:w-6 md:h-6"/>
                    </div>
                    <span ref="pdfLabel" class="text-para-xs md:text-para-s font-semibold mt-2 xl:mt-4">
                        {{ downloadPdfLabel }}
                    </span>
                </a>
                <!-- scroll button -->
                <button type="button"
                        class="absolute left-1/2 bottom-12 md:bottom-18 transform -translate-x-1/2 w-8"
                        :aria-label="$globalLabels.next"
                        @click="scrollDown"
                >
                    <svg ref="scrollIndicator"
                         viewBox="0 0 33 57"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 42.5L15.7929 47.2929C16.1834 47.6834 16.8166 47.6834 17.2071 47.2929L22 42.5"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                        />
                        <rect x="0.75"
                              y="0.75"
                              width="31.5"
                              height="55.5"
                              rx="15.75"
                              stroke="white"
                              stroke-width="1.5"
                        />
                        <circle cx="16.5"
                                cy="15.5"
                                r="1.5"
                                fill="white"
                        />
                        <circle cx="16.5"
                                cy="23.5"
                                r="1.5"
                                fill="white"
                        />
                        <circle cx="16.5"
                                cy="31.5"
                                r="1.5"
                                fill="white"
                        />
                    </svg>
                </button>
            </div>
            <!-- quote section -->
            <div v-show="showQuoteSection"
                 ref="quoteSection"
                 class="flex flex-col justify-center items-center min-h-mobile-nav md:min-h-tablet-nav"
            >
                <!-- quote -->
                <p v-show="quote"
                   ref="ceoQuoteRef"
                   class="md:w-1/2 text-center text-para-base xl:text-para-lg mt-12 xl:mt-24"
                   v-html="quote"
                />
                <!-- signature -->
                <div v-show="!hideSignature" ref="ceoSignatureRef" class="mt-8 xl:mt-12 z-1">
                    <svg v-if="!customSignature" viewBox="0 0 137 102" fill="none" style="width: 137px; height: 102px;"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M84.8289 15.2162C63.6271 21.2424 3.86895 52.7827 2.04906 57.0751C0.229536 61.3675 49.5542 39.4902 76.8376 37.8316C104.121 36.173 118.346 49.7328 72.9781 78.9364C27.61 108.14 0.594901 99.0067 6.72325 73.4235C13.1232 46.7069 67.2008 7.14058 80.235 2.59633C93.5202 -2.03515 70.665 21.023 65.8784 27.3806C61.0918 33.7381 4.02553 91.3786 14.4937 98.4599C24.9619 105.542 39.5539 87.6731 77.3354 59.948C105.368 39.3774 124.859 27.2882 135.872 21.2281"
                            stroke="white"
                            stroke-width="3"
                        />
                    </svg>
                    <div v-else v-html="customSignature" />
                </div>
                <!-- name -->
                <p v-show="ceoName" ref="ceoNameRef" class="text-para-base xl:text-para-lg mt-8 xl:mt-12">
                    {{ ceoName }}
                </p>
                <!-- job title -->
                <p v-show="ceoTitle" ref="ceoTitleRef" class="text-para-base xl:text-para-lg font-semibold">
                    {{ ceoTitle }}
                </p>
                <!-- play button -->
                <div v-if="video" class="mt-8 xl:mt-12 mb-18 xl:mb-28">
                    <div ref="playBtn" class="flex justify-center">
                        <PlayButton :absolute="false" :aria-label="$globalLabels.play" @click="openVideoModal" />
                    </div>
                    <button v-show="watchLabel"
                            ref="playVideoLabel"
                            class="text-para-xs md:text-para-s font-semibold mt-2 md:mt-4"
                            tabindex="-1"
                            @click="openVideoModal"
                    >
                        {{ watchLabel }}
                    </button>
                </div>
            </div>
        </div>
        <template v-if="video && currentVideo">
            <VideoModal :video-id="currentVideo"
                        :account-id="accountId"
                        :player-id="playerId"
                        @close="currentVideo = null"
            />
        </template>
    </div>
</template>

<script lang="ts">
import gsap from 'gsap';
import { easeOut, Timeline, update } from './animations';
import breakpoints from '../../plugins/breakpoints';
import VideoModal from "../video/VideoModal.vue";
import { PropType } from "vue";
import ResponsiveImage from "../base/ResponsiveImage.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import Icon from "../base/Icon.vue";
import PlayButton from "../video/PlayButton.vue";

declare const window: any;

/* eslint-disable */
export default {
    components: {PlayButton, Icon, DropdownItem, Dropdown, ResponsiveImage, VideoModal},
    inject: ['$globalLabels'],
    props: {
        language: {type: String},
        backgroundImageUrls: {type: Object as PropType<Record<string, string>>},
        backgroundImageAlt: {type: String},
        seeLabel: {type: String},
        title: {type: String},
        locales: {type: Object as PropType<Record<string, string>>},
        pdfLink: {type: String},
        downloadPdfLabel: {type: String},
        anchorLink: {type: String},
        quote: {type: String},
        ceoName: {type: String},
        ceoTitle: {type: String},
        hideSignature: {type: Boolean, default: false},
        watchLabel: {type: String},
        video: {type: String},
        accountId: {type: String},
        playerId: {type: String},
        customSignature: {type: String}
    },
    data() {
        return {
            currentLang: this.language,
            currentVideo: null
        };
    },
    mounted(): void {
        this.animateTitleSection();
        setTimeout(() => this.animateQuoteSection(), 100);
    },
    computed: {
        showQuoteSection(): boolean {
            return !!this.quote || !this.hideSignature || !!this.ceoName || !!this.ceoTitle || !!this.video;
        }
    },
    methods: {
        animateTitleSection(): void {
            const tl = gsap.timeline({paused: false});
            tl.fromTo(this.$refs.h1, {opacity: 0, y: 50}, {opacity: 1, y: 0, duration: 1.0, ease: easeOut}, 0.8);
            tl.fromTo(this.$refs.langSelect, {opacity: 0, y: -20}, {opacity: 1, y: 0, duration: 1.0, ease: easeOut}, 1.1);

            tl.fromTo(this.$refs.downloadPdfBtn, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.3, ease: easeOut}, 1.6);

            tl.fromTo(this.$refs.pdfLabel, {opacity: 0, y: 15}, {
                opacity: 1,
                y: 0,
                duration: 0.6,
                ease: easeOut
            }, 1.4);

            tl.fromTo(this.$refs.scrollIndicator, {opacity: 0}, {opacity: 1, duration: 0.6}, 1.9);
            this.animateScrollDown(this.$refs.scrollIndicator);
        },
        animateScrollDown(element: SVGElement): void {
            const tl = gsap.timeline({paused: false, repeat: -1});

            const circles: NodeListOf<SVGCircleElement> = element.querySelectorAll('circle');
            const arrow = element.querySelector('path');
            let timingOffset = 0;

            Array.from(circles).forEach(circle => {
                tl.to(circle, {opacity: 0, duration: 0.2}, timingOffset);
                tl.to(circle, {opacity: 1, duration: 0.2}, timingOffset + 0.2);
                timingOffset += 0.2;
            });

            tl.to(arrow, {y: 3, duration: 0.5}, 0.0);
            tl.to(arrow, {y: 0, duration: 0.5}, 0.5);
        },
        animateQuoteSection(): void {
            const tl = Timeline(true);

            if (!window.animations) {
                window.animations = {};
            }
            window.animations.last_t = window.performance.now();
            window.animations.scroll = window.scrollY;
            window.animations.screen_w = window.innerWidth;
            window.animations.screen_h = window.innerHeight;
            window.animations.$signature = this.$refs.ceoSignatureRef.querySelector('svg');

            tl.fromTo(this.$refs.playBtn, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.3, ease: easeOut}, 1.2);
            tl.fromTo(this.$refs.playVideoLabel, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.6, ease: easeOut}, 1);

            tl.fromTo(this.$refs.ceoQuoteRef, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.5, ease: easeOut}, 0.0);
            tl.fromTo(this.$refs.ceoNameRef, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.5, ease: easeOut}, 0.3);
            tl.fromTo(this.$refs.ceoTitleRef, {opacity: 0, y: 15}, {opacity: 1, y: 0, duration: 0.5, ease: easeOut}, 0.6);

            const paths: SVGPathElement[] = Array.from(this.$refs.ceoSignatureRef.querySelectorAll('path'));
            let t = 1.0;
            const path_lengths = new Array(paths.length);
            let max_length = 0;

            for (let i = 0; i < paths.length; ++i) {
                const l = paths[i].getTotalLength();
                path_lengths[i] = l;
                if (l > max_length) max_length = l;
            }

            for (let i = 0; i < paths.length; ++i) {
                const l = path_lengths[i];
                const draw_time = (l / max_length) * 1.25;

                tl.fromTo(paths[i],
                    {strokeDasharray: l, strokeDashoffset: l},
                    {strokeDashoffset: 0, duration: draw_time},
                    t);
                t += draw_time;
            }

            window.animations.signature = tl;

            window.requestAnimationFrame(update);
        },
        scrollDown(): void {
            if (this.showQuoteSection) {
                const offset = breakpoints.mdAndUp ? 80 : 56;
                window.scrollBy({
                    top: this.$refs.quoteSection.getBoundingClientRect().top - offset,
                    behavior: 'smooth'
                });
            } else {
                window.scroll({
                    top: this.$refs.wrapper.clientHeight,
                    behavior: 'smooth'
                });
            }
        },
        openVideoModal(): void {
            this.currentVideo = this.video;
        }
    },
    watch: {
        async currentLang() {
            const path = window.location.pathname;
            const localeSegment = `/${this.currentLang}/`;
            window.location.href = path.replace(/\/([a-z]{2,3})\//, localeSegment);
        }
    }
};
</script>
