<template>
    <div class="absolute left-0 top-14 md:top-20 w-full h-mobile-nav md:h-tablet-nav bg-primary-50 h-full overflow-y-auto pb-5">
        <div class="container flex flex-col">
            <!-- input field -->
            <div class="relative w-full text-primary-500 mt-4">
                <input type="text"
                       class="w-full rounded text-black-600 text-para-s focus:outline-none py-2 pl-10 pr-2 placeholder-improvedContrast"
                       :aria-label="labels.placeholder"
                       :placeholder="labels.placeholder"
                       v-model="keyword"
                       @keydown.enter="initSearch"
                />
                <Icon name="search" class="absolute left-4 top-3 w-4 h-4 stroke-current"/>
            </div>

            <!-- suggestions -->
            <ul v-show="keyword.length >= 3 && (currentSearch !== keyword)" class="flex flex-col gap-1 mt-4">
                <li v-for="(suggestion, index) in suggestions"
                    :key="index"
                    class="flex items-center justify-between text-para-s bg-white hover:bg-primary-500 text-black-900 hover:text-white font-normal transition-colors cursor-pointer py-3 pl-4 pr-6 group"
                    @click="searchByKeyword(suggestion)"
                >
                    <span>{{ suggestion }}</span>
                    <Icon name="arrow-right" class="flex-shrink-0 w-4 h-4 ml-6 text-primary-500 group-hover:text-white stroke-current" />
                </li>
            </ul>

            <template v-if="!currentSearch && keyword.length < 3">
                <!-- top search terms -->
                <div v-if="topTerms.length > 0" class="flex flex-wrap items-center gap-2 w-full text-para-xs md:text-para-s mt-4">
                    <span class="hidden md:inline">
                        {{ labels.topTerms }}:
                    </span>
                    <span v-for="(term, i) in topTerms"
                          :key="i"
                          class="py-1 px-3 rounded-full border border-primary-500 cursor-pointer hover:text-white hover:bg-primary-500 transition-colors"
                          @click="searchByKeyword(term)"
                    >
                        {{ term }}
                    </span>
                </div>

                <!-- quick links -->
                <div v-if="quickLinks.length > 0" class="mt-12">
                    <div class="text-para-s font-semibold mb-4">{{ labels.quickLinks }}</div>
                    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2 md:gap-4 xl:gap-8">
                        <a v-for="(quickLink, index) in quickLinks"
                           :key="index"
                           :href="quickLink.link"
                           class="flex md:flex-col text-para-s text-black-900 font-normal bg-white group px-3 pt-4 pb-6 md:px-6 md:pt-6 md:pb-12"
                        >
                            <div class="flex justify-center items-center flex-shrink-0 bg-primary-50 rounded-full w-10 h-10 transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 mr-3 md:mb-4">
                                <Icon :name="getIcon(getType(quickLink.type), quickLink.link)" class="w-4 h-4" />
                            </div>
                            <p class="text-para-s flex items-center">
                                {{ quickLink.label }}
                            </p>
                        </a>
                    </div>
                </div>
            </template>

            <template v-if="keyword.length >=3 && currentSearch === keyword">
                <!-- top results -->
                <div v-if="topResults.length > 0" class="hidden md:block mt-6">
                    <div class="text-para-s font-semibold mb-4">{{ labels.top }}</div>
                    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2 md:gap-4 xl:gap-8">
                        <SearchLink v-for="(result, index) in topResults"
                                    :key="index"
                                    :url="result.link"
                                    :overlay="result.overlay"
                                    class="flex md:flex-col text-para-s text-black-900 font-normal bg-white group px-3 pt-4 pb-6 md:px-6 md:pt-6 md:pb-12"
                        >
                            <div class="flex justify-center items-center flex-shrink-0 bg-primary-50 rounded-full w-10 h-10 transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 mr-3 md:mb-4">
                                <Icon :name="getIcon(result.type, result.link)" class="w-4 h-4" />
                            </div>
                            <p class="text-para-s flex items-center">
                                {{ result.label }}
                            </p>
                        </SearchLink>
                    </div>
                </div>

                <!-- no results -->
                <div v-if="!loading && currentSearch && topResults.length === 0" class="mt-16 md:mt-10">
                    <div class="flex flex-col text-para-s bg-white px-4 pt-4 pb-6">
                        <span class="font-semibold">{{ noResultsLabel }}</span>
                        <span class="mt-2">{{ labels.noResultsText }}</span>
                    </div>
                    <div v-if="topTerms.length > 0" class="mt-6 md:mt-8">
                        <span class="text-para-s font-semibold">{{ labels.noResultsOther }}</span>
                        <div class="flex flex-wrap items-center gap-2 w-full text-para-xs md:text-para-s mt-4">
                            <span v-for="(term, i) in topTerms"
                                  :key="i"
                                  class="py-1 px-3 rounded-full border border-primary-500 cursor-pointer hover:text-white hover:bg-primary-500 transition-colors"
                                  @click="searchByKeyword(term)"
                            >
                                {{ term }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>

            <div v-if="loading" class="flex justify-center text-primary-700 mt-32">
                <LoadingSpinner class="w-20 h-20 fill-current" />
            </div>
        </div>

        <template v-if="keyword.length >=3 && currentSearch === keyword">
            <!-- categories -->
            <div v-if="results.length > 0" class="hidden md:block bg-white mt-12">
                <div class="container flex">
                    <span v-for="(cat, ws, index) in categories"
                          :key="index"
                          class="text-para-s p-3 hover:bg-primary-500 hover:text-white cursor-pointer transition-colors"
                          :class="{'bg-primary-500 text-white': currentCategory === ws}"
                          @click="currentCategory = ws"
                    >
                        {{ cat }}
                    </span>
                </div>
            </div>

            <!-- all results (tablet / desktop) -->
            <div v-if="results.length > 0 && !isMobile" class="container mt-8">
                <div class="text-para-s" v-html="resultsLabel" />
                <!-- tablet / desktop results -->
                <div class="grid grid-cols-1 gap-1 mt-4">
                    <SearchLink v-for="(result, index) in results"
                                :key="index"
                                :url="result.link"
                                :overlay="result.overlay"
                                :target="getTarget(result.type)"
                                class="flex items-center text-para-s bg-white hover:bg-primary-500 text-black-900 hover:text-white font-normal transition-colors py-3 px-6 group"
                    >
                        <Icon :name="getIcon(result.type, result.link)" class="flex-shrink-0 w-4 h-4 mr-2" />
                        <p class="flex-grow text-para-s">
                            {{ result.label }}
                        </p>
                        <Icon name="arrow-right" class="flex-shrink-0 w-4 h-4 ml-6 text-primary-500 group-hover:text-white stroke-current" />
                    </SearchLink>
                </div>
                <pagination v-model="currentPage" :pages="pages" class="mt-4" />
            </div>

            <!-- mobile results -->
            <div v-if="topResults.length > 0 && isMobile" class="mt-6">
                <div class="container">
                    <div class="text-para-s" v-html="resultsLabel" />
                </div>
                <ContentGroup type="accordion"
                              class="mt-6"
                              :in-content-group="false"
                              v-slot="{ index, type, showAll, itemsLength, componentUid }"
                >
                    <ContentGroupItem v-for="(cat, i) in mobileCategories"
                                      :key="i"
                                      :title="categories[cat]"
                                      :index="index"
                                      :component-uid="componentUid"
                    >
                        <div class="grid grid-cols-1 gap-1 mt-4 mb-6">
                            <SearchLink v-for="(result, j) in mobileResults[cat]"
                                        :key="j"
                                        :url="result.link"
                                        :overlay="result.overlay"
                                        :target="getTarget(result.type)"
                                        class="flex items-center text-para-s bg-white hover:bg-primary-500 text-black-900 hover:text-white font-normal transition-colors py-3 px-6 group"
                            >
                                <Icon :name="getIcon(result.type, result.link)" class="flex-shrink-0 w-4 h-4 mr-2" />
                                <p class="flex-grow text-para-s">
                                    {{ result.label }}
                                </p>
                                <Icon name="arrow-right" class="flex-shrink-0 w-4 h-4 ml-6 text-primary-500 group-hover:text-white stroke-current" />
                            </SearchLink>
                        </div>
                        <div v-if="hasMoreResults(cat)" class="flex justify-center prose text-para-s mb-6">
                            <a class="link cursor-pointer" @click="searchMobile(cat)">{{ labels.moreResults }}</a>
                        </div>
                    </ContentGroupItem>
                </ContentGroup>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import DocumentsTable from '../documents/DocumentsTable.vue';
import breakpoints from '../../plugins/breakpoints';
import debounce from 'lodash/debounce';
import Pagination from "../base/Pagination.vue";
import LoadingSpinner from "../base/LoadingSpinner.vue";
import Icon from "../base/Icon.vue";
import SearchLink from "./SearchLink.vue";
import ContentGroup from "../contentGroup/ContentGroup.vue";
import ContentGroupItem from "../contentGroup/ContentGroupItem.vue";

export interface SearchLabels {
    placeholder: string;
    all: string;
    top: string;
    results: string;
    sortBy: string;
    topTerms: string;
    quickLinks: string;
    noResultsTitle: string;
    noResultsText: string;
    noResultsOther: string;
    moreResults: string;
    categories: Record<string, string>;
}

export interface SearchResult {
    site: SiteResults;
}

export interface SiteResults {
    page?: number;
    results: Result[];
    resultsPerWorkspace: Record<string, number>;
    total: number;
}

export interface Result {
    id: string;
    jcrPath: string;
    label: string;
    lastModified: Record<string, number>;
    link: string;
    overlay: boolean;
    score: number;
    type: string;
}

export default {
    components: {ContentGroupItem, ContentGroup, SearchLink, Icon, LoadingSpinner, Pagination},
    inject: ['$site', '$lang'],
    props: {
        labels: {type: Object as PropType<SearchLabels>},
        topTerms: {type: Array as PropType<Array<string>>, default: () => []},
        quickLinks: {type: Array as PropType<Array<Record<string, string>>>, default: () => []},
        resultsPerPage: {type: Number, default: 20}
    },
    data() {
        return {
            apiUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SEARCH, this.$site),
            suggestBaseUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SEARCH_SUGGESTIONS, this.$site),

            PAGE_SIZE: 20,
            PAGE_SIZE_MOBILE: 5,
            NUM_TOP_RESULTS: 4,

            keyword: '',
            currentSearch: '',
            currentPage: 1,
            currentCategory: 'all',
            pages: 0,
            results: [],
            topResults: [],
            suggestions: [],
            mobileResults: {},
            numResults: 0,
            resultsPerWorkspace: {},
            loading: false,

            searchSuggestions: debounce(this.getSuggestions, 500)
        };
    },
    computed: {
        categories(): Record<string, string> {
            const result: Record<string, string> = {
                all: `${this.labels.all} (${this.numResults})`
            };
            Object.keys(this.resultsPerWorkspace)
                .sort((k1, k2) => this.resultsPerWorkspace[k2] - this.resultsPerWorkspace[k1])
                .filter(k => this.resultsPerWorkspace[k] > 0)
                .forEach(k => {
                    result[k] = `${this.labels.categories[k]} (${this.resultsPerWorkspace[k]})`;
                });
            return result;
        },
        resultsLabel(): string {
            return Utils.formatString(this.labels.results, this.numResults, this.currentSearch);
        },
        noResultsLabel(): string {
            return Utils.formatString(this.labels.noResultsTitle, this.currentSearch);
        },
        url(): string {
            return `${this.mobileUrl}&category=${this.currentCategory}&start=${this.start}&rows=${this.rows}`;
        },
        mobileUrl(): string {
            return `${this.apiUrl}locale=${this.$lang}&query=${this.currentSearch}`;
        },
        suggestUrl(): string {
            return `${this.suggestBaseUrl}locale=${this.$lang}&query=${this.keyword}`;
        },
        start(): number {
            return this.currentCategory === 'all' && this.currentPage > 1 ? ((this.currentPage - 1) * this.PAGE_SIZE) + this.NUM_TOP_RESULTS : (this.currentPage - 1) * this.PAGE_SIZE;
        },
        rows(): number {
            return this.currentCategory === 'all' && this.currentPage === 1 ? this.PAGE_SIZE + this.NUM_TOP_RESULTS : this.PAGE_SIZE;
        },
        isMobile(): boolean {
            return breakpoints.mobile;
        },
        mobileCategories(): string[] {
            return Object.keys(this.mobileResults).sort((k1, k2) => this.resultsPerWorkspace[k2] - this.resultsPerWorkspace[k1]);
        }
    },
    watch: {
        async keyword() {
            if (this.keyword.trim().length < 3) {
                this.suggestions = [];
            } else if (this.keyword !== this.currentSearch) {
                this.searchSuggestions();
            }
        },
        async currentPage() {
            if (this.currentPage > 0) {
                await this.search();
            }
        },
        async currentCategory() {
            this.currentPage = 1;
            await this.search();
        }
    },
    methods: {
        hasMoreResults(workspace: string): boolean {
            return workspace === 'all'
                ? this.mobileResults[workspace].length < this.numResults
                : this.mobileResults[workspace].length < this.resultsPerWorkspace[workspace];
        },
        getType(linkType: string) {
            switch (linkType) {
                case 'document':
                case 'asset':
                    return 'DOCUMENT';
                default:
                case 'basic':
                case 'page':
                case 'mail':
                    return 'WEBSITE';
            }
        },
        getIcon(type: string, link: string): string {
            switch (type) {
                case 'WEBSITE':
                    return 'search_page';
                case 'DOCUMENT':
                    return `search_${DocumentsTable.methods.getFileType(link)}`;
                case 'MEMBER':
                    return 'search_person';
                case 'OFFICE':
                    return 'search_office';
                case 'LOCATION':
                    return 'search_location';
                case 'EVENT':
                    return 'search_calendar';
                default:
                    return 'search_file';
            }
        },
        getTarget(type: string) {
            if (type === 'DOCUMENT') {
                return '_blank';
            }
            return '_self';
        },
        searchByKeyword(suggestion: string) {
            this.keyword = suggestion;
            this.initSearch();
        },
        initSearch(): void {
            if (this.keyword.length >= 3 && this.currentSearch !== this.keyword) {
                this.currentSearch = this.keyword;
                this.loading = true;
                this.currentPage = 1;
                this.currentCategory = 'all';
                this.results = [];
                this.mobileResults = {};
                this.topResults = [];
                this.search();
                this.trackSearch(this.currentSearch);
            }
        },
        async search(): Promise<void> {
            if (this.currentSearch) {
                try {
                    const res = await axios.get(this.url);
                    const data: SearchResult = res.data;
                    if (this.currentPage === 1) {
                        if (this.currentCategory === 'all') {
                            this.pages = Math.ceil((data.site.total - this.NUM_TOP_RESULTS) / this.resultsPerPage);
                            this.numResults = data.site.total;
                            this.resultsPerWorkspace = data.site.resultsPerWorkspace;
                            this.topResults = data.site.results.slice(0, this.NUM_TOP_RESULTS);
                            this.results = data.site.results.slice(this.NUM_TOP_RESULTS);
                            // mobile search: init all other categories with values
                            if (this.isMobile) {
                                this.mobileResults['all'] = data.site.results.slice(0, this.PAGE_SIZE_MOBILE);
                                Object.keys(this.resultsPerWorkspace).filter(k => this.resultsPerWorkspace[k] > 0).forEach(k => this.searchMobile(k));
                            }
                        } else {
                            this.pages = Math.ceil((data.site.total) / this.resultsPerPage);
                            this.results = data.site.results;
                        }
                    } else {
                        this.results = data.site.results;
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        async searchMobile(workspace: string): Promise<void> {
            const start = this.mobileResults[workspace] ? this.mobileResults[workspace].length : 0;
            try {
                const res = await axios.get(`${this.mobileUrl}&category=${workspace}&start=${start}&rows=${this.PAGE_SIZE_MOBILE}`);
                const data: SearchResult = res.data;
                this.mobileResults[workspace] = (this.mobileResults[workspace] || []).concat(data.site.results);
            } catch (err) {
                console.error('Error performing search', err);
                this.mobileResults[workspace] = {};
            }
        },
        async getSuggestions(): Promise<void> {
            this.loading = true;
            if (this.keyword.length < 3) return;
            const res = await axios.get(this.suggestUrl);
            if (res.status === 200 && res.data.length > 0) {
                this.suggestions = res.data;
            } else {
                this.suggestions = [];
            }
            this.loading = false;
        },
        trackSearch(searchText: string): void {
            if (this.$gtm && this.$gtm.enabled()) {
                window.dataLayer.push({
                    event: 'customSearch',
                    customSearchInput: searchText
                });
            }
        }
    }
};
</script>
