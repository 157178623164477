import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex items-center justify-center",
    style: _normalizeStyle($options.checkedStyle)
  }, [
    ($props.model)
      ? (_openBlock(), _createElementBlock("svg", {
          key: 0,
          xmlns: "http://www.w3.org/2000/svg",
          style: _normalizeStyle($props.iconStyles),
          viewBox: "0 0 9 9",
          fill: "none",
          class: "w-9/12 h-9/12"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("path", {
            d: "M7.5 1L2.99997 6.99994L0.499962 4.99995",
            stroke: "white",
            "stroke-width": "1.5"
          }, null, -1)
        ]), 4))
      : _createCommentVNode("", true)
  ], 4))
}