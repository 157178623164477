import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["data-before"]
const _hoisted_2 = {
  key: 0,
  class: "absolute -bottom-14 right-4 flex items-center md:right-10 xl:right-20"
}
const _hoisted_3 = ["aria-label", "onClick"]
const _hoisted_4 = ["aria-label", "onClick"]
const _hoisted_5 = { class: "absolute left-0 top-0 h-full w-10 md:w-20 xl:w-40 bg-gradient-to-l from-transparent to-primary-50 pointer-events-none" }
const _hoisted_6 = { class: "absolute right-0 top-0 h-full w-10 md:w-20 xl:w-40 bg-gradient-to-r from-transparent to-primary-50 pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcSwiperSlide = _resolveComponent("GcSwiperSlide")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_GcSwiper = _resolveComponent("GcSwiper")!

  return (!($props.tabs.length === 1 && $props.hideWhenSingleTab))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["text-para-s border-t border-b border-primary-100 bg-primary-50", {'mb-14': $data.hasControls}])
      }, [
        _createVNode(_component_GcSwiper, {
          class: _normalizeClass(["relative", {'container': !$props.inContentGroup}]),
          "container-classes": ($props.narrow && !$props.inContentGroup ? 'xl:w-2/3 mx-auto ' : '') + 'py-px -my-px',
          "is-tab": true,
          "slide-classes": "flex"
        }, {
          controls: _withCtx(({ next, prev, show, prevVisible, nextVisible }) => [
            ($options.controlsVisible(show))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("button", {
                    class: "group",
                    "aria-label": $options.$globalLabels.previous,
                    onClick: prev
                  }, [
                    _createVNode(_component_ArrowButton, {
                      direction: "left",
                      class: _normalizeClass(["btn-prev w-10 h-10", {'invisible': !prevVisible}])
                    }, null, 8, ["class"])
                  ], 8, _hoisted_3),
                  _createElementVNode("button", {
                    class: "group",
                    "aria-label": $options.$globalLabels.next,
                    onClick: next
                  }, [
                    _createVNode(_component_ArrowButton, {
                      direction: "right",
                      class: _normalizeClass(["btn-next w-10 h-10", {'invisible': !nextVisible}])
                    }, null, 8, ["class"])
                  ], 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
              [_vShow, prevVisible]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_6, null, 512), [
              [_vShow, nextVisible]
            ])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (item, index) => {
              return (_openBlock(), _createBlock(_component_GcSwiperSlide, {
                id: $props.anchors[index],
                key: index,
                ref_for: true,
                ref: "slideEl",
                class: _normalizeClass(["text-para-s flex items-start cursor-pointer border-t hover:border-primary-500 focus:border-primary-500 px-3 py-4 -my-px", [{'border-primary-500 bg-white font-semibold': index === $props.modelValue, 'border-transparent': index !== $props.modelValue}]]),
                style: _normalizeStyle($options.getStyle(index)),
                "is-tab": true,
                "aria-selected": ($props.modelValue === index) ? 'true' : 'false',
                "aria-controls": $props.anchors && $props.anchors.length > 0 ? $props.anchors[index] : 'item-' + $props.componentUid + '-' + index,
                tabindex: "0",
                role: "tab",
                onClick: ($event: any) => {$options.select(index); $options.setDeeplink(index)},
                onKeyup: _withKeys(($event: any) => {$options.select(index); $options.setDeeplink(index)}, ["enter"])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: "tab-label line-clamp-2",
                    "data-before": item
                  }, _toDisplayString(item), 9, _hoisted_1)
                ]),
                _: 2
              }, 1032, ["id", "class", "style", "aria-selected", "aria-controls", "onClick", "onKeyup"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["class", "container-classes"])
      ], 2))
    : _createCommentVNode("", true)
}