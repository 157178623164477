<template>
    <div class="bg-fixed bg-center bg-no-repeat bg-cover relative"
         :style="{ backgroundImage: imageUrl }"
    >
        <slot />
        <ScrollDown v-if="scrollToAnchor" :to-anchor="scrollToAnchor" />
    </div>
</template>

<script lang="ts">
import breakpoints from '../../plugins/breakpoints';
import ScrollDown from "../base/ScrollDown.vue";

export default {
    components: {ScrollDown},
    props: {
        imageExtraLarge: {type: String, required: true},
        imageLarge: {type: String, required: true},
        imageMedium: {type: String, required: true},
        scrollToAnchor: {type: String}
    },
    computed: {
        image(): string {
            return breakpoints.xl ? this.imageExtraLarge : (breakpoints.lg ? this.imageLarge : this.imageMedium);
        },
        imageUrl(): string {
            return "url('" + this.image + "')";
        }
    },
    methods: {
    }
};
</script>
