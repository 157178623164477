import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full grid grid-cols-6 gap-x-6 gap-y-4 text-para-s bg-black-50 px-6 py-4" }
const _hoisted_2 = { class: "font-semibold" }
const _hoisted_3 = {
  key: 0,
  class: "col-span-2"
}
const _hoisted_4 = {
  key: 1,
  class: "col-span-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.properties, (label, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
        ($props.user.properties.hasOwnProperty(key))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("span", _hoisted_2, _toDisplayString(label), 1),
              (key === 'regstamp' && $props.user.properties.hasOwnProperty(key))
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$filters.formatDate(Number($props.user.properties[key]))), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.user.properties[key] || 'n/a'), 1))
            ], 64))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}