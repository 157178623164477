import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "text-heading-3" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.datasets, (dataset, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "w-full flex flex-col border-b border-white mb-4"
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(dataset.unit || "") + _toDisplayString(dataset.value || "") + " " + _toDisplayString(dataset.scale !== 'none' ? dataset.scale : '' || ''), 1),
        _createElementVNode("div", {
          class: "text-para-xs mb-1 text-black-100 whitespace-pre-line",
          innerHTML: dataset.description
        }, null, 8, _hoisted_3)
      ]))
    }), 128))
  ]))
}