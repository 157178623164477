import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, renderSlot as _renderSlot, vShow as _vShow, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "aria-label", "aria-required", "aria-invalid", "aria-describedby", "name"]
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  key: 0,
  class: "cursor-pointer text-black-900 mx-2 my-3 text-para-s flex items-center justify-start relative border border-black-200 rounded"
}
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = { class: "absolute top-1/2 transform -translate-y-1/2 right-4 text-black-200" }
const _hoisted_8 = ["id", "name"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: `${$props.name}-dropdown`,
          class: "mb-2 text-para-s"
        }, _toDisplayString($props.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      id: `${$props.name}-dropdown`,
      type: "button",
      role: "combobox",
      class: _normalizeClass(["flex justify-between items-center w-full border transition-colors cursor-pointer border-black-100 hover:text-black-900 focus:border-primary-500 focus:text-black-900 px-4 py-3 md:py-2.5", [
                    { 'border-black-100': !$props.error },
                    { 'border-error': $props.error },
                    { 'border-black-900': $props.passed },
                    { 'rounded-b': !$data.open && $props.rounded },
                    { 'rounded-t': $props.rounded },
                    { 'bg-white': $props.white && !$props.disabled },
                    { 'hover:bg-black-100 focus:bg-black-100': !$props.white },
                    { 'bg-black-50' : $props.disabled },
                    { 'md:flex md:flex-auto': $props.flex }
                ]]),
      "aria-label": $data.open ? $options.$globalLabels.close : $options.$globalLabels.open,
      "aria-required": $props.required,
      "aria-invalid": $props.error,
      "aria-describedby": $props.describedBy,
      name: `${$props.name}-dropdown`,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleClick && $options.handleClick(...args))),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('delete-pressed')), ["delete"]))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass($props.textClasses),
        title: $data.activeTitle
      }, _toDisplayString($data.activeTitle), 11, _hoisted_4),
      _createVNode(_component_Icon, {
        name: "chevron-down",
        class: _normalizeClass(["w-3 h-3 flex-shrink-0 transition-transform transform origin-center stroke-current", { 'rotate-180': $data.open }])
      }, null, 8, ["class"])
    ], 42, _hoisted_3),
    _createVNode(_component_CollapseTransition, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["overflow-y-auto absolute top-full z-10 w-full bg-white border-r border-b border-l border-black-100 max-h-42", {'rounded-b': $props.rounded, 'max-h-96': $props.higherMenu}]),
          role: "listbox"
        }, [
          ($props.searchable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.theCurrentSearch) = $event)),
                  type: "text",
                  "aria-label": $options.$globalLabels.search,
                  class: "w-10/12 h-full py-2 px-2 md:py-2.5 placeholder:text-para-s focus:outline-none rounded placeholder-improvedContrast"
                }, null, 8, _hoisted_6), [
                  [_vModelText, $data.theCurrentSearch]
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Icon, {
                    name: "search",
                    class: "stroke-current w-4 h-4"
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ], 2), [
          [_vShow, $data.open]
        ])
      ]),
      _: 3
    }),
    _withDirectives(_createElementVNode("select", {
      id: $props.name,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.activeValue) = $event)),
      class: "hidden",
      name: $props.name
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (item.value === $props.defaultValue)
            ? (_openBlock(), _createElementBlock("option", {
                key: 0,
                value: item.value,
                selected: ""
              }, _toDisplayString(item.label), 9, _hoisted_9))
            : (_openBlock(), _createElementBlock("option", {
                key: 1,
                value: item.value
              }, _toDisplayString(item.label), 9, _hoisted_10))
        ], 64))
      }), 128))
    ], 8, _hoisted_8), [
      [_vModelSelect, $data.activeValue]
    ])
  ]))
}