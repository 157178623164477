import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title", "href", "aria-label", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overlay = _resolveComponent("Overlay")!
  const _component_VideoModal = _resolveComponent("VideoModal")!

  return (_openBlock(), _createElementBlock("a", {
    title: $props.title,
    href: $options.displayUrl,
    "aria-label": $props.ariaLabel,
    class: _normalizeClass(["cursor-pointer", $props.classes]),
    target: $props.target,
    tabindex: "0",
    onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($options.click && $options.click(...args)), ["stop"])),
    onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => ($options.click && $options.click(...args)), ["enter"]))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    ($props.type === 'overlay')
      ? (_openBlock(), _createBlock(_component_Overlay, {
          key: 0,
          url: $data.targetUrl,
          onClose: _cache[0] || (_cache[0] = ($event: any) => ($data.targetUrl=''))
        }, null, 8, ["url"]))
      : ($props.type === 'video')
        ? (_openBlock(), _createBlock(_component_VideoModal, {
            key: 1,
            "video-id": $data.targetUrl,
            "account-id": $props.accountId,
            "player-id": $props.playerId,
            onClose: _cache[1] || (_cache[1] = ($event: any) => ($data.targetUrl=''))
          }, null, 8, ["video-id", "account-id", "player-id"]))
        : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}