import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HotspotTemplate = _resolveComponent("HotspotTemplate")!
  const _component_InteractiveImageModal = _resolveComponent("InteractiveImageModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HotspotTemplate, {
      "json-map": $props.jsonMap,
      image: $props.image
    }, null, 8, ["json-map", "image"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.modals, (modal) => {
      return (_openBlock(), _createBlock(_Transition, {
        key: modal.id,
        name: "fade"
      }, {
        default: _withCtx(() => [
          ($data.opened === modal.id)
            ? (_openBlock(), _createBlock(_component_InteractiveImageModal, {
                key: 0,
                modal: modal,
                onClose: _cache[0] || (_cache[0] = ($event: any) => ($data.opened = ''))
              }, null, 8, ["modal"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}