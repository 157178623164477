<template>
    <Form v-slot="{ handleSubmit }" as="div">
        <form ref="form" :name="name" @submit.prevent="handleSubmit($event, next)">
            <MultiStepFormHeader :steps="steps" :current-step="theCurrentStep" />
            <!-- Back navigation -->
            <button type="button" class="flex items-center cursor-pointer w-fit mb-6"
                    :class="{'invisible': !showBackNavigation || sending}"
                    @click="previous"
            >
                <Icon name="chevron-left" class="w-3 h-3 text-black-300 stroke-current" />
                <span class="text-black-600 text-para-xs ml-4">{{ labels.back }} {{ lastStepTitle }}</span>
            </button>

            <div v-show="!sending && (theCurrentStep < steps.length || steps.length === 0)"
                 ref="stepContainer"
                 class="relative"
            >
                <!-- Actual form steps are rendered via a slot, including navigation/submit buttons per step -->
                <slot :current-step="theCurrentStep"
                      :show-next="theCurrentStep < (steps.length - 1)"
                      :handle-next="() => next()"
                      :show-submit="theCurrentStep === (steps.length - 1) && !sending"
                />
            </div>
            <!-- Loading spinner while sending and processing -->
            <div v-show="sending" class="flex justify-center items-center">
                <LoadingSpinner class="w-20 h-20 fill-current text-primary-500" />
            </div>
            <!-- Success view -->
            <div v-show="!sending && theCurrentStep === steps.length && steps.length > 0"
                 class="flex flex-col items-center"
            >
                <template v-if="success">
                    <slot name="result" :data="theResultData">
                        <!-- default result view -->
                        <svg class="w-14 h-14 stroke-current text-primary-500" viewBox="0 0 58 58" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="m13.852 32 6.186 8.506c.234.34.55.62.918.818a2.714 2.714 0 0 0 2.424.07c.38-.176.712-.437.967-.763l19.8-24.276"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="transition-all duration-1000 dasharray-25"
                                :class="{
                                    'dashoffset-25': !animateIcon,
                                    'dashoffset-0': animateIcon,
                                }"
                            />
                            <path d="M29 57c15.464 0 28-12.536 28-28S44.464 1 29 1 1 13.536 1 29s12.536 28 28 28Z"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="transition-all duration-1000 dasharray-50"
                                  :class="{
                                      'dashoffset-50': !animateIcon,
                                      'dashoffset-0': animateIcon,
                                  }"
                            />
                        </svg>
                        <div class="prose md:prose-md xl:prose-xl mt-6" v-html="successMessage" />
                    </slot>
                </template>
                <!-- Error view -->
                <template v-else>
                    <svg class="w-14 h-14 stroke-current text-error" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30 58.123c15.533 0 28.125-12.592 28.125-28.125C58.125 14.464 45.533 1.873 30 1.873S1.875 14.464 1.875 29.998C1.875 45.53 14.467 58.123 30 58.123ZM18.75 41.248l22.5-22.5M41.25 41.248l-22.5-22.5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="transition-all duration-1000 dasharray-50"
                              :class="{
                                  'dashoffset-50': !animateIcon,
                                  'dashoffset-0': animateIcon,
                              }"
                        />
                    </svg>
                    <div class="prose md:prose-md xl:prose-xl mt-6" v-html="errorMessage" />
                    <p v-if="serverError" class="text-para-s text-error text-center mt-6">{{ serverError }}</p>
                </template>
            </div>
        </form>
    </Form>
</template>

<script lang="ts">
import {computed, PropType} from 'vue';
import MultiStepFormHeader from './MultiStepFormHeader.vue';
import axios from 'axios';
import {CurrencyDisplay, CurrencyInputOptions} from 'vue-currency-input';
import LoadingSpinner from '../../base/LoadingSpinner.vue';
import Utils from '../../../utils/Utils';
import Icon from "../../base/Icon.vue";
import {Form} from "vee-validate";
import {DynamicsFieldMapping} from "../../form/VueForm.vue";
import DynamicsUtils from "../../../utils/DynamicsUtils";

export interface Step {
    title: string;
    type: 'normal' | 'intermediate';
}

export interface MultiStepFormLabels {
    next: string;
    back: string;
    submit: string;
}

export const formattingOptions: CurrencyInputOptions = {
    currency: 'EUR',
    currencyDisplay: CurrencyDisplay.hidden,
    autoDecimalDigits: false,
    useGrouping: true,
    precision: {min: 0, max: 2},
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    accountingSign: false
};

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {Form, Icon, LoadingSpinner, MultiStepFormHeader},
    provide() {
        return {
            model: computed(() => this.theModel),
            currentStep: computed(() => this.theCurrentStep),
            resultData: computed(() => this.theResultData),
            calcHeight: this.doCalcHeight
        };
    },
    props: {
        name: {type: String, default: 'form'},
        endpoint: {type: String, required: true},
        successMessage: {type: String, required: true},
        errorMessage: {type: String, required: true},
        labels: {type: Object as PropType<MultiStepFormLabels>, required: true},
        editMode: {type: Boolean, default: false},
        resultComponentId: {type: String, default: ''},
        steps: {type: Array as PropType<Array<Step>>},
        dynamicsSend: {type: Boolean, default: false},
        dynamicsFormId: {type: String},
        dynamicsApiUrl: {type: String},
        dynamicsLibUrl: {type: String},
        dynamicsFieldMappings: {type: Array as PropType<Array<DynamicsFieldMapping>>, default: () => []}
    },
    data() {
        return {
            theModel: {},
            theCurrentStep: 0,
            theResultData: {},
            success: false,
            animateIcon: false,
            serverError: '',
            sending: false,
            genericDynamicsFieldMappings: undefined
        };
    },
    computed: {
        showBackNavigation(): boolean {
            return (this.theCurrentStep > 0) && (this.theCurrentStep < this.steps.length) && (this.steps.length > 1);
        },
        lastStepTitle(): string {
            return this.theCurrentStep > 0 ? this.steps[this.theCurrentStep - 1]?.title || '' : '';
        }
    },
    created(): void {
        if (this.dynamicsSend && this.dynamicsLibUrl) {
            Utils.addScript(this.dynamicsLibUrl);
        }
    },
    mounted(): void {
        window.addEventListener('resize', this.doCalcHeight);
        this.doCalcHeight();

        if (this.resultComponentId) {
            this.theModel['resultComponentId'] = this.resultComponentId;
        }

        DynamicsUtils.loadGenericFieldMappings().then((mappings) => this.genericDynamicsFieldMappings = mappings);
    },
    methods: {
        next(): void {
            if (this.theCurrentStep === (this.steps.length - 1)) {
                this.submit();
            } else {
                this.theCurrentStep++;
            }
        },
        previous(): void {
            this.theCurrentStep--;
        },
        doCalcHeight() {
            if (!this.editMode) {
                let maxHeight = 0;
                this.$refs.stepContainer.childNodes.forEach((child: HTMLDivElement) => {
                    if (child.clientHeight > maxHeight) maxHeight = child.clientHeight;
                });
                this.$refs.stepContainer.style.minHeight = `${maxHeight}px`;
            }
        },
        submit(): void {
            this.sending = true;

            if (this.dynamicsSend) {
                // dynamics form capture
                const form = this.$el.querySelector('form');

                DynamicsUtils.submitDynamicsForm(
                    form,
                    this.genericDynamicsFieldMappings,
                    this.dynamicsFieldMappings,
                    this.dynamicsFormId,
                    this.dynamicsApiUrl,
                    this.doSend // callback which is called after submitting dynamics form
                );
            } else {
                // normal submit
                this.doSend();
            }
        },
        doSend() {
            axios.post(this.endpoint, this.theModel)
                .then((res) => {
                    this.resultData = res.data;
                    this.success = true;
                    this.theCurrentStep++;
                    setTimeout(() => {
                        this.animateIcon = true;
                    }, 250);
                })
                .catch((error) => {
                    if (error.response?.data) {
                        this.serverError = error.response.data;
                    } else {
                        this.serverError = this.translate('request_error');
                    }
                    this.theCurrentStep++;
                    setTimeout(() => {
                        this.animateIcon = true;
                    }, 250);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
};
</script>
