<template>
    <custom-marker
        v-if="visible"
        :options="{
            position: feature.position
        }"
        :clickable="false"
    >
        <div class="w-1 h-1 md:w-2 md:h-2 relative flex items-center justify-center flex-col">
            <div class="w-full h-full rounded-full" :style="{ backgroundColor: feature.dotColor }" />
            <div class="absolute h-full flex items-center justify-center top-3.5" :style="{ color: feature.fontColor }">
                <span :class="feature.fontClasses">{{ feature.name }}</span>
            </div>
        </div>
    </custom-marker>
</template>

<script lang="ts">

export default {
    props: {
        feature: {type: Object},
        visible: {type: Boolean, default: true}
    }
};
</script>
