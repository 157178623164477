import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative w-full h-full bg-white overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out" }
const _hoisted_2 = { class: "absolute left-6 top-6 right-6 flex flex-row text-para-xs group-hover:text-primary-500" }
const _hoisted_3 = { class: "text-black-500 group-hover:text-primary-500 transition-all duration-300 ease-out" }
const _hoisted_4 = { class: "px-6 pt-4 pb-6" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("svg", {
        class: "w-4 h-4 fill-current mr-2 transition-all duration-300 ease-out",
        viewBox: "0 0 1200 1227",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", { d: "M714.163 519.284 1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026ZM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026Z" })
      ], -1)),
      ($props.data.accountName)
        ? (_openBlock(), _createElementBlock("h6", {
            key: 0,
            class: _normalizeClass([{'invisible': !$options.showChannel}, "flex-grow font-semibold transition-all duration-300 ease-out"])
          }, _toDisplayString($props.data.accountName), 3))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.data.difference), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["min-h-30", [!$options.isVertical ? 'h-60 md:h-auto' : '']])
    }, null, 2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", {
        class: "text-para-s tracking-tight line-clamp-6",
        innerHTML: $props.data.caption
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("a", {
      href: $props.data.link,
      class: "absolute left-0 right-0 top-0 bottom-0",
      "aria-label": $props.data.accountName,
      target: "_blank"
    }, null, 8, _hoisted_6)
  ]))
}