<template>
    <div class="absolute top-1/2 transform -translate-y-1/2 left-0 right-0 flex flex-col md:flex-row border border-primary-500 bg-white rounded shadow-lg z-10">
        <div class="w-full md:w-5/12">
            <img class="w-full h-full object-cover" :src="modal.image" alt="">
        </div>
        <div class="relative w-full md:w-7/12 py-16 px-12">
            <div class="absolute top-4 right-4 flex justify-center items-center w-8 h-8 rounded-full border border-primary-500 text-primary-500 cursor-pointer"
                 @click="close"
            >
                <Icon name="close" class="w-4 h-4 stroke-current" />
            </div>
            <div class="prose md:prose-md xl:prose-xl" v-html="modal.text" />
            <a v-if="modal.link.url"
               class="inline-flex items-center text-para-xs font-semibold group max-w-full mt-4"
               :href="modal.link.url"
               :target="modal.link.target"
            >
                <span class="max-w-full overflow-hidden block break-words">{{ modal.link.label }}</span>
                <ArrowButton class="w-10 h-10" />
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {InteractiveModal} from './InteractiveImage.vue';
import Icon from "../base/Icon.vue";
import ArrowButton from "../base/ArrowButton.vue";

export default {
    components: {ArrowButton, Icon},
    props: {
        modal: {type: Object as PropType<InteractiveModal>}
    },
    emits: ['close'],
    methods: {
        close() {
            this.$emit('close');
        }
    }
};
</script>
