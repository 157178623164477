<template>
    <div>
        <TabHeader v-if="showFilter"
                   v-model="tabActive"
                   :tabs="tabItems"
                   :in-content-group="inContentGroup"
                   narrow
                   :component-uid="$.uid"
        />

        <SocialFilter v-if="showFilter && isFilterActive"
                      :accounts="dataRef"
                      :label-social-filter="labels.labelFilter"
                      :in-content-group="inContentGroup"
        />

        <SocialTabSection :data="data"
                          :layout="layout"
                          :class="{'bg-black-50': highlight}"
                          :show-label="true"
                          :in-content-group="inContentGroup"
                          :visible="visible"
                          :label-tab-section="labels.labelSocialMedia"
        />

        <div v-if="isLoadMoreActive" :class="{'bg-black-50': highlight}">
            <div class="relative hidden md:flex justify-center text-white text-para-xs md:pt-4 md:pb-8 xl:pb-12">
                <div class="absolute hidden md:block left-0 -top-38 w-full h-30 bg-gradient-to-t pointer-events-none"
                     :class="[{'from-black-50': highlight},{'from-white': !highlight}]"
                />
                <GcButton class="font-semibold" :label="labels.labelLoadMore" :secondary="true" @click="loadMore" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {PropType} from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import SocialFilter from './SocialFilter.vue';
import SocialTabSection from './SocialTabSection.vue';
import TabHeader from '../base/TabHeader.vue';
import GcButton from "../base/GcButton.vue";

export interface SocialReferenceItem {
    value: string;
    label: string;
}

export interface Account {
    type: string;
    accounts: SocialReferenceItem[];
}

export interface Labels {
    labelFilter: string;
    labelLoadMore: string;
    labelSocialMedia: string;
    labelSocialAll: string;
    labelsAccountTypes: Record<string, string>;
}

export default {
    components: {GcButton, SocialTabSection, SocialFilter, TabHeader},
    inject: ['$site'],
    provide() {
        return {
            'isVertical':  this.layout === 'vertical',
            'showChannel': this.showChannelNames
        };
    },
    props: {
        numOfItems: {type: Number, default: 8},
        uuid: {type: String, default: ''},
        rssPost: {type: Object as PropType<Record<string, string>>, default: () => {}},
        layout: {type: String, default: 'vertical'},
        showFilter: {type: Boolean, default: false},
        showChannelNames: {type: Boolean, default: true},
        highlight: {type: Boolean, default: false},
        inContentGroup: {type: Boolean, default: false},
        visible: {type: Boolean, default: true},
        accounts: {type: Array as PropType<Array<Account>>, default: () => []},
        posts: {type: Array as PropType<Array<Record<string, string>>>, default: () => []},
        labels: {type: Object as PropType<Labels>, default: () => {}}
    },
    data() {
        return {
            apiUrlSocial: `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}${Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SOCIAL, this.$site)}`,
            tabItems: [],
            offset: 0,
            isLoadMoreActive: true,
            isFilterActive: false,
            tabActive: 0,
            accActive: '',
            data: [],
            dataRef: []
        };
    },
    computed: {
        uuidParam(): string {
            if (this.showFilter) {
                return '';
            }
            return `&uuid=${this.uuid}`;
        }
    },
    watch: {
        async tabActive() {
            if (this.tabActive !== undefined) {
                this.getSocialRef();
                this.getSocial(0, true);
            }
        },
        async accActive() {
            this.getSocial(0, true);
        }
    },
    created(): void {
        this.tabItems = [this.labels.labelSocialAll];
        this.accounts.forEach((account: Account) => {
            if (account.accounts && account.accounts.length) {
                this.tabItems.push(this.labels.labelsAccountTypes[account.type] || account.type);
            }
        });
        if (this.showFilter) {
            this.getSocial(0, true);
        } else {
            this.data = this.posts;
            if (this.rssPost.link) {
                this.data.pop();
                this.data.unshift(this.rssPost);
            }
        }
    },
    methods: {
        getSocial(offset, reload): void {
            this.offset = offset;
            // eslint-disable-next-line max-len
            const url = `${this.apiUrlSocial}${this.getActiveTab() !== this.labels.labelSocialAll ? `type=${this.getActiveTab()}&` : ''}offset=${this.offset}&limit=${this.numOfItems}&account=${this.accActive}${this.uuidParam}`;
            Utils.getAPI(url)
                .then(res => {
                    // set data according to parameters and rss status
                    const tmpData: Record<string, string>[] = res.data;
                    if (reload && this.rssPost.link && this.getActiveTab() === this.labels.labelSocialAll) {
                        tmpData.pop();
                        tmpData.unshift(this.rssPost);
                        this.data = tmpData;
                        // also lower offset by 1 to reflect the removed post
                        this.offset--;
                    } else if (!reload) {
                        this.data = [...this.data, ...tmpData];
                    } else {
                        this.data = tmpData;
                    }
                    this.isLoadMoreActive = res.status;
                });
        },
        getActiveTab(): string {
            return this.tabItems[this.tabActive] || '';
        },
        getSocialRef(): void {
            if (this.getActiveTab() !== this.labels.labelSocialAll) {
                const accountData = this.accounts
                    .filter((x: Account) => x.type.toUpperCase() === this.getActiveTab().toUpperCase());

                if (accountData && accountData.length > 0) {
                    this.dataRef = accountData[0].accounts;
                    this.accActive = this.dataRef[0].value;
                    this.isFilterActive = true;
                } else {
                    this.isFilterActive = false;
                }
            } else {
                this.isFilterActive = false;
            }
        },
        loadMore(): void {
            this.offset = this.offset + this.numOfItems;
            this.getSocial(this.offset, false);
        }
    }
};
</script>
