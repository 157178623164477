import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "abstract inline-block text-heading-3 lg:text-heading-2 mb-6 lg:mb-8"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "mt-2 lg:mt-0 w-full overflow-x-auto overflow-y-hidden md:overflow-x-visible md:overflow-y-visible" }
const _hoisted_6 = { class: "mt-2 md:mt-4 mb-1 md:-mb-6 xl:-mb-2" }
const _hoisted_7 = {
  key: 0,
  class: "bg-primary-50 mb-8"
}
const _hoisted_8 = { class: "relative w-full text-primary-500" }
const _hoisted_9 = ["aria-label", "placeholder"]
const _hoisted_10 = { class: "mb-6 md:mb-8" }
const _hoisted_11 = { class: "text-para-s whitespace-nowrap" }
const _hoisted_12 = { class: "block text-para-s text-black-400 transition-colors group-hover:text-white group-focus:text-white" }
const _hoisted_13 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentsTable = _resolveComponent("DocumentsTable")!
  const _component_ContentGroupItem = _resolveComponent("ContentGroupItem")!
  const _component_ContentGroup = _resolveComponent("ContentGroup")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_SearchableGroupDropdown = _resolveComponent("SearchableGroupDropdown")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.title || $props.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`${!$props.inContentGroup ? 'container' : ''} pb-0 mb-4 md:mb-6 pt-6`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`${!$props.inContentGroup ? 'xl:w-8/12' : 'xl:w-full'} mx-auto  prose md:prose-md xl:prose-xl`)
          }, [
            ($props.title)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString($props.title), 1))
              : _createCommentVNode("", true),
            ($props.description)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "text-para-s md:text-para-base prose-caption -mt-2 xl:-mt-4 mb-6 xl:mb-8",
                  innerHTML: $props.description
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true)
          ], 2)
        ], 2))
      : _createCommentVNode("", true),
    ($props.grouping === 'tabs')
      ? (_openBlock(), _createBlock(_component_ContentGroup, {
          key: 1,
          type: "tabs",
          "default-index": 0,
          "scrollable-content": true,
          "in-content-group": $props.inContentGroup
        }, {
          default: _withCtx(({ index, type, showAll, itemsLength, componentUid }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.years.slice(0, $props.maxColumns), (year, i) => {
              return (_openBlock(), _createBlock(_component_ContentGroupItem, {
                key: year,
                title: `${year}`,
                index: i,
                "current-index": index,
                "component-uid": componentUid
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass({'container': !$props.inContentGroup})
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass({'xl:w-8/12 mx-auto': !$props.inContentGroup})
                    }, [
                      _createVNode(_component_DocumentsTable, {
                        class: "-mt-1 md:mt-6 xl:mt-8 mb-3 md:mb-4 xl:mb-6",
                        items: $options.groups.find(g => g.year === year).items,
                        options: $options.tableOptions,
                        "sorting-enabled": $props.sortingEnabled
                      }, null, 8, ["items", "options", "sorting-enabled"])
                    ], 2)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["title", "index", "current-index", "component-uid"]))
            }), 128)),
            ($options.archive.length)
              ? (_openBlock(), _createBlock(_component_ContentGroupItem, {
                  key: 'archive',
                  title: $props.labelArchive,
                  index: $options.years.slice(0, $props.maxColumns).length,
                  "current-index": index,
                  "component-uid": componentUid
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ContentGroup, {
                      class: "-mt-1 md:mt-6 xl:mt-8",
                      type: "accordion",
                      "in-content-group": $props.inContentGroup
                    }, {
                      default: _withCtx(({ index, type, showAll, itemsLength, componentUid:componentUidInner }) => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.archive, (group, i) => {
                          return (_openBlock(), _createBlock(_component_ContentGroupItem, {
                            key: i,
                            title: `${group.year}`,
                            index: i,
                            "component-uid": componentUidInner
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DocumentsTable, {
                                class: "mt-2 md:mt-4 mb-1 md:-mb-6 xl:-mb-2",
                                items: group.items,
                                options: $options.tableOptions
                              }, null, 8, ["items", "options"])
                            ]),
                            _: 2
                          }, 1032, ["title", "index", "component-uid"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["in-content-group"])
                  ]),
                  _: 2
                }, 1032, ["title", "index", "current-index", "component-uid"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["in-content-group"]))
      : ($props.grouping === 'accordions')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_ContentGroup, {
              type: "accordion",
              "in-content-group": $props.inContentGroup
            }, {
              default: _withCtx(({ index, type, showAll, itemsLength, componentUid }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groups.concat($options.archive), (group, i) => {
                  return (_openBlock(), _createBlock(_component_ContentGroupItem, {
                    key: i,
                    title: `${group.year}`,
                    index: i,
                    "component-uid": componentUid
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_DocumentsTable, {
                            items: group.items,
                            options: $options.tableOptions,
                            "sorting-enabled": $props.sortingEnabled
                          }, null, 8, ["items", "options", "sorting-enabled"])
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["title", "index", "component-uid"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["in-content-group"])
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            ($props.showSearchBar)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass({'container': !$props.inContentGroup})
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["py-6 md:py-8", {'xl:w-2/3 mx-auto': !$props.inContentGroup}])
                    }, [
                      _createElementVNode("div", _hoisted_8, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.keyword) = $event)),
                          type: "text",
                          class: "w-full rounded text-black-600 text-para-s focus:outline-none border border-black-100 py-2 px-10 placeholder-improvedContrast",
                          "aria-label": $props.filterLabels.fullSearch,
                          placeholder: $props.filterLabels.fullSearch
                        }, null, 8, _hoisted_9), [
                          [_vModelText, $data.keyword]
                        ]),
                        _createVNode(_component_Icon, {
                          name: "search",
                          class: "absolute left-4 top-3 w-4 h-4 stroke-current"
                        }),
                        _createElementVNode("div", {
                          class: "absolute right-4 top-3 cursor-pointer",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.keyword = ''))
                        }, [
                          _withDirectives(_createVNode(_component_Icon, {
                            name: "close",
                            class: "w-4 h-4 stroke-current"
                          }, null, 512), [
                            [_vShow, !!$data.keyword]
                          ])
                        ])
                      ])
                    ], 2)
                  ], 2)
                ]))
              : _createCommentVNode("", true),
            ($options.isFiltered)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(!$props.inContentGroup ? 'container' : '')
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(`${!$props.inContentGroup ? 'xl:w-2/3' : ''} mt-5 md:mt-4 xl:mt-2 mx-auto`)
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      (!$data.filterVisible)
                        ? (_openBlock(), _createBlock(_component_GcButton, {
                            key: 0,
                            icon: "filter",
                            label: "Filter",
                            "aria-label": $options.$globalLabels.open,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => ($data.filterVisible = true))
                          }, null, 8, ["aria-label"]))
                        : _createCommentVNode("", true),
                      ($data.filterVisible)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["flex flex-col gap-4 md:gap-x-8 md:flex-row md:items-center justify-end relative border border-black-100 rounded prose py-4 pl-4 pr-12 md:pl-8 md:pr-12", {'flex-wrap': $props.showSingleCategories}])
                          }, [
                            _createElementVNode("span", _hoisted_11, _toDisplayString($props.filterLabels.filterBy), 1),
                            ($options.showYearFilter)
                              ? (_openBlock(), _createBlock(_component_SearchableGroupDropdown, {
                                  key: 0,
                                  modelValue: $data.filterYear,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.filterYear) = $event)),
                                  class: "w-full md:w-40",
                                  "search-label": $props.filterLabels.search,
                                  placeholder: $props.filterLabels.year,
                                  groups: $options.yearGroup
                                }, null, 8, ["modelValue", "search-label", "placeholder", "groups"]))
                              : _createCommentVNode("", true),
                            ($options.showReportingYearFilter)
                              ? (_openBlock(), _createBlock(_component_SearchableGroupDropdown, {
                                  key: 1,
                                  modelValue: $data.filterReportingYear,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.filterReportingYear) = $event)),
                                  class: "w-full md:w-40",
                                  "search-label": $props.filterLabels.search,
                                  placeholder: $props.filterLabels.reportingYear,
                                  groups: $options.reportingYearGroup
                                }, null, 8, ["modelValue", "search-label", "placeholder", "groups"]))
                              : _createCommentVNode("", true),
                            ($options.showTagFilter && !$props.showSingleCategories)
                              ? (_openBlock(), _createBlock(_component_SearchableGroupDropdown, {
                                  key: 2,
                                  modelValue: $data.filterTag,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.filterTag) = $event)),
                                  class: "w-full flex-grow md:mr-3",
                                  "search-label": $props.filterLabels.search,
                                  placeholder: $props.filterLabels.tag,
                                  groups: $props.tagCategories
                                }, null, 8, ["modelValue", "search-label", "placeholder", "groups"]))
                              : _createCommentVNode("", true),
                            ($options.showTagFilter && $props.showSingleCategories)
                              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList($props.tagCategories, (tags, cat) => {
                                  return (_openBlock(), _createBlock(_component_Dropdown, {
                                    key: cat,
                                    modelValue: $data.filterTags[cat],
                                    "onUpdate:modelValue": ($event: any) => (($data.filterTags[cat]) = $event),
                                    class: "w-full md:w-auto min-w-40 flex-grow",
                                    "text-classes": "text-para-xs md:text-para-s h-4 md:h-6 text-black-500",
                                    title: cat,
                                    "higher-menu": true
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tags, (tag, index) => {
                                        return (_openBlock(), _createBlock(_component_DropdownItem, {
                                          key: index,
                                          label: tag,
                                          value: tag,
                                          "show-label": false,
                                          "text-classes": "pb-2 pt-2 group"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("span", _hoisted_12, _toDisplayString(tag), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1032, ["modelValue", "onUpdate:modelValue", "title"]))
                                }), 128))
                              : _createCommentVNode("", true),
                            _createElementVNode("button", {
                              class: "cursor-pointer absolute right-4 top-1/2 transform -translate-y-1/2",
                              "aria-label": $options.$globalLabels.close,
                              onClick: _cache[6] || (_cache[6] = ($event: any) => ($data.filterVisible = false))
                            }, [
                              _createVNode(_component_Icon, {
                                name: "double-arrow-left",
                                class: "stroke-current w-3 h-3"
                              })
                            ], 8, _hoisted_13)
                          ], 2))
                        : _createCommentVNode("", true)
                    ])
                  ], 2)
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(`${!$props.inContentGroup ? 'container' : ''} overflow-x-auto overflow-y-hidden md:overflow-x-visible md:overflow-y-visible`)
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(`${!$props.inContentGroup ? 'xl:w-2/3' : ''} ${!$options.isFiltered ? 'mt-5 md:mt-4 xl:mt-2' : ''} mx-auto`)
              }, [
                _createVNode(_component_DocumentsTable, {
                  items: $options.filteredData,
                  options: $options.tableOptions,
                  "sorting-enabled": $props.sortingEnabled
                }, null, 8, ["items", "options", "sorting-enabled"])
              ], 2)
            ], 2)
          ], 64))
  ]))
}