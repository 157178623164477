<template>
    <div v-show="pages > 1" class="flex flex-row justify-center items-center w-full md:w-2/3 mx-auto">
        <button type="button" class="group" :aria-label="$globalLabels.previous" @click="prev">
            <ArrowButton direction="left" class="w-10 h-10" />
        </button>
        <span class="flex-grow max-w-12" />
        <ul class="flex flex-row items-center space-x-1">
            <li>
                <button type="button" class="text-para-xs" aria-label="1" :class="getClasses(1)" @click="goTo(1)">1</button>
            </li>
            <li v-if="showLowerDots" class="text-black-200 text-para-xs font-semibold">...</li>
            <li v-for="page in pagesShown" :key="page">
                <button type="button" class="text-para-xs" :class="getClasses(page)" @click="goTo(page)">{{ page }}</button>
            </li>
            <li v-if="showUpperDots" class="text-black-200 text-para-xs font-semibold">...</li>
            <li>
                <button type="button" class="text-para-xs" :class="getClasses(pages)" @click="goTo(pages)">{{ pages }}</button>
            </li>
        </ul>
        <span class="flex-grow max-w-12" />
        <button type="button" class="group" :aria-label="$globalLabels.next"  @click="next">
            <ArrowButton direction="right" class="w-10 h-10" />
        </button>
    </div>
</template>

<script lang="ts">

import ArrowButton from "./ArrowButton.vue";

export default {
    components: {ArrowButton},
    inject: ['$globalLabels'],
    props: {
        /**
         * Current page, for allowing v-model usage.
         */
        modelValue: {type: Number},
        /**
         * The number of pages.
         */
        pages: {type: Number},
        /**
         * The amount of pages shown to the left and right of the current page (if applicable).
         * Not including the first and last page.
         */
        threshold: {type: Number, default: 1}
    },
    emits: ['update:modelValue'],
    computed: {
        showLowerDots(): boolean {
            return this.pagesShown[0] && this.pagesShown[0] > 2;
        },
        showUpperDots(): boolean {
            return this.pagesShown.length > 0 && (this.pagesShown[this.pagesShown.length - 1]) < (this.pages - 1);
        },
        pagesShown(): number[] {
            const result = [];
            if (this.pages <= ((2 * this.threshold) + 2)) {
                // case: not enough pages to fill the dynamic pages part
                for (let i = 2; i < this.pages; i++) {
                    result.push(i);
                }
            } else if (this.modelValue <= this.threshold + 1) {
                // case: lower edge
                for (let i = 2; i <= ((2 * this.threshold) + 2); i++) {
                    result.push(i);
                }
            } else if (this.modelValue >= (this.pages - this.threshold)) {
                // case: upper edge
                for (let i = (this.pages - ((2 * this.threshold) + 1)); i < this.pages; i++) {
                    result.push(i);
                }
            } else {
                // case: default
                for (let i = (this.modelValue - this.threshold); i <= (this.modelValue + this.threshold); i++) {
                    result.push(i);
                }
            }
            return result;
        }
    },
    methods: {
        getClasses(page: number): Record<string, boolean> {
            return {
                'flex flex-row justify-center items-center w-6 h-6 md:w-8 md:h-8 rounded-full border hover:border-primary-500 cursor-pointer': true,
                'text-white border-primary-500 bg-primary-500': this.modelValue === page,
                'border-transparent': this.modelValue !== page
            };
        },
        next(): void {
            if (this.modelValue < this.pages) {
                this.$emit('update:modelValue', this.modelValue + 1);
            }
        },
        prev(): void {
            if (this.modelValue > 1) {
                this.$emit('update:modelValue', this.modelValue - 1);
            }
        },
        goTo(page: number) {
            this.$emit('update:modelValue', page);
        }
    }
};
</script>
