import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = {
  key: 0,
  class: "absolute w-full h-full top-0 left-0 border-4 border-black-100"
}
const _hoisted_3 = { class: "prose-caption text-para-xs mt-4" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = ["onKeyup"]
const _hoisted_6 = { class: "tracking-tight" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = {
  key: 1,
  class: "hidden xl:flex items-center gap-1"
}
const _hoisted_10 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", {
      class: "relative h-0 pt-16/9",
      style: _normalizeStyle($options.style)
    }, [
      (($props.color.hex || '#FFFFFF').toLowerCase() === '#ffffff')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true)
    ], 4),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString($props.title), 1),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.color, (value, key) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
            (value && $options.showValue(key, value))
              ? (_openBlock(), _createElementBlock("li", {
                  key: key,
                  class: "flex justify-between items-center group",
                  tabindex: "0",
                  onKeyup: _withKeys(($event: any) => ($options.copyToClipboard(key, value)), ["enter"])
                }, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString($props.labels[key]) + " " + _toDisplayString(value), 1),
                  (!$data.copied)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "hidden xl:group-hover:flex xl:group-focus:flex items-center gap-1 cursor-pointer",
                        onClick: ($event: any) => ($options.copyToClipboard(key, value))
                      }, [
                        _createVNode(_component_Icon, {
                          name: "copy",
                          class: "stroke-current w-4 h-4"
                        }),
                        _createElementVNode("span", _hoisted_8, _toDisplayString($props.copyLabel), 1)
                      ], 8, _hoisted_7))
                    : _createCommentVNode("", true),
                  ($data.copied === key)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                        _createVNode(_component_Icon, {
                          name: "check",
                          class: "stroke-current w-4 h-4"
                        }),
                        _createElementVNode("span", _hoisted_10, _toDisplayString($props.copiedLabel), 1)
                      ]))
                    : _createCommentVNode("", true)
                ], 40, _hoisted_5))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ])
  ]))
}