import ArticleFilterGrid from "./components/articles/ArticleFilterGrid.vue";
import ArticleTabbedGrid from "./components/articles/ArticleTabbedGrid.vue";
import Accordion from "./components/base/Accordion.vue";
import ArrowButton from "./components/base/ArrowButton.vue";
import SliderButton from "./components/base/SliderButton.vue";
import Dropdown from "./components/base/Dropdown.vue";
import DropdownItem from "./components/base/DropdownItem.vue";
import GcSwiper from "./components/base/GcSwiper.vue";
import GcSwiperSlide from "./components/base/GcSwiperSlide.vue";
import CareerList from "./components/career/CareerList.vue";
import ContentGroup from "./components/contentGroup/ContentGroup.vue";
import ContentGroupItem from "./components/contentGroup/ContentGroupItem.vue";
import CookiePolicy from "./components/cookie/CookiePolicy.vue";
import EmbeddedCookiePolicy from "./components/cookie/EmbeddedCookiePolicy.vue";
import EventsSection from "./components/events/EventsSection.vue";
import ReCaptchaSubmit from "./components/form/ReCaptchaSubmit.vue";
import VueForm from "./components/form/VueForm.vue";
import VueFormInput from "./components/form/VueFormInput.vue";
import VueFormSelection from "./components/form/VueFormSelection.vue";
import VueFormCheckbox from "./components/form/VueFormCheckbox.vue";
import VueFormFile from "./components/form/VueFormFile.vue";
import Leadership from "./components/leadership/Leadership.vue";
import MediaLibrary from "./components/media/MediaLibrary.vue";
import GlobalNavigation from "./components/navigation/GlobalNavigation.vue";
import MainNavigation from "./components/navigation/MainNavigation.vue";
import MainNavigationSubMenu from "./components/navigation/MainNavigationSubMenu.vue";
import MetaNavigation from "./components/navigation/MetaNavigation.vue";
import NavigationItem from "./components/navigation/NavigationItem.vue";
import Search from "./components/navigation/Search.vue";
import SocialSection from "./components/social/SocialSection.vue";
import StageCarousel from "./components/stage/StageCarousel.vue";
import StageTeaser from "./components/stage/StageTeaser.vue";
import StageVideoTeaser from "./components/video/StageVideoTeaser.vue";
import StagePromoTeaser from "./components/stage/StagePromoTeaser.vue";
import StageSectionTeaser from "./components/stage/StageSectionTeaser.vue";
import TailingsTable from "./components/tailings/TailingsTable.vue";
import BrightCoveVideo from "./components/video/BrightCoveVideo.vue";
import MovingImageVideo from "./components/video/MovingImageVideo.vue";
import DocumentListView from "./components/documents/DocumentListView.vue";
import DocumentsTable from "./components/documents/DocumentsTable.vue";
import QuickLinks from "./components/links/QuickLinks.vue";
import BackgroundTexts from "./components/backgroundTexts/BackgroundTexts.vue";
import ScrollDown from "./components/base/ScrollDown.vue";
import IsaMillCalculator from "./components/calculators/IsaMillCalculator.vue";
import JamesonCellCalculator from "./components/calculators/JamesonCellCalculator.vue";
import MonitoringGraph from "./components/monitoring/MonitoringGraph.vue";
import MonitoringGraphMntIsa from "./components/monitoring/MonitoringGraphMntIsa.vue";
import CustomHtml from "./components/html/CustomHtml.vue";
import WorldMap from "./components/map/WorldMap.vue";
import MapEmbed from "./components/map/MapEmbed.vue";
import MapCategories from "./components/map/MapCategories.vue";
import MapOffice from "./components/map/MapOffice.vue";
import MapDetail from "./components/map/MapDetail.vue";
import TeaserLink from "./components/teaser/TeaserLink.vue";
import TeaserHeroList from "./components/teaser/TeaserHeroList.vue";
import Overlay from "./components/teaser/Overlay.vue";
import TableContainer from "./components/tables/TableContainer.vue";
import ReadMore from "./components/base/ReadMore.vue";
import SharingIcon from "./components/base/SharingIcon.vue";
import GcButton from "./components/base/GcButton.vue";
import SocioEconomicMap from "./components/socioEconomicMap/SocioEconomicMap.vue";
import SocioEconomicMapEmbed from "./components/socioEconomicMap/SocioEconomicMapEmbed.vue";
import SocioEconomicMapStaticFeature from "./components/socioEconomicMap/SocioEconomicMapStaticFeature.vue";
import SocioEconomicMapDetail from "./components/socioEconomicMap/SocioEconomicMapDetail.vue";
import SocioEconomicMapFiltering from "./components/socioEconomicMap/SocioEconomicMapFiltering.vue";
import SocioEconomicMapSelection from "./components/socioEconomicMap/SocioEconomicMapSelection.vue";
import ResponsiveImage from "./components/base/ResponsiveImage.vue";
import SimpleImage from "./components/base/SimpleImage.vue";
import BrandColor from "./components/brand/BrandColor.vue";
import Icon from "./components/base/Icon.vue";
import UserManagement from "./components/pur/UserManagement.vue";
import SocialSharing from "./components/social/SocialSharing.vue";
import Breadcrumbs from "./components/base/Breadcrumbs.vue";
import MultiStepForm from "./components/calculators/albion/MultiStepForm.vue";
import MultiStepFormRangeInput from "./components/calculators/albion/MultiStepFormRangeInput.vue";
import MultiStepFormNumberInput from "./components/calculators/albion/MultiStepFormNumberInput.vue";
import MultiStepFormTextInput from "./components/calculators/albion/MultiStepFormTextInput.vue";
import MultiStepFormSelect from "./components/calculators/albion/MultiStepFormSelect.vue";
import MultiStepFormHidden from "./components/calculators/albion/MultiStepFormHidden.vue";
import MultiStepFormCheckbox from "./components/calculators/albion/MultiStepFormCheckbox.vue";
import ContentOverlay from "./components/base/ContentOverlay.vue";
import InteractiveImage from "./components/media/InteractiveImage.vue";
import FlipCard from "./components/teaser/FlipCard.vue";
import Questionnaire from "./components/code/Questionnaire.vue";
import FiguresSection from "./components/figures/FiguresSection.vue";
import BackToTop from "./components/base/BackToTop.vue";
import InfoBanner from "./components/base/InfoBanner.vue";
import CareerListV2 from "./components/career/CareerListV2.vue";
import PublicUserComments from "./components/pur/PublicUserComments.vue";
import PublicUserLogin from "./components/pur/PublicUserLogin.vue";
import OverlayLink from "./components/portalNavigator/OverlayLink.vue";
import SearchLink from "./components/navigation/SearchLink.vue";
import ConditionalAreaSelect from "./components/calculators/ConditionalAreaSelect.vue";
import IsaKiddResult from "./components/calculators/albion/IsaKiddResult.vue";
import GolfEvent from "./components/golfevent/GolfEvent.vue";
import ToggleButtons from "./components/base/ToggleButtons.vue";
import PlayButton from "./components/video/PlayButton.vue";
import NavigationBlur from "./components/navigation/NavigationBlur.vue";
import GcHeader from "./components/base/GcHeader.vue";
import WithTopMarginContent from "./components/base/WithTopMarginContent.vue";
import CollapseTransition from "./components/base/CollapseTransition.vue";
import PositionSticky from "./components/base/PositionSticky.vue";
import ToggleButton from "./components/base/ToggleButton.vue";
import ConsentWrapper from "./components/cookie/ConsentWrapper.vue";
import { GoogleMap, AdvancedMarker, MarkerCluster, CustomMarker } from 'vue3-google-map';


export default {
    install(app) {
        /******************************
         * context- and resource path globally for vue components
         *****************************/
        app.provide('$contextPath', window['contextPath']);
        app.provide('$resourcePath', window['resourcePath']);
        app.provide('$site', window['siteName']);
        app.provide('$lang', window['lang']);
        app.provide('$globalLabels', window['globalLabels']);

        /******************************
         * Components
         *****************************/
        app.component("gc-header", GcHeader);
        app.component("with-top-margin-content", WithTopMarginContent);
        app.component("article-filter-grid", ArticleFilterGrid);
        app.component("article-tabbed-grid", ArticleTabbedGrid);
        app.component("accordion", Accordion);
        app.component("arrow-button", ArrowButton);
        app.component("slider-button", SliderButton);
        app.component("dropdown", Dropdown);
        app.component("dropdown-item", DropdownItem);
        app.component("gc-swiper", GcSwiper);
        app.component("gc-swiper-slide", GcSwiperSlide);
        app.component("career-list", CareerList);
        app.component("content-group", ContentGroup);
        app.component("content-group-item", ContentGroupItem);
        app.component("cookie-policy", CookiePolicy);
        app.component("embedded-cookie-policy", EmbeddedCookiePolicy);
        app.component("events-section", EventsSection);
        app.component("re-captcha-submit", ReCaptchaSubmit);
        app.component("vue-form", VueForm);
        app.component("vue-form-input", VueFormInput);
        app.component("vue-form-selection", VueFormSelection);
        app.component("vue-form-checkbox", VueFormCheckbox);
        app.component("vue-form-file", VueFormFile);
        app.component("leadership", Leadership);
        app.component("media-library", MediaLibrary);
        app.component("global-navigation", GlobalNavigation);
        app.component("main-navigation", MainNavigation);
        app.component("main-navigation-sub-menu", MainNavigationSubMenu);
        app.component("meta-navigation", MetaNavigation);
        app.component("navigation-item", NavigationItem);
        app.component("navigation-blur", NavigationBlur);
        app.component("search", Search);
        app.component("social-section", SocialSection);
        app.component("stage-carousel", StageCarousel);
        app.component("stage-teaser", StageTeaser);
        app.component("stage-video-teaser", StageVideoTeaser);
        app.component("stage-promo-teaser", StagePromoTeaser);
        app.component("stage-section-teaser", StageSectionTeaser);
        app.component("tailings-table", TailingsTable);
        app.component("bright-cove-video", BrightCoveVideo);
        app.component("moving-image-video", MovingImageVideo);
        app.component("document-list-view", DocumentListView);
        app.component("documents-table", DocumentsTable);
        app.component("quick-links", QuickLinks);
        app.component("background-texts", BackgroundTexts);
        app.component("scroll-down", ScrollDown);
        app.component("isamill-calculator", IsaMillCalculator);
        app.component("jameson-cell-calculator", JamesonCellCalculator);
        app.component("monitoring-graph", MonitoringGraph);
        app.component("monitoring-graph-mnt-isa", MonitoringGraphMntIsa);
        app.component("custom-html", CustomHtml);
        app.component("world-map", WorldMap);
        app.component("map-embed", MapEmbed);
        app.component("map-categories", MapCategories);
        app.component("map-office", MapOffice);
        app.component("map-detail", MapDetail);
        app.component("teaser-link", TeaserLink);
        app.component("teaser-hero-list", TeaserHeroList);
        app.component("overlay", Overlay);
        app.component("table-container", TableContainer);
        app.component("sharing-icon", SharingIcon);
        app.component("gc-button", GcButton);
        app.component("socio-economic-map", SocioEconomicMap);
        app.component("socio-economic-map-embed", SocioEconomicMapEmbed);
        app.component("socio-economic-map-static-feature", SocioEconomicMapStaticFeature);
        app.component("socio-economic-map-detail", SocioEconomicMapDetail);
        app.component("socio-economic-map-filtering", SocioEconomicMapFiltering);
        app.component("socio-economic-map-selection", SocioEconomicMapSelection);
        app.component("responsive-image", ResponsiveImage);
        app.component("simple-image", SimpleImage);
        app.component("brand-color", BrandColor);
        app.component("icon", Icon);
        app.component("user-management", UserManagement);
        app.component("social-sharing", SocialSharing);
        app.component("breadcrumbs", Breadcrumbs);
        app.component("multi-step-form", MultiStepForm);
        app.component("multi-step-form-range-input", MultiStepFormRangeInput);
        app.component("multi-step-form-number-input", MultiStepFormNumberInput);
        app.component("multi-step-form-text-input", MultiStepFormTextInput);
        app.component("multi-step-form-select", MultiStepFormSelect);
        app.component("multi-step-form-hidden", MultiStepFormHidden);
        app.component("multi-step-form-checkbox", MultiStepFormCheckbox);
        app.component("content-overlay", ContentOverlay);
        // app.component('hotspot-template', HotspotTemplate);
        // app.component('docs-header', DocsHeader);
        // app.component('docs-tabs', DocsTabs);
        // app.component('docs-tab', DocsTab);
        app.component("interactive-image", InteractiveImage);
        app.component("flip-card", FlipCard);
        app.component("questionnaire", Questionnaire);
        app.component("figures-section", FiguresSection);
        app.component("back-to-top", BackToTop);
        app.component("info-banner", InfoBanner);
        app.component("career-list-v2", CareerListV2);
        app.component("public-user-comments", PublicUserComments);
        app.component("public-user-login", PublicUserLogin);
        app.component("overlay-link", OverlayLink);
        app.component("search-link", SearchLink);
        app.component("conditional-area-select", ConditionalAreaSelect);
        app.component("isa-kidd-result", IsaKiddResult);
        app.component("golf-event", GolfEvent);
        app.component("toggle-buttons", ToggleButtons);
        app.component("play-button", PlayButton);
        app.component("collapse-transition", CollapseTransition);
        app.component("position-sticky", PositionSticky);
        app.component("toggle-button", ToggleButton);
        app.component("google-map", GoogleMap);
        app.component("advanced-marker", AdvancedMarker);
        app.component("custom-marker", CustomMarker);
        app.component("marker-cluster", MarkerCluster);
        app.component("consent-wrapper", ConsentWrapper);
        app.component("read-more", ReadMore);

        /******************************
         * Filters
         *****************************/
        const dateFormat = document.body.dataset.dateFormat || "dd/MM/yyyy";
        const formatDate = (date: string | number) => {
            if (!date) return "";
            const tdd = (x) => (x < 10 ? `0${x}` : `${x}`);
            const d = new Date(date);
            const day = tdd(d.getDate());
            const month = tdd(d.getMonth() + 1);
            const year = "" + d.getFullYear();
            return dateFormat
                .replace("dd", day)
                .replace("MM", month)
                .replace("yyyy", year);
        };
        const formatTime = (date: string | number) => {
            if (!date) return "n/a";

            const d = new Date(date);
            return d.toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            });
        };

        app.config.globalProperties.$filters = {
            formatDate(date: string | number) {
                return formatDate(date);
            },
            formatTime(date: string | number) {
                return formatTime(date);
            },
            formatDateTime(date: string | number) {
                if (!date) return "n/a";

                const formattedDate = formatDate(date);
                const formattedTime = formatTime(date);
                return formattedDate + " " + formattedTime;
            },
        };
    },
};
