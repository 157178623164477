<template>
    <div>
        <h3 v-if="title" class="text-para-xl">{{ title }}</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 mt-6 mb-10">
            <div class="flex flex-col">
                <span class="text-para-s">{{ labels.barType }}</span>
                <span class="font-semibold">{{ resultData.barType || 'n/a' }}</span>
                <span class="text-para-s mt-4">{{ labels.bladeType }}</span>
                <span class="font-semibold">{{ resultData.bladeType || 'n/a' }}</span>
                <span class="text-para-s mt-4">{{ labels.edgestripType }}</span>
                <span class="font-semibold">{{ resultData.edgestripType || 'n/a' }}</span>
                <p class="text-para-s mt-8">{{ text }}</p>
            </div>
            <div class="-mx-2 md:ml-0 md:-mr-16">
                <SimpleImage :src="currentImage"
                             class="h-0 pt-1/1"
                             img-class="absolute top-0 left-0 h-full w-full border border-black-200 object-contain object-center"
                             show-lightbox
                />
                <div class="flex gap-3 h-15 mt-7">
                    <SimpleImage :src="image"
                                 class="w-15 h-15"
                                 img-class="h-full w-full border border-black-50 object-contain object-center cursor-pointer"
                                 @click="selectCurrentImage(image)"
                    />
                    <SimpleImage v-if="imageZoom"
                                 :src="imageZoom"
                                 class="w-15 h-15"
                                 img-class="h-full w-full border border-black-50 object-contain object-center cursor-pointer"
                                 @click="selectCurrentImage(imageZoom)"
                    />
                </div>
            </div>
        </div>
        <div class="md:text-center">
            <span class="text-para-xs text-black-500">{{ footNote }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import SimpleImage from "../../base/SimpleImage.vue";

export interface ResultLabels {
    barType: string;
    bladeType: string;
    edgestripType: string;
}

export default {
    components: {SimpleImage},
    inject: ['resultData'],
    props: {
        title: {type: String},
        text: {type: String},
        footNote: {type: String},
        labels: {type: Object as PropType<ResultLabels>},
        images: {type: Object as PropType<Record<string, string>>},
        defaultImage: {type: String}
    },
    data() {
        return {
            currentImage: ''
        };
    },
    computed: {
        image(): string {
            return this.images[this.resultData.resultImage] || this.defaultImage;
        },
        imageZoom(): string {
            return this.images[this.resultData.resultImage + 'Zoom'] || this.defaultImage;
        }
    },
    mounted(): void {
        this.selectCurrentImage(this.image);
    },
    methods: {
        selectCurrentImage(image: string) {
            this.currentImage = image;
        }
    }
};
</script>
