import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "bg-primary-50 mb-8" }
const _hoisted_2 = { class: "relative w-full text-primary-500" }
const _hoisted_3 = ["aria-label", "placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col mb-8 md:mb-12"
}
const _hoisted_5 = { class: "text-para-s font-semibold mb-4" }
const _hoisted_6 = { class: "flex flex-col md:flex-row items-center gap-4 md:gap-8" }
const _hoisted_7 = { class: "flex flex-col md:flex-row gap-12 mb-2 md:mb-8" }
const _hoisted_8 = { class: "flex flex-col md:flex-row md:items-center md:justify-end gap-4 w-full md:order-2" }
const _hoisted_9 = { class: "text-para-s font-semibold" }
const _hoisted_10 = { class: "flex items-center justify-end md:justify-start w-full md:order-1 text-para-s font-semibold" }
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col overflow-x-auto pb-4 md:pb-0 px-4 md:px-0 -mx-4 md:mx-0"
}
const _hoisted_12 = { class: "w-full text-para-s md:text-para-s md:tracking-tight text-left" }
const _hoisted_13 = { class: "border-b border-black-200" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = {
  key: 0,
  class: "relative -top-px text-para-xs text-primary-900 bg-primary-50 rounded-full px-2.5 py-1"
}
const _hoisted_19 = ["href", "onKeyup"]
const _hoisted_20 = {
  key: 1,
  class: "border-t border-black-200"
}
const _hoisted_21 = ["href"]
const _hoisted_22 = {
  key: 0,
  class: "relative -top-0.5 text-para-xs text-primary-900 bg-primary-50 rounded-full px-2.5 py-1"
}
const _hoisted_23 = { class: "text-para-xs text-black-600 mt-2" }
const _hoisted_24 = { class: "prose-list mt-8" }
const _hoisted_25 = { class: "flex flex-col gap-2 text-para-s" }
const _hoisted_26 = { class: "flex justify-center mt-6" }
const _hoisted_27 = {
  key: 1,
  class: "text-error text-para-s"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SearchableGroupDropdown = _resolveComponent("SearchableGroupDropdown")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass({'container': !$props.inContentGroup})
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["py-6 md:py-8", {'xl:w-2/3 mx-auto': !$props.inContentGroup}])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.keyword) = $event)),
              type: "text",
              class: "w-full rounded text-black-600 text-para-s focus:outline-none py-2 px-10 placeholder-improvedContrast",
              "aria-label": $props.labels.placeholder,
              placeholder: $props.labels.placeholder
            }, null, 8, _hoisted_3), [
              [_vModelText, $data.keyword]
            ]),
            _createVNode(_component_Icon, {
              name: "search",
              class: "absolute left-4 top-3 w-4 h-4 stroke-current"
            }),
            _createElementVNode("div", {
              class: "absolute right-4 top-3 cursor-pointer",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.reset && $options.reset(...args)))
            }, [
              _withDirectives(_createVNode(_component_Icon, {
                name: "close",
                class: "w-4 h-4 stroke-current"
              }, null, 512), [
                [_vShow, !!$data.keyword]
              ])
            ])
          ])
        ], 2)
      ], 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass({'container': !$props.inContentGroup})
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({'xl:w-2/3 mx-auto': !$props.inContentGroup})
      }, [
        (Object.keys($props.filters).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString($props.labels.filter), 1),
              _createElementVNode("div", _hoisted_6, [
                (Object.keys($options.filterOptions).length > 0)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.filterKeys, (filter) => {
                      return (_openBlock(), _createBlock(_component_SearchableGroupDropdown, {
                        key: filter,
                        modelValue: $data.searchCriteria[filter],
                        "onUpdate:modelValue": ($event: any) => (($data.searchCriteria[filter]) = $event),
                        class: "w-full",
                        "search-label": "Search",
                        placeholder: $props.filters[filter],
                        groups: $options.filterOptions[filter],
                        "sort-group-entries": true
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "groups"]))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, _toDisplayString($props.labels.sortBy), 1),
            _createVNode(_component_Dropdown, {
              modelValue: $data.sorting,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.sorting) = $event)),
              class: "w-full md:w-auto min-w-60",
              title: "",
              "higher-menu": ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column, key) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                    _createVNode(_component_DropdownItem, {
                      label: column + ' (' + $props.labels.ascending + ')',
                      value: key + '-asc'
                    }, null, 8, ["label", "value"]),
                    _createVNode(_component_DropdownItem, {
                      label: column + ' (' + $props.labels.descending + ')',
                      value: key + '-desc'
                    }, null, 8, ["label", "value"])
                  ], 64))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", null, _toDisplayString($data.totalResults) + " " + _toDisplayString($props.labels.results), 1)
          ])
        ])
      ], 2),
      (!$options.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("table", _hoisted_12, [
              _createElementVNode("thead", _hoisted_13, [
                _createElementVNode("tr", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column, key) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: key,
                      scope: "col",
                      class: "py-2 px-1 md:py-2 md:px-3 xl:px-4 font-semibold"
                    }, [
                      _createElementVNode("span", null, _toDisplayString(column), 1)
                    ]))
                  }), 128)),
                  _cache[3] || (_cache[3] = _createElementVNode("th", {
                    scope: "col",
                    class: "py-2 px-1 md:py-2 md:px-3 xl:px-4 font-semibold"
                  }, null, -1))
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.careers, (career) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: career.id,
                    class: "even:bg-black-50 hover:bg-primary-100 focus-within:bg-primary-100 transition duration-100 ease-out"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column, key, index) => {
                      return (_openBlock(), _createElementBlock("td", { key: key }, [
                        _createElementVNode("a", {
                          href: $options.getLink(career),
                          class: "block align-middle py-2 px-1 md:py-2 md:px-3 xl:px-4",
                          tabindex: "-1"
                        }, [
                          ((key.toLowerCase()).indexOf('date') >= 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                                ($options.isFarFuture(career[key]))
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createTextVNode(_toDisplayString($props.labels.never), 1)
                                    ], 64))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(_ctx.$filters.formatDate(career[key])), 1)
                                    ], 64))
                              ]))
                            : (key === 'combinedLocation')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString($options.getCombinedLocation(career)), 1))
                              : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                  _createElementVNode("span", {
                                    class: _normalizeClass({'mr-4': index === 0 && $options.isNew(career.startDate)})
                                  }, _toDisplayString(career[key]), 3),
                                  (index === 0 && $options.isNew(career.startDate))
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString($props.labels.new), 1))
                                    : _createCommentVNode("", true)
                                ]))
                        ], 8, _hoisted_14)
                      ]))
                    }), 128)),
                    _createElementVNode("td", null, [
                      _createElementVNode("a", {
                        href: $options.getLink(career),
                        class: "inline-block align-middle py-2 px-1 md:py-2 md:px-3 xl:px-4",
                        onKeyup: _withKeys(($event: any) => ($options.openLink(career)), ["enter"])
                      }, [
                        _createVNode(_component_Icon, {
                          name: "arrow-right",
                          class: "w-4 h-4 stroke-current"
                        })
                      ], 40, _hoisted_19)
                    ])
                  ]))
                }), 128))
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_20, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.careers, (career, index) => {
              return (_openBlock(), _createElementBlock("a", {
                key: index,
                href: $options.getLink(career),
                class: _normalizeClass(["block py-4 px-2", {'bg-black-50': (index % 2) !== 0}])
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["text-para-s font-semibold", {'mr-2': $options.isNew(career.startDate)}])
                  }, _toDisplayString(career.title), 3),
                  ($options.isNew(career.startDate))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString($props.labels.new), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$filters.formatDate(career.startDate)), 1),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("ul", _hoisted_25, [
                    _createElementVNode("li", null, _toDisplayString($options.getCombinedLocation(career)), 1),
                    _createElementVNode("li", null, _toDisplayString($props.labels.closingDate) + " " + _toDisplayString(_ctx.$filters.formatDate(career.endDate)), 1)
                  ])
                ])
              ], 10, _hoisted_21))
            }), 128))
          ])),
      _createElementVNode("div", _hoisted_26, [
        (!$data.error)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _withDirectives(_createVNode(_component_GcButton, {
                class: "font-semibold",
                label: $props.labels.loadMore,
                secondary: true,
                onClick: $options.loadCareers
              }, null, 8, ["label", "onClick"]), [
                [_vShow, $options.hasMore]
              ]),
              _createVNode(_component_LoadingSpinner, {
                class: _normalizeClass([{'invisible': !$data.loading}, "w-10 h-10 fill-current text-primary-500"])
              }, null, 8, ["class"])
            ], 64))
          : (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString($props.labels.error), 1))
      ])
    ], 2)
  ]))
}