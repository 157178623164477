<template>
    <section v-html="html" />
</template>

<script lang="ts">
import {PropType} from 'vue';

export default {
    props: {
        html: {type: String},
        css: {type: String},
        js: {type: String},
        libs: {type: Array as PropType<Array<string>>}
    },
    data() {
        return {
            libPromises: []
        };
    },
    mounted(): void {
        this.insertCSS();
        this.insertLibs().then(() => this.insertJS());
    },
    methods: {
        insertCSS() {
            if (this.css && this.css.length > 0) {
                const _style: HTMLStyleElement = document.createElement('style');
                _style.innerHTML = this.css;
                document.head.appendChild(_style);
            }
        },
        /**
         * For each lib a promise is added to a stateful array. When all promises resolve custom JS is injected.
         */
        insertLibs() {
            return new Promise(resolve => {
                if (this.libs && this.libs.length > 0) {
                    this.libs.forEach(lib => {
                        const _script: HTMLScriptElement = document.createElement('script');
                        _script.type = 'text/javascript';
                        document.body.appendChild(_script);
                        this.libPromises.push(new Promise(resolve => {
                            _script.addEventListener('load', () => {
                                resolve(true);
                            });
                        }));
                        _script.src = lib;
                    });
                    Promise.all(this.libPromises).then(() => {
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            });
        },
        insertJS() {
            if (this.js && this.js.length > 0) {
                const _script: HTMLScriptElement = document.createElement('script');
                _script.innerHTML = this.js;
                document.body.appendChild(_script);
            }
        }
    }
};
</script>
