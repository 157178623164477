<template>
    <div class="relative">
        <label v-if="label" :for="`${name}-dropdown`" class="mb-2 text-para-s">{{ label }}</label>
        <button :id="`${name}-dropdown`"
                type="button"
                role="combobox"
                class="flex justify-between items-center w-full border transition-colors cursor-pointer border-black-100 hover:text-black-900 focus:border-primary-500 focus:text-black-900 px-4 py-3 md:py-2.5"
                :class="[
                    { 'border-black-100': !error },
                    { 'border-error': error },
                    { 'border-black-900': passed },
                    { 'rounded-b': !open && rounded },
                    { 'rounded-t': rounded },
                    { 'bg-white': white && !disabled },
                    { 'hover:bg-black-100 focus:bg-black-100': !white },
                    { 'bg-black-50' : disabled },
                    { 'md:flex md:flex-auto': flex }
                ]"
                :aria-label="open ? $globalLabels.close : $globalLabels.open"
                :aria-required="required"
                :aria-invalid="error"
                :aria-describedby="describedBy"
                :name="`${name}-dropdown`"
                @click="handleClick"
                @keyup.delete="$emit('delete-pressed')"
        >
            <div :class="textClasses" :title="activeTitle">{{ activeTitle }}</div>
            <Icon name="chevron-down"
                  class="w-3 h-3 flex-shrink-0 transition-transform transform origin-center stroke-current"
                  :class="{ 'rotate-180': open }"
            />
        </button>
        <CollapseTransition>
            <div v-show="open"
                 class="overflow-y-auto absolute top-full z-10 w-full bg-white border-r border-b border-l border-black-100 max-h-42"
                 :class="{'rounded-b': rounded, 'max-h-96': higherMenu}"
                 role="listbox"
            >
                <div v-if="searchable" class="cursor-pointer text-black-900 mx-2 my-3 text-para-s flex items-center justify-start relative border border-black-200 rounded">
                    <input v-model="theCurrentSearch"
                           type="text"
                           :aria-label="$globalLabels.search"
                           class="w-10/12 h-full py-2 px-2 md:py-2.5 placeholder:text-para-s focus:outline-none rounded placeholder-improvedContrast"
                    >
                    <div class="absolute top-1/2 transform -translate-y-1/2 right-4 text-black-200">
                        <Icon name="search" class="stroke-current  w-4 h-4" />
                    </div>
                </div>
                <slot />
            </div>
        </CollapseTransition>
        <select :id="name" v-model="activeValue" class="hidden" :name="name">
            <template v-for="(item, index) in items" :key="index">
                <option v-if="item.value === defaultValue" :value="item.value" selected>{{ item.label }}</option>
                <option v-else :value="item.value">{{ item.label }}</option>
            </template>
        </select>
    </div>
</template>

<script lang="ts">
import Icon from "./Icon.vue";
import CollapseTransition from "./CollapseTransition.vue";
import isEqual from 'lodash/isEqual';
import {computed} from "vue";

export default {
    components: {CollapseTransition, Icon},
    inject: ['$globalLabels'],
    provide() {
        return {
            register: this.registerItem,
            currentSearch: computed(() => this.theCurrentSearch),
            selectValue: this.select
        };
    },
    props: {
        label: {type: String},
        name: {type: String},
        defaultValue: {type: String},
        white: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        required: {type: Boolean, default: false},
        error: {type: Boolean, default: false},
        passed: {type: Boolean, default: false},
        rounded: {type: Boolean, default: true},
        higherMenu: {type: Boolean, default: false},
        title: {type: [String, Number]},
        textClasses: {type: String, default: 'text-para-s h-6 truncate'},
        flex: {type: Boolean, default: false},
        searchable: {type: Boolean, default: false},
        modelValue: {type: [String, Number, Object]},
        describedBy: {type: String} // id of description or error element, needed for a11y
    },
    emits: ['open', 'close', 'update:modelValue', 'delete-pressed'],
    data() {
        return {
            open: false,
            activeTitle: this.title ? String(this.title) : '',
            activeValue: this.title ? String(this.title) : '',
            items: [],
            theCurrentSearch: ''
        };
    },
    watch: {
        /**
         * When model value changes, show the new value as selected.
         *
         * @param newValue The new model value
         */
        modelValue(newValue: string) {
            this.select(newValue);
        }
    },
    mounted(): void {
        document.addEventListener('click', this.onOutsideClick);

        if (this.defaultValue) {
            this.select(this.defaultValue);
        } else if (this.modelValue) {
            this.select(this.modelValue);
        }
    },
    beforeUnmount(): void {
        document.removeEventListener('click', this.onOutsideClick);
    },
    methods: {
        /**
         * Handles a click on the chevron icon to open or close the dropdown.
         */
        handleClick(): void {
            if (!this.disabled) {
                this.open = !this.open;
                this.$emit(this.open ? 'open' : 'close');
            }
        },
        /**
         * Selects a value in the dropdown.
         *
         * @param value The selected value
         */
        select(value: string | number | object): void {
            const item: {label: string | number, value: string | number | object} = this.items.find(i => isEqual(i.value, value));
            if (item) {
                this.activeTitle = item.label;
                this.activeValue = item.value;
                this.$emit('update:modelValue', value);
            } else {
                this.activeTitle = this.title;
                this.activeValue = this.title;
            }
            this.open = false;
        },
        /**
         * This method is called by child items.
         *
         * @param item A child item that wants to register itself
         */
        registerItem(item: any): void {
            this.items.push(item);
        },
        /**
         * A click handler that closes the dropdown when the user clicks outside of it.
         *
         * @param evt Click event
         */
        onOutsideClick(evt: any): void {
            let targetElement = evt.target;

            do {
                if (targetElement === this.$el) {
                    return;
                }
                targetElement = targetElement.parentNode;
            } while (targetElement);
            this.open = false;
        }
    }
};
</script>
