<template>
    <Teleport to="body">
        <Transition name="fade">
            <dialog ref="dialogEL" class="min-w-full min-h-full overflow-x-hidden bg-white backdrop:bg-white">
                <div ref="embedded" />
            </dialog>
        </Transition>
    </Teleport>
</template>

<script lang="ts">
import {createVNode, nextTick, render} from 'vue';
import {gsap} from 'gsap';

export default {
    props: {
        url: {type: String}
    },
    emits: ['close'],
    data() {
        return {
            el: null
        };
    },
    watch: {
        async url() {
            if (this.url.length > 0) {
                await this.show();
            } else {
                this.hide();
            }
        }
    },
    methods: {
        /**
         * Actually fetches the dialog content.
         */
        fetchContent(): Promise<Element> {
            return new Promise((resolve, reject) => {
                // eslint-disable-next-line no-undef
                // Below parameter 'embedded' is checked for in 'gc/templates/pages/overlay.ftl'
                fetch(this.url + '?embedded=true')
                    .then(res => res.text())
                    .then(html => {
                        // insert the fetched html into the dom

                        const mount = {
                            props: [],
                            template: html
                        };
                        // https://stackoverflow.com/questions/76774836/vue-3-equivalent-of-vue-extend-mount
                        const vm = createVNode(mount);
                        vm.appContext = this._.appContext;
                        render(vm, this.$refs.embedded);

                        nextTick(() => {
                            // get the inserted html as element
                            resolve(this.$el);
                        });
                    })
                    .catch(reject);
            });
        },
        async show() {
            try {
                await this.fetchContent();

                const tl: gsap.core.Timeline = gsap.timeline({ paused: true });
                tl.fromTo('.js-content-modal-bg', { height: 0 }, { height: '100%', duration: 1.0 }, 0.0);
                tl.fromTo('.js-content-modal-content', { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 0.4 }, 0.3);
                tl.fromTo('.js-content-modal-copy', { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 0.5 }, 0.6);
                tl.restart();

                document.body.style.overflow = 'hidden';

                // get targets for open/close event
                const closeBtn: HTMLButtonElement = this.$refs.embedded.querySelector('.js-close-button');
                // and add the listeners
                closeBtn.addEventListener('click', this.hide);
                closeBtn.focus();
                // make sure hide method is called when dialog is closed by pressing escape
                this.$refs.dialogEL.addEventListener('close', this.hide);

                if (this.$refs.dialogEL && !this.$refs.dialogEL.open) {
                    this.$refs.dialogEL.showModal();
                }
            } catch (err) {
                console.error(err);
            }
        },
        hide() {
            this.$emit('close');

            document.body.style.overflow = '';

            this.$refs.dialogEL.removeEventListener('close', this.hide);

            if (this.$refs.dialogEL.open) {
                this.$refs.dialogEL.close();
            }

            window.requestAnimationFrame(() => {
                // Timeout matches the animation time for it to fade.
                setTimeout(() => {
                    this.$refs.embedded.innerHTML = '';
                }, 300);
            });
        }
    }
};
</script>
