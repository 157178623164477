import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "dialogEL",
  class: "w-full max-w-none h-full min-h-full m-0 p-0 backdrop:backdrop-filter backdrop:bg-opacity-0"
}
const _hoisted_2 = { class: "relative flex items-center w-full h-14 md:h-20 text-para-xs transition-colors text-black-700 bg-white" }
const _hoisted_3 = { class: "flex flex-grow pt-0.5 mx-4 md:mx-28" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { class: "flex flex-col h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TabHeader = _resolveComponent("TabHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("dialog", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "header-logo max-h-nav-m md:max-h-nav max-w-logo-m md:max-w-none",
              src: $props.logoPath,
              alt: $props.logoAlt
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("button", {
            class: "flex justify-center items-center w-14 h-14 md:w-20 md:h-20 text-white md:order-6 bg-primary-700 md:bg-black-900",
            "aria-label": $options.$globalLabels.close,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.hide && $options.hide(...args)))
          }, [
            _createVNode(_component_Icon, {
              name: "close",
              class: "w-4 h-4 stroke-current"
            })
          ], 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_TabHeader, {
            modelValue: $data.currentTab,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.currentTab) = $event)),
            class: "mt-14 md:mt-20",
            tabs: $options.tabTitles,
            "component-uid": _ctx.$.uid
          }, null, 8, ["modelValue", "tabs", "component-uid"]),
          _renderSlot(_ctx.$slots, "default", {
            currentTab: $data.currentTab,
            componentUid: _ctx.$.uid
          })
        ])
      ], 512)
    ]))
  ]))
}