<template>
    <div v-show="shouldRender"
         :class="textClasses"
         class="cursor-pointer text-black-900 px-4 py-3 md:py-2.5 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white"
         tabindex="0"
         role="option"
         @click="selectValue(value)"
         @keyup.enter="selectValue(value)"
    >
        {{ showLabel ? label : '' }}
        <slot />
    </div>
</template>

<script lang="ts">

export default {
    inject: ['register', 'currentSearch', 'selectValue'],
    props: {
        label: {type: [String, Number]},
        value: {type: [String, Number, Object]},
        selected: {type: Boolean, default: false},
        showLabel: {type: Boolean, default: true},
        textClasses: {type: String, default: 'text-para-s'}
    },
    emits: ['click'],
    data() {
        return {
            shouldRender: false
        };
    },
    watch: {
        /**
         * This provided field is changed when someone types in the search field of the dropdown.
         */
        currentSearch() {
            this.shouldRender = this.determineShouldRender();
        }
    },
    created(): void {
        if (this.register) {
            const {label, value} = this;
            // register self on parent component
            this.register({
                label,
                value
            });
        }
    },
    mounted(): void {
        this.shouldRender = this.determineShouldRender();
    },
    methods: {
        /**
         * Determines if this item should be displayed (sanity check).
         */
        determineShouldRender(): boolean {
            if (this.label === '​') return false;
            if (!this.currentSearch) return true;
            return this.label.toLowerCase().includes(this.currentSearch.toLowerCase());
        }
    }
};
</script>
