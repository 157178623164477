import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "text-para-base md:text-para-lg" }
const _hoisted_3 = { class: "text-para-xs md:text-para-s mb-2 md:mb-3" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = { class: "flex flex-col md:flex-row items-center mb-5 md:mb-12" }
const _hoisted_8 = { class: "w-35 h-35 md:w-50 md:h-50 min-w-35 md:min-w-50 mb-1 md:mb-0 md:mr-8 xl:mr-12" }
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "w-full md:w-auto" }
const _hoisted_11 = { class: "text-para-base md:text-para-lg text-center md:text-left" }
const _hoisted_12 = { class: "text-para-xs md:text-para-s text-center md:text-left mb-4" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "mb-4 md:mb-12" }
const _hoisted_15 = { class: "text-para-base md:text-para-xl font-semibold mb-1 md:mb-2" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "mb-4 md:mb-10" }
const _hoisted_18 = { class: "text-para-base md:text-para-xl font-semibold mb-1 md:mb-2" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "text-para-xs md:text-para-s font-semibold mr-3 md:mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowButton = _resolveComponent("ArrowButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("ul", {
      class: _normalizeClass(["grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 xl:gap-12 py-4 md:py-8 xl:py-12", {'xl:grid-cols-4': !$props.inContentGroup}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.members, (member, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "flex flex-row md:flex-col"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-16 h-16 mr-4 md:mr-0 md:mb-4", $options.imgSize])
          }, [
            _createElementVNode("img", {
              class: "w-full h-full object-cover rounded-full",
              src: member.image,
              alt: member.name
            }, null, 8, _hoisted_1)
          ], 2),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(member.name), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(member.job), 1),
            _createElementVNode("button", {
              class: "inline-flex items-center text-para-xs md:text-para-s font-semibold focus:outline-none group",
              onClick: ($event: any) => ($options.showProfile(member.uuid))
            }, [
              _createElementVNode("span", null, _toDisplayString($props.labels.viewProfile), 1),
              _createVNode(_component_ArrowButton, {
                direction: "right",
                class: "w-10 h-10"
              })
            ], 8, _hoisted_4)
          ])
        ]))
      }), 128))
    ], 2), [
      [_vShow, !$options.currentMember]
    ]),
    ($options.currentMember)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["relative w-full pt-14 md:pt-18 pb-6 md:pb-10 xl:pb-14 mx-auto", {'xl:w-2/3': !$props.inContentGroup}])
          }, [
            _createElementVNode("button", {
              class: "absolute top-0 right-0 flex items-center justify-center text-white bg-primary-500 w-10 h-10 focus:outline-none transition-all duration-100 ease-out hover:bg-black-900",
              "aria-label": $props.labels.close,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.close && $options.close(...args)))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("svg", {
                class: "w-4 h-4 stroke-current",
                viewBox: "0 0 16 16",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  d: "M2.375 13.624l11.25-11.25M13.625 13.624L2.375 2.374",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                })
              ], -1)
            ]), 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("img", {
                  class: "w-full h-full object-cover rounded-full mb-1.5 md:mb-0",
                  src: $options.currentMember.image,
                  alt: $options.currentMember.name
                }, null, 8, _hoisted_9)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("p", _hoisted_11, _toDisplayString($options.currentMember.name), 1),
                _createElementVNode("p", _hoisted_12, _toDisplayString($options.currentMember.job), 1),
                ($options.currentMember.workplace)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "text-para-xs md:text-para-s",
                      innerHTML: $options.currentMember.workplace
                    }, null, 8, _hoisted_13))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", _hoisted_15, _toDisplayString($props.labels.appointed), 1),
              _createElementVNode("div", {
                class: "text-para-s md:text-para-lg",
                innerHTML: $options.currentMember.appointed
              }, null, 8, _hoisted_16)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("p", _hoisted_18, _toDisplayString($props.labels.experience), 1),
              _createElementVNode("div", {
                class: "text-para-s md:text-para-lg",
                innerHTML: $options.currentMember.experience
              }, null, 8, _hoisted_19)
            ]),
            _createElementVNode("button", {
              class: "flex items-center focus:outline-none group",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.close && $options.close(...args)))
            }, [
              _createElementVNode("span", _hoisted_20, _toDisplayString($props.labels.close), 1),
              _createVNode(_component_ArrowButton, {
                class: "w-10 h-10",
                direction: "close"
              })
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}