import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "video",
  class: "object-cover object-center absolute top-0 left-0 w-full h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayButton = _resolveComponent("PlayButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["overflow-hidden relative w-full", [$props.fullScreen ? 'h-full' : `h-0 ${$options.padding}`]])
  }, [
    _createElementVNode("div", _hoisted_1, null, 512),
    (!$data.firstPlay)
      ? (_openBlock(), _createBlock(_component_PlayButton, {
          key: 0,
          "tablet-size": $options.tabletPlayButtonSize,
          "desktop-size": $options.desktopPlayButtonSize,
          "aria-label": $options.$globalLabels.play,
          onClick: $options.play
        }, null, 8, ["tablet-size", "desktop-size", "aria-label", "onClick"]))
      : _createCommentVNode("", true),
    (!$data.firstPlay && $props.fullScreen)
      ? _renderSlot(_ctx.$slots, "default", {
          key: 1,
          play: $options.play
        })
      : _createCommentVNode("", true)
  ], 2))
}