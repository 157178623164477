<template>
    <div v-if="!(tabs.length === 1 && hideWhenSingleTab)"
         class="text-para-s border-t border-b border-primary-100 bg-primary-50"
         :class="{'mb-14': hasControls}"
    >
        <GcSwiper class="relative"
                  :class="{'container': !inContentGroup}"
                  :container-classes="(narrow && !inContentGroup ? 'xl:w-2/3 mx-auto ' : '') + 'py-px -my-px'"
                  :is-tab="true"
                  slide-classes="flex"
        >
            <GcSwiperSlide v-for="(item, index) in tabs"
                           :id="anchors[index]"
                           :key="index"
                           ref="slideEl"
                           class="text-para-s flex items-start cursor-pointer border-t hover:border-primary-500 focus:border-primary-500 px-3 py-4 -my-px"
                           :class="[{'border-primary-500 bg-white font-semibold': index === modelValue, 'border-transparent': index !== modelValue}]"
                           :style="getStyle(index)"
                           :is-tab="true"
                           :aria-selected="(modelValue === index) ? 'true' : 'false'"
                           :aria-controls="anchors && anchors.length > 0 ? anchors[index] : 'item-' + componentUid + '-' + index"
                           tabindex="0"
                           role="tab"
                           @click="select(index); setDeeplink(index)"
                           @keyup.enter="select(index); setDeeplink(index)"
            >
                <span class="tab-label line-clamp-2" :data-before="item">{{ item }}</span>
            </GcSwiperSlide>
            <template #controls="{ next, prev, show, prevVisible, nextVisible }">
                <div v-if="controlsVisible(show)" class="absolute -bottom-14 right-4 flex items-center md:right-10 xl:right-20">
                    <button class="group" :aria-label="$globalLabels.previous" @click="prev">
                        <ArrowButton direction="left" class="btn-prev w-10 h-10" :class="{'invisible': !prevVisible}" />
                    </button>
                    <button class="group" :aria-label="$globalLabels.next" @click="next">
                        <ArrowButton direction="right" class="btn-next w-10 h-10" :class="{'invisible': !nextVisible}" />
                    </button>
                </div>
                <div v-show="prevVisible"
                     class="absolute left-0 top-0 h-full w-10 md:w-20 xl:w-40 bg-gradient-to-l from-transparent to-primary-50 pointer-events-none"
                />
                <div v-show="nextVisible"
                     class="absolute right-0 top-0 h-full w-10 md:w-20 xl:w-40 bg-gradient-to-r from-transparent to-primary-50 pointer-events-none"
                />
            </template>
        </GcSwiper>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import GcSwiperSlide from "./GcSwiperSlide.vue";
import ArrowButton from "./ArrowButton.vue";
import GcSwiper from "./GcSwiper.vue";

export default {
    components: {ArrowButton, GcSwiper, GcSwiperSlide},
    inject: ['$globalLabels'],
    props: {
        tabs: {type: Array as PropType<Array<string>>, default: () => []},
        anchors: {type: Array as PropType<Array<string>>, default: () => []},
        inContentGroup: {type: Boolean, default: false},
        narrow: {type: Boolean, default: false},
        highlight: {type: Boolean, default: false},
        modelValue: {type: Number},
        componentUid: {type: Number, required: true},
        hideWhenSingleTab: {type: Boolean, default: false}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            hasControls: false
        };
    },
    mounted(): void {
        // some dirty hack to trigger rendering to initially calculate the width correctly on the first tab
        const current = this.modelValue;
        this.$emit('update:modelValue', undefined);
        setTimeout(() => {
            // check if modelValue has changed while waiting and set accordingly, otherwise reset to what it was before
            const index = (!!this.modelValue || this.modelValue === 0) && (this.modelValue !== current) ? this.modelValue : current;
            this.$emit('update:modelValue', index);
        }, 100);
    },
    methods: {
        /**
         * Emits the update:modelValue event to trigger v-model update with the given selected index.
         *
         * @param index The selected index
         */
        select(index: number): void {
            this.$emit('update:modelValue', index);
        },
        setDeeplink(tabIndex: number): void {
            if (this.anchors[tabIndex]) {
                window.history.pushState('', '', `#${this.anchors[tabIndex]}`);
                // Set the focus on the tab for keyboard navigation
                const tab = document.getElementById(this.anchors[tabIndex]);
                tab.focus({ preventScroll: true });
            } else {
                // See ContentGroupItem.vue, where this anchor is set in case none was defined by an editor
                // Set the focus on the tab for keyboard navigation
                const autoGeneratedKey = 'item-' + this.componentUid + '-' + tabIndex;
                const tab = document.getElementById(autoGeneratedKey);

                if (tab) {
                    tab.focus({preventScroll: true});
                }
            }
        },
        getStyle(index: number): Record<string, unknown> {
            return {
                'max-width': index === this.modelValue ? this.calcWidth(index) : `${this.getMaxWidth()}px`
            };
        },
        getMaxWidth(): number {
            return Math.max(184, 853 / this.tabs.length);
        },
        calcWidth(index: number): string {
            const max = this.getMaxWidth();
            if (this.$refs.slideEl && this.$refs.slideEl[index]) {
                const slide = this.$refs.slideEl[index];
                // calc width based on character count
                // calc with an average of 7.5px per character
                const text = (slide.$el as HTMLElement).innerText || '';
                const width = (((text.length * 7.5) / 2) + 24);
                return width > max ? `${width}px` : `${max}px`;
            }
            return `${max}px`;
        },
        /**
         * Honk method to propagate the control visibility status to the component itself.
         */
        controlsVisible(show: boolean): boolean {
            this.hasControls = show;
            return show;
        }
    }
};
</script>
