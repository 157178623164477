import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "overflow-hidden relative h-screen text-white" }
const _hoisted_2 = ["poster"]
const _hoisted_3 = ["src", "type"]
const _hoisted_4 = { class: "absolute bottom-0 w-full bg-gradient-to-t from-black-900" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = {
  key: 0,
  class: "text-stage-title-mobile md:text-stage-title-tablet xl:text-stage-title-desktop mb-2 md:mb-12 xl:mb-16"
}
const _hoisted_7 = { class: "pb-24 md:pb-15 xl:pb-10 xl:inline-grid xl:gap-x-16 xl:grid-cols-3 w-full" }
const _hoisted_8 = { class: "flex flex-col justify-between items-start md:order-2" }
const _hoisted_9 = { class: "hidden text-para-lg md:block" }
const _hoisted_10 = ["href", "target", "aria-label"]
const _hoisted_11 = {
  key: 0,
  class: "flex absolute bottom-0 justify-center items-center py-6 md:py-10 space-x-2 w-full xl:hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_LoadSpinner = _resolveComponent("LoadSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        (item.video)
          ? (_openBlock(), _createElementBlock("video", {
              key: 0,
              poster: item.src,
              autoplay: "",
              muted: "",
              loop: "",
              class: _normalizeClass(["object-cover object-center top-0 left-0 w-full h-full transition-all duration-1000 ease-out transform origin-center", [i === $data.index ? 'block' : 'hidden']])
            }, [
              _createElementVNode("source", {
                src: item.video,
                type: `video/${item.videoType}`
              }, null, 8, _hoisted_3)
            ], 10, _hoisted_2))
          : (_openBlock(), _createBlock(_component_ResponsiveImage, {
              key: 1,
              "show-wide": "",
              urls: item.urls,
              "alt-title": "",
              class: _normalizeClass([[i === $data.index ? 'opacity-100 scale-100' : 'opacity-0 scale-150'], "object-cover object-center absolute top-0 left-0 w-full h-full transition-all duration-1000 ease-out transform origin-center"])
            }, null, 8, ["urls", "class"]))
      ], 64))
    }), 128)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (!!$options.currentItem && !!$options.currentItem.title)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_6, _toDisplayString($options.currentItem.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item, i) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `${item.title}-${i}`,
              class: _normalizeClass([[i === $data.index ? 'inline-flex' : 'hidden'], "overflow-hidden flex-col xl:inline-flex xl:max-w-sm xl:h-30 md:flex-row"])
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(item.description), 1),
                (item.link)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: item.link,
                      target: item.target,
                      "aria-label": item.ariaLabel ? item.ariaLabel : item.title,
                      class: "inline-flex justify-between items-center text-para-s font-semibold group"
                    }, [
                      _createTextVNode(_toDisplayString(item.label) + " ", 1),
                      _createVNode(_component_ArrowButton, {
                        direction: "right",
                        class: "ml-2 w-10 h-10"
                      })
                    ], 8, _hoisted_10))
                  : _createCommentVNode("", true)
              ]),
              ($data.items.length > 1)
                ? (_openBlock(), _createBlock(_component_LoadSpinner, {
                    key: 0,
                    class: "mr-4 md:order-1",
                    active: $data.index === i
                  }, null, 8, ["active"]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ])
      ])
    ]),
    ($data.items.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items.length, (i) => {
            return (_openBlock(), _createElementBlock("span", {
              key: `stage-page-${i}`,
              class: _normalizeClass(["inline-black w-1.5 h-1.5 rounded-full transition-colors bg-black-300", {'bg-white': $data.index === i - 1}])
            }, null, 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}