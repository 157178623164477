import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-semibold text-para-xs md:text-para-s"
}
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "relative border border-dashed border-black-100" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["id", "name", "aria-invalid", "aria-describedby", "onChange"]
const _hoisted_6 = { class: "text-para-xs md:text-para-s mt-4" }
const _hoisted_7 = { class: "text-para-xxs md:text-para-xs text-black-600 mt-1" }
const _hoisted_8 = { class: "flex items-center gap-4 border border-black-100 rounded py-5 px-4" }
const _hoisted_9 = { class: "flex flex-col md:flex-row md:items-center gap-x-4 flex-grow" }
const _hoisted_10 = { class: "text-para-xs md:text-para-s" }
const _hoisted_11 = { class: "text-para-xxs md:text-para-xs text-black-600 whitespace-nowrap" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.keys, (key) => {
        return (_openBlock(), _createBlock(_component_Field, {
          key: key,
          modelValue: $data.files,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.files) = $event)),
          ref_for: true,
          ref: "provider",
          name: $props.title,
          mode: "eager",
          rules: $props.rules,
          vid: $props.name,
          as: "div"
        }, {
          default: _withCtx(({ field, errors, value }) => [
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", {
                for: $props.name + key,
                class: "flex flex-col items-center cursor-pointer pt-5 pb-2.5 px-4"
              }, [
                _createElementVNode("input", _mergeProps({
                  id: $props.name + key,
                  ref_for: true,
                  ref: "input",
                  type: "file",
                  class: "hidden"
                }, field, {
                  name: $props.name + key,
                  accept: ".pdf",
                  "aria-invalid": errors.length > 0,
                  "aria-describedby": errors.length > 0 ? `${$props.name + key}-error` : '',
                  onChange: ($event: any) => ($options.onFileChange($event, key))
                }), null, 16, _hoisted_5),
                _createVNode(_component_Icon, {
                  name: "upload",
                  class: "w-10 h-10"
                }),
                _createElementVNode("span", _hoisted_6, _toDisplayString($props.description), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString($props.limitation), 1)
              ], 8, _hoisted_4)
            ], 512), [
              [_vShow, !$data.files[key]]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Icon, {
                name: "file2",
                class: "w-4 h-4 stroke-current"
              }),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString($data.files[key]), 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString($options.getFileSize(key)), 1)
              ]),
              _createElementVNode("div", {
                onClick: ($event: any) => ($options.reset(key))
              }, [
                _createVNode(_component_Icon, {
                  name: "close",
                  class: "w-4 h-4 stroke-current cursor-pointer"
                })
              ], 8, _hoisted_12)
            ], 512), [
              [_vShow, $data.files[key]]
            ]),
            (errors.length > 0)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  id: `${$props.name + key}-error`,
                  class: "text-error text-para-xs mt-1 md:mt-2"
                }, _toDisplayString(errors[0]), 9, _hoisted_13))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["modelValue", "name", "rules", "vid"]))
      }), 128))
    ])
  ]))
}