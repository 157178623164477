<template>
    <div>
        <div class="relative w-full">
            <div class="relative w-full h-0 md:pt-114 pt-80 print:hidden">
                <slot :name="image" />
            </div>
        </div>
        <!-- error view -->
        <div v-if="error" class="container">
            <div class="w-full xl:w-2/3 md:text-center mx-auto mt-12 md:mt-32">
                <h2 class="text-heading-2 md:text-heading-1">{{ labels.errorTitle }}</h2>
                <p class="text-para-s md:text-heading-3 mt-4 md:mt-12" v-html="labels.errorHint" />
                <div class="flex flex-col text-para-s md:text-para-base mt-6 md:mt-4">
                    <span class="font-black">{{ theEvent.paName }}</span>
                    <span>{{ labels.mail }}: {{ theEvent.paMail }}</span>
                    <span>{{ labels.phone }}: {{ theEvent.paPhone }}</span>
                    <span>{{ labels.mobile }}: {{ theEvent.paMobile }}</span>
                </div>
            </div>
        </div>
        <!-- no access / wrong token / no data found -->
        <div v-else-if="denied" class="container">
            <div class="w-full xl:w-2/3 md:text-center mx-auto mt-12 md:mt-32">
                <h2 class="text-heading-2 md:text-heading-1">{{ labels.accessDenied }}</h2>
                <p class="text-para-s md:text-heading-3 mt-4 md:mt-14" v-html="labels.accessDeniedDescription" />
            </div>
        </div>
        <!-- registration closed -->
        <div v-else-if="registrationClosed" class="container">
            <div class="w-full xl:w-2/3 md:text-center mx-auto mt-12 md:mt-32">
                <h2 class="text-heading-2 md:text-heading-1">{{ labels.registrationClosed }}</h2>
                <p class="text-para-s md:text-heading-3 mt-4 md:mt-12" v-html="labels.registrationClosedHint" />
                <div class="flex flex-col text-para-s md:text-para-base mt-6 md:mt-4">
                    <span class="font-black">{{ theEvent.paName }}</span>
                    <span>{{ labels.mail }}: {{ theEvent.paMail }}</span>
                    <span>{{ labels.phone }}: {{ theEvent.paPhone }}</span>
                    <span>{{ labels.mobile }}: {{ theEvent.paMobile }}</span>
                </div>
            </div>
        </div>
        <!-- actual loading spinner -->
        <div v-else-if="loading" class="container">
            <div class="flex justify-center w-full xl:w-2/3 mx-auto mt-12 md:mt-32">
                <LoadingSpinner class="w-20 h-20" />
            </div>
        </div>
        <!-- registration flow -->
        <div v-else-if=" theParticipant.status === 'UNKNOWN' || currentView === 'registration'" class="container">
            <div class="w-full xl:w-2/3 mx-auto">
                <GolfEventRegistrationDialog
                    ref="dialog"
                    :labels="labels"
                    @cancel="showCancelOverlay = true"
                    @participate="confirmParticipation"
                />
            </div>
        </div>
        <!-- participation has been cancelled -->
        <div v-else-if="theParticipant.status === 'CANCELLED'" class="container">
            <div class="w-full xl:w-2/3 mx-auto mt-12 md:mt-32">
                <h2 class="text-heading-2 md:text-heading-1">{{ labels.cancellationConfirmation }}</h2>
                <p class="text-para-s md:text-para-lg mt-4 md:mt-14">
                    {{ labels.dear }} {{ theParticipant.firstName }}
                </p>
                <p class="text-para-s md:text-para-lg mt-6" v-html="labels.cancellationConfirmationDescription" />
            </div>
        </div>
        <!-- summary -->
        <div v-else-if="theParticipant.status === 'REGISTERED_COMPLETE'" class="container">
            <GolfEventSummary
                class="w-full xl:w-2/3 mx-auto"
                :labels="labels"
            />
        </div>
        <!-- default main menu -->
        <div v-else-if="theParticipant.id">
            <div class="container">
                <div class="w-full xl:w-2/3 mx-auto mt-12 md:mt-32">
                    <h2 class="text-heading-2 md:text-heading-1">{{ labels.overview }}</h2>
                    <p class="text-para-s md:text-para-xl mt-4 md:mt-2" v-html="labels.overviewDescription" />
                </div>
            </div>
            <div class="bg-black-50 mt-12 md:mt-14">
                <div class="container">
                    <div
                        class="grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-8 w-full xl:w-2/3 mx-auto py-12 md:pb-18"
                    >
                        <!-- edit personal data -->
                        <button
                            class="flex md:flex-col items-center md:items-start gap-x-4 gap-y-3 py-6 px-4 md:p-6 group bg-white"
                            :class="{
                                'opacity-50': theParticipant.registrationStep < 1,
                                'cursor-pointer': theParticipant.registrationStep >= 1,
                            }"
                            @click="goToRegistrationStep(1)"
                        >
                            <span
                                class="w-8 min-w-8 h-8 md:w-10 md:min-w-10 md:h-10 flex justify-center items-center rounded-full bg-primary-50"
                                :class="{
                                    'transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 group-focus:bg-primary-500 group-focus:text-white group-focus:scale-110':
                                        theParticipant.registrationStep >= 1,
                                }"
                            >
                                <Icon name="edit2" class="stroke-current flex-shrink-0 w-4 h-4" />
                            </span>
                            <span class="text-para-s">{{ labels.editPersonalData }}</span>
                        </button>
                        <!-- edit participation -->
                        <button
                            class="flex md:flex-col items-center md:items-start gap-x-4 gap-y-3 py-6 px-4 md:p-6 group bg-white"
                            :class="{
                                'opacity-50': theParticipant.registrationStep < 2,
                                'cursor-pointer': theParticipant.registrationStep >= 2,
                            }"
                            @click="goToRegistrationStep(2)"
                        >
                            <span
                                class="w-8 min-w-8 h-8 md:w-10 md:min-w-10 md:h-10 flex justify-center items-center rounded-full bg-primary-50"
                                :class="{
                                    'transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 group-focus:bg-primary-500 group-focus:text-white group-focus:scale-110':
                                        theParticipant.registrationStep >= 2,
                                }"
                            >
                                <Icon name="edit2" class="stroke-current flex-shrink-0 w-4 h-4" />
                            </span>
                            <span class="text-para-s">{{ labels.editParticipation }}</span>
                        </button>
                        <!-- edit travel data -->
                        <button
                            class="flex md:flex-col items-center md:items-start gap-x-4 gap-y-3 py-6 px-4 md:p-6 group bg-white"
                            :class="{
                                'opacity-50': theParticipant.registrationStep < 3,
                                'cursor-pointer': theParticipant.registrationStep >= 3,
                            }"
                            @click="goToRegistrationStep(3)"
                        >
                            <span
                                class="w-8 min-w-8 h-8 md:w-10 md:min-w-10 md:h-10 flex justify-center items-center rounded-full bg-primary-50"
                                :class="{
                                    'transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 group-focus:bg-primary-500 group-focus:text-white group-focus:scale-110':
                                        theParticipant.registrationStep >= 3,
                                }"
                            >
                                <Icon name="edit2" class="stroke-current flex-shrink-0 w-4 h-4" />
                            </span>
                            <span class="text-para-s">{{ labels.editTravelData }}</span>
                        </button>
                        <!-- cancel participation -->
                        <button
                            class="flex md:flex-col items-center md:items-start gap-x-4 gap-y-3 py-6 px-4 md:p-6 group cursor-pointer bg-white"
                            @click="showCancelOverlay = true"
                        >
                            <span
                                class="w-8 min-w-8 h-8 md:w-10 md:min-w-10 md:h-10 flex justify-center items-center rounded-full transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 group-focus:bg-primary-500 group-focus:text-white group-focus:scale-110 bg-primary-50"
                            >
                                <Icon name="close" class="stroke-current flex-shrink-0 w-4 h-4" />
                            </span>
                            <span class="text-para-s">{{ labels.cancelParticipation }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- backup loading spinner when no other condition is met -->
        <div v-else class="container">
            <div class="flex justify-center w-full xl:w-2/3 mx-auto mt-12 md:mt-32">
                <LoadingSpinner class="w-20 h-20" />
            </div>
        </div>
        <!-- cancel overlay -->
        <ContentOverlay
            v-model="showCancelOverlay"
            narrow
            @hide="$refs.dialog ? ($refs.dialog.yesNoModel = []) : undefined"
        >
            <div class="h-4 md:h-14" />
            <h4 class="font-semibold text-para-lg md:text-para-xl">{{ labels.cancellation }}</h4>
            <p class="text-para-s md:text-para-lg mt-6 md:mt-5">{{ labels.cancellationDescription }}</p>
            <template #buttons>
                <div
                    class="grid grid-cols-1 md:grid-cols-2 gap-4 px-4 md:px-6 xl:px-32 pb-12 md:pb-24 mt-4 md:-mt-2 xl:-mt-6"
                >
                    <GcButton
                        class="whitespace-nowrap"
                        :label="labels.cancellationYes"
                        secondary
                        @click="cancelParticipation"
                    />
                    <GcButton
                        class="whitespace-nowrap"
                        :label="labels.cancellationNo"
                        secondary
                        @click="showCancelOverlay = false"
                    />
                </div>
            </template>
        </ContentOverlay>
    </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import { GolfEventLabels, PropertyDto } from './GolfEventTypes';
import LoadingSpinner from "../base/LoadingSpinner.vue";
import GolfEventSummary from "./GolfEventSummary.vue";
import GolfEventRegistrationDialog from "./GolfEventRegistrationDialog.vue";
import Icon from "../base/Icon.vue";
import ContentOverlay from "../base/ContentOverlay.vue";
import GcButton from "../base/GcButton.vue";

export default {
    components: {GcButton, ContentOverlay, Icon, GolfEventRegistrationDialog, GolfEventSummary, LoadingSpinner},
    provide() {
        return {
            currentStep: computed(() => this.theCurrentStep),
            participant: computed(() => this.theParticipant),
            event: computed(() => this.theEvent),
            confirmBooking: this.doConfirmBooking,
            nextStep: this.doNextStep,
            previousStep: this.doPreviousStep,
            toOverview: this.goToOverview,
            updateData: this.doUpdateData,
            updateProperty: this.doUpdateProperty
        };
    },
    props: {
        labels: {type: Object as PropType<GolfEventLabels>, required: true}
    },
    data() {
        return {
            apiUrl : Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.GOLF_EVENT,

            currentView : '',
            denied : false,
            error : false,
            loading : false,
            showCancelOverlay : false,

            theCurrentStep: 0,
            theParticipant: {},
            theEvent: {}
        };
    },
    computed: {
        registrationClosed(): boolean {
            if (!this.theEvent.registrationByDate) {
                return false;
            }
            // current time if after closing data -> closed
            return new Date().getTime() > this.theEvent.registrationByDate;
        },
        image(): string {
            if (this.error || this.denied || this.registrationClosed) {
                return 'error';
            }
            if (this.theParticipant.status === 'UNKNOWN' || this.currentView === 'registration') {
                switch (this.theCurrentStep) {
                    case 0:
                        return 'registration';
                    case 1:
                        return 'personal';
                    case 2:
                        return 'participation';
                    case 3:
                        return 'travel';
                    case 4:
                        return 'summary';
                }
            }
            if (this.theParticipant.status === 'CANCELLED') {
                return 'cancelled';
            }
            if (this.theParticipant.status === 'REGISTERED_COMPLETE') {
                return 'summary';
            }
            return 'default';
        }
    },
    created(): void {
        // query initial data for event and participant
        this.queryEvent();
        this.queryData();
    },
    methods: {
        cancelParticipation(): void {
            // cancel participation
            this.theParticipant['status'] = 'CANCELLED';
            this.showCancelOverlay = false;
            this.doUpdateData();
        },
        confirmParticipation(): void {
            // this will take the user to the first step of the actual registration form
            this.theParticipant['status'] = 'REGISTERED_OPEN';
            this.theParticipant['registrationStep'] = 1;
            this.doUpdateData();
            this.currentView = 'registration';
            this.theCurrentStep = 1;
        },
        goToRegistrationStep(step: number): void {
            // only go to step if user actually has already been on that step
            if (this.theParticipant.registrationStep >= step) {
                this.theCurrentStep = step;
                this.currentView = 'registration';
            }
        },
        queryEvent(): void {
            this.loading = true;
            axios.get(this.apiUrl)
                .then(res => {
                    this.theEvent = res.data;
                })
                .catch(() => {
                    // handle error / show error view
                    this.error = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        queryData(): void {
            this.loading = true;
            axios.get(this.apiUrl + '/participant/' + this.getTokenFromUrl())
                .then(res => {
                    this.theParticipant = res.data;
                })
                .catch(() => {
                    // no user with this token or other error -> denied
                    this.denied = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getTokenFromUrl(): string {
            let token = '';
            const params = new URLSearchParams(window.location.search);
            if (params.has('token')) {
                // token (technical id) should be present in the url
                token = params.get('token');
            } else {
                // if not, it also might be the last path segment (server side forward)
                token = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
            }
            return this.isValidTokenFormat(token) ? token : '';
        },
        isValidTokenFormat(token: string): boolean {
            // standard uuid format
            return /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(token);
        },
        doConfirmBooking(): void {
            this.theParticipant['status'] = 'REGISTERED_COMPLETE';
            this.doUpdateData();
            this.currentView = '';
        },
        doNextStep(): void {
            const step = this.theCurrentStep + 1;
            // only update the user step if it is actually higher
            if (this.theParticipant.registrationStep < step) {
                this.theParticipant['registrationStep'] = step;
            }
            this.doUpdateData();
            this.theCurrentStep = step;
        },
        doUpdateData(background = false): void {
            if (!background) {
                this.loading = true;
            }
            axios.post(this.apiUrl + '/participant', this.theParticipant)
                .then(() => {
                    // nothing to do here
                })
                .catch(() => {
                    // handle error / show error view
                    this.error = true;
                })
                .finally(() => {
                    if (!background) {
                        this.loading = false;
                    }
                });
        },
        doPreviousStep(): void {
            this.theCurrentStep -= 1;
        },
        goToOverview(): void {
            this.currentView = '';
        },
        doUpdateProperty(propertyName: string, value: any): void {
            if (!this.theParticipant || !this.theParticipant.id || !propertyName || typeof value === 'undefined') return;
            const data: PropertyDto = {
                name: propertyName,
                value: value,
                token: this.theParticipant.id
            };
            axios.post(this.apiUrl + '/participant/property', data)
                .then(() => {
                    // nothing to do here
                })
                .catch(() => {
                    // handle error / show error view
                    this.error = true;
                })
                .finally(() => {
                    // nothing to do here
                });
        }
    }
};
</script>
