<template>
    <Form v-slot="{ validate }" as="div">
        <div class="flex items-center justify-between mt-2 mb-6 md:mt-8 md:mb-20">
            <button type="button"
                    class="flex items-center cursor-pointer w-fit"
                    @click="previousStep"
            >
                <Icon name="chevron-left" class="w-3 h-3 text-black-300 stroke-current"/>
                <span class="text-black-600 text-para-xs ml-4">{{ labels.backTo }} {{ labels.personalData }}</span>
            </button>
            <button type="button"
                    class="hidden md:flex items-center group cursor-pointer"
                    @click="toOverview"
            >
                <span class="text-para-s font-semibold">{{ labels.personalOverview }}</span>
                <ArrowButton direction="person" class="w-10 h-10" />
            </button>
        </div>
        <h2 class="text-heading-2 md:text-heading-1">{{ labels.participation }}</h2>
        <p class="text-para-s md:text-para-xl mt-4 md:mt-2" v-html="labels.participationDescription"/>
        <div class="mt-10 md:mt-14">
            <label>
                <span class="font-semibold text-para-xs md:text-para-s">
                    {{ labels.participateInPrefix }}
                    <dfn title="required">*</dfn>
                </span>
                <span class="text-para-xs md:text-para-s text-black-500 font-normal">
                    {{ labels.participateInSuffix }}
                </span>
            </label>
            <ToggleButtons v-model="dinnerModel"
                           :items="{ dinner1: labels.dinnerWednesday, dinner2: labels.dinnerThursday }"
                           :views="{ dinner1: 'dinnerView', dinner2: 'dinnerView' }"
                           btn-classes="min-w-60"
                           multi-select
                           grow-mobile
                           col-mobile
                           class="mt-4"
            >
                <template #dinnerView>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
                        <VueFormSelection v-model="participant.dietaryRestrictions"
                                          name="dietaryRestrictions"
                                          type="combobox"
                                          :pill-items="true"
                                          :title="labels.dietaryRestrictions"
                                          :placeholder="labels.pleaseSelect"
                                          :labels="[
                                              labels.vegetarian + ':VEGETARIAN',
                                              labels.noPork + ':NO_PORK',
                                              labels.noShellfish + ':NO_SHELLFISH',
                                              labels.nutsAllergy + ':NUT_ALLERGY',
                                              labels.glutenAllergy + ':GLUTEN_ALLERGY',
                                              labels.other + ':OTHER'
                                          ]"
                                          @update:modelValue="onFocusChange(true, 'dietaryRestrictions', participant.dietaryRestrictions)"
                        />
                        <VueFormInput v-show="participant.dietaryRestrictions.indexOf('OTHER') >= 0"
                                      v-model="participant.dietaryRestrictionsOther"
                                      name="dietaryOther"
                                      :title="labels.otherPleaseSpecify"
                                      @blur="onFocusChange($event, 'dietaryRestrictionsOther', participant.dietaryRestrictionsOther)"
                        />
                        <VueFormSelection v-model="participant.accommodationCostCoverage"
                                          name="accommodationCostCoverage"
                                          class="col-start-1"
                                          type="select"
                                          :required="true"
                                          :title="labels.accommodationCost"
                                          :placeholder="labels.pleaseChoose"
                                          :labels="[
                                              labels.yes + ':true',
                                              labels.no + ':false'
                                          ]"
                                          @update:modelValue="onFocusChange(true, 'accommodationCostCoverage', participant.accommodationCostCoverage)"
                        />
                    </div>
                </template>
            </ToggleButtons>
            <hr class="w-full my-8 md:my-10">
            <ToggleButtons v-model="golfModel"
                           :items="{ golf: labels.golfTournament }"
                           :views="{ golf: 'golfView' }"
                           btn-classes="min-w-60"
                           multi-select
                           grow-mobile
                           class="mt-4"
            >
                <template #golfView>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
                        <VueFormInput v-model="participant.golfHandicap"
                                      name="golfHandicap"
                                      :title="labels.handicap"
                                      @blur="onFocusChange($event, 'golfHandicap', participant.golfHandicap)"
                        />
                        <VueFormInput v-model="participant.golfClubMembership"
                                      name="golfClubMembership"
                                      :title="labels.clubMember"
                                      @blur="onFocusChange($event, 'golfClubMembership', participant.golfClubMembership)"
                        />
                        <VueFormSelection v-model="participant.golfClubsRental"
                                          name="golfClubsRental"
                                          type="select"
                                          :required="true"
                                          :title="labels.clubRental"
                                          :placeholder="labels.pleaseChoose"
                                          :labels="[
                                              labels.noRental + ':NO',
                                              labels.rightHanded + ':RIGHT_HANDED',
                                              labels.rightHandedLong + ':RIGHT_HANDED_LONG',
                                              labels.leftHanded + ':LEFT_HANDED',
                                              labels.leftHandedLong + ':LEFT_HANDED_LONG'
                                          ]"
                                          @update:modelValue="onFocusChange(true, 'golfClubsRental', participant.golfClubsRental)"
                        />
                        <VueFormSelection v-model="participant.clothingSize"
                                          name="clothingSize"
                                          type="select"
                                          :required="true"
                                          :title="labels.clothingSize"
                                          :placeholder="labels.pleaseChoose"
                                          :labels="[
                                              labels.s + ':S',
                                              labels.m + ':M',
                                              labels.l + ':L',
                                              labels.xl + ':XL',
                                              labels.xxl + ':XXL'
                                          ]"
                                          @update:modelValue="onFocusChange(true, 'clothingSize', participant.clothingSize)"
                        />
                    </div>
                </template>
            </ToggleButtons>
            <hr class="w-full my-8 md:my-10">
            <ToggleButtons v-model="bikeModel"
                           :items="{ bike: labels.bikeTour }"
                           :views="{ bike: 'bikeView' }"
                           btn-classes="min-w-60"
                           multi-select
                           grow-mobile
                           class="mt-4"
            >
                <template #bikeView>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
                        <VueFormSelection v-model="participant.bikeSkillLevel"
                                          name="bikeSkillLevel"
                                          type="select"
                                          :required="true"
                                          :title="labels.skillLevel"
                                          :placeholder="labels.pleaseChoose"
                                          :labels="[
                                              labels.beginner + ':BEGINNER',
                                              labels.intermediate + ':INTERMEDIATE'
                                          ]"
                                          @update:modelValue="onFocusChange(true, 'bikeSkillLevel', participant.bikeSkillLevel)"
                        />
                        <VueFormInput v-model="participant.height"
                                      name="height"
                                      :required="true"
                                      :rules="{required: true, numeric: true}"
                                      :title="labels.height"
                                      class="col-start-1"
                                      @blur="onFocusChange($event, 'height', participant.height)"
                        />
                        <VueFormInput v-model="participant.weight"
                                      name="weight"
                                      :required="true"
                                      :rules="{required: true, numeric: true}"
                                      :title="labels.weight"
                                      @blur="onFocusChange($event, 'weight', participant.weight)"
                        />
                    </div>
                </template>
            </ToggleButtons>
        </div>
        <p class="text-para-s md:text-para-base mt-10 md:mt-16" v-html="labels.requiredHint"/>
        <button type="button"
                class="flex flex-row-reverse items-center group w-full mt-8 md:mt-12"
                @click="onSubmit(validate)"
        >
            <ArrowButton class="w-10 h-10 flex-shrink-0" show-ring-on-mobile/>
            <span class="font-semibold text-para-s line-clamp-1">{{ labels.next }}</span>
        </button>
        <div class="md:hidden border-t border-black-200 mt-12">
            <button type="button"
                    class="flex items-center group cursor-pointer mt-6"
                    @click="toOverview"
            >
                <span class="text-para-s font-semibold">{{ labels.personalOverview }}</span>
                <ArrowButton direction="person" class="w-10 h-10" show-ring-on-mobile />
            </button>
        </div>
    </Form>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { GolfEventLabels } from './GolfEventTypes';
import ToggleButtons from "../base/ToggleButtons.vue";
import VueFormSelection from "../form/VueFormSelection.vue";
import VueFormInput from "../form/VueFormInput.vue";
import ArrowButton from "../base/ArrowButton.vue";
import Icon from "../base/Icon.vue";
import { Form } from 'vee-validate';

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {Icon, ArrowButton, VueFormInput, VueFormSelection, ToggleButtons, Form},
    inject: ['participant', 'updateData', 'updateProperty', 'nextStep', 'previousStep', 'toOverview'],
    props: {
        labels: {type: Object as PropType<GolfEventLabels>, required: true}
    },
    computed: {
        dinnerModel: {
            get(): string[] {
                const result: string[] = [];
                if (this.participant.dinnerDay1) result.push('dinner1');
                if (this.participant.dinnerDay2) result.push('dinner2');
                return result;
            },
            set(newVal: string[]) {
                this.participant['dinnerDay1'] = newVal.indexOf('dinner1') >= 0;
                this.updateProperty('dinnerDay1', newVal.indexOf('dinner1') >= 0);
                this.participant['dinnerDay2'] = newVal.indexOf('dinner2') >= 0;
                this.updateProperty('dinnerDay2', newVal.indexOf('dinner2') >= 0);
            }
        },
        golfModel: {
            get(): string[] {
                const result: string[] = [];
                if (this.participant.golfTournament) result.push('golf');
                return result;
            },
            set(newVal: string[]) {
                this.participant['golfTournament'] = newVal.indexOf('golf') >= 0;
                this.updateProperty('golfTournament', newVal.indexOf('golf') >= 0);
            }
        },
        bikeModel: {
            get(): string[] {
                const result: string[] = [];
                if (this.participant.bikeTour) result.push('bike');
                return result;
            },
            set(newVal: string[]) {
                this.participant['bikeTour'] = newVal.indexOf('bike') >= 0;
                this.updateProperty('bikeTour', newVal.indexOf('bike') >= 0);
            }
        }
    },
    mounted(): void {
        this.$el.scrollIntoView({behavior: 'smooth'});
    },
    methods: {
        onFocusChange(passed: boolean, name: string, value: any): void {
            if (passed) {
                this.updateProperty(name, value);
            }
        },
        onSubmit(validate: () => Promise<boolean>): void {
            validate().then((valid) => {
                if (valid) {
                    this.nextStep();
                }
            });
        }
    }
};
</script>
