<template>
    <div class="overflow-hidden relative w-full" :class="[fullScreen ? 'h-full' : `h-0 ${padding}`]">
        <div ref="video" class="object-cover object-center absolute top-0 left-0 w-full h-full" />
        <PlayButton v-if="!firstPlay"
                    :tablet-size="tabletPlayButtonSize"
                    :desktop-size="desktopPlayButtonSize"
                    :aria-label="$globalLabels.play"
                    @click="play"
        />
        <slot v-if="!firstPlay && fullScreen" :play="play" />
    </div>
</template>

<script lang="ts">
import brightcovePlayerLoader from '@brightcove/player-loader';
import PlayButton from "./PlayButton.vue";

export default {
    components: {PlayButton},
    inject: ['$globalLabels'],
    props: {
        id: {type: String, default: ''},
        placeholder: {type: String, default: ''},
        playerId: {type: String, default: ''},
        videoId: {type: String, default: ''},
        account: {type: String, default: ''},
        dataPlayer: {type: String, default: ''},
        rendition: {type: String, default: 'landscape'},
        fullScreen: {type: Boolean, default: false},
        numOfComponents: {type: Number, default: 1},
        narrow: {type: Boolean, default: false},
        isSlider: {type: Boolean, default: false},
    },
    data() {
        return {
            videoObject: null,
            firstPlay: false
        };
    },
    computed: {
        padding(): string {
            return this.rendition === 'landscape' ? 'pt-16/9' : 'pt-3/4';
        },
        tabletPlayButtonSize(): number {
            // preserve comment: md:h-24 md:w-24 md:h-16 md:w-16 md:h-10 md:w-10
            if (this.fullScreen) {
                return 24;
            } else if (this.isSlider || (this.numOfComponents % 2 === 0)) {
                return 16;
            } else if (this.numOfComponents % 3 === 0) {
                return 10;
            }
            return 20;
        },
        desktopPlayButtonSize(): number {
            // preserve comment: xl:h-20 xl:w-20 xl:h-16 xl:w-16 xl:h-10 xl:w-10
            if (this.fullScreen) {
                return 24;
            } else if (this.narrow) {
                if (this.isSlider || (this.numOfComponents % 2 === 0)) {
                    return 16;
                } else if (this.numOfComponents % 3 === 0) {
                    return 10;
                } else {
                    return 20;
                }
            } else if (this.isSlider || (this.numOfComponents % 2 === 0)) {
                return 20;
            } else if (this.numOfComponents % 3 === 0) {
                return 16;
            }
            return 24;
        }
    },
    mounted(): void {
        const options = {} as Record<string, string>;
        if (this.noPreload) {
            options.preload = 'none';
        }
        brightcovePlayerLoader({
            refNode: this.$refs.video,
            refNodeInsert: 'append',
            accountId: this.account,
            playerId: this.dataPlayer,
            embedId: 'default',
            videoId: this.videoId,
            poster: this.placeholder,
            options: options
        })
            .then(success => {
                this.videoObject = success.ref;
                this.videoObject.addClass('w-full');
                this.videoObject.addClass('h-full');
                this.videoObject.bigPlayButton.hide();
                this.videoObject.on('firstplay', () => {
                    // if play is not triggered by the play button
                    this.firstPlay = true;
                });
                // somehow dirty, this is used to control the visibility of the slider controls if applicable
                this.videoObject.on('pause', () => {
                    if (typeof (this.$parent.$parent as any).isPlaying !== 'undefined') {
                        (this.$parent.$parent as any).isPlaying = false;
                    }
                });
                this.videoObject.on('play', () => {
                    if (typeof (this.$parent.$parent as any).isPlaying !== 'undefined') {
                        (this.$parent.$parent as any).isPlaying = true;
                    }
                });
            })
            .catch(error => {
                console.log(error);
            });
    },
    methods: {
        play(): void {
            if (this.videoObject) {
                this.videoObject.play();
                this.firstPlay = true;
            }
        }
    }
};
</script>
