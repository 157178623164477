<template>
    <Field
        v-slot="{ field, errors }"
        v-model="model"
        :name="name"
        :rules="rules"
        type="checkbox"
        :value="true"
        as="div"
    >
        <label :for="name" class="flex flex-row items-center">
            <input
                :id="name"
                v-bind="field"
                class="absolute opacity-0 text-para-xs md:text-para-s"
                type="checkbox"
                :name="name"
                :aria-required="required"
                :aria-invalid="errors.length > 0"
                :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
            >
            <span class="relative prose checkbox-label" :class="labelClasses">
                <slot />
            </span>
        </label>
        <span
            v-if="errors.length > 0"
            :id="`${name}-error`"
            class="text-error text-para-xs mt-1 md:mt-2"
        >
            {{ errors[0] }}
        </span>
    </Field>
</template>

<script lang="ts">
import { Field } from "vee-validate";

export default {
    components: {Field},
    props: {
        name: {type: String, required: true},
        required: {type: Boolean, default: false},
        textClasses: {type: String},
        modelValue: {type: Boolean}
    },
    emits: ['update:modelValue'],
    computed: {
        // make v-model work on this component
        model: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        rules(): Record<string, unknown> {
            return {
                required: this.required
            };
        },
        labelClasses(): string {
            if (this.textClasses) {
                return this.textClasses;
            } else {
                return 'text-para-xs pl-5';
            }
        }
    }
};
</script>
