import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, { as: "div" }, {
    default: _withCtx(({ handleSubmit }) => [
      _renderSlot(_ctx.$slots, "default", {
        submit: $options.submit,
        handleSubmit: handleSubmit
      })
    ]),
    _: 3
  }))
}