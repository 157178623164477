import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "aria-required", "aria-invalid", "aria-describedby"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: $options.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.model) = $event)),
    name: $props.name,
    rules: $options.rules,
    type: "checkbox",
    value: true,
    as: "div"
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("label", {
        for: $props.name,
        class: "flex flex-row items-center"
      }, [
        _createElementVNode("input", _mergeProps({ id: $props.name }, field, {
          class: "absolute opacity-0 text-para-xs md:text-para-s",
          type: "checkbox",
          name: $props.name,
          "aria-required": $props.required,
          "aria-invalid": errors.length > 0,
          "aria-describedby": errors.length > 0 ? `${$props.name}-error` : ''
        }), null, 16, _hoisted_2),
        _createElementVNode("span", {
          class: _normalizeClass(["relative prose checkbox-label", $options.labelClasses])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ], 8, _hoisted_1),
      (errors.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            id: `${$props.name}-error`,
            class: "text-error text-para-xs mt-1 md:mt-2"
          }, _toDisplayString(errors[0]), 9, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["modelValue", "name", "rules"]))
}