/*******************************************************************************************************
* This util-class is a copy of
* https://github.com/euvl/vue-js-toggle-button/blob/master/src/utils.js
*
* It is used in this project for /gc/ts/components/base/ToggleButton.vue
*******************************************************************************************************/

export const isString = (value) => {
    return typeof value === 'string';
};

export const isObject = (value) => {
    return typeof value === 'object';
};

export const has = (object, key) => {
    return isObject(object) && object.hasOwnProperty(key);
};

export const get = (object, key, defaultValue) => {
    return has(object, key) ? object[key] : defaultValue;
};

export const px = value => {
    return `${value}px`;
};

export const translate = (x, y) => {
    return `translate(${x}, ${y})`;
};
