<template>
    <div :class="topMarginContent">
        <slot />
    </div>
</template>

<script lang="ts">
import {mapState} from "pinia";
import {useNavigationStore} from "../../stores/navigation";

export default {
    props: {
        sm: {type: Number, default: 14},
        md: {type: Number, default: 20},
        xl: {type: Number, default: 20}
    },
    computed: {
        ...mapState(useNavigationStore, ['additionalTopMarginContent']),
        topMarginContent(): string {
            return this.additionalTopMarginContent({ prefix: 'mt', sm: this.sm, md: this.md, xl: this.xl });
        }
    }
};
</script>
