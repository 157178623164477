import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-center items-center w-full md:w-2/3 mx-auto" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = { class: "flex flex-row items-center space-x-1" }
const _hoisted_4 = {
  key: 0,
  class: "text-black-200 text-para-xs font-semibold"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "text-black-200 text-para-xs font-semibold"
}
const _hoisted_7 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowButton = _resolveComponent("ArrowButton")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "group",
      "aria-label": $options.$globalLabels.previous,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.prev && $options.prev(...args)))
    }, [
      _createVNode(_component_ArrowButton, {
        direction: "left",
        class: "w-10 h-10"
      })
    ], 8, _hoisted_2),
    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "flex-grow max-w-12" }, null, -1)),
    _createElementVNode("ul", _hoisted_3, [
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass(["text-para-xs", $options.getClasses(1)]),
          "aria-label": "1",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.goTo(1)))
        }, "1", 2)
      ]),
      ($options.showLowerDots)
        ? (_openBlock(), _createElementBlock("li", _hoisted_4, "..."))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.pagesShown, (page) => {
        return (_openBlock(), _createElementBlock("li", { key: page }, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(["text-para-xs", $options.getClasses(page)]),
            onClick: ($event: any) => ($options.goTo(page))
          }, _toDisplayString(page), 11, _hoisted_5)
        ]))
      }), 128)),
      ($options.showUpperDots)
        ? (_openBlock(), _createElementBlock("li", _hoisted_6, "..."))
        : _createCommentVNode("", true),
      _createElementVNode("li", null, [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass(["text-para-xs", $options.getClasses($props.pages)]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.goTo($props.pages)))
        }, _toDisplayString($props.pages), 3)
      ])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "flex-grow max-w-12" }, null, -1)),
    _createElementVNode("button", {
      type: "button",
      class: "group",
      "aria-label": $options.$globalLabels.next,
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.next && $options.next(...args)))
    }, [
      _createVNode(_component_ArrowButton, {
        direction: "right",
        class: "w-10 h-10"
      })
    ], 8, _hoisted_7)
  ], 512)), [
    [_vShow, $props.pages > 1]
  ])
}