<template>
    <header id="header"
            class="w-full z-40"
            :class="[ {'fixed' : !editMode}, topMarginHeader ]"
    >
        <slot />
    </header>
</template>

<script lang="ts">

import {mapState} from "pinia";
import {useNavigationStore} from "../../stores/navigation";

export default {
    props: {
        editMode: {type: Boolean, default: false}
    },
    computed: {
        ...mapState(useNavigationStore, ['additionalTopMarginHeader']),
        topMarginHeader(): string {
            return this.additionalTopMarginHeader({prefix: 'top', sm: 0, md: 0, xl: 0});
        }
    }
};
</script>
