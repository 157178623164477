<template>
    <div class="flex items-center justify-center" :style="checkedStyle">
        <svg v-if="model" xmlns="http://www.w3.org/2000/svg" :style="iconStyles" viewBox="0 0 9 9" fill="none" class="w-9/12 h-9/12">
            <path d="M7.5 1L2.99997 6.99994L0.499962 4.99995" stroke="white" stroke-width="1.5" />
        </svg>
    </div>
</template>

<script lang="ts">

export default {
    props: {
        checkedStyles: {type: Object, default: () => ({ backgroundColor: '#00928E', borderColor: '#00928E' })},
        iconStyles: {type: Object, default: () => {}},
        model: {type: Boolean, default: false}
    },
    computed: {
        checkedStyle() {
            return !this.model ? {} : this.checkedStyles;
        }
    }
};
</script>
