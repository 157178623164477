<template>
    <div>
        <div v-if="hasPrivacy" class="w-full mb-4">
            <VueFormCheckbox v-model="checked" name="privacy" required>
                <slot name="privacytext" />
            </VueFormCheckbox>
        </div>
        <div class="flex flex-col space-y-2 md:flex-row md:justify-between md:items-end md:space-y-0">
            <VueRecaptcha
                v-if="!isDev()"
                :sitekey="siteKey"
                @verify="onVerify"
                @expired="onExpire"
            />
            <div class="w-full md:w-auto pb-0.5">
                <GcButton
                    class="w-full md:w-auto font-semibold disabled:text-black-600"
                    type="submit"
                    :label="label"
                    :disabled="disabled || !verified || !checked"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import VueFormCheckbox from "./VueFormCheckbox.vue";
import GcButton from "../base/GcButton.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default {
    components: { GcButton, VueFormCheckbox, VueRecaptcha },
    props: {
        label: { type: String, required: true },
        siteKey: { type: String, default: "---no-recaptcha---" },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            verified: this.isDev(),
            checked: false,
        };
    },
    computed: {
        hasPrivacy() {
            return (
                this.$slots.privacytext && this.$slots.privacytext().length > 0
            );
        }
    },
    mounted() {
        if (!this.hasPrivacy) {
            this.checked = true;
        }
    },
    methods: {
        isDev() {
            return this.siteKey === "---no-recaptcha---";
        },
        onVerify(): void {
            this.verified = true;
        },
        onExpire(): void {
            this.verified = false;
        }
    }
};
</script>
