import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "flex justify-center gap-x-2 md:gap-x-20 mt-8 md:mt-12 bg-primary-50 p-4 md:p-12 -mx-4 md:mx-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabHeader = _resolveComponent("TabHeader")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_GraphView = _resolveComponent("GraphView")!
  const _component_WindChart = _resolveComponent("WindChart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TabHeader, {
      modelValue: $data.tabModel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.tabModel) = $event)),
      tabs: $options.tabs,
      class: "mb-6",
      "in-content-group": $props.inContentGroup,
      narrow: "",
      "component-uid": _ctx.$.uid
    }, null, 8, ["modelValue", "tabs", "in-content-group", "component-uid"]),
    _createElementVNode("div", {
      class: _normalizeClass({'container': !$props.inContentGroup})
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col w-full mx-auto", {'xl:w-2/3': !$props.inContentGroup}])
      }, [
        ($data.tabModel === 0)
          ? (_openBlock(), _createBlock(_component_TableView, {
              key: 0,
              labels: $props.labels,
              scale: $props.scale,
              data: $data.data,
              "hide-traffic-light": $props.hideTrafficLight
            }, null, 8, ["labels", "scale", "data", "hide-traffic-light"]))
          : _createCommentVNode("", true),
        ($data.tabModel === 1)
          ? (_openBlock(), _createBlock(_component_GraphView, {
              key: 1,
              labels: $props.labels,
              data: $data.data,
              "max-value": $props.maxValue
            }, null, 8, ["labels", "data", "max-value"]))
          : _createCommentVNode("", true),
        ($data.windData.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.windData, (item, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  _createVNode(_component_WindChart, {
                    label: item.label,
                    speed: item.speed,
                    direction: item.direction
                  }, null, 8, ["label", "speed", "direction"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ]))
}