<template>
    <div class="flex flex-col overflow-x-auto pb-4 md:pb-0 px-4 md:px-0 -mx-4 md:mx-0">
        <table class="w-full text-para-xs md:text-para-s md:tracking-tight text-left">
            <thead class="border-b border-black-200">
            <tr>
                <th scope="col" class="p-2 md:px-4">
                    {{ labels.date }}
                </th>
                <th scope="col" class="p-2 md:px-4">
                    {{ labels.event }}
                </th>
                <th v-if="showAlert" scope="col" class="p-2 md:px-4" />
            </tr>
            </thead>
            <tbody>
            <tr v-for="(event, index) in events"
                :key="index"
                class="even:bg-black-50 hover:bg-primary-100 transition duration-100 ease-out"
            >
                <td class="p-2 md:px-4 whitespace-nowrap md:whitespace-normal">
                    {{ $filters.formatDate(event.dateFrom) }}
                </td>
                <td class="p-2 md:px-4 whitespace-nowrap md:whitespace-normal prose">
                    <a v-if="event.link" :href="event.link || ''">
                        {{ event.title }}
                    </a>
                    <span v-else class="inline-block py-1 md:py-0">{{ event.title }}</span>
                </td>
                <td v-if="showAlert" class="flex flex-row items-center justify-end p-2 md:px-4 whitespace-nowrap prose">
                    <a :href="event.icsLink || ''">
                        {{ labels.addToCalendar }}
                    </a>
                    <svg class="w-4 h-4 stroke-current ml-2" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.689 3.188H2.314a.875.875 0 00-.875.874v9.625c0 .484.391.876.875.876h11.375a.875.875 0 00.875-.876V4.063a.875.875 0 00-.875-.875zM1 8h13.125M4.939 4.5V1.437M11.064 4.5V1.437"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {CalendarEvent, EventsLabels} from './EventsSection.vue';

export default {
    props: {
        events: {type: Array as PropType<Array<CalendarEvent>>, default: () => []},
        labels: {
            type: Object as PropType<EventsLabels>, default: () => {
            }
        },
        showAlert: {type: Boolean, default: false}
    }
};
</script>
