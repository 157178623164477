<template>
    <div class="mb-12 xl:mb-20">
        <ul class="flex">
            <template v-for="(step, index) in steps" :key="index">
                <li v-if="step.type === 'normal'" class="relative flex flex-col items-center">
                    <span class="w-3 h-3 md:w-4 md:h-4 rounded-full"
                          :class="{
                              'border-2 border-primary-500': currentStep === index,
                              'bg-primary-500': currentStep > index,
                              'bg-black-300': currentStep < index
                          }"
                    />
                    <span class="absolute top-6 hidden md:block text-para-xs whitespace-nowrap"
                          :class="{
                              'text-primary-500': currentStep >= index,
                              'text-black-300': currentStep < index,
                              'font-semibold': currentStep === index
                          }"
                    >
                        {{ step.title }}
                    </span>
                </li>
                <li v-if="step.type === 'intermediate'" class="-mx-2.5" />
                <li v-if="index < (steps.length - 1)"
                    class="h-0.5 mx-2 my-1.25 md:my-1.5 rounded-sm"
                    :style="getStyles(index)"
                    :class="{
                        'bg-black-300': currentStep <= index,
                        'bg-primary-500': currentStep > index
                    }"
                />
            </template>
        </ul>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {Step} from './MultiStepForm.vue';

export default {
    props: {
        steps: {type: Array as PropType<Step[]>},
        currentStep: {type: Number}
    },
    methods: {
        getStyles(step: number): Record<string, unknown> {
            return {
                'flex-grow': this.getFlexGrow(step)
            };
        },
        getFlexGrow(step: number): number {
            const intermediates = this.getIntermediateStepsBefore(step) + this.getIntermediateStepsAfter(step);
            return 1 / (intermediates + 1);
        },
        getIntermediateStepsBefore(step: number): number {
            if (this.steps[step].type === 'normal' || step === 0) {
                return 0;
            }
            let result = 1;
            for (let i = step - 1; i >= 0; i--) {
                if (this.steps[i].type === 'intermediate') {
                    result++;
                } else {
                    break;
                }
            }
            return result;
        },
        getIntermediateStepsAfter(step: number): number {
            if (step === (this.steps.length - 1)) {
                return 0;
            }
            let result = 0;
            for (let i = step + 1; i <= this.steps.length - 1; i++) {
                if (this.steps[i].type === 'intermediate') {
                    result++;
                } else {
                    break;
                }
            }
            return result;
        }
    }
};
</script>
