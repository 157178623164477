import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverlayLink = _resolveComponent("OverlayLink")!

  return (!$props.overlay)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: $props.url
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_1))
    : (_openBlock(), _createBlock(_component_OverlayLink, {
        key: 1,
        url: $props.url
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["url"]))
}