import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-para-xl"
}
const _hoisted_2 = { class: "grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8 mt-6 mb-10" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "text-para-s" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "text-para-s mt-4" }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = { class: "text-para-s mt-4" }
const _hoisted_9 = { class: "font-semibold" }
const _hoisted_10 = { class: "text-para-s mt-8" }
const _hoisted_11 = { class: "-mx-2 md:ml-0 md:-mr-16" }
const _hoisted_12 = { class: "flex gap-3 h-15 mt-7" }
const _hoisted_13 = { class: "md:text-center" }
const _hoisted_14 = { class: "text-para-xs text-black-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleImage = _resolveComponent("SimpleImage")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($props.title)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString($props.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString($props.labels.barType), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString($options.resultData.barType || 'n/a'), 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString($props.labels.bladeType), 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString($options.resultData.bladeType || 'n/a'), 1),
        _createElementVNode("span", _hoisted_8, _toDisplayString($props.labels.edgestripType), 1),
        _createElementVNode("span", _hoisted_9, _toDisplayString($options.resultData.edgestripType || 'n/a'), 1),
        _createElementVNode("p", _hoisted_10, _toDisplayString($props.text), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_SimpleImage, {
          src: $data.currentImage,
          class: "h-0 pt-1/1",
          "img-class": "absolute top-0 left-0 h-full w-full border border-black-200 object-contain object-center",
          "show-lightbox": ""
        }, null, 8, ["src"]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_SimpleImage, {
            src: $options.image,
            class: "w-15 h-15",
            "img-class": "h-full w-full border border-black-50 object-contain object-center cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.selectCurrentImage($options.image)))
          }, null, 8, ["src"]),
          ($options.imageZoom)
            ? (_openBlock(), _createBlock(_component_SimpleImage, {
                key: 0,
                src: $options.imageZoom,
                class: "w-15 h-15",
                "img-class": "h-full w-full border border-black-50 object-contain object-center cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.selectCurrentImage($options.imageZoom)))
              }, null, 8, ["src"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("span", _hoisted_14, _toDisplayString($props.footNote), 1)
    ])
  ]))
}