<template>
    <div :class="{'pb-20 md:pb-22': hasMore}">
        <table class="min-w-full text-para-xs md:text-para-s">
            <thead v-if="opts.showHeaders" class="border-b border-black-200">
            <tr>
                <template v-for="(column, i) in opts.columns" :key="i">
                    <th v-if="column.key === 'check'"
                        scope="col"
                        class="w-10 p-2"
                    />
                    <th v-else-if="column.key === 'download'"
                        scope="col"
                        class="w-10 py-2 pl-1 pr-2 xl:pr-4"
                    />
                    <template v-else-if="column.key === 'tagCategories'">
                        <th v-for="(cat, index) in column.categories"
                            :key="index"
                            scope="col"
                            class="whitespace-nowrap text-left p-2"
                        >
                            <span>{{ cat }}</span>
                        </th>
                    </template>
                    <th v-else
                        scope="col"
                        :class="'whitespace-nowrap py-2 ' + (i === 0 ? 'pl-1 xl:pl-3 pr-0' : i === opts.columns.length - 1 ? 'pl-1 pr-2 xl:pr-4' : 'pl-1 pr-2')"
                        @click="sortBy(column.key !== 'format' ? column.key : 'size')"
                    >
                        <div class="flex items-center gap-1" :class="tableHeaderStyle(column.key)">
                            <Icon v-if="sortingEnabled"
                                  name="arrow-right"
                                  class="w-4 h-4 transform origin-center stroke-current"
                                  :class="getIconClasses(column.key !== 'format' ? column.key : 'size')"/>
                            <div v-if="!sortingEnabled" class="w-0" />
                            <!-- small padding correction if the sorting arrow is not displayed -->
                            <span>{{ column.label }}</span>
                        </div>
                    </th>
                </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(file, i) in sorted"
                :key="`${$.uid}_file#${i}`"
                :data-title="file.link.split('/').pop()"
                :aria-label="file.link.split('/').pop()"
                class="even:bg-black-50 hover:bg-primary-100 focus-within:bg-primary-100 transition duration-100 ease-out group cursor-pointer"
                @click="rowClick(file.id, $event)"
            >
                <template v-if="(i < (currentPage * opts.batchSize)) || (opts.batchSize <= 0)">
                    <template v-for="(column, i) in opts.columns">
                        <td v-if="column.key === 'check'">
                            <!-- Checkbox at start of table (only visible when multiDownloadsActive=true) -->
                            <div
                                class="relative ml-4 pr-2 text-black-200 group-hover:text-primary-500 group-focus:text-primary-500"
                                :class="{'text-primary-500': isSelected(file.id)}"
                                @keyup.enter="multiDownloadsActive ? toggleSelectedForDownload(file.id) : null"
                            >
                                <Icon :name="isSelected(file.id) ? 'checkbox-active' : 'checkbox'"
                                      :tabindex="multiDownloadsActive ? 0 : -1"
                                      class="w-3 h-3 stroke-current"
                                />
                            </div>
                        </td>
                        <td v-else-if="column.key === 'format' && (opts.showIcon || opts.showSize)"
                            :key="`${$.uid}_${column.key}_${i}`"
                        >
                            <!-- Column with file type and size (clickable when multiDownloadsActive=false) -->
                            <div v-if="multiDownloadsActive"
                                 class="flex items-center whitespace-nowrap md:whitespace-normal py-0 pl-0 pr-2 xl:pr-4"
                                 :class="{'md:pl-3': !opts.showIcon}"
                            >
                                <div v-if="opts.showIcon" class="flex justify-center items-center">
                                    <Icon :name="file.link ? fileType(file.link) : 'file'" class="w-10 h-10" />
                                </div>
                                <span v-if="opts.showSize"
                                      class="hidden md:block uppercase text-black-600 whitespace-nowrap"
                                >
                                    {{ file.size }}
                                </span>
                            </div>
                            <a v-else
                               class="flex items-center whitespace-nowrap md:whitespace-normal py-0 pl-0 pr-2 xl:pr-4"
                               :class="{'md:pl-3': !opts.showIcon}"
                               :href="file.link"
                               :aria-label="file.title"
                               :target="determineTarget(file.link)"
                            >
                                <div v-if="opts.showIcon" class="flex justify-center items-center">
                                    <Icon :name="file.link ? fileType(file.link) : 'file'" class="w-10 h-10" />
                                </div>
                                <span v-if="opts.showSize"
                                      class="hidden md:block uppercase text-black-600 whitespace-nowrap"
                                >
                                    {{ file.size }}
                                </span>
                            </a>
                        </td>
                        <template v-else-if="column.key === 'tagCategories'">
                            <!-- Tag categories column(s) -->
                            <td v-for="(cat, index) in column.categories" :key="index"
                                class="whitespace-nowrap md:whitespace-normal p-2"
                            >
                                {{ getTagsForCategory(file, cat) || '-' }}
                            </td>
                        </template>
                        <td v-else-if="column.key === 'download'">
                            <!-- Download link at end of table (only visible when multiDownloadsActive=true) -->
                            <a :href="file.link"
                               class="block pr-2 py-3"
                               :aria-label="file.title"
                               :target="determineTarget(file.link)"
                               tabindex="-1"
                            >
                                <Icon name="download" class="w-4 h-4" tabindex="0" />
                            </a>
                        </td>
                        <td v-else :key="`${$.uid}_${column.key}_${i}`">
                            <!-- Any other column -->
                            <div class="block whitespace-nowrap md:whitespace-normal py-2"
                                 :class="i === 0 ? 'pl-2 xl:pl-4 pr-2 md:pr-0' : 'px-2'"
                                 :aria-label="multiDownloadsActive ? null : file.title"
                            >
                                <template v-if="column.key === 'date'">
                                    {{ $filters.formatDate(file.date) }}
                                </template>
                                <template v-else>
                                    {{ file[column.key] }}
                                </template>
                                <template v-if="opts.dateAfterText && column.key === 'title'">
                                    - {{ $filters.formatDate(file.date) }}
                                </template>
                            </div>
                        </td>
                    </template>
                </template>
            </tr>
            </tbody>
        </table>
        <!-- pagination -->
        <div v-if="hasMore" class="absolute left-0 right-0 bottom-4 flex justify-center">
            <GcButton class="font-semibold" :label="options.lblLoadMore" secondary @click="loadMore" />
        </div>
        <!-- Multi download sticky -->
        <div v-if="downloads.length > 1" class="fixed left-0 bottom-0 w-full bg-white shadow-top-sm z-9000">
            <div class="container">
                <div class="flex flex-col md:flex-row justify-between items-center gap-4 w-full xl:w-2/3 mx-auto py-4">
                    <span class="font-semibold">{{ downloads.length }} {{ opts.lblSelected }}</span>
                    <GcButton icon="download_" :label="opts.lblDownload" @click="downloadSelectedDocuments" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import {PropType} from "vue";
import Icon from "../base/Icon.vue";
import GcButton from "../base/GcButton.vue";

const ALLOWED_TYPES = ['ai', 'doc', 'docx', 'eps', 'file', 'indd', 'jpg', 'jpeg', 'pdf', 'png', 'ps', 'psd', 'svg', 'xls', 'xlsx', 'xml', 'zip'];

let uuid = 0;

export default {
    components: {GcButton, Icon},
    props: {
        options: {type: Object as PropType<Record<string, any>>, required: true},
        items: {type: Array as PropType<Array<Record<string, any>>>, required: true},
        sortingEnabled: {type: Boolean, default: false}
    },
    data() {
        return {
            currentPage: 1,

            apiUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.LOCATION + Enums.API.DOCUMENTS,

            downloads: [],

            defaultOptions: {
                columns: [],
                order: 'ascending',
                orderBy: 'name',
                showHeaders: true,
                showSize: true,
                showIcon: true,
                dateAfterText: false
            },

            ascending: true,
            sortProperty: ''
        };
    },
    computed: {
        multiDownloadsActive(): boolean {
            return this.opts.columns.findIndex(c => c.key === 'check') >= 0;
        },
        downloadsUrl(): string {
            const params = new URLSearchParams();
            this.downloads.forEach(k => params.append('key', k));
            return this.apiUrl + '?' + params.toString();
        },
        opts(): Record<string, any> {
            return {...this.defaultOptions, ...this.options};
        },
        hasMore(): boolean {
            return (this.opts.batchSize > 0) && (this.items.length > (this.currentPage * this.opts.batchSize));
        },
        sorted(): Record<string, any>[] {
            if (this.sortingEnabled && this.sortProperty) {
                const sorted: Record<string, any>[] = [...this.items].sort((row1, row2) => {
                    if (row1[this.sortProperty] && row2[this.sortProperty]) {
                        if (!isNaN(Number(row1[this.sortProperty])) && !isNaN(Number(row2[this.sortProperty]))) {
                            return Number(row1[this.sortProperty]) - Number(row2[this.sortProperty]);
                        }
                        return row1[this.sortProperty].localeCompare(row2[this.sortProperty]);
                    }
                    return 0;
                });
                if (!this.ascending) {
                    sorted.reverse();
                }
                return sorted;
            }
            return this.items;
        }
    },
    watch: {
        async items() {
            this.currentPage = 1;
        }
    },
    beforeCreate() {
        this.uuid = uuid.toString();
        uuid += 1;
    },
    methods: {
        determineTarget(link: string): string {
            return link && link.indexOf('download=true') > 0 ? '_self' : '_blank';
        },
        getTagsForCategory(file: Record<string, any>, category: string): string {
            const tags = (file.tags || []).filter(t => this.options.categories[category].indexOf(t) >= 0).sort();
            return tags.join(', ');
        },
        loadMore(): void {
            this.currentPage++;
        },
        rowClick(id: any, ev: MouseEvent): void {
            if (this.multiDownloadsActive) {
                this.toggleSelectedForDownload(id);
            } else {
                // block the original event as it might be doing the exact same thing as we're going to do next
                ev.preventDefault();

                if (ev.ctrlKey || ev.metaKey) {
                    return; // blocked
                }

                const el: HTMLElement = ev.currentTarget as HTMLElement;

                if (el instanceof HTMLTableRowElement) {
                    const anchors: HTMLCollectionOf<HTMLAnchorElement> = el.getElementsByTagName('a');

                    if (anchors && anchors.length > 0) {
                        const a = anchors[0] as HTMLAnchorElement;
                        window.open(a.href, '_blank');
                    }
                }
            }
        },
        tableHeaderStyle(column): string {
            if (column === 'title') {
                if (this.opts.columns.find(c => c.key === 'date')) {
                    return 'font-semibold w-80 md:w-7/12 lg:w-8/12 text-left min-w-80';
                }
                return 'font-semibold w-80 md:w-9/12 lg:w-10/12 text-left min-w-80';
            }
            if (column === 'date') {
                return 'font-semibold w-24';
            }
            if (column === 'format') {
                return 'font-semibold pl-1';
            }
            return '';
        },
        downloadSelectedDocuments(): void {
            if (this.downloads.length > 0) {
                window.open(this.downloadsUrl, '_blank').focus();
                this.trackDownloads();
                this.downloads = [];
            }
        },
        toggleSelectedForDownload(id: string): void {
            if (this.multiDownloadsActive) {
                // either delete or add id to downloads list
                const index = this.downloads.indexOf(id);
                if (index >= 0) {
                    this.downloads.splice(index, 1);
                } else {
                    this.downloads.push(id);
                }
            }
        },
        isSelected(id: string): boolean {
            return this.downloads.indexOf(id) >= 0;
        },
        fileType(link: string): string {
            return this.getFileType(link);
        },
        trackDownloads(): void {
            if (this.$gtm && this.$gtm.enabled()) {
                window.dataLayer.push({
                    event: 'multi_file_download',
                    files: this.downloads.map(dl => this.items.find(i => i.id === dl)).filter(dl => !!dl).map(dl => dl.fileName)
                });
            }
        },
        getFileType(link: string): string {
            // strip parameters
            const stripped = link.lastIndexOf('?') > 0 ? link.substring(0, link.lastIndexOf('?')) : link;
            // check for allowed file types or otherwise unsupported types will cause no icon to display at all
            let ending = stripped.split('.').length > 0 ? stripped.split('.').pop().toLowerCase() : 'file';
            if (ALLOWED_TYPES.indexOf(ending.toLowerCase()) < 0) {
                ending = 'file';
            }
            return ending;
        },
        sortBy(property: string): void {
            if (!this.sortingEnabled) {
                return;
            }
            if (property === this.sortProperty) {
                this.ascending = !this.ascending;
            } else {
                this.sortProperty = property;
                this.ascending = true;
            }
        },
        getIconClasses(property: string): Record<string, boolean> {
            return {
                'rotate-90': this.sortProperty === property && !this.ascending,
                '-rotate-90': (this.sortProperty === property && this.ascending) || this.sortProperty !== property,
                'text-primary-500': this.sortProperty === property,
                'text-black-400': this.sortProperty !== property
            };
        }
    }
};
</script>
