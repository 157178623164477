<template>
    <Field v-slot="{ field, errors, value }"
           v-model="model[name]"
           :name="title"
           :rules="rules"
           mode="eager"
           :disabled="validationDisabled"
           as="div"
    >
        <div class="text-para-s font-semibold">
            <span>{{ title }}</span>
            <span v-if="required" aria-hidden="true"> *</span>
            <span v-if="suffix" class="text-black-500 font-normal"> {{ suffix }}</span>
        </div>
        <div class="relative text-black-500">
            <input v-if="type !== 'number'"
                   class="w-full text-para-s border rounded focus:outline-none py-2.5 pr-4"
                   :class="{
                       'pl-4': type !== 'email',
                       'pl-10': type === 'email',
                       'border-black-200 focus:border-primary-500': errors.length === 0,
                       'border-error': errors.length > 0
                   }"
                   type="text"
                   :name="name"
                   :aria-required="required"
                   :aria-invalid="errors.length > 0"
                   :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
                   v-bind="field"
            >
            <NumberInput v-else
                         v-bind="field"
                         v-model="model[name]"
                         class="w-full text-para-s border rounded focus:outline-none py-2.5 pr-4"
                         :class="{
                             'pl-4': type !== 'email',
                             'pl-10': type === 'email',
                             'border-black-200 focus:border-primary-500': errors.length === 0,
                             'border-error': errors.length > 0
                         }"
                         :name="name"
                         :aria-required="required"
                         :aria-invalid="errors.length > 0"
                         :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
                         :options="options"
            />
            <Icon v-if="type === 'email'" name="mail" class="absolute stroke-current w-4 h-10 md:h-11 left-4 top-px" />
        </div>
        <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-1 md:mt-2">
            {{ errors[0] }}
        </span>
    </Field>
</template>

<script lang="ts">
import {CurrencyInputOptions} from 'vue-currency-input';
import {formattingOptions} from './MultiStepForm.vue';
import NumberInput from "../../base/NumberInput.vue";
import Icon from "../../base/Icon.vue";
import {Field} from "vee-validate";

export default {
    components: {Field, Icon, NumberInput},
    inject: ['model', 'currentStep'],
    props: {
        name: {type: String},
        title: {type: String},
        suffix: {type: String},
        type: {type: String, default: 'text', validator(value: string) {
            // The value must match one of these strings
            return ['text', 'number', 'email'].includes(value);
        }},
        required: {type: Boolean, default: false},
        stepIndex: {type: [Number, String], required: true},
        default: {type: String}
    },
    computed: {
        validationDisabled(): boolean {
            return this.currentStep !== this.stepIndex;
        },
        options(): CurrencyInputOptions {
            return formattingOptions;
        },
        rules(): Record<string, unknown> {
            /* eslint-disable */
            return {
                required: this.required,
                is_number: this.type === 'number',
                email: this.type === 'email'
            };
            /* eslint-enable */
        }
    },
    created(): void {
        if (!this.model[this.name] && this.default) {
            this.model[this.name] = this.default;
        }
    }
};
</script>
