import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative flex flex-col overflow-hidden" }
const _hoisted_2 = ["placeholder", "readonly", "tabindex"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative flex justify-between items-center text-para-s border transition-colors cursor-pointer border-black-100 hover:text-black-900 rounded-t", {'rounded-b': !$data.open}])
    }, [
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.keyword) = $event)),
        class: "w-full disabled:bg-white pl-4 pr-12 py-2 xl:py-2.5 placeholder-improvedContrast",
        type: "text",
        placeholder: $props.placeholder,
        readonly: $props.readonly,
        tabindex: $props.tabIndex,
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.handleSearch && $options.handleSearch(...args))),
        onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($options.handleSearch && $options.handleSearch(...args)), ["enter"]))
      }, null, 40, _hoisted_2), [
        [_vModelText, $data.keyword]
      ]),
      _createVNode(_component_Icon, {
        name: "search",
        class: _normalizeClass(["absolute top-1/2 transform -translate-y-1/2 right-4 w-4 h-4 stroke-current", {'text-black-200 ': !$data.open, 'text-black-500': $data.open}])
      }, null, 8, ["class"])
    ], 2),
    _createVNode(_component_CollapseTransition, null, {
      default: _withCtx(() => [
        ($data.open && $options.hasResults)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["overflow-y-auto w-full bg-white border-r border-b border-l border-black-100 rounded-b z-10", {'absolute top-full max-h-72': $props.absolute}])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredEntries, (label, uuid) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: uuid,
                  class: "text-black-600 hover:text-white hover:bg-primary-500 text-para-s px-4 py-2 xl:py-2.5",
                  onClick: ($event: any) => ($options.handleClick(uuid))
                }, _toDisplayString(label), 9, _hoisted_3))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}