<template>
    <div v-if="accounts.length > 1" :class="{'container': !inContentGroup}">
        <div class="flex flex-row items-center justify-center md:justify-end pt-2.5 pb-4 md:py-0">
            <p class="font-semibold text-para-xs mr-4">
                {{ labelSocialFilter }}
            </p>
            <Dropdown v-model="model"
                      class="min-w-50 -my-px"
                      name="year"
                      :title="model"
                      :rounded="mobile"
                      :default-value="modelValue"
            >
                <DropdownItem v-for="(account, index) in accounts"
                              :key="index"
                              :label="account.label"
                              :value="account.value"
                />
            </Dropdown>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {SocialReferenceItem} from './SocialSection.vue';
import breakpoints from '../../plugins/breakpoints';
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";

export default {
    components: {DropdownItem, Dropdown},
    props: {
        accounts: {type: Array as PropType<Array<SocialReferenceItem>>, default: () => []},
        inContentGroup: {type: Boolean, default: false},
        labelSocialFilter: {type: String},
        modelValue: {type: String}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            model: ''
        };
    },
    computed: {
        mobile(): boolean {
            return breakpoints.mobile;
        }
    },
    watch: {
        async model() {
            this.$emit('update:modelValue', this.model);
        },
        async accounts() {
            this.model = this.accounts[0].value;
        }
    },
    mounted(): void {
        this.model = this.accounts[0].value;
    }
};
</script>
