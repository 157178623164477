<template>
    <Field v-slot="{ field, errors, meta }"
           ref="field"
           v-model="model"
           :name="title"
           :rules="rules"
           :disabled="disabled"
           as="div"
    >
        <label v-if="!hideTitle && (type === 'select' || type === 'combobox')" :id="`${name}-label`" :for="labelFor">
            <span class="font-semibold text-para-xs md:text-para-s">
                {{ title }}
                <dfn v-if="required" aria-hidden="true" title="required">{{ requiredSymbol }}</dfn>
            </span>
        </label>

        <div :class="{'mt-2': !title}">
            <div v-if="type === 'select'" class="flex flex-col">
                <Dropdown v-bind="field"
                          v-model="model"
                          :name="name"
                          :title="placeholder"
                          :required="required"
                          :error="errors.length > 0"
                          :described-by="getDescribedById(errors)"
                          :passed="meta.validated && meta.valid"
                          text-classes="text-para-xs md:text-para-s h-4 md:h-6"
                          :searchable="searchable"
                          higher-menu
                          :default-value="defaultValue"
                          :disabled="disabled"
                >
                    <DropdownItem v-for="(label, iValue, index) in options"
                                  :key="index"
                                  text-classes="text-para-xs md:text-para-s"
                                  :value="iValue"
                                  :label="label"
                    />
                </Dropdown>
                <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-1 md:mt-2">
                    {{ errors[0] }}
                </span>
                <span v-else-if="description" :id="`${name}-description`" class="text-para-xs mt-1 md:mt-2">
                    {{ description }}
                </span>
            </div>
            <div v-else-if="type === 'combobox'" class="flex flex-col">
                <Combobox v-bind="field"
                          v-model="model"
                          :name="name"
                          :items="options"
                          :placeholder="placeholder"
                          :searchable="searchable"
                          :pill-items="pillItems"
                />
                <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-1 md:mt-2">
                    {{ errors[0] }}
                </span>
                <span v-else-if="description" :id="`${name}-description`" class="text-para-xs mt-1 md:mt-2">
                    {{ description }}
                </span>
            </div>
            <fieldset v-else :role="type === 'radio' ? 'radiogroup' : ''" class="flex flex-col gap-y-2 gap-x-4 mt-2" :class="{'md:flex-row flex-wrap': horizontal}">
                <legend v-if="!hideTitle" class="mb-2">
                    <span class="font-semibold text-para-xs md:text-para-s">
                        {{ title }}
                        <dfn v-if="required" aria-hidden="true" title="required">{{ requiredSymbol }}</dfn>
                    </span>
                </legend>
                <div v-for="(label, iValue, index) in options" :key="index" class="flex flex-row items-center">
                    <input v-bind="field"
                           :id="isSingle? name : `${name}-${index}`"
                           :name="isSingle? name : `${name}-${index}`"
                           class="absolute opacity-0 text-black-900 text-para-xs md:text-para-s"
                           :type="type"
                           :value="iValue"
                           :aria-invalid="errors.length > 0"
                           :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
                    >
                    <label :for="isSingle? name : `${name}-${index}`" class="relative" :class="type + '-label'">
                        <span class="text-para-xs pl-5 md:pl-6" v-html="label" />
                    </label>
                </div>
                <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-1 md:mt-2">
                    {{ errors[0] }}
                </span>
            </fieldset>
        </div>
    </Field>
</template>

<script lang="ts">
import Combobox from "../base/Combobox.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import {Field} from "vee-validate";
import {PropType} from "vue";

export default {
    components: {Field, DropdownItem, Dropdown, Combobox},
    props: {
        name: {type: String},
        title: {type: String},
        description: {type: String},
        defaultValue: {type: String},
        placeholder: {type: String},
        type: {type: String, default: 'text'},
        required: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        horizontal: {type: Boolean, default: false},
        searchable: {type: Boolean, default: false},
        hideTitle: {type: Boolean, default: false},
        pillItems: {type: Boolean, default: false},
        requiredSymbol: {type: String, default: '*'},
        labels: {type: Array as PropType<Array<string>>},
        modelValue: {type: [String, Number, Boolean, Array as PropType<Array<string>>], default: ''}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            model: '',
            labelFor: this.type=='select' ? `${this.name}-dropdown` : this.name,
        };
    },
    computed: {
        rules(): Record<string, unknown> {
            return {
                required: this.required
            };
        },
        options(): Record<string, string> {
            const result = {};
            if (this.labels) {
                this.labels.forEach((s: string) => {
                    const keyValue = s.split(':');
                    if (keyValue.length === 2) {
                        result[keyValue[1]] = keyValue[0];
                    }
                });
            }
            return result;
        },
        isSingle(): boolean {
            return Object.getOwnPropertyNames(this.options).length == 1;
        }
    },
    watch: {
        async model() {
            this.$emit('update:modelValue', this.model);
        }
    },
    mounted(): void {
        if (this.type === 'checkbox') {
            // checkbox always needs array
            const tmpModel = [];
            if (this.defaultValue) {
                const split: Array<string> = this.defaultValue.split('__');
                split.forEach(s => tmpModel.push(s));
            }
            this.model = tmpModel;
        } else if (typeof this.modelValue !== 'undefined') {
            // enables using a boolean as v-model
            if (typeof this.modelValue === 'boolean') {
                this.model = String(this.modelValue);
            } else {
                this.model = this.modelValue;
            }
        } else if (this.defaultValue) {
            this.model = this.defaultValue;
        }
    },
    methods: {
        getDescribedById(errors: string[]): string {
            if (errors.length > 0) {
                return `${this.name}-error`;
            } else if (this.description) {
                return `${this.name}-description`;
            }
            return '';
        }
    }
};
</script>
