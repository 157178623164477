<template>
    <div>
        <TabHeader v-model="tabModel"
                   :tabs="tabs"
                   class="mb-6"
                   :in-content-group="inContentGroup"
                   narrow
                   :component-uid="$.uid"
        />
        <div :class="{'container': !inContentGroup}">
            <div class="flex flex-col w-full mx-auto" :class="{'xl:w-2/3': !inContentGroup}">
                <template v-if="tabModel === 0">
                    <TableView :labels="labels" :scale="scale" :data="data" :hide-traffic-light="hideTrafficLight" />
                </template>
                <template v-if="tabModel === 1">
                    <GraphView :labels="labels" :data="data" :max-value="maxValue" />
                </template>
                <ul v-if="windData.length > 0"
                    class="flex justify-center gap-x-2 md:gap-x-20 mt-8 md:mt-12 bg-primary-50 p-4 md:p-12 -mx-4 md:mx-0"
                >
                    <li v-for="(item, index) in windData" :key="index">
                        <WindChart :label="item.label" :speed="item.speed" :direction="item.direction" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import TabHeader from "../base/TabHeader.vue";
import TableView from "./TableView.vue";
import GraphView from "./GraphView.vue";
import WindChart from "./WindChart.vue";

export interface MonitoringLabels {
    col1: string;
    col2: string;
    col3: string;
    col4: string;
    min5Value: string;
    min5Time: string;
    hourlyValue: string;
    hourlyTime: string;
    tableView: string;
    graphView: string;
    dataCollected: string;
    legend: string;
}

export interface Scale {
    label: string;
    min: number;
    max?: number;
    color: string;
}

export interface SnapshotValue {
    station: string;
    value: string;
    time: string;
    interval: string;
    datatype: string;
}

export interface DataPoint {
    label: string;
    value5?: number;
    value5Time?: string;
    value60: number;
    value60Time?: string;
}

export interface WindDataPoint {
    label: string;
    direction: number;
    speed: number;
}

export default {
    components: {WindChart, GraphView, TableView, TabHeader},
    props: {
        labels: {type: Object as PropType<MonitoringLabels>, required: true},
        scale: {type: Array as PropType<Array<Scale>>, required: true},
        maxValue: {type: Number, default: 1000},
        hideTrafficLight: {type: Boolean, default: false},
        inContentGroup: {type: Boolean, default: false}
    },
    data() {
        return {
            apiUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.SO2,

            data: [],
            windData: [],
            tabModel: 0
        };
    },
    computed: {
        tabs(): string[] {
            return [
                this.labels.tableView,
                this.labels.graphView
            ];
        }
    },
    created(): void {
        this.getSnapshotData();
    },
    methods: {
        getSnapshotData(): void {
            const url: string = `${this.apiUrl}/snapshot/sudbury/`;
            axios.get(url)
                .then(res => {
                    const data: SnapshotValue[] = res.data;
                    // get unique stations
                    const so2Stations = data.filter(d => d.datatype === 'SO2').map(s => s.station).filter((s, i, a) => a.indexOf(s) === i);
                    const windStations = data.filter(d => d.datatype === 'Wind Speed').map(s => s.station).filter((s, i, a) => a.indexOf(s) === i);
                    // create data entries
                    so2Stations.forEach(so2 => {
                        const value60 = data.find(d => d.station === so2 && d.interval === 'Hourly');
                        const value5 = data.find(d => d.station === so2 && d.interval !== 'Hourly');
                        this.data.push({
                            label: so2,
                            value5: parseFloat(value5?.value || '0'),
                            value5Time: value5?.time || '-',
                            value60: parseFloat(value60?.value || '0'),
                            value60Time: value60?.time || '-'
                        });
                    });
                    windStations.forEach(w => {
                        const dir = data.find(d => d.station === w && d.datatype === 'Wind Dir')?.value || '0';
                        const speed = data.find(d => d.station === w && d.datatype === 'Wind Speed')?.value || '0';
                        this.windData.push({
                            label: w,
                            direction: parseFloat(dir),
                            speed: parseFloat(speed)
                        });
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        },
        getDate(): string {
            const date = new Date();
            // convert numbers to double digits
            const dd = num => (num < 10 ? `0${num}` : `${num}`);
            return `${dd(date.getDate())}/${dd(date.getMonth() + 1)}/${dd(date.getFullYear())} ${dd(date.getHours())}:${dd(date.getMinutes())}`;
        }
    }
};
</script>
