import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($data.show && $props.isTab)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClick && $options.onClick(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default", { active: $data.active })
      ]))
    : ($data.show && !$props.isTab)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", { active: $data.active })
        ]))
      : _createCommentVNode("", true)
}