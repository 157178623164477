<template>
    <a :title="title"
       :href="displayUrl"
       :aria-label="ariaLabel"
       class="cursor-pointer"
       :class="classes"
       :target="target"
       tabindex="0"
       @click.stop="click"
       @keyup.enter="click"
    >
        <slot />
        <Overlay
            v-if="type === 'overlay'"
            :url="targetUrl"
            @close="targetUrl=''"
        />
        <VideoModal
            v-else-if="type === 'video'"
            :video-id="targetUrl"
            :account-id="accountId"
            :player-id="playerId"
            @close="targetUrl=''"
        />
    </a>
</template>
<script lang="ts">

import VideoModal from "../video/VideoModal.vue";
import Overlay from "./Overlay.vue";

export default {
    components: {Overlay, VideoModal},
    props: {
        type: {type: String},
        target: {type: String, default: '_self'},
        ariaLabel: {type: String},
        title: {type: String},
        url: {type: String},
        classes: {type: String},
        accountId: {type: String},
        playerId: {type: String},
        videoId: {type: String}
    },
    data() {
        return {
            targetUrl: ''
        };
    },
    computed: {
        displayUrl(): string {
            // this should prevent from links being rendered on types where it's not needed
            if (this.type !== 'overlay' && this.type !== 'video') {
                return this.url;
            }
            return undefined;
        }
    },
    methods: {
        click(event) {
            if (this.type === 'overlay') {
                event.preventDefault();
                this.targetUrl = this.url;
            } else if (this.type === 'video') {
                event.preventDefault();
                this.targetUrl = this.videoId;
            }
        }
    }
};
</script>
