import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col pt-7 px-5 md:w-2/3 md:ml-auto md:flex-row md:flex-wrap md:justify-start md:items-center xl:w-3/4" }
const _hoisted_2 = { class: "text-para-xs uppercase md:w-full md:pb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.operations, (operation, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "w-full relative flex flex-row items-center justify-start mt-2 md:w-auto md:mr-6 md:mt-3"
      }, [
        _createElementVNode("div", {
          class: "w-1 h-full absolute top-0 left-0",
          style: _normalizeStyle({ 'backgroundColor': $options.cssColor(operation.color) })
        }, null, 4),
        _createElementVNode("div", {
          class: "ml-2 py-1 px-3 rounded-xl text-para-xs font-semibold",
          style: _normalizeStyle({ 'backgroundColor': $options.cssColor($options.lightenColor(operation.color)) })
        }, _toDisplayString(operation.title || operation.name || ""), 5)
      ]))
    }), 128))
  ]))
}