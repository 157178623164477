import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["srcset", "sizes", "src", "alt"]
const _hoisted_2 = { class: "pt-4 xl:pt-6 uppercase text-para-xs" }
const _hoisted_3 = { class: "pt-2" }
const _hoisted_4 = { class: "font-semibold text-para-s" }
const _hoisted_5 = { class: "h-37 w-full pl-6 pr-12 pt-8 border-b border-solid border-black-100" }
const _hoisted_6 = { class: "uppercase text-para-xs" }
const _hoisted_7 = { class: "mt-2 h-18 line-clamp-3" }
const _hoisted_8 = { class: "font-semibold text-para-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_TeaserLink = _resolveComponent("TeaserLink")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["grid grid-cols-1 md:gap-4 xl:gap-8", [$props.showImages ? 'gap-4' : 'gap-2', $props.articles.length > 2 ? 'md:grid-cols-3' : 'md:grid-cols-2']])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.articles, (article, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        ($props.showImages)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: _normalizeClass(["w-full", { 'bg-white': index === 0,
                          'justify-self-start': $props.articles.length === 1,
                          'md:row-span-3 md:col-span-3 xl:row-span-2 xl:col-span-2': index === 0 && $props.articles.length > 1}])
            }, [
              (article.asset)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    srcset: article.asset.sourceSet,
                    sizes: article.asset.sizes,
                    src: article.asset.source,
                    alt: article.asset.title,
                    class: "w-full object-cover object-center"
                  }, null, 8, _hoisted_1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass({ 'p-6': index === 0 })
              }, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(article.date), 1),
                _createElementVNode("div", _hoisted_3, _toDisplayString(article.title), 1),
                _createVNode(_component_TeaserLink, {
                  url: article.link,
                  type: article.type,
                  title: article.title,
                  "aria-label": article.title,
                  classes: "h-8 md:h-10 w-full flex items-center group mt-4 mb-8 md:mb-0"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, _toDisplayString($props.labels.readMore), 1),
                    _createVNode(_component_ArrowButton, {
                      direction: $options.getIcon(article.type),
                      class: "w-10 h-10"
                    }, null, 8, ["direction"])
                  ]),
                  _: 2
                }, 1032, ["url", "type", "title", "aria-label"])
              ], 2)
            ], 2))
          : (_openBlock(), _createElementBlock("li", {
              key: 1,
              class: _normalizeClass([$props.highlight ? 'bg-white' : 'bg-black-50'])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(article.date), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(article.title), 1)
              ]),
              _createVNode(_component_TeaserLink, {
                url: article.link,
                type: article.type,
                title: article.title,
                "aria-label": article.title,
                classes: "h-15 w-full flex items-center group pl-6 pr-12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_8, _toDisplayString($props.labels.readMore), 1),
                  _createVNode(_component_ArrowButton, {
                    direction: $options.getIcon(article.type),
                    class: "w-10 h-10"
                  }, null, 8, ["direction"])
                ]),
                _: 2
              }, 1032, ["url", "type", "title", "aria-label"])
            ], 2))
      ], 64))
    }), 128))
  ], 2))
}