import {defineStore} from 'pinia';

export interface State {
    navigationOpened: boolean;
    globalNavOpened: boolean;
    searchOpened: boolean;
    infoBannerOpened: boolean;
    adaptiveLanguageSwitcherOpened: boolean;
    activatedTab: string;
}

export const useNavigationStore = defineStore('navigationStore', {
    state: (): State => ({
        navigationOpened: false,
        globalNavOpened: false,
        searchOpened: false,
        infoBannerOpened: false,
        adaptiveLanguageSwitcherOpened: false,
        activatedTab: ''
    }),
    getters: {
        bodyClasses(state): number {
            if (state.navigationOpened) {
                document.body.classList.add('overflow-hidden', 'md:overflow-auto');
                return 1;
            } else if (state.globalNavOpened || state.searchOpened) {
                document.body.classList.add('overflow-hidden');
                document.body.classList.remove('md:overflow-auto');
                return 2;
            } else {
                document.body.classList.remove('overflow-hidden', 'md:overflow-auto');
                return 3;
            }
        },
        additionalTopMarginContent(state): object {
            // Note: if the values change, make sure all possible results are configured in the 'spacing' settings of the tailwind config
            return ({prefix, sm, md, xl}: { prefix: string; sm: number; md: number; xl: number }): string => {
                if (state.adaptiveLanguageSwitcherOpened) {
                    sm += 14;
                    md += 14;
                }
                if (state.infoBannerOpened) {
                    sm += 38;
                    md += 35;
                    xl += 35;
                }
                return `${prefix}-${sm} md:${prefix}-${md} xl:${prefix}-${xl}`;
            };
        },
        additionalTopMarginHeader(state): object {
            // Note: if the values change, make sure all possible results are configured in the 'spacing' settings of the tailwind config
            return ({prefix, sm, md, xl}: { prefix: string; sm: number; md: number; xl: number }): string => {
                if (state.infoBannerOpened) {
                    sm += 38;
                    md += 35;
                    xl += 35;
                }
                return `${prefix}-${sm} md:${prefix}-${md} xl:${prefix}-${xl}`;
            };
        }
    },
    actions: {
    }
});
