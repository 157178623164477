<template>
    <div class="relative">
        <div v-if="title || description" :class="`${!inContentGroup ? 'container' : ''} pb-0 mb-4 md:mb-6 pt-6`">
            <div :class="`${!inContentGroup ? 'xl:w-8/12' : 'xl:w-full'} mx-auto  prose md:prose-md xl:prose-xl`">
                <h2 v-if="title" class="abstract inline-block text-heading-3 lg:text-heading-2 mb-6 lg:mb-8">
                    {{ title }}
                </h2>
                <div v-if="description" class="text-para-s md:text-para-base prose-caption -mt-2 xl:-mt-4 mb-6 xl:mb-8" v-html="description" />
            </div>
        </div>
        <!-- tab grouping -->
        <template v-if="grouping === 'tabs'">
            <ContentGroup
                v-slot="{ index, type, showAll, itemsLength, componentUid }"
                type="tabs"
                :default-index="0"
                :scrollable-content="true"
                :in-content-group="inContentGroup"
            >
                <ContentGroupItem
                    v-for="(year, i) in years.slice(0, maxColumns)"
                    :key="year"
                    :title="`${year}`"
                    :index="i"
                    :current-index="index"
                    :component-uid="componentUid"
                >
                    <div :class="{'container': !inContentGroup}">
                        <div :class="{'xl:w-8/12 mx-auto': !inContentGroup}">
                            <DocumentsTable class="-mt-1 md:mt-6 xl:mt-8 mb-3 md:mb-4 xl:mb-6"
                                            :items="groups.find(g => g.year === year).items"
                                            :options="tableOptions"
                                            :sorting-enabled="sortingEnabled"
                            />
                        </div>
                    </div>
                </ContentGroupItem>
                <ContentGroupItem
                    v-if="archive.length"
                    :key="'archive'"
                    :title="labelArchive"
                    :index="years.slice(0, maxColumns).length"
                    :current-index="index"
                    :component-uid="componentUid"
                >
                    <ContentGroup
                        v-slot="{ index, type, showAll, itemsLength, componentUid:componentUidInner }"
                        class="-mt-1 md:mt-6 xl:mt-8"
                        type="accordion"
                        :in-content-group="inContentGroup"
                    >
                        <ContentGroupItem
                            v-for="(group, i) in archive"
                            :key="i"
                            :title="`${group.year}`"
                            :index="i"
                            :component-uid="componentUidInner"
                        >
                            <DocumentsTable class="mt-2 md:mt-4 mb-1 md:-mb-6 xl:-mb-2"
                                            :items="group.items"
                                            :options="tableOptions"
                            />
                        </ContentGroupItem>
                    </ContentGroup>
                </ContentGroupItem>
            </ContentGroup>
        </template>
        <!-- accordion grouping -->
        <div v-else-if="grouping === 'accordions'">
            <ContentGroup
                v-slot="{ index, type, showAll, itemsLength, componentUid }"
                type="accordion"
                :in-content-group="inContentGroup"
            >
                <ContentGroupItem
                    v-for="(group, i) in groups.concat(archive)"
                    :key="i"
                    :title="`${group.year}`"
                    :index="i"
                    :component-uid="componentUid"
                >
                    <div class="mt-2 lg:mt-0 w-full overflow-x-auto overflow-y-hidden md:overflow-x-visible md:overflow-y-visible">
                        <div class="mt-2 md:mt-4 mb-1 md:-mb-6 xl:-mb-2">
                            <DocumentsTable :items="group.items"
                                            :options="tableOptions"
                                            :sorting-enabled="sortingEnabled"
                            />
                        </div>
                    </div>
                </ContentGroupItem>
            </ContentGroup>
        </div>
        <!-- default table with optional filters -->
        <template v-else>
            <div v-if="showSearchBar" class="bg-primary-50 mb-8">
                <div :class="{'container': !inContentGroup}">
                    <div class="py-6 md:py-8" :class="{'xl:w-2/3 mx-auto': !inContentGroup}">
                        <div class="relative w-full text-primary-500">
                            <input v-model="keyword"
                                   type="text"
                                   class="w-full rounded text-black-600 text-para-s focus:outline-none border border-black-100 py-2 px-10 placeholder-improvedContrast"
                                   :aria-label="filterLabels.fullSearch"
                                   :placeholder="filterLabels.fullSearch"
                            >
                            <Icon name="search" class="absolute left-4 top-3 w-4 h-4 stroke-current" />
                            <div class="absolute right-4 top-3 cursor-pointer" @click="keyword = ''">
                                <Icon v-show="!!keyword" name="close" class="w-4 h-4 stroke-current" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isFiltered" :class="!inContentGroup ? 'container' : ''">
                <div :class="`${!inContentGroup ? 'xl:w-2/3' : ''} mt-5 md:mt-4 xl:mt-2 mx-auto`">
                    <div class="mb-6 md:mb-8">
                        <GcButton v-if="!filterVisible"
                                  icon="filter"
                                  label="Filter"
                                  :aria-label="$globalLabels.open"
                                  @click="filterVisible = true"
                        />
                        <div v-if="filterVisible"
                             class="flex flex-col gap-4 md:gap-x-8 md:flex-row md:items-center justify-end relative
                                    border border-black-100 rounded prose py-4 pl-4 pr-12 md:pl-8 md:pr-12"
                             :class="{'flex-wrap': showSingleCategories}"
                        >
                            <span class="text-para-s whitespace-nowrap">{{ filterLabels.filterBy }}</span>
                            <SearchableGroupDropdown v-if="showYearFilter"
                                                     v-model="filterYear"
                                                     class="w-full md:w-40"
                                                     :search-label="filterLabels.search"
                                                     :placeholder="filterLabels.year"
                                                     :groups="yearGroup"
                            />
                            <SearchableGroupDropdown v-if="showReportingYearFilter"
                                                     v-model="filterReportingYear"
                                                     class="w-full md:w-40"
                                                     :search-label="filterLabels.search"
                                                     :placeholder="filterLabels.reportingYear"
                                                     :groups="reportingYearGroup"
                            />
                            <!-- either show single grouped dropdown or one for each category -->
                            <SearchableGroupDropdown v-if="showTagFilter && !showSingleCategories"
                                                     v-model="filterTag"
                                                     class="w-full flex-grow md:mr-3"
                                                     :search-label="filterLabels.search"
                                                     :placeholder="filterLabels.tag"
                                                     :groups="tagCategories"
                            />
                            <template v-if="showTagFilter && showSingleCategories">
                                <Dropdown v-for="(tags, cat) in tagCategories"
                                          :key="cat"
                                          v-model="filterTags[cat]"
                                          class="w-full md:w-auto min-w-40 flex-grow"
                                          text-classes="text-para-xs md:text-para-s h-4 md:h-6 text-black-500"
                                          :title="cat"
                                          :higher-menu="true"
                                >
                                    <DropdownItem v-for="(tag, index) in tags"
                                                  :key="index"
                                                  :label="tag"
                                                  :value="tag"
                                                  :show-label="false"
                                                  text-classes="pb-2 pt-2 group"
                                    >
                                        <span class="block text-para-s text-black-400 transition-colors group-hover:text-white group-focus:text-white">
                                            {{ tag }}
                                        </span>
                                    </DropdownItem>
                                </Dropdown>
                            </template>
                            <button class="cursor-pointer absolute right-4 top-1/2 transform -translate-y-1/2"
                                    :aria-label="$globalLabels.close"
                                    @click="filterVisible = false"
                            >
                                <Icon name="double-arrow-left" class="stroke-current w-3 h-3" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="`${!inContentGroup ? 'container' : ''} overflow-x-auto overflow-y-hidden md:overflow-x-visible md:overflow-y-visible`">
                <div :class="`${!inContentGroup ? 'xl:w-2/3' : ''} ${!isFiltered ? 'mt-5 md:mt-4 xl:mt-2' : ''} mx-auto`">
                    <DocumentsTable :items="filteredData"
                                    :options="tableOptions"
                                    :sorting-enabled="sortingEnabled"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import SearchableGroupDropdown from "../base/SearchableGroupDropdown.vue";
import DocumentsTable from "./DocumentsTable.vue";
import Icon from "../base/Icon.vue";
import DropdownItem from "../base/DropdownItem.vue";
import Dropdown from "../base/Dropdown.vue";
import GcButton from "../base/GcButton.vue";
import ContentGroup from "../contentGroup/ContentGroup.vue";
import ContentGroupItem from "../contentGroup/ContentGroupItem.vue";

export interface FilterLabels {
    filterBy: string;
    year: string;
    tag: string;
    reportingYear: string;
    fullSearch: string;
    search: string;
    download: string;
    selected: string;
    loadMore: string;
    defaultFilter: string;
}

export interface Filters {
    tag: boolean;
    year: boolean;
    reportingYear: boolean;
}

export default {
    components: {
        ContentGroupItem,
        ContentGroup, GcButton, Dropdown, DropdownItem, Icon, DocumentsTable, SearchableGroupDropdown},
    inject: ['$globalLabels'],
    props: {
        data: {type: Array, required: true},
        tagCategories: {type: Object as PropType<Record<string, string[]>>, default: () => {}},
        batchSize: {type: Number, default: 0},
        columns: {type: Array as PropType<Array<{ key: string; label: string }>>, required: true},
        archiveText: {type: String, required: true},
        title: {type: String, required: true},
        labelArchive: {type: String, required: true},
        filterLabels: {type: Object as PropType<FilterLabels>, required: true},
        chevronIcon: {type: String, required: true},
        grouping: {type: String, default: 'tabs'},
        expanded: {type: Boolean, default: false},
        showSingleCategories: {type: Boolean, default: true},
        showSearchBar: {type: Boolean, default: true},
        showTableHeader: {type: Boolean, default: true},
        showDocumentSize: {type: Boolean, default: true},
        showDocumentIcon: {type: Boolean, default: true},
        showDateAfterText: {type: Boolean, default: true},
        filters: {type: Object as PropType<Filters>, default: () => {}},
        sortingEnabled: {type: Boolean, default: false},
        inContentGroup: {type: Boolean, default: false},
        maxColumns: {type: Number, default: 6},
        description: {type: String}
    },
    data() {
        return {
            filterVisible : false,
            filterYear : '',
            filterReportingYear : '',
            filterTag : '',
            filterTags: {},
            keyword : ''
        };
    },
    computed: {
        groups(): Record<string, any>[] {
            if (!this.grouped) {
                return [{ year: -1, items: this.data }];
            }
            return this.mapData(this.data, this.years.slice(0, this.maxColumns));
        },
        archive(): Record<string, any>[] {
            return this.mapData(this.data, this.years.slice(this.maxColumns));
        },
        years(): number[] {
            return Object.keys(this.data.reduce((acc, item) => {
                acc[new Date(item.date).getFullYear()] = '';
                return acc;
            }, {})).map(x => parseInt(x)).sort((a, b) => b - a);
        },
        reportingYears(): number[] {
            return Object.keys(this.data.reduce((acc, item) => {
                if (item.reportingYear) {
                    acc[item.reportingYear] = '';
                }
                return acc;
            }, {})).map(x => parseInt(x)).sort((a, b) => b - a);
        },
        showYearFilter(): boolean {
            return (this.showDateAfterText || this.columns.findIndex(col => col.key === 'date') >= 0) && this.filters.year;
        },
        showTagFilter(): boolean {
            return this.filters.tag;
        },
        showReportingYearFilter(): boolean {
            return this.filters.reportingYear;
        },
        yearGroup(): Record<string, string[]> {
            const group = {};
            group[this.filterLabels.year] = [
                ...this.years.map(year => year + '')
            ];
            return group;
        },
        reportingYearGroup(): Record<string, string[]> {
            const group = {};
            group[this.filterLabels.reportingYear] = [
                ...this.reportingYears.map(year => year + '')
            ];
            return group;
        },
        tableOptions(): Record<string, any> {
            return {
                columns: this.columns,
                categories: this.tagCategories,
                showHeaders: this.showTableHeader,
                showSize: this.showDocumentSize,
                showIcon: this.showDocumentIcon,
                dateAfterText: this.showDateAfterText,
                batchSize: this.batchSize,
                lblDownload: this.filterLabels.download,
                lblSelected: this.filterLabels.selected,
                lblLoadMore: this.filterLabels.loadMore
            };
        },
        isFiltered(): boolean {
            return Object.keys(this.filters).some(k => this.filters[k]);
        },
        grouped(): boolean {
            return this.grouping === 'tabs' || this.grouping === 'accordions';
        },
        filteredData(): Record<string, any>[] {
            if (!this.isFiltered) {
                return this.data;
            }
            let result = this.data;
            // filter after year of publishing
            if (this.filterYear) {
                const year = Number(this.filterYear);
                result = result.filter(item => new Date(item.date).getFullYear() === year);
            }
            // filter after reporting year (not always the same as year of publishing)
            if (this.filterReportingYear) {
                result = result.filter(item => item.reportingYear === this.filterReportingYear);
            }
            // default tag filtering, you can only select one tag in the grouped dropdown
            if (this.filterTag) {
                result = result.filter(item => (item.tags || []).indexOf(this.filterTag) > -1);
            }
            // basically same as filterTag but in single mode you can select multiple tags (one from each category)
            if (this.showSingleCategories && Object.keys(this.filterTags).length > 0) {
                result = result.filter(item =>
                    // logical AND, so the item must have all filtered tags
                    Object.keys(this.filterTags)
                        .filter(ft => !!this.filterTags[ft] && this.filterTags[ft] !== this.filterLabels.defaultFilter)
                        .every(ft => (item.tags || []).indexOf(this.filterTags[ft]) >= 0)
                );
            }
            // keyword filtering -> look up in document title (case-insensitive)
            if (this.keyword) {
                result = result.filter(item => (item.title || '').toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0);
            }
            return result;
        }
    },
    mounted(): void {
        // for single dropdown mode:
        // add a default option to all categories & init model
        if (this.showSingleCategories) {
            Object.keys(this.tagCategories).forEach(tc => {
                this.tagCategories[tc].unshift(this.filterLabels.defaultFilter);
                this.filterTags[tc] = '';
            });
        }
    },
    methods: {
        mapData(data, years): Record<string, unknown>[] {
            return years
                .map(year => (
                    {
                        year,
                        items: data.filter(item => year === new Date(item.date).getFullYear())
                    }
                ));
        }
    }
};
</script>
