<template>
    <div class="absolute top-5 right-5 stroke-black md:top-3/4 xl:pt-12">
        <div class="mb-3" @click="zoomIn">
            <Icon name="add-square" class="stroke-current w-8 h-8 cursor-pointer" />
        </div>
        <div class="mb-3" @click="zoomOut">
            <Icon name="subtract-square" class="stroke-current w-8 h-8 cursor-pointer" />
        </div>
    </div>
</template>

<script lang="ts">

import Icon from "../base/Icon.vue";

export default {
    components: {Icon},
    emits: ['zoomIn', 'zoomOut'],
    methods: {
        zoomIn() {
            this.$emit('zoomIn');
        },
        zoomOut() {
            this.$emit('zoomOut');
        }
    }
};
</script>
