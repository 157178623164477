import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "xl:w-2/3 mx-auto" }
const _hoisted_4 = {
  key: 0,
  class: "text-heading-3 md:text-heading-2 tracking-tight mb-4 md:mb-6"
}
const _hoisted_5 = { class: "text-heading-5 md:text-heading-4 tracking-tight" }
const _hoisted_6 = { class: "flex md:items-center gap-6 md:gap-10" }
const _hoisted_7 = {
  key: 2,
  class: "flex items-center justify-center rounded-full border border-primary-500 w-10 h-10"
}
const _hoisted_8 = { class: "text-para-xs text-white font-semibold" }
const _hoisted_9 = { class: "mb-18" }
const _hoisted_10 = { class: "container bg-black-50 xl:bg-transparent mt-16" }
const _hoisted_11 = { class: "xl:bg-black-50 py-6 md:py-12" }
const _hoisted_12 = { class: "flex items-center gap-6 md:gap-12 xl:w-1/2 mx-auto" }
const _hoisted_13 = { class: "flex items-center justify-center bg-primary-500 w-18 h-18 rounded-full" }
const _hoisted_14 = {
  key: 0,
  class: "text-para-base md:text-para-xl font-semibold"
}
const _hoisted_15 = { class: "text-para-s md:text-para-lg mt-6" }
const _hoisted_16 = { class: "flex gap-6 md:gap-12 xl:w-1/2 mx-auto mt-8" }
const _hoisted_17 = { class: "py-4 md:pb-0 md:pt-12" }
const _hoisted_18 = {
  key: 0,
  class: "text-para-base md:text-para-xl font-semibold"
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { class: "pt-4 md:pt-12" }
const _hoisted_21 = {
  key: 0,
  class: "text-para-base md:text-para-xl font-semibold"
}
const _hoisted_22 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!
  const _component_ContentOverlay = _resolveComponent("ContentOverlay")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      id: $props.anchor,
      class: "container mt-16 mb-32"
    }, [
      _createElementVNode("div", _hoisted_3, [
        ($props.title)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString($props.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_5, _toDisplayString($props.subtitle), 1)
      ])
    ], 8, _hoisted_2),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.questions, (question, i) => {
        return (_openBlock(), _createElementBlock("li", {
          key: i,
          class: _normalizeClass(["relative container", {'bg-black-50 xl:bg-transparent': i === $data.questionIndex}])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["mr-9 xl:mr-0", {'xl:bg-black-50 border-l-4 border-primary-400': i === $data.questionIndex}])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex md:items-center gap-6 md:gap-10 xl:w-2/3 mx-auto py-6 md:py-12", {
                             'flex-col md:flex-row': i >= $data.questionIndex,
                             'items-center': i < $data.questionIndex,
                             'border-b border-black-400': i !== $data.questionIndex && i !== ($data.questionIndex - 1)
                         }])
            }, [
              _createElementVNode("p", {
                class: _normalizeClass(["flex-grow text-para-base md:text-para-xl font-semibold", {'text-black-600': i > $data.questionIndex}])
              }, _toDisplayString(question), 3),
              _createElementVNode("div", _hoisted_6, [
                (i === $data.questionIndex)
                  ? (_openBlock(), _createBlock(_component_GcButton, {
                      key: 0,
                      class: "whitespace-nowrap",
                      label: $props.yesLabel,
                      secondary: "",
                      onClick: $options.nextStep
                    }, null, 8, ["label", "onClick"]))
                  : _createCommentVNode("", true),
                (i === $data.questionIndex)
                  ? (_openBlock(), _createBlock(_component_GcButton, {
                      key: 1,
                      class: "whitespace-nowrap",
                      label: $props.noLabel,
                      secondary: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.contactOpen = true))
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true),
                (i < $data.questionIndex)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_Icon, {
                        name: "checkmark",
                        class: "w-10 h-10 text-primary-500 stroke-current"
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ], 2),
          _withDirectives(_createElementVNode("button", {
            type: "button",
            class: "absolute top-1/2 right-4.5 xl:right-9 flex items-center gap-4 xl:gap-7 transform translate-x-1/2 -translate-y-1/2 -rotate-90 bg-primary-400 px-4 xl:px-6 py-1.5 xl:py-4",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.openModal && $options.openModal(...args)))
          }, [
            _createElementVNode("span", _hoisted_8, _toDisplayString($props.modalLabel), 1),
            _createVNode(_component_Icon, {
              name: "chat",
              class: "w-6 h-6 xl:w-10 xl:h-10 text-white stroke-current fill-current transform rotate-90"
            })
          ], 512), [
            [_vShow, !$options.complete && i === $data.questionIndex]
          ])
        ], 2))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_9, null, 512), [
      [_vShow, !$options.complete]
    ]),
    _withDirectives(_createVNode(_component_CollapseTransition, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_Icon, {
                  name: "checkmark",
                  class: "w-18 h-18 text-white stroke-current"
                })
              ]),
              _createElementVNode("div", null, [
                ($props.resetTitle)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_14, _toDisplayString($props.resetTitle), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("p", _hoisted_15, _toDisplayString($props.resetText), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-18" }, null, -1)),
              _createVNode(_component_GcButton, {
                label: $props.resetLabel,
                icon: "arrow-up",
                onClick: $options.reset
              }, null, 8, ["label", "onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, $options.complete]
    ]),
    _createVNode(_component_ContentOverlay, {
      modelValue: $data.modalOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.modalOpen) = $event)),
      narrow: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_17, [
          ($props.modalTitle)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_18, _toDisplayString($props.modalTitle), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            class: "text-para-s md:text-para-lg",
            innerHTML: $props.modalText
          }, null, 8, _hoisted_19)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ContentOverlay, {
      modelValue: $data.contactOpen,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.contactOpen) = $event)),
      "button-label": $props.contactLabel,
      narrow: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_20, [
          ($props.contactTitle)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_21, _toDisplayString($props.contactTitle), 1))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            class: "text-para-s md:text-para-lg",
            innerHTML: $props.contactText
          }, null, 8, _hoisted_22)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "button-label"])
  ]))
}