import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, mergeProps as _mergeProps, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative container" }
const _hoisted_2 = { class: "xl:w-2/3 mx-auto bg-white py-4 md:py-6 px-2 md:px-28" }
const _hoisted_3 = ["onSubmit"]
const _hoisted_4 = {
  key: 0,
  class: "text-heading-3 md:text-heading-2 mt-10"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "text-black-600 text-para-xs ml-4" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col gap-6 w-full mb-4"
}
const _hoisted_8 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_9 = {
  for: "commodity",
  class: "md:w-48"
}
const _hoisted_10 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_11 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_12 = {
  for: "duty",
  class: "md:w-48"
}
const _hoisted_13 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_14 = {
  type: "submit",
  class: "flex justify-end items-center group cursor-pointer appearance-none focus:outline-none"
}
const _hoisted_15 = { class: "text-para-s font-semibold" }
const _hoisted_16 = { class: "mb-4" }
const _hoisted_17 = { class: "flex flex-col gap-6 relative w-full" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = {
  type: "submit",
  class: "flex justify-end items-center group cursor-pointer appearance-none focus:outline-none"
}
const _hoisted_20 = { class: "text-para-s font-semibold" }
const _hoisted_21 = {
  key: 2,
  class: "flex flex-col gap-6 w-full mb-4"
}
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_24 = {
  for: "firstName",
  class: "md:w-48"
}
const _hoisted_25 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_26 = ["aria-invalid", "aria-describedby"]
const _hoisted_27 = {
  key: 1,
  id: "firstName-error",
  class: "text-error font-normal text-para-xs mt-1 md:mt-2"
}
const _hoisted_28 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_29 = {
  for: "lastName",
  class: "md:w-48"
}
const _hoisted_30 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_31 = ["aria-invalid", "aria-describedby"]
const _hoisted_32 = {
  key: 1,
  id: "lastName-error",
  class: "text-error font-normal text-para-xs mt-1 md:mt-2"
}
const _hoisted_33 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_34 = {
  for: "company",
  class: "md:w-48"
}
const _hoisted_35 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_36 = ["aria-invalid", "aria-describedby"]
const _hoisted_37 = {
  key: 1,
  id: "company-error",
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_38 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_39 = {
  for: "email",
  class: "md:w-48"
}
const _hoisted_40 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_41 = ["aria-invalid", "aria-describedby", "placeholder"]
const _hoisted_42 = {
  key: 1,
  id: "email-error",
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_43 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_44 = {
  for: "country",
  class: "md:w-48"
}
const _hoisted_45 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_46 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_47 = { class: "flex relative w-full max-w-full md:ml-1/3 md:max-w-8/12" }
const _hoisted_48 = ["aria-invalid", "aria-describedby"]
const _hoisted_49 = ["innerHTML"]
const _hoisted_50 = {
  key: 0,
  id: "acceptPrivacy-error",
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_51 = {
  type: "submit",
  class: "flex justify-end items-center group cursor-pointer appearance-none focus:outline-none"
}
const _hoisted_52 = { class: "text-para-s font-semibold" }
const _hoisted_53 = {
  key: 1,
  class: "relative w-full py-11 flex flex-col"
}
const _hoisted_54 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_55 = { class: "flex flex-col py-4" }
const _hoisted_56 = {
  key: 0,
  class: "text-para-s text-error text-center mt-6"
}
const _hoisted_57 = { class: "text-black-600 text-para-xs ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiStepFormHeader = _resolveComponent("MultiStepFormHeader")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_CalculatorInput = _resolveComponent("CalculatorInput")!
  const _component_JamesonCellResults = _resolveComponent("JamesonCellResults")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Form, { as: "div" }, {
        default: _withCtx(({ handleSubmit }) => [
          ($data.step <= $options.currentDuty.steps.length + 1)
            ? (_openBlock(), _createElementBlock("form", {
                key: 0,
                onSubmit: _withModifiers(($event: any) => (handleSubmit($event, $options.onSubmit)), ["prevent"])
              }, [
                ($props.title)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString($props.title), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "mt-4 mb-12 prose md:prose-md lg:prose-lg",
                  innerHTML: $props.intro
                }, null, 8, _hoisted_5),
                _createVNode(_component_MultiStepFormHeader, {
                  steps: $options.stepHeader,
                  "current-step": $data.step
                }, null, 8, ["steps", "current-step"]),
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["flex items-center cursor-pointer w-fit mb-6", [ $options.showBackNavigation ? '' : 'invisible' ]]),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.back && $options.back(...args)))
                }, [
                  _createVNode(_component_Icon, {
                    name: "chevron-left",
                    class: "w-3 h-3 text-black-300 stroke-current"
                  }),
                  _createElementVNode("span", _hoisted_6, _toDisplayString($props.labelBack), 1)
                ], 2),
                ($data.step === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("label", _hoisted_9, _toDisplayString($options.translate('commodity')), 1),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_Dropdown, {
                            id: "commodity",
                            modelValue: $data.commodity,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.commodity) = $event)),
                            title: $props.commodities.find(x => x.value === $data.commodity).label,
                            white: true,
                            disabled: $data.step > 0,
                            class: "w-full md:w-auto min-w-40"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.commodities, (c) => {
                                return (_openBlock(), _createBlock(_component_DropdownItem, {
                                  key: c.value,
                                  value: c.value,
                                  label: c.label
                                }, null, 8, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "title", "disabled"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("label", _hoisted_12, _toDisplayString($options.translate('duty')), 1),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_Dropdown, {
                            id: "duty",
                            modelValue: $data.duty,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.duty) = $event)),
                            title: $options.translate($data.duty),
                            white: true,
                            disabled: $data.step > 0,
                            class: "w-full md:w-auto min-w-40"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.duties, (d, i) => {
                                return (_openBlock(), _createBlock(_component_DropdownItem, {
                                  key: i,
                                  value: d.key,
                                  label: $options.translate(d.key)
                                }, null, 8, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "title", "disabled"])
                        ])
                      ]),
                      _createElementVNode("button", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString($data.step > $options.currentDuty.steps.length ? $props.labelRequest : $props.labelNext), 1),
                        _createVNode(_component_ArrowButton, {
                          direction: "right",
                          class: "w-10 h-10"
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.currentDuty.steps, (stp, s) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: stp.key
                  }, [
                    _withDirectives(_createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        ($options.getStepDescription(stp.key))
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "mb-4",
                              innerHTML: $options.getStepDescription(stp.key)
                            }, null, 8, _hoisted_18))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(stp.fields, (field) => {
                          return (_openBlock(), _createBlock(_component_CalculatorInput, {
                            key: field.key,
                            modelValue: $data.calculationValues[field.key],
                            "onUpdate:modelValue": ($event: any) => (($data.calculationValues[field.key]) = $event),
                            name: field.key,
                            disabled: s < $data.step - 1 || !$options.getDependency(field.enableIf),
                            values: field.values,
                            interval: field.interval,
                            dependency: $options.getDependency(field.filterValues),
                            label: $options.translate(field.label),
                            "yes-label": $options.translate('yes'),
                            type: field.type,
                            observable: $options.calcValuesStr
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "disabled", "values", "interval", "dependency", "label", "yes-label", "type", "observable"]))
                        }), 128)),
                        ($options.getStepResults(s).length > 0)
                          ? (_openBlock(), _createBlock(_component_JamesonCellResults, {
                              key: 1,
                              "group-labels": $options.getResultGroups(s),
                              "label-results-suffix": $props.labelResultsSuffix,
                              results: $options.getStepResults(s)
                            }, null, 8, ["group-labels", "label-results-suffix", "results"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", _hoisted_19, [
                          _createElementVNode("span", _hoisted_20, _toDisplayString($data.step > $options.currentDuty.steps.length ? $props.labelRequest : $props.labelNext), 1),
                          _createVNode(_component_ArrowButton, {
                            direction: "right",
                            class: "w-10 h-10"
                          })
                        ])
                      ])
                    ], 512), [
                      [_vShow, $data.step > s]
                    ])
                  ])), [
                    [_vShow, ($data.step - 1) === s]
                  ])
                }), 128)),
                ($data.step > $options.currentDuty.steps.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      ($options.getStepDescription('send'))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "mb-4",
                            innerHTML: $options.getStepDescription('send')
                          }, null, 8, _hoisted_22))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("label", _hoisted_24, _toDisplayString($props.labelFirstName), 1),
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(_component_Field, {
                            modelValue: $data.firstName,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.firstName) = $event)),
                            vid: "firstName",
                            rules: "required",
                            name: $props.labelFirstName
                          }, {
                            default: _withCtx(({ field, errors, meta }) => [
                              _createElementVNode("input", _mergeProps({
                                id: "firstName",
                                type: "text",
                                class: "w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white",
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'firstName-error' : ''
                              }, field, {
                                class: [ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : ''],
                                "data-vv-scope": "contact"
                              }), null, 16, _hoisted_26),
                              ($options.getIcon(errors, meta.valid))
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: $options.getIcon(errors, meta.valid),
                                    class: "absolute w-4 h-10 md:h-11 right-4 top-px"
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(errors[0]), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "name"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("label", _hoisted_29, _toDisplayString($props.labelLastName), 1),
                        _createElementVNode("div", _hoisted_30, [
                          _createVNode(_component_Field, {
                            modelValue: $data.lastName,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.lastName) = $event)),
                            vid: "lastName",
                            rules: "required",
                            name: $props.labelLastName
                          }, {
                            default: _withCtx(({ field, errors, meta }) => [
                              _createElementVNode("input", _mergeProps({
                                id: "lastName",
                                type: "text",
                                class: "w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white",
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'lastName-error' : ''
                              }, field, {
                                class: [ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : ''],
                                "data-vv-scope": "contact"
                              }), null, 16, _hoisted_31),
                              ($options.getIcon(errors, meta.valid))
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: $options.getIcon(errors, meta.valid),
                                    class: "absolute w-4 h-10 md:h-11 right-4 top-px"
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(errors[0]), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "name"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("label", _hoisted_34, _toDisplayString($props.labelCompany), 1),
                        _createElementVNode("div", _hoisted_35, [
                          _createVNode(_component_Field, {
                            modelValue: $data.company,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.company) = $event)),
                            vid: "company",
                            name: $props.labelCompany,
                            rules: "required"
                          }, {
                            default: _withCtx(({ field, errors, meta }) => [
                              _createElementVNode("input", _mergeProps({
                                id: "company",
                                type: "text",
                                class: ["w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white", [ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']],
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'company-error' : '',
                                "data-vv-scope": "contact"
                              }, field), null, 16, _hoisted_36),
                              ($options.getIcon(errors, meta.valid))
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: $options.getIcon(errors, meta.valid),
                                    class: "absolute w-4 h-10 md:h-11 right-4 top-px"
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(errors[0]), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "name"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_38, [
                        _createElementVNode("label", _hoisted_39, _toDisplayString($props.labelEmail), 1),
                        _createElementVNode("div", _hoisted_40, [
                          _createVNode(_component_Field, {
                            modelValue: $data.email,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.email) = $event)),
                            vid: "email",
                            name: $props.labelEmail,
                            rules: { required: true, email: true }
                          }, {
                            default: _withCtx(({ field, errors, meta }) => [
                              _createElementVNode("input", _mergeProps({
                                id: "email",
                                type: "email",
                                class: ["w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white placeholder-improvedContrast", [ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']],
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'email-error' : ''
                              }, field, {
                                "data-vv-scope": "contact",
                                placeholder: $props.labelEmailPlaceholder
                              }), null, 16, _hoisted_41),
                              ($options.getIcon(errors, meta.valid))
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: $options.getIcon(errors, meta.valid),
                                    class: "absolute w-4 h-10 md:h-11 right-4 top-px"
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_42, _toDisplayString(errors[0]), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "name"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_43, [
                        _createElementVNode("label", _hoisted_44, _toDisplayString($props.labelCountry), 1),
                        _createElementVNode("div", _hoisted_45, [
                          _createVNode(_component_Dropdown, {
                            id: "country",
                            modelValue: $data.country,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.country) = $event)),
                            name: "country",
                            white: true,
                            searchable: true,
                            title: $options.countryOptions.find(x => x.value === $data.country) ? $options.countryOptions.find(x => x.value === $data.country).label : '',
                            class: "w-full md:w-auto min-w-40"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.countryOptions, (c, i) => {
                                return (_openBlock(), _createBlock(_component_DropdownItem, {
                                  key: i,
                                  value: c.value,
                                  label: c.label
                                }, null, 8, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "title"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_46, [
                        _createElementVNode("div", _hoisted_47, [
                          _createVNode(_component_Field, {
                            modelValue: $data.privacyAccepted,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.privacyAccepted) = $event)),
                            name: "labelPrivacy",
                            vid: "acceptPrivacy",
                            rules: "required",
                            "unchecked-value": false
                          }, {
                            default: _withCtx(({ field, errors }) => [
                              _createElementVNode("input", _mergeProps({
                                id: "acceptPrivacy",
                                name: "labelPrivacy",
                                type: "checkbox",
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'acceptPrivacy-error' : '',
                                "data-vv-scope": "contact",
                                class: "mb-0 absolute opacity-0 text-black-900 text-para-xs md:text-para-s"
                              }, field, { value: true }), null, 16, _hoisted_48),
                              _createElementVNode("label", {
                                class: "inline-block relative pl-6 checkbox-label-calculator",
                                for: "acceptPrivacy",
                                innerHTML: $props.privacy
                              }, null, 8, _hoisted_49),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_50, _toDisplayString($props.errorAcceptPrivacy), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("button", _hoisted_51, [
                        _createElementVNode("span", _hoisted_52, _toDisplayString($data.step > $options.currentDuty.steps.length ? $props.labelRequest : $props.labelNext), 1),
                        _createVNode(_component_ArrowButton, {
                          direction: "right",
                          class: "w-10 h-10"
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 40, _hoisted_3))
            : (_openBlock(), _createElementBlock("div", _hoisted_53, [
                ($props.title)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_54, _toDisplayString($props.title), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_55, [
                  ($data.httpError)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_56, _toDisplayString($data.httpError), 1))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("p", null, _toDisplayString($props.confirmation1), 1),
                        _createElementVNode("p", null, _toDisplayString($props.confirmation2), 1)
                      ], 64))
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: _normalizeClass(["flex items-center cursor-pointer w-fit mt-4", [ $data.step === 0 ? 'hidden md:block md:invisible' : 'visible' ]]),
                  onClick: _cache[9] || (_cache[9] = ($event: any) => ($data.step--))
                }, [
                  _createVNode(_component_Icon, {
                    name: "chevron-left",
                    class: "w-3 h-3 text-black-300 stroke-current"
                  }),
                  _createElementVNode("span", _hoisted_57, _toDisplayString($props.labelBack), 1)
                ], 2)
              ]))
        ]),
        _: 1
      })
    ])
  ]))
}