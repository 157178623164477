<template>
    <li class="relative flex flex-col xl:flex-row group/1" :class="classes">
        <slot name="image" v-bind:click="play" />
        <slot name="text" v-bind:uniqueRef="uniqueRef" />
    </li>
</template>

<script lang="ts">

export default {
    props: {
        classes: {type: String}
    },
    data() {
        return {
            uniqueRef: 0
        };
    },
    /**
     * Generate unique Ref ID on component generation.
     */
    mounted(): void {
        this.uniqueRef = Math.floor(Math.random() * 100);
    },
    methods: {
        /**
         * On 'play' button click, or image click, simulate click on video link.
         */
        play() {
            const teaserLinkRef = this.$slots.text()[0].context.$refs['teaserLink-' + this.uniqueRef];

            if (teaserLinkRef) {
                teaserLinkRef.$el.click();
            }
        }
    }
};
</script>
