<template>
    <div class="relative w-full h-full">
        <div class="w-full h-full max-h-full md:overflow-y-auto pb-10">
            <div
                class="w-full flex flex-col text-white pt-2 pb-14 md:pb-10 md:pt-5 px-5 xl:pt-10 xl:pl-10 xl:pr-6 relative min-h-150 md:min-h-0"
            >
                <span class="text-heading-4 xl:text-heading-3 w-full break-words mb-4 md:mb-6">
                    {{ entity.title || entity.name }}
                </span>
                <!-- Operations -->
                <ul v-if="scope === 'Region' && displayOperations" class="text-para-xs mb-5">
                    {{ operationsLabel }}:
                    <li v-for="(operation, index) in displayOperations" :key="index" class="inline-flex text-white">
                        <a class="cursor-pointer underline"
                           @click="setOperation(operation)"
                        >{{ operation.title || operation.name || '' }}</a>
                        <span v-if="filterOperations[filterOperations.length - 1] !== operation"
                              class="inline-flex no-underline mr-0.5"
                        >,</span>
                    </li>
                </ul>
                <!-- Description -->
                <SocioEconomicMapDetailDescription
                    v-if="entity.description && entity.description.trim().length > 0"
                    :description="entity.description"
                    :show-more-label="showMoreLabel"
                    :show-less-label="showLessLabel"
                />
                <SocioEconomicMapDetailSlider v-if="entity.images && entity.images.length > 0"
                                              :slides="entity.images"
                />
                <!-- Datasets -->
                <SocioEconomicMapDetailDatasets v-if="entity.data && entity.data.length > 0"
                                                :datasets="entity.data"
                />
                <!-- Disclaimer -->
                <div v-if="entity.disclaimer && entity.disclaimer.trim().length"
                     class="w-full flex flex-col text-black-100 text-para-xxs pt-2 relative mt-20 md:pb-3 md:text-para-xxxs"
                >
                    {{ entity.disclaimer || '' }}
                </div>
            </div>
            <!-- Document -->
            <a v-if="entity.factSheet" :href="entity.factSheet.url"
               class="bg-primary-500 shadow-2xl shadow-black absolute bottom-0 md:bottom-0 left-0 px-5 py-3 w-full text-white text-para-xs flex items-center"
            >
                <span class="font-semibold mr-1">{{ downloadFactsheetLabel }}</span>
                <span v-if="entity.factSheet.size" class="text-black-100 mr-3">({{ entity.factSheet.size }})</span>
                <ArrowButton direction="download" />
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import SocioEconomicMapUtils, {Operation, SubOperation, SubRegion} from './SocioEconomicMapUtils';
import SocioEconomicMapDetailDescription from "./SocioEconomicMapDetailDescription.vue";
import SocioEconomicMapDetailSlider from "./SocioEconomicMapDetailSlider.vue";
import SocioEconomicMapDetailDatasets from "./SocioEconomicMapDetailDatasets.vue";
import ArrowButton from "../base/ArrowButton.vue";

export default {
    components: {ArrowButton, SocioEconomicMapDetailDatasets, SocioEconomicMapDetailSlider, SocioEconomicMapDetailDescription},
    props: {
        entity: {type: Object as PropType<SubRegion | SubOperation>, default: null},
        scope: {type: String, default: ''},
        operations: {type: Array as PropType<Array<Operation>>, default: () => ([])},
        downloadFactsheetLabel: {type: String, default: ''},
        operationsLabel: {type: String, default: ''},
        showMoreLabel: {type: String, default: ''},
        showLessLabel: {type: String, default: ''}
    },
    emits: ['setOperation'],
    computed: {
        displayOperations(): Array<SubOperation> {
            return this.filterOperations.filter(operation => !operation.hideInSidebar);
        },
        filterOperations(): Array<SubOperation> {
            return this.scope === 'Region' && this.entity !== null && this.operations.length > 0
                ? SocioEconomicMapUtils.getSubOperationsFromRegion(this.entity as SubRegion, this.operations)
                : [];
        }
    },
    methods: {
        setOperation(operation: SubOperation) {
            this.$emit('setOperation', operation);
        }
    }
};
</script>
