import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = ["id", "aria-label"]
const _hoisted_4 = {
  key: 0,
  class: "flex flex-wrap gap-2 min-h-4 md:min-h-6"
}
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title", "onClick", "onKeyup"]
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "flex items-center flex-shrink-0 gap-4" }
const _hoisted_9 = {
  key: 0,
  class: "text-para-xs md:text-para-s text-black-600"
}
const _hoisted_10 = {
  class: "absolute top-full z-10 w-full bg-white border-r border-b border-l border-black-100 rounded-b",
  role: "listbox"
}
const _hoisted_11 = { class: "overflow-y-scroll max-h-96" }
const _hoisted_12 = {
  key: 0,
  class: "cursor-pointer text-black-900 mx-2 mb-4 mt-3 text-para-s flex items-center justify-start relative border border-black-200 rounded"
}
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "absolute top-1/2 transform -translate-y-1/2 right-4 text-black-200" }
const _hoisted_15 = ["onClick", "onKeyup"]
const _hoisted_16 = { class: "block text-para-xs md:text-para-s text-black-600 group-hover:text-white group-focus:text-white" }
const _hoisted_17 = {
  key: 0,
  class: "flex justify-end py-2 px-4 shadow-top-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        id: $props.name,
        type: "button",
        role: "combobox",
        class: _normalizeClass(["flex justify-between items-center gap-2 w-full border transition-colors cursor-pointer border-black-100 focus:border-primary-500 hover:text-black-900 rounded-t px-4 py-3 md:py-2.5", {'rounded-b': !$data.open}]),
        "aria-label": $data.open ? $options.$globalLabels.close : $options.$globalLabels.open,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.doClose && $options.doClose(...args)))
      }, [
        ($props.pillItems)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (!$data.currentSelection || !$data.currentSelection.length)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "text-para-xs md:text-para-s text-black-600 truncate",
                    title: $options.title
                  }, _toDisplayString($options.title), 9, _hoisted_5))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.currentSelection, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item,
                  class: "flex items-center gap-1 text-para-xs truncate bg-black-50 px-3 py-1 rounded-full",
                  title: $props.items[item].key,
                  tabindex: "0",
                  onClick: ($event: any) => ($options.toggle(item)),
                  onKeyup: _withKeys(($event: any) => ($options.toggle(item)), ["delete"])
                }, [
                  _createVNode(_component_Icon, {
                    name: "close",
                    class: "w-2 h-2 stroke-current"
                  }),
                  _createElementVNode("div", null, _toDisplayString($props.items[item]), 1)
                ], 40, _hoisted_6))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "text-para-xs md:text-para-s text-black-600 h-4 md:h-6 truncate",
              title: $options.title
            }, _toDisplayString($options.title), 9, _hoisted_7)),
        _createElementVNode("div", _hoisted_8, [
          (!$props.pillItems && $data.currentSelection.length > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, " + " + _toDisplayString($data.currentSelection.length - 1), 1))
            : _createCommentVNode("", true),
          _createVNode(_component_Icon, {
            name: "chevron-down",
            class: _normalizeClass(["w-3 h-3 flex-shrink-0 transition-transform transform origin-center stroke-current", { 'rotate-180': $data.open }])
          }, null, 8, ["class"])
        ])
      ], 10, _hoisted_3),
      _createVNode(_component_CollapseTransition, null, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              ($props.searchable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.currentSearch) = $event)),
                      type: "text",
                      placeholder: $props.searchLabel,
                      class: "w-10/12 h-full py-2 px-2 md:py-2.5 placeholder:text-para-s focus:outline-none rounded placeholder-improvedContrast"
                    }, null, 8, _hoisted_13), [
                      [_vModelText, $data.currentSearch]
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_Icon, {
                        name: "search",
                        class: "stroke-current w-4 h-4"
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (label, iValue) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: iValue,
                  role: "option",
                  class: "flex gap-2 cursor-pointer hover:bg-primary-500 focus:bg-primary-500 px-4 py-3 md:py-2.5 group",
                  tabindex: "0",
                  onClick: ($event: any) => ($options.toggle(iValue)),
                  onKeyup: _withKeys(($event: any) => ($options.toggle(iValue)), ["enter"])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["group-hover:text-white group-focus:text-white", $options.isSelected(iValue) ? 'text-primary-500' : 'text-black-200'])
                  }, [
                    _createVNode(_component_Icon, {
                      name: $options.isSelected(iValue) ? 'checkbox-active' : 'checkbox',
                      class: "relative top-1.5 stroke-current w-3 h-3"
                    }, null, 8, ["name"])
                  ], 2),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(label), 1)
                ], 40, _hoisted_15)), [
                  [_vShow, $options.shouldItemRender(iValue)]
                ])
              }), 128))
            ]),
            ($props.showCta)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "flex flex-row items-center justify-center text-para-xs text-white font-semibold rounded border border-transparent focus:outline-none bg-black-900 hover:bg-black-600 focus:border-primary-500 active:bg-black-500 transition-all duration-300 ease-out px-4 py-1",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.emitCta && $options.emitCta(...args)))
                  }, _toDisplayString($props.ctaLabel), 1)
                ]))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, $data.open]
          ])
        ]),
        _: 1
      })
    ])
  ]))
}