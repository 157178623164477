import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-8 h-8 relative flex justify-center items-center"
}
const _hoisted_2 = {
  key: 1,
  class: "w-8 h-8 relative"
}
const _hoisted_3 = {
  key: 2,
  class: "w-8 h-8 relative"
}
const _hoisted_4 = {
  key: 3,
  class: "w-8 h-8 relative"
}
const _hoisted_5 = {
  key: 4,
  class: "w-8 h-8 relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.network === 'twitter')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "absolute top-0 left-0 w-full h-full bg-black-50 scale-110 rounded-full transition-all duration-150 transform group-hover:bg-primary-50 group-hover:scale-125 group-focus:bg-primary-50 group-focus:scale-125" }, null, -1),
        _createElementVNode("svg", {
          class: "relative",
          viewBox: "0 0 1200 1227",
          fill: "none",
          width: "16",
          height: "16",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            d: "M714.163 519.284 1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026ZM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026Z",
            fill: "#000"
          })
        ], -1)
      ])))
    : ($props.network === 'facebook')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "absolute top-0 left-0 w-full h-full bg-black-50 scale-110 rounded-full transition-all duration-150 transform group-hover:bg-primary-50 group-hover:scale-125 group-focus:bg-primary-50 group-focus:scale-125" }, null, -1),
          _createElementVNode("svg", {
            class: "relative",
            xmlns: "http://www.w3.org/2000/svg",
            width: "32",
            height: "32",
            viewBox: "0 0 32 32",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M16.911 23.3333V16.6304H19.1674L19.4992 14.0422H16.911V12.3831C16.911 11.6531 17.1101 11.1221 18.1719 11.1221H19.5656V8.79936C19.3002 8.79936 18.5038 8.66663 17.5747 8.66663C15.5837 8.66663 14.2564 9.8612 14.2564 12.1176V14.0422H12V16.6304H14.2564V23.3333H16.911Z",
              fill: "black"
            })
          ], -1)
        ])))
      : ($props.network === 'messenger')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "absolute top-0 left-0 w-full h-full bg-black-50 scale-110 rounded-full transition-all duration-150 transform group-hover:bg-primary-50 group-hover:scale-125 group-focus:bg-primary-50 group-focus:scale-125" }, null, -1),
            _createElementVNode("svg", {
              class: "relative",
              xmlns: "http://www.w3.org/2000/svg",
              width: "32",
              height: "32",
              viewBox: "0 0 32 32",
              fill: "none"
            }, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M16 8C11.4934 8 8 11.3011 8 15.76C8 18.0923 8.95584 20.1076 10.5124 21.4997C10.6431 21.6166 10.722 21.7805 10.7274 21.9558L10.7709 23.3789C10.7848 23.8328 11.2537 24.1282 11.6691 23.9448L13.257 23.2438C13.3916 23.1845 13.5424 23.1734 13.6842 23.2125C14.4139 23.4131 15.1905 23.52 16 23.52C20.5066 23.52 24 20.2189 24 15.76C24 11.3011 20.5066 8 16 8Z",
                fill: "black"
              }),
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M11.196 18.0294L13.546 14.3011C13.9198 13.708 14.7203 13.5603 15.2812 13.9809L17.1503 15.3828C17.3218 15.5114 17.5577 15.5107 17.7285 15.3811L20.2528 13.4653C20.5897 13.2097 21.0295 13.6129 20.804 13.9707L18.454 17.699C18.0802 18.2921 17.2797 18.4398 16.7188 18.0192L14.8497 16.6173C14.6782 16.4887 14.4423 16.4894 14.2715 16.619L11.7472 18.5348C11.4103 18.7904 10.9705 18.3872 11.196 18.0294Z",
                fill: "white"
              })
            ], -1)
          ])))
        : ($props.network === 'linkedin')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "absolute top-0 left-0 w-full h-full bg-black-50 scale-110 rounded-full transition-all duration-150 transform group-hover:bg-primary-50 group-hover:scale-125 group-focus:bg-primary-50 group-focus:scale-125" }, null, -1),
              _createElementVNode("svg", {
                class: "relative",
                xmlns: "http://www.w3.org/2000/svg",
                width: "32",
                height: "32",
                viewBox: "0 0 32 32",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M11.9172 23.3333H8.82531V13.5027H11.9172V23.3333ZM10.4109 12.1549C9.45954 12.1549 8.66675 11.3621 8.66675 10.4108C8.66675 9.45942 9.45954 8.66663 10.4109 8.66663C11.3622 8.66663 12.155 9.45942 12.155 10.4108C12.155 11.3621 11.3622 12.1549 10.4109 12.1549ZM23.3334 23.3333H20.2415V18.5765C20.2415 17.4666 20.2415 15.9603 18.6559 15.9603C17.0704 15.9603 16.8325 17.2288 16.8325 18.4973V23.3333H13.7406V13.5027H16.7532V14.8504H16.8325C17.2289 14.0576 18.2595 13.2648 19.6866 13.2648C22.7785 13.2648 23.3334 15.3261 23.3334 17.9423V23.3333V23.3333Z",
                  fill: "black"
                })
              ], -1)
            ])))
          : ($props.network === 'email')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[4] || (_cache[4] = [
                _createElementVNode("div", { class: "absolute top-0 left-0 w-full h-full bg-black-50 scale-110 rounded-full transition-all duration-150 transform group-hover:bg-primary-50 group-hover:scale-125 group-focus:bg-primary-50 group-focus:scale-125" }, null, -1),
                _createElementVNode("svg", {
                  class: "relative",
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "32",
                  height: "32",
                  viewBox: "0 0 32 32",
                  fill: "none"
                }, [
                  _createElementVNode("path", {
                    d: "M22 11.1666H10C9.44772 11.1666 9 11.6143 9 12.1666V20.1666C9 20.7189 9.44772 21.1666 10 21.1666H22C22.5523 21.1666 23 20.7189 23 20.1666V12.1666C23 11.6143 22.5523 11.1666 22 11.1666Z",
                    fill: "black",
                    stroke: "black",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }),
                  _createElementVNode("path", {
                    d: "M22.7741 11.5333L17.3447 15.7093C16.9592 16.0059 16.4865 16.1667 16.0001 16.1667C15.5137 16.1667 15.0409 16.0059 14.6554 15.7093L9.22607 11.5333",
                    stroke: "white",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  })
                ], -1)
              ])))
            : _createCommentVNode("", true)
}