<template>
    <div class="w-full grid grid-cols-6 gap-x-6 gap-y-4 text-para-s bg-black-50 px-6 py-4">
        <template v-for="(label, key) in properties" :key="key">
            <template v-if="user.properties.hasOwnProperty(key)">
                <span class="font-semibold">{{ label }}</span>
                <span v-if="key === 'regstamp' && user.properties.hasOwnProperty(key)" class="col-span-2">
                    {{ $filters.formatDate(Number(user.properties[key])) }}
                </span>
                <span v-else class="col-span-2">{{ user.properties[key] || 'n/a' }}</span>
            </template>
        </template>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {User} from './UserManagement.vue';

export default {
    props: {
        user: {type: Object as PropType<User>, required: true},
        properties: {type: Object as PropType<Record<string, string>>, required: true}
    }
};
</script>
