<script lang="ts">
import {h, VNode} from 'vue';

export default {
    render() {
        return h('div',
            [
                this.$slots.default ?
                    this.$slots.default().map((child: VNode) =>
                        child.type === 'table' ?
                            h('div', {staticClass: 'overflow-x-auto table-section'}, [child])
                            : child)
                    : ''
            ]
        );
    }
};
</script>
