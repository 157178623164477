<template>
    <div class="relative container">
        <div class="xl:w-2/3 mx-auto bg-white py-4 md:py-6 px-2 md:px-28">
            <Form v-slot="{ handleSubmit }" as="div">
                <form v-if="step < 2" @submit.prevent="handleSubmit(submit)">
                    <h2 v-if="title" class="text-heading-3 md:text-heading-2 mt-10">
                        {{ title }}
                    </h2>
                    <div class="mt-4 mb-12 prose md:prose-md lg:prose-lg" v-html="intro" />
                    <MultiStepFormHeader :steps="stepHeader" :current-step="step" />
                    <!-- Back navigation -->
                    <div class="flex items-center cursor-pointer w-fit mb-6"
                         :class="[ showBackNavigation ? '' : 'invisible' ]"
                         tabindex="0"
                         @click="back"
                         @keyup.enter="back"
                    >
                        <Icon name="chevron-left" class="w-3 h-3 text-black-300 stroke-current" />
                        <span class="text-black-600 text-para-xs ml-4">{{ labelBack }}</span>
                    </div>
                    <!-- first step -->
                    <div v-if="step === 0" class="flex flex-col gap-6 w-full mb-4">
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="commodity" class="md:w-48">
                                {{ translate('commodity') }}
                            </label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Dropdown id="commodity"
                                          v-model="commodity"
                                          :title="commodities.find(x => x.value === commodity).label"
                                          :white="true"
                                          :disabled="step > 0"
                                          class="w-full md:w-auto min-w-40"
                                >
                                    <DropdownItem v-for="(c, i) in commodities"
                                                  :key="i"
                                                  :value="c.value"
                                                  :label="c.label"
                                    />
                                </Dropdown>
                            </div>
                        </div>
                        <CalculatorInput v-model="f80"
                                         name="f80"
                                         :values="f80Values"
                                         :label="translate('f80')"
                                         :type="DutyFieldType.RANGE_SLIDER"
                                         :interval="5"
                        />
                        <CalculatorInput v-model="p80"
                                         name="p80"
                                         :values="p80Values"
                                         :dependency="x => x <= f80"
                                         :label="translate('p80')"
                                         :type="DutyFieldType.RANGE_SLIDER"
                                         :interval="1"
                                         :observable="f80"
                        />
                        <CalculatorInput v-model="throughput"
                                         name="throughput"
                                         :values="tphValues"
                                         :label="translate('throughput')"
                                         :type="DutyFieldType.RANGE_SLIDER"
                                         :interval="1"
                        />
                        <!-- jump to next step -->
                        <button class="flex justify-end items-center group cursor-pointer appearance-none focus:outline-none"
                                @click.prevent="handleSubmit(submit)"
                        >
                            <span class="text-para-s font-semibold">{{ labelNext }}</span>
                            <ArrowButton direction="right" class="w-10 h-10" />
                        </button>
                    </div>
                    <!-- last step -->
                    <div v-if="step === 1" class="flex flex-col gap-6 w-full mb-4">
                        <div v-if="sendReportDescription" class="mb-4" v-html="sendReportDescription" />
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="firstName" class="md:w-48">{{ labelFirstName }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Field v-slot="{ field, errors, value, meta }"
                                       v-model="firstName"
                                       vid="firstName"
                                       :name="labelFirstName"
                                       rules="required"
                                >
                                    <input id="firstName"
                                           type="text"
                                           class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'firstName-error' : ''"
                                           :class="[ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']"
                                           v-bind="field"
                                           data-vv-scope="contact"
                                    >
                                    <Icon v-if="getIcon(errors, meta.valid)" :name="getIcon(errors, meta.valid)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
                                    <span v-if="errors.length > 0" id="firstName-error" class="text-error text-para-xs mt-1 md:mt-2">
                                        {{ errors[0] }}
                                    </span>
                                </Field>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="lastName" class="md:w-48">{{ labelLastName }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Field v-slot="{ field, errors, value, meta }"
                                       v-model="lastName"
                                       vid="lastName"
                                       :name="labelLastName"
                                       rules="required"
                                >
                                    <input id="lastName"
                                           type="text"
                                           class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'lastName-error' : ''"
                                           :class="[ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']"
                                           v-bind="field"
                                           data-vv-scope="contact"
                                    >
                                    <Icon v-if="getIcon(errors, meta.valid)" :name="getIcon(errors, meta.valid)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
                                    <span v-if="errors.length > 0" id="lastName-error" class="text-error text-para-xs mt-1 md:mt-2">
                                        {{ errors[0] }}
                                    </span>
                                </Field>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="company" class="md:w-48">{{ labelCompany }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Field v-slot="{ field, errors, value, meta }"
                                       v-model="company"
                                       vid="company"
                                       rules="required"
                                       :name="labelCompany"
                                >
                                    <input id="company"
                                           v-bind="field"
                                           type="text"
                                           class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white"
                                           :class="[ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'company-error' : ''"
                                           data-vv-scope="company"
                                    >
                                    <Icon v-if="getIcon(errors, meta.valid)" :name="getIcon(errors, meta.valid)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
                                    <span v-if="errors.length > 0" id="company-error" class="text-error text-para-xs mt-1 md:mt-2">
                                        {{ errors[0] }}
                                    </span>
                                </Field>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="email" class="md:w-48">{{ labelEmail }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Field v-slot="{ field, errors, value, meta }"
                                       v-model="email"
                                       vid="email"
                                       :name="labelEmail"
                                       :rules="{ required: true, email: true }"
                                >
                                    <input id="email"
                                           v-bind="field"
                                           type="email"
                                           class="w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white placeholder-improvedContrast"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'email-error' : ''"
                                           :class="[ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']"
                                           data-vv-scope="contact"
                                           :placeholder="labelEmailPlaceholder"
                                    >
                                    <Icon v-if="getIcon(errors, meta.valid)" :name="getIcon(errors, meta.valid)" class="absolute w-4 h-10 md:h-11 right-4 top-px" />
                                    <span v-if="errors.length > 0" id="email-error" class="text-error text-para-xs mt-1 md:mt-2">
                                        {{ errors[0] }}
                                    </span>
                                </Field>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <label for="country" class="md:w-48">{{ labelCountry }}</label>
                            <div class="relative w-full max-w-full md:max-w-8/12">
                                <Dropdown id="country"
                                          v-model="country"
                                          name="country"
                                          :white="true"
                                          :disabled="step !== 1"
                                          :searchable="true"
                                          :title="countryOptions.find(x => x.value === country) ? countryOptions.find(x => x.value === country).label : ''"
                                          class="w-full md:w-auto min-w-40"
                                >
                                    <DropdownItem v-for="(c, i) in countryOptions"
                                                  :key="i"
                                                  :value="c.value"
                                                  :label="c.label"
                                    />
                                </Dropdown>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
                            <div class="flex relative w-full max-w-full md:ml-1/3 md:max-w-8/12">
                                <Field v-slot="{ field, errors }"
                                       v-model="model"
                                       vid="acceptPrivacy"
                                       rules="required"
                                       name="labelPrivacy"
                                >
                                    <input id="acceptPrivacy"
                                           v-bind="field"
                                           :value="true"
                                           type="checkbox"
                                           aria-required="true"
                                           :aria-invalid="errors.length > 0"
                                           :aria-describedby="errors.length > 0 ? 'acceptPrivacy-error' : ''"
                                           data-vv-scope="contact"
                                           class="absolute opacity-0 text-black-900 text-para-xs md:text-para-s"
                                           name="labelPrivacy"
                                    >
                                    <label class="inline-block relative pl-6 checkbox-label-calculator" for="acceptPrivacy" v-html="privacy" />
                                    <p v-if="errors.length > 0" id="acceptPrivacy-error" class="text-error text-para-xs mt-1 md:mt-2">
                                        {{ errorAcceptPrivacy }}
                                    </p>
                                </Field>
                            </div>
                        </div>

                        <!-- submit form -->
                        <button class="flex justify-end items-center group cursor-pointer appearance-none focus:outline-none"
                                @click.prevent="handleSubmit(submit)"
                        >
                            <span class="text-para-s font-semibold">{{ labelRequest }}</span>
                            <ArrowButton direction="right" class="w-10 h-10" />
                        </button>
                    </div>
                </form>
                <div v-else class="relative w-full py-11 flex flex-col">
                    <h4 v-if="title" class="font-semibold">
                        {{ title }}
                    </h4>
                    <div class="flex flex-col py-4">
                        <span v-if="httpError" class="text-para-s text-error text-center mt-6">{{ httpError }}</span>
                        <template v-else>
                            <span>{{ confirmation1 }}</span>
                            <span>{{ confirmation2 }}</span>
                        </template>
                    </div>
                    <!-- Back navigation -->
                    <div class="flex items-center cursor-pointer w-fit mt-4"
                         :class="[ step === 0 ? 'hidden md:block md:invisible' : 'visible' ]"
                         @click="step--"
                    >
                        <Icon name="chevron-left" class="w-3 h-3 text-black-300 stroke-current" />
                        <span class="text-black-600 text-para-xs ml-4">{{ labelBack }}</span>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import axios from 'axios';
import {Commodity, DutyFieldType, isaMillCalculation} from './calculations';
import {Step} from './albion/MultiStepForm.vue';
import Utils from '../../utils/Utils';
import MultiStepFormHeader from "./albion/MultiStepFormHeader.vue";
import CalculatorInput from "./CalculatorInput.vue";
import Icon from "../base/Icon.vue";
import ArrowButton from "../base/ArrowButton.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import {Field, Form} from "vee-validate";
import {DynamicsFieldMapping} from "../form/VueForm.vue";
import DynamicsUtils from "../../utils/DynamicsUtils";

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {Field, Form, DropdownItem, Dropdown, ArrowButton, Icon, CalculatorInput, MultiStepFormHeader},
    inject: ['$contextPath'],
    props: {
        labelTranslation: {type: Array, default: () => []},
        commodities: {type: Array as PropType<Array<Commodity>>, default: () => []},
        title: {type: String, required: true},
        subTitle: {type: String, required: true},
        intro: {type: String, required: true},
        privacy: {type: String, required: true},
        confirmation1: {type: String, required: true},
        confirmation2: {type: String, required: true},
        f80Min: {type: Number, required: true},
        f80Max: {type: Number, required: true},
        p80Min: {type: Number, required: true},
        p80Max: {type: Number, required: true},
        tphMin: {type: Number, required: true},
        tphMax: {type: Number, required: true},
        labelNext: {type: String, required: true},
        labelBack: {type: String, required: true},
        labelStep: {type: String, required: true},
        labelRequest: {type: String, required: true},
        labelFirstName: {type: String, required: true},
        labelLastName: {type: String, required: true},
        labelCompany: {type: String, required: true},
        labelEmail: {type: String, required: true},
        labelEmailPlaceholder: {type: String, required: true},
        labelCountry: {type: String, required: true},
        countries: {type: Array as PropType<Array<string>>, default: () => []},
        labelSendReport: {type: String, required: true},
        errorAcceptPrivacy: {type: String, required: true},
        sendReportDescription: {type: String},
        dynamicsSend: {type: Boolean, default: false},
        dynamicsFormId: {type: String},
        dynamicsApiUrl: {type: String},
        dynamicsLibUrl: {type: String},
        dynamicsFieldMappings: {type: Array as PropType<Array<DynamicsFieldMapping>>, default: () => []}
    },
    data() {
        return {
            step: 0,
            commodity: 'copper-molybdenum',
            f80: this.f80Min,
            p80: 0,
            throughput: this.tphMin,
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            country: '',
            recommendation: null,
            httpError: null,

            // f80Min – f80Max, 5 steps
            f80Values: new Array(Math.floor((this.f80Max - this.f80Min) / 5) + 1).fill(0).map((x, i) => i * 5 + this.f80Min),
            // p80Min – p80Max, 1 steps
            p80Values: new Array(Math.floor(this.p80Max - this.p80Min) + 1).fill(0).map((x, i) => i + this.p80Min),

            tphValues: new Array(Math.floor(this.tphMax - this.tphMin) + 1).fill(0).map((x, i) => i + this.tphMin),

            model: '',
            genericDynamicsFieldMappings: undefined
        };
    },
    computed: {
        DutyFieldType() {
            return DutyFieldType;
        },
        stepHeader(): Step[] {
            return [
                {
                    title: this.subTitle,
                    type: 'normal'
                },
                {
                    title: this.labelSendReport,
                    type: 'normal'
                }
            ];
        },
        showBackNavigation(): boolean {
            return (this.step > 0) && (this.step < this.stepHeader.length) && (this.stepHeader.length > 1);
        },
        countryOptions(): any[] {
            const result = [];
            if (this.countries) {
                this.countries.forEach(s => {
                    const keyValue = s.split(':');
                    if (keyValue.length === 2) {
                        result.push({ label: keyValue[0], value: keyValue[1] });
                    }
                });
            }
            return result;
        }
    },
    created(): void {
        if (this.dynamicsSend && this.dynamicsLibUrl) {
            Utils.addScript(this.dynamicsLibUrl);
        }
    },
    mounted(): void {
        DynamicsUtils.loadGenericFieldMappings().then((mappings) => this.genericDynamicsFieldMappings = mappings);
    },
    methods: {
        getIcon(errors: string[], passed: boolean): string {
            if (errors.length > 0) {
                return 'invalid';
            } else if (passed) {
                return 'valid';
            }
            return '';
        },
        translate(key): string {
            const translation = this.labelTranslation.find(x => x.key === key);
            if (translation) {
                return translation.value;
            }
            // missing translations throw errors that are hard to track down
            console.error(`key "${key}" not found`);
            return '';
        },
        /**
         * form submit (via next button or enter key)
         * go to next form step or send form
         */
        submit() {
            this.step++;
            if (this.step === 2) {
                // last step: send form
                if (this.dynamicsSend) {
                    // dynamics form capture
                    const form = this.$el.querySelector('form');

                    DynamicsUtils.submitDynamicsForm(
                        form,
                        this.genericDynamicsFieldMappings,
                        this.dynamicsFieldMappings,
                        this.dynamicsFormId,
                        this.dynamicsApiUrl,
                        this.doSend // callback which is called after submitting dynamics form
                    );
                } else {
                    // normal submit
                    this.doSend();
                }
            } else if (this.step > 0) {
                // make calculation with form values
                this.recommendation = isaMillCalculation(this.commodity, this.f80, this.p80, this.throughput);
            }
        },
        doSend() {
            const { firstName, lastName, company, email, country, recommendation } = this;
            const { commodity, f80, p80, throughput } = this;
            this.httpError = null;

            axios.post(`${this.$contextPath}/.rest/api/v1/mill-calculation`, {
                type: 'isa-mill',
                recommendation,
                inputs: {
                    commodity,
                    f80,
                    p80,
                    throughput
                },
                firstName,
                lastName,
                company,
                email,
                country
            }).catch(e => {
                this.httpError = (e.response && e.response.message) ? e.response.message : this.translate('request_error');
            });
        },
        back() {
            this.step = 0;
        }
    }
};
</script>
