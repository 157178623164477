import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "cursor-pointer text-black-900 mx-2 mb-4 mt-3 text-para-s flex items-center justify-start relative border border-black-200 rounded" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "absolute top-1/2 transform -translate-y-1/2 right-4 text-black-200" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "text-para-s text-black-400 mr-4 md:mr-3 xl:mr-4" }
const _hoisted_7 = { class: "w-3 h-3 md:w-2 md:h-2 xl:w-3 xl:h-3 text-black-600 flex items-center justify-start" }
const _hoisted_8 = { class: "pl-2" }
const _hoisted_9 = { class: "block text-para-s text-black-400 transition-colors group-hover:text-white group-focus:text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      modelValue: $data.currentSelection,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.currentSelection) = $event)),
      class: "w-full md:w-auto min-w-40 md:flex md:flex-auto",
      flex: true,
      "text-classes": "text-para-xs md:text-para-s h-4 md:h-6 text-black-600",
      title: $props.placeholder,
      "higher-menu": true,
      onDeletePressed: $options.unsetValue
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentSearch) = $event)),
            type: "text",
            placeholder: $props.searchLabel,
            class: "w-10/12 h-full py-2 px-2 md:py-2.5 placeholder:text-para-s focus:outline-none placeholder-improvedContrast"
          }, null, 8, _hoisted_3), [
            [_vModelText, $data.currentSearch]
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Icon, {
              name: "search",
              class: "stroke-current w-4 h-4"
            })
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sortedGroups(), (gValue, group, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            key: index,
            class: "mb-4"
          }, [
            _createElementVNode("div", {
              class: "px-4 w-full flex items-center justify-start mb-2 cursor-pointer",
              onClick: ($event: any) => ($options.toggleGroup(group))
            }, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(group), 1),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_Icon, {
                  name: "chevron-down",
                  class: _normalizeClass(["stroke-current w-full h-full transition-transform transform origin-center", { 'rotate-180': $options.isGroupOpen(group) }])
                }, null, 8, ["class"])
              ])
            ], 8, _hoisted_5),
            _createVNode(_component_CollapseTransition, null, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gValue, (item) => {
                    return _withDirectives((_openBlock(), _createBlock(_component_DropdownItem, {
                      key: item,
                      value: item,
                      label: item,
                      "show-label": false,
                      "text-classes": "pb-2 pt-2 group"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "label"])), [
                      [_vShow, $options.shouldItemRender(item)]
                    ])
                  }), 128))
                ], 512), [
                  [_vShow, $options.isGroupOpen(group)]
                ])
              ]),
              _: 2
            }, 1024)
          ])), [
            [_vShow, $options.shouldGroupRender(group)]
          ])
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onDeletePressed"]),
    _withDirectives(_createElementVNode("div", {
      class: "absolute w-3 h-full top-0 right-9 flex justify-center items-center text-black-500 cursor-pointer",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.unsetValue && $options.unsetValue(...args)))
    }, [
      _createVNode(_component_Icon, {
        name: "close",
        class: "stroke-current w-full"
      })
    ], 512), [
      [_vShow, $data.currentSelection]
    ])
  ]))
}