<template>
    <div class="flex flex-col overflow-x-auto pb-4 md:pb-0 px-4 md:px-0 -mx-4 md:mx-0">
        <table class="w-full text-para-xs md:text-para-s md:tracking-tight text-left">
            <thead class="border-b border-black-200">
            <tr>
                <th scope="col" class="py-2 px-1 md:py-2 md:px-3 xl:px-4" @click="sortBy('position')">
                    <div class="flex items-center font-semibold">
                        <div class="mr-1" :class="[{'text-primary-500': sortProperty === 'position'},{'text-black-600': sortProperty !== 'position'}]">
                            <Icon name="arrow-right"
                                  class="w-4 h-4 transform origin-center stroke-current"
                                  :class="[{'rotate-90': sortProperty === 'position' && !ascending },{'-rotate-90': (sortProperty === 'position' && ascending) || sortProperty !== 'position' }]"
                            />
                        </div>
                        <span>{{ labels.position }}</span>
                    </div>
                </th>
                <th scope="col" class="py-2 px-1 md:py-2 md:px-3 xl:px-4 font-semibold" v-if="showLocalisation">
                    <span>{{ labels.localisation }}</span>
                </th>
                <th v-for="(label, key, i) in addColumns"
                    :key="i"
                    scope="col"
                    class="py-2 px-1 md:py-2 md:px-3 xl:px-4 font-semibold"
                >
                    <span>{{ label }}</span>
                </th>
                <th scope="col" class="py-2 px-1 md:py-2 md:px-3 xl:px-4" @click="sortBy('date')">
                    <div class="flex items-center justify-end font-semibold">
                        <div class="mr-1" :class="[{'text-primary-500': sortProperty === 'date'},{'text-black-600': sortProperty !== 'date'}]">
                            <Icon name="arrow-right"
                                  class="w-4 h-4 transform origin-center stroke-current"
                                  :class="[{'rotate-90': sortProperty === 'date' && !ascending },{'-rotate-90': (sortProperty === 'date' && ascending) || sortProperty !== 'date' }]"
                            />
                        </div>
                        <span>{{ labels.deadline }}</span>
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="job in sortedJobs"
                :key="job.jobId"
                class="even:bg-black-50 hover:bg-primary-100 transition duration-100 ease-out cursor-pointer"
                @click="openCareer(job.jobId)"
            >
                <td class="py-2 px-1 md:py-2 md:px-3 xl:px-4">
                    {{ job.position }}
                </td>
                <td v-if="showLocalisation" class="py-2 px-1 md:py-2 md:px-3 xl:px-4">
                    {{ job.localisation }}
                </td>
                <td v-for="(label, key, i) in addColumns"
                    :key="i"
                    class="py-2 px-1 md:py-2 md:px-3 xl:px-4"
                    v-html="job[key] ? job[key] : ''"
                />
                <td class="py-2 px-1 md:py-2 md:px-3 xl:px-4 text-right">
                    {{ $filters.formatDate(job.date) }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Icon from "../base/Icon.vue";

export interface CareerListLabels {
    position: string;
    localisation: string;
    deadline: string;
}

export interface Job {
    date: string;
    description: string;
    header: string;
    introduces: string[];
    jobHighlight1: string;
    jobHighlight2: string;
    jobHighlight3: string;
    jobId: string;
    localisation: string;
    position: string;
    title: string;
    url: string;
}

export default {
    components: {Icon},
    props: {
        jobs: {type: Array as PropType<Array<Job>>, default: () => []},
        addColumns: {type: Object as PropType<Record<string, string>>, default: () => {}},
        labels: {type: Object as PropType<CareerListLabels>, default: () => {}},
        showLocalisation: {type: Boolean, default: false},
        detailPage: {type: String},
        feedId: {type: String}
    },
    data() {
        return {
            sortProperty: 'position',
            ascending: true
        };
    },
    computed: {
        sortedJobs(): Job[] {
            const sortedJobs: Job[] = [...this.jobs];
            sortedJobs.sort((j1, j2) => {
                if (this.sortProperty === 'date') {
                    return new Date(j1[this.sortProperty]).getTime() - new Date(j2[this.sortProperty]).getTime();
                } else if (j1[this.sortProperty]) {
                    return j1[this.sortProperty].localeCompare(j2[this.sortProperty]);
                }
                return 0;
            });
            if (!this.ascending) {
                sortedJobs.reverse();
            }
            return sortedJobs;
        }
    },
    methods: {
        sortBy(property: string) {
            if (property === this.sortProperty) {
                this.ascending = !this.ascending;
            } else {
                this.sortProperty = property;
                this.ascending = true;
            }
        },
        openCareer(jobId: string) {
            if (this.detailPage && this.feedId && jobId) {
                window.open(`${this.detailPage}?jobId=${jobId}&feedId=${this.feedId}`, '_self');
            }
        }
    }
};
</script>
