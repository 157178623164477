<template>
    <div class="group relative" :class="{'cursor-pointer': _showLightbox}">
        <img :class="imgClass"
             :src="src"
             :alt="alt"
             :title="title"
             :aria-labelledby="labelledBy"
             @click="setLightboxVisible(true)"
        >
        <GcLightbox v-if="_showLightbox"
                    v-model="lightboxVisible"
                    :src="src"
                    :caption="caption"
                    :alt-title="alt"
                    :content="content"
                    :hide-mobile-icon="hideMobileLightboxIcon"
        />
    </div>
</template>

<script lang="ts">
import breakpoints from '../../plugins/breakpoints';
import GcLightbox from "./GcLightbox.vue";

export default {
    components: {GcLightbox},
    props: {
        src: {type: String, required: true},
        content: {type: String},
        title: {type: String},
        labelledBy: {type: String},
        caption: {type: String},
        altTitle: {type: String},
        imgClass: {type: String, default: 'w-full h-full'},
        showCaption: {type: Boolean, default: false},
        showLightbox: {type: Boolean, default: false},
        hideMobileLightboxIcon: {type: Boolean, default: false}
    },
    emits: ['click'],
    data() {
        return {
            lightboxVisible: false
        };
    },
    computed: {
        alt(): string {
            return this.getCleanText(this.altTitle || this.title || this.caption || this.fileName);
        },
        _showLightbox(): boolean {
            return this.showLightbox && breakpoints.mdAndUp;
        },
        fileName(): string {
            if (this.src) {
                return this.src.split('/').pop();
            }
            return '';
        }
    },
    methods: {
        getCleanText(text: string): string {
            const el = document.createElement('div');
            el.innerHTML = text;
            return el.innerText;
        },
        setLightboxVisible(value: boolean): void {
            if (this._showLightbox) {
                this.lightboxVisible = value;
            }

            this.$emit('click');
        }
    }
};
</script>
