import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "w-full flex items-center justify-center pb-12 md:pb-16" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_scroll_down = _resolveDirective("scroll-down")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("a", {
      href: '#' + $props.toAnchor
    }, _cache[0] || (_cache[0] = [
      _createStaticVNode("<svg class=\"w-8 translate-x-2/4\" viewBox=\"0 0 33 57\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M11 42.5L15.7929 47.2929C16.1834 47.6834 16.8166 47.6834 17.2071 47.2929L22 42.5\" stroke=\"white\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><rect x=\"0.75\" y=\"0.75\" width=\"31.5\" height=\"55.5\" rx=\"15.75\" stroke=\"white\" stroke-width=\"1.5\"></rect><circle cx=\"16.5\" cy=\"15.5\" r=\"1.5\" fill=\"white\"></circle><circle cx=\"16.5\" cy=\"23.5\" r=\"1.5\" fill=\"white\"></circle><circle cx=\"16.5\" cy=\"31.5\" r=\"1.5\" fill=\"white\"></circle></svg>", 1)
    ]), 8, _hoisted_2)), [
      [_directive_scroll_down]
    ])
  ]))
}