import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute top-0 left-0" }
const _hoisted_2 = {
  viewBox: "0 0 50 50",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "w-full h-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex relative flex-shrink-0 justify-center items-center w-12 h-12 transition-all duration-500 transform origin-center", [$data.animate ? 'opacity-100 scale-100' : 'opacity-0 scale-30']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock("svg", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("path", {
          d: "M44 25C44 20.5634 42.4474 16.2666 39.6114 12.8547C36.7753 9.44287 32.8347 7.13105 28.4728 6.32011C24.1109 5.50918 19.6027 6.25027 15.73 8.4149C11.8572 10.5795 8.86408 14.0312 7.26953 18.1714C5.67499 22.3116 5.57959 26.8793 6.99988 31.0824C8.42017 35.2856 11.2666 38.8592 15.0456 41.1837C18.8246 43.5081 23.2979 44.4368 27.6899 43.8087C32.0818 43.1806 36.1155 41.0353 39.0915 37.7448",
          stroke: "white",
          class: "opacity-20"
        }, null, -1)),
        _cache[1] || (_cache[1] = _createElementVNode("path", {
          d: "M24.9412 48.9411C30.5453 48.9411 35.9728 46.9799 40.2826 43.3976C44.5923 39.8152 47.5125 34.8376 48.5368 29.3278C49.5611 23.818 48.625 18.1235 45.8908 13.2316C43.1565 8.3397 38.7965 4.55888 33.5668 2.54472C28.3371 0.530558 22.5674 0.410056 17.2581 2.20411C11.9489 3.99816 7.43484 7.59364 4.49872 12.3671C1.5626 17.1406 0.389539 22.7911 1.18294 28.3388",
          stroke: "white",
          class: "opacity-20"
        }, null, -1)),
        _createElementVNode("path", {
          class: _normalizeClass(["transition-all ease-out delay-300 transform track dasharray-28 duration-4000", [$data.animate ? 'dashoffset-0' : 'dashoffset-28']]),
          stroke: "white",
          d: "M44 25C44 20.5634 42.4474 16.2666 39.6114 12.8547C36.7753 9.44287 32.8347 7.13105 28.4728 6.32011C24.1109 5.50918 19.6027 6.25027 15.73 8.4149C11.8572 10.5795 8.86408 14.0312 7.26953 18.1714C5.67499 22.3116 5.57959 26.8793 6.99988 31.0824C8.42017 35.2856 11.2666 38.8592 15.0456 41.1837C18.8246 43.5081 23.2979 44.4368 27.6899 43.8087C32.0818 43.1806 36.1155 41.0353 39.0915 37.7448"
        }, null, 2),
        _createElementVNode("path", {
          class: _normalizeClass(["transition-all ease-out delay-300 transform track dasharray-32 duration-4000", [$data.animate ? 'dashoffset-0' : 'dashoffset-32']]),
          stroke: "white",
          d: "M24.9412 48.9411C30.5453 48.9411 35.9728 46.9799 40.2826 43.3976C44.5923 39.8152 47.5125 34.8376 48.5368 29.3278C49.5611 23.818 48.625 18.1235 45.8908 13.2316C43.1565 8.3397 38.7965 4.55888 33.5668 2.54472C28.3371 0.530558 22.5674 0.410056 17.2581 2.20411C11.9489 3.99816 7.43484 7.59364 4.49872 12.3671C1.5626 17.1406 0.389539 22.7911 1.18294 28.3388"
        }, null, 2)
      ]))
    ])
  ], 2))
}