<template>
    <div>
        <Teleport to="body">
            <dialog ref="dialogEL" class="w-full max-w-none h-full min-h-full m-0 p-0 backdrop:backdrop-filter backdrop:bg-opacity-0">
                <div class="relative flex items-center w-full h-14 md:h-20 text-para-xs transition-colors text-black-700 bg-white">
                    <div class="flex flex-grow pt-0.5 mx-4 md:mx-28">
                        <img class="header-logo max-h-nav-m md:max-h-nav max-w-logo-m md:max-w-none"
                             :src="logoPath"
                             :alt="logoAlt"
                        >
                    </div>
                    <button class="flex justify-center items-center w-14 h-14 md:w-20 md:h-20 text-white md:order-6 bg-primary-700 md:bg-black-900"
                            :aria-label="$globalLabels.close"
                            @click="hide"
                    >
                        <Icon name="close" class="w-4 h-4 stroke-current" />
                    </button>
                </div>

                <div class="flex flex-col h-full">
                    <TabHeader v-model="currentTab" class="mt-14 md:mt-20" :tabs="tabTitles" :component-uid="$.uid" />
                    <slot :current-tab="currentTab" :component-uid="$.uid" />
                </div>
            </dialog>
        </Teleport>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import TabHeader from '../base/TabHeader.vue';
import Icon from "../base/Icon.vue";

export default {
    components: {TabHeader, Icon},
    inject: ['$globalLabels'],
    props: {
        tabs: {type: Object as PropType<Record<string, string>>},
        region: {type: String, default: ''},
        opened: {type: Boolean, default: false},
        logoPath: {type: String, default: ''},
        logoAlt: {type: String, default: ''}
    },
    emits: ['hide'],
    data() {
        return {
            currentTab: 0
        };
    },
    computed: {
        tabTitles(): string[] {
            return Object.values(this.tabs);
        }
    },
    watch: {
        opened(newValue: boolean): void {
            if (newValue) {
                this.show();
            } else {
                this.hide();
            }
        }
    },
    created(): void {
        if (this.region && this.tabs[this.region]) {
            this.currentTab = Object.keys(this.tabs).indexOf(this.region);
        }
    },
    methods: {
        show(): void {
            document.body.style.overflow = 'hidden';

            if (this.$refs.dialogEL && !this.$refs.dialogEL.open) {
                this.$refs.dialogEL.showModal();
            }

            // make sure hide method is called when dialog is closed by pressing escape
            this.$refs.dialogEL.addEventListener('cancel', (ev) => {
                ev.preventDefault();
                this.hide(); // call our hide-method to close with delay and make sure transition is visible
            }, { once: true });
        },
        hide(): void {
            document.body.style.overflow = '';

            if (this.$refs.dialogEL.open) {
                // Timeout matches the animation time for it to fade.
                setTimeout(() => this.$refs.dialogEL.close(), 300);
            }

            this.$emit('hide');
        }
    }
};
</script>
