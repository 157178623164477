import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute left-0 top-0 w-full h-full table-fixed pointer-events-none -z-1" }
const _hoisted_3 = { class: "text-para-xs uppercase text-black-600 border-b border-black-900" }
const _hoisted_4 = ["colspan"]
const _hoisted_5 = { class: "text-para-s md:text-para-base" }
const _hoisted_6 = { class: "p-0 pb-2 opacity-0 min-h-10" }
const _hoisted_7 = { class: "w-full table-fixed" }
const _hoisted_8 = { class: "text-para-xs uppercase text-black-600 border-b border-black-900" }
const _hoisted_9 = {
  scope: "col",
  class: "font-semibold p-0 pb-2 text-right md:w-20"
}
const _hoisted_10 = { class: "flex justify-between" }
const _hoisted_11 = { class: "text-para-s md:text-para-base" }
const _hoisted_12 = { class: "p-0 pb-2" }
const _hoisted_13 = ["colspan"]
const _hoisted_14 = { class: "absolute left-full -top-1 text-para-xs tracking-normal bg-primary-50 rounded-xl whitespace-nowrap hidden group-hover:inline px-3 py-1 ml-2 z-1" }
const _hoisted_15 = { class: "absolute left-full -top-1 text-para-xs tracking-normal bg-black-50 rounded-xl whitespace-nowrap hidden group-hover:inline px-3 py-1 ml-2 z-1" }
const _hoisted_16 = { class: "text-para-xs text-right p-0 pb-2" }
const _hoisted_17 = { class: "flex flex-col md:flex-row md:justify-between md:items-center mt-2 md:mt-4" }
const _hoisted_18 = { class: "flex flex-col md:flex-row md:justify-end text-para-xs xl:ml-40" }
const _hoisted_19 = { class: "mr-1" }
const _hoisted_20 = { class: "font-semibold" }
const _hoisted_21 = { class: "font-semibold" }
const _hoisted_22 = { class: "flex gap-x-4 mt-4 md:mt-0" }
const _hoisted_23 = { class: "flex" }
const _hoisted_24 = { class: "text-para-xs font-semibold bg-primary-50 rounded-xl px-3 py-1" }
const _hoisted_25 = { class: "flex" }
const _hoisted_26 = { class: "text-para-xs font-semibold bg-black-50 rounded-xl px-3 py-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", _hoisted_3, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("th", {
              scope: "col",
              class: "w-24 md:w-40 p-0 pb-2"
            }, "‍", -1)),
            _createElementVNode("th", {
              colspan: $options.colspan,
              scope: "col",
              class: "p-0"
            }, null, 8, _hoisted_4),
            _cache[1] || (_cache[1] = _createElementVNode("th", {
              scope: "col",
              class: "p-0 md:w-20"
            }, null, -1))
          ])
        ]),
        _createElementVNode("tbody", _hoisted_5, [
          _createElementVNode("tr", null, [
            _cache[2] || (_cache[2] = _createElementVNode("td", { class: "p-0 h-2 md:h-4" }, null, -1)),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(11, (num) => {
              return _createElementVNode("td", {
                key: num,
                class: _normalizeClass(["border-l border-black-50 p-0", {'hidden md:table-cell': num % 2 === 0}])
              }, null, 2)
            }), 64))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              _createElementVNode("td", _hoisted_6, _toDisplayString(item.label), 1),
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(11, (num) => {
                return _createElementVNode("td", {
                  key: num,
                  class: _normalizeClass(["border-l border-black-50 p-0", {'hidden md:table-cell': num % 2 === 0}])
                }, null, 2)
              }), 64))
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("table", _hoisted_7, [
        _createElementVNode("thead", _hoisted_8, [
          _createElementVNode("tr", null, [
            _cache[4] || (_cache[4] = _createElementVNode("th", {
              scope: "col",
              class: "font-semibold p-0 pb-2 w-24 md:w-40"
            }, null, -1)),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (num) => {
              return _createElementVNode("th", {
                key: num,
                scope: "col",
                class: _normalizeClass(["font-semibold p-0 pb-2 text-left", {'hidden md:table-cell': num % 2 === 0}])
              }, _toDisplayString((num - 1) * $options.interval), 3)
            }), 64)),
            _createElementVNode("th", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("span", null, _toDisplayString($props.maxValue), 1),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "hidden md:inline" }, "PPB", -1))
              ])
            ])
          ])
        ]),
        _createElementVNode("tbody", _hoisted_11, [
          _cache[5] || (_cache[5] = _createElementVNode("tr", null, [
            _createElementVNode("td", { class: "h-2 md:h-4" })
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (item, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              _createElementVNode("td", _hoisted_12, _toDisplayString(item.label), 1),
              _createElementVNode("td", {
                colspan: $options.colspan,
                class: "p-0 pb-2"
              }, [
                _createElementVNode("div", {
                  class: "relative h-4 bg-primary-500 group",
                  style: _normalizeStyle($options.calcStyles(item.value5))
                }, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(item.value5.toFixed(2)) + " " + _toDisplayString($props.labels.min5Value), 1)
                ], 4),
                _createElementVNode("div", {
                  class: "relative h-4 bg-black-200 group",
                  style: _normalizeStyle($options.calcStyles(item.value60))
                }, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(item.value60.toFixed(2)) + " " + _toDisplayString($props.labels.hourlyValue), 1)
                ], 4)
              ], 8, _hoisted_13),
              _createElementVNode("td", _hoisted_16, [
                _createElementVNode("div", null, _toDisplayString(item.value5.toFixed(2)), 1),
                _createElementVNode("div", null, _toDisplayString(item.value60.toFixed(2)), 1)
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("span", _hoisted_19, _toDisplayString($props.labels.dataCollected) + ":", 1),
        _createElementVNode("span", null, [
          _createElementVNode("span", null, _toDisplayString($props.labels.min5Time) + ": ", 1),
          _createElementVNode("span", _hoisted_20, _toDisplayString($options._fiveMinTime) + " - ", 1),
          _createElementVNode("span", null, _toDisplayString($props.labels.hourlyTime) + ": ", 1),
          _createElementVNode("span", _hoisted_21, _toDisplayString($options._hourlyTime), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-1 h-6 bg-primary-500 md:h-full mr-2" }, null, -1)),
          _createElementVNode("div", _hoisted_24, _toDisplayString($props.labels.min5Value), 1)
        ]),
        _createElementVNode("div", _hoisted_25, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "w-1 h-6 bg-black-200 md:h-full mr-2" }, null, -1)),
          _createElementVNode("div", _hoisted_26, _toDisplayString($props.labels.hourlyValue), 1)
        ])
      ])
    ])
  ]))
}