<template>
    <Field v-slot="{ errors, field }"
           v-model="model[name]"
           :name="title"
           :rules="rules"
           as="div"
           mode="eager"
           :disabled="validationDisabled"
    >
        <div class="text-para-s font-semibold">
            <span>{{ title }}</span>
            <span v-if="required" aria-hidden="true"> *</span>
        </div>
        <Dropdown :id="name"
                  v-bind="field"
                  v-model="model[name]"
                  :name="name"
                  :title="placeholder"
                  :value="defaultValue"
                  :required="required"
                  :error="errors.length > 0"
                  :described-by="errors.length > 0 ? `${name}-error` : ''"
                  :white="true"
                  class="w-full min-w-40"
                  @update:model-value="openArea(model[name])"
        >
            <DropdownItem v-for="(label, value, index) in options"
                          :key="index"
                          :value="value"
                          :label="label"
            />
        </Dropdown>
        <CollapseTransition v-show="opened || editMode"
                            @after-enter="calcHeight"
                            @after-leave="calcHeight"
        >
            <slot />
        </CollapseTransition>
        <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-4">
            {{ errors[0] }}
        </span>
    </Field>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import CollapseTransition from "../base/CollapseTransition.vue";
import {Field} from "vee-validate";

export default {
    components: {Field, CollapseTransition, DropdownItem, Dropdown},
    inject: ['model', 'calcHeight', 'currentStep'],
    props: {
        title: {type: String, required: true},
        name: {type: String, required: true},
        placeholder: {type: String, default: ''},
        labels: {type: Array as PropType<Array<string>>, default: () => []},
        defaultValue: {type: String, default: ''},
        showAreaValues: {type: Array as PropType<Array<string>>, default: () => []},
        required: {type: Boolean, default: false},
        stepIndex: {type: Number, required: true},
        editMode: {type: Boolean, default: false}
    },
    data() {
        return {
            opened: false
        };
    },
    computed: {
        validationDisabled(): boolean {
            return this.currentStep !== this.stepIndex;
        },
        options(): Record<string, string> {
            const result: Record<string, string> = {};
            if (this.labels) {
                this.labels.forEach(s => {
                    const keyValue = s.split(':');
                    if (keyValue.length === 2) {
                        result[keyValue[1]] = keyValue[0];
                    }
                });
            }
            return result;
        },
        rules(): Record<string, unknown> {
            /* eslint-disable */
            return {
                required: this.required
            };
            /* eslint-enable */
        }
    },
    created(): void {
        if (!this.model[this.name] && this.defaultValue) {
            this.model[this.name] = this.defaultValue;
        }
        // set initial open/closed state
        if (this.model[this.name]) {
            this.opened = this.showAreaValues.includes(this.model[this.name] as string);
        }
    },
    methods: {
        openArea(value: string): void {
            this.opened = this.showAreaValues.includes(value);
        }
    }
};
</script>
