<template>
    <a class="cursor-pointer"
       :class="classes"
       :aria-label="ariaLabel"
       @click.stop="click"
    >
        <slot />
        <Overlay :url="targetUrl" @close="targetUrl=''" />
    </a>
</template>

<script lang="ts">

import Overlay from "../teaser/Overlay.vue";

export default {
    components: {Overlay},
    props: {
        url: {type: String},
        classes: {type: String},
        ariaLabel: {type: String}
    },
    data() {
        return {
            targetUrl: ''
        };
    },
    methods: {
        click(event: Event) {
            event.preventDefault();
            this.targetUrl = this.url;
        }
    }
};
</script>
