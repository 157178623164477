import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-para-s font-semibold mb-4" }
const _hoisted_2 = { class: "flex flex-col md:flex-row gap-x-6 gap-y-2" }
const _hoisted_3 = { class: "flex-grow" }
const _hoisted_4 = { class: "flex justify-between mt-px" }
const _hoisted_5 = { class: "text-para-xs text-black-500" }
const _hoisted_6 = { class: "text-para-xs text-black-500" }
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_VueSlider = _resolveComponent("VueSlider")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: $options.model[$props.name],
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($options.model[$props.name]) = $event)),
    class: "flex flex-col border border-black-200 rounded p-4 md:pr-28 mt-4 mb-8",
    rules: $options.rules,
    name: $props.title,
    as: "div",
    disabled: $options.validationDisabled
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("span", _hoisted_1, [
        _createTextVNode(_toDisplayString($props.title) + " ", 1),
        _cache[5] || (_cache[5] = _createElementVNode("span", { "aria-hidden": "true" }, "*", -1))
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NumberInput, _mergeProps(field, {
          modelValue: $options.model[$props.name],
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (($options.model[$props.name]) = $event)),
            $options.updateSliderValue
          ],
          class: "appearance-none text-para-lg font-semibold focus:outline-none w-full md:w-28",
          name: $props.name,
          "aria-required": "true",
          "aria-invalid": errors.length > 0,
          "aria-describedby": errors.length > 0 ? `${$props.name}-error` : '',
          options: $options.options
        }), null, 16, ["modelValue", "name", "aria-invalid", "aria-describedby", "options", "onUpdate:modelValue"]),
        _createElementVNode("div", _hoisted_3, [
          ($data.initialised)
            ? (_openBlock(), _createBlock(_component_VueSlider, {
                key: 0,
                modelValue: $data.sliderModel,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.sliderModel) = $event)),
                height: 2,
                min: $props.min,
                max: $props.max,
                adsorb: true,
                interval: $props.interval,
                "process-style": {backgroundColor: '#00928e'},
                "dot-style": {backgroundColor: '#00928e'},
                tooltip: "none",
                onChange: _cache[2] || (_cache[2] = (value) => $options.model[$props.name] = value),
                onDragging: _cache[3] || (_cache[3] = (value) => $options.model[$props.name] = value)
              }, null, 8, ["modelValue", "min", "max", "interval"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString($options._min) + " " + _toDisplayString($props.unit), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString($options._max) + " " + _toDisplayString($props.unit), 1)
          ])
        ])
      ]),
      (errors.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            id: `${$props.name}-error`,
            class: "text-error text-para-xs mt-4"
          }, _toDisplayString(errors[0]), 9, _hoisted_7))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "rules", "name", "disabled"]))
}