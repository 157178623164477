<template>
    <div class="relative overflow-hidden xl:overflow-visible" :class="{ 'overflow-visible': filteringOpen }">
        <div class="relative flex flex-wrap xl:flex-row overflow-hidden xl:overflow-visible"
             :class="{ 'overflow-visible': filteringOpen }"
        >
            <SocioEconomicMapDetail
                ref="detail"
                :section-data="sectionData"
                :section-disclaimer="sectionDisclaimer"
                :operations="operations"
                :region="currentRegion"
                :operation="currentOperation"
                :download-factsheet-label="translate('downloadFactsheet')"
                :operations-label="translate('operations')"
                :show-more-label="translate('showMore')"
                :show-less-label="translate('showLess')"
                :scroll-effect-level="scrollEffectLevel"
                @set-operation="setOperation"
            />
            <!-- Embedded Map -->
            <div v-show="showMap"
                 ref="map"
                 class="map relative w-full order-1 md:order-2 md:w-2/3 xl:w-3/4 h-socio-economic-map md:h-120 xl:h-180"
                 :style="specialMapClasses"
            >
                <SocioEconomicMapEmbed
                    v-if="filteredOperations"
                    :country="'australia'"
                    :current-region="currentRegion"
                    :current-operation="currentOperation"
                    :operations="filteredOperations"
                    :all-operations="operations"
                    :selected-marker="selectedMarker"
                    :region-option="regionOption"
                    @open-filtering="filteringOpen = true"
                    @marker-clicked="handleMarkerClicked"
                    @map-initialized="makeMapVisible"
                />
                <!-- Filter Button -->
                <button v-if="!filteringOpen"
                        type="button"
                        class="py-2 px-5 cursor-pointer rounded flex text-para-xs md:text-para-s items-center justify-center bg-black-900 text-white absolute top-5 left-5 md:right-auto md:left-8 md:bottom-auto md:top-8"
                        @click="openFiltering"
                >
                    <span class="mr-2">
                        {{ translate('filter') }} {{ currentRegion !== null || currentOperation !== null ? '(1)' : '' }}
                    </span>
                    <span class="w-4 h-5 text-white flex items-center justify-center">
                        <Icon name="filter" class="fill-current w-full h-full" />
                    </span>
                </button>
                <!-- Filter Menu -->
                <SocioEconomicMapFiltering
                    v-show="filteringOpen"
                    :operations="operations"
                    :regions="regions"
                    :current-region="currentRegion"
                    :current-operation="currentOperation"
                    :region-label="translate('region')"
                    :reset-filter-label="translate('resetFilter')"
                    :operation-label="translate('operation')"
                    :search-label="translate('search')"
                    :filter-by-label="translate('filterBy')"
                    @close-filtering="filteringOpen = false"
                    @set-region="setRegion"
                    @set-operation="setOperation"
                    @unset="unsetQuery"
                />
            </div>
        </div>
        <!-- Legend -->
        <SocioEconomicMapLegend
            :operations="operations"
            :label="translate('legend')"
        />
    </div>
</template>

<script lang="ts">
import {nextTick, PropType} from 'vue';
import Utils from '../../utils/Utils';
import SocioEconomicMapDetail from './SocioEconomicMapDetail.vue';
import SocioEconomicMapScrollEffect from './SocioEconomicMapScrollEffect';
import SocioEconomicMapUtils, {Operation, Region, SubOperation, SubRegion} from './SocioEconomicMapUtils';
import SocioEconomicMapLegend from "./SocioEconomicMapLegend.vue";
import SocioEconomicMapEmbed from "./SocioEconomicMapEmbed.vue";
import Icon from "../base/Icon.vue";
import SocioEconomicMapFiltering from "./SocioEconomicMapFiltering.vue";

export default {
    components: {SocioEconomicMapFiltering, Icon, SocioEconomicMapEmbed, SocioEconomicMapDetail, SocioEconomicMapLegend},
    props: {
        operations: {type: Array as PropType<Array<Operation>>, required: true},
        regions: {type: Array as PropType<Array<Region>>, required: true},
        sectionData: {type: Object as PropType<SubRegion | SubOperation>, default: () => {}},
        labelTranslation: {type: Object},
        sectionDisclaimer: {type: String}
    },
    data() {
        return {
            locations: null,
            currentRegion: null,
            currentOperation: null,
            selectedMarker: null,
            filteringOpen: false,
            scrollEffectLevel: '',
            specialMapClasses: {},
            showMap: false,
            regionOption: {
                lat: -25.958045,
                lng: 134.123247
            }
        };
    },
    computed: {
        filteredOperations(): Array<Operation> {
            // Case: Region is set.
            if (this.currentRegion !== null) {
                return SocioEconomicMapUtils.getOperationsFromRegion(this.currentRegion, this.operations);
            }
            // Case: Operation is set.
            if (this.currentOperation !== null) {
                return [{...SocioEconomicMapUtils.getParentOperation(this.currentOperation, this.operations), ...{items: [this.currentOperation]}}];
            }
            // Case: Neither a Region nor an Operation is set.
            return this.operations;
        },
        allSubOperations(): Array<SubOperation> {
            const result: Array<SubOperation> = [];
            this.operations.forEach((operation: Operation) => {
                operation.items.forEach(item => {
                    result.push(item);
                });
            });
            return result;
        }
    },
    mounted(): void {
        this.setupScrollEffect();
    },
    methods: {
        makeMapVisible() {
            this.showMap = true;
        },
        setupScrollEffect() {
            nextTick(() => {
                const effect = new SocioEconomicMapScrollEffect(
                    this.$refs.map as HTMLElement,
                    this.$refs.detail.$el as HTMLElement,
                    () => {
                        this.showMap = true;
                        this.specialMapClasses = {
                            position: 'relative'
                        };
                        this.$refs.detail.toggleOverlayOffset(0);
                        this.$refs.detail.setChevronDown(false);
                    },
                    () => {
                        this.showMap = true;
                        this.scrollEffectLevel = 'detailContainer';
                        this.specialMapClasses = {
                            position: 'fixed',
                            top: '56px',
                            left: 0
                        };
                        this.$refs.detail.toggleOverlayOffset((this.$refs.map as HTMLElement).clientHeight - 50);
                        this.$refs.detail.setChevronDown(false);
                    },
                    () => {
                        this.showMap = false;
                        this.$refs.detail.setChevronDown(true);
                    },
                    () => {
                        this.showMap = false;
                        this.$refs.detail.setChevronDown(false);
                    }
                );
                effect.handleScrollEffect();
            });
        },

        translate(key): string {
            const translation = this.labelTranslation.find(x => x.key === key);
            if (translation) {
                return translation.value;
            }
            return '';
        },

        openFiltering(): void {
            Utils.lockBodyScroll('md');
            this.filteringOpen = true;
        },

        handleMarkerClicked(marker: Location, operation: SubOperation) {
            if (this.currentOperation && this.currentOperation.uuid === operation.uuid) {
                // case: a selected operation's poi is clicked: deselect.
                this.unsetOperation();
            } else {
                // case: a poi of an unselected operation is clicked: select.
                this.setOperation(operation);
                this.selectedMarker = marker;
            }
        },

        setRegion(filter: SubRegion): void {
            this.unsetOperation();
            this.currentRegion = filter;
        },

        unsetRegion(): void {
            this.currentRegion = null;
        },

        setOperation(operation: SubOperation): void {
            this.unsetRegion();
            this.currentOperation = operation;
        },

        unsetOperation(): void {
            this.currentOperation = null;
            this.selectedMarker = null;
        },

        unsetQuery(): void {
            this.unsetRegion();
            this.unsetOperation();
        },

    }
};
</script>
