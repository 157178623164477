import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabHeader = _resolveComponent("TabHeader")!

  return (_openBlock(), _createElementBlock("div", {
    id: $props.anchor,
    class: "content-group"
  }, [
    (!$options.isAccordion())
      ? (_openBlock(), _createBlock(_component_TabHeader, {
          key: 0,
          modelValue: $data.currentIndex,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentIndex) = $event)),
          tabs: $data.titles,
          anchors: $data.anchors,
          "in-content-group": $props.inContentGroup,
          highlight: $props.highlight,
          narrow: $props.narrow,
          "component-uid": _ctx.$.uid
        }, null, 8, ["modelValue", "tabs", "anchors", "in-content-group", "highlight", "narrow", "component-uid"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({
            'container': !$props.inContentGroup && $options.isAccordion(),
            'overflow-x-auto overflow-y-hidden md:overflow-x-visible md:overflow-y-visible': $props.scrollableContent
        })
    }, [
      _createElementVNode("ul", {
        class: _normalizeClass({
                'border-t': $options.isAccordion(),
                'border-black-900': $options.isAccordion() && !$props.inContentGroup,
                'xl:w-2/3 mx-auto': $options.isAccordion() && !$props.inContentGroup && $props.narrow,
                'border-black-500': $options.isNestedAccordion(),
            })
      }, [
        _renderSlot(_ctx.$slots, "default", {
          index: $data.currentIndex,
          type: $props.type,
          showAll: $props.showAll,
          itemsLength: $props.itemsLength,
          componentUid: _ctx.$.uid
        })
      ], 2)
    ], 2)
  ], 8, _hoisted_1))
}