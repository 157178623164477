<template>
    <div class="read-more mb-6 md:mb-8" :class="{ inactive: !opened }">
        <CollapseTransition v-show="opened">
            <div>
                <slot />
            </div>
        </CollapseTransition>
        <span class="cursor-pointer" @click="opened = !opened">{{ label }}</span>
    </div>
</template>

<script lang="ts">
import CollapseTransition from "./CollapseTransition.vue";

export default {
    components: { CollapseTransition },
    props: {
        showMore: { type: String },
        showLess: { type: String },
    },
    data() {
        return {
            opened: false,
        };
    },
    computed: {
        label(): string {
            return this.opened ? this.showLess : this.showMore;
        },
    },
};
</script>
