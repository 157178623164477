import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-heading-2 md:text-heading-1 mt-12 md:mt-32" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "font-semibold text-para-s md:text-para-xl mt-10 md:mt-14 mb-4 md:mb-2" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButtons = _resolveComponent("ToggleButtons")!
  const _component_GolfEventPersonalDataStep = _resolveComponent("GolfEventPersonalDataStep")!
  const _component_GolfEventParticipationStep = _resolveComponent("GolfEventParticipationStep")!
  const _component_GolfEventTravelDataStep = _resolveComponent("GolfEventTravelDataStep")!
  const _component_GolfEventSummary = _resolveComponent("GolfEventSummary")!

  return ($options.currentStep === 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString($props.labels.registration), 1),
        _createElementVNode("p", {
          class: "text-para-s md:text-para-xl mt-4 md:mt-2",
          innerHTML: $props.labels.registrationDescription
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, _toDisplayString($props.labels.willParticipate) + ":", 1),
        _createVNode(_component_ToggleButtons, {
          modelValue: $data.yesNoModel,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.yesNoModel) = $event)),
          items: { yes: $props.labels.yes, no: $props.labels.no },
          grow: "",
          onYes: $options.onParticipate,
          onNo: $options.onCancel
        }, null, 8, ["modelValue", "items", "onYes", "onNo"])
      ]))
    : ($options.currentStep === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_GolfEventPersonalDataStep, { labels: $props.labels }, null, 8, ["labels"])
        ]))
      : ($options.currentStep === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_GolfEventParticipationStep, { labels: $props.labels }, null, 8, ["labels"])
          ]))
        : ($options.currentStep === 3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_GolfEventTravelDataStep, { labels: $props.labels }, null, 8, ["labels"])
            ]))
          : ($options.currentStep === 4)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_GolfEventSummary, { labels: $props.labels }, null, 8, ["labels"])
              ]))
            : _createCommentVNode("", true)
}