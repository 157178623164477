import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-4 xl:mb-7" }
const _hoisted_2 = {
  key: 0,
  class: "flex md:flex-row md:items-center flex-col justify-end px-0 py-0 gap-y-2"
}
const _hoisted_3 = { class: "text-para-xs font-semibold mr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ArticleGrid = _resolveComponent("ArticleGrid")!
  const _component_GcButton = _resolveComponent("GcButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      ($props.filterByYear || $props.filterByTag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString($props.labels.filter), 1),
            ($props.filterByYear)
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: "year",
                  modelValue: $data.currentFilters.year,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentFilters.year) = $event)),
                  class: "w-full md:w-auto min-w-40 md:mr-2 last:mr-0 mb-1 md:mb-0",
                  title: $props.labels.yearFilter,
                  "higher-menu": true
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.years, (year, i) => {
                      return (_openBlock(), _createBlock(_component_DropdownItem, {
                        key: i,
                        label: year,
                        value: year
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "title"]))
              : _createCommentVNode("", true),
            ($props.filterByTag)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.categories, (tags, category, index) => {
                  return (_openBlock(), _createBlock(_component_Dropdown, {
                    key: index,
                    modelValue: $data.currentFilters[category],
                    "onUpdate:modelValue": ($event: any) => (($data.currentFilters[category]) = $event),
                    class: "w-full md:w-auto min-w-40 md:mr-2 last:mr-0 mb-1 md:mb-0",
                    title: category,
                    "higher-menu": true
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tags, (tag, i) => {
                        return (_openBlock(), _createBlock(_component_DropdownItem, {
                          key: i,
                          label: tag.tag,
                          value: tag.uuid
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "title"]))
                }), 128))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ArticleGrid, {
      articles: $data.shownArticles,
      "show-images": $props.showImages,
      highlight: $props.highlight,
      labels: $props.labels
    }, null, 8, ["articles", "show-images", "highlight", "labels"]),
    ($data.hasMore)
      ? (_openBlock(), _createBlock(_component_GcButton, {
          key: 0,
          class: "font-semibold mx-auto mt-8",
          label: $props.labels.readMore,
          secondary: true,
          onClick: $options.loadMore
        }, null, 8, ["label", "onClick"]))
      : _createCommentVNode("", true)
  ]))
}