import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative w-full bg-white flex flex-col xl:w-1/4 xl:h-180 xl:overflow-x-hidden xl:shadow-2xl z-10" }
const _hoisted_2 = { class: "relative w-full flex flex-wrap items-center justify-start p-4 md:mb-6 xl:mb-0 md:px-10 md:py-5 xl:p-6" }
const _hoisted_3 = { class: "w-full flex flex-col md:flex-row xl:flex-col" }
const _hoisted_4 = { class: "w-4 h-4" }
const _hoisted_5 = { class: "ml-2 block text-para-xs font-semibold" }
const _hoisted_6 = {
  class: "w-full flex flex-col md:flex-row xl:flex-col h-auto xl:h-full overflow-auto md:px-6 xl:px-0",
  tabindex: "-1"
}
const _hoisted_7 = { class: "w-full flex items-center justify-start mb-2 last:mb-0" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "w-4 h-4" }
const _hoisted_10 = { class: "ml-2 block font-semibold text-para-xs" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "w-4 h-4" }
const _hoisted_13 = { class: "ml-2 block text-para-xs" }
const _hoisted_14 = { class: "flex flex-col overflow-hidden max-h-full" }
const _hoisted_15 = { class: "font-semibold text-para-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInputDropdown = _resolveComponent("SearchInputDropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.searchOpen = true)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => ($data.searchOpen = true), ["enter"]))
        }, [
          _createVNode(_component_SearchInputDropdown, {
            class: "cursor-pointer mb-4 md:mb-0 md:mr-4 xl:mb-4 xl:mr-0",
            placeholder: $props.labelSearch,
            "tab-index": 0,
            readonly: ""
          }, null, 8, ["placeholder"])
        ], 32),
        _createElementVNode("button", {
          class: "flex items-center",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.selectAllCategories && $options.selectAllCategories(...args)))
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_Checkbox, {
              class: "w-full h-full rounded-sm border border-black-400",
              model: $data.selectedCategories.length < 1 && !$data.searched,
              "icon-styles": { stroke: 'white' }
            }, null, 8, ["model"])
          ]),
          _createElementVNode("span", _hoisted_5, _toDisplayString($props.labelShowAll), 1)
        ])
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "absolute bottom-0 left-0 h-px w-screen bg-black-100 xl:block xl:w-full" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.categories, (category, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "relative flex-shrink-0 w-full p-4 md:pb-8 md:pt-0 xl:p-6 md:w-4/12 xl:w-full"
        }, [
          _createElementVNode("ul", {
            class: "w-full flex flex-wrap pl-3 border-l-2",
            style: _normalizeStyle({borderColor: category.color})
          }, [
            _createElementVNode("li", _hoisted_7, [
              _createElementVNode("button", {
                class: "flex items-center justify-center",
                onClick: ($event: any) => ($data.selectedCategories.includes(category) ? $options.deselectCategory(category) : $options.selectCategory(category))
              }, [
                _createElementVNode("span", _hoisted_9, [
                  _createVNode(_component_Checkbox, {
                    class: "w-full h-full rounded-sm border border-black-400",
                    model: $data.selectedCategories.includes(category),
                    "checked-styles": { backgroundColor: category.color },
                    "icon-styles": { stroke: 'white', borderColor: category.color }
                  }, null, 8, ["model", "checked-styles", "icon-styles"])
                ]),
                _createElementVNode("span", _hoisted_10, _toDisplayString(category.label), 1)
              ], 8, _hoisted_8)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.subCategories, (subCategory, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                class: "w-full flex items-center justify-start mb-2 last:mb-0"
              }, [
                _createElementVNode("button", {
                  class: "flex items-center justify-center",
                  onClick: ($event: any) => ($data.selectedCategories.includes(subCategory) ? $options.deselectCategory(subCategory) : $options.selectCategory(subCategory))
                }, [
                  _createElementVNode("span", _hoisted_12, [
                    _createVNode(_component_Checkbox, {
                      class: "w-4 h-4 rounded-sm border border-black-400",
                      model: $data.selectedCategories.includes(subCategory),
                      "checked-styles": { backgroundColor: category.color },
                      "icon-styles": { stroke: 'white', borderColor: category.color }
                    }, null, 8, ["model", "checked-styles", "icon-styles"])
                  ]),
                  _createElementVNode("span", _hoisted_13, _toDisplayString(subCategory.label), 1)
                ], 8, _hoisted_11)
              ]))
            }), 128))
          ], 4),
          _createElementVNode("hr", {
            class: _normalizeClass(["absolute bottom-0 left-0 h-px w-screen md:hidden bg-black-100 xl:w-full", {'xl:hidden' : index === Object.keys($props.categories)[Object.keys($props.categories).length - 1], 'xl:block': index !== Object.keys($props.categories)[Object.keys($props.categories).length - 1]}])
          }, null, 2)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute w-full h-full bg-white transition-all duration-500 ease-out px-4 md:px-10 xl:px-6 pb-4 md:pb-10 xl:pb-6 pt-8 md:pt-6 xl:pt-12", {'left-0 -top-full xl:-left-full xl:top-0': !$data.searchOpen, 'left-0 top-0': $data.searchOpen}])
    }, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", {
          class: "flex gap-2 items-center cursor-pointer mb-3",
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($data.searchOpen = false))
        }, [
          _createVNode(_component_Icon, {
            name: "chevron-left",
            class: "w-2.5 h-2.5 stroke-current"
          }),
          _createElementVNode("span", _hoisted_15, _toDisplayString($props.labelBack), 1)
        ]),
        _createVNode(_component_SearchInputDropdown, {
          entries: $props.searchEntries,
          placeholder: $props.labelSearch,
          focus: $data.searchOpen,
          "tab-index": $data.searchOpen ? 0 : -1,
          onSelected: $options.handleSearch
        }, null, 8, ["entries", "placeholder", "focus", "tab-index", "onSelected"])
      ])
    ], 2)
  ]))
}