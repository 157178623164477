<template>
    <div class="w-full flex items-center justify-center pb-12 md:pb-16">
        <a v-scroll-down :href="'#' + toAnchor">
            <svg class="w-8 translate-x-2/4"
                 viewBox="0 0 33 57"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M11 42.5L15.7929 47.2929C16.1834 47.6834 16.8166 47.6834 17.2071 47.2929L22 42.5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                />
                <rect x="0.75"
                      y="0.75"
                      width="31.5"
                      height="55.5"
                      rx="15.75"
                      stroke="white"
                      stroke-width="1.5"
                />
                <circle cx="16.5"
                        cy="15.5"
                        r="1.5"
                        fill="white"
                />
                <circle cx="16.5"
                        cy="23.5"
                        r="1.5"
                        fill="white"
                />
                <circle cx="16.5"
                        cy="31.5"
                        r="1.5"
                        fill="white"
                />
            </svg>
        </a>
    </div>
</template>

<script lang="ts">

export default {
    props: {
        toAnchor: {type: String, required: true, default: ''}
    }
};
</script>
