<template>
    <Transition name="fade">
        <div class="fixed top-0 left-0 w-screen h-screen bg-white p-5 text-para-s z-9000 md:z-0
                    md:absolute md:right-auto md:left-5 md:bottom-auto md:top-5 md:w-11/12
                    md:max-w-160 md:max-h-10/12 md:h-auto md:flex md:flex-nowrap md:rounded
                    md:shadow-2xl xl:max-w-none xl:w-11/12"
        >
            <h4 class="text-black-800 py-3 md:mr-3">
                {{ filterByLabel }}
            </h4>
            <button class="md:hidden w-full text-primary-500 mb-6" @click="unsetQuery">
                {{ resetFilterLabel }}
            </button>
            <!-- Filters Dropdown -->
            <SocioEconomicMapSelection
                :groups="regions"
                :scope="'Region'"
                :current-query="currentQuery"
                :current-region="currentRegion"
                :current-operation="currentOperation"
                :search-label="searchLabel"
                :placeholder="regionLabel"
                @set-region="setRegion"
                @unset="unsetQuery"
            />
            <!-- Operations Dropdown -->
            <SocioEconomicMapSelection
                :groups="operations"
                :scope="'Operation'"
                :current-query="currentQuery"
                :current-region="currentRegion"
                :current-operation="currentOperation"
                :search-label="searchLabel"
                :placeholder="operationLabel"
                @set-operation="setOperation"
                @unset="unsetQuery"
            />
            <button type="button"
                    class="md:hidden absolute top-5 right-5 w-4 h-4 text-black-500 cursor-pointer"
                    :aria-label="$globalLabels.close"
                    @click="closeFiltering"
            >
                <Icon name="close" class="w-full h-full stroke-current" />
            </button>
            <button type="button"
                    class="hidden md:flex items-center justify-start ml-4 text-black-500 cursor-pointer"
                    :aria-label="$globalLabels.close"
                    @click="closeFiltering"
            >
                <Icon name="double-chevron-left" class=" w-4 h-4 stroke-current" />
            </button>
        </div>
    </Transition>
</template>

<script lang="ts">
import { Operation, Region, SubOperation, SubRegion } from './SocioEconomicMapUtils';
import Utils from '../../utils/Utils';
import { PropType } from "vue";
import SocioEconomicMapSelection from "./SocioEconomicMapSelection.vue";
import Icon from "../base/Icon.vue";

export default {
    components: {Icon, SocioEconomicMapSelection},
    inject: ['$globalLabels'],
    props: {
        regions: {type: Array as PropType<Array<Region>>, default: () => ([])},
        operations: {type: Array as PropType<Array<Operation>>, default: () => ([])},
        currentOperation: {type: Object as PropType<Operation>, default: null},
        currentRegion: {type: Object as PropType<Region>, default: null},
        regionLabel: {type: String, default: ''},
        resetFilterLabel: {type: String, default: ''},
        operationLabel: {type: String, default: ''},
        searchLabel: {type: String, default: ''},
        filterByLabel: {type: String, default: ''}
    },
    emits: ['closeFiltering', 'unset', 'setRegion', 'setOperation'],
    data() {
        return {
            currentQuery: null
        };
    },
    watch: {
        async currentOperation() {
            if (this.currentOperation !== null) {
                this.currentQuery = 'Operation';
            } else if (this.currentRegion === null) {
                this.currentQuery = null;
            }
        },
        async currentRegion() {
            if (this.currentRegion !== null) {
                this.currentQuery = 'Region';
            } else if (this.currentOperation === null) {
                this.currentQuery = null;
            }
        }
    },
    methods: {
        closeFiltering() {
            Utils.unlockBodyScroll('md');
            this.$emit('closeFiltering');
        },

        setRegion(region: SubRegion) {
            this.$emit('setRegion', region);
            this.currentQuery = 'Region';
        },
        setOperation(operation: SubOperation) {
            this.$emit('setOperation', operation);
            this.currentQuery = 'Operation';
        },
        unsetQuery() {
            this.currentQuery = null;
            this.$emit('unset');
        }
    }
};
</script>
