<template>
    <figure :class="{'cursor-pointer': _showLightbox}" :aria-labelledby="labelledBy">
        <picture class="w-full h-full">
            <source v-for="(src, index) in sources" :key="index" :media="src.media" :srcset="src.srcset">
            <img :class="imgClass"
                 loading="lazy"
                 :src="originalUrl"
                 :alt="alt"
                 :title="title"
            >
        </picture>
        <figcaption v-if="showCaption">{{ caption }}</figcaption>
        <GcLightbox v-if="_showLightbox"
                    v-model="lightboxVisible"
                    :src="wideUrl"
                    :caption="caption"
                    :alt-title="alt"
                    :hide-mobile-icon="hideMobileLightboxIcon"
        />
    </figure>
</template>

<script lang="ts">
import GcLightbox from './GcLightbox.vue';
import breakpoints from '../../plugins/breakpoints';
import {PropType} from "vue";

export default {
    components: {GcLightbox},
    props: {
        urls: {type: Object as PropType<Record<string, string>>, required: true},
        title: {type: String},
        labelledBy: {type: String},
        caption: {type: String},
        altTitle: {type: String},
        imgClass: {type: String, default: 'w-full h-full object-cover'},
        variationMobile: {type: String, default: 'sm'},
        variationTablet: {type: String, default: 'md'},
        variationDesktop: {type: String, default: 'lg'},
        variationWide: {type: String, default: 'xl'},
        variationOriginal: {type: String, default: 'original'},
        showCaption: {type: Boolean, default: false},
        showWide: {type: Boolean, default: false},
        showLightbox: {type: Boolean, default: false},
        hideMobileLightboxIcon: {type: Boolean, default: false}
    },
    data() {
        return {
            lightboxVisible: false
        };
    },
    computed: {
        alt(): string {
            return this.getCleanText(this.altTitle || '');
        },
        _showLightbox(): boolean {
            return this.showLightbox && breakpoints.mdAndUp;
        },
        renderWide(): boolean {
            return this.showWide && !!this.urls[this.variationWide];
        },
        sources(): Record<string, string>[] {
            const sources = [
                {
                    media: '(max-width: 600px)',
                    srcset: this.urls[this.variationMobile]
                },
                {
                    media: '(max-width: 1024px)',
                    srcset: this.urls[this.variationTablet]
                },
                {
                    media: this.renderWide ? '(max-width: 1280px)' : undefined,
                    srcset: this.urls[this.variationDesktop]
                }
            ];

            if (this.renderWide) {
                sources.push({
                    media: undefined,
                    srcset: this.urls[this.variationWide]
                });
            }

            return sources;
        },
        fileName(): string {
            if (this.urls && this.urls.high) {
                return this.urls.high.split('/').pop();
            }
            return '';
        },
        originalUrl(): string {
            return this.urls[this.variationOriginal];
        },
        wideUrl(): string {
            return this.urls[this.variationWide];
        }
    },
    methods: {
        getCleanText(text: string): string {
            const el = document.createElement('div');
            el.innerHTML = text;
            return el.innerText;
        },
        setLightboxVisible(value: boolean): void {
            if (this._showLightbox) {
                this.lightboxVisible = value;
            }
        }
    }
};
</script>
