import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute top-0 left-0 w-full h-full perspective-1000" }
const _hoisted_2 = { class: "absolute top-0 left-0 bottom-0 right-0 backface-hidden z-2 rotate-y-180" }
const _hoisted_3 = { class: "relative w-full h-full flex justify-center items-center" }
const _hoisted_4 = { class: "absolute left-0 bottom-0 flex gap-4 items-center p-4" }
const _hoisted_5 = { class: "absolute top-0 left-0 bottom-0 right-0 backface-hidden z-3 bg-black-50 transform rotate-0" }
const _hoisted_6 = { class: "relative w-full h-full flex justify-center items-center px-4 xl:px-6 pt-4 xl:pt-6 pb-16" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "absolute left-4 bottom-4 right-4 flex justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResponsiveImage = _resolveComponent("ResponsiveImage")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["relative", {'pt-16/9': $props.rendition === 'landscape', 'pt-3/4': $props.rendition === 'portrait'}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["relative w-full h-full preserve-3d transition-transform duration-500 ease-in-out cursor-pointer group/1", {'rotate-y-180': !$data.flipped}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.flipped = !$data.flipped))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["absolute top-0 left-0 bottom-0 right-0", {
                                 'bg-gradient-to-b from-transparent to-black-900': $props.showGradient,
                                 'via-transparent opacity-80': $props.rendition === 'portrait',
                                 'opacity-65': $props.rendition === 'landscape',
                             }])
            }, null, 2),
            _createVNode(_component_ResponsiveImage, {
              class: "w-full h-full",
              urls: $props.imageUrls
            }, null, 8, ["urls"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex flex-shrink-0 justify-center items-center bg-white rounded-full border border-black-500 group-hover/1:border-primary-500", {'w-8 h-8': $props.rendition === 'landscape', 'w-12 h-12': $props.rendition === 'portrait'}])
              }, [
                _createVNode(_component_Icon, {
                  name: "flip",
                  class: _normalizeClass(["text-black-500 group-hover/1:text-primary-500 fill-current", {'w-4 h-4': $props.rendition === 'landscape', 'w-6 h-6': $props.rendition === 'portrait'}])
                }, null, 8, ["class"])
              ], 2),
              _createElementVNode("span", {
                class: _normalizeClass(["text-white line-clamp-2", {'text-para-xs': $props.rendition === 'landscape'}])
              }, _toDisplayString($props.title), 3)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass(["text-center text-para-s xl:text-para-base max-w-narrow-content", {'line-clamp-4 xl:line-clamp-5 max-h-26 xl:max-h-32': $props.rendition === 'landscape', 'line-clamp-15 max-h-90': $props.rendition === 'portrait'}]),
              innerHTML: $props.description
            }, null, 10, _hoisted_7),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex justify-center items-center bg-white rounded-full border border-black-500 group-hover/1:border-primary-500", {'w-8 h-8': $props.rendition === 'landscape', 'w-12 h-12': $props.rendition === 'portrait'}])
              }, [
                _createVNode(_component_Icon, {
                  name: "flip",
                  class: _normalizeClass(["text-black-500 group-hover/1:text-primary-500 fill-current", {'w-4 h-4': $props.rendition === 'landscape', 'w-6 h-6': $props.rendition === 'portrait'}])
                }, null, 8, ["class"])
              ], 2),
              _renderSlot(_ctx.$slots, "link")
            ])
          ])
        ])
      ], 2)
    ])
  ], 2))
}