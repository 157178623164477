<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9333333333333333s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(24 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8666666666666667s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(48 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(72 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7333333333333333s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(96 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(120 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(144 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5333333333333333s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(168 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4666666666666667s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(192 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(216 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(240 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.26666666666666666s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(264 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(288 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.13333333333333333s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(312 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.06666666666666667s" repeatCount="indefinite" />
        </rect>
        <rect x="47.5" y="23" rx="2.5" ry="6" width="5" height="12" transform="rotate(336 50 50)">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" />
        </rect>
    </svg>
</template>

<script lang="ts">

export default {};
</script>
