import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "min-w-full text-para-xs md:text-para-s" }
const _hoisted_2 = {
  key: 0,
  class: "border-b border-black-200"
}
const _hoisted_3 = {
  key: 0,
  scope: "col",
  class: "w-10 p-2"
}
const _hoisted_4 = {
  key: 1,
  scope: "col",
  class: "w-10 py-2 pl-1 pr-2 xl:pr-4"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "w-0"
}
const _hoisted_7 = ["data-title", "aria-label", "onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["onKeyup"]
const _hoisted_10 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_11 = {
  key: 1,
  class: "hidden md:block uppercase text-black-600 whitespace-nowrap"
}
const _hoisted_12 = ["href", "aria-label", "target"]
const _hoisted_13 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_14 = {
  key: 1,
  class: "hidden md:block uppercase text-black-600 whitespace-nowrap"
}
const _hoisted_15 = { key: 3 }
const _hoisted_16 = ["href", "aria-label", "target"]
const _hoisted_17 = ["aria-label"]
const _hoisted_18 = {
  key: 0,
  class: "absolute left-0 right-0 bottom-4 flex justify-center"
}
const _hoisted_19 = {
  key: 1,
  class: "fixed left-0 bottom-0 w-full bg-white shadow-top-sm z-9000"
}
const _hoisted_20 = { class: "container" }
const _hoisted_21 = { class: "flex flex-col md:flex-row justify-between items-center gap-4 w-full xl:w-2/3 mx-auto py-4" }
const _hoisted_22 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_GcButton = _resolveComponent("GcButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'pb-20 md:pb-22': $options.hasMore})
  }, [
    _createElementVNode("table", _hoisted_1, [
      ($options.opts.showHeaders)
        ? (_openBlock(), _createElementBlock("thead", _hoisted_2, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.opts.columns, (column, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                  (column.key === 'check')
                    ? (_openBlock(), _createElementBlock("th", _hoisted_3))
                    : (column.key === 'download')
                      ? (_openBlock(), _createElementBlock("th", _hoisted_4))
                      : (column.key === 'tagCategories')
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(column.categories, (cat, index) => {
                            return (_openBlock(), _createElementBlock("th", {
                              key: index,
                              scope: "col",
                              class: "whitespace-nowrap text-left p-2"
                            }, [
                              _createElementVNode("span", null, _toDisplayString(cat), 1)
                            ]))
                          }), 128))
                        : (_openBlock(), _createElementBlock("th", {
                            key: 3,
                            scope: "col",
                            class: _normalizeClass('whitespace-nowrap py-2 ' + (i === 0 ? 'pl-1 xl:pl-3 pr-0' : i === $options.opts.columns.length - 1 ? 'pl-1 pr-2 xl:pr-4' : 'pl-1 pr-2')),
                            onClick: ($event: any) => ($options.sortBy(column.key !== 'format' ? column.key : 'size'))
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["flex items-center gap-1", $options.tableHeaderStyle(column.key)])
                            }, [
                              ($props.sortingEnabled)
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: "arrow-right",
                                    class: _normalizeClass(["w-4 h-4 transform origin-center stroke-current", $options.getIconClasses(column.key !== 'format' ? column.key : 'size')])
                                  }, null, 8, ["class"]))
                                : _createCommentVNode("", true),
                              (!$props.sortingEnabled)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                                : _createCommentVNode("", true),
                              _createElementVNode("span", null, _toDisplayString(column.label), 1)
                            ], 2)
                          ], 10, _hoisted_5))
                ], 64))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sorted, (file, i) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `${_ctx.$.uid}_file#${i}`,
            "data-title": file.link.split('/').pop(),
            "aria-label": file.link.split('/').pop(),
            class: "even:bg-black-50 hover:bg-primary-100 focus-within:bg-primary-100 transition duration-100 ease-out group cursor-pointer",
            onClick: ($event: any) => ($options.rowClick(file.id, $event))
          }, [
            ((i < ($data.currentPage * $options.opts.batchSize)) || ($options.opts.batchSize <= 0))
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($options.opts.columns, (column, i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (column.key === 'check')
                      ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["relative ml-4 pr-2 text-black-200 group-hover:text-primary-500 group-focus:text-primary-500", {'text-primary-500': $options.isSelected(file.id)}]),
                            onKeyup: _withKeys(($event: any) => ($options.multiDownloadsActive ? $options.toggleSelectedForDownload(file.id) : null), ["enter"])
                          }, [
                            _createVNode(_component_Icon, {
                              name: $options.isSelected(file.id) ? 'checkbox-active' : 'checkbox',
                              tabindex: $options.multiDownloadsActive ? 0 : -1,
                              class: "w-3 h-3 stroke-current"
                            }, null, 8, ["name", "tabindex"])
                          ], 42, _hoisted_9)
                        ]))
                      : (column.key === 'format' && ($options.opts.showIcon || $options.opts.showSize))
                        ? (_openBlock(), _createElementBlock("td", {
                            key: `${_ctx.$.uid}_${column.key}_${i}`
                          }, [
                            ($options.multiDownloadsActive)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: _normalizeClass(["flex items-center whitespace-nowrap md:whitespace-normal py-0 pl-0 pr-2 xl:pr-4", {'md:pl-3': !$options.opts.showIcon}])
                                }, [
                                  ($options.opts.showIcon)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                        _createVNode(_component_Icon, {
                                          name: file.link ? $options.fileType(file.link) : 'file',
                                          class: "w-10 h-10"
                                        }, null, 8, ["name"])
                                      ]))
                                    : _createCommentVNode("", true),
                                  ($options.opts.showSize)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(file.size), 1))
                                    : _createCommentVNode("", true)
                                ], 2))
                              : (_openBlock(), _createElementBlock("a", {
                                  key: 1,
                                  class: _normalizeClass(["flex items-center whitespace-nowrap md:whitespace-normal py-0 pl-0 pr-2 xl:pr-4", {'md:pl-3': !$options.opts.showIcon}]),
                                  href: file.link,
                                  "aria-label": file.title,
                                  target: $options.determineTarget(file.link)
                                }, [
                                  ($options.opts.showIcon)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                        _createVNode(_component_Icon, {
                                          name: file.link ? $options.fileType(file.link) : 'file',
                                          class: "w-10 h-10"
                                        }, null, 8, ["name"])
                                      ]))
                                    : _createCommentVNode("", true),
                                  ($options.opts.showSize)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(file.size), 1))
                                    : _createCommentVNode("", true)
                                ], 10, _hoisted_12))
                          ]))
                        : (column.key === 'tagCategories')
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(column.categories, (cat, index) => {
                              return (_openBlock(), _createElementBlock("td", {
                                key: index,
                                class: "whitespace-nowrap md:whitespace-normal p-2"
                              }, _toDisplayString($options.getTagsForCategory(file, cat) || '-'), 1))
                            }), 128))
                          : (column.key === 'download')
                            ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                                _createElementVNode("a", {
                                  href: file.link,
                                  class: "block pr-2 py-3",
                                  "aria-label": file.title,
                                  target: $options.determineTarget(file.link),
                                  tabindex: "-1"
                                }, [
                                  _createVNode(_component_Icon, {
                                    name: "download",
                                    class: "w-4 h-4",
                                    tabindex: "0"
                                  })
                                ], 8, _hoisted_16)
                              ]))
                            : (_openBlock(), _createElementBlock("td", {
                                key: `${_ctx.$.uid}_${column.key}_${i}`
                              }, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["block whitespace-nowrap md:whitespace-normal py-2", i === 0 ? 'pl-2 xl:pl-4 pr-2 md:pr-0' : 'px-2']),
                                  "aria-label": $options.multiDownloadsActive ? null : file.title
                                }, [
                                  (column.key === 'date')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode(_toDisplayString(_ctx.$filters.formatDate(file.date)), 1)
                                      ], 64))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createTextVNode(_toDisplayString(file[column.key]), 1)
                                      ], 64)),
                                  ($options.opts.dateAfterText && column.key === 'title')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                        _createTextVNode(" - " + _toDisplayString(_ctx.$filters.formatDate(file.date)), 1)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ], 10, _hoisted_17)
                              ]))
                  ], 64))
                }), 256))
              : _createCommentVNode("", true)
          ], 8, _hoisted_7))
        }), 128))
      ])
    ]),
    ($options.hasMore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createVNode(_component_GcButton, {
            class: "font-semibold",
            label: $props.options.lblLoadMore,
            secondary: "",
            onClick: $options.loadMore
          }, null, 8, ["label", "onClick"])
        ]))
      : _createCommentVNode("", true),
    ($data.downloads.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, _toDisplayString($data.downloads.length) + " " + _toDisplayString($options.opts.lblSelected), 1),
              _createVNode(_component_GcButton, {
                icon: "download_",
                label: $options.opts.lblDownload,
                onClick: $options.downloadSelectedDocuments
              }, null, 8, ["label", "onClick"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}