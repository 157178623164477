import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex justify-between mb-4" }
const _hoisted_3 = { class: "flex gap-4 items-center" }
const _hoisted_4 = { class: "text-para-s text-black-600" }
const _hoisted_5 = {
  key: 0,
  class: "text-para-xs text-primary-900 bg-primary-50 rounded-full py-1 px-2.5"
}
const _hoisted_6 = {
  key: 1,
  class: "flex gap-4 text-black-600"
}
const _hoisted_7 = { class: "hidden md:inline text-para-s text-black-600" }
const _hoisted_8 = {
  key: 0,
  class: "font-semibold mb-2"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 2,
  class: "flex flex-col items-end mb-6"
}
const _hoisted_12 = { class: "md:hidden text-para-s text-black-600 mt-2 mb-6" }
const _hoisted_13 = { class: "flex items-center gap-4 text-para-s" }
const _hoisted_14 = {
  key: 0,
  class: "text-black-600"
}
const _hoisted_15 = { class: "text-black-600" }
const _hoisted_16 = {
  key: 3,
  class: "flex flex-col items-end"
}
const _hoisted_17 = {
  key: 4,
  class: "border-b border-black-200 my-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString($props.comment.fullName), 1),
        ($props.comment.role)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($props.labels[$props.comment.role]), 1))
          : _createCommentVNode("", true),
        ($props.isAdmin)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.toggleEditMode($props.index)))
              }, [
                _createVNode(_component_Icon, {
                  name: "edit",
                  class: "w-4 h-4 stroke-current fill-current cursor-pointer"
                })
              ]),
              _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.deleteComment($props.comment.id)))
              }, [
                _createVNode(_component_Icon, {
                  name: "delete",
                  class: "w-4 h-4 fill-current cursor-pointer"
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$filters.formatDateTime($props.comment.date)), 1)
    ]),
    ($props.comment.title)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_8, _toDisplayString($props.comment.title), 1))
      : _createCommentVNode("", true),
    (!$options.editMode[$props.index])
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("p", {
            ref: "text",
            class: "prose transition-all mb-6 overflow-hidden max-h-30 line-clamp-5",
            innerHTML: $options.formatText($props.comment.text)
          }, null, 8, _hoisted_10)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($props.comment.text) = $event)),
            class: "w-full border border-black-100 text-black-600 text-para-s rounded-sm focus:outline-none min-h-32 py-3 px-4 mb-6"
          }, null, 512), [
            [_vModelText, $props.comment.text]
          ]),
          _createVNode(_component_GcButton, {
            type: "button",
            label: $props.labels.submit,
            disabled: $data.isLoading,
            secondary: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.updateComment($props.comment, $props.index)))
          }, null, 8, ["label", "disabled"])
        ])),
    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$filters.formatDateTime($props.comment.date)), 1),
    _createElementVNode("div", _hoisted_13, [
      (!$props.disabled)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "flex items-center font-semibold group cursor-pointer",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.toggle && $options.toggle(...args)))
          }, [
            _createElementVNode("span", null, _toDisplayString($props.labels.reply), 1),
            _createVNode(_component_ArrowButton, {
              direction: "reply",
              class: "w-10 h-10 stroke-current"
            })
          ]))
        : _createCommentVNode("", true),
      (!$props.nested)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!$props.disabled)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, "|"))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_15, _toDisplayString(($props.comment.comments || []).length) + " " + _toDisplayString($props.labels.comments), 1)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (!$props.disabled)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_16, [
          _withDirectives(_createElementVNode("textarea", {
            ref: "edit",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($options.replyModel[$props.index]) = $event)),
            class: "w-full border border-black-100 text-black-600 text-para-s rounded-sm focus:outline-none py-3 px-4 my-4",
            rows: "1",
            onKeydown: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.autosize && $options.autosize(...args)))
          }, null, 544), [
            [_vModelText, $options.replyModel[$props.index]]
          ]),
          _createVNode(_component_GcButton, {
            type: "button",
            label: $props.labels.submit,
            disabled: !$options.replyModel[$props.index] || $data.isLoading,
            secondary: "",
            onClick: _cache[7] || (_cache[7] = ($event: any) => ($options.replyToComment($props.nested ? $props.parentId : $props.comment.id, $props.index)))
          }, null, 8, ["label", "disabled"])
        ], 512)), [
          [_vShow, $options.replyMode[$props.index]]
        ])
      : _createCommentVNode("", true),
    (!$props.nested || !$props.isLast)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17))
      : _createCommentVNode("", true)
  ]))
}