import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-primary-50 my-8" }
const _hoisted_2 = { class: "relative w-full text-primary-500" }
const _hoisted_3 = ["aria-label", "placeholder"]
const _hoisted_4 = { class: "table-fixed min-w-full" }
const _hoisted_5 = { class: "text-para-xs md:text-para-s text-left font-semibold border-b border-black-200" }
const _hoisted_6 = { class: "w-2/12 px-4 py-2" }
const _hoisted_7 = { class: "w-3/12 px-4 py-2" }
const _hoisted_8 = { class: "w-3/12 px-4 py-2" }
const _hoisted_9 = { class: "w-4/12 px-4 py-2" }
const _hoisted_10 = { class: "flex gap-8" }
const _hoisted_11 = { class: "w-1/2" }
const _hoisted_12 = { class: "w-1/2" }
const _hoisted_13 = { class: "text-para-xs md:text-para-s" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["aria-label"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "px-4 py-2 truncate" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "px-4 py-2" }
const _hoisted_20 = { class: "px-4 py-2" }
const _hoisted_21 = { class: "flex flex-wrap xl:flex-nowrap gap-x-8 gap-y-4" }
const _hoisted_22 = {
  key: 0,
  class: "text-error text-para-xs mt-1 md:mt-2 w-full"
}
const _hoisted_23 = {
  key: 1,
  class: "text-error text-para-xs mt-1 md:mt-2 w-full"
}
const _hoisted_24 = { colspan: "5" }
const _hoisted_25 = { class: "px-4 pt-2 pb-4" }
const _hoisted_26 = { class: "table-fixed min-w-full" }
const _hoisted_27 = { class: "text-para-xs md:text-para-s text-left font-semibold border-b border-black-200" }
const _hoisted_28 = { class: "w-2/12 px-4 py-2" }
const _hoisted_29 = { class: "w-3/12 px-4 py-2" }
const _hoisted_30 = { class: "w-5/12 px-4 py-2" }
const _hoisted_31 = { class: "w-2/12 px-4 py-2" }
const _hoisted_32 = { class: "text-para-xs md:text-para-s" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = ["aria-label"]
const _hoisted_35 = ["onClick"]
const _hoisted_36 = { class: "px-4 py-2 truncate" }
const _hoisted_37 = ["href"]
const _hoisted_38 = { class: "px-4 py-2" }
const _hoisted_39 = {
  key: 0,
  class: "text-error text-para-xs mt-1 md:mt-2 w-full"
}
const _hoisted_40 = { class: "px-4 py-2" }
const _hoisted_41 = {
  key: 0,
  class: "text-error text-para-xs mt-1 md:mt-2 w-full"
}
const _hoisted_42 = { colspan: "5" }
const _hoisted_43 = { class: "px-4 pt-2 pb-4" }
const _hoisted_44 = { class: "table-fixed min-w-full" }
const _hoisted_45 = { class: "text-para-xs md:text-para-s text-left font-semibold border-b border-black-200" }
const _hoisted_46 = { class: "w-3/12 px-4 py-2" }
const _hoisted_47 = { class: "w-5/12 px-4 py-2" }
const _hoisted_48 = { class: "w-4/12 px-4 py-2" }
const _hoisted_49 = { class: "flex gap-8" }
const _hoisted_50 = { class: "w-1/2" }
const _hoisted_51 = { class: "w-1/2" }
const _hoisted_52 = { class: "text-para-xs md:text-para-s" }
const _hoisted_53 = { class: "px-4 py-2 truncate" }
const _hoisted_54 = { class: "px-4 py-2 truncate" }
const _hoisted_55 = { class: "px-4 py-2" }
const _hoisted_56 = { class: "flex flex-wrap xl:flex-nowrap gap-x-8 gap-y-4" }
const _hoisted_57 = {
  key: 0,
  class: "text-error text-para-xs mt-1 md:mt-2 w-full"
}
const _hoisted_58 = {
  key: 1,
  class: "text-error text-para-xs mt-1 md:mt-2 w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Combobox = _resolveComponent("Combobox")!
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_UserProperties = _resolveComponent("UserProperties")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!
  const _component_ContentGroupItem = _resolveComponent("ContentGroupItem")!
  const _component_gc_button = _resolveComponent("gc-button")!
  const _component_user_properties = _resolveComponent("user-properties")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!
  const _component_content_group_item = _resolveComponent("content-group-item")!
  const _component_ContentGroup = _resolveComponent("ContentGroup")!

  return (_openBlock(), _createBlock(_component_ContentGroup, {
    type: "tabs",
    "in-content-group": $props.inContentGroup
  }, {
    default: _withCtx(({ index, type, showAll, itemsLength, componentUid }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass({'container': !$props.inContentGroup})
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["py-6 md:py-8", {'xl:w-2/3 mx-auto': !$props.inContentGroup}])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.keyword) = $event)),
                type: "text",
                class: "w-full rounded text-black-600 text-para-s focus:outline-none py-2 px-10 placeholder-improvedContrast",
                "aria-label": $props.labels.placeholder,
                placeholder: $props.labels.placeholder
              }, null, 8, _hoisted_3), [
                [_vModelText, $data.keyword]
              ]),
              _createVNode(_component_Icon, {
                name: "search",
                class: "absolute left-4 top-3 w-4 h-4 stroke-current"
              }),
              _createElementVNode("div", {
                class: "absolute right-4 top-3 cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($data.keyword = ''))
              }, [
                _withDirectives(_createVNode(_component_Icon, {
                  name: "close",
                  class: "w-4 h-4 stroke-current"
                }, null, 512), [
                  [_vShow, !!$data.keyword]
                ])
              ])
            ])
          ], 2)
        ], 2)
      ]),
      _createVNode(_component_ContentGroupItem, {
        title: $props.labels.tabNewUsers,
        index: 0,
        "current-index": index,
        "component-uid": componentUid
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([{'container': !$props.inContentGroup}, "section"])
          }, [
            _createElementVNode("table", _hoisted_4, [
              _createElementVNode("thead", _hoisted_5, [
                _createElementVNode("tr", null, [
                  _cache[2] || (_cache[2] = _createElementVNode("th", null, null, -1)),
                  _createElementVNode("th", _hoisted_6, _toDisplayString($props.labels.login), 1),
                  _createElementVNode("th", _hoisted_7, _toDisplayString($props.labels.email), 1),
                  _createElementVNode("th", _hoisted_8, _toDisplayString($props.labels.targetRoles), 1),
                  _createElementVNode("th", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString($props.labels.approve), 1),
                      _createElementVNode("span", _hoisted_12, _toDisplayString($props.labels.reject), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("tbody", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredUsers, (user, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (user && user.title && !$options.isProcessed(user.id))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_openBlock(), _createElementBlock("tr", { key: index }, [
                            _createElementVNode("td", {
                              class: "px-4 py-2 truncate cursor-pointer",
                              onClick: ($event: any) => ($data.activeUser1 === user.id ? $data.activeUser1 = '' : $data.activeUser1 = user.id)
                            }, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "py-3 px-3 rounded border border-transparent focus:outline-none bg-black-50 hover:bg-black-100 transition-colors duration-100 ease-out",
                                "aria-label": $data.activeUser1 === user.id ? $options.$globalLabels.close : $options.$globalLabels.open
                              }, [
                                _createVNode(_component_Icon, {
                                  name: "chevron-down",
                                  class: _normalizeClass(["w-3 h-3 flex-shrink-0 transition-transform transform origin-center stroke-current", { 'rotate-180': $data.activeUser1 === user.id }])
                                }, null, 8, ["class"])
                              ], 8, _hoisted_15)
                            ], 8, _hoisted_14),
                            _createElementVNode("td", {
                              class: "px-4 py-2 truncate cursor-pointer",
                              onClick: ($event: any) => ($data.activeUser1 === user.id ? $data.activeUser1 = '' : $data.activeUser1 = user.id)
                            }, _toDisplayString(user.title), 9, _hoisted_16),
                            _createElementVNode("td", _hoisted_17, [
                              _createElementVNode("a", {
                                class: "link",
                                href: 'mailto:' + user.email
                              }, _toDisplayString(user.email), 9, _hoisted_18)
                            ]),
                            _createElementVNode("td", _hoisted_19, [
                              _createVNode(_component_Combobox, {
                                modelValue: $data.selectedRoles[user.id],
                                "onUpdate:modelValue": ($event: any) => (($data.selectedRoles[user.id]) = $event),
                                placeholder: $props.labels.selectPlaceholder,
                                searchable: true,
                                "search-label": $props.labels.searchPlaceholder,
                                items: $options.rolesItems
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "search-label", "items"])
                            ]),
                            _createElementVNode("td", _hoisted_20, [
                              _createElementVNode("div", _hoisted_21, [
                                _createVNode(_component_GcButton, {
                                  class: "w-full whitespace-nowrap",
                                  disabled: !$data.active,
                                  label: $props.labels.approval,
                                  onClick: ($event: any) => ($options.approve(user.id))
                                }, null, 8, ["disabled", "label", "onClick"]),
                                _createVNode(_component_GcButton, {
                                  class: "w-full whitespace-nowrap",
                                  disabled: !$data.active,
                                  label: $props.labels.reject,
                                  icon: "close",
                                  onClick: ($event: any) => ($options.reject(user.id, $options.getRejectUrl(user.id)))
                                }, null, 8, ["disabled", "label", "onClick"])
                              ]),
                              ($data.error === 'approve-' + user.id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString($props.labels.approveError), 1))
                                : _createCommentVNode("", true),
                              ($data.error === 'reject-' + user.id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString($props.labels.rejectError), 1))
                                : _createCommentVNode("", true)
                            ])
                          ])),
                          (_openBlock(), _createElementBlock("tr", {
                            key: `${index}_detail`
                          }, [
                            _createElementVNode("td", _hoisted_24, [
                              _withDirectives(_createVNode(_component_CollapseTransition, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_25, [
                                    _createVNode(_component_UserProperties, {
                                      user: user,
                                      properties: $props.properties
                                    }, null, 8, ["user", "properties"])
                                  ])
                                ]),
                                _: 2
                              }, 1536), [
                                [_vShow, $data.activeUser1 === user.id]
                              ])
                            ])
                          ]))
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 256))
              ])
            ])
          ], 2)
        ]),
        _: 2
      }, 1032, ["title", "current-index", "component-uid"]),
      _createVNode(_component_content_group_item, {
        title: $props.labels.tabUserManagement,
        index: 1,
        "current-index": index,
        "component-uid": componentUid
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([{'container': !$props.inContentGroup}, "section"])
          }, [
            _createElementVNode("table", _hoisted_26, [
              _createElementVNode("thead", _hoisted_27, [
                _createElementVNode("tr", null, [
                  _cache[3] || (_cache[3] = _createElementVNode("th", null, null, -1)),
                  _createElementVNode("th", _hoisted_28, _toDisplayString($props.labels.login), 1),
                  _createElementVNode("th", _hoisted_29, _toDisplayString($props.labels.email), 1),
                  _createElementVNode("th", _hoisted_30, _toDisplayString($props.labels.targetRoles), 1),
                  _createElementVNode("th", _hoisted_31, _toDisplayString($props.labels.deletion), 1)
                ])
              ]),
              _createElementVNode("tbody", _hoisted_32, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredEnabledUsers, (user, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (user && user.title && !$options.isProcessed(user.id))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_openBlock(), _createElementBlock("tr", { key: index }, [
                            _createElementVNode("td", {
                              class: "px-4 py-2 truncate cursor-pointer",
                              onClick: ($event: any) => ($data.activeUser2 === user.id ? $data.activeUser2 = '' : $data.activeUser2 = user.id)
                            }, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "py-3 px-3 rounded border border-transparent focus:outline-none bg-black-50 hover:bg-black-100 transition-colors duration-100 ease-out",
                                "aria-label": $data.activeUser2 === user.id ? $options.$globalLabels.close : $options.$globalLabels.open
                              }, [
                                _createVNode(_component_Icon, {
                                  name: "chevron-down",
                                  class: _normalizeClass(["w-3 h-3 flex-shrink-0 transition-transform transform origin-center stroke-current", { 'rotate-180': $data.activeUser2 === user.id }])
                                }, null, 8, ["class"])
                              ], 8, _hoisted_34)
                            ], 8, _hoisted_33),
                            _createElementVNode("td", {
                              class: "px-4 py-2 truncate cursor-pointer",
                              onClick: ($event: any) => ($data.activeUser2 === user.id ? $data.activeUser2 = '' : $data.activeUser2 = user.id)
                            }, _toDisplayString(user.title), 9, _hoisted_35),
                            _createElementVNode("td", _hoisted_36, [
                              _createElementVNode("a", {
                                class: "link",
                                href: 'mailto:' + user.email
                              }, _toDisplayString(user.email), 9, _hoisted_37)
                            ]),
                            _createElementVNode("td", _hoisted_38, [
                              _createVNode(_component_Combobox, {
                                placeholder: $props.labels.selectPlaceholder,
                                searchable: true,
                                "search-label": $props.labels.searchPlaceholder,
                                items: $options.rolesItems,
                                modelValue: $data.selectedRoles[user.id],
                                "onUpdate:modelValue": ($event: any) => (($data.selectedRoles[user.id]) = $event),
                                "cta-label": $props.labels.save,
                                "show-cta": "",
                                onCta: ($event: any) => ($options.update(user.id))
                              }, null, 8, ["placeholder", "search-label", "items", "modelValue", "onUpdate:modelValue", "cta-label", "onCta"]),
                              ($data.error === 'update-' + user.id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString($props.labels.updateError), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("td", _hoisted_40, [
                              _createVNode(_component_gc_button, {
                                class: "w-full whitespace-nowrap",
                                disabled: !$data.active,
                                label: $props.labels.delete,
                                icon: "close",
                                onClick: ($event: any) => ($options.reject(user.id, $options.getDeleteUrl(user.id)))
                              }, null, 8, ["disabled", "label", "onClick"]),
                              ($data.error === 'reject-' + user.id)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString($props.labels.rejectError), 1))
                                : _createCommentVNode("", true)
                            ])
                          ])),
                          (_openBlock(), _createElementBlock("tr", {
                            key: `${index}_detail`
                          }, [
                            _createElementVNode("td", _hoisted_42, [
                              _withDirectives(_createVNode(_component_collapse_transition, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_43, [
                                    _createVNode(_component_user_properties, {
                                      user: user,
                                      properties: $props.properties
                                    }, null, 8, ["user", "properties"])
                                  ])
                                ]),
                                _: 2
                              }, 1536), [
                                [_vShow, $data.activeUser2 === user.id]
                              ])
                            ])
                          ]))
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 256))
              ])
            ])
          ], 2)
        ]),
        _: 2
      }, 1032, ["title", "current-index", "component-uid"]),
      (!$props.hideReApprovalTab)
        ? (_openBlock(), _createBlock(_component_content_group_item, {
            key: 0,
            title: $props.labels.tabReapprovals,
            index: 2,
            "current-index": index,
            "component-uid": componentUid
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass([{'container': !$props.inContentGroup}, "section"])
              }, [
                _createElementVNode("table", _hoisted_44, [
                  _createElementVNode("thead", _hoisted_45, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", _hoisted_46, _toDisplayString($props.labels.login), 1),
                      _createElementVNode("th", _hoisted_47, _toDisplayString($props.labels.email), 1),
                      _createElementVNode("th", _hoisted_48, [
                        _createElementVNode("div", _hoisted_49, [
                          _createElementVNode("span", _hoisted_50, _toDisplayString($props.labels.approve), 1),
                          _createElementVNode("span", _hoisted_51, _toDisplayString($props.labels.reject), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("tbody", _hoisted_52, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filteredUsersToBeReapproved, (user, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (user && user.title && !$options.isProcessed(user.id))
                          ? (_openBlock(), _createElementBlock("tr", { key: index }, [
                              _createElementVNode("td", _hoisted_53, _toDisplayString(user.title), 1),
                              _createElementVNode("td", _hoisted_54, _toDisplayString(user.email), 1),
                              _createElementVNode("td", _hoisted_55, [
                                _createElementVNode("div", _hoisted_56, [
                                  _createVNode(_component_gc_button, {
                                    class: "w-full whitespace-nowrap",
                                    disabled: !$data.active,
                                    label: $props.labels.approval,
                                    onClick: ($event: any) => ($options.reapprove(user.id))
                                  }, null, 8, ["disabled", "label", "onClick"]),
                                  _createVNode(_component_gc_button, {
                                    class: "w-full whitespace-nowrap",
                                    disabled: !$data.active,
                                    label: $props.labels.reject,
                                    icon: "close",
                                    onClick: ($event: any) => ($options.reject(user.id, $options.getRejectReapproveUrl(user.id)))
                                  }, null, 8, ["disabled", "label", "onClick"])
                                ]),
                                ($data.error === 'approve-' + user.id)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_57, _toDisplayString($props.labels.approveError), 1))
                                  : _createCommentVNode("", true),
                                ($data.error === 'reject-' + user.id)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_58, _toDisplayString($props.labels.rejectError), 1))
                                  : _createCommentVNode("", true)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ])
                ])
              ], 2)
            ]),
            _: 2
          }, 1032, ["title", "current-index", "component-uid"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["in-content-group"]))
}