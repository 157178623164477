import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "mb-12 xl:mb-20" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = {
  key: 0,
  class: "relative flex flex-col items-center"
}
const _hoisted_4 = {
  key: 1,
  class: "-mx-2.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.steps, (step, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (step.type === 'normal')
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                _createElementVNode("span", {
                  class: _normalizeClass(["w-3 h-3 md:w-4 md:h-4 rounded-full", {
                              'border-2 border-primary-500': $props.currentStep === index,
                              'bg-primary-500': $props.currentStep > index,
                              'bg-black-300': $props.currentStep < index
                          }])
                }, null, 2),
                _createElementVNode("span", {
                  class: _normalizeClass(["absolute top-6 hidden md:block text-para-xs whitespace-nowrap", {
                              'text-primary-500': $props.currentStep >= index,
                              'text-black-300': $props.currentStep < index,
                              'font-semibold': $props.currentStep === index
                          }])
                }, _toDisplayString(step.title), 3)
              ]))
            : _createCommentVNode("", true),
          (step.type === 'intermediate')
            ? (_openBlock(), _createElementBlock("li", _hoisted_4))
            : _createCommentVNode("", true),
          (index < ($props.steps.length - 1))
            ? (_openBlock(), _createElementBlock("li", {
                key: 2,
                class: _normalizeClass(["h-0.5 mx-2 my-1.25 md:my-1.5 rounded-sm", {
                        'bg-black-300': $props.currentStep <= index,
                        'bg-primary-500': $props.currentStep > index
                    }]),
                style: _normalizeStyle($options.getStyles(index))
              }, null, 6))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}