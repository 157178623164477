import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "fixed bottom-4 md:bottom-12 right-4 md:right-20 flex justify-center items-center w-12 md:w-16 h-12 md:h-16 rounded bg-black-900 cursor-pointer",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.scrollToTop && $options.scrollToTop(...args)))
  }, [
    _createVNode(_component_Icon, {
      name: "chevron-up",
      class: "w-4 h-4 stroke-current text-white"
    })
  ], 512)), [
    [_vShow, $options.visible]
  ])
}