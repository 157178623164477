import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-para-s" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: $options.model[$props.name],
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.model[$props.name]) = $event)),
    class: "flex flex-col items-center border border-black-200 rounded p-4",
    rules: $options.rules,
    name: $props.title,
    as: "div",
    mode: "eager",
    disabled: $options.validationDisabled
  }, {
    default: _withCtx(({ field, errors }) => [
      _createVNode(_component_NumberInput, _mergeProps({
        class: ["appearance-none text-para-lg font-semibold focus:outline-none w-full text-center", {'text-black-600': $options.model[$props.name] === 0}]
      }, field, {
        modelValue: $options.model[$props.name],
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.model[$props.name]) = $event)),
        name: $props.name,
        "aria-required": $props.required,
        "aria-invalid": errors.length > 0,
        "aria-describedby": errors.length > 0 ? `${$props.name}-error` : '',
        options: $options.options
      }), null, 16, ["class", "modelValue", "name", "aria-required", "aria-invalid", "aria-describedby", "options"]),
      _createElementVNode("span", _hoisted_1, _toDisplayString($props.title), 1),
      (errors.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            id: `${$props.name}-error`,
            class: "text-error text-para-xs mt-4 text-center"
          }, _toDisplayString(errors[0]), 9, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "rules", "name", "disabled"]))
}