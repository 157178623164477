import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SocioEconomicMapDetailContent = _resolveComponent("SocioEconomicMapDetailContent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["w-full order-2 min-h-screen h-auto mt-socio-economic-map overflow-hidden relative max-h-full rounded-t-xl md:rounded-none md:order-1 md:min-h-0 bg-primary-500 md:transition-all md:block md:static z-30 flex flex-col md:mt-0 md:w-1/3 xl:w-1/4 md:h-120 xl:h-180 xl:overflow-x-hidden xl:overflow-y-auto xl:shadow-2xl", { 'pt-14': !$data.showChevronUp }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex md:hidden mx-auto mt-0 w-full h-14 p-5 text-white", !$data.showChevronUp ? 'bg-primary-500 justify-start' : 'justify-center']),
      style: _normalizeStyle($data.specialChevronStyles)
    }, [
      _withDirectives(_createVNode(_component_Icon, {
        name: "double-chevron-up",
        class: "w-8 h-8 stroke-current"
      }, null, 512), [
        [_vShow, $data.showChevronUp]
      ]),
      _withDirectives(_createVNode(_component_Icon, {
        name: "chevron-down",
        class: "w-6 h-6 ml-5 stroke-current"
      }, null, 512), [
        [_vShow, !$data.showChevronUp]
      ])
    ], 6),
    ($props.operation)
      ? (_openBlock(), _createBlock(_component_SocioEconomicMapDetailContent, {
          key: 0,
          scope: "Operation",
          entity: $props.operation,
          "download-factsheet-label": $props.downloadFactsheetLabel,
          "show-more-label": $props.showMoreLabel,
          "show-less-label": $props.showLessLabel,
          "operations-label": $props.operationsLabel
        }, null, 8, ["entity", "download-factsheet-label", "show-more-label", "show-less-label", "operations-label"]))
      : ($props.region)
        ? (_openBlock(), _createBlock(_component_SocioEconomicMapDetailContent, {
            key: 1,
            scope: "Region",
            entity: $props.region,
            operations: $props.operations,
            "download-factsheet-label": $props.downloadFactsheetLabel,
            "show-more-label": $props.showMoreLabel,
            "show-less-label": $props.showLessLabel,
            "operations-label": $props.operationsLabel,
            onSetOperation: $options.setOperation
          }, null, 8, ["entity", "operations", "download-factsheet-label", "show-more-label", "show-less-label", "operations-label", "onSetOperation"]))
        : ($props.sectionData)
          ? (_openBlock(), _createBlock(_component_SocioEconomicMapDetailContent, {
              key: 2,
              entity: $props.sectionData,
              scope: "Initial",
              "download-factsheet-label": $props.downloadFactsheetLabel,
              "show-more-label": $props.showMoreLabel,
              "show-less-label": $props.showLessLabel,
              "operations-label": $props.operationsLabel
            }, null, 8, ["entity", "download-factsheet-label", "show-more-label", "show-less-label", "operations-label"]))
          : _createCommentVNode("", true)
  ], 2))
}