<template>
    <Teleport to="body">
        <Transition name="fade">
            <dialog ref="dialogEL"
                    class="max-w-full max-h-full backdrop:bg-black-900 backdrop:bg-opacity-20 backdrop:backdrop-filter backdrop:backdrop-blur"
                    @close="close"
            >
                <div class="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center z-50 text-left bg-black-900 transition-all duration-300 ease-out"
                     :class="{'pointer-events-all bg-opacity-90': active, 'pointer-events-none bg-opacity-0': !active}"
                     @mousemove="handleCloseButton"
                >
                    <div class="relative w-7/8 max-w-video-modal h-0 bg-black-900 overflow-hidden transform transition-all duration-600 ease-out"
                         :class="{'translate-y-4': !active, 'translate-y-0 shadow-m': active}"
                         :style="{ height: containerHeight }"
                    >
                        <button v-if="closeVisible" class="close-button absolute top-5 right-5 w-10 h-10 text-primary-500 cursor-pointer origin-center transition-all duration-200 ease-out overflow-visible transform hover:scale-125 z-10"
                                autofocus
                                :aria-label="$globalLabels.close"
                                @click.stop.prevent="close"
                        >
                            <svg class="w-full h-full fill-current"
                                 viewBox="0 0 43 44"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M15.6256 27.1151L20.3674 22.3734L15.7504 17.7564L16.8734 16.6333L21.4904 21.2503L26.2322 16.5085L27.3053 17.5817L22.5635 22.3235L27.2055 26.9654L26.0824 28.0885L21.4405 23.4465L16.6987 28.1883L15.6256 27.1151Z" />
                            </svg>
                        </button>
                        <div ref="embed" />
                    </div>
                </div>
            </dialog>
        </Transition>
    </Teleport>
</template>

<script lang="ts">
import brightcovePlayerLoader from '@brightcove/player-loader';

export default {
    inject: ['$globalLabels'],
    props: {
        videoId: {type: [String, Number], required: true},
        accountId: {type: String, required: true},
        playerId: {type: String, required: true}
    },
    emits: ['fix-header', 'close'],
    data() {
        return {
            videoRef: null,
            containerHeight: '0',
            hidden: true,
            active: false,

            closeVisible: false,
            timer: ''
        };
    },
    watch: {
        async videoId() {
            if (this.videoId) {
                await this.loadVideo();
                this.openModal();
            } else if (this.active) {
                await this.close();
            }
        }
    },
    methods: {
        handleCloseButton(): void {
            this.closeVisible = true;
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.closeVisible = false;
            }, 3000);
        },
        async loadVideo() {
            const success = await brightcovePlayerLoader({
                refNode: this.$refs.embed,
                accountId: this.accountId,
                playerId: this.playerId,
                videoId: this.videoId,
                embedOptions: {
                    tagName: brightcovePlayerLoader.EMBED_TAG_NAME_VIDEO,
                    responsive: {
                        aspectRatio: '16:9'
                    }
                }
            });

            this.videoRef = success.ref;

            // Wait a frame for the video to be inserted
            window.requestAnimationFrame(() => {
                this.containerHeight = `${this.$refs.embed.clientHeight}px`;
            });

            this.videoRef.on('loadedmetadata', () => {
                if (this.videoRef) {
                    this.videoRef.play();
                }
            });
        },
        close() {
            if (this.videoRef) {
                this.videoRef.pause();
                this.videoRef = false;
            }

            this.closeModal();

            // Remove the old video and clear the height
            this.$refs.embed.innerHTML = '';
            this.containerHeight = '0';
            this.$emit('close');
        },
        openModal() {
            this.active = true;

            document.body.style.overflow = 'hidden';

            if (this.$refs.dialogEL && !this.$refs.dialogEL.open) {
                this.$refs.dialogEL.showModal();
            }
        },
        closeModal() {
            this.active = false;

            document.body.style.overflow = '';

            if (this.$refs.dialogEL.open) {
                this.$refs.dialogEL.close();
            }
        }
    }
};
</script>
