<template>
    <div :id="anchor" class="content-group">
        <TabHeader v-if="!isAccordion()"
                   v-model="currentIndex"
                   :tabs="titles"
                   :anchors="anchors"
                   :in-content-group="inContentGroup"
                   :highlight="highlight"
                   :narrow="narrow"
                   :component-uid="$.uid"
        />
        <div :class="{
            'container': !inContentGroup && isAccordion(),
            'overflow-x-auto overflow-y-hidden md:overflow-x-visible md:overflow-y-visible': scrollableContent
        }"
        >
            <ul :class="{
                'border-t': isAccordion(),
                'border-black-900': isAccordion() && !inContentGroup,
                'xl:w-2/3 mx-auto': isAccordion() && !inContentGroup && narrow,
                'border-black-500': isNestedAccordion(),
            }"
            >
                <slot :index="currentIndex"
                      :type="type"
                      :show-all="showAll"
                      :items-length="itemsLength"
                      :component-uid="$.uid"
                />
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import TabHeader from '../base/TabHeader.vue';
import Utils from '../../utils/Utils';
import { useNavigationStore } from "../../stores/navigation";
import { mapState } from "pinia";

export default {
    components: {TabHeader},
    provide() {
        return {
            register: this.registerItem,
            checkAccordion: this.isAccordion(),
            checkNestedAccordion: this.isNestedAccordion()
        };
    },
    props: {
        type: {type: String, default: 'accordion'},
        showAll: {type: Boolean, default: false},
        inContentGroup: {type: Boolean, default: false},
        narrow: {type: Boolean, default: true},
        scrollableContent: {type: Boolean, default: false},
        highlight: {type: Boolean, default: false},
        defaultIndex: {type: Number, default: null},
        itemsLength: {type: Number, default: 0},
        anchor: {type: String, default: null},
        showAllLabel: {type: String}
    },
    data() {
        return {
            initialIndex: this.defaultIndex !== null ? this.defaultIndex : this.isAccordion() ? null : 0,
            currentIndex: this.initialIndex,
            children: [],
            titles: [],
            anchors: []
        };
    },
    computed: {
        ...mapState(useNavigationStore, ['activatedTab'])
    },
    watch: {
        async activatedTab(newVal: string) {
            if (newVal) {
                this.checkActivatedTab(newVal);
            }
        }
    },
    mounted(): void {
        // show all -> add this option as last tab
        if (this.showAll) {
            this.titles.push(this.showAllLabel);
        }
        this.loadDeepLink();

        // listen for changes of the anchor
        addEventListener('hashchange', this.loadDeepLink);
    },
    methods: {
        // called by child items
        registerItem(item: any): void {
            this.children.push(item);
            if (item.title) {
                this.titles.push(item.title);

                if (item.anchor) {
                    this.anchors.push(item.anchor);
                } else {
                    this.anchors.push(Utils.cleanString(item.title));
                }
            }
        },
        isAccordion(): boolean {
            return this.type === 'accordion';
        },
        isNestedAccordion(): boolean {
            return this.isAccordion() && this.inContentGroup;
        },
        loadDeepLink(): void {
            const itemIds: string[] = this.getItemsFromUrl();
            itemIds.forEach(id => this.presetItem(id));
        },
        checkActivatedTab(anchor: string): void {
            if (anchor) {
                const index = this.anchors.findIndex(a => a === anchor);
                if (index >= 0) {
                    this.currentIndex = index;
                }
            }
        },
        presetItem(itemId: string, sectionId?: string): void {
            if (!itemId || (sectionId && this.anchor !== sectionId)) {
                this.currentIndex = this.initialIndex;
                return;
            }

            let itemIndex: number;
            if (this.anchors.includes(itemId)) {
                itemIndex = this.anchors.indexOf(itemId);
            } else {
                // check if target item is contained in one of the content group items
                const targetItem = document.getElementById(itemId);
                const list = this.children.filter(child => {
                    return child.$el.contains(targetItem);
                });

                if (!list.length) return; // targetItem not in this content group -> nothing to do

                // targetItem found,
                itemIndex = (list[0] as any).index;
            }
            this.currentIndex = itemIndex >= 0 ? itemIndex : this.initialIndex;
            this.children
                .filter(c => (c as any).index === itemIndex)
                .forEach(c => {
                    setTimeout(() => {
                        (c as any).open = true;
                    }, 0);
                });

            // Trigger scroll after synchronous operationFilters have been executed. In case of accordion,
            // wait for animation to finish.
            // Let the target element always be the last id found in the url to ensure a deepest child is visible.
            if (this.getItemsFromUrl().indexOf(itemId) === this.getItemsFromUrl().length - 1) {
                let el = document.getElementById(itemId);
                if (!el) {
                    el = document.getElementById(`item-${this._uid}-${itemIndex}`);
                }

                if (el) {
                    setTimeout(() => {
                        el.scrollIntoView({ behavior: 'smooth' });
                    }, this.isAccordion ? 300 : 0);
                }
            }
        },
        getItemsFromUrl() {
            return window.location.hash.substring(1).split(':');
        }
    }
};
</script>
