<template>
    <div :class="{'container': !inContentGroup}">
        <div class="flex flex-col w-full tracking-tight mx-auto" :class="{'xl:w-2/3': !inContentGroup}">
            <div class="flex flex-col md:flex-row md:items-center gap-x-8 gap-y-2 border border-black-100 px-8 py-4">
                <span>{{ labels.datePicker }}</span>
                <div class="relative w-min" :class="{'text-primary-600': opened}">
                    <FlatPickr
                        v-model="dateModel"
                        class="text-para-s text-black-600 border border-black-100 focus:border-primary-600 focus:outline-none rounded py-2.5 pl-4 pr-8"
                        :config="config"
                        @on-open="opened = true"
                        @on-close="handleDateChange"
                    />
                    <Icon name="calendar"
                          class="absolute right-4 top-1/2 transform -translate-y-1/2 stroke-current w-4 h-4 pointer-events-none"
                    />
                </div>
            </div>
            <div class="flex flex-col md:flex-row justify-between items-center gap-y-8 md:pl-21 xl:pl-30 mt-8 md:mt-10">
                <div class="self-start md:self-auto">
                    <span v-html="labels.captionPrefix" />
                    <span class="font-semibold">&nbsp;{{ date }}&nbsp;</span>
                    <span>{{ labels.captionSuffix }}</span>
                    <span class="font-semibold">&nbsp;{{ time }}</span>
                </div>
                <div class="flex self-end md:self-auto">
                    <div class="w-1 h-6 mr-2 bg-primary-500" />
                    <div class="text-para-xs font-semibold rounded-xl bg-primary-50 px-3 py-1">
                        {{ labels.legend }}
                    </div>
                </div>
            </div>
            <div class="px-4 md:px-0 -mx-4 md:mx-0 overflow-x-auto md:overflow-x-hidden">
                <div class="flex gap-x-8 xl:gap-x-10 mt-6">
                    <div class="flex items-center w-6 xl:w-auto">
                        <span class="transform -rotate-90 xl:rotate-0 -translate-x-3 xl:translate-x-0"
                              v-html="labels.yAxis"
                        />
                    </div>
                    <div class="w-full min-h-125 min-w-150">
                        <canvas ref="canvas" />
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-6 xl:mt-10">
                <span v-html="labels.xAxis" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType, shallowRef, toRaw} from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import hasOwn from 'object.hasown';
import Chart, {ChartData, ChartOptions} from 'chart.js/auto';
import Icon from "../base/Icon.vue";

export interface MntIsaLabels {
    datePicker: string;
    legend: string;
    xAxis: string;
    yAxis: string;
    captionPrefix: string;
    captionSuffix: string;
}

export interface Station {
    name: string;
    label: string;
}

export default {
    components: {Icon},
    props: {
        labels: {type: Object as PropType<MntIsaLabels>, required: true},
        stations: {type: Array as PropType<Array<Station>>, required: true},
        minValue: {type: Number, default: 10},
        inContentGroup: {type: Boolean, default: false}
    },
    data() {
        return {
            apiUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.SO2,

            currentDate: null,
            data: [],
            dateModel: null,
            chart: shallowRef(null),    // shallowRef is needed to prevent reactivity issues with chart js

            drawn: false,
            opened: false
        };
    },
    computed: {
        date(): string {
            if (this.dateModel) {
                return this.dateModel.split(' ')[0];
            }
            return '';
        },
        time(): string {
            if (this.dateModel) {
                // format to am/pm
                const time = this.dateModel.split(' ')[1];
                const splitTime = time.split(':');

                const tmpHours = Number(splitTime[0]);
                const minutes = splitTime[1];

                const suffix = tmpHours >= 12 ? 'PM' : 'AM';
                let hours = tmpHours % 12;
                if (hours === 0) hours = 12;
                return `${this.dd(hours)}:${minutes} ${suffix}`;
            }
            return '';
        },
        config(): Record<string, unknown> {
            return {
                altInput: true,
                altFormat: 'd/m/Y, G:i K',
                dateFormat: 'd/m/Y H:i',
                disableMobile: true,
                defaultDate: this.currentDate,
                enableTime: true,
                maxDate: this.currentDate
            };
        },
        chartData(): ChartData {
            return {
                labels: this.stations.map((station: Station) => station.label),
                datasets: [
                    {
                        data: this.data,
                        backgroundColor: '#00928E',
                        barThickness: 24
                    }
                ]
            };
        },
        chartOptions(): ChartOptions {
            return {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false,
                        external: this.externalTooltipHandler
                    }
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            color: '#5A5A5A',
                            font: {
                                size: 14
                            },
                            autoSkip: false,
                            maxRotation: 32,
                            minRotation: 32
                        },
                        border: {
                            color: '#F5F5F5'
                        },
                        grid: {
                            color: '#F5F5F5'
                        }
                    },
                    y: {
                        beginAtZero: true,
                        suggestedMax: this.minValue,
                        min: 0,
                        ticks: {
                            color: '#000000',
                            font: {
                                size: 14
                            }
                        },
                        border: {
                            color: '#F5F5F5'
                        },
                        grid: {
                            color: '#F5F5F5'
                        }
                    }
                }
            };
        }
    },
    created(): void {
        const current = new Date();
        // should set minutes to a multiple of 5
        current.setMinutes(Math.floor(current.getMinutes() / 5) * 5);
        current.setHours(current.getHours() - 1);
        this.currentDate = current;
        this.dateModel = this.getFormattedDate(current);
        this.getTimeSeriesData();
    },
    methods: {
        handleDateChange(): void {
            setTimeout(() => {
                this.getTimeSeriesData();
                this.opened = false;
            }, 100);
        },
        drawChart(): void {
            if (!this.$refs.canvas || this.drawn) return;

            const ctx = this.$refs.canvas.getContext('2d');
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: this.chartData,
                options: this.chartOptions
            });
            this.drawn = true;
        },
        updateChart(): void {
            this.chart.data.datasets.forEach(dataset => {
                dataset.data = this.data;
            });
            this.chart.update();
        },
        dd(num: number): string {
            return (num < 10 ? `0${num}` : `${num}`);
        },
        getFormattedDate(date: Date): string {
            // convert numbers to double digits
            return `${this.dd(date.getDate())}/${this.dd(date.getMonth() + 1)}/${this.dd(date.getFullYear())} ${this.dd(date.getHours())}:${this.dd(date.getMinutes())}`;
        },
        getTimeSeriesData(): void {
            const url = `${this.apiUrl}/timeseries/mntisa/?date_time=${this.dateModel}`;
            axios.get(url)
                .then(res => {
                    const allData = res.data.values.reduce((accumulator, item) => {
                        accumulator[item.name] = item.value;
                        return accumulator;
                    }, {});
                    const values: number[] = [];
                    this.stations.forEach((station: Station) => {
                        if (hasOwn(allData, station.name)) {
                            values.push(allData[station.name]);
                        } else {
                            values.push(0);
                        }
                    });
                    this.data = values;
                    if (!this.drawn) {
                        this.drawChart();
                    } else {
                        this.updateChart();
                    }
                })
                .catch(err => {
                    console.log(err);
                    if (!this.drawn) {
                        this.data = this.stations.map(() => 0);
                        this.drawChart();
                    }
                });
        },
        getTooltip(chart): HTMLDivElement {
            let tooltipEl = chart.canvas.parentNode.querySelector('div');

            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.classList.add('bg-primary-50', 'py-1', 'px-3', 'rounded-xl', 'pointer-events-none', 'absolute',
                    'transform', '-translate-x-3', '-translate-y-10', 'transition-all', 'duration-100', 'text-para-xs', 'flex');
                tooltipEl.style.opacity = 1;
                chart.canvas.parentNode.appendChild(tooltipEl);
            } else {
                tooltipEl.innerHTML = '';
            }

            return tooltipEl;
        },
        externalTooltipHandler(context): void {
            // Tooltip Element
            const {chart, tooltip} = context;
            const tooltipEl = this.getTooltip(chart);

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
            }

            // Set Text
            if (tooltip.body) {
                const titleLines = tooltip.title || [];
                const bodyLines = tooltip.body.map(b => b.lines);

                titleLines.forEach(title => {
                    const label = document.createElement('span');
                    label.classList.add('font-semibold', 'mr-2');
                    label.innerText = title;
                    tooltipEl.appendChild(label);
                });

                bodyLines.forEach((body) => {
                    const value = document.createElement('span');
                    value.innerText = body;
                    tooltipEl.appendChild(value);
                });
            }

            const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        }
    }
};
</script>
