<template>
    <div class="flex flex-row items-center justify-center md:justify-end xl:w-2/3 mx-auto pt-3 md:py-0">
        <span class="font-semibold text-para-xs mr-4">{{ label }}</span>
        <Dropdown v-model="model"
                  class="min-w-50 -my-px"
                  name="year"
                  :title="model"
                  :default-value="modelValue"
                  :rounded="isMobile"
        >
            <DropdownItem v-for="(item, index) in options" :key="index" :label="item" :value="item" />
        </Dropdown>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import breakpoints from '../../plugins/breakpoints';
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";

export default {
    components: {DropdownItem, Dropdown},
    props: {
        options: {type: Array as PropType<Array<string>>, default: () => []},
        label: {type: String, default: ''},
        modelValue: {type: String}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            model : ''
        };
    },
    computed: {
        isMobile(): boolean {
            return breakpoints.mobile;
        }
    },
    watch: {
        async model(newVal) {
            if (newVal !== this.modelValue) {
                this.$emit('update:modelValue', newVal);
            }
        }
    },
    mounted(): void {
        this.model = this.modelValue;
    }
};
</script>
