<!--
*******************************************************************************************************
* This Vue-class is a copy of
* https://github.com/euvl/vue-js-toggle-button/blob/master/src/Button.vue
*
* ... only... fixed for and built using Vue3, which makes it work (instead of throwing runtime errors).
*******************************************************************************************************
-->
<template>
    <label :class="[{ 'pointer-events-none opacity-60' : disabled}, className]" tabindex="0" role="checkbox"
           @keydown.space.prevent="keyToggle"
    >
        <input
            v-model="toggled"
            type="checkbox"
            class="opacity-0 absolute w-px h-px"
            :name="name"
            :disabled="disabled"
            tabindex="-1"
            @change.stop="toggle"
        >
        <div
            class="block relative box-border outline-0 m-0 transition border-color background-color delay-300 select-none"
            :style="coreStyle"
        >
            <div class="block absolute overflow-hidden top-0 left-0 rounded-full bg-white z-2" :style="buttonStyle" />
        </div>
        <template v-if="labels">
            <span v-if="toggled" class="absolute top-0 font-semibold text-white z-1 left-2.5" :style="labelStyle">
                <slot name="checked">{{ labelChecked }}</slot>
            </span>
            <span v-else class="absolute top-0 font-semibold text-white z-1 right-2.5" :style="labelStyle">
                <slot name="unchecked">{{ labelUnchecked }}</slot>
            </span>
        </template>
    </label>
</template>

<script>
import {get, has, isObject, isString, px, translate} from '../../utils/ToggleButtonUtils';

const DEFAULT_COLOR_CHECKED = '#75c791';
const DEFAULT_COLOR_UNCHECKED = '#bfcbd9';
const DEFAULT_LABEL_CHECKED = 'on';
const DEFAULT_LABEL_UNCHECKED = 'off';
const DEFAULT_SWITCH_COLOR = '#fff';

export default {
    props: {
        modelValue: {type: Boolean, default: false},
        name: {type: String},
        disabled: {type: Boolean, default: false},
        tag: {type: String},
        sync: {type: Boolean, default: false},
        speed: {type: Number, default: 300},
        color: {
            type: [String, Object], validator(value) {
                return (
                    isString(value) ||
                    has(value, 'checked') ||
                    has(value, 'unchecked') ||
                    has(value, 'disabled')
                );
            }
        },
        switchColor: {
            type: [String, Object], validator(value) {
                return (
                    isString(value) || has(value, 'checked') || has(value, 'unchecked')
                );
            }
        },
        cssColors: {type: Boolean, default: false},
        labels: {
            type: [Boolean, Object], default: false, validator(value) {
                return typeof value === 'object'
                    ? value.checked || value.unchecked
                    : typeof value === 'boolean';
            }
        },
        height: {type: Number, default: 22},
        width: {type: Number, default: 50},
        margin: {type: Number, default: 3},
        fontSize: {type: Number}
    },
    emits: ['change', 'update:modelValue'],
    data() {
        return {
            toggled: !!this.modelValue
        };
    },
    computed: {
        className() {
            const {toggled, disabled} = this;

            return [
                'inline-block relative align-middle select-none text-xs cursor-pointer',
                {
                    toggled,
                    disabled
                }
            ];
        },
        coreStyle() {
            return {
                width: px(this.width),
                height: px(this.height),
                backgroundColor: this.cssColors
                    ? null
                    : this.disabled
                        ? this.colorDisabled
                        : this.colorCurrent,
                borderRadius: px(Math.round(this.height / 2))
            };
        },
        buttonRadius() {
            return this.height - this.margin * 2;
        },
        distance() {
            return px(this.width - this.height + this.margin);
        },
        buttonStyle() {
            const transition = `transform ${this.speed}ms`;
            const margin = px(this.margin);

            const transform = this.toggled
                ? translate(this.distance, margin)
                : translate(margin, margin);

            const background = this.switchColor ? this.switchColorCurrent : null;

            return {
                width: px(this.buttonRadius),
                height: px(this.buttonRadius),
                transition,
                transform,
                background
            };
        },
        labelStyle() {
            return {
                lineHeight: px(this.height),
                fontSize: this.fontSize ? px(this.fontSize) : null
            };
        },
        colorChecked() {
            const {color} = this;

            if (!isObject(color)) {
                return color || DEFAULT_COLOR_CHECKED;
            }

            return get(color, 'checked', DEFAULT_COLOR_CHECKED);
        },
        colorUnchecked() {
            return get(this.color, 'unchecked', DEFAULT_COLOR_UNCHECKED);
        },
        colorDisabled() {
            return get(this.color, 'disabled', this.colorCurrent);
        },
        colorCurrent() {
            return this.toggled ? this.colorChecked : this.colorUnchecked;
        },
        labelChecked() {
            return get(this.labels, 'checked', DEFAULT_LABEL_CHECKED);
        },
        labelUnchecked() {
            return get(this.labels, 'unchecked', DEFAULT_LABEL_UNCHECKED);
        },
        switchColorChecked() {
            return get(this.switchColor, 'checked', DEFAULT_SWITCH_COLOR);
        },
        switchColorUnchecked() {
            return get(this.switchColor, 'unchecked', DEFAULT_SWITCH_COLOR);
        },
        switchColorCurrent() {
            if (!isObject(this.switchColor)) {
                return this.switchColor || DEFAULT_SWITCH_COLOR;
            }

            return this.toggled ? this.switchColorChecked : this.switchColorUnchecked;
        }
    },
    watch: {
        async modelValue() {
            if (this.sync) {
                this.toggled = !!this.modelValue;
            }

            this.$emit('update:modelValue', this.modelValue);
        }
    },
    methods: {
        keyToggle(event) {
            // the key event happens whether the control is disabled or not
            // nothing should be done if disabled is true
            if (this.disabled) {
                return;
            }

            this.toggle(event);
        },
        toggle(event) {
            const toggled = !this.toggled;

            if (!this.sync) {
                this.toggled = toggled;
            }

            this.$emit('update:modelValue', toggled);
            this.$emit('change', {
                value: toggled,
                tag: this.tag,
                srcEvent: event
            });
        }
    }
};
</script>
