<template>
    <svg>
        <use :xlink:href="`${$contextPath}${sprite}#${name}`"></use>
    </svg>
</template>

<script lang="ts">

export default {
    inject: ['$contextPath'],
    props: {
        name: {type: String},
        source: {type: String, default: '/img/sprite.svg'}
    },
    computed: {
        sprite(): string {
            return '/.resources/gc/webresources' + this.source;
        }
    }
};
</script>
