import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed top-0 left-0 w-screen h-screen bg-white p-5 text-para-s z-9000 md:z-0 md:absolute md:right-auto md:left-5 md:bottom-auto md:top-5 md:w-11/12 md:max-w-160 md:max-h-10/12 md:h-auto md:flex md:flex-nowrap md:rounded md:shadow-2xl xl:max-w-none xl:w-11/12" }
const _hoisted_2 = { class: "text-black-800 py-3 md:mr-3" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocioEconomicMapSelection = _resolveComponent("SocioEconomicMapSelection")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString($props.filterByLabel), 1),
        _createElementVNode("button", {
          class: "md:hidden w-full text-primary-500 mb-6",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.unsetQuery && $options.unsetQuery(...args)))
        }, _toDisplayString($props.resetFilterLabel), 1),
        _createVNode(_component_SocioEconomicMapSelection, {
          groups: $props.regions,
          scope: 'Region',
          "current-query": $data.currentQuery,
          "current-region": $props.currentRegion,
          "current-operation": $props.currentOperation,
          "search-label": $props.searchLabel,
          placeholder: $props.regionLabel,
          onSetRegion: $options.setRegion,
          onUnset: $options.unsetQuery
        }, null, 8, ["groups", "current-query", "current-region", "current-operation", "search-label", "placeholder", "onSetRegion", "onUnset"]),
        _createVNode(_component_SocioEconomicMapSelection, {
          groups: $props.operations,
          scope: 'Operation',
          "current-query": $data.currentQuery,
          "current-region": $props.currentRegion,
          "current-operation": $props.currentOperation,
          "search-label": $props.searchLabel,
          placeholder: $props.operationLabel,
          onSetOperation: $options.setOperation,
          onUnset: $options.unsetQuery
        }, null, 8, ["groups", "current-query", "current-region", "current-operation", "search-label", "placeholder", "onSetOperation", "onUnset"]),
        _createElementVNode("button", {
          type: "button",
          class: "md:hidden absolute top-5 right-5 w-4 h-4 text-black-500 cursor-pointer",
          "aria-label": $options.$globalLabels.close,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.closeFiltering && $options.closeFiltering(...args)))
        }, [
          _createVNode(_component_Icon, {
            name: "close",
            class: "w-full h-full stroke-current"
          })
        ], 8, _hoisted_3),
        _createElementVNode("button", {
          type: "button",
          class: "hidden md:flex items-center justify-start ml-4 text-black-500 cursor-pointer",
          "aria-label": $options.$globalLabels.close,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.closeFiltering && $options.closeFiltering(...args)))
        }, [
          _createVNode(_component_Icon, {
            name: "double-chevron-left",
            class: "w-4 h-4 stroke-current"
          })
        ], 8, _hoisted_4)
      ])
    ]),
    _: 1
  }))
}