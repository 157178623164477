<!--
*******************************************************************************************************
* This Vue-class is a copy of
* https://github.com/uncleLian/vue-position-sticky/blob/master/src/vue-position-sticky.vue
*
* ... only... fixed for and built using Vue3, which makes it work (instead of throwing runtime errors).
*******************************************************************************************************
-->
<template>
    <div :style="wrapperStyles">
        <div :class="[{'fixed z-999': sticky}, stickyClass]" :style="stickyStyles">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
/*
* @params
* offsetTop     Offset from the top of the window
* offsetBottom  Offset from the bottom of the window
* stickyClass   Customizing the class name of a sticky element
*
* @callback
* change        Sticky changed
*/
import {nextTick} from "vue";

export default {
    props: {
        offsetTop: {type: Number, default: 0},
        offsetBottom: {type: Number},
        stickyClass: {type: String}
    },
    emits: ['change'],
    data() {
        return {
            sticky: false,
            wrapperStyles: {}, // Outer container styles
            stickyStyles: {} // Sticky container styles
        };
    },
    computed: {
        // sticky type
        offsetType() {
            let type = 'top';
            if (this.offsetBottom >= 0) {
                type = 'bottom';
            }
            return type;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, false);
        window.addEventListener('resize', this.handleResize, false);
    },
    beforeUnmount(): void {
        window.removeEventListener('scroll', this.handleScroll, false);
        window.removeEventListener('resize', this.handleResize, false);
    },
    methods: {
        // Get scrollbar position
        getScroll(target, top) {
            const page = top ? 'pageYOffset' : 'pageXOffset';
            return target[page];
        },
        // Get the position of the element in the document
        getOffset(element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = this.getScroll(window, true);
            const scrollLeft = this.getScroll(window);

            // Subtracting clientTop and clientLeft is a solution to the problem
            // that IE's getBoundingClientRect method starts at (2, 2).
            const docEl = window.document.body;
            const clientTop = docEl.clientTop || 0;
            const clientLeft = docEl.clientLeft || 0;
            return {
                top: rect.top + scrollTop - clientTop,
                left: rect.left + scrollLeft - clientLeft,
                width: rect.width,
                height: rect.height
            };
        },
        handleScroll() {
            const sticky = this.sticky;
            const scrollTop = this.getScroll(window, true);
            const elOffset = this.getOffset(this.$el);
            const windowHeight = window.innerHeight;
            const elHeight = this.$el.getElementsByTagName('div')[0].offsetHeight;

            // Fixed Top
            if ((elOffset.top - this.offsetTop) <= scrollTop && this.offsetType === 'top' && !sticky) {
                this.sticky = true;
                this.wrapperStyles = {
                    'width': `${elOffset.width}px`,
                    'min-width': `${elOffset.width}px`,
                    'height': `${elOffset.height}px`,
                    'min-height': `${elOffset.height}px`
                };
                this.stickyStyles = {
                    'top': `${this.offsetTop}px`,
                    'left': `${elOffset.left}px`,
                    'width': `${this.$el.offsetWidth}px`
                };
                this.$emit('change', true);
            } else if ((elOffset.top - this.offsetTop) > scrollTop && this.offsetType === 'top' && sticky) {
                this.sticky = false;
                this.wrapperStyles = null;
                this.stickyStyles = null;
                this.$emit('change', false);
            }

            // Fixed Bottom
            if ((elOffset.top + this.offsetBottom + elHeight) > (scrollTop + windowHeight) && this.offsetType === 'bottom' && !sticky) {
                this.sticky = true;
                this.wrapperStyles = {
                    'width': `${elOffset.width}px`,
                    'min-width': `${elOffset.width}px`,
                    'height': `${elOffset.height}px`,
                    'min-height': `${elOffset.height}px`
                };
                this.stickyStyles = {
                    'bottom': `${this.offsetBottom}px`,
                    'left': `${elOffset.left}px`,
                    'width': `${this.$el.offsetWidth}px`
                };
                this.$emit('change', true);
            } else if ((elOffset.top + this.offsetBottom + elHeight) < (scrollTop + windowHeight) && this.offsetType === 'bottom' && sticky) {
                this.sticky = false;
                this.wrapperStyles = null;
                this.stickyStyles = null;
                this.$emit('change', false);
            }
        },
        handleResize() {
            this.sticky = false;
            this.wrapperStyles = null;
            this.stickyStyles = null;
            nextTick(() => {
                this.handleScroll();
            });
        }
    }
};
</script>
