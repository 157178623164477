import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle($data.wrapperStyles)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{'fixed z-999': $data.sticky}, $props.stickyClass]),
      style: _normalizeStyle($data.stickyStyles)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 6)
  ], 4))
}