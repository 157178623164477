<template>
    <div>
        <TabHeader v-model="currentFilter"
                   :tabs="tabs"
                   :in-content-group="inContentGroup"
                   narrow
                   :component-uid="$.uid"
                   :hide-when-single-tab="true"
        />
        <div :class="{'container': !inContentGroup}">
            <EventsYear v-if="filterShown" v-model="currentYear" :options="yearOptions" :label="labels.filter" />
            <div class="w-full mx-auto mt-3 md:mt-8 xl:mt-12" :class="{'xl:w-2/3': !inContentGroup}">
                <EventsTable v-show="!loading" :events="events" :labels="labels" :show-alert="!filterShown" />
            </div>
        </div>
        <div v-if="pages > 1" class="mt-4 md:mt-8 xl:mt-12" :class="{'container': !inContentGroup}">
            <div class="w-full mx-auto" :class="{'xl:w-2/3': !inContentGroup}">
                <Pagination v-model="currentPage" :pages="pages" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import TabHeader from "../base/TabHeader.vue";
import EventsYear from "./EventsYear.vue";
import EventsTable from "./EventsTable.vue";
import Pagination from "../base/Pagination.vue";

export interface Filter {
    type: string;
    label: string;
    options: string[];
    default: string;
}

export interface EventsLabels {
    filter: string;
    upcoming: string;
    previous: string;
    date: string;
    event: string;
    alert: string;
    addToCalendar: string;
}

export interface CalendarEvent {
    dateFrom: string;
    dateTo: string;
    icsLink: string;
    labelAddToCalendar: string;
    link: string;
    location: string;
    title: string;
}

export default {
    components: {Pagination, EventsTable, EventsYear, TabHeader},
    inject: ['$site'],
    props: {
        filters: {type: Array as PropType<Array<Filter>>, default: () => []},
        tags: {type: Array as PropType<Array<string>>, default: () => []},
        exclude: {type: Array as PropType<Array<string>>, default: () => []},
        labels: {type: Object as PropType<EventsLabels>, default: () => {}},
        itemsPerPage: {type: Number, default: 10},
        inContentGroup: {type: Boolean, default: false}
    },
    data() {
        return {
            apiUrl : Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SEARCH_EVENT, this.$site),

            currentFilter : 0,
            currentYear : '',
            currentPage : 1,
            pages : 1,
            loading : false,

            events: []
        };
    },
    computed: {
        filterShown(): boolean {
            return this.currentFilter === 1;
        },
        tabs(): string[] {
            return this.filters.map(f => f.label);
        },
        yearOptions(): string[] {
            if (this.filters[this.currentFilter]) {
                return this.filters[this.currentFilter].options;
            }
            return [];
        },
        tagsQuery(): string {
            return this.tags.map(tag => `&tags=${tag}`).join('');
        },
        excludedQuery(): string {
            return this.exclude.map(tag => `&excluded=${tag}`).join('');
        }
    },
    watch: {
        async currentFilter() {
            this.loading = true;
            const defaultYear = this.yearOptions.length > 0 ? this.yearOptions[0] : '';
            // prevent unnecessary api calls
            const fetch = defaultYear === this.currentYear && !!this.currentYear;
            if (fetch) {
                this.currentPage = 1;
                this.getEvents();
            } else {
                this.currentYear = defaultYear;
            }
        },
        async currentYear() {
            this.currentPage = 1;
            this.getEvents();
        },
        async currentPage() {
            this.getEvents();
        }
    },
    created(): void {
        if (this.filters.length > 0) {
            this.currentYear = this.filters[0].default;
        }
    },
    mounted(): void {
    },
    methods: {
        getEvents(): void {
            const url = `${this.apiUrl}upcoming=${this.currentFilter === 0}&offset=${(this.currentPage - 1) * this.itemsPerPage}&limit=${this.itemsPerPage}` +
                `&year=${this.currentYear}&reload=true&locale=${Utils.getLocalStorage(Enums.STORAGE_KEY.LANGUAGE)}` +
                `${this.tagsQuery}${this.excludedQuery}`;
            Utils.getAPI(url).then(data => {
                this.events = data.content;
                this.pages = data.pages === -1 ? this.pages : data.pages;
                this.loading = false;
            });
        }
    }
};
</script>
