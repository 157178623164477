import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  ref: "dialogEL",
  class: "max-w-full max-h-full bg-white px-8 py-14 xl:p-16 backdrop:bg-black-900 backdrop:bg-opacity-20 backdrop:backdrop-filter backdrop:backdrop-blur"
}
const _hoisted_2 = { class: "flex flex-column items-center justify-center" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("dialog", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("button", {
                        class: "absolute right-0 top-0 flex justify-center items-center w-10 h-10 text-white bg-primary-400 hover:bg-primary-600 focus:bg-primary-600 transition-colors",
                        autofocus: "",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.hide && $options.hide(...args)))
                      }, [
                        _createVNode(_component_Icon, {
                          name: "close",
                          class: "w-4 h-4 stroke-current"
                        })
                      ]),
                      (!$props.content)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            ref: "imageEl",
                            class: "max-w-lightbox-t xl:max-w-lightbox-d max-h-lightbox-t xl:max-h-lightbox-d",
                            src: $props.src,
                            alt: $options.alt,
                            onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onLoad && $options.onLoad(...args)))
                          }, null, 40, _hoisted_3))
                        : _createCommentVNode("", true),
                      ($props.content)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            ref: "svgContainer",
                            class: "svg-image",
                            innerHTML: $props.content
                          }, null, 8, _hoisted_4))
                        : _createCommentVNode("", true),
                      ($props.caption)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            ref: "captionEl",
                            class: "prose-caption text-para-xs border-l border-primary-500 pl-4 mt-4 xl:mt-6 line-clamp-2 w-0",
                            innerHTML: $props.caption
                          }, null, 8, _hoisted_5))
                        : _createCommentVNode("", true)
                    ])
                  ], 512), [
                    [_vShow, !$data.loading]
                  ])
                ]),
                _: 1
              })
            ])),
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_loading_spinner, { class: "w-20 h-20 fill-current" })
            ], 512), [
              [_vShow, $data.loading]
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("button", {
      class: _normalizeClass(["absolute right-2 bottom-2 justify-center items-center text-black-300 hover:text-primary-600 focus:text-primary-600 bg-white w-7 h-7 rounded-full transition-colors", $props.hideMobileIcon ? 'hidden md:flex' : 'flex']),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.show && $options.show(...args)))
    }, [
      _createVNode(_component_Icon, {
        class: "stroke-current w-3 h-3",
        name: "enlarge"
      })
    ], 2)
  ]))
}