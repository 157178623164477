import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative w-full h-full" }
const _hoisted_2 = { class: "w-full h-full max-h-full md:overflow-y-auto pb-10" }
const _hoisted_3 = { class: "w-full flex flex-col text-white pt-2 pb-14 md:pb-10 md:pt-5 px-5 xl:pt-10 xl:pl-10 xl:pr-6 relative min-h-150 md:min-h-0" }
const _hoisted_4 = { class: "text-heading-4 xl:text-heading-3 w-full break-words mb-4 md:mb-6" }
const _hoisted_5 = {
  key: 0,
  class: "text-para-xs mb-5"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "inline-flex no-underline mr-0.5"
}
const _hoisted_8 = {
  key: 4,
  class: "w-full flex flex-col text-black-100 text-para-xxs pt-2 relative mt-20 md:pb-3 md:text-para-xxxs"
}
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "font-semibold mr-1" }
const _hoisted_11 = {
  key: 0,
  class: "text-black-100 mr-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocioEconomicMapDetailDescription = _resolveComponent("SocioEconomicMapDetailDescription")!
  const _component_SocioEconomicMapDetailSlider = _resolveComponent("SocioEconomicMapDetailSlider")!
  const _component_SocioEconomicMapDetailDatasets = _resolveComponent("SocioEconomicMapDetailDatasets")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString($props.entity.title || $props.entity.name), 1),
        ($props.scope === 'Region' && $options.displayOperations)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
              _createTextVNode(_toDisplayString($props.operationsLabel) + ": ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.displayOperations, (operation, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "inline-flex text-white"
                }, [
                  _createElementVNode("a", {
                    class: "cursor-pointer underline",
                    onClick: ($event: any) => ($options.setOperation(operation))
                  }, _toDisplayString(operation.title || operation.name || ''), 9, _hoisted_6),
                  ($options.filterOperations[$options.filterOperations.length - 1] !== operation)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, ","))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        ($props.entity.description && $props.entity.description.trim().length > 0)
          ? (_openBlock(), _createBlock(_component_SocioEconomicMapDetailDescription, {
              key: 1,
              description: $props.entity.description,
              "show-more-label": $props.showMoreLabel,
              "show-less-label": $props.showLessLabel
            }, null, 8, ["description", "show-more-label", "show-less-label"]))
          : _createCommentVNode("", true),
        ($props.entity.images && $props.entity.images.length > 0)
          ? (_openBlock(), _createBlock(_component_SocioEconomicMapDetailSlider, {
              key: 2,
              slides: $props.entity.images
            }, null, 8, ["slides"]))
          : _createCommentVNode("", true),
        ($props.entity.data && $props.entity.data.length > 0)
          ? (_openBlock(), _createBlock(_component_SocioEconomicMapDetailDatasets, {
              key: 3,
              datasets: $props.entity.data
            }, null, 8, ["datasets"]))
          : _createCommentVNode("", true),
        ($props.entity.disclaimer && $props.entity.disclaimer.trim().length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($props.entity.disclaimer || ''), 1))
          : _createCommentVNode("", true)
      ]),
      ($props.entity.factSheet)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: $props.entity.factSheet.url,
            class: "bg-primary-500 shadow-2xl shadow-black absolute bottom-0 md:bottom-0 left-0 px-5 py-3 w-full text-white text-para-xs flex items-center"
          }, [
            _createElementVNode("span", _hoisted_10, _toDisplayString($props.downloadFactsheetLabel), 1),
            ($props.entity.factSheet.size)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, "(" + _toDisplayString($props.entity.factSheet.size) + ")", 1))
              : _createCommentVNode("", true),
            _createVNode(_component_ArrowButton, { direction: "download" })
          ], 8, _hoisted_9))
        : _createCommentVNode("", true)
    ])
  ]))
}