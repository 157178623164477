<template>
    <div>
        <div class="mb-4 xl:mb-7">
            <div v-if="filterByYear || filterByTag" class="flex md:flex-row md:items-center flex-col justify-end px-0 py-0 gap-y-2">
                <span class="text-para-xs font-semibold mr-4">{{ labels.filter }}</span>
                <Dropdown v-if="filterByYear"
                          key="year"
                          v-model="currentFilters.year"
                          class="w-full md:w-auto min-w-40 md:mr-2 last:mr-0 mb-1 md:mb-0"
                          :title="labels.yearFilter"
                          :higher-menu="true"
                >
                    <DropdownItem v-for="(year, i) in years" :key="i" :label="year" :value="year" />
                </Dropdown>
                <template v-if="filterByTag">
                    <Dropdown v-for="(tags, category, index) in categories"
                              :key="index"
                              v-model="currentFilters[category]"
                              class="w-full md:w-auto min-w-40 md:mr-2 last:mr-0 mb-1 md:mb-0"
                              :title="category"
                              :higher-menu="true"
                    >
                        <DropdownItem v-for="(tag, i) in tags" :key="i" :label="tag.tag" :value="tag.uuid" />
                    </Dropdown>
                </template>
            </div>
        </div>
        <ArticleGrid :articles="shownArticles" :show-images="showImages" :highlight="highlight" :labels="labels" />
        <GcButton v-if="hasMore"
                  class="font-semibold mx-auto mt-8"
                  :label="labels.readMore"
                  :secondary="true"
                  @click="loadMore"
        />
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import ArticleGrid from "./ArticleGrid.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import GcButton from "../base/GcButton.vue";

/* Holds all information needed for responsive images */
export interface Asset {
    sourceSet: string;
    source: string;
    sizes: string;
}

export interface Article {
    type: string;
    title: string;
    link: string;
    date: string;
    tags: string[];
    imageUrls: string[];
    asset: Asset;
}

export interface ArticleLabels {
    defaultFilter: string;
    yearFilter: string;
    readMore: string;
    filter: string;
}

/**
 * Component to enable filtering of articles.
 * See articles section with layout `filter-grid`.
 */
export default {
    components: {GcButton, DropdownItem, Dropdown, ArticleGrid},
    inject: ['$lang'],
    props: {
        showImages: {type: Boolean, default: false},
        filterByYear: {type: Boolean, default: false},
        filterByTag: {type: Boolean, default: false},
        highlight: {type: Boolean, default: false},
        maxItems: {type: Number, default: 24},
        chunkSize: {type: Number, default: 18},
        labels: {type: Object as PropType<ArticleLabels>, default: () => {}},
        articles: {type: Array as PropType<Array<Article>>, default: () => []},
        categories: {type: Object as PropType<Record<string, Record<string, string>[]>>, default: () => {}},
        uuid: {type: String, required: true},
        yearOptions: {type: Array as PropType<Array<string>>, default: () => []}
    },
    data() {
        return {
            apiUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.LOCATION_V2 + Enums.API.ARTICLES,
            years: [],

            // only use one model for all filters
            currentFilters: {},

            shownArticles: [],
            offset: 0,
            hasMore: false,
            init: false
        };
    },
    computed: {
        filterStorageKey(): string {
            return 'articleFilterGrid_' + this.uuid;
        },
        yearFilter(): string {
            return this.currentFilters.year && this.currentFilters.year !== this.labels.defaultFilter ? `&year=${this.currentFilters.year}` : '';
        },
        tagFilter(): string {
            const setFilters = Object.keys(this.currentFilters)
                .filter(c => c !== 'year') // filter out years since this will be treated differently
                .filter(c => this.currentFilters[c] && this.currentFilters[c] !== '0')
                .map(c => `&tag=${this.currentFilters[c]}`);
            return setFilters.join('');
        }
    },
    watch: {
        currentFilters: {
            async handler() {
                // check if any filters are actually set
                if (Object.keys(this.currentFilters).some(c => this.currentFilters[c])) {
                    this.init = true;
                }
                if (this.init) {
                    this.offset = 0;
                    this.getArticles(this.maxItems);
                    sessionStorage.setItem(this.filterStorageKey, JSON.stringify(this.currentFilters));
                }
            },
            deep: true  // deep watch, because object
        }
    },
    /**
     * Collect years, initialize category state object
     */
    created(): void {
        // init years and year model
        this.years = [
            this.labels.defaultFilter,
            ...this.yearOptions
        ];
        this.currentFilters['year'] = null;

        // add default category and init categories model
        for (const [key, value] of this.categories) {
            value.unshift({
                uuid: '0',
                tag: this.labels.defaultFilter
            });
            this.currentFilters[key] = null;
        }

        this.shownArticles = this.articles;
        this.offset = this.shownArticles.length;
        this.hasMore = this.shownArticles.length === this.maxItems;

        this.setTagFromQueryOrStorage();
    },
    methods: {
        getUrl(limit: number): string {
            return `${this.apiUrl}uuid=${this.uuid}&locale=${this.$lang}${this.yearFilter}${this.tagFilter}&offset=${this.offset}&limit=${limit}`;
        },
        setTagFromQueryOrStorage(): void {
            const params = new URLSearchParams(window.location.search);
            // tag from query has priority over session storage
            if (params.has('tag')) {
                const tag = params.get('tag');
                // actually we also need to check the categories if one of them actually contains the given tag
                Object.keys(this.categories).forEach(key => {
                    if (this.categories[key].findIndex(t => t.uuid === tag) >= 0) {
                        // set the tag
                        this.currentFilters[key] = tag;
                    }
                });
            } else if (sessionStorage.getItem(this.filterStorageKey)) {
                // load from session storage and apply all non-null/default values to model
                const filters: Record<string, string> = JSON.parse(sessionStorage.getItem(this.filterStorageKey));
                Object.keys(filters)
                    .filter(f => !!filters[f] && filters[f] !== '0' && filters[f] !== this.labels.defaultFilter)
                    .forEach(f => this.currentFilters[f] = filters[f]);
            }
        },
        getArticles(limit: number): void {
            axios.get(this.getUrl(limit)).then(res => {
                if (this.offset === 0) {
                    this.shownArticles = res.data;
                } else {
                    this.shownArticles = [
                        ...this.shownArticles,
                        ...res.data
                    ];
                }
                this.offset += limit;
                // if number of delivered articles is same as limit, assume there are more articles to load
                this.hasMore = res.data.length === limit;
            });
        },
        loadMore(): void {
            this.getArticles(this.chunkSize);
        }
    }
};
</script>
