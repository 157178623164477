<template>
    <div class="flex justify-start">
        <div class="relative flex items-center gap-2">
            <span id="share_label" class="text-para-xs">
                {{ label }}
            </span>
            <button class="flex justify-center items-center w-8 h-8 rounded-full cursor-pointer hover:bg-primary-600 hover:text-white focus:bg-primary-600 focus:text-white"
                    :class="{'bg-black-50 text-black-900': !opened, 'bg-primary-600 text-white': opened}"
                    aria-labelledby="share_label"
                    tabindex="0"
                    @click="opened = !opened"
            >
                <Icon name="social-share" class="w-4 h-4 fill-current stroke-current" />
            </button>
            <CollapseTransition dimension="width">
                <ul v-show="opened" class="absolute left-full flex items-center gap-2 ml-2">
                    <li v-for="network in networks" :key="network.network" class="group">
                        <button v-if="network.isNetwork"
                                :aria-label="network.label"
                                type="button"
                                class="block"
                        >
                            <share-network v-slot="{ share }"
                                           :network="network.network"
                                           :url="pageUrl"
                                           :title="pageTitle"
                                           :description="description"
                                           :quote="quote"
                                           :hashtags="hashtags"
                                           :twitter-user="network.twitterUser"
                            >
                                <SharingIcon :network="network.network" @click="share" />
                            </share-network>
                        </button>
                        <a v-else
                           :href="network.url"
                           :aria-label="network.label"
                           target="_blank"
                        >
                            <SharingIcon :network="network.network" />
                        </a>
                    </li>
                </ul>
            </CollapseTransition>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Icon from "../base/Icon.vue";
import SharingIcon from "../base/SharingIcon.vue";
import CollapseTransition from "../base/CollapseTransition.vue";

export interface SocialNetwork {
    isNetwork: boolean;
    network: string;
    label: string;
    twitterUser?: string;
    url?: string;
}

export default {
    components: {CollapseTransition, SharingIcon, Icon},
    props: {
        networks: {type: Array as PropType<Array<SocialNetwork>>, required: true},
        pageUrl: {type: String, required: true},
        pageTitle: {type: String, required: true},
        label: {type: String, default: 'share'},
        description: {type: String},
        quote: {type: String},
        hashtags: {type: String}
    },
    data() {
        return {
            opened: false
        };
    }
};
</script>
