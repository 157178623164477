import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "hidden md:flex flex-row flex-wrap items-center text-para-xs gap-y-2 h-min" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["href", "aria-label"]
const _hoisted_4 = {
  key: 0,
  class: "text-black-300 mx-4"
}
const _hoisted_5 = {
  key: 0,
  class: "text-black-300 mx-4"
}
const _hoisted_6 = { class: "text-black-300 mx-4" }
const _hoisted_7 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        _withDirectives(_createElementVNode("li", {
          class: "flex items-center text-black-600 max-w-50 whitespace-nowrap py-2",
          title: item.label
        }, [
          _createElementVNode("a", {
            href: item.url,
            class: "overflow-hidden overflow-ellipsis",
            "aria-label": item.label
          }, _toDisplayString(item.label), 9, _hoisted_3),
          (index < ($props.items.length - 1))
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createVNode(_component_Icon, {
                  name: "chevron-right",
                  class: "stroke-current w-2.5 h-2.5"
                })
              ]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2), [
          [_vShow, $options.isItemVisible(index)]
        ]),
        ($options.showDots && index === 1)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: "flex items-center text-black-600 cursor-pointer py-2",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.opened = true))
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("span", null, "...", -1)),
              (index < ($props.items.length - 1))
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createVNode(_component_Icon, {
                      name: "chevron-right",
                      class: "stroke-current w-2.5 h-2.5"
                    })
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    ($props.items.length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_Icon, {
              name: "chevron-right",
              class: "stroke-current w-2.5 h-2.5"
            })
          ]),
          _createElementVNode("li", {
            class: "text-black-900 bg-black-100 rounded-full max-w-50 overflow-hidden whitespace-nowrap overflow-ellipsis px-3 py-2",
            title: $props.currentPageLabel
          }, _toDisplayString($props.currentPageLabel), 9, _hoisted_7)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}