<template>
    <div>
        <ul v-show="!currentMember"
            class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 xl:gap-12 py-4 md:py-8 xl:py-12"
            :class="{'xl:grid-cols-4': !inContentGroup}"
        >
            <li v-for="(member, index) in members" :key="index" class="flex flex-row md:flex-col">
                <div class="w-16 h-16 mr-4 md:mr-0 md:mb-4" :class="imgSize">
                    <img class="w-full h-full object-cover rounded-full" :src="member.image" :alt="member.name">
                </div>
                <div>
                    <p class="text-para-base md:text-para-lg">
                        {{ member.name }}
                    </p>
                    <p class="text-para-xs md:text-para-s mb-2 md:mb-3">
                        {{ member.job }}
                    </p>
                    <button class="inline-flex items-center text-para-xs md:text-para-s font-semibold focus:outline-none group"
                            @click="showProfile(member.uuid)"
                    >
                        <span>{{ labels.viewProfile }}</span>
                        <ArrowButton direction="right" class="w-10 h-10" />
                    </button>
                </div>
            </li>
        </ul>

        <div v-if="currentMember">
            <div class="relative w-full pt-14 md:pt-18 pb-6 md:pb-10 xl:pb-14 mx-auto"
                 :class="{'xl:w-2/3': !inContentGroup}"
            >
                <button class="absolute top-0 right-0 flex items-center justify-center text-white bg-primary-500
                               w-10 h-10 focus:outline-none transition-all duration-100 ease-out hover:bg-black-900"
                        :aria-label="labels.close"
                        @click="close"
                >
                    <svg class="w-4 h-4 stroke-current" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M2.375 13.624l11.25-11.25M13.625 13.624L2.375 2.374" stroke-linecap="round"
                              stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <div class="flex flex-col md:flex-row items-center mb-5 md:mb-12">
                    <div class="w-35 h-35 md:w-50 md:h-50 min-w-35 md:min-w-50 mb-1 md:mb-0 md:mr-8 xl:mr-12">
                        <img class="w-full h-full object-cover rounded-full mb-1.5 md:mb-0" :src="currentMember.image"
                             :alt="currentMember.name"
                        >
                    </div>
                    <div class="w-full md:w-auto">
                        <p class="text-para-base md:text-para-lg text-center md:text-left">
                            {{ currentMember.name }}
                        </p>
                        <p class="text-para-xs md:text-para-s text-center md:text-left mb-4">
                            {{ currentMember.job }}
                        </p>
                        <div v-if="currentMember.workplace" class="text-para-xs md:text-para-s"
                             v-html="currentMember.workplace"
                        />
                    </div>
                </div>
                <div class="mb-4 md:mb-12">
                    <p class="text-para-base md:text-para-xl font-semibold mb-1 md:mb-2">
                        {{ labels.appointed }}
                    </p>
                    <div class="text-para-s md:text-para-lg" v-html="currentMember.appointed" />
                </div>
                <div class="mb-4 md:mb-10">
                    <p class="text-para-base md:text-para-xl font-semibold mb-1 md:mb-2">
                        {{ labels.experience }}
                    </p>
                    <div class="text-para-s md:text-para-lg" v-html="currentMember.experience" />
                </div>
                <button class="flex items-center focus:outline-none group" @click="close">
                    <span class="text-para-xs md:text-para-s font-semibold mr-3 md:mr-4">
                        {{ labels.close }}
                    </span>
                    <ArrowButton class="w-10 h-10" direction="close" />
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import {PropType} from 'vue';
import Utils from '../../utils/Utils';
import ArrowButton from "../base/ArrowButton.vue";

export interface Member {
    uuid: string;
    name: string;
    job: string;
    image: string;
    smallImage: string;
    workplace: string;
    experience: string;
    appointed: string;
    priority: number;
}

export interface LeadershipLabels {
    close: string;
    viewProfile: string;
    appointed: string;
    experience: string;
}

export default {
    components: {ArrowButton},
    props: {
        members: {type: Array as PropType<Array<Member>>, default: () => []},
        labels: {
            type: Object as PropType<LeadershipLabels>, default: () => {
            }
        },
        inContentGroup: {type: Boolean, default: false}
    },
    data() {
        return {
            currentProfile: ''
        };
    },
    computed: {
        currentMember(): Member {
            return this.currentProfile ? this.members.find(m => m.uuid === this.currentProfile) : undefined;
        },
        imgSize(): Record<string, boolean> {
            return {
                'md:w-50 md:h-50': !this.inContentGroup,
                'md:w-40 md:h-40': this.inContentGroup
            };
        }
    },
    created(): void {
        const uuid = Utils.getUrlParam('uuid');
        if (uuid && this.members.findIndex(m => m.uuid === uuid) >= 0) {
            this.currentProfile = uuid;
        }
    },
    methods: {
        showProfile(uuid: string): void {
            this.currentProfile = uuid;
        },
        close(): void {
            this.currentProfile = '';
        }
    }
};
</script>
