<template>
    <Transition name="fade">
        <div
            v-if="location !== null"
            class="fixed top-0 left-0 overflow-x-auto w-screen h-screen bg-black-50 z-9000 md:absolute md:w-5/12 md:h-120 md:z-30 xl:h-180 xl:w-1/4 md:shadow-2xl"
            :class="{
                'xl:left-0': hideCategories,
                'xl:left-1/4': !hideCategories,
            }"
        >
            <div
                class="flex flex-wrap justify-start items-start py-10 px-6 xl:px-10"
            >
                <div
                    class="w-full flex justify-start items-center mb-4"
                    :class="{ 'xl:mb-6': !location.image }"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <circle
                            cx="12"
                            cy="12"
                            r="8"
                            :fill="location.category.color"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            r="11.5"
                            :stroke="location.category.color"
                        />
                    </svg>
                    <span class="ml-3 text-para-base font-semibold">
                        {{ location.label }}
                    </span>
                </div>
                <div
                    v-if="location.images && location.images.length > 0"
                    class="flex flex-grow justify-center items-center mb-4 -mx-6 xl:-mx-10"
                >
                    <!--  tailwind comment to prevent purge: after:border-primary-400 bg-primary-400 -->
                    <SocioEconomicMapDetailSlider
                        slide-classes="w-full"
                        container-classes="px-6 xl:px-10"
                        pager-color="primary-400"
                        :slides="location.images"
                    />
                </div>
                <div
                    class="w-full flex justify-start items-center text-para-s"
                    :class="{ 'mb-4': location.url }"
                    v-html="location.description"
                />
                <div
                    v-if="location.url"
                    class="w-full flex justify-start items-center"
                >
                    <a
                        :href="location.url"
                        :target="location.target"
                        class="flex items-center justify-start group cursor-pointer"
                    >
                        <span class="text-para-s font-semibold">
                            {{ location.linkLabel || labelVisitWebsite }}
                        </span>
                        <ArrowButton direction="right" class="ml-2 h-10 w-10" />
                    </a>
                </div>
            </div>
            <button
                class="absolute text-white top-0 right-0 w-10 h-10 flex justify-center items-center bg-primary-500 transition-colors hover:bg-primary-700 focus:outline-none"
                @click="closeLocation()"
            >
                <Icon name="close" class="w-4 stroke-current" />
            </button>
        </div>
    </Transition>
</template>

<script lang="ts">
import SocioEconomicMapDetailSlider from "../socioEconomicMap/SocioEconomicMapDetailSlider.vue";
import ArrowButton from "../base/ArrowButton.vue";
import Icon from "../base/Icon.vue";

export default {
    components: { Icon, ArrowButton, SocioEconomicMapDetailSlider },
    props: {
        location: { type: Object, default: null },
        hideCategories: { type: Boolean, default: false },
        labelVisitWebsite: { type: String, default: "" },
    },
    emits: ["close-location"],
    methods: {
        closeLocation() {
            this.$emit("close-location");
        },
    },
};
</script>
