<template>
    <div>
        <!-- Search bar -->
        <div class="bg-primary-50 mb-8">
            <div :class="{'container': !inContentGroup}">
                <div class="py-6 md:py-8" :class="{'xl:w-2/3 mx-auto': !inContentGroup}">
                    <div class="relative w-full text-primary-500">
                        <input v-model="keyword"
                               type="text"
                               class="w-full rounded text-black-600 text-para-s focus:outline-none py-2 px-10 placeholder-improvedContrast"
                               :aria-label="labels.placeholder"
                               :placeholder="labels.placeholder"
                        >
                        <Icon name="search" class="absolute left-4 top-3 w-4 h-4 stroke-current" />
                        <div class="absolute right-4 top-3 cursor-pointer" @click="reset">
                            <Icon v-show="!!keyword" name="close" class="w-4 h-4 stroke-current" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="{'container': !inContentGroup}">
            <div :class="{'xl:w-2/3 mx-auto': !inContentGroup}">
                <!-- Filters -->
                <div v-if="Object.keys(filters).length > 0" class="flex flex-col mb-8 md:mb-12">
                    <span class="text-para-s font-semibold mb-4">{{ labels.filter }}</span>
                    <div class="flex flex-col md:flex-row items-center gap-4 md:gap-8">
                        <template v-if="Object.keys(filterOptions).length > 0">
                            <SearchableGroupDropdown v-for="filter in filterKeys"
                                                     :key="filter"
                                                     v-model="searchCriteria[filter]"
                                                     class="w-full"
                                                     search-label="Search"
                                                     :placeholder="filters[filter]"
                                                     :groups="filterOptions[filter]"
                                                     :sort-group-entries="true"
                            />
                        </template>
                    </div>
                </div>
                <!-- Result count / sorting -->
                <div class="flex flex-col md:flex-row gap-12 mb-2 md:mb-8">
                    <div class="flex flex-col md:flex-row md:items-center md:justify-end gap-4 w-full md:order-2">
                        <span class="text-para-s font-semibold">{{ labels.sortBy }}</span>
                        <Dropdown v-model="sorting" class="w-full md:w-auto min-w-60" title="" higher-menu>
                            <template v-for="(column, key) in columns" :key="key">
                                <DropdownItem :label="column + ' (' + labels.ascending + ')'" :value="key + '-asc'" />
                                <DropdownItem :label="column + ' (' + labels.descending + ')'" :value="key + '-desc'" />
                            </template>
                        </Dropdown>
                    </div>
                    <div class="flex items-center justify-end md:justify-start w-full md:order-1 text-para-s font-semibold">
                        <span>{{ totalResults }} {{ labels.results }}</span>
                    </div>
                </div>
            </div>
            <!-- tablet / desktop table -->
            <div v-if="!isMobile" class="flex flex-col overflow-x-auto pb-4 md:pb-0 px-4 md:px-0 -mx-4 md:mx-0">
                <table class="w-full text-para-s md:text-para-s md:tracking-tight text-left">
                    <thead class="border-b border-black-200">
                        <tr>
                            <th v-for="(column, key) in columns"
                                :key="key"
                                scope="col"
                                class="py-2 px-1 md:py-2 md:px-3 xl:px-4 font-semibold"
                            >
                                <span>{{ column }}</span>
                            </th>
                            <th scope="col"
                                class="py-2 px-1 md:py-2 md:px-3 xl:px-4 font-semibold"
                            />
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="career in careers"
                            :key="career.id"
                            class="even:bg-black-50 hover:bg-primary-100 focus-within:bg-primary-100 transition duration-100 ease-out"
                        >
                            <td v-for="(column, key, index) in columns" :key="key">
                                <a :href="getLink(career)" class="block align-middle py-2 px-1 md:py-2 md:px-3 xl:px-4" tabindex="-1">
                                    <span v-if="(key.toLowerCase()).indexOf('date') >= 0">
                                        <template v-if="isFarFuture(career[key])">
                                            {{ labels.never }}
                                        </template>
                                        <template v-else>
                                            {{ $filters.formatDate(career[key]) }}
                                        </template>
                                    </span>
                                    <span v-else-if="key === 'combinedLocation'">
                                        {{ getCombinedLocation(career) }}
                                    </span>
                                    <div v-else>
                                        <span :class="{'mr-4': index === 0 && isNew(career.startDate)}">{{ career[key] }}</span>
                                        <span v-if="index === 0 && isNew(career.startDate)"
                                              class="relative -top-px text-para-xs text-primary-900 bg-primary-50 rounded-full px-2.5 py-1"
                                        >
                                            {{ labels.new }}
                                        </span>
                                    </div>
                                </a>
                            </td>
                            <td>
                                <a :href="getLink(career)" class="inline-block align-middle py-2 px-1 md:py-2 md:px-3 xl:px-4" @keyup.enter="openLink(career)">
                                    <Icon name="arrow-right" class="w-4 h-4 stroke-current" />
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- mobile view -->
            <div v-else class="border-t border-black-200">
                <a v-for="(career, index) in careers"
                   :key="index"
                   :href="getLink(career)"
                   class="block py-4 px-2"
                   :class="{'bg-black-50': (index % 2) !== 0}"
                >
                    <div>
                        <span class="text-para-s font-semibold" :class="{'mr-2': isNew(career.startDate)}">
                            {{ career.title }}
                        </span>
                        <span v-if="isNew(career.startDate)"
                              class="relative -top-0.5 text-para-xs text-primary-900 bg-primary-50 rounded-full px-2.5 py-1"
                        >
                            {{ labels.new }}
                        </span>
                    </div>
                    <span class="text-para-xs text-black-600 mt-2">
                        {{ $filters.formatDate(career.startDate) }}
                    </span>
                    <div class="prose-list mt-8">
                        <ul class="flex flex-col gap-2 text-para-s">
                            <li>{{ getCombinedLocation(career) }}</li>
                            <li>{{ labels.closingDate }} {{ $filters.formatDate(career.endDate) }}</li>
                        </ul>
                    </div>
                </a>
            </div>
            <!-- pagination -->
            <div class="flex justify-center mt-6">
                <template v-if="!error">
                    <GcButton v-show="hasMore"
                              class="font-semibold"
                              :label="labels.loadMore"
                              :secondary="true"
                              @click="loadCareers"
                    />
                    <LoadingSpinner :class="{'invisible': !loading}" class="w-10 h-10 fill-current text-primary-500" />
                </template>
                <template v-else>
                    <span class="text-error text-para-s">{{ labels.error }}</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {nextTick, PropType} from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios, {AxiosResponse} from 'axios';
import breakpoints from '../../plugins/breakpoints';
import debounce from 'lodash/debounce';
import SearchableGroupDropdown from "../base/SearchableGroupDropdown.vue";
import LoadingSpinner from "../base/LoadingSpinner.vue";
import GcButton from "../base/GcButton.vue";
import Icon from "../base/Icon.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";

export interface Career {
    id: number;
    jobId: string;
    feed: string;
    title: string;
    language: string;
    locationTitle: string;
    department: string;
    city: string;
    region: string;
    country: string;
    channel: string;
    highlights: string[];
    description: string;
    url: string;
    applicationLink: string;
    startDate: number;
    endDate: number;
    lastUpdated: number;
    version: number;
}

interface CareerLabels {
    placeholder: string;
    filter: string;
    results: string;
    sortBy: string;
    new: string;
    closingDate: string;
    loadMore: string;
    ascending: string;
    descending: string;
    never: string;
    error: string;
}

export default {
    components: {DropdownItem, Dropdown, Icon, GcButton, LoadingSpinner, SearchableGroupDropdown},
    inject: ['$lang'],
    props: {
        limit: {type: Number, default: 10},
        filters: {type: Object as PropType<Record<string, string>>, default: () => {}},
        preFilters: {type: Object as PropType<Record<string, string>>, default: () => {}},
        hardcodedFilters: {type: Object as PropType<Record<string, string>>, default: () => {}},
        columns: {type: Object as PropType<Record<string, string>>, default: () => {}},
        countries: {type: Array as PropType<Array<string>>, default: () => []},
        labels: {type: Object as PropType<CareerLabels>, default: () => {}},
        inContentGroup: {type: Boolean, default: false},
        farFutureThreshold: {type: Number, default: 10},
        uuid: {type: String, default: ''},
        localeOverride: {type: String, default: ''}
    },
    data() {
        return {
            // threshold in days which will decide if a posting is considered new
            newThreshold: 14,

            careers: [],

            filterData: {},
            initialFilterData: {},

            initialized: false,
            currentPage: 0,
            totalResults: 0,
            loading: false,
            error: false,

            keyword: '',
            lastKeyword: '',
            sorting: '',

            searchCriteria: {},

            apiBaseUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) +
                Enums.API.LOCATION_V2 + Enums.API.CAREERS +
                'locale=' + (this.localeOverride || this.$lang),

            debouncedKeywordSearch: debounce(this.keywordSearch, 500)
        };
    },
    computed: {
        filterStorageKey(): string {
            return 'careerFilters_' + this.uuid;
        },
        filterKeys(): string[] {
            return Object.keys(this.filters);
        },
        filterOptions(): Record<string, Record<string, string[]>> {
            const options: Record<string, Record<string, string[]>> = {};
            Object.keys(this.filterData).forEach(fd => {
                const option: Record<string, string[]> = {};
                option[this.filters[fd]] = this.filterData[fd];
                options[fd] = option;
            });
            return options;
        },
        apiUrl(): string {
            return this.apiBaseUrl +
                this.countries.map(c => '&country=' + c).join('') +
                '&sortBy=' + this.sorting +
                '&offset=' + (this.currentPage * this.limit) +
                '&limit=' + this.limit +
                '&searchCriteria=' + encodeURI(JSON.stringify(this.extendedSearchCriteria)) +
                '&keyword=' + encodeURI(this.lastKeyword.trim());
        },
        hasMore(): boolean {
            return this.currentPage > 0 && (this.totalResults > (this.currentPage * this.limit));
        },
        isMobile(): boolean {
            return breakpoints.mobile;
        },
        extendedSearchCriteria(): Record<string, string[]> {
            const extendedSearchCriteria = {};
            Object.keys(this.searchCriteria).forEach(k => {
                extendedSearchCriteria[k] = [this.searchCriteria[k]];
            });
            Object.keys(this.hardcodedFilters).forEach(k => {
                const l = extendedSearchCriteria[k] || [];
                l.push(this.hardcodedFilters[k]);
                extendedSearchCriteria[k] = l;
            });
            return extendedSearchCriteria;
        }
    },
    watch: {
        searchCriteria: {
            async handler() {
                this.search();
            },
            deep: true  // deep watch, because object
        },
        async sorting() {
            this.search();
        },
        async keyword() {
            this.debouncedKeywordSearch();
        }
    },
    mounted(): void {
        // sort by first column ascending by default
        if (Object.keys(this.columns).length > 0) {
            this.sorting = Object.keys(this.columns)[0] + '-asc';
        }

        // set default values to filters
        Object.keys(this.filters).forEach(f => this.searchCriteria[f] = '');

        if (sessionStorage.getItem(this.filterStorageKey)) {
            // if applicable: override filters with state retrieved from session storage
            const criteria: Record<string, string> = JSON.parse(sessionStorage.getItem(this.filterStorageKey));
            Object.keys(criteria).forEach(c => this.searchCriteria[c] = criteria[c]);
        }
        // set prefilters and hardcoded filters as last
        Object.keys(this.preFilters).forEach(f => this.searchCriteria[f] = this.preFilters[f]);

        // wait for watchers to initially trigger before continuing
        nextTick(() => {
            this.initialized = true;
            this.loadCareers();
        });
    },
    methods: {
        isFarFuture(date: number): boolean {
            const current = new Date();
            current.setFullYear(current.getFullYear() + this.farFutureThreshold);
            return date > current.getTime();
        },
        getCombinedLocation(career: Career): string {
            // INFO: the 'zwsp' value is used in the location mapper for unknown locations
            const locationParts = [];

            if (career.city && career.city !== '​') {
                locationParts.push(career.city);
            }
            if (career.region && career.region !== '​') {
                locationParts.push(career.region);
            }
            if (career.country && career.country !== '​') {
                locationParts.push(career.country);
            }

            return locationParts.join(', ');
        },
        isNew(postingDate: number): boolean {
            const date = new Date();
            date.setDate(date.getDate() - this.newThreshold);
            return postingDate > date.getTime();
        },
        getLink(career: Career): string {
            let base: string = window.location.pathname;
            if (!base.endsWith('/')) { // prevent adding duplicate '/'
                base += '/';
            }
            return base + encodeURI(career.jobId) + (this.localeOverride ? '?locale=' + this.localeOverride : '');
        },
        openLink(career: Career): void {
            const url: string = this.getLink(career);
            window.open(url, '_self');
        },
        loadCareers(): void {
            this.loading = true;
            this.error = false;
            axios.get(this.apiUrl).then((res) => {
                if (res.status === 200) {
                    if (this.currentPage === 0) {
                        this.careers = res.data.data;
                        this.totalResults = res.data.totalResults;
                        this.applyFilters(res);
                    } else {
                        this.careers = this.careers.concat(res.data.data);
                    }
                    this.currentPage++;
                }
            }).catch(() => {
                this.error = true;
            }).finally(() => {
                this.loading = false;
            });
        },
        applyFilters(res: AxiosResponse<any>) {
            // normal case: apply filters as delivered by response
            const tmpFilterData: Record<string, string[]> = res.data.filters;
            Object.keys(tmpFilterData).forEach(filterKey => {
                this.filterData[filterKey] = tmpFilterData[filterKey];
            });
            const setFilters = Object.keys(this.searchCriteria).filter(key => !!this.searchCriteria[key]).length;
            if (setFilters === 0 || Object.keys(this.initialFilterData).length === 0) {
                // zero filters or no initial state present: set initial state
                this.initialFilterData = JSON.parse(JSON.stringify(this.filterData));
            } else if (setFilters === 1) {
                // only one filter set: reset the data for this key to initial state
                const key = Object.keys(this.searchCriteria).find(key => !!this.searchCriteria[key]);
                this.filterData[key] = this.initialFilterData[key];
            }
        },
        reset(): void {
            this.keyword = '';
            if (this.lastKeyword) {
                this.lastKeyword = '';
                this.search();
            }
        },
        keywordSearch(): void {
            if (this.keyword.trim().length >= 3) {
                this.lastKeyword = this.keyword.trim();
                this.search();
            } else if (this.lastKeyword) {
                this.lastKeyword = '';
                this.search();
            }
        },
        search(): void {
            if (!this.initialized) return;
            this.currentPage = 0;
            this.loadCareers();
            sessionStorage.setItem(this.filterStorageKey, JSON.stringify(this.searchCriteria));
        }
    }
};
</script>
