<template>
    <li class="w-full h-full">
        <div class="relative h-0 pt-16/9" :style="style">
            <div v-if="(color.hex || '#FFFFFF').toLowerCase() === '#ffffff'"
                 class="absolute w-full h-full top-0 left-0 border-4 border-black-100"
            />
        </div>
        <div class="prose-caption text-para-xs mt-4">
            <p class="font-semibold">{{ title }}</p>
            <ul>
                <template v-for="(value, key) in color" :key="key">
                    <li v-if="value && showValue(key, value)"
                        :key="key"
                        class="flex justify-between items-center group"
                        tabindex="0"
                        @keyup.enter="copyToClipboard(key, value)"
                    >
                        <span class="tracking-tight">{{ labels[key] }} {{ value }}</span>
                        <span v-if="!copied"
                              class="hidden xl:group-hover:flex xl:group-focus:flex items-center gap-1 cursor-pointer"
                              @click="copyToClipboard(key, value)"
                        >
                            <Icon name="copy" class="stroke-current w-4 h-4" />
                            <span class="font-semibold">{{ copyLabel }}</span>
                        </span>
                        <span v-if="copied === key" class="hidden xl:flex items-center gap-1">
                            <Icon name="check" class="stroke-current w-4 h-4" />
                            <span class="font-semibold">{{ copiedLabel }}</span>
                        </span>
                    </li>
                </template>
            </ul>
        </div>
    </li>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Icon from "../base/Icon.vue";

export interface Color {
    pms?: string;
    cmyk?: string;
    rgb?: string;
    ral?: string;
    hex: string;
}

export default {
    components: {Icon},
    props: {
        title: {type: String, required: true},
        color: {type: Object as PropType<Color>, required: true},
        labels: {type: Object as PropType<Color>, required: true},
        copyLabel: {type: String, required: true},
        copiedLabel: {type: String, required: true}
    },
    data() {
        return {
            copied: ''
        };
    },
    computed: {
        style(): Record<string, string> {
            return {
                backgroundColor: this.color.hex
            };
        }
    },
    methods: {
        showValue(key: string, value: string): boolean {
            return key !== 'hex' || (value || '').toLowerCase() !== '#ffffff';
        },
        copyToClipboard(key: string, value: string): void {
            navigator.clipboard.writeText(value);
            this.copied = key;
            setTimeout(() => {
                this.copied = '';
            }, 1500);
        }
    }
};
</script>
