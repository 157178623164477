export interface Position {
    lat: number;
    lng: number;
}

export interface Office {
    position: Position;
    title: string;
    area: string;
    fullAddress: string;
    phoneNumber: string;
    faxNumber: string;
    email: string;
}

export interface Operation {
    uuid: string;
    name: string;
    title: string;
    color: string;
    open?: boolean;
    items: Array<SubOperation>;
}

export interface SubOperation {
    uuid: string;
    title: string;
    name: string;
    color?: string;
    locations: Array<Location>;
    zoomLevelOverride: string;
    hideInSidebar: boolean;
}

export interface Location {
    uuid: string;
    title: string;
    position: Position;
    description?: string;
    regions?: Array<string>;
    operations?: Array<string>;
}

export interface Region {
    uuid: string;
    title: string;
    name: string;
    open?: boolean;
    items: Array<SubRegion>;
}

export interface SubRegion {
    uuid: string;
    title: string;
    name: string;
    zoomLevelOverride: string;
    operations: Array<string>;
}

export default class SocioEconomicMapUtils {
    static getOperationsFromRegion(region: SubRegion, operations: Array<Operation>): Array<Operation> {
        // @TODO Fixme: Heavy Brainfuck
        const result: Array<Operation> = [];
        const referencedOperations: Array<SubOperation|Operation> = region.operations.map(uuid => SocioEconomicMapUtils.findOperation(uuid, operations));
        referencedOperations.filter(operation => operation != null).forEach(operation => {
            if ((operation as Operation).items !== undefined) {
                // is main operation
                if (!(result.find(entry => entry.uuid === (operation as Operation).uuid))) {
                    result.push(operation as Operation);
                }
            } else {
                // is sub operation
                if (result.find(entry => entry.uuid === SocioEconomicMapUtils.getParentOperation(operation as SubOperation, operations).uuid)) {
                    // main operation does exist
                    const parent: Operation = result.find(entry => entry.uuid === SocioEconomicMapUtils.getParentOperation(operation as SubOperation, operations).uuid);
                    result[result.indexOf(parent)] = ({ ...parent, ...{ items: [...parent.items, operation as SubOperation] } }) as Operation;
                } else {
                    // main operation does not exist
                    result.push({ ...SocioEconomicMapUtils.getParentOperation(operation as SubOperation, operations), ...{ items: [{ ...(operation as SubOperation) }] } });
                }
            }
        });
        return result;
    }

    static getSubOperationsFromRegion(region: SubRegion, operations: Array<Operation>): Array<SubOperation> {
        const result: Array<SubOperation> = [];
        SocioEconomicMapUtils.getOperationsFromRegion(region, operations).forEach(operation => {
            operation.items.forEach(subOperation => {
                if (!(result.find(item => item.uuid === subOperation.uuid))) {
                    result.push(subOperation);
                }
            });
        });
        return result;
    }

    static findOperation(uuid: string, operations: Array<Operation>): SubOperation|Operation {
        let result: Operation | SubOperation = null;
        operations.forEach(operation => {
            if (operation.uuid === uuid) {
                result = operation;
            }
            operation.items.forEach(subOperation => {
                if (subOperation.uuid === uuid) {
                    result = subOperation;
                }
            });
        });
        return result;
    }

    static getParentOperation(subOperation: SubOperation, operations: Array<Operation>): Operation {
        return operations.find(operation => operation.items.some(item => item.uuid === subOperation.uuid));
    }

    static makeRegionsDistinctByOperation(regions) {
        const uuids = [];
        return regions.map(region => {
            region.operations = region.operations.filter(operation => {
                const bool = !uuids.includes(operation.uuid);
                uuids.push(operation.uuid);
                return bool;
            });
            return region;
        });
    }

    /**
     * Makes any Google Map instance fit a defined boundary with a defined padding
     * @param map a Google Map instance
     * @param origBounds a set of Google Map Bounds to fit the map to
     * @param paddingXY a padding object (top, right ...) to consider when fitting the map
     * @param moveCenter a number set of x and y defining a camera centering by the respective coordinate value
     * @TODO Add working types for google maps api
     */
    /* eslint-disable */
    // @ts-ignore
    static fitBoundsPaddingCenter(map, origBounds: google.maps.LatLngBounds, paddingXY, moveCenter = {}) {
        const projection = map.getProjection();
        if (projection) {
            if (!paddingXY) paddingXY = { x: 0, y: 0 };

            let zoomBefore = map.getZoom();

            const paddings = {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            };

            if (paddingXY.left) {
                paddings.left = paddingXY.left;
            } else if (paddingXY.x) {
                paddings.left = paddingXY.x;
                paddings.right = paddingXY.x;
            }

            if (paddingXY.right) {
                paddings.right = paddingXY.right;
            }

            if (paddingXY.top) {
                paddings.top = paddingXY.top;
            } else if (paddingXY.y) {
                paddings.top = paddingXY.y;
                paddings.bottom = paddingXY.y;
            }

            if (paddingXY.bottom) {
                paddings.bottom = paddingXY.bottom;
            }

            // copying the bounds object, since we will extend it
            // @ts-ignore
            let bounds = new google.maps.LatLngBounds(
                origBounds.getSouthWest(),
                origBounds.getNorthEast()
            );

            // SW
            let point1 = projection.fromLatLngToPoint(bounds.getSouthWest());

            // we must call fitBounds 2 times - first is necessary to set up a projection with initial (actual) bounds
            // and then calculate new bounds by adding our pixel-sized paddings to the resulting viewport
            map.fitBounds(bounds);

            // @ts-ignore
            let point2 = new google.maps.Point(
                (typeof paddings.left == "number" ? paddings.left : 0) /
                    Math.pow(2, map.getZoom()) || 0,
                (typeof paddings.bottom == "number" ? paddings.bottom : 0) /
                    Math.pow(2, map.getZoom()) || 0
            );

            // @ts-ignore
            let newPoint = projection.fromPointToLatLng(
                new google.maps.Point(point1.x - point2.x, point1.y + point2.y)
            );

            bounds.extend(newPoint);

            // NE
            point1 = projection.fromLatLngToPoint(bounds.getNorthEast());
            // @ts-ignore
            point2 = new google.maps.Point(
                (typeof paddings.right == "number" ? paddings.right : 0) /
                    Math.pow(2, map.getZoom()) || 0,
                (typeof paddings.top == "number" ? paddings.top : 0) /
                    Math.pow(2, map.getZoom()) || 0
            );
            // @ts-ignore
            newPoint = projection.fromPointToLatLng(
                new google.maps.Point(point1.x + point2.x, point1.y - point2.y)
            );

            bounds.extend(newPoint);

            map.fitBounds(bounds);

            let zoomAfter = map.getZoom();

            // bounds are only set if they make to map move (prevent freeze)
            if (zoomBefore === zoomAfter) {
                map.fitBounds(origBounds);
            }

            // move center if defined (and not zoomed in too close)
            if (moveCenter && map.getZoom() <= 6) {
                map.setCenter({
                    lng:
                        map.getCenter().lng() +
                        parseFloat((moveCenter as any).x || 0),
                    lat:
                        map.getCenter().lat() +
                        parseFloat((moveCenter as any).y || 0),
                });
            }
        }
    }
    /* eslint-enable */
}
