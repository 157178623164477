<template>
    <div class="w-full">
        <div v-for="(dataset, index) in datasets" :key="index" class="w-full flex flex-col border-b border-white mb-4">
            <div class="text-heading-3">{{ dataset.unit || "" }}{{ dataset.value || "" }}&nbsp;{{ dataset.scale !== 'none' ? dataset.scale : '' || '' }}</div>
            <div class="text-para-xs mb-1 text-black-100 whitespace-pre-line" v-html="dataset.description" />
        </div>
    </div>
</template>

<script lang="ts">

export default {
    props: {
        datasets: {type: Array, required: true, default: () => ([])}
    }
};
</script>
