<template>
    <div class="relative">
        <div class="flex flex-wrap gap-4" :class="{'flex-col': col || colMobile, 'md:flex-row': colMobile}">
            <button v-for="(key, value) in items"
                    :key="value"
                    type="button"
                    class="bg-black-100 text-para-xs md:text-para-s font-semibold rounded focus:outline-none px-8 py-6 transition-all duration-300 ease-out"
                    :class="[btnClasses, {'bg-black-900 text-white': isSelected(value), 'flex-1': grow || growMobile, 'md:flex-none': growMobile}]"
                    :aria-pressed="isSelected(value)"
                    @click="toggle(value)"
            >
                {{ key }}
            </button>
        </div>
        <slot v-for="(view, i) in currentViews" :key="i" :name="view" />
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';

export default {
    props: {
        items: {type: Object as PropType<Record<string, string>>, default: () =>{}}, // key -> label
        views: {type: Object as PropType<Record<string, string>>, default: () => {}}, // key -> view
        btnClasses: {type: String, default: ''},
        emptyValue: {type: String, default: ''},
        multiSelect: {type: Boolean, default: false},
        grow: {type: Boolean, default: false},
        growMobile: {type: Boolean, default: false},
        col: {type: Boolean, default: false},
        colMobile: {type: Boolean, default: false},
        modelValue: {type: Array as PropType<Array<string>>} // selected key(s)
    },
    emits: ['update:modelValue', 'yes', 'no'],
    data() {
        return {
            currentSelection: []
        };
    },
    computed: {
        currentViews(): Set<string> {
            const result = new Set<string>();
            if (this.views) {
                this.currentSelection.forEach((value: string) => {
                    result.add(this.views[value]);
                });
            }
            return result;
        }
    },
    watch: {
        async currentSelection(newVal: string[]) {
            this.$emit('update:modelValue', this.currentSelection);

            // if no multiselect, also emit the key as event
            if (!this.multiSelect && newVal.length > 0) {
                const key = newVal[0];
                if (key) {
                    this.$emit(key);
                }
            }
        }
    },
    mounted(): void {
        // respect the initial v-model value
        if (this.modelValue) {
            this.currentSelection = this.modelValue;
        }
    },
    methods: {
        isSelected(item: string): boolean {
            return this.currentSelection.indexOf(item) >= 0;
        },
        toggle(value: string) {
            const index = this.currentSelection.indexOf(value);
            if (index >= 0) {
                // remove from selection
                this.currentSelection.splice(index, 1);
                if (this.currentSelection.length === 0) {
                    if (this.emptyValue) {
                        this.currentSelection = [this.emptyValue];
                    }
                }
            } else {
                if (this.multiSelect) {
                    const newSelection = [...this.currentSelection];
                    // add to selection
                    newSelection.push(value);
                    // sort selection by position in items
                    newSelection.sort((a, b) =>
                        Object.keys(this.items).indexOf(a) - Object.keys(this.items).indexOf(b)
                    );
                    this.currentSelection = newSelection;
                } else {
                    // allow only one selection at a time
                    this.currentSelection = [value];
                }
            }
        }
    }
};
</script>
