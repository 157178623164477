import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "py-2 self-start px-1 max-w-full lg:max-w-4/12" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "relative pr-1 w-full max-w-full lg:pl-4 lg:max-w-8/12" }
const _hoisted_4 = { class: "border-collapse table-fixed w-full" }
const _hoisted_5 = { class: "py-2.5 px-4" }
const _hoisted_6 = { class: "w-35 py-2.5 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.groups, (group, g) => {
      return (_openBlock(), _createElementBlock("div", {
        key: g,
        class: "mt-12 flex-wrap justify-between items-center -mx-1 mb-4 lg:flex"
      }, [
        _createElementVNode("div", _hoisted_1, [
          (Object.keys($options.groups).length > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($options.getGroupLabel(g)), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString($props.labelResultsSuffix), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (result, res) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: 'res' + res,
                  class: "odd:bg-black-50 even:bg-white"
                }, [
                  _createElementVNode("td", _hoisted_5, _toDisplayString(result.label), 1),
                  _createElementVNode("td", _hoisted_6, _toDisplayString($options.formatValue(result.value)) + " " + _toDisplayString(result.unit), 1)
                ]))
              }), 128))
            ])
          ])
        ])
      ]))
    }), 128))
  ]))
}