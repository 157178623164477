<template>
    <div class="w-full bg-primary-500 z-30 flex flex-col xl:w-90 xl:h-180 xl:overflow-x-hidden xl:overflow-y-scroll xl:shadow-2xl">
        <div class="w-full h-full py-12 px-4 md:py-16 px-4 md:px-10 xl:px-6" :class="{ 'pb-60 md:pb-60 xl:pb-16': openState }">
            <div v-if="offices.length > 1" class="font-semibold text-para-s text-white">{{ labelSelectOffice }}</div>
            <Dropdown v-if="offices.length > 1"
                      v-model="office"
                      class="w-full md:w-auto min-w-40"
                      :class="[selectedOffice ? 'mb-6' : 'mb-0']"
                      text-classes="text-black-600 text-para-s h-6 truncate"
                      :default-value="null"
                      :title="`${labelSelectOffice}...`"
                      :white="true"
                      @open="openState = true"
                      @close="openState = false"
                      @update:model-value="selectOffice(office)"
            >
                <DropdownItem v-for="(item, i) in offices"
                              :key="i"
                              :label="item.area"
                              :value="item"
                              text-classes="text-para-xs md:text-para-s"
                />
            </Dropdown>
            <div v-if="selectedOffice" class="text-white w-full flex flex-col text-para-base break-word">
                <div v-if="selectedOffice.images && Object.keys(selectedOffice.images).length > 0"
                     class="flex flex-grow justify-center items-center mb-4 -mx-10"
                >
                    <SocioEconomicMapDetailSlider slide-classes="w-full" container-classes="px-10" :slides="selectedOffice.images" />
                </div>
                <h3 v-if="selectedOffice.title" class="text-para-xl font-semibold">{{ selectedOffice.title }}</h3>
                <h4 v-if="selectedOffice.area" class="font-semibold mb-6">{{ selectedOffice.area }}</h4>
                <div class="mb-4" v-html="selectedOffice.fullAddress" />
                <div v-if="selectedOffice.phoneNumber">{{ labelPhone }}: {{ selectedOffice.phoneNumber }}</div>
                <div v-if="selectedOffice.mobileNumber">{{ labelMobile }}: {{ selectedOffice.mobileNumber }}</div>
                <div v-if="selectedOffice.faxNumber">{{ labelFax }}: {{ selectedOffice.faxNumber }}</div>
                <div class="w-full break-words mt-4">{{ selectedOffice.email }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {Office} from './WorldMap.vue';
import {PropType} from 'vue';
import SocioEconomicMapDetailSlider from "../socioEconomicMap/SocioEconomicMapDetailSlider.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";

export default {
    components: {DropdownItem, Dropdown, SocioEconomicMapDetailSlider},
    props: {
        offices: {type: Array as PropType<Array<Office>>, default: () => []},
        selectedOffice: {type: Object as PropType<Office>, default: null},
        labelSelectOffice: {type: String, default: ''},
        labelPhone: {type: String, default: ''},
        labelMobile: {type: String, default: ''},
        labelFax: {type: String, default: ''}
    },
    emits: ['office-select'],
    data() {
        return {
            openState: false,
            office: undefined
        };
    },
    mounted(): void{
        this.selectOffice(this.getDefaultOffice());
    },
    methods: {
        getDefaultOffice(): Office {
            if (this.selectedOffice) {
                return this.selectedOffice;
            }

            // will find the first office with defaultOffice = true
            // if no office matches that condition, return the first office
            const maybeOffice = this.offices.find(o => o.defaultOffice);
            return maybeOffice || this.offices[0];
        },
        selectOffice(office: Office): void {
            this.$emit('office-select', office);
        }
    }
};
</script>
