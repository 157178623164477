<template>
    <li v-if="checkAccordion">
        <Accordion v-model="open" :title="title" :anchor="safeAnchor" :is-last="isLast">
            <slot :visible="visible" />
        </Accordion>
    </li>
    <li v-else v-show="active" :id="safeAnchor" role="tabpanel" class="-mt-14 pt-14 focus:outline-none" tabindex="0">
        <slot :visible="visible" />
    </li>
</template>

<script lang="ts">

import {mapState} from "pinia";
import {useNavigationStore} from "../../stores/navigation";
import Accordion from "../base/Accordion.vue";
import Utils from "../../utils/Utils";

export default {
    components: {Accordion},
    inject: ['register', 'checkAccordion'],
    props: {
        showAll: {type: Boolean, default: false},
        isLast: {type: Boolean, default: false},
        type: {type: String, default: 'accordion'},
        title: {type: String},
        currentIndex: {type: Number},
        index: {type: Number},
        itemsLength: {type: Number, default: 1},
        anchor: {type: String, default: ''},
        componentUid: {type: Number, required: true}
    },
    data() {
        return {
            open: false
        };
    },
    computed: {
        ...mapState(useNavigationStore, ['activatedTab']),
        visible(): boolean {
            if (this.checkAccordion) {
                return this.open;
            }
            return this.active;
        },
        active(): boolean {
            return (this.currentIndex === this.index) || (this.showAll && this.currentIndex === this.itemsLength);
        },
        /**
         * Make sure to always add an anchor, so the content group item can be referenced for keyboard access.
         * See also TabHeader.vue, where this anchor is referenced.
         */
        safeAnchor(): string {
            return this.anchor ? this.anchor : (this.title ? Utils.cleanString(this.title) : 'item-' + this.componentUid + '-' + this.index);
        }
    },
    watch: {
        async activatedTab(newVal: string) {
            if (newVal) {
                this.checkActivatedTab(newVal);
            }
        },
        async visible(newVal: boolean) {
            if (newVal) {
                // some stuff needs an event to trigger when becoming visible to actually work
                // ok, in fact only the animated text currently needs this or the text stays invisible
                setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
            }
        }
    },
    mounted() {
        this.open = this.active || this.showAll;
    },
    created(): void {
        // register self on parent component
        this.register(this);
    },
    methods: {
        toggle(): void {
            this.open = !this.open;
        },
        checkActivatedTab(id: string): void {
            if (this.safeAnchor === id) {
                this.open = true;
            }
        }
    }
};
</script>
