<template>
    <div>
        <div>
            <Transition name="fade">
                <div>
                    <Teleport to="body">
                        <Transition name="fade">
                            <dialog v-show="!loading"
                                    ref="dialogEL"
                                    class="max-w-full max-h-full bg-white px-8 py-14 xl:p-16 backdrop:bg-black-900 backdrop:bg-opacity-20 backdrop:backdrop-filter backdrop:backdrop-blur"
                            >
                                <div class="flex flex-column items-center justify-center">
                                    <button class="absolute right-0 top-0 flex justify-center items-center w-10 h-10 text-white bg-primary-400 hover:bg-primary-600 focus:bg-primary-600 transition-colors"
                                            autofocus
                                            @click="hide"
                                    >
                                        <Icon name="close" class="w-4 h-4 stroke-current" />
                                    </button>
                                    <img v-if="!content"
                                         ref="imageEl"
                                         class="max-w-lightbox-t xl:max-w-lightbox-d max-h-lightbox-t xl:max-h-lightbox-d"
                                         :src="src"
                                         :alt="alt"
                                         @load="onLoad"
                                    >
                                    <div v-if="content"
                                         ref="svgContainer"
                                         class="svg-image"
                                         v-html="content"
                                    />
                                    <div v-if="caption"
                                         ref="captionEl"
                                         class="prose-caption text-para-xs border-l border-primary-500 pl-4 mt-4 xl:mt-6 line-clamp-2 w-0"
                                         v-html="caption"
                                    />
                                </div>
                            </dialog>
                        </Transition>
                    </Teleport>
                    <div v-show="loading">
                        <loading-spinner class="w-20 h-20 fill-current" />
                    </div>
                </div>
            </Transition>
        </div>
        <button class="absolute right-2 bottom-2 justify-center items-center text-black-300 hover:text-primary-600 focus:text-primary-600 bg-white w-7 h-7 rounded-full transition-colors"
                :class="hideMobileIcon ? 'hidden md:flex' : 'flex'"
                @click="show"
        >
            <Icon class="stroke-current w-3 h-3" name="enlarge" />
        </button>
    </div>
</template>

<script lang="ts">

import LoadingSpinner from "./LoadingSpinner.vue";
import Icon from "./Icon.vue";

export default {
    components: {Icon, LoadingSpinner},
    props: {
        src: {type: String, required: true},
        content: {type: String},
        caption: {type: String, default: ''},
        altTitle: {type: String, default: ''},
        hideMobileIcon: {type: Boolean, default: false},
        modelValue: {type: Boolean}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            loading: false
        };
    },
    computed: {
        alt(): string {
            return this.getCleanText(this.altTitle || '');
        }
    },
    watch: {
        async modelValue(newVal: boolean) {
            if (newVal) {
                this.show();
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    },
    mounted(): void {
        if (!this.content) {
            // show loading spinner
            this.loading = true;
        }
    },
    methods: {
        show(): void {
            document.body.style.overflow = 'hidden';

            if (this.$refs.dialogEL && !this.$refs.dialogEL.open) {
                this.$refs.dialogEL.showModal();
            }

            // make sure hide method is called when dialog is closed by pressing escape
            this.$refs.dialogEL.addEventListener('close', this.hide, { once: true });

            this.onLoad();
            window.addEventListener('resize', this.handleResize);

            this.$emit('update:modelValue', true);
        },
        hide(): void {
            document.body.style.overflow = '';

            if (this.$refs.dialogEL.open) {
                this.$refs.dialogEL.close();
            }

            window.removeEventListener('resize', this.handleResize);

            this.$emit('update:modelValue', false);
        },
        onLoad(): void {
            this.loading = false;
            setTimeout(() => {
                this.handleResize();
            }, 100);
        },
        handleResize(): void {
            if ((this.$refs.imageEl || this.$refs.svgContainer) && this.$refs.captionEl) {
                const width = this.$refs.imageEl ? this.$refs.imageEl.width : this.$refs.svgContainer ? this.$refs.svgContainer.offsetWidth : 0;
                this.$refs.captionEl.style.width = `${width}px`;
            }
        },
        getCleanText(text: string): string {
            const el = document.createElement('div');
            el.innerHTML = text;
            return el.innerText;
        }
    }
};
</script>
