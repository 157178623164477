import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col items-end mb-8"
}
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "text-para-s font-semibold" }
const _hoisted_4 = {
  key: 0,
  class: "pl-4 md:pl-8"
}
const _hoisted_5 = {
  key: 0,
  class: "prose mb-12 text-center md:text-left"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  class: "border-b border-black-200 mt-8 mb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_UserComment = _resolveComponent("UserComment")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!$props.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.textModel) = $event)),
            class: "w-full min-h-32 border border-black-100 text-black-600 text-para-s rounded-sm focus:outline-none py-3 px-4 mb-6 placeholder-improvedContrast",
            placeholder: $props.labels.placeholder
          }, null, 8, _hoisted_2), [
            [_vModelText, $data.textModel]
          ]),
          _createVNode(_component_GcButton, {
            class: "w-fit",
            type: "button",
            label: $props.labels.submit,
            disabled: !$data.textModel || $data.isLoading,
            onClick: $options.submitComment
          }, null, 8, ["label", "disabled", "onClick"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, _toDisplayString($data.comments.length) + " " + _toDisplayString($props.labels.comments), 1),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "border-b border-black-200 my-6" }, null, -1)),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.comments, (comment, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _createVNode(_component_UserComment, {
            ref_for: true,
            ref: "comment",
            index: index,
            comment: comment,
            labels: $props.labels,
            "full-name": $props.fullName,
            email: $props.email,
            "is-admin": $props.isAdmin,
            disabled: $props.disabled
          }, null, 8, ["index", "comment", "labels", "full-name", "email", "is-admin", "disabled"]),
          ((comment.comments || []).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (
                            comment.comments.length > 1 &&
                            !$data.showComment[comment.id]
                        )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("a", {
                        class: "link cursor-pointer",
                        onClick: ($event: any) => ($options.showPrevious(comment.id))
                      }, _toDisplayString($props.labels.showPrevious), 9, _hoisted_6)
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comment.comments, (childComment, childIndex) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_UserComment, {
                    key: index + '-' + childIndex,
                    ref_for: true,
                    ref: "comment",
                    index: index + '-' + childIndex,
                    "parent-id": comment.id,
                    "is-last": 
                                (comment.comments || []).length ===
                                childIndex + 1
                            ,
                    comment: childComment,
                    labels: $props.labels,
                    "full-name": $props.fullName,
                    email: $props.email,
                    "is-admin": $props.isAdmin,
                    disabled: $props.disabled,
                    nested: ""
                  }, null, 8, ["index", "parent-id", "is-last", "comment", "labels", "full-name", "email", "is-admin", "disabled"])), [
                    [_vShow, 
                                childIndex === comment.comments.length - 1 ||
                                $data.showComment[comment.id]
                            ]
                  ])
                }), 128))
              ]))
            : _createCommentVNode("", true),
          ((comment.comments || []).length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}