<template>
    <div v-if="video || Object.keys(urls).length > 1" class="w-full relative" :class="{'h-screen': !editMode}">
        <video v-if="video" :poster="poster" autoplay muted loop class="object-cover object-center w-full h-full">
            <source :src="video">
        </video>
        <ResponsiveImage v-else
                         :urls="urls"
                         class="w-full h-full"
                         :img-class="'object-cover object-center w-full h-full' + (editMode ? '': ' absolute top-0 left-0')"
                         :show-wide="true"
                         :caption="title"
                         :alt-title="imageAlt"
        />
        <div v-if="showGradient" class="absolute top-0 left-0 w-full h-full bg-stage-gradient" />
        <div v-if="showLanguageSwitcher" class="absolute w-full text-white"
             :class="additionalTopMarginContent({prefix: 'top', sm: 14, md: 20, xl: 20})"
        >
            <div class="flex justify-center items-center mt-18 xl:mt-28 z-10">
                <p class="text-para-xs font-semibold mr-2">{{ languageSwitcherPrefix }}</p>
                <Dropdown v-model="currentLang" class="min-w-40 md:min-w-50" title="language">
                    <DropdownItem v-for="(lang, locale) in locales" :key="locale" :label="lang" :value="locale" />
                </Dropdown>
                <p class="text-para-xs font-semibold ml-2">{{ languageSwitcherSuffix }}</p>
            </div>
        </div>
        <div v-if="title || description || subTitle" class="absolute bottom-0 w-full md:mb-35 mb-24 text-white">
            <div class="container">
                <div class="inline-grid grid-cols-1 xl:gap-y-4">
                    <h1 v-if="title"
                        class="text-stage-title-mobile md:text-stage-title-tablet xl:text-stage-title-desktop order-2 md:pb-0 pb-2"
                    >
                        {{ title }}
                    </h1>
                    <h2 v-if="subTitle" class="xl:text-heading-4 text-heading-5 order-1">{{ subTitle }}</h2>
                    <p v-if="description" class="md:text-para-lg max-w-xl order-3">{{ description }}</p>
                </div>
            </div>
        </div>
    </div>
    <div v-else :class="additionalTopMarginContent({prefix: 'mt', sm: 20, md: 44, xl: 44})">
        <div class="container">
            <div class="inline-grid grid-cols-1 xl:gap-y-4 gap-y-2">
                <h1 v-if="title"
                    class="text-stage-title-mobile md:text-stage-title-tablet xl:text-stage-title-desktop order-2 md:pb-0 pb-2"
                >
                    {{ title }}
                </h1>
                <h2 v-if="subTitle" class="xl:text-heading-4 text-heading-5 order-1">{{ subTitle }}</h2>
                <p v-if="description" class="md:text-para-lg max-w-xl order-3">{{ description }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {PropType} from "vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import ResponsiveImage from "../base/ResponsiveImage.vue";
import {mapState} from "pinia";
import {useNavigationStore} from "../../stores/navigation";

export default {
    components: {ResponsiveImage, DropdownItem, Dropdown},
    props: {
        editMode: {type: Boolean, default: false},
        showGradient: {type: Boolean, default: false},
        showLanguageSwitcher: {type: Boolean, default: false},
        language: {type: String, required: true},
        locales: {
            type: Object as PropType<Record<string, string>>, default: () => {
            }
        },
        title: {type: String},
        subTitle: {type: String},
        description: {type: String},
        languageSwitcherPrefix: {type: String},
        languageSwitcherSuffix: {type: String},
        video: {type: String},
        poster: {type: String},
        urls: {type: Object as PropType<Record<string, string>>, required: true},
        imageAlt: {type: String}
    },
    data() {
        return {
            currentLang: this.language
        };
    },
    computed: {
        ...mapState(useNavigationStore, ['additionalTopMarginContent']),
    },
    watch: {
        async currentLang() {
            const path = window.location.pathname;
            const localeSegment = `/${this.currentLang}/`;
            window.location.href = path.replace(/\/([a-z]{2,3})\//, localeSegment);
        }
    }
};
</script>
