import _ from "lodash";

import plugin from "tailwindcss/plugin";

const colorFunc = function(color, gradient, opacityVariable, opacityValue) {
    if (opacityValue !== undefined) {
        return `rgba(var(--color-${color}-${gradient}), ${opacityValue})`;
    }
    if (opacityVariable !== undefined) {
        return `rgba(var(--color-${color}-${gradient}), var(${opacityVariable}, 1))`;
    }
    return `rgb(var(--color-${color}-${gradient}))`;
};

export default {
    content: [
        './templates/**/*.ftl',
        './styles/*.css',
        './ts/**/*.vue',
        './ts/**/*.ts',
        './assets/js/ckeditor/*.js'
    ],
    safelist: {
        standard: [
            'fade-enter-active',
            'fade-leave-active',
            'fade-enter',
            'fade-leave-to',
            /^top-[0-9]*$/,
            /^mt-[0-9]*$/,
            /^md:top-[0-9]*$/,
            /^md:mt-[0-9]*$/,
            /^xl:top-[0-9]*$/,
            /^xl:mt-[0-9]*$/
        ],
        deep: [
            /ol/
        ]
    },
    theme: {
        typography: theme => ({
            DEFAULT: {
                css: {
                    color: theme('colors.black.900'),
                    // misc
                    wordWrap: 'break-word',
                    em: {
                        fontWeight: 200,
                        fontStyle: 'italic'
                    },
                    // spacing
                    'h2:not(.abstract)': {
                        marginBottom: '1.5rem'
                    },
                    'h3, h4': {
                        marginBottom: '.5rem'
                    },
                    p: {
                        wordWrap: 'break-word',
                        marginBottom: '1.5rem'
                    },
                    'p:last-child, .read-more:last-child': {
                        marginBottom: 0
                    },
                    // links
                    'a, .read-more > span': {
                        color: theme('colors.black.600'),
                        position: 'relative',
                        bottom: '0',
                        paddingBottom: '0',
                        fontWeight: 'normal',
                        wordBreak: 'break-word',
                        textDecoration: 'none',
                        transition: 'all 0.14s ease-out',
                        display: 'inline',
                        borderBottom: `1px solid ${theme('colors.primary.500')}`,
                        '&:focus': {
                            outline: `2px solid ${theme('colors.primary.500')}`
                        },
                        '&:hover': {
                            color: theme('colors.primary.500'),
                            bottom: '2px',
                            paddingBottom: '3px'
                        }
                    },
                    'a.external': {
                        marginRight: '.125rem'
                    },
                    'a.external:after': {
                        position: 'relative',
                        display: 'inline-block',
                        top: '0.125rem',
                        right: '-.25rem',
                        width: '1rem',
                        height: '1rem'
                    },
                    'p.text-para-xs': {
                        marginBottom: '1.5rem'
                    },
                    // lists vertical spacing
                    'ul, ol': {
                        marginBottom: '1.5rem'
                    },
                    'li + li': {
                        marginTop: '1rem'
                    },
                    'li ul, li ol': {
                        marginTop: '.5rem',
                        marginBottom: '0'
                    },
                    'li ul li + li, li ol li + li': {
                        marginTop: '.5rem'
                    },
                    // lists styling
                    'ul > li': {
                        position: 'relative',
                        paddingLeft: '.75rem'
                    },
                    'ul > li::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        left: '0',
                        top: '.625rem',
                        backgroundColor: theme('colors.primary.500'),
                        borderRadius: '999px',
                        width: '0.25rem',
                        height: '0.25rem'
                    },
                    'ul li ul li': {
                        marginLeft: '1rem'
                    },
                    'ul li ul li:before': {
                        border: `1px solid ${theme('colors.primary.500')}`,
                        backgroundColor: 'transparent',
                        top: '.5625rem',
                        width: '0.375rem',
                        height: '0.375rem'
                    },
                    'ul li ul li ul li': {
                        marginLeft: '.5rem'
                    },
                    'ul li ul li ul li:before': {
                        border: `1px solid ${theme('colors.black.900')}`
                    },
                    ol: {
                        borderLeft: `2px solid ${theme('colors.primary.500')}`,
                        paddingLeft: '0.625rem'
                    },
                    'ol > li': {
                        position: 'relative',
                        paddingLeft: '1.25rem'
                    },
                    'ol > li::before': {
                        content: 'counter(list-item, var(--list-counter-style, decimal)) "."',
                        position: 'absolute',
                        left: '0',
                        color: theme('colors.black.900')
                    },
                    'ol li ol': {
                        '--list-counter-style': 'lower-alpha',
                        borderLeft: 'none',
                        paddingLeft: '0px'
                    },
                    'ol li ol li ol': {
                        '--list-counter-style': 'lower-roman'
                    },
                    // don't override cke styles
                    'ol[style*="list-style-type"]': {
                        paddingLeft: '2.25rem'
                    },
                    'ol[style*="list-style-type"] ol': {
                        listStyleType: 'inherit',
                        paddingLeft: '1.125rem'
                    },
                    'ol[style*="list-style-type"] li': {
                        paddingLeft: '.5rem'
                    },
                    'ol[style*="list-style-type"] li:before': {
                        content: 'none'
                    },
                    // highlighting
                    'ul.highlight': {
                        borderLeft: `2px solid ${theme('colors.primary.500')}`,
                        paddingLeft: '0.725rem'
                    },
                    'p.highlight': {
                        fontWeight: 600,
                        borderLeft: `2px solid ${theme('colors.primary.500')}`,
                        paddingLeft: '0.725rem'
                    },
                    // tables
                    table: {
                        display: 'block',
                        overflowX: 'auto',
                        width: '100%',
                        maxWidth: '100%',
                        textAlign: 'left',
                        fontSize: '.75rem',
                        lineHeight: '1rem'
                    },
                    thead: {
                        borderBottom: `1px solid ${theme('colors.black.200')}`
                    },
                    'th, td': {
                        whiteSpace: 'nowrap',
                        padding: '.5rem'
                    },
                    'tr:nth-child(even)': {
                        backgroundColor: `${theme('colors.black.50')}`
                    },
                    'tbody tr': {
                        transition: 'background-color .1s ease-out'
                    },
                    'tbody tr:hover': {
                        backgroundColor: `${theme('colors.primary.100')}`
                    },
                    'tbody tr:focus-within': {
                        backgroundColor: `${theme('colors.primary.100')}`
                    },
                    '.read-more > div > *:last-child': {
                        marginBottom: '1.5rem'
                    },
                    'p + .read-more.inactive': {
                        marginTop: '-.5rem'
                    }
                }
            },
            md: {
                css: {
                    // spacing
                    'h2:not(.abstract)': {
                        marginBottom: '2rem'
                    },
                    p: {
                        marginBottom: '2rem'
                    },
                    // tables
                    table: {
                        fontSize: '.875rem',
                        lineHeight: '1.5rem'
                    },
                    'th, td': {
                        whiteSpace: 'normal',
                        padding: '.5rem 1rem'
                    },
                    // lists vertical spacing
                    'ul, ol': {
                        marginBottom: '2rem'
                    },
                    // lists styling
                    ol: {
                        paddingLeft: '0.875rem'
                    },
                    'ol > li': {
                        paddingLeft: '1.875rem'
                    },
                    'ul > li': {
                        paddingLeft: '1rem'
                    },
                    'ul li ul li': {
                        marginLeft: '1.5rem'
                    },
                    'ul li ul li ul li': {
                        marginLeft: '1rem'
                    },
                    // don't override cke styles
                    'ol[style*="list-style-type"]': {
                        paddingLeft: '2.5rem'
                    },
                    'ol[style*="list-style-type"] li': {
                        paddingLeft: '.75rem'
                    },
                    // highlighting
                    '.highlight': {
                        paddingLeft: '0.825rem'
                    }
                }
            },
            xl: {
                css: {
                    table: {
                        display: 'table'
                    }
                }
            },
            // custom rte styles
            caption: {
                css: {
                    'p:not(:last-child)': {
                        marginBottom: '.5rem'
                    },
                    a: {
                        color: theme('colors.black.600'),
                        position: 'relative',
                        bottom: '0',
                        paddingBottom: '0',
                        fontWeight: 'normal',
                        wordBreak: 'break-word',
                        textDecoration: 'none',
                        transition: 'all 0.14s ease-out',
                        display: 'inline',
                        borderBottom: `1px solid ${theme('colors.primary.500')}`,
                        '&:focus': {
                            outline: `2px solid ${theme('colors.primary.500')}`
                        },
                        '&:hover': {
                            color: theme('colors.primary.500'),
                            bottom: '2px',
                            paddingBottom: '3px'
                        }
                    }
                }
            },
            list: {
                css: {
                    'ul > li': {
                        position: 'relative',
                        paddingLeft: '.5rem'
                    },
                    'ul > li:before': {
                        content: '""',
                        position: 'absolute',
                        left: '0',
                        top: 'calc(.75rem - 1px)',
                        display: 'inline-block',
                        width: '.125rem',
                        height: '.125rem',
                        backgroundColor: theme('colors.primary.500'),
                        borderRadius: '999px'
                    }
                }
            },
            'list-lg': {
                css: {
                    'ul > li:before': {
                        top: 'calc(1rem - 1px)'
                    }
                }
            },
            summary: {
                css: {
                    '.bullet': {
                        display: 'flex'
                    },
                    '.bullet:before': {
                        content: '"\u2022"',
                        marginRight: '1rem',
                        color: theme('colors.primary.500'),
                        width: '.25rem'
                    }
                }
            }
        }),
        placeholderColor: {
            'improvedContrast': '#5a5a5a'
        },
        extend: {
            colors: {
                current: 'currentColor',
                black: {
                    900: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '900', opacityVariable, opacityValue);
                    },
                    800: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '800', opacityVariable, opacityValue);
                    },
                    700: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '700', opacityVariable, opacityValue);
                    },
                    600: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '600', opacityVariable, opacityValue);
                    },
                    500: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '500', opacityVariable, opacityValue);
                    },
                    400: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '400', opacityVariable, opacityValue);
                    },
                    300: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '300', opacityVariable, opacityValue);
                    },
                    200: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '200', opacityVariable, opacityValue);
                    },
                    100: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '100', opacityVariable, opacityValue);
                    },
                    50: ({opacityVariable, opacityValue}) => {
                        return colorFunc('black', '050', opacityVariable, opacityValue);
                    }
                },
                primary: {
                    900: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '900', opacityVariable, opacityValue);
                    },
                    800: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '800', opacityVariable, opacityValue);
                    },
                    700: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '700', opacityVariable, opacityValue);
                    },
                    600: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '600', opacityVariable, opacityValue);
                    },
                    500: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '500', opacityVariable, opacityValue);
                    },
                    400: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '400', opacityVariable, opacityValue);
                    },
                    300: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '300', opacityVariable, opacityValue);
                    },
                    200: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '200', opacityVariable, opacityValue);
                    },
                    100: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '100', opacityVariable, opacityValue);
                    },
                    50: ({opacityVariable, opacityValue}) => {
                        return colorFunc('primary', '050', opacityVariable, opacityValue);
                    }
                },
                secondary: {
                    900: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '900', opacityVariable, opacityValue);
                    },
                    800: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '800', opacityVariable, opacityValue);
                    },
                    700: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '700', opacityVariable, opacityValue);
                    },
                    600: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '600', opacityVariable, opacityValue);
                    },
                    500: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '500', opacityVariable, opacityValue);
                    },
                    400: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '400', opacityVariable, opacityValue);
                    },
                    300: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '300', opacityVariable, opacityValue);
                    },
                    200: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '200', opacityVariable, opacityValue);
                    },
                    100: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '100', opacityVariable, opacityValue);
                    },
                    50: ({opacityVariable, opacityValue}) => {
                        return colorFunc('secondary', '050', opacityVariable, opacityValue);
                    }
                },
                error: '#E11900',
                success: '#45B550',
                greyAccessibility: '#949494',
                graph: {
                    blue: '#BCE3FF',
                    green: '#CBE99E',
                    yellow: '#FFFF9A',
                    orange: '#FF9933',
                    red: '#C00000'
                }
            },
            /* eslint-disable */
            backgroundImage: theme => ({
                'circle': 'url(\'../assets/img/bg-circle.svg\')',
                'download': 'url(\'../assets/svg/download.svg\')',
                'stage-gradient': "radial-gradient(108.47% 253.52% at -13.58% 95.83%, #000000 0%, rgba(0, 0, 0, 0.28125) 65.54%, rgba(0, 0, 0, 0.113963) 81.96%, rgba(0, 0, 0, 0) 100%)"
            }),
            margin: {
                '1/3': '33.333333%',
                '1/2': '50%',
                'socio-economic-map': 'calc(100vh - 56px)'
            },
            scale: {
                '30': '0.3',
                '101': '1.01',
                '110': '1.1',
                '115': '1.15'
            },
            spacing: {
                '1.25': '.3125rem',
                '4.5': '1.125rem',
                '14': '3.5rem',
                '15': '3.75rem',
                '18': '4.5rem',
                '19': '4.75rem',
                '20': '5rem',
                '21': '5.25rem',
                '22': '5.5rem',
                '23': '5.75rem',
                '25': '6.25rem',
                '26': '6.5rem',
                '27': '6.75rem',
                '27.5': '6.875rem',
                '28': '7rem',
                '30': '7.5rem',
                '31': '7.75rem',
                '32': '8rem',
                '34': '8.5rem',
                '35': '8.75rem',
                '37': '9.25rem',
                '38': '9.5rem',
                '42': '10.5rem',
                '43': '10.75rem',
                '44': '11rem',
                '46': '11.5rem',
                '50': '12.5rem',
                '52': '13rem',
                '55': '13.75rem',
                '58': '14.5rem',
                '61': '15.25rem',
                '66': '16.5rem',
                '67': '16.75rem',
                '69': '17.25rem',
                '72': '18rem',
                '75': '18.75rem',
                '77': '19.25rem',
                '79': '19.75rem',
                '81': '20.25rem',
                '90': '22.5rem',
                '93': '23.25rem',
                '100': '25rem',
                '101': '25.25rem',
                '102': '25.5rem',
                '104': '26rem',
                '107': '26.75rem',
                '118': '29.5rem',
                '120': '30rem',
                '125': '31.25rem',
                '135': '33.75rem',
                '156': '39rem',
                '162': '40.5rem',
                '165': '41.25rem',
                '180': '45rem',
                '192': '48rem',
                '200': '50rem',
                '220': '55rem',
                '254': '63.5rem'
            },
            transitionDuration: {
                DEFAULT: '300ms',
                '600': '600ms',
                '4000': '4000ms'
            },
            fontFamily: {
                sans: ['Montserrat']
            },
            fontSize: {
                'stage-title-mobile': ['2.625rem', {lineHeight: '3.375rem', letterSpacing: '-0.02em'}],
                'stage-title-tablet': ['3.75rem', {lineHeight: '5rem', letterSpacing: '-0.02em'}],
                'stage-title-desktop': ['5rem', {lineHeight: '6rem', letterSpacing: '-0.02em'}],
                'heading-1': ['3rem', '4rem'],
                'heading-2': ['2.125rem', '3rem'],
                'heading-3': ['1.5rem', '2rem'],
                'heading-4': ['1.25rem', '2rem'],
                'heading-5': ['1.125rem', '2rem'],
                'para-xl': ['1.25rem', {lineHeight: '2rem', letterSpacing: '-0.02em'}],
                'para-lg': ['1.125rem', {lineHeight: '2rem', letterSpacing: '-0.02em'}],
                'para-base': ['1rem', {lineHeight: '1.5rem', letterSpacing: '-0.02em'}],
                'para-s': ['.875rem', {lineHeight: '1.5rem', letterSpacing: '-0.02em'}],
                'para-xs': ['.75rem', {lineHeight: '1rem', letterSpacing: '0'}],
                'para-xxs': ['.5625rem', {lineHeight: '1rem', letterSpacing: '0'}],
                'para-xxxs': ['.5rem', {lineHeight: '.75rem', letterSpacing: '0'}]
            },
            letterSpacing: {
                wide: '.02em',
                wider: '.04em',
                widest: '.06em',
                tight: '-.02em',
                tighter: '-.02em',
                tightest: '-.02em'
            },
            maxWidth: {
                'logo': '13.5rem',
                'logo-m': '10rem',
                'video-modal': '1150px',
                'content-modal-narrow': '740px',
                'content-modal': '1109.33px',
                'narrow-content': '853.33px',
                'tab': '11.5rem',
                '12': '3rem',
                '36': '9rem',
                '40': '10rem',
                '50': '12.5rem',
                '160': '40rem',
                '4/12': '33.333333%',
                '1/2': '50%',
                '1/3': '33.333333%',
                '1/4': '25%',
                '8/12': '66.6666666667%',
                '10/12': '83.333333%',
                '11/12': '91.666666667%',
                'lightbox-d': 'calc(100vw - 16rem - 8rem)',
                'lightbox-t': 'calc(100vw - 4rem - 4rem)'
            },
            minHeight: {
                'tablet-nav': 'calc(100vh - 5rem)',
                'mobile-nav': 'calc(100vh - 3.5rem)',
                '4': '1rem',
                '6': '1.5rem',
                '14': '3.5rem',
                '20': '5rem',
                '30': '7.5rem',
                '32': '8rem',
                '102': '25.5rem',
                '104': '26rem',
                '118': '29.5rem',
                '125': '31.25rem',
                '150': '37.5rem',
                '156': '39rem'
            },
            minWidth: {
                '8': '2rem',
                '10': '2.5rem',
                '35': '8.75rem',
                '40': '10rem',
                '50': '12.5rem',
                '60': '15rem',
                '150': '37.5rem',
            },
            padding: {
                '16/9': '56.25%',
                '3/1': '33.33%',
                '3/2': '66.66%',
                '4/3': '75%',
                '3/4': '133.33%',
                '1/1': '100%',
                '114': '28.5rem',
                '9/5': '55.56%'
            },
            width: {
                'logo': '13.5rem',
                'logo-m': '9rem',
                '1/8': '12.5%',
                '2/8': '25%',
                '3/8': '37.5%',
                '4/8': '50%',
                '5/8': '62.5%',
                '6/8': '75%',
                '7/8': '87.5%',
                'fit': 'fit-content',
                'narrow-padded': '1073.33px',
            },
            height: {
                header: '28.5rem',
                'nav': '5rem',
                'nav-m': '3.5rem',
                'tablet-nav': 'calc(100vh - 5rem)',
                'mobile-nav': 'calc(100vh - 3.5rem)',
                'mobile-full': 'calc(100vh - 3.5rem - 3.5rem)',
                'tablet-full': 'calc(100vh - 5rem - 6.5rem)',
                'socio-economic-map': 'calc(100vh - 56px)',
                '37': '9.25rem',
                '168': '42rem',
                'min': 'min-content',
                'fit': 'fit-content',
                'dynamic-screen': '100svh'
            },
            maxHeight: {
                'nav': '5rem',
                'nav-m': '3.5rem',
                'policy': 'calc(100vh - 1.25rem - 2.5rem)',
                'policy-m': 'calc(100vh - 0.75rem - 1.75rem)',
                'overlay-teaser': '660px',
                'lightbox-d': 'calc(100vh - 8rem - 8rem - 5rem)',
                'lightbox-t': 'calc(100vh - 4rem - 7rem - 4.5rem)'
            },
            flex: {
                '1/2': '1 0 50%',
                '1/3': '1 0 33.3%',
                '1/4': '1 0 25%'
            },
            inset: {
                '26': '6.5rem'
            },
            lineClamp: {
                '7': '7',
                '9': '9',
                '13': '13',
                '15': '15'
            },
            zIndex: {
                '-1': '-1',
                '1': '1',
                '2': '2',
                '5': '5',
                '999': 999,
                '9000': 9000
            },
            boxShadow: {
                '2xl': '6px 0px 40px rgba(0, 0, 0, 0.16);',
                'top': '0px -2px 8px 0px rgba(0, 0, 0, 0.25);',
                'top-sm': '0px -1px 4px 0px rgba(210, 210, 210, 0.5)'
            },
            gradientColorStops: {
                'transparent': 'rgba(255,255,255,0)'
            },
            opacity: {
                '65': '.65'
            },
            screens: {
                'print': {'raw': 'print'}
            }
        }
    },
    plugins: [
        require('@tailwindcss/typography'),
        plugin(({addVariant, e}) => {
            addVariant('before', ({modifySelectors, separator}) => {
                modifySelectors(({className}) => {
                    return `.${e(`before${separator}${className}`)}::before`;
                });
            });
            addVariant('after', ({modifySelectors, separator}) => {
                modifySelectors(({className}) => {
                    return `.${e(`after${separator}${className}`)}::after`;
                });
            });
        }),
        plugin(({addUtilities, e, theme}) => {
            const contentUtilities = {
                '.content': {
                    content: 'attr(data-content)'
                },
                '.content-before': {
                    content: 'attr(data-before)'
                },
                '.content-after': {
                    content: 'attr(data-after)'
                }
            };

            const dashoffsetUtilities = _.map(theme('spacing'), (val, name) => ({
                // safari doesn't like rem values on dasharray and dashoffset
                // so just take 'name * 4' as value
                [`.${e(`dashoffset-${name}`)}`]: {
                    strokeDashoffset: `${name * 4}`
                },
                [`.${e(`dasharray-${name}`)}`]: {
                    strokeDasharray: `${name * 4}`
                }
            }));

            addUtilities(contentUtilities, ['before', 'after']);
            addUtilities(dashoffsetUtilities, ['responsive', 'group-hover', 'group-focus']);
        }),
        function ({ addComponents }) {
            addComponents({
                // https://stackoverflow.com/questions/65903737/configure-container-max-width-at-specific-breakpoints-tailwindcss
                '.container': {
                    maxWidth: '1440px',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    '@screen md': {
                        paddingLeft: '2.5rem',
                        paddingRight: '2.5rem',
                    },
                    '@screen xl': {
                        paddingLeft: '5rem',
                        paddingRight: '5rem',
                    }
                },
                '.max-w-container': {
                    maxWidth: '1440px'
                }
            })
        }
    ],
    corePlugins: {
        container: false
    }
};
