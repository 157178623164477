import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(["absolute left-0 top-0 w-full md:w-1/2 xl:w-1/3 h-dynamic-screen bg-white overflow-hidden transform transition-all duration-500 ease-out -z-1", {'-translate-x-full opacity-0': !_ctx.navigationOpened, 'opacity-100': _ctx.navigationOpened, 'invisible': !_ctx.navigationOpened}])
  }, [
    _renderSlot(_ctx.$slots, "default", {
      toggleGlobalNav: $options.toggleGlobalNavigation,
      hasOpenSubmenu: $data.hasOpenSubMenu
    })
  ], 2))
}