import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute top-5 right-5 stroke-black md:top-3/4 xl:pt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "mb-3",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.zoomIn && $options.zoomIn(...args)))
    }, [
      _createVNode(_component_Icon, {
        name: "add-square",
        class: "stroke-current w-8 h-8 cursor-pointer"
      })
    ]),
    _createElementVNode("div", {
      class: "mb-3",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.zoomOut && $options.zoomOut(...args)))
    }, [
      _createVNode(_component_Icon, {
        name: "subtract-square",
        class: "stroke-current w-8 h-8 cursor-pointer"
      })
    ])
  ]))
}