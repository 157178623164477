<template>
    <div class="overflow-hidden relative h-screen text-white">
        <template v-for="(item, i) in items" :key="i">
            <video v-if="item.video"
                   :poster="item.src"
                   autoplay
                   muted
                   loop
                   class="object-cover object-center top-0 left-0 w-full h-full transition-all duration-1000 ease-out transform origin-center"
                   :class="[i === index ? 'block' : 'hidden']"
            >
                <source :src="item.video" :type="`video/${item.videoType}`">
            </video>
            <ResponsiveImage v-else
                             show-wide
                             :urls="item.urls"
                             alt-title=""
                             :class="[i === index ? 'opacity-100 scale-100' : 'opacity-0 scale-150']"
                             class="object-cover object-center absolute top-0 left-0 w-full h-full transition-all duration-1000 ease-out transform origin-center"
            />
        </template>
        <div class="absolute bottom-0 w-full bg-gradient-to-t from-black-900">
            <div class="container">
                <h1 v-if="!!currentItem && !!currentItem.title"
                    class="text-stage-title-mobile md:text-stage-title-tablet xl:text-stage-title-desktop mb-2 md:mb-12 xl:mb-16"
                >
                    {{ currentItem.title }}
                </h1>
                <ul class="pb-24 md:pb-15 xl:pb-10 xl:inline-grid xl:gap-x-16 xl:grid-cols-3 w-full">
                    <li v-for="(item, i) in items"
                        :key="`${item.title}-${i}`"
                        :class="[i === index ? 'inline-flex' : 'hidden']"
                        class="overflow-hidden flex-col xl:inline-flex xl:max-w-sm xl:h-30 md:flex-row"
                    >
                        <div class="flex flex-col justify-between items-start md:order-2">
                            <div class="hidden text-para-lg md:block">{{ item.description }}</div>
                            <a v-if="item.link"
                               :href="item.link"
                               :target="item.target"
                               :aria-label="item.ariaLabel ? item.ariaLabel : item.title"
                               class="inline-flex justify-between items-center text-para-s font-semibold group"
                            >
                                {{ item.label }}
                                <ArrowButton direction="right" class="ml-2 w-10 h-10" />
                            </a>
                        </div>
                        <LoadSpinner v-if="items.length > 1" class="mr-4 md:order-1" :active="index === i" />
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="items.length > 1"
             class="flex absolute bottom-0 justify-center items-center py-6 md:py-10 space-x-2 w-full xl:hidden"
        >
            <span v-for="i in items.length"
                  :key="`stage-page-${i}`"
                  class="inline-black w-1.5 h-1.5 rounded-full transition-colors bg-black-300"
                  :class="{'bg-white': index === i - 1}"
            />
        </div>
        <slot />
    </div>
</template>

<script lang="ts">
import LoadSpinner from "./LoadSpinner.vue";
import ResponsiveImage from "../base/ResponsiveImage.vue";
import ArrowButton from "../base/ArrowButton.vue";

export default {
    components: {ArrowButton, ResponsiveImage, LoadSpinner},
    provide() {
        return {
            registerCarouselItem: this.register
        };
    },
    data() {
        return {
            items: [],
            index: 0,
            interval: null
        };
    },
    computed: {
        currentItem(): object {
            if (this.items.length > this.index) {
                return this.items[this.index];
            }
            return null;
        }
    },
    beforeUnmount(): void {
        clearInterval(this.interval);
    },
    methods: {
        /**
         * This method is called by child items.
         *
         * @param item A child item that wants to register itself
         */
        register(item: any) {
            // in this case only, we have to copy all properties (to prevent issues with reactivity)
            this.items.push(Object.assign({}, item));

            // cannot determine tag of child nodes in Vue3
            // instead check for a specific property that should be present in the children we want to count
            if (this.$slots.default().filter(child => child.props.urls).length === this.items.length) {
                this.start();
            }
        },
        start() {
            this.interval = setInterval(() => {
                if (this.index + 1 < this.items.length) {
                    this.index++;
                } else {
                    this.index = 0;
                }
            }, 4500);
        }
    }
};
</script>
