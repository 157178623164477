import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "font-semibold text-para-xs md:text-para-s" }
const _hoisted_3 = {
  key: 0,
  "aria-hidden": "true",
  title: "required"
}
const _hoisted_4 = { class: "relative text-black-500" }
const _hoisted_5 = ["id", "name", "type", "title", "readonly", "disabled", "autofocus", "autocomplete", "placeholder", "aria-required", "aria-invalid", "aria-describedby", "onChange"]
const _hoisted_6 = ["id", "name", "rows", "title", "readonly", "disabled", "autofocus", "autocomplete", "placeholder", "aria-invalid", "aria-describedby", "onChange", "onBlur"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    ref: "field",
    modelValue: $data.model,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.model) = $event)),
    name: $props.name,
    mode: "eager",
    rules: $props.rules,
    disabled: $props.disabled,
    vid: $props.name,
    as: "div"
  }, {
    default: _withCtx(({ field, errors, handleChange, meta }) => [
      ($props.title)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: $props.name
          }, [
            _createElementVNode("span", _hoisted_2, [
              _createTextVNode(_toDisplayString($props.title) + " ", 1),
              ($props.required)
                ? (_openBlock(), _createElementBlock("dfn", _hoisted_3, _toDisplayString($props.requiredSymbol), 1))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        ($props.rows === 1 || $props.type === 'number')
          ? (_openBlock(), _createElementBlock("input", _mergeProps({
              key: 0,
              id: $props.name
            }, field, {
              name: $props.name,
              type: $props.type,
              title: $props.patternDescription,
              readonly: $props.readonly,
              disabled: $props.disabled,
              autofocus: $props.autofocus,
              autocomplete: $props.autocomplete,
              placeholder: $props.placeholder,
              "aria-required": $props.required,
              "aria-invalid": $options.hasError(errors),
              "aria-describedby": $options.getDescribedById(errors),
              class: ["w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 placeholder-improvedContrast", $options.getClasses(meta)],
              onChange: handleChange,
              onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onBlur && $options.onBlur(...args)))
            }), null, 16, _hoisted_5))
          : _createCommentVNode("", true),
        ($props.rows > 1 && $props.type === 'text')
          ? (_openBlock(), _createElementBlock("textarea", _mergeProps({
              key: 1,
              id: $props.name
            }, field, {
              name: $props.name,
              rows: $props.rows,
              title: $props.patternDescription,
              readonly: $props.readonly,
              disabled: $props.disabled,
              autofocus: $props.autofocus,
              autocomplete: $props.autocomplete,
              placeholder: $props.placeholder,
              "aria-invalid": $options.hasError(errors),
              "aria-describedby": $options.getDescribedById(errors),
              class: ["w-full text-para-xs md:text-para-s border focus:border-primary-500 rounded resize-none focus:outline-none pr-10 py-2 md:py-2.5 placeholder-improvedContrast", $options.getClasses(meta)],
              onChange: handleChange,
              onBlur: ($event: any) => ($options.onBlur(meta.valid))
            }), null, 16, _hoisted_6))
          : _createCommentVNode("", true),
        ($props.type === 'email')
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 2,
              name: "mail",
              class: "absolute stroke-current w-4 h-10 md:h-11 left-4 top-px"
            }))
          : _createCommentVNode("", true),
        (meta.validated)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 3,
              name: $options.determineIconName(meta),
              class: "absolute w-4 h-10 md:h-11 right-4 top-px"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true)
      ]),
      (errors.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            id: `${$props.name}-error`,
            class: "text-error text-para-xs mt-1 md:mt-2"
          }, _toDisplayString($props.patternDescription || errors[0]), 9, _hoisted_7))
        : ($props.description)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              id: `${$props.name}-description`,
              class: "text-para-xs mt-1 md:mt-2"
            }, _toDisplayString($props.description), 9, _hoisted_8))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "name", "rules", "disabled", "vid"]))
}