<template>
    <div class="fixed left-0 top-0 h-full w-full bg-white transform transition-all duration-500 ease-out pt-14 md:pt-20"
         :class="{'-translate-x-full': !isOpened, 'invisible': !isOpened}"
    >
        <div class="h-full px-4 md:px-10 xl:pl-28 xl:pr-12 py-4 md:pb-15 overflow-y-auto">
            <slot :has-open-submenu="hasOpenSubMenu" />
        </div>
    </div>
</template>

<script lang="ts">

export default {
    props: {
        isOpened: {type: Boolean, required: true}
    },
    data() {
        return {
            hasOpenSubMenu: false
        };
    },
    mounted() {
        if (this.isOpened) {
            this.notifyParent(true);
        }
    },
    methods: {
        notifyParent(open: boolean): void {
            // notify parent menu, that it has an opened sub menu
            if (typeof (this.$parent.$parent as any).hasOpenSubMenu === 'boolean') {
                (this.$parent.$parent as any).hasOpenSubMenu = open;
            }
        },
    },
    watch: {
        hasOpenSubMenu(newVal: boolean): void {
            if (newVal) {
                this.notifyParent(true);
            }
        },
        isOpened(newVal: boolean): void {
            this.notifyParent(newVal);
        }
    }
};
</script>
