<template>
    <nav class="absolute left-0 top-0 w-full md:w-1/2 xl:w-1/3 h-dynamic-screen bg-white overflow-hidden transform transition-all duration-500 ease-out -z-1"
         :class="{'-translate-x-full opacity-0': !navigationOpened, 'opacity-100': navigationOpened, 'invisible': !navigationOpened}"
    >
        <slot :toggle-global-nav="toggleGlobalNavigation" :has-open-submenu="hasOpenSubMenu"/>
    </nav>
</template>

<script lang="ts">
import {mapWritableState} from "pinia";
import {useNavigationStore} from "../../stores/navigation";

export default {
    data() {
        return {
            hasOpenSubMenu: false
        };
    },
    computed: {
        ...mapWritableState(useNavigationStore, ['navigationOpened', 'globalNavOpened', 'searchOpened']),
    },
    methods: {
        toggleGlobalNavigation(): void {
            this.globalNavOpened = !this.globalNavOpened;
            // shouldn't be possible to open multiple navigations/overlays at the same time
            if (this.globalNavOpened) {
                this.navigationOpened = false;
                this.searchOpened = false;
            }
        }
    }
};
</script>
