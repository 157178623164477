import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt", "title", "aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcLightbox = _resolveComponent("GcLightbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["group relative", {'cursor-pointer': $options._showLightbox}])
  }, [
    _createElementVNode("img", {
      class: _normalizeClass($props.imgClass),
      src: $props.src,
      alt: $options.alt,
      title: $props.title,
      "aria-labelledby": $props.labelledBy,
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.setLightboxVisible(true)))
    }, null, 10, _hoisted_1),
    ($options._showLightbox)
      ? (_openBlock(), _createBlock(_component_GcLightbox, {
          key: 0,
          modelValue: $data.lightboxVisible,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.lightboxVisible) = $event)),
          src: $props.src,
          caption: $props.caption,
          "alt-title": $options.alt,
          content: $props.content,
          "hide-mobile-icon": $props.hideMobileLightboxIcon
        }, null, 8, ["modelValue", "src", "caption", "alt-title", "content", "hide-mobile-icon"]))
      : _createCommentVNode("", true)
  ], 2))
}