<template>
    <input ref="inputRef"
           :name="name"
           :value="modelValue"
           type="text"
           @input="$emit('update:modelValue', $event.target.value)"
    >
</template>

<script lang="ts">
import {PropType} from 'vue';
import {CurrencyInputOptions, useCurrencyInput} from 'vue-currency-input';

export default {
    props: {
        name: {type: String, required: true},
        modelValue: {type: [Number, String]},
        options: {type: Object as PropType<CurrencyInputOptions>}
    },
    emits: ['update:modelValue', 'change', 'blur', 'input'],
    setup(props) {
        const { inputRef } = useCurrencyInput(props.options as CurrencyInputOptions);
        return { inputRef };
    }
};
</script>
