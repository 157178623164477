<template>
    <button class="rounded-full group focus-primary-out" :aria-label="ariaLabel" @click="$emit('click')">
        <svg class="text-black-300 group-hover:text-primary-500 group-focus:text-primary-500 transform"
             viewBox="0 0 40 40" fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="20" cy="20" r="18.5" fill="#fff" stroke="#949494" />
            <circle class="stroke-current transition-all duration-500 ease-in-out dasharray-30 dashoffset-30 group-hover:dashoffset-0 group-focus:dashoffset-0" cx="20" cy="20" r="18.5" fill="#fff" />
            <path class="stroke-current transform origin-center transition-colors duration-500 ease-in-out"
                  :class="rotationClass"
                  d="m16 18.354 3.573 3.573a.25.25 0 0 0 .354 0l3.573-3.573"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
        </svg>
    </button>
</template>

<script lang="ts">

export default {
    props: {
        direction: {type: String, default: 'right'},
        ariaLabel: {type: String, required: true}
    },
    emits: ['click'],
    computed: {
        rotationClass(): string {
            switch (this.direction) {
                case 'up':
                    return 'rotate-180';
                case 'right':
                    return '-rotate-90';
                case 'left':
                    return 'rotate-90';
                case 'down':
                default:
                    return '';
            }
        }
    }
};
</script>
