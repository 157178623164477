import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "absolute left-0 top-14 md:top-20 w-full h-mobile-nav md:h-tablet-nav bg-primary-50 h-full overflow-y-auto pb-5" }
const _hoisted_2 = { class: "container flex flex-col" }
const _hoisted_3 = { class: "relative w-full text-primary-500 mt-4" }
const _hoisted_4 = ["aria-label", "placeholder"]
const _hoisted_5 = { class: "flex flex-col gap-1 mt-4" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "flex flex-wrap items-center gap-2 w-full text-para-xs md:text-para-s mt-4"
}
const _hoisted_8 = { class: "hidden md:inline" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 1,
  class: "mt-12"
}
const _hoisted_11 = { class: "text-para-s font-semibold mb-4" }
const _hoisted_12 = { class: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2 md:gap-4 xl:gap-8" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "flex justify-center items-center flex-shrink-0 bg-primary-50 rounded-full w-10 h-10 transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 mr-3 md:mb-4" }
const _hoisted_15 = { class: "text-para-s flex items-center" }
const _hoisted_16 = {
  key: 0,
  class: "hidden md:block mt-6"
}
const _hoisted_17 = { class: "text-para-s font-semibold mb-4" }
const _hoisted_18 = { class: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2 md:gap-4 xl:gap-8" }
const _hoisted_19 = { class: "flex justify-center items-center flex-shrink-0 bg-primary-50 rounded-full w-10 h-10 transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 mr-3 md:mb-4" }
const _hoisted_20 = { class: "text-para-s flex items-center" }
const _hoisted_21 = {
  key: 1,
  class: "mt-16 md:mt-10"
}
const _hoisted_22 = { class: "flex flex-col text-para-s bg-white px-4 pt-4 pb-6" }
const _hoisted_23 = { class: "font-semibold" }
const _hoisted_24 = { class: "mt-2" }
const _hoisted_25 = {
  key: 0,
  class: "mt-6 md:mt-8"
}
const _hoisted_26 = { class: "text-para-s font-semibold" }
const _hoisted_27 = { class: "flex flex-wrap items-center gap-2 w-full text-para-xs md:text-para-s mt-4" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = {
  key: 2,
  class: "flex justify-center text-primary-700 mt-32"
}
const _hoisted_30 = {
  key: 0,
  class: "hidden md:block bg-white mt-12"
}
const _hoisted_31 = { class: "container flex" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = {
  key: 1,
  class: "container mt-8"
}
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = { class: "grid grid-cols-1 gap-1 mt-4" }
const _hoisted_36 = { class: "flex-grow text-para-s" }
const _hoisted_37 = {
  key: 2,
  class: "mt-6"
}
const _hoisted_38 = { class: "container" }
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = { class: "grid grid-cols-1 gap-1 mt-4 mb-6" }
const _hoisted_41 = { class: "flex-grow text-para-s" }
const _hoisted_42 = {
  key: 0,
  class: "flex justify-center prose text-para-s mb-6"
}
const _hoisted_43 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SearchLink = _resolveComponent("SearchLink")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_ContentGroupItem = _resolveComponent("ContentGroupItem")!
  const _component_ContentGroup = _resolveComponent("ContentGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "w-full rounded text-black-600 text-para-s focus:outline-none py-2 pl-10 pr-2 placeholder-improvedContrast",
          "aria-label": $props.labels.placeholder,
          placeholder: $props.labels.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.keyword) = $event)),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => ($options.initSearch && $options.initSearch(...args)), ["enter"]))
        }, null, 40, _hoisted_4), [
          [_vModelText, $data.keyword]
        ]),
        _createVNode(_component_Icon, {
          name: "search",
          class: "absolute left-4 top-3 w-4 h-4 stroke-current"
        })
      ]),
      _withDirectives(_createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.suggestions, (suggestion, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "flex items-center justify-between text-para-s bg-white hover:bg-primary-500 text-black-900 hover:text-white font-normal transition-colors cursor-pointer py-3 pl-4 pr-6 group",
            onClick: ($event: any) => ($options.searchByKeyword(suggestion))
          }, [
            _createElementVNode("span", null, _toDisplayString(suggestion), 1),
            _createVNode(_component_Icon, {
              name: "arrow-right",
              class: "flex-shrink-0 w-4 h-4 ml-6 text-primary-500 group-hover:text-white stroke-current"
            })
          ], 8, _hoisted_6))
        }), 128))
      ], 512), [
        [_vShow, $data.keyword.length >= 3 && ($data.currentSearch !== $data.keyword)]
      ]),
      (!$data.currentSearch && $data.keyword.length < 3)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            ($props.topTerms.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString($props.labels.topTerms) + ": ", 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.topTerms, (term, i) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: i,
                      class: "py-1 px-3 rounded-full border border-primary-500 cursor-pointer hover:text-white hover:bg-primary-500 transition-colors",
                      onClick: ($event: any) => ($options.searchByKeyword(term))
                    }, _toDisplayString(term), 9, _hoisted_9))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            ($props.quickLinks.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString($props.labels.quickLinks), 1),
                  _createElementVNode("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.quickLinks, (quickLink, index) => {
                      return (_openBlock(), _createElementBlock("a", {
                        key: index,
                        href: quickLink.link,
                        class: "flex md:flex-col text-para-s text-black-900 font-normal bg-white group px-3 pt-4 pb-6 md:px-6 md:pt-6 md:pb-12"
                      }, [
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_Icon, {
                            name: $options.getIcon($options.getType(quickLink.type), quickLink.link),
                            class: "w-4 h-4"
                          }, null, 8, ["name"])
                        ]),
                        _createElementVNode("p", _hoisted_15, _toDisplayString(quickLink.label), 1)
                      ], 8, _hoisted_13))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      ($data.keyword.length >=3 && $data.currentSearch === $data.keyword)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            ($data.topResults.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, _toDisplayString($props.labels.top), 1),
                  _createElementVNode("div", _hoisted_18, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.topResults, (result, index) => {
                      return (_openBlock(), _createBlock(_component_SearchLink, {
                        key: index,
                        url: result.link,
                        overlay: result.overlay,
                        class: "flex md:flex-col text-para-s text-black-900 font-normal bg-white group px-3 pt-4 pb-6 md:px-6 md:pt-6 md:pb-12"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_Icon, {
                              name: $options.getIcon(result.type, result.link),
                              class: "w-4 h-4"
                            }, null, 8, ["name"])
                          ]),
                          _createElementVNode("p", _hoisted_20, _toDisplayString(result.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["url", "overlay"]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (!$data.loading && $data.currentSearch && $data.topResults.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("span", _hoisted_23, _toDisplayString($options.noResultsLabel), 1),
                    _createElementVNode("span", _hoisted_24, _toDisplayString($props.labels.noResultsText), 1)
                  ]),
                  ($props.topTerms.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createElementVNode("span", _hoisted_26, _toDisplayString($props.labels.noResultsOther), 1),
                        _createElementVNode("div", _hoisted_27, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.topTerms, (term, i) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: i,
                              class: "py-1 px-3 rounded-full border border-primary-500 cursor-pointer hover:text-white hover:bg-primary-500 transition-colors",
                              onClick: ($event: any) => ($options.searchByKeyword(term))
                            }, _toDisplayString(term), 9, _hoisted_28))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      ($data.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createVNode(_component_LoadingSpinner, { class: "w-20 h-20 fill-current" })
          ]))
        : _createCommentVNode("", true)
    ]),
    ($data.keyword.length >=3 && $data.currentSearch === $data.keyword)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($data.results.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.categories, (cat, ws, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: index,
                      class: _normalizeClass(["text-para-s p-3 hover:bg-primary-500 hover:text-white cursor-pointer transition-colors", {'bg-primary-500 text-white': $data.currentCategory === ws}]),
                      onClick: ($event: any) => ($data.currentCategory = ws)
                    }, _toDisplayString(cat), 11, _hoisted_32))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          ($data.results.length > 0 && !$options.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                _createElementVNode("div", {
                  class: "text-para-s",
                  innerHTML: $options.resultsLabel
                }, null, 8, _hoisted_34),
                _createElementVNode("div", _hoisted_35, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.results, (result, index) => {
                    return (_openBlock(), _createBlock(_component_SearchLink, {
                      key: index,
                      url: result.link,
                      overlay: result.overlay,
                      target: $options.getTarget(result.type),
                      class: "flex items-center text-para-s bg-white hover:bg-primary-500 text-black-900 hover:text-white font-normal transition-colors py-3 px-6 group"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Icon, {
                          name: $options.getIcon(result.type, result.link),
                          class: "flex-shrink-0 w-4 h-4 mr-2"
                        }, null, 8, ["name"]),
                        _createElementVNode("p", _hoisted_36, _toDisplayString(result.label), 1),
                        _createVNode(_component_Icon, {
                          name: "arrow-right",
                          class: "flex-shrink-0 w-4 h-4 ml-6 text-primary-500 group-hover:text-white stroke-current"
                        })
                      ]),
                      _: 2
                    }, 1032, ["url", "overlay", "target"]))
                  }), 128))
                ]),
                _createVNode(_component_pagination, {
                  modelValue: $data.currentPage,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.currentPage) = $event)),
                  pages: $data.pages,
                  class: "mt-4"
                }, null, 8, ["modelValue", "pages"])
              ]))
            : _createCommentVNode("", true),
          ($data.topResults.length > 0 && $options.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", {
                    class: "text-para-s",
                    innerHTML: $options.resultsLabel
                  }, null, 8, _hoisted_39)
                ]),
                _createVNode(_component_ContentGroup, {
                  type: "accordion",
                  class: "mt-6",
                  "in-content-group": false
                }, {
                  default: _withCtx(({ index, type, showAll, itemsLength, componentUid }) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.mobileCategories, (cat, i) => {
                      return (_openBlock(), _createBlock(_component_ContentGroupItem, {
                        key: i,
                        title: $options.categories[cat],
                        index: index,
                        "component-uid": componentUid
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_40, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.mobileResults[cat], (result, j) => {
                              return (_openBlock(), _createBlock(_component_SearchLink, {
                                key: j,
                                url: result.link,
                                overlay: result.overlay,
                                target: $options.getTarget(result.type),
                                class: "flex items-center text-para-s bg-white hover:bg-primary-500 text-black-900 hover:text-white font-normal transition-colors py-3 px-6 group"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Icon, {
                                    name: $options.getIcon(result.type, result.link),
                                    class: "flex-shrink-0 w-4 h-4 mr-2"
                                  }, null, 8, ["name"]),
                                  _createElementVNode("p", _hoisted_41, _toDisplayString(result.label), 1),
                                  _createVNode(_component_Icon, {
                                    name: "arrow-right",
                                    class: "flex-shrink-0 w-4 h-4 ml-6 text-primary-500 group-hover:text-white stroke-current"
                                  })
                                ]),
                                _: 2
                              }, 1032, ["url", "overlay", "target"]))
                            }), 128))
                          ]),
                          ($options.hasMoreResults(cat))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                _createElementVNode("a", {
                                  class: "link cursor-pointer",
                                  onClick: ($event: any) => ($options.searchMobile(cat))
                                }, _toDisplayString($props.labels.moreResults), 9, _hoisted_43)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["title", "index", "component-uid"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}