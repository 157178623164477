import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabHeader = _resolveComponent("TabHeader")!
  const _component_EventsYear = _resolveComponent("EventsYear")!
  const _component_EventsTable = _resolveComponent("EventsTable")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_TabHeader, {
      modelValue: $data.currentFilter,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.currentFilter) = $event)),
      tabs: $options.tabs,
      "in-content-group": $props.inContentGroup,
      narrow: "",
      "component-uid": _ctx.$.uid,
      "hide-when-single-tab": true
    }, null, 8, ["modelValue", "tabs", "in-content-group", "component-uid"]),
    _createElementVNode("div", {
      class: _normalizeClass({'container': !$props.inContentGroup})
    }, [
      ($options.filterShown)
        ? (_openBlock(), _createBlock(_component_EventsYear, {
            key: 0,
            modelValue: $data.currentYear,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.currentYear) = $event)),
            options: $options.yearOptions,
            label: $props.labels.filter
          }, null, 8, ["modelValue", "options", "label"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["w-full mx-auto mt-3 md:mt-8 xl:mt-12", {'xl:w-2/3': !$props.inContentGroup}])
      }, [
        _withDirectives(_createVNode(_component_EventsTable, {
          events: $data.events,
          labels: $props.labels,
          "show-alert": !$options.filterShown
        }, null, 8, ["events", "labels", "show-alert"]), [
          [_vShow, !$data.loading]
        ])
      ], 2)
    ], 2),
    ($data.pages > 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["mt-4 md:mt-8 xl:mt-12", {'container': !$props.inContentGroup}])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-full mx-auto", {'xl:w-2/3': !$props.inContentGroup}])
          }, [
            _createVNode(_component_Pagination, {
              modelValue: $data.currentPage,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.currentPage) = $event)),
              pages: $data.pages
            }, null, 8, ["modelValue", "pages"])
          ], 2)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}