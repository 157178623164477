import { reactive } from 'vue';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

const twConfig = resolveConfig(tailwindConfig);

export const screens = {
    sm: Number(twConfig.theme.screens.sm.replace('px', '')),
    md: Number(twConfig.theme.screens.md.replace('px', '')),
    lg: Number(twConfig.theme.screens.lg.replace('px', '')),
    xl: Number(twConfig.theme.screens.xl.replace('px', ''))
};

const sm = val => val >= screens.sm && val < screens.md;
const smAndDown = val => val < screens.md;
const smAndUp = val => val >= screens.sm;

const md = val => val >= screens.md && val <= screens.lg;
const mdAndDown = val => val < screens.lg;
const mdAndUp = val => val >= screens.md;

const lg = val => val >= screens.lg && val <= screens.xl;
const lgAndDown = val => val < screens.xl;
const lgAndUp = val => val >= screens.lg;

const xl = val => val >= screens.xl;

const getBreakpoint = w => {
    if (sm(w)) return 'sm';
    else if (md(w)) return 'md';
    else if (lg(w)) return 'lg';
    else if (xl(w)) return 'xl';
    else return 'xs';
};

// eslint-disable-next-line @typescript-eslint/no-require-imports
const debounce = require('lodash.debounce');

const breakpoints = reactive({
    w: window.innerWidth,
    h: window.innerHeight,
    is: getBreakpoint(window.innerWidth),
    sm: sm(window.innerWidth),
    smAndDown: smAndDown(window.innerWidth),
    smAndUp: smAndUp(window.innerWidth),
    md: md(window.innerWidth),
    mdAndDown: mdAndDown(window.innerWidth),
    mdAndUp: mdAndUp(window.innerWidth),
    lg: lg(window.innerWidth),
    lgAndDown: lgAndDown(window.innerWidth),
    lgAndUp: lgAndUp(window.innerWidth),
    xl: xl(window.innerWidth),
    mobile: smAndDown(window.innerWidth),
    tablet: md(window.innerWidth) || lg(window.innerWidth),
    desktop: xl(window.innerWidth)
});

window.addEventListener(
    'resize',
    debounce(() => {
        breakpoints.w = window.innerWidth;
        breakpoints.h = window.innerHeight;
        breakpoints.is = getBreakpoint(window.innerWidth);
        breakpoints.sm = sm(window.innerWidth);
        breakpoints.smAndDown = smAndDown(window.innerWidth);
        breakpoints.smAndUp = smAndUp(window.innerWidth);
        breakpoints.md = md(window.innerWidth);
        breakpoints.mdAndDown = mdAndDown(window.innerWidth);
        breakpoints.mdAndUp = mdAndUp(window.innerWidth);
        breakpoints.lg = lg(window.innerWidth);
        breakpoints.lgAndDown = lgAndDown(window.innerWidth);
        breakpoints.lgAndUp = lgAndUp(window.innerWidth);
        breakpoints.xl = xl(window.innerWidth);
        breakpoints.mobile = smAndDown(window.innerWidth);
        breakpoints.tablet = md(window.innerWidth) || lg(window.innerWidth);
        breakpoints.desktop = xl(window.innerWidth);
    }, 200),
    false
);

export default breakpoints;
