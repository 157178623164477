<template>
    <div>
        <slot :open-terms="openTerms" :agreed="agreed" />
        <ContentOverlay v-cloak v-model="termsModel" :button-label="buttonLabel" @cta="agreed = true">
            <slot name="overlay" />
        </ContentOverlay>
    </div>
</template>

<script lang="ts">

import ContentOverlay from "../base/ContentOverlay.vue";

export default {
    components: {ContentOverlay},
    props: {
        buttonLabel: {type: String, required: true}
    },
    data() {
        return {
            termsModel: false,
            agreed: false
        };
    },
    methods: {
        openTerms(): void {
            this.termsModel = true;
        }
    }
};
</script>
