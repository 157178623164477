<template>
    <div class="w-full md:w-auto min-w-40 md:w-1/3 md:flex md:flex-auto relative md:mr-2 last:mr-0 mb-3 md:mb-0">
        <Dropdown ref="currentSelectionEL"
                  v-model="currentSelection"
                  class="w-full md:w-auto min-w-40 md:flex md:flex-auto"
                  :flex="true"
                  text-classes="text-para-xs md:text-para-s h-4 md:h-6 text-black-500 truncate pr-8"
                  :title="(placeholder ? placeholder : scope)"
                  :default-value="null"
                  :higher-menu="true"
                  @open="focusSearch"
        >
            <div class="cursor-pointer text-black-900 mx-2 mb-4 mt-3 text-para-s flex items-center justify-start relative border border-black-200 rounded">
                <input ref="searchField"
                       v-model="currentSearch"
                       type="text"
                       :placeholder="searchLabel"
                       class="w-10/12 h-full py-2 px-2 md:py-2.5 placeholder:text-para-s placeholder-improvedContrast"
                >
                <div class="w-2/12 h-full text-black-200 flex items-center justify-center absolute right-0 bottom-0">
                    <Icon name="search" class="stroke-current  w-4 h-4" />
                </div>
            </div>
            <template v-if="currentSearch.length === 0">
                <div v-for="group in groups"
                     :key="groups.indexOf(group)"
                     :class="{ 'mb-4' : groups[groups.indexOf(group) + 1] && !isSingle(groups[groups.indexOf(group) + 1]) }"
                >
                    <div class="px-4 w-full flex items-center justify-start mb-2 cursor-pointer"
                         :class="{ 'group px-4 py-3 md:py-2.5 pb-2 pt-2 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white' : isSingle(group) }"
                         @click="handleGroupClick(group)"
                    >
                        <span class="text-para-s text-black-400 mr-4 md:mr-3 xl:mr-4"
                              :class="{ 'transition-colors group-hover:text-white group-focus:text-white' : isSingle(group) }"
                        >
                            {{ group.title || group.name }}
                        </span>
                        <div v-if="!isSingle(group)"
                             class="w-3 h-3 md:w-2 md:h-2 xl:w-3 xl:h-3 text-black-600 flex items-center justify-start"
                        >
                            <Icon name="chevron-down" class="stroke-current w-full h-full transition-transform transform origin-center" :class="{ 'rotate-180': isGroupOpen(group) }" />
                        </div>
                    </div>
                    <CollapseTransition v-if="!isSingle(group)">
                        <div v-show="isGroupOpen(group)" class="pl-2">
                            <DropdownItem
                                v-for="(item, i) in group.items"
                                :key="i"
                                :value="item"
                                :label="(item.title || item.name)"
                                :show-label="false"
                                text-classes="pb-2 pt-2 group"
                                @click="setQuery(item)"
                            >
                                <span class="block text-para-s text-black-400 transition-colors group-hover:text-white group-focus:text-white">{{ (item.title || item.name) }}</span>
                            </DropdownItem>
                        </div>
                    </CollapseTransition>
                </div>
            </template>
            <template v-else>
                <template v-for="item in allItems" :key="allItems.indexOf(item)">
                    <DropdownItem
                        v-if="shouldItemRender(item)"
                        :value="item"
                        :label="(item.title || item.name)"
                        :show-label="false"
                        text-classes="text-para-s text-black-400 mb-4"
                        @click="setQuery(item)"
                    >
                        <div v-html="highlightSearch((item.title || item.name))" />
                    </DropdownItem>
                </template>
            </template>
        </Dropdown>
        <button v-if="currentQuery === scope"
                type="button"
                class="absolute w-3 h-full top-0 right-9 flex justify-center items-center text-black-500 cursor-pointer"
                :aria-label="$globalLabels.close"
                @click="unsetQuery"
                @keyup.delete="unsetQuery"
        >
            <Icon name="close" class="stroke-current w-full" />
        </button>
    </div>
</template>

<script lang="ts">
import {nextTick, PropType} from 'vue';
import { Operation, Region, SubOperation, SubRegion } from './SocioEconomicMapUtils';
import Dropdown from "../base/Dropdown.vue";
import Icon from "../base/Icon.vue";
import DropdownItem from "../base/DropdownItem.vue";
import CollapseTransition from "../base/CollapseTransition.vue";

export default {
    components: {CollapseTransition, DropdownItem, Icon, Dropdown},
    inject: ['$globalLabels'],
    props: {
        groups: {type: Array as PropType<Array<Operation | Region>>, default: () => ([])},
        scope: {type: String, required: true},
        currentQuery: {type: String, default: null},
        currentOperation: {type: Object as PropType<Operation | SubOperation>, default: null},
        currentRegion: {type: Object as PropType<Region | SubRegion>, default: null},
        placeholder: {type: String, default: ''},
        searchLabel: {type: String, default: ''}
    },
    emits: ['unset'],
    data() {
        return {
            currentSelection: null,
            currentSearch: '',
            openGroups: []
        };
    },
    computed: {
        allItems() {
            const result: Array<SubOperation | SubRegion> = [];
            this.groups.forEach((group: Operation | Region) => {
                group.items.forEach((item: SubRegion | SubOperation) => {
                    result.push(item);
                });
            });
            return result;
        },
        dropdownTextClasses() {
            return this.currentSelection !== null
                ? 'text-para-xs md:text-para-s h-4 md:h-6 text-black-500'
                : 'text-para-xs md:text-para-s h-4 md:h-6 text-black-200';
        }
    },
    watch: {
        async currentQuery() {
            if (this.currentQuery !== this.scope) {
                // Unset
                this.currentSelection = null;
                this.currentSearch = '';
                this.$refs.currentSelectionEL.activeValue = this.scope;
                this.$refs.currentSelectionEL.activeTitle = this.scope;
            } else {
                // Set
                this.currentSelection = null;
                this.currentSearch = '';
                this.$refs.currentSelectionEL.activeValue =
                    (this.scope === 'Operation' ? this.currentOperation : this.currentRegion);
                this.$refs.currentSelectionEL.activeTitle = this.scope === 'Operation'
                    ? (this.currentOperation.title || this.currentOperation.name)
                    : (this.currentRegion.title || this.currentRegion.name);
            }
        }
    },
    mounted(): void {
        this.openGroup(this.groups[0]);
    },
    methods: {
        shouldItemRender(item: SubOperation | SubRegion): boolean {
            return (
                item.title.includes(this.currentSearch) ||
                item.title.toUpperCase().includes(this.currentSearch.toUpperCase()) ||
                item.title.toLowerCase().includes(this.currentSearch.toLowerCase()) ||
                item.name.includes(this.currentSearch) ||
                item.name.toUpperCase().includes(this.currentSearch.toUpperCase()) ||
                item.name.toUpperCase().includes(this.currentSearch.toLowerCase())
            );
        },
        setQuery(item: SubOperation | SubRegion): void {
            this.$emit(`set${this.scope}`, item);
        },
        unsetQuery(): void {
            this.$emit('unset');
        },
        highlightSearch(string: string): string {
            return `${string.slice(0, this.getStartIndex(string))}<strong>${string.slice(this.getStartIndex(string), this.getStartIndex(string) + this.currentSearch.length)}</strong>${string.slice(this.getStartIndex(string) + this.currentSearch.length, string.length)}`;
        },
        getStartIndex(string: string): number {
            if (string.indexOf(this.currentSearch) > -1) return string.indexOf(this.currentSearch);
            if (string.toLowerCase().indexOf(this.currentSearch) > -1) return string.toLowerCase().indexOf(this.currentSearch);
            if (string.toUpperCase().indexOf(this.currentSearch) > -1) return string.toUpperCase().indexOf(this.currentSearch);
            return -1;
        },
        isSingle(group: Operation | Region) {
            return group.items.length < 1;
        },
        handleGroupClick(group: Operation | Region) {
            if (group.items.length > 0) {
                this.toggleGroup(group);
            } else {
                this.setQuery(group as any as SubOperation | SubRegion);
            }
        },
        toggleGroup(group: Operation | Region) {
            if (group) {
                if (this.isGroupOpen(group)) {
                    this.closeGroup(group);
                } else {
                    this.openGroup(group);
                }
            }
        },
        openGroup(group: Operation | Region): void {
            if (group) {
                this.openGroups.push(group.uuid);
            }
        },
        closeGroup(group: Operation | Region): void {
            if (group) {
                this.openGroups.splice(this.openGroups.indexOf(group.uuid), 1);
            }
        },
        isGroupOpen(group: Operation | Region): boolean {
            return group ? this.openGroups.includes(group.uuid) : false;
        },
        focusSearch(): void {
            nextTick(() => {
                if (this.searchField) {
                    this.searchField.focus({preventScroll: true});
                }
            });
        }
    }
};
</script>
