import { vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "fixed left-0 top-0 right-0 bottom-0 backdrop-filter backdrop-blur z-20",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.markAskClosed && $options.markAskClosed(...args)))
  }, null, 512)), [
    [_vShow, _ctx.navigationOpened]
  ])
}