<template>
    <div>
        <div class="flex flex-col md:flex-row justify-end items-center mb-8 md:mb-6 xl:mb-8">
            <span class="text-para-xs font-semibold w-full md:w-auto md:mr-6">{{ labels.title }}:</span>
            <Dropdown v-for="filter in filters"
                      :key="filter.type"
                      v-model="currentFilters[filter.type]"
                      class="w-full md:w-auto min-w-40 md:mr-2 last:mr-0 mb-1 md:mb-0"
                      text-classes="text-para-xs md:text-para-s h-4 md:h-6"
                      :title="filter.type"
                      :default-value="currentFilters[filter.type]"
            >
                <DropdownItem v-for="item in filter.data"
                              :key="item.type"
                              :label="item.label"
                              :value="item.type"
                              text-classes="text-para-xs md:text-para-s"
                />
            </Dropdown>
        </div>
        <ul class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-6 xl:gap-y-16 gap-x-4 xl:gap-x-8 mb-8 xl:mb-20">
            <li v-for="(item, index) in items" :key="index" class="flex flex-col">
                <div :class="['flex', 'md:flex-col', {'flex-col': item.videoLink }]">
                    <div v-if="!item.videoLink"
                         :class="'overflow-hidden relative flex-shrink-0 w-16 h-16 mr-4 md:w-full md:h-0 md:pt-16/9 md:mb-4 md:mr-0'"
                    >
                        <ResponsiveImage :urls="item.imageUrls"
                                         class="absolute top-0 left-0 object-cover object-center w-full h-full"
                                         :show-lightbox="showLightboxes"
                                         :alt-title="item.title"
                                         :hide-mobile-lightbox-icon="true"
                        />
                    </div>
                    <a v-else :href="getUrl(item, '')" :title="item.title" :class="'overflow-hidden relative flex-shrink-0 w-16 h-16 mr-4 w-full h-0 pt-16/9 mb-4 mr-0'" tabindex="-1">
                        <ResponsiveImage :urls="item.imageUrls" class="absolute top-0 left-0 object-cover object-center w-full h-full" />
                        <div v-if="item.videoLink">
                            <PlayButton :desktop-size="16"
                                        :tablet-size="10"
                                        :fill-default="true"
                                        :aria-label="$globalLabels.play"
                            />
                        </div>
                    </a>
                    <div class="flex flex-col">
                        <div class="flex flex-col md:flex-row md:justify-between md:items-center md:mb-2">
                            <div v-if="!item.videoLink" class="md:order-2 text-para-xs py-1">
                                <span v-if="item.country" class="px-3 py-1 bg-black-100 rounded-full mr-1">
                                    {{ item.country }}
                                </span>
                                <span v-if="item.commodity" class="px-3 py-1 bg-black-100 rounded-full">
                                    {{ item.commodity }}
                                </span>
                            </div>
                            <div class="md:order-1">
                                <span class="text-para-xs text-black-600">{{ item.dateString }}</span>
                            </div>
                        </div>
                        <div class="text-para-s md:text-para-base">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
                <div v-if="!item.videoLink" class="md:h-12 mt-3 md:mt-8">
                    <ContextMenu>
                        <template #default="{ toggle }">
                            <button type="button"
                                    class="h-full text-para-s font-semibold cursor-pointer"
                                    @click="toggle"
                            >
                                <span class="flex items-center h-full group">
                                    <span>{{ labels.download }}</span>
                                    <ArrowButton direction="download" class="w-10 h-10 group" />
                                </span>
                            </button>
                        </template>
                        <template #items="{ toggle }">
                            <a v-for="res in availableResolution"
                               :key="res"
                               :href="getUrl(item, res)"
                               target="_blank"
                               class="block text-para-s text-black-900 px-4 py-2 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white"
                               @click="toggle"
                               @keyup.enter="toggle"
                               download
                            >
                                {{ labels[res] }}
                            </a>
                        </template>
                    </ContextMenu>
                </div>
            </li>
        </ul>
        <Pagination v-model="currentPage" :pages="pages" />
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import Pagination from "../base/Pagination.vue";
import ContextMenu from "../base/ContextMenu.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import ResponsiveImage from "../base/ResponsiveImage.vue";
import PlayButton from "../video/PlayButton.vue";
import ArrowButton from "../base/ArrowButton.vue";

export interface Filter {
    type: string;
    data: Record<string, string>[];
    default: string;
}

export interface MediaLabels {
    title: string;
    download: string;
    low: string;
    medium: string;
    high: string;
}

export interface MediaItem {
    commodity: string;
    country: string;
    dateModified: string;
    dateString: string;
    description: string;
    duration: string;
    image: string;
    imageUrls: Record<string, string>;
    intro: string;
    name: string;
    title: string;
    urlImageHigh: string;
    urlImageLow: string;
    urlImageMedium: string;
    videoId: string;
    videoLink: string;
    videoType: string;
}

export default {
    components: {ArrowButton, PlayButton, ResponsiveImage, DropdownItem, Dropdown, ContextMenu, Pagination},
    inject: ['$site', '$globalLabels'],
    props: {
        initialPages: {type: Number, default: 1},
        limit: {type: Number, default: 6},
        showLightboxes: {type: Boolean, default: false},
        filters: {type: Array as PropType<Array<Filter>>},
        labels: {type: Object as PropType<MediaLabels>}
    },
    data() {
        return {
            apiUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Utils.addSiteToApi(Enums.API.LOCATION_V2, Enums.API.SEARCH_MEDIA, this.$site),
            currentFilters: {},
            currentPage: 1,
            pages: this.initialPages,
            items: [],
            availableResolution: ['low', 'medium', 'high']
        };
    },
    computed: {
        /**
         * Construct query string for filter options.
         * Default options aren't appended.
         */
        filterUrl(): string {
            let queryUrl = '';

            this.filters.forEach(filter => {
                queryUrl += this.currentFilters[filter.type] === filter.default
                    ? ''
                    : `&${filter.type}=${this.decodeStr(this.currentFilters[filter.type])}`;
            });
            return queryUrl;
        }
    },
    watch: {
        async currentPage() {
            this.getData(false);
        },
        currentFilters: {
            handler() {
                this.currentPage = 1;
                this.getData(true);
            },
            deep: true  // deep watch, because object
        }
    },
    created(): void {
        this.filters.forEach(filter => {
            this.currentFilters[filter.type] = filter.default;
        });
    },
    methods: {
        /**
         * Get the (download) url according to the type (video/image) and resolution.
         *
         * @param item          The item to look up the url
         * @param resolution    The selected resolution
         */
        getUrl(item: MediaItem, resolution: string) {
            if (item.videoLink) {
                return item.videoLink;
            }
            return item[`urlImage${this.capitalize(resolution)}`];
        },
        /**
         * Capitalizes the first letter of the given string.
         *
         * @param string    The string to capitalize
         */
        capitalize(string: string) {
            if (!string) return string;
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        /**
         * Request media data from the backend.
         *
         * @param reload    If reload is true, the pagination will be reset.
         */
        getData(reload: boolean): void {
            const url = `${this.apiUrl}offset=${(this.currentPage - 1) * this.limit}&limit=${this.limit}${this.filterUrl}&reload=${reload}&locale=${Utils.getLocalStorage(Enums.STORAGE_KEY.LANGUAGE)}&rendition=square,landscape,landscape`;

            axios.get(url)
                .then(res => res.data)
                .then(data => {
                    this.items = data.content;
                    this.pages = data.pages === -1 ? this.pages : data.pages;
                });
        },
        /**
         * Simple space encoding.
         *
         * @param str The string to encode.
         */
        decodeStr(str: string): string {
            return str.replace(' ', '%20');
        }
    }
};
</script>
