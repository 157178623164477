import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col overflow-x-auto pb-4 md:pb-0 px-4 md:px-0 -mx-4 md:mx-0" }
const _hoisted_2 = { class: "w-full text-para-xs md:text-para-s md:tracking-tight text-left" }
const _hoisted_3 = { class: "border-b border-black-200" }
const _hoisted_4 = {
  scope: "col",
  class: "p-2 md:px-4"
}
const _hoisted_5 = {
  scope: "col",
  class: "p-2 md:px-4"
}
const _hoisted_6 = {
  key: 0,
  scope: "col",
  class: "p-2 md:px-4"
}
const _hoisted_7 = { class: "p-2 md:px-4 whitespace-nowrap md:whitespace-normal" }
const _hoisted_8 = { class: "p-2 md:px-4 whitespace-nowrap md:whitespace-normal prose" }
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 1,
  class: "inline-block py-1 md:py-0"
}
const _hoisted_11 = {
  key: 0,
  class: "flex flex-row items-center justify-end p-2 md:px-4 whitespace-nowrap prose"
}
const _hoisted_12 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", _hoisted_3, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_4, _toDisplayString($props.labels.date), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString($props.labels.event), 1),
          ($props.showAlert)
            ? (_openBlock(), _createElementBlock("th", _hoisted_6))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.events, (event, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: index,
            class: "even:bg-black-50 hover:bg-primary-100 transition duration-100 ease-out"
          }, [
            _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.$filters.formatDate(event.dateFrom)), 1),
            _createElementVNode("td", _hoisted_8, [
              (event.link)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: event.link || ''
                  }, _toDisplayString(event.title), 9, _hoisted_9))
                : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(event.title), 1))
            ]),
            ($props.showAlert)
              ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
                  _createElementVNode("a", {
                    href: event.icsLink || ''
                  }, _toDisplayString($props.labels.addToCalendar), 9, _hoisted_12),
                  _cache[0] || (_cache[0] = _createElementVNode("svg", {
                    class: "w-4 h-4 stroke-current ml-2",
                    viewBox: "0 0 16 16",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("path", {
                      d: "M13.689 3.188H2.314a.875.875 0 00-.875.874v9.625c0 .484.391.876.875.876h11.375a.875.875 0 00.875-.876V4.063a.875.875 0 00-.875-.875zM1 8h13.125M4.939 4.5V1.437M11.064 4.5V1.437",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    })
                  ], -1))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ])
    ])
  ]))
}