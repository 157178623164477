<template>
    <ul class="grid grid-cols-1 md:gap-4 xl:gap-8"
        :class="[showImages ? 'gap-4' : 'gap-2', articles.length > 2 ? 'md:grid-cols-3' : 'md:grid-cols-2']"
    >
        <template v-for="(article, index) in articles" :key="index">
            <li v-if="showImages"
                class="w-full"
                :class="{ 'bg-white': index === 0,
                          'justify-self-start': articles.length === 1,
                          'md:row-span-3 md:col-span-3 xl:row-span-2 xl:col-span-2': index === 0 && articles.length > 1}"
            >
                <img v-if="article.asset"
                     :srcset="article.asset.sourceSet"
                     :sizes="article.asset.sizes"
                     :src="article.asset.source"
                     :alt="article.asset.title"
                     class="w-full object-cover object-center">
                <div :class="{ 'p-6': index === 0 }">
                    <div class="pt-4 xl:pt-6 uppercase text-para-xs">{{ article.date }}</div>
                    <div class="pt-2">{{ article.title }}</div>
                    <TeaserLink :url="article.link"
                                :type="article.type"
                                :title="article.title"
                                :aria-label="article.title"
                                classes="h-8 md:h-10 w-full flex items-center group mt-4 mb-8 md:mb-0"
                    >
                        <span class="font-semibold text-para-s">{{ labels.readMore }}</span>
                        <ArrowButton :direction="getIcon(article.type)" class="w-10 h-10" />
                    </TeaserLink>
                </div>
            </li>
            <li v-else :class="[highlight ? 'bg-white' : 'bg-black-50']">
                <div class="h-37 w-full pl-6 pr-12 pt-8 border-b border-solid border-black-100">
                    <div class="uppercase text-para-xs">{{ article.date }}</div>
                    <p class="mt-2 h-18 line-clamp-3">{{ article.title }}</p>
                </div>
                <TeaserLink :url="article.link"
                            :type="article.type"
                            :title="article.title"
                            :aria-label="article.title"
                            classes="h-15 w-full flex items-center group pl-6 pr-12"
                >
                    <span class="font-semibold text-para-s">{{ labels.readMore }}</span>
                    <ArrowButton :direction="getIcon(article.type)" class="w-10 h-10" />
                </TeaserLink>
            </li>
        </template>
    </ul>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {Article, ArticleLabels} from './ArticleFilterGrid.vue';
import ArrowButton from "../base/ArrowButton.vue";
import TeaserLink from "../teaser/TeaserLink.vue";

/**
 * Render filtered articles.
 */
export default {
    components: {TeaserLink, ArrowButton},
    props: {
        articles: {type: Array as PropType<Array<Article>>, default: () => []},
        showImages: {type: Boolean, default: false},
        highlight: {type: Boolean, default: false},
        labels: {type: Object as PropType<ArticleLabels>, default: () => {}}
    },
    methods: {
        getIcon(type: string): string {
            return type === 'overlay' ? type : 'right';
        }
    }
};
</script>
