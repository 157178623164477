<template>
    <Form v-slot="{ handleSubmit }" as="div">
        <slot :submit="submit" :handle-submit="handleSubmit" />
    </Form>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import Utils from '../../utils/Utils';
import {Form} from "vee-validate";
import DynamicsUtils from "../../utils/DynamicsUtils";
import {PropType, toRaw} from "vue";

export interface DynamicsFieldMapping {
    FormFieldName: string;
    DataverseFieldName: string;
}

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {Form},
    props: {
        uuid: {type: String},
        agreed: {type: Boolean, default: false},
        dynamicsSend: {type: Boolean, default: false},
        dynamicsFormId: {type: String},
        dynamicsApiUrl: {type: String},
        dynamicsLibUrl: {type: String},
        dynamicsFieldMappings: {type: Array as PropType<Array<DynamicsFieldMapping>>, default: () => []}
    },
    data() {
        return {
            genericDynamicsFieldMappings: undefined
        };
    },
    watch: {
        /**
         * Special method for the login component, where users might have to agree to terms & conditions first
         */
        async agreed() {
            if (this.agreed) {
                this.submit();
            }
        }
    },
    created(): void {
        if (this.dynamicsSend && this.dynamicsLibUrl) {
            Utils.addScript(this.dynamicsLibUrl);
        }
    },
    mounted(): void {
        const params = new URLSearchParams(window.location.search);
        if (params.has('mgnlFormToken')) {
            const actionResult = document.getElementById(this.uuid);
            if (actionResult) {
                actionResult.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }

        DynamicsUtils.loadGenericFieldMappings().then((mappings) => this.genericDynamicsFieldMappings = mappings);
    },
    methods: {
        submit(): void {
            const form = this.$el.querySelector('form');
            if (form) {
                // get csrf from cookie if not set
                const csrf = this.$el.querySelector('#csrf') as HTMLInputElement;
                if (csrf && !csrf.value) {
                    csrf.value = Cookies.get('csrf');
                }
                if (this.dynamicsSend) {
                    // dynamics form capture
                    const form = this.$el.querySelector('form');

                    DynamicsUtils.submitDynamicsForm(
                        form,
                        toRaw(this.genericDynamicsFieldMappings),
                        this.dynamicsFieldMappings,
                        this.dynamicsFormId,
                        this.dynamicsApiUrl,
                        form.submit // callback which is called after submitting dynamics form
                    );
                } else {
                    // normal submit
                    form.submit();
                }
            }
        }
    }
};
</script>
