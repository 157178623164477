import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative container h-38 md:h-35 py-4 md:py-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return ($data.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["top-0 w-full bg-secondary-200 z-50", { 'fixed': !$props.editMode }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "absolute top-4 md:top-8 right-4 md:right-8 text-black-900 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.hide && $options.hide(...args)))
          }, [
            _createVNode(_component_Icon, {
              name: "close",
              class: "w-4 h-4 stroke-current"
            })
          ]),
          _renderSlot(_ctx.$slots, "default")
        ])
      ], 2))
    : _createCommentVNode("", true)
}