<template>
    <nav class="relative flex items-center w-full h-14 md:h-20 text-para-xs transition-colors"
         :class="{'text-black-700 bg-white': showBackground, 'text-white': !showBackground}"
    >
        <slot :navigation-opened="navigationOpened"
              :global-nav-opened="globalNavOpened"
              :search-opened="searchOpened"
              :overlay-opened="overlayOpened"
              :show-background="showBackground"
              :show-black-logo="showBlackLogo"
              :toggle-navigation="toggleNavigation"
              :toggle-global-nav="toggleGlobalNavigation"
              :toggle-search="toggleSearch"
              :close-overlays="closeOverlays"
              :status="status"
              :show-language-banner="showLanguageBanner"
        />

        <!-- language hint banner -->
        <Transition name="fade">
            <div v-if="bannerModel" ref="languageHint" class="hidden xl:block absolute left-0 top-full bottom-0 w-full h-fit text-right bg-black-50 text-black-900 py-4 xl:px-28">
                <span v-if="languageSwitcherShown" class="absolute top-0 right-32 transform rotate-45 -translate-y-1/2 h-6 w-6 bg-black-50" />
                <span>{{ hint }}</span>
            </div>
        </Transition>

        <!-- no content for this language overlay -->
        <Transition v-if="!editMode" name="fade">
            <div v-if="noContentModel && prevSelectedLocale"
                 class="fixed left-0 top-0 w-full h-full bg-black-900 bg-opacity-30"
            >
                <div class="container h-full py-22 md:py-28">
                    <div class="relative xl:w-2/3 mx-auto bg-primary-50 py-6 pl-8 pr-18">
                        <div
                            class="absolute top-0 right-0 flex justify-center items-center w-10 h-10 bg-primary-500 hover:bg-black-900 cursor-pointer"
                            @click="noContentModel = false"
                        >
                            <Icon name="close" class="w-4 h-4 text-white stroke-current" />
                        </div>
                        <span class="text-para-s text-black-900">{{ noContentText }}</span>
                    </div>
                </div>
            </div>
        </Transition>
    </nav>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Cookies from 'js-cookie';
import {useNavigationStore} from "../../stores/navigation";
import {mapState, mapWritableState} from "pinia";
import Icon from "../base/Icon.vue";

export default {
    components: {Icon},
    inject: ['$lang'],
    props: {
        transparent: {type: Boolean, default: false},
        editMode: {type: Boolean, default: false},
        showLocaleHints: {type: Boolean, default: false},
        showLanguageBanner: {type: Boolean, default: false},
        availableLocales: {type: Array as PropType<Array<string>>, default: () => []},
        languageSwitcherShown: {type: Boolean, default: true},
        hint: {type: String},
        noContentTexts: {type: Object}
    },
    data() {
        return {
            scrollPos: -1,

            bannerModel: false,
            noContentModel: false,
            prevSelectedLocale: undefined
        };
    },
    computed: {
        ...mapState(useNavigationStore, ['bodyClasses']),
        ...mapWritableState(useNavigationStore, ['navigationOpened', 'globalNavOpened', 'searchOpened', 'adaptiveLanguageSwitcherOpened']),
        overlayOpened(): boolean {
            return this.globalNavOpened || this.searchOpened;
        },
        showBackground(): boolean {
            return this.scrollPos > 0 || !this.transparent || this.globalNavOpened || this.searchOpened;
        },
        showBlackLogo(): boolean {
            return this.navigationOpened || this.showBackground;
        },
        // hack to set body classes via getter
        status(): number {
            return this.bodyClasses;
        },
        noContentText(): string {
            const texts: Map<string, string> = new Map(Object.entries(this.noContentTexts));
            return this.prevSelectedLocale && texts.has(this.prevSelectedLocale) ? texts.get(this.prevSelectedLocale) : null;
        }
    },
    mounted(): void {
        this.prevSelectedLocale = Cookies.get('prev-selected-locale');
        if (!this.editMode && this.transparent) {
            document.addEventListener('scroll', this.onScroll);
        }
        // set navigationStore variable for top margin correction
        this.adaptiveLanguageSwitcherOpened = this.showLanguageBanner;
        // extended hints for locales, e.g. when sub site has more locales than core site or current site doesn't have a set locale
        if (this.showLocaleHints) {
            // show hint for additional languages once
            if (this.showLanguageBanner) {
                if (!Cookies.get('shown-language-hint')) {
                    this.bannerModel = true;
                    Cookies.set('shown-language-hint', 'true');
                }
            } else {
                Cookies.remove('shown-language-hint');
            }
            // show no content overlay
            if (this.showNoContentForLocale()) {
                if (!Cookies.get('shown-no-content-hint') && this.isSameDomain()) {
                    this.noContentModel = true;
                    Cookies.set('shown-no-content-hint', 'true');
                }
            } else {
                Cookies.remove('shown-no-content-hint');
            }
            // this will close the language banner if clicked anywhere outside of it
            document.addEventListener('click', this.onOutsideLanguageHintClick);
            // set current locale as cookie
            Cookies.set('prev-selected-locale', this.$lang);
        } else {
            // cleanup after setting switch
            if (Cookies.get('shown-language-hint')) {
                Cookies.remove('shown-language-hint');
            }
            if (Cookies.get('shown-no-content-hint')) {
                Cookies.remove('shown-no-content-hint');
            }
        }
    },
    beforeUnmount(): void {
        document.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        toggleNavigation(): void {
            this.navigationOpened = !this.navigationOpened;
            // shouldn't be possible to open multiple navigations/overlays at the same time
            if (this.navigationOpened) {
                this.globalNavOpened = false;
                this.searchOpened = false;
            }
        },
        toggleGlobalNavigation(): void {
            this.globalNavOpened = !this.globalNavOpened;
            // shouldn't be possible to open multiple navigations/overlays at the same time
            if (this.globalNavOpened) {
                this.navigationOpened = false;
                this.searchOpened = false;
            }
        },
        toggleSearch(): void {
            this.searchOpened = !this.searchOpened;
            // shouldn't be possible to open multiple navigations/overlays at the same time
            if (this.searchOpened) {
                this.navigationOpened = false;
                this.globalNavOpened = false;
            }
        },
        closeOverlays(): void {
            this.globalNavOpened = false;
            this.searchOpened = false;
        },

        onScroll(e: Event): void {
            this.scrollPos = (e.target as Document).scrollingElement.scrollTop;
        },
        onOutsideLanguageHintClick(evt: Event) {
            let targetElement = evt.target as HTMLElement;
            do {
                if (targetElement === this.languageHint) {
                    return;
                }
                targetElement = targetElement.parentElement;
            } while (targetElement);
            this.bannerModel = false;
        },
        isSameDomain() {
            if (!document.referrer) {
                return false;
            }
            const url = new URL(document.referrer);
            return url.origin === window.origin;
        },
        showNoContentForLocale() {
            if (this.prevSelectedLocale) {
                return !this.availableLocales.includes(this.prevSelectedLocale);
            } else {
                return false;
            }
        }
    }
};
</script>
