<template>
    <div class="flex flex-col">
        <div class="flex justify-between mb-4">
            <div class="flex gap-4 items-center">
                <span class="text-para-s text-black-600">{{ comment.fullName }}</span>
                <span v-if="comment.role" class="text-para-xs text-primary-900 bg-primary-50 rounded-full py-1 px-2.5">
                    {{ labels[comment.role] }}
                </span>
                <div v-if="isAdmin" class="flex gap-4 text-black-600">
                    <div @click="toggleEditMode(index)">
                        <Icon name="edit" class="w-4 h-4 stroke-current fill-current cursor-pointer" />
                    </div>
                    <div @click="deleteComment(comment.id)">
                        <Icon name="delete" class="w-4 h-4 fill-current cursor-pointer" />
                    </div>
                </div>
            </div>
            <span class="hidden md:inline text-para-s text-black-600">{{ $filters.formatDateTime(comment.date) }}</span>
        </div>
        <h5 v-if="comment.title" class="font-semibold mb-2">{{ comment.title }}</h5>
        <!-- normal view -->
        <div v-if="!editMode[index]">
            <p ref="text" class="prose transition-all mb-6 overflow-hidden max-h-30 line-clamp-5" v-html="formatText(comment.text)" />
        </div>
        <!-- edit view -->
        <div v-else class="flex flex-col items-end mb-6">
            <textarea v-model="comment.text"
                      class="w-full border border-black-100 text-black-600 text-para-s rounded-sm focus:outline-none min-h-32 py-3 px-4 mb-6"
            />
            <GcButton type="button"
                      :label="labels.submit"
                      :disabled="isLoading"
                      secondary
                      @click="updateComment(comment, index)"
            />
        </div>
        <span class="md:hidden text-para-s text-black-600 mt-2 mb-6">{{ $filters.formatDateTime(comment.date) }}</span>
        <div class="flex items-center gap-4 text-para-s">
            <button v-if="!disabled"
                    type="button"
                    class="flex items-center font-semibold group cursor-pointer"
                    @click="toggle"
            >
                <span>{{ labels.reply }}</span>
                <ArrowButton direction="reply" class="w-10 h-10 stroke-current" />
            </button>
            <template v-if="!nested">
                <span v-if="!disabled" class="text-black-600">|</span>
                <span class="text-black-600">{{ (comment.comments || []).length }} {{ labels.comments }}</span>
            </template>
        </div>
        <div v-if="!disabled" v-show="replyMode[index]" class="flex flex-col items-end">
            <textarea ref="edit"
                      v-model="replyModel[index]"
                      class="w-full border border-black-100 text-black-600 text-para-s rounded-sm focus:outline-none py-3 px-4 my-4"
                      rows="1"
                      @keydown="autosize"
            />
            <GcButton type="button"
                      :label="labels.submit"
                      :disabled="!replyModel[index] || isLoading"
                      secondary
                      @click="replyToComment(nested ? parentId : comment.id, index)"
            />
        </div>
        <div v-if="!nested || !isLast" class="border-b border-black-200 my-6" />
    </div>
</template>

<script lang="ts">
import {nextTick, PropType} from 'vue';
import {Comment, CommentLabels} from './PublicUserComments.vue';
import Icon from "../base/Icon.vue";
import GcButton from "../base/GcButton.vue";
import ArrowButton from "../base/ArrowButton.vue";

export default {
    components: {ArrowButton, GcButton, Icon},
    inject: ['loading', 'replyModel', 'replyMode', 'editMode', 'formatText', 'autosize', 'toggleReply', 'toggleEditMode', 'getComments', 'replyToComment', 'deleteComment', 'updateComment'],
    props: {
        comment: {type: Object as PropType<Comment>, required: true},
        labels: {type: Object as PropType<CommentLabels>, required: true},
        nested: {type: Boolean, default: false},
        isAdmin: {type: Boolean, default: false},
        isLast: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        index: {type: [String, Number], required: true},
        fullName: {type: String, required: true},
        email: {type: String, required: true},
        parentId: {type: Number}
    },
    data() {
        return {
            isLoading: false
        };
    },
    watch: {
        /**
         * This property is when comments are loaded/posted/deleted/etc. and therefore needs to be watched.
         *
         * @param newVal The new loading value
         */
        async loading(newVal: boolean) {
            this.isLoading = newVal;
        }
    },
    mounted() {
        this.isLoading = this.loading;
    },
    methods: {
        /**
         * Toggle a reply for this comment.
         */
        toggle(): void {
            this.toggleReply(this.comment, this.index);

            if (this.replyMode[this.index]) {
                nextTick(() => this.$refs.edit.focus());
            }
        }
    }
};
</script>
