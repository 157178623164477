import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative w-full h-full bg-white overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out" }
const _hoisted_2 = { class: "text-para-s px-6 pt-4 pb-6" }
const _hoisted_3 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_4 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["min-h-30", [!$options.isVertical ? 'h-60 md:h-auto' : '']])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      ($props.data.title)
        ? (_openBlock(), _createElementBlock("h6", _hoisted_3, _toDisplayString($props.data.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString($props.data.text), 1)
    ]),
    _createElementVNode("a", {
      href: $props.data.link,
      class: "absolute left-0 right-0 top-0 bottom-0",
      "aria-label": $props.data.title,
      target: "_blank"
    }, null, 8, _hoisted_4)
  ]))
}