import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  class: "w-full h-full",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_3 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([['w-' + $props.mobileSize + ' h-' + $props.mobileSize + ' md:w-' + $props.tabletSize + ' md:h-' + $props.tabletSize + ' xl:w-' + $props.desktopSize + ' xl:h-' + $props.desktopSize,{'absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2': $props.absolute}], "flex flex-row justify-center items-center rounded-full bg-white cursor-pointer transform transition-all duration-300 ease-out hover:scale-115 focus:scale-115 focus-primary-out z-10 group/1"]),
    "aria-label": $props.ariaLabel,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["w-3 h-3", [{'md:w-6 md:h-6': $props.tabletSize > 10},{'xl:w-6 xl:h-6': $props.desktopSize > 10}]])
    }, [
      (_openBlock(), _createElementBlock("svg", _hoisted_2, [
        _createElementVNode("path", {
          d: "M2.33801 3.25502V20.745C2.33799 21.0044 2.4052 21.2593 2.5331 21.4849C2.66099 21.7105 2.84519 21.8991 3.06773 22.0323C3.29026 22.1654 3.54352 22.2387 3.80279 22.2447C4.06205 22.2508 4.31847 22.1896 4.54701 22.067L20.87 13.322C21.1091 13.1938 21.309 13.0032 21.4483 12.7704C21.5877 12.5376 21.6613 12.2713 21.6613 12C21.6613 11.7287 21.5877 11.4625 21.4483 11.2297C21.309 10.9969 21.1091 10.8062 20.87 10.678L4.54701 1.93302C4.31847 1.81044 4.06205 1.74921 3.80279 1.7553C3.54352 1.76139 3.29026 1.83459 3.06773 1.96777C2.84519 2.10095 2.66099 2.28955 2.5331 2.51516C2.4052 2.74077 2.33799 2.99568 2.33801 3.25502V3.25502Z",
          stroke: $props.fillDefault ? '#00928E' : '#949494',
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, 8, _hoisted_3),
        _cache[1] || (_cache[1] = _createElementVNode("path", {
          class: "dashoffset-16 dasharray-16 transition-all duration-300 ease-out group-hover/1:dashoffset-0 group-focus/1:dashoffset-0",
          d: "M2.33801 3.25502V20.745C2.33799 21.0044 2.4052 21.2593 2.5331 21.4849C2.66099 21.7105 2.84519 21.8991 3.06773 22.0323C3.29026 22.1654 3.54352 22.2387 3.80279 22.2447C4.06205 22.2508 4.31847 22.1896 4.54701 22.067L20.87 13.322C21.1091 13.1938 21.309 13.0032 21.4483 12.7704C21.5877 12.5376 21.6613 12.2713 21.6613 12C21.6613 11.7287 21.5877 11.4625 21.4483 11.2297C21.309 10.9969 21.1091 10.8062 20.87 10.678L4.54701 1.93302C4.31847 1.81044 4.06205 1.74921 3.80279 1.7553C3.54352 1.76139 3.29026 1.83459 3.06773 1.96777C2.84519 2.10095 2.66099 2.28955 2.5331 2.51516C2.4052 2.74077 2.33799 2.99568 2.33801 3.25502V3.25502Z",
          stroke: "#00928E",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, -1))
      ]))
    ], 2)
  ], 10, _hoisted_1))
}