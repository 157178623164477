<template>
    <Teleport to="body">
        <Transition name="fade">
            <dialog v-show="visible"
                    ref="dialogEL"
                    class="w-full max-h-full backdrop:bg-black-900 backdrop:bg-opacity-20 backdrop:backdrop-filter backdrop:backdrop-blur"
                    :class="{'max-w-content-modal': !narrow, 'max-w-content-modal-narrow': narrow}"
            >
                <div class="flex flex-col bg-white">
                    <div class="flex justify-end">
                        <button class="flex justify-center items-center w-10 h-10 bg-primary-500 hover:bg-black-900 focus:bg-black-900 cursor-pointer"
                                autofocus
                                :aria-label="$globalLabels.close"
                                @click="hide"
                        >
                            <Icon name="close" class="w-4 h-4 text-white stroke-current" />
                        </button>
                    </div>
                    <div ref="parentContainer"
                         class="flex flex-col max-h-full prose md:prose-md overflow-y-auto px-4 md:px-6 xl:px-32"
                         :class="{'pb-4 md:pb-12 xl:pb-16': !buttonLabel}"
                         tabindex="-1"
                    >
                        <div ref="contentContainer">
                            <slot />
                        </div>
                    </div>
                    <slot name="buttons">
                        <div v-if="buttonLabel" class="flex px-4 md:px-6 xl:px-32 pt-4.5 md:pt-12 pb-4.5 md:pb-12 xl:pb-16"
                             :class="{'justify-center': !narrow, 'shadow-top': scrollable}"
                        >
                            <GcButton :class="{'px-14 md:px-18': !narrow}"
                                      :label="buttonLabel"
                                      secondary
                                      @click="ctaClick"
                            />
                        </div>
                    </slot>
                </div>
            </dialog>
        </Transition>
    </Teleport>
</template>

<script lang="ts">
import Cookies from 'js-cookie';
import Icon from "./Icon.vue";
import GcButton from "./GcButton.vue";
import {nextTick} from "vue";

export default {
    components: {GcButton, Icon},
    inject: ['$globalLabels'],
    props: {
        modelValue: {type: Boolean, default: false},
        showOnLoad: {type: Boolean, default: false},
        showAlways: {type: Boolean, default: false},
        narrow: {type: Boolean, default: false},
        delay: {type: Number, default: 500},
        buttonLabel: {type: String}
    },
    emits: ['cta', 'hide', 'show', 'update:modelValue'],
    data() {
        return {
            scrollable: false,
            visible: false
        };
    },
    watch: {
        /**
         * Watch for changes on model value and react accordingly.
         *
         * @param newVal The new model value
         */
        modelValue(newVal: boolean) {
            if (newVal) {
                this.show();
            } else {
                this.hide();
            }
        }
    },
    mounted(): void {
        this.visible = this.modelValue;

        const alreadyShown = !!Cookies.get('gc-overlay-shown');

        if (this.showAlways || (this.showOnLoad && !alreadyShown)) {
            setTimeout(() => this.show(), this.delay);
        }
    },
    methods: {
        checkIfScrollable(): void {
            this.scrollable = !!this.$refs.parentContainer && !!this.$refs.contentContainer &&
                (this.$refs.parentContainer.clientHeight < this.$refs.contentContainer.clientHeight);
        },
        /**
         * The CTA button was clicked, emit an additional event.
         */
        ctaClick(): void {
            this.hide();
            this.$emit('cta');
        },
        /**
         * Shows the dialog.
         */
        show(): void {
            document.body.style.overflow = 'hidden';
            this.visible = true;

            if (this.$refs.dialogEL && !this.$refs.dialogEL.open) {
                this.$refs.dialogEL.showModal();
            }

            // make sure hide method is called when dialog is closed by pressing escape
            this.$refs.dialogEL.addEventListener('close', this.hide, { once: true });
            window.addEventListener('resize', this.checkIfScrollable);

            Cookies.set('gc-overlay-shown', 'true');

            nextTick(() => this.checkIfScrollable());

            this.$emit('update:modelValue', true);
            this.$emit('show', true);
        },
        /**
         * Hides the dialog.
         */
        hide(): void {
            this.visible = false;
            document.body.style.overflow = '';

            if (this.$refs.dialogEL.open) {
                this.$refs.dialogEL.close();
            }

            window.removeEventListener('resize', this.checkIfScrollable);

            this.$emit('update:modelValue', false);
            this.$emit('hide', false);
        }
    }
};
</script>
