<template>
    <div class="flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center">
        <Field v-if="type === DutyFieldType.RANGE_SLIDER"
               v-slot="{ errors, field }"
               v-model="model"
               class="w-full flex flex-col border border-black-100 rounded p-4 md:pr-28"
               :rules="rules"
               as="div"
               :name="label"
        >
            <span class="text-para-s font-semibold mb-4">
                {{ label }}
            </span>
            <div class="flex flex-col md:flex-row gap-x-6 gap-y-2">
                <NumberInput v-bind="field"
                             v-model="model"
                             class="appearance-none text-para-lg font-semibold focus:outline-none w-full md:w-28"
                             :name="name"
                             :options="rangeOptions"
                             aria-required="true"
                             :aria-label="label"
                             :aria-invalid="errors.length > 0"
                             :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
                             @update:model-value="updateSliderValue"
                />
                <div class="flex-grow">
                    <!-- Slider is bound to sliderModel and not to model directly, as v-bind doesn't seem to work here -->
                    <VueSlider v-if="initialised"
                               v-model="sliderModel"
                               :height="2"
                               :min="Math.min(...dValues)"
                               :max="Math.max(...dValues)"
                               :adsorb="true"
                               :interval="interval"
                               :process-style="{backgroundColor: '#00928e'}"
                               :dot-style="{backgroundColor: '#00928e'}"
                               tooltip="none"
                               @change="(value: number) => model = value"
                               @dragging="(value: number) => model = value"
                    />
                    <div class="flex justify-between mt-px">
                        <span class="text-para-xs text-black-500">{{ _min }}</span>
                        <span class="text-para-xs text-black-500">{{ _max }}</span>
                    </div>
                </div>
            </div>
            <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-4">
                {{ errors[0] }}
            </span>
        </Field>
        <template v-else>
            <label :for="name" class="md:w-48">{{ label }}</label>
            <div class="relative w-full max-w-full md:max-w-8/12">
                <Dropdown v-if="type === DutyFieldType.DROPDOWN"
                          :id="name"
                          v-model="model"
                          :title="model"
                          :white="true"
                          :disabled="disabled"
                          class="w-full min-w-40"
                >
                    <DropdownItem v-for="(val, i) in dValues"
                                  :key="i"
                                  :value="`${name}#${i}`"
                                  :label="`${val}`"
                    />
                </Dropdown>
                <Field v-else-if="type === DutyFieldType.PERCENTAGE"
                       v-slot="{ field, errors }"
                       v-model="model"
                       :vid="name"
                       :rules="{ required: true, min: 0, max: 99 }"
                       :name="name"
                >
                    <input :id="name"
                           v-bind="field"
                           type="number"
                           class="w-full border transition-colors cursor-pointer border-black-100 hover:text-black-900 bg-white rounded-b rounded-t px-4 placeholder-improvedContrast"
                           :class="[ errors.length > 0 ? 'border-error' : '']"
                           aria-required="true"
                           :aria-invalid="errors.length > 0"
                           :aria-describedby="errors.length > 0 ? `${name}-error` : ''"
                           min="0"
                           max="99"
                           placeholder="enter a % value between 1-99"
                    >
                    <span v-if="errors.length > 0" :id="`${name}-error`" class="text-error text-para-xs mt-1 md:mt-2">
                        {{ errors[0] }}
                    </span>
                </Field>
                <template v-else-if="type === DutyFieldType.CHECKBOX">
                    <input :id="name"
                           v-model="model"
                           type="checkbox"
                           :value="true"
                           class="absolute opacity-0 text-black-900 text-para-xs md:text-para-s"
                    >
                    <label :for="name" class="inline-block relative pl-6 checkbox-label-calculator">
                        <span class="d-md-none">{{ label }}</span>
                        <span class="d-none d-md-inline">&nbsp;{{ yesLabel }}</span>
                    </label>
                </template>
                <Dropdown v-else-if="type === DutyFieldType.KVP_DROPDOWN"
                          :id="name"
                          v-model="model"
                          :title="name"
                >
                    <DropdownItem v-for="c in values"
                                  :key="`${name}#${c.value}`"
                                  :value="c.value"
                                  :label="c.label"
                    />
                </Dropdown>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import {DutyFieldType} from './calculations';
import {CurrencyInputOptions} from 'vue-currency-input';
import {formattingOptions} from './albion/MultiStepForm.vue';
import NumberInput from "../base/NumberInput.vue";
import Dropdown from "../base/Dropdown.vue";
import DropdownItem from "../base/DropdownItem.vue";
import {Field} from "vee-validate";
import VueSlider from "vue-3-slider-component";
import {nextTick} from "vue";

export default {
    components: {Field, DropdownItem, Dropdown, NumberInput, VueSlider},
    props: {
        name: {type: String, required: true},
        label: {type: String, required: true},
        yesLabel: {type: String, default: 'yes'},
        values: {type: Array},
        interval: {type: Number},
        disabled: {type: Boolean},
        type: {type: Number, required: true},
        modelValue: {type: [String, Number, Boolean]},
        dependency: {type: Function},
        observable: {type: [String, Number]}
    },
    emits: ['update:modelValue'],
    data() {
        return {
            DutyFieldType,  // see: https://stackoverflow.com/questions/57538539/how-to-use-enums-or-const-in-vuejs
            model: '',
            sliderModel: 0,
            initialised: false
        };
    },
    computed: {
        modelLabel(): string {
            const selected = this.values.find(x => x.value === this.model);
            return selected ? selected.label : '';
        },
        // type: (number[] | { key: string, value: string }[]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dValues(): any[] {
            if (!this.dependency || !this.values || !this.observable) {
                return this.values;
            }
            return this.values.filter(this.dependency);
        },
        rangeOptions(): CurrencyInputOptions {
            return formattingOptions;
        },
        /**
         * Computes a visual representation of the slider min value.
         */
        _min(): string {
            return new Intl.NumberFormat().format(Math.min(...this.dValues));
        },
        /**
         * Computes a visual representation of the slider max value.
         */
        _max(): string {
            return new Intl.NumberFormat().format(Math.max(...this.dValues));
        },
        /**
         * Determines validation rules to use for the field.
         */
        rules(): Record<string, unknown> {
            /* eslint-disable */
            return {
                min_value: Math.min(...this.dValues),
                max_value: Math.max(...this.dValues),
                required: true
            };
            /* eslint-enable */
        }
    },
    watch: {
        /**
         * Listen for changes in modelValue and update model accordingly.
         *
         * @param val The new value
         */
        modelValue(val) {
            this.initialised = false;

            if (val) {
                this.model = val;
            } else {
                this.initialiseModel();
            }

            this.initialised = true;
        },
        /**
         * Listen for changes in model and emit modelChanged-event if needed.
         *
         * @param val New value
         * @param old Old value
         */
        model(val, old) {
            // trigger validation because automatic validation is disabled,
            // but only if there previously was a value (to avoid initial validation error)
            if (this.type === DutyFieldType.PERCENTAGE && old) {
                // nothing here
            } else if (this.type === DutyFieldType.DROPDOWN) {
                this.$emit('update:modelValue', val ? parseFloat(val) : val);
            } else if (this.type === DutyFieldType.CHECKBOX) {
                this.$emit('update:modelValue', val && !this.disabled);
            } else {
                this.$emit('update:modelValue', val);
            }
        },
        /**
         * Listens for changes in the duty values and re-initialises model if needed.
         */
        dValues() {
            if (!this.dValues) return;
            // if the dependency changes make sure the current value
            // still is in a valid range -> select last valid value
            if (!this.dValues.find(x => (this.type === DutyFieldType.KVP_DROPDOWN ? x.value === this.model : x === this.model)) && this.dValues.length > 0) {
                this.initialiseModel();
            }
        },
        /**
         * Listens for changes in the observable and re-initialises model if needed.
         */
        observable() {
            if (!this.dValues) return;
            // if the dependency changes make sure the current value
            // still is in a valid range -> select last valid value
            if (!this.dValues.find(x => (this.type === DutyFieldType.KVP_DROPDOWN ? x.value === this.model : x === this.model)) && this.dValues.length > 0) {
                this.initialiseModel();
            }
        }
    },
    mounted(): void {
        if (this.modelValue) {
            this.model = this.modelValue;
        } else {
            this.initialiseModel();
        }

        this.initialised = true;
    },
    methods: {
        /**
         * Initialises the internal model, based on the type of field that is rendered.
         */
        initialiseModel(): void {
            switch (this.type) {
                case DutyFieldType.CHECKBOX:
                    this.model = false;
                    break;
                case DutyFieldType.DROPDOWN:
                    this.model = Math.min(...this.dValues);
                    break;
                case DutyFieldType.RANGE_SLIDER:
                    this.model = Math.min(...this.dValues);
                    this.sliderModel = this.model;
                    break;
                case DutyFieldType.KVP_DROPDOWN:
                    this.model = this.values[0].value;
                    break;
                case DutyFieldType.PERCENTAGE:
                    // set number input to undefined so placeholder is displayed
                    // this will trigger a validation error, so automatic validation is disabled
                    this.model = undefined;
                    break;
                default:
                    break;
            }
        },
        /**
         * When the number input in front of the slider is changed, the slider needs to be updated
         * accordingly, so the user has a visual feedback.
         *
         * If the input value is out-of-range, the slider resets to the lowest value.
         *
         * If the input value does not exactly match one of the allowed values for the slider, the
         * value is adjusted to the nearest point.
         *
         * @param newValue The new value
         */
        updateSliderValue(newValue: number): void {
            if (newValue &&
                newValue >= Math.min(...this.dValues) &&
                newValue <= Math.max(...this.dValues)) {
                // in range!
                // now find the closest allowed value...
                const closest: number = this.dValues.reduce(function(prev, curr) {
                    return (Math.abs(curr - newValue) < Math.abs(prev - newValue) ? curr : prev);
                });
                // and set the slider to the same
                nextTick(() => this.sliderModel = closest);
            } else {
                // illegal value: set slider to min value
                nextTick(() => this.sliderModel = Math.min(...this.dValues));
            }
        }
    }
};
</script>
