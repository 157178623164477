<template>
    <div class="text-para-s text-white mb-6">
        <div ref="text" class="mb-2" :class="{ 'line-clamp-3 break-words': descriptionOpen === false }">
            {{ description || "" }}
        </div>
        <div v-if="isTextClamped || descriptionOpen"
             class="flex flex-row items-center cursor-pointer"
             @click="descriptionOpen = !descriptionOpen"
        >
            <span v-if="!descriptionOpen">{{ showMoreLabel }}</span>
            <span v-else>{{ showLessLabel }}</span>
            <Icon v-if="!descriptionOpen" class="ml-2 w-3 h-2 stroke-current" name="chevron-down" />
            <Icon v-else class="ml-2 w-3 h-2 stroke-current transform rotate-180" name="chevron-down" />
        </div>
    </div>
</template>

<script lang="ts">

import Icon from "../base/Icon.vue";
import {nextTick} from "vue";

export default {
    components: {Icon},
    props: {
        description: {type: String, default: null},
        showMoreLabel: {type: String, default: ''},
        showLessLabel: {type: String, default: ''}
    },
    data() {
        return {
            descriptionOpen: false,
            isTextClamped: false
        };
    },
    watch: {
        async description() {
            // recalculate clamping whenever content changes
            this.checkClamping();
        }
    },
    mounted(): void {
        this.handleClamping();
        this.checkClamping();
    },
    methods: {
        handleClamping(): void {
            this.checkClamping();
            window.addEventListener('resize', () => this.checkClamping());
        },
        checkClamping(): void {
            nextTick(() => {
                const element: Element = this.$refs.text as Element;
                this.isTextClamped = element ? element.scrollHeight > element.clientHeight : false;
            });
        }
    }
};
</script>
