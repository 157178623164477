import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mx-auto max-w-container relative" }
const _hoisted_2 = {
  key: 0,
  class: "fixed top-0 left-0 w-screen h-screen backdrop-filter backdrop-blur z-10"
}
const _hoisted_3 = { class: "flex flex-row-reverse md:float-right" }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = {
  key: 0,
  class: "bg-black-50 text-para-base font-semibold pt-4"
}
const _hoisted_6 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_PositionSticky = _resolveComponent("PositionSticky")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    ($data.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_PositionSticky, { "offset-top": $options.offset }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["absolute top-0 right-0 z-20", {'w-full': $data.open}])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              "aria-label": $props.ariaLabel,
              class: "focus-primary md:w-20 md:h-20 w-14 h-14 bg-black-50 flex justify-center items-center md:float-right focus:outline-none",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.toggle && $options.toggle(...args)))
            }, [
              ($data.open)
                ? (_openBlock(), _createBlock(_component_Icon, {
                    key: 0,
                    name: "close",
                    class: "w-4 h-4 stroke-current"
                  }))
                : (_openBlock(), _createBlock(_component_Icon, {
                    key: 1,
                    name: "icon_link",
                    class: "w-4 h-4 stroke-current"
                  }))
            ], 8, _hoisted_4),
            ($props.title)
              ? (_openBlock(), _createElementBlock("h2", {
                  key: 0,
                  class: _normalizeClass([$data.open && $options.isMobile ? 'bg-black-50 text-para-base font-semibold p-4 flex-grow' : 'hidden'])
                }, _toDisplayString($props.title), 3))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["float-right w-full md:w-80 h-full text-para-s bg-black-50 md:pt-10 xl:pt-4 md:pb-8 md:px-8 p-4 overflow-x-hidden", [$data.open ? 'flex flex-col' : 'hidden']]),
            style: _normalizeStyle({ maxHeight: $options.computedHeight }),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.click && $options.click(...args)))
          }, [
            ($data.open && !$options.isMobile && $props.title)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString($props.title), 1))
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_6, [
              _renderSlot(_ctx.$slots, "links")
            ])
          ], 6)
        ], 2)
      ]),
      _: 3
    }, 8, ["offset-top"])
  ]))
}