<template>
    <div ref="observable"
         class="relative w-full h-full bg-white overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out"
    >
        <div class="relative" :class="[!isVertical ? 'h-60 md:h-auto' : '']">
            <img v-if="show"
                 alt=""
                 class="object-cover w-full transform group-hover:scale-110 transition-all duration-300 ease-out"
                 :class="[!isVertical ? 'h-full md:h-auto' : 'h-auto']"
                 :src="data.asset || data.url"
                 loading="lazy"
                 @load="imageLoaded"
            >
            <div class="absolute left-0 top-0 w-full h-full group-hover:bg-none bg-gradient-to-b opacity-40 from-black-900 pointer-events-none" />
            <PlayButton :desktop-size="16"
                        :tablet-size="10"
                        :aria-label="$globalLabels.play"
                        @click="go"
            />
        </div>
        <div class="absolute left-4 top-4 flex items-center text-para-xs text-white group-hover:text-primary-500">
            <div class="flex justify-center items-center w-8 h-8 text-white bg-black-900 rounded-full group-hover:bg-primary-500 transition-all duration-300 ease-out mr-2">
                <svg class="w-4 h-4 fill-current" viewBox="0 0 24 17" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.484 2.645a2.965 2.965 0 00-2.13-2.129C19.485 0 12 0 12 0S4.516 0 2.645.516a2.965 2.965 0 00-2.129 2.13C0 4.515 0 8.386 0 8.386s0 3.871.516 5.742a2.965 2.965 0 002.13 2.13c1.87.515 9.354.515 9.354.515s7.484 0 9.355-.516a2.965 2.965 0 002.129-2.129C24 12.259 24 8.387 24 8.387s-.064-3.935-.516-5.742zM9.548 12V4.774l6.194 3.613L9.548 12z" />
                </svg>
            </div>
            <h6 v-if="data.accountName"
                :class="{'invisible': !showChannel}"
                class="flex-grow font-semibold transition-all duration-300 ease-out"
            >
                {{ data.accountName }}
            </h6>
        </div>
        <div v-if="!isVertical" class="md:hidden px-6 py-4">
            <p class="text-para-s tracking-tight line-clamp-6" v-html="data.caption" />
        </div>
        <a :href="data.link"
           class="absolute left-0 right-0 top-0 bottom-0"
           :aria-label="data.accountName"
           target="_blank"
        />
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import debounce from 'lodash/debounce';
import Utils from '../../../utils/Utils';
import PlayButton from "../../video/PlayButton.vue";

export default {
    components: {PlayButton},
    inject: ['isVertical', 'showChannel', '$globalLabels'],
    props: {
        data: {type: Object as PropType<Record<string, string>>}
    },
    data() {
        return {
            redraw: debounce(this.$redrawVueMasonry, 300),
            show: false
        };
    },
    mounted(): void {
        Utils.observeIntersection(this.$refs.observable, () => { this.show = true; });
    },
    methods: {
        imageLoaded(): void {
            this.redraw();
        },
        go(): void {
            window.open(this.data.link, '_blank').focus();
        }
    }
};
</script>
