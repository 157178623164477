<template>
    <div ref="observable"
         class="relative w-full h-full overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out"
    >
        <div class="w-full h-full overflow-hidden bg-no-repeat bg-center bg-cover pb-1/1 transform group-hover:scale-110 transition-all duration-300 ease-out" :style="styles">
            <a :href="data.urlExtendMedia"
               class="absolute left-0 right-0 top-0 bottom-0"
               target="_blank"
               :aria-label="data.accountUsername"
            />
            <div class="absolute left-0 top-0 w-full h-full group-hover:bg-none bg-gradient-to-b opacity-40 from-black-900 pointer-events-none" />
        </div>
        <div class="absolute left-4 top-4 flex items-center text-para-xs text-white group-hover:text-primary-500">
            <div class="flex justify-center items-center w-8 h-8 text-white bg-black-900 rounded-full group-hover:bg-primary-500 transition-all duration-300 ease-out mr-2">
                <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.771a6.138 6.138 0 100 12.276A6.138 6.138 0 0012 5.77zm0 10.076a3.91 3.91 0 01-3.939-3.939A3.91 3.91 0 0112 7.97a3.91 3.91 0 013.939 3.94c.092 2.198-1.74 3.938-3.939 3.938z" />
                    <path d="M19.878 5.588a1.466 1.466 0 11-2.931 0 1.466 1.466 0 012.93 0z" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C8.794 0 8.336 0 7.053.092c-1.282.091-2.106.274-2.93.55-.825.274-1.467.732-2.108 1.373S.916 3.298.641 4.122c-.275.733-.458 1.649-.55 2.931C0 8.336 0 8.703 0 12c0 3.206 0 3.664.092 4.947.091 1.282.274 2.106.55 2.93.274.825.732 1.466 1.373 2.108.641.641 1.283 1.099 2.107 1.374.733.275 1.649.458 2.931.55C8.336 24 8.703 24 12 24c3.298 0 3.664 0 4.947-.092 1.282-.091 2.106-.274 2.93-.55.825-.274 1.466-.732 2.108-1.373.641-.642 1.099-1.283 1.374-2.107.275-.733.458-1.649.55-2.931C24 15.664 24 15.297 24 12c0-3.298 0-3.664-.092-4.947-.091-1.282-.274-2.106-.55-2.93-.274-.825-.732-1.467-1.373-2.108-.642-.641-1.283-1.099-2.107-1.374-.733-.275-1.649-.458-2.931-.55C15.664 0 15.297 0 12 0zm0 2.107c3.206 0 3.572 0 4.855.091a11.197 11.197 0 012.198.367c.55.183.916.458 1.374.916.459.458.642.824.916 1.374.184.458.367 1.008.367 2.198.091 1.283.091 1.65.091 4.855 0 3.207 0 3.573-.091 4.855a11.19 11.19 0 01-.341 2.1l-.002.007-.024.092c-.183.55-.457.916-.916 1.374-.457.458-.824.641-1.374.916-.458.183-1.007.366-2.198.366-1.283.092-1.649.092-4.855.092-3.206 0-3.573 0-4.855-.092a11.19 11.19 0 01-2.198-.366c-.55-.183-.916-.458-1.374-.916-.458-.458-.642-.824-.917-1.374-.183-.458-.366-1.008-.366-2.199-.092-1.282-.092-1.648-.092-4.853v-.003c0-3.205 0-3.571.092-4.854.084-1.09.244-1.719.341-2.099l.021-.081.004-.018c.184-.55.459-.916.917-1.374.458-.458.824-.641 1.374-.916.458-.183 1.007-.367 2.198-.367.501 0 .877-.014 1.334-.03.712-.027 1.624-.061 3.521-.061z" />
                </svg>
            </div>
            <h6 v-if="data.accountName"
                :class="{'invisible': !showChannel}"
                class="flex-grow font-semibold transition-all duration-300 ease-out"
            >
                {{ data.accountName }}
            </h6>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Utils from '../../../utils/Utils';

export default {
    inject: ['showChannel'],
    props: {
        data: {
            type: Object as PropType<Record<string, string>>
        }
    },
    data() {
        return {
            show: false
        };
    },
    computed: {
        imgUrl(): string {
            return this.data.asset || (this.data.mediaType === 'VIDEO' ? this.data.thumbnailUrl : this.data.url) || '';
        },
        styles(): Record<string, string> {
            return {
                'background-image': this.show ? `url(${this.imgUrl})` : ''
            };
        }
    },
    mounted(): void {
        Utils.observeIntersection(this.$refs.observable, () => { this.show = true; });
    }
};
</script>
