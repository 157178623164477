import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-start items-start py-10 px-6 xl:px-10" }
const _hoisted_2 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["stroke"]
const _hoisted_5 = { class: "ml-3 text-para-base font-semibold" }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-grow justify-center items-center mb-4 -mx-6 xl:-mx-10"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "w-full flex justify-start items-center"
}
const _hoisted_9 = ["href", "target"]
const _hoisted_10 = { class: "text-para-s font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocioEconomicMapDetailSlider = _resolveComponent("SocioEconomicMapDetailSlider")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      ($props.location !== null)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["fixed top-0 left-0 overflow-x-auto w-screen h-screen bg-black-50 z-9000 md:absolute md:w-5/12 md:h-120 md:z-30 xl:h-180 xl:w-1/4 md:shadow-2xl", {
                'xl:left-0': $props.hideCategories,
                'xl:left-1/4': !$props.hideCategories,
            }])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: _normalizeClass(["w-full flex justify-start items-center mb-4", { 'xl:mb-6': !$props.location.image }])
              }, [
                (_openBlock(), _createElementBlock("svg", _hoisted_2, [
                  _createElementVNode("circle", {
                    cx: "12",
                    cy: "12",
                    r: "8",
                    fill: $props.location.category.color
                  }, null, 8, _hoisted_3),
                  _createElementVNode("circle", {
                    cx: "12",
                    cy: "12",
                    r: "11.5",
                    stroke: $props.location.category.color
                  }, null, 8, _hoisted_4)
                ])),
                _createElementVNode("span", _hoisted_5, _toDisplayString($props.location.label), 1)
              ], 2),
              ($props.location.images && $props.location.images.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_SocioEconomicMapDetailSlider, {
                      "slide-classes": "w-full",
                      "container-classes": "px-6 xl:px-10",
                      "pager-color": "primary-400",
                      slides: $props.location.images
                    }, null, 8, ["slides"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["w-full flex justify-start items-center text-para-s", { 'mb-4': $props.location.url }]),
                innerHTML: $props.location.description
              }, null, 10, _hoisted_7),
              ($props.location.url)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("a", {
                      href: $props.location.url,
                      target: $props.location.target,
                      class: "flex items-center justify-start group cursor-pointer"
                    }, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString($props.location.linkLabel || $props.labelVisitWebsite), 1),
                      _createVNode(_component_ArrowButton, {
                        direction: "right",
                        class: "ml-2 h-10 w-10"
                      })
                    ], 8, _hoisted_9)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("button", {
              class: "absolute text-white top-0 right-0 w-10 h-10 flex justify-center items-center bg-primary-500 transition-colors hover:bg-primary-700 focus:outline-none",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.closeLocation()))
            }, [
              _createVNode(_component_Icon, {
                name: "close",
                class: "w-4 stroke-current"
              })
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}