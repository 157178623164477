<template>
    <google-map
        ref="mapRef"
        :api-key="apiKeyGM"
        :styles="mapStyles"
        :center="center"
        :zoom="zoom"
        :min-zoom="minZoom"
        :max-zoom="maxZoom"
        :disable-default-ui="true"
        :street-view-control="false"
        :fullscreen-control="false"
        :map-type-control="false"
        class="w-full h-full"
        style="width: 100%; height: 100%"
        @idle="onMapIdle"
    >
        <SocioEconomicMapStaticFeature
            v-for="(feature, i) in countryConfig.features"
            :key="i"
            :feature="feature"
            :visible="showStaticFeatures"
        />

        <marker-cluster
            v-for="(operation, i) in operations"
            :key="i"
            :options="{
                algorithm: clusterAlgorithm(),
                renderer: clusterRenderer,
                onClusterClick: onClusterClick,
            }"
        >
            <custom-marker
                v-for="(marker, index) in getLocationsForOperation(operation)"
                :key="index"
                :options="{
                    position: marker.position,
                    offsetY: -40,
                }"
                :clickable="true"
                :label="{
                    color: 'black',
                    fontFamily:
                        'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
                    fontSize: '12px',
                    fontWeight: '100',
                    text: ' ',
                }"
                @click="onMarkerClicked(marker, operation)"
            >
                <div
                    class="relative bg-white px-4 py-2 flex items-center justify-center border rounded-full text-para-xs"
                    :style="getMarkerStyle(marker, operation)"
                >
                    {{ marker.title || marker.name || "" }}
                    <div
                        class="absolute border-1 w-0.5 top-full h-10"
                        :style="{
                            backgroundColor: getColor(operation.color),
                            borderColor: getColor(operation.color),
                            left: 'calc(50% - 2px)',
                        }"
                    />
                </div>
            </custom-marker>
        </marker-cluster>
        <SocioEconomicMapZoomButtons @zoom-in="zoomIn" @zoom-out="zoomOut" />
    </google-map>
</template>

<script lang="ts">
import styles from "./mapStyles";
import FEATURES from "./mapFeatures";
import { PropType } from "vue";
import breakpoints from "../../plugins/breakpoints";
import SocioEconomicMapUtils, {
    Location,
    Operation,
    SubOperation,
    SubRegion,
} from "./SocioEconomicMapUtils";
import Utils from "../../utils/Utils";
import SocioEconomicMapZoomButtons from "./SocioEconomicMapZoomButtons.vue";
import SocioEconomicMapStaticFeature from "./SocioEconomicMapStaticFeature.vue";
import { GridAlgorithm } from "@googlemaps/markerclusterer";
import { Algorithm } from "@googlemaps/markerclusterer/dist/algorithms/core";

enum GridSizes {
    LARGE = 60,
    NORMAL = 60,
    NONE = 0,
}

enum ClusterSizes {
    LARGE = 1,
    NORMAL = 2,
}

const cluster = {
    textColor: "black",
    height: 38,
    fontFamily: "Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: "12px",
    fontWeight: "800",
    url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDMyIDQwIiBmaWxsPSJub25lIj4KICAgIDxjaXJjbGUgY3g9IjE2IiBjeT0iMTYiIHI9IjE1IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjMDA5MjhFIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxsaW5lIHgxPSIxNiIgeTE9IjMyIiB4Mj0iMTYiIHkyPSIzOSIgc3Ryb2tlPSIjMDA5MjhFIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4=",
    width: 32,
};

export default {
    components: { SocioEconomicMapStaticFeature, SocioEconomicMapZoomButtons },
    props: {
        markers: { type: Array },
        singleMarker: { type: Object },
        regionOption: { type: Object },
        isOfficeMap: { type: Boolean, default: false },
        isWorldMap: { type: Boolean, default: false },
        hideCategories: { type: Boolean, default: false },
        selectedMarker: { type: Object, default: () => ({}) },
        country: { type: String, required: true },
        bounded: { type: Boolean, default: true },
        currentRegion: { type: Object as PropType<SubRegion> },
        currentOperation: { type: Object as PropType<SubOperation> },
        operations: {
            type: Array as PropType<Array<Operation>>,
            required: true,
        },
        allOperations: {
            type: Array as PropType<Array<Operation>>,
            required: true,
        },
    },
    emits: ["openFiltering", "markerClicked", "mapInitialized"],
    data() {
        return {
            kmlName: "australia-states.kml",
            defaultZoom: 5,
            zoom: 2, // use integer!
            minZoom: 2, // use integer!
            maxZoom: 12, // use integer!
            specialZoomLevels: {
                mobile: 2,
                tablet: 1.4,
                desktop: 2,
            },
            responsiveGridThresholds: {
                mobile: 4,
                tablet: 4,
                desktop: 5,
            },
            boundsPadding: {
                top: 30,
                right: 1,
                left: 1,
                bottom: 1,
            },
            infoContent: "",
            currentMidx: null,
            center: { lat: 0, lng: 0 },
            bounds: {},
            mapInitialized: false,
            isZoomed: false,
            apiKeyGM:
                document.body.dataset.gmapsApiKey ||
                "AIzaSyCSrasp7xoSYNhdBANpj51-QEtNjlH0MyY",
            mapStyles: styles,
            reactiveMapRef: undefined,
            viewportPadding: 100,
            clusterRenderer: {
                render: (props) => {
                    const { count, position } = props;
                    const clusterMarker = new google.maps.Marker({
                        position,
                        label: {
                            text: String(count),
                            color: cluster.textColor,
                            fontSize: cluster.fontSize,
                            fontFamily: cluster.fontFamily,
                            fontWeight: "800",
                        },
                        icon: {
                            url: cluster.url,
                            scaledSize: new google.maps.Size(
                                cluster.width,
                                cluster.height
                            ),
                            labelOrigin: new google.maps.Point(16, 16),
                        },
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    });

                    return clusterMarker;
                },
            },
        };
    },
    computed: {
        countryConfig() {
            return FEATURES[this.country];
        },
        showStaticFeatures() {
            return this.zoom > 2 && this.zoom < 9;
        },
        zoomLevelOverride(): number {
            let override: number = null;
            if (
                this.currentOperation &&
                this.currentOperation.zoomLevelOverride
            ) {
                override = parseInt(this.currentOperation.zoomLevelOverride);
            }
            if (this.currentRegion && this.currentRegion.zoomLevelOverride) {
                override = parseInt(this.currentRegion.zoomLevelOverride);
            }
            return override;
        },
        formattedLocations(): any {
            let result = [];
            const allOperations = this.operations.map(
                (operation: Operation) => {
                    return { ...operation };
                }
            );
            allOperations.forEach((operation: Operation) => {
                const locations: Location[] =
                    this.getLocationsForOperation(operation);
                locations.forEach((location) => {
                    location["operationColor"] = operation.color || "";
                });
                result = result.concat(locations);
            });
            return result;
        },
        gridSize(): number {
            if (this.zoom === this.maxZoom) return GridSizes.NONE;
            if (
                this.zoom > this.responsiveGridThreshold ||
                this.currentRegion ||
                this.currentOperation ||
                this.isZoomed
            ) {
                return GridSizes.NORMAL;
            } else {
                return GridSizes.LARGE;
            }
        },
        clusterSize(): number {
            if (
                this.zoom > this.responsiveGridThreshold ||
                this.currentRegion ||
                this.currentOperation ||
                this.isZoomed
            ) {
                return ClusterSizes.NORMAL;
            } else {
                return ClusterSizes.LARGE;
            }
        },
        responsiveGridThreshold(): number {
            return this.responsiveGridThresholds[this.breakpoint];
        },
        breakpoint(): string {
            if (breakpoints.desktop) {
                return "desktop";
            }
            if (breakpoints.tablet) {
                return "tablet";
            } else {
                return "mobile";
            }
        },
    },
    watch: {
        async markers() {
            this.handleLocations();
        },
        async operations() {
            this.handleLocations();
        },
        "reactiveMapRef.ready": {
            handler() {
                this.handleLocations();
            },
            deep: true, // deep watch, because property on referenced object
        },
    },
    created() {
        if (this.regionOption && !this.isWorldMap) {
            const { lat, lng } = this.regionOption;
            this.center.lat = lat;
            this.center.lng = lng;
        }
    },
    mounted() {
        this.reactiveMapRef = this.$refs.mapRef;
        this.handleLocations();
    },
    methods: {
        clusterAlgorithm(): Algorithm {
            return new GridAlgorithm({
                gridSize: this.gridSize,
                viewportPadding: this.viewportPadding,
            });
        },
        handleLocations(): void {
            if (this.$refs.mapRef.ready) {
                if (this.operations.length) {
                    const bounds = new google.maps.LatLngBounds();
                    let markers = [];

                    this.operations.forEach((operation: Operation) => {
                        markers = [
                            ...markers,
                            ...this.getLocationsForOperation(operation),
                        ];
                    });
                    markers.forEach((marker) => bounds.extend(marker.position));

                    if (markers && markers.length > 0) {
                        if (
                            this.mapInitialized &&
                            (this.currentRegion || this.currentOperation)
                        ) {
                            this.$refs.mapRef.map.fitBounds(bounds);
                            if (this.hasValidZoomLevelOverride()) {
                                this.$refs.mapRef.map.setZoom(
                                    this.zoomLevelOverride
                                );
                                this.onZoom();
                            } else {
                                // 1 coordinate unit is moved to north if not zoomed in too close
                                SocioEconomicMapUtils.fitBoundsPaddingCenter(
                                    this.$refs.mapRef.map,
                                    bounds,
                                    this.boundsPadding,
                                    { y: 1 }
                                );
                            }
                        } else {
                            // set zoom for maps not yet manipulated
                            this.$refs.mapRef.map.setZoom(this.defaultZoom);
                            this.setCenter(this.$refs.mapRef.map);
                            this.mapInitialized = true;
                            this.$emit("mapInitialized"); // shows map
                        }
                    } else {
                        this.mapInitialized = true;
                        this.$emit("mapInitialized"); // shows map
                    }
                }
            }
        },
        setCenter(map: any) {
            if (this.regionOption) {
                const { lat, lng } = this.regionOption;
                map.panTo({ lat, lng });
            }
        },
        hasValidZoomLevelOverride() {
            return (
                this.zoomLevelOverride >= this.minZoom &&
                this.zoomLevelOverride <= this.maxZoom
            );
        },
        onClusterClick(event, cluster): void {
            const bounds = new google.maps.LatLngBounds();

            cluster.markers
                .map((marker) => {
                    return {
                        lat: marker.getPosition().lat(),
                        lng: marker.getPosition().lng(),
                    };
                })
                .forEach((position) => bounds.extend(position));

            // 1 coordinate unit is moved to north if not zoomed in too close
            SocioEconomicMapUtils.fitBoundsPaddingCenter(
                this.$refs.mapRef.map,
                bounds,
                this.boundsPadding,
                { y: 1 }
            );
            /* eslint-enable */
        },
        onMapIdle(): void {
            this.setZoom();
        },
        getLocationsForOperation(operation: Operation): Array<Location> {
            const result: Array<Location> = new Array<Location>();
            const usedIds: Array<string> = new Array<string>();
            operation.items.forEach((item) => {
                item.locations.forEach((location) => {
                    if (
                        !usedIds.includes(location.uuid) &&
                        (!this.currentRegion ||
                            location.regions.includes(this.currentRegion.uuid))
                    ) {
                        result.push(location);
                        usedIds.push(location.uuid);
                    }
                });
            });
            return result;
        },
        onMarkerClicked(marker: Location, operation: Operation): void {
            this.$emit(
                "markerClicked",
                marker,
                this.getFirstOperationForLocation(marker)
            );
        },
        /**
         * retrieves the first operation found for a location (depending on the order in magnolia)
         * @param marker
         */
        getFirstOperationForLocation(
            marker: Location
        ): Operation | SubOperation {
            let result: Operation | SubOperation;
            let counter = 0;
            while (!result) {
                result = SocioEconomicMapUtils.findOperation(
                    marker.operations[counter],
                    this.allOperations
                );
                counter++;
            }
            return result;
        },
        /**
         * retrieves the sub-operation for a location (depending on the state of Vue)
         * @param marker
         * @param operation
         */
        getSubOperationForLocation(
            marker: Location,
            operation: Operation
        ): SubOperation {
            return operation.items.find((item) =>
                item.locations.some((location) => location.uuid === marker.uuid)
            );
        },
        getMarkerStyle(marker, operation: Operation): any {
            return {
                borderColor: Utils.cssColor(operation.color) || "#00928E",
                backgroundColor:
                    this.selectedMarker &&
                    this.selectedMarker.uuid === marker.uuid
                        ? Utils.cssColor(operation.color) || "#00928E"
                        : "white",
                color:
                    this.selectedMarker &&
                    this.selectedMarker.uuid === marker.uuid
                        ? "white"
                        : "black",
            };
        },
        getColor(color: string): string {
            return Utils.cssColor(color);
        },
        openFiltering() {
            this.$emit("openFiltering");
        },
        makeClusterShape(operation: Operation = null): string {
            return btoa(
                '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 0 32 40" fill="none">\\n\' +\n' +
                    '            \'    <circle cx="16" cy="16" r="15" fill="white" stroke="' +
                    (operation
                        ? `${this.formatOperationColor(operation.color)}`
                        : "#00928E") +
                    '" stroke-width="2"/>\\n\' +\n' +
                    '            \'    <line x1="16" y1="32" x2="16" y2="39" stroke="' +
                    (operation
                        ? `${this.formatOperationColor(operation.color)}`
                        : "#00928E") +
                    '" stroke-width="2" stroke-linecap="round"/>\\n\' +\n' +
                    "            '</svg>"
            );
        },
        zoomIn(): void {
            if (this.$refs.mapRef.ready) {
                this.$refs.mapRef.map.setZoom(
                    this.$refs.mapRef.map.getZoom() + 1
                );
                this.onZoom();
            }
        },
        zoomOut(): void {
            if (this.$refs.mapRef.ready) {
                this.$refs.mapRef.map.setZoom(
                    this.$refs.mapRef.map.getZoom() - 1
                );
                this.onZoom();
            }
        },
        formatOperationColor(color: string): string {
            if (!color) return color;
            return `${color[0] === "#" ? "" : "#"}${color}`;
        },
        onZoom(): void {
            this.setZoom();
        },
        setZoom(): void {
            if (this.$refs.mapRef && this.$refs.mapRef.ready) {
                this.zoom = this.$refs.mapRef.map.getZoom();
            }
        },
    },
};
</script>
