<template>
    <Transition name="fade">
        <div v-cloak
             v-show="show"
             class="fixed left-3 md:left-auto right-3 md:right-4 bottom-3 md:bottom-5 md:w-80 max-h-policy-m md:max-h-policy rounded bg-white shadow-lg px-6 py-8 overflow-y-auto z-40"
        >
            <div v-if="!advanced" class="absolute right-2 top-2 w-4 h-4 cursor-pointer" @click="close">
                <Icon name="close" class="w-full h-full stroke-current" />
            </div>

            <div class="font-semibold mb-2">
                {{ title }}
            </div>
            <p class="text-para-s mb-3">
                {{ text }}
            </p>

            <GcButton v-if="simple && !!link.url"
                      class="w-full"
                      :label="link.label"
                      :aria-label="link.ariaLabel"
                      :url="link.url"
            />

            <div v-if="subtle && !!link.url" class="prose">
                <a class="link text-para-s" :aria-label="link.ariaLabel" :href="link.url">{{ link.label }}</a>
            </div>

            <p v-if="opened" class="text-para-s mb-4">
                {{ advText }}
            </p>

            <div v-if="opened && advanced">
                <CheckboxAccordion v-for="option in options"
                                   :id="option.type"
                                   :key="option.type"
                                   v-model="model[option.type]"
                                   :title="option.label"
                                   :show-checkbox="option.checkbox"
                                   :check="grantModel[option.type]"
                                   small
                                   @change="grantModel[option.type] = $event"
                >
                    <p class="text-para-s">{{ option.text }}</p>
                </CheckboxAccordion>
            </div>

            <GcButton v-if="opened" class="w-full mb-3 mt-1" color="primary" :secondary="true" :label="labels.rejectAll" @click="rejectAll" />
            <GcButton v-if="opened" class="w-full" :label="labels.confirm" @click="updateConsent" />

            <template v-if="!opened && advanced">
                <GcButton class="w-full mb-3" color="primary" :secondary="true" :label="labels.settings" @click="opened = true" />
                <GcButton class="w-full mb-3" :label="labels.reject" @click="rejectAll" />
                <GcButton class="w-full" :label="labels.accept" @click="acceptAll" />
            </template>
        </div>
    </Transition>
</template>

<script lang="ts">
import {PropType} from 'vue';
import Utils from '../../utils/Utils';
import CheckboxAccordion from "./CheckboxAccordion.vue";
import Icon from "../base/Icon.vue";
import GcButton from "../base/GcButton.vue";
import eventBus from '../../event-bus';

export interface CookieLabels {
    settings: string;
    reject: string;
    accept: string;
    rejectAll: string;
    confirm: string;
}

export interface CookieOption {
    type: string;
    label: string;
    text: string;
    checkbox: boolean;
}

export interface CookieLink {
    url: string;
    label: string;
    ariaLabel: string;
}

export default {
    components: {GcButton, Icon, CheckboxAccordion},
    props: {
        type: {type: String},
        title: {type: String},
        text: {type: String},
        advText: {type: String},
        labels: {type: Object as PropType<CookieLabels>},
        link: {type: Object as PropType<CookieLink>},
        options: {type: Array as PropType<Array<CookieOption>>, default: () => []}
    },
    data() {
        return {
            key : 'glencore-policy',
            consentKey : 'glencore-policy-consent',

            show : true,
            opened : false,

            model: {},
            grantModel: {}
        };
    },
    computed: {
        consent(): Record<string, string> {
            /* eslint-disable */
            return {
                security_storage: 'granted',
                analytics_storage: this.grantModel.analytics ? 'granted' : 'denied',
                functional_storage: this.grantModel.functional ? 'granted' : 'denied',
                personalization_storage: this.grantModel.functional ? 'granted' : 'denied',
                ad_storage: this.grantModel.targeting ? 'granted' : 'denied'
            }
            /* eslint-enable */
        },
        advanced(): boolean {
            return this.type === 'advanced';
        },
        simple(): boolean {
            return this.type === 'simple';
        },
        subtle(): boolean {
            return this.type === 'subtle';
        }
    },
    created(): void {
        if (this.advanced) {
            // init data layer if not already present
            window.dataLayer = window.dataLayer || [];

            // always init consent with default values
            this.options.forEach(option => {
                if (option.checkbox) {
                    this.grantModel[option.type] = false;
                }
            });
            this.initConsent();

            // and if there's a consent state saved, update it after initialization
            const savedConsent = Utils.getLocalStorage(this.consentKey);
            if (savedConsent) {
                // set model from storage
                this.grantModel = JSON.parse(savedConsent);
                this.updateConsent();
                this.show = false;
            }
        } else {
            // check local storage
            const savedPolicyState = Utils.getLocalStorage(this.key) || '';
            if (savedPolicyState === 'accepted') {
                this.show = false;
            }
        }
    },
    mounted(): void {
        document.addEventListener('click', this.onOutsideClick);
    },
    beforeUnmount(): void {
        document.removeEventListener('click', this.onOutsideClick);
    },
    methods: {
        onOutsideClick(evt: Event) {
            // for now, do nothing in advanced mode or when invisible
            if (this.advanced || !this.show) return;
            let targetElement = evt.target as HTMLElement;
            do {
                if (targetElement === this.$el) {
                    return;
                }
                targetElement = targetElement.parentElement;
            } while (targetElement);
            this.close();
        },
        close(): void {
            if (this.advanced) {
                Utils.setLocalStorage(this.consentKey, JSON.stringify(this.grantModel));

                // send event to global event bus, so other components can respond to it
                eventBus.$emit('cookie-consent-updated');
            }
            Utils.setLocalStorage(this.key, 'accepted');
            this.show = false;
        },
        acceptAll(): void {
            Object.keys(this.grantModel).forEach(key => {
                this.grantModel[key] = true;
            });
            this.updateConsent();
        },
        rejectAll(): void {
            Object.keys(this.grantModel).forEach(key => {
                this.grantModel[key] = false;
            });
            this.updateConsent();
        },
        initConsent(): void {
            if (this.$gtm && this.$gtm.enabled()) {
                this.gtag('consent', 'default', this.consent);
            }
        },
        updateConsent(): void {
            if (this.$gtm && this.$gtm.enabled()) {
                this.gtag('consent', 'update', this.consent);
                window.dataLayer.push({
                    event: 'cookie_consent_update'
                });
            }
            this.close();
        },
        gtag(...args): void {
            // somehow it won't work when using the args parameter instead of arguments, so please don't change
            /* eslint-disable-next-line prefer-rest-params */
            window.dataLayer.push(arguments);
        }
    }
};
</script>
