<template>
    <div>
        <!-- new comment -->
        <div v-if="!disabled" class="flex flex-col items-end mb-8">
            <textarea
                v-model="textModel"
                class="w-full min-h-32 border border-black-100 text-black-600 text-para-s rounded-sm focus:outline-none py-3 px-4 mb-6 placeholder-improvedContrast"
                :placeholder="labels.placeholder"
            />
            <GcButton
                class="w-fit"
                type="button"
                :label="labels.submit"
                :disabled="!textModel || isLoading"
                @click="submitComment"
            />
        </div>
        <!-- no of comments -->
        <span class="text-para-s font-semibold"
            >{{ comments.length }} {{ labels.comments }}</span
        >
        <div class="border-b border-black-200 my-6" />
        <div>
            <!-- comments -->
            <template v-for="(comment, index) in comments" :key="index">
                <UserComment
                    ref="comment"
                    :index="index"
                    :comment="comment"
                    :labels="labels"
                    :full-name="fullName"
                    :email="email"
                    :is-admin="isAdmin"
                    :disabled="disabled"
                />
                <!-- child comments -->
                <div
                    v-if="(comment.comments || []).length > 0"
                    class="pl-4 md:pl-8"
                >
                    <div
                        v-if="
                            comment.comments.length > 1 &&
                            !showComment[comment.id]
                        "
                        class="prose mb-12 text-center md:text-left"
                    >
                        <a
                            class="link cursor-pointer"
                            @click="showPrevious(comment.id)"
                            >{{ labels.showPrevious }}</a
                        >
                    </div>
                    <template
                        v-for="(childComment, childIndex) in comment.comments"
                        :key="index + '-' + childIndex"
                    >
                        <UserComment
                            v-show="
                                childIndex === comment.comments.length - 1 ||
                                showComment[comment.id]
                            "
                            ref="comment"
                            :index="index + '-' + childIndex"
                            :parent-id="comment.id"
                            :is-last="
                                (comment.comments || []).length ===
                                childIndex + 1
                            "
                            :comment="childComment"
                            :labels="labels"
                            :full-name="fullName"
                            :email="email"
                            :is-admin="isAdmin"
                            :disabled="disabled"
                            nested
                        />
                    </template>
                </div>
                <div
                    v-if="(comment.comments || []).length > 0"
                    class="border-b border-black-200 mt-8 mb-6"
                />
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, nextTick, PropType, ref} from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import UserComment from "./UserComment.vue";
import GcButton from "../base/GcButton.vue";

export interface Comment {
    id?: number;
    componentId?: string;
    title?: string;
    text: string;
    fullName: string;
    email: string;
    date?: number;
    comments?: Comment[];
    role?: 'moderator' | 'staff';
}

export interface CommentLabels {
    placeholder: string;
    submit: string;
    comments: string;
    reply: string;
    readMore: string;
    readLess: string;
    showPrevious: string;
    moderator: string;
    staff: string;
}

export default {
    components: {GcButton, UserComment},
    provide() {
        return {
            loading: computed(() => this.isLoading),
            replyModel: computed(() => this.theReplyModel),
            replyMode: computed(() => this.theReplyMode),
            editMode: computed(() => this.theEditMode),
            autosize: this.autosize,
            toggleReply: this.toggleReply,
            toggleEditMode: this.toggleEditMode,
            formatText: this.formatText,
            createReadMore: this.createReadMore,
            getComments: this.getComments,
            replyToComment: this.replyToComment,
            updateComment: this.updateComment,
            deleteComment: this.deleteComment
        };
    },
    props: {
        componentId: {type: String, required: true},
        labels: {type: Object as PropType<CommentLabels>, required: true},
        fullName: {type: String, required: true},
        email: {type: String, required: true},
        isAdmin: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false}
    },
    data() {
        return {
            apiBaseUrl: Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH) + Enums.API.COMMENTS,
            comments: [],
            textModel: '',
            showComment: {},
            isLoading: false,
            theReplyModel: {},
            theReplyMode: {},
            theEditMode: {}
        };
    },
    computed: {
        commentsUrl(): string {
            return this.apiBaseUrl + '/all/' + this.componentId;
        },
        postNewCommentUrl(): string {
            return this.apiBaseUrl;
        },
        updateCommentUrl(): string {
            return this.apiBaseUrl + '/update';
        },
        deleteCommentUrl(): string {
            return this.apiBaseUrl + '/delete';
        }
    },
    async mounted(): Promise<void> {
        await this.getComments();
        window.addEventListener('resize', this.createReadMore);
    },
    methods: {
        submitComment(): void {
            if (this.textModel) {
                this.postNewComment();
            }
        },
        showPrevious(id: number): void {
            this.showComment[id] = true;
        },
        toggleReadMore(el: HTMLParagraphElement, button: HTMLElement): void {
            const rem = el.scrollHeight / 16;
            if (el.style.maxHeight) {
                el.style.maxHeight = '';
                button.innerText = this.labels.readMore;
                // wait for the transition to finish before reapplying the default clamping
                setTimeout(() => {
                    el.style.webkitLineClamp = '';
                }, 300);
            } else {
                el.style.maxHeight = `${rem}rem`;
                el.style.webkitLineClamp = '99';
                button.innerText = this.labels.readLess;
            }
        },
        reset(): void {
            // this will reset all edit forms and texts
            this.textModel = '';
            Object.keys(this.theReplyMode).forEach(key => delete this.theReplyMode[key]);
            Object.keys(this.theEditMode).forEach(key => delete this.theEditMode[key]);
            Object.keys(this.theReplyModel).forEach(key => delete this.theReplyModel[key]);
        },
        autosize(ev: Event): void {
            setTimeout(function() {
                const el: HTMLTextAreaElement = ev.target as HTMLTextAreaElement;
                el.style.cssText = 'height:auto; padding:12px 16px';
                // for box-sizing other than "content-box" use:
                // el.style.cssText = '-moz-box-sizing:content-box';
                el.style.cssText = 'height:' + (el.scrollHeight + 2) + 'px';
            }, 0);
        },
        toggleReply(comment: Comment, key: string | number): void {
            // this will toggle the visibility of the text box under the comment
            this.theReplyMode[key] = !this.theReplyMode[key];
            // this will prefill/empty the text box with the commentator's name we're replying to
            if (this.theReplyMode[key]) {
                this.theReplyModel[key] = '@' + comment.fullName + ' ';
            } else {
                this.theReplyModel[key] = '';
            }
        },
        toggleEditMode(key: string | number): void {
            this.theEditMode[key] = !this.theEditMode[key];
            nextTick(() => this.createReadMore());
        },
        formatText(text: string): string {
            return text
                // wrap links
                .replace(/(http(s)?:\/\/\S+)/g, '<a href="$1" class="link" target="_blank">$1</a>')
                // replace line breaks
                .replace(/(\r\n|\r|\n)/g, '<br>');
        },
        createReadMore(): void {
            if (!this.$refs.comment) return;
            // clientHeight: current rendered height
            // scrollHeight: real element height (including overflow)
            // so if clientHeight < scrollHeight -> there's a read more button needed
            this.$refs.comment.forEach(cEl => {
                const c = cEl.text;
                if (c) {
                    if (c.clientHeight < c.scrollHeight && !c.nextElementSibling) {
                        const wrapper = document.createElement('div');
                        const button = document.createElement('a');
                        wrapper.classList.add('prose', 'mb-6', '-mt-4');
                        wrapper.appendChild(button);
                        button.classList.add('link', 'cursor-pointer');
                        button.innerText = this.labels.readMore;
                        button.addEventListener('click', () => {
                            this.toggleReadMore(c, button);
                        });
                        c.parentElement.appendChild(wrapper);
                    } else if (c.clientHeight === c.scrollHeight && c.nextElementSibling) {
                        c.style.maxHeight = '';
                        c.style.webkitLineClamp = '';
                        c.nextElementSibling.remove();
                    }
                }
            });
        },
        async getComments(): Promise<void> {
            this.isLoading = true;
            const res = await axios.get(this.commentsUrl);
            if (res.status === 200) {
                this.reset();
                this.comments = res.data;
                await nextTick();
                this.createReadMore();
            }
            this.isLoading = false;
        },
        async postNewComment(): Promise<void> {
            const comment: Comment = {
                componentId: this.componentId,
                text: this.textModel,
                fullName: this.fullName,
                email: this.email
            };
            this.isLoading = true;
            const res = await axios.post(this.postNewCommentUrl, comment);
            if (res.status === 200) {
                this.textModel = '';
                await this.getComments();
            }
            this.isLoading = false;
        },
        async replyToComment(id: number, key: string | number): Promise<void> {
            const url = this.postNewCommentUrl + '/' + id;
            const comment: Comment = {
                text: this.theReplyModel[key],
                fullName: this.fullName,
                email: this.email
            };
            this.isLoading = true;
            const res = await axios.post(url, comment);
            if (res.status === 200) {
                this.theReplyModel[key] = '';
                this.theReplyMode[key] = false;
                // this will make sure that after replying there is no "show more comments" and all child comments are shown
                this.showComment[id] = true;
                await this.getComments();
            }
            this.isLoading = false;
        },
        async updateComment(comment: Comment, key: string | number): Promise<void> {
            const url = this.updateCommentUrl;
            this.isLoading = true;
            const res = await axios.post(url, comment);
            if (res.status === 200) {
                this.theEditMode[key] = false;
                await this.getComments();
            }
            this.isLoading = false;
        },
        async deleteComment(id: number): Promise<void> {
            const url = this.deleteCommentUrl + '/' + id;
            this.isLoading = true;
            const res = await axios.post(url);
            if (res.status === 200) {
                await this.getComments();
            }
            this.isLoading = false;
        }
    }
};
</script>
