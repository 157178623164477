import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { ref: "embed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("dialog", {
          ref: "dialogEL",
          class: "max-w-full max-h-full backdrop:bg-black-900 backdrop:bg-opacity-20 backdrop:backdrop-filter backdrop:backdrop-blur",
          onClose: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.close && $options.close(...args)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center z-50 text-left bg-black-900 transition-all duration-300 ease-out", {'pointer-events-all bg-opacity-90': $data.active, 'pointer-events-none bg-opacity-0': !$data.active}]),
            onMousemove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.handleCloseButton && $options.handleCloseButton(...args)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["relative w-7/8 max-w-video-modal h-0 bg-black-900 overflow-hidden transform transition-all duration-600 ease-out", {'translate-y-4': !$data.active, 'translate-y-0 shadow-m': $data.active}]),
              style: _normalizeStyle({ height: $data.containerHeight })
            }, [
              ($data.closeVisible)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "close-button absolute top-5 right-5 w-10 h-10 text-primary-500 cursor-pointer origin-center transition-all duration-200 ease-out overflow-visible transform hover:scale-125 z-10",
                    autofocus: "",
                    "aria-label": $options.$globalLabels.close,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.close && $options.close(...args)), ["stop","prevent"]))
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("svg", {
                      class: "w-full h-full fill-current",
                      viewBox: "0 0 43 44",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("path", { d: "M15.6256 27.1151L20.3674 22.3734L15.7504 17.7564L16.8734 16.6333L21.4904 21.2503L26.2322 16.5085L27.3053 17.5817L22.5635 22.3235L27.2055 26.9654L26.0824 28.0885L21.4405 23.4465L16.6987 28.1883L15.6256 27.1151Z" })
                    ], -1)
                  ]), 8, _hoisted_1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, null, 512)
            ], 6)
          ], 34)
        ], 544)
      ]),
      _: 1
    })
  ]))
}