import Utils from "./Utils";
import Enums from "./Enums";

/**
 * Form object, loaded by Vue component (see 'created' function) that calls the function in this class.
 */
declare const d365mktformcapture: any;

/**
 * Utils class for form submission to Microsoft Dynamics.
 */
export default class DynamicsUtils {
    /**
     * Submits the form to Microsoft Dynamics.
     *
     * @param form The source for element, containing the user-entered data
     * @param genericDynamicsFieldMappings Pre-loaded generic dynamics field mappings
     * @param dynamicsFieldMappings Form-defined Dynamics field mappings
     * @param dynamicsFormId ID of the related dynamics form
     * @param dynamicsApiUrl Dynamics API URL, which will be used for submit
     * @param callback Callback which is executed, regardless of successful form submissions
     */
    static submitDynamicsForm(
        form: HTMLElement,
        genericDynamicsFieldMappings: [],
        dynamicsFieldMappings: any,
        dynamicsFormId: string,
        dynamicsApiUrl: string,
        callback: () => void
    ) {
        if (d365mktformcapture) {
            // dynamics form capture
            form.querySelectorAll("select").forEach((select: any) => {
                if (select.forEach) {
                    select.forEach = null;
                }
            });

            // 1. merge generic mappings with form-defined mappings
            const mergedMappings = [
                ...genericDynamicsFieldMappings,
                ...dynamicsFieldMappings,
            ];

            // 2. convert to dynamics expected format
            const serializedForm = d365mktformcapture.serializeForm(
                form,
                mergedMappings
            );

            const payload = serializedForm.SerializedForm.build();

            const captureConfig = {
                FormId: dynamicsFormId,
                FormApiUrl: dynamicsApiUrl,
            };

            // 3. submit data
            d365mktformcapture
                .submitForm(captureConfig, payload)
                .catch((e) => console.error(e))
                .finally(() => {
                    // make sure we continue with normal form submit after the promise is either accepted or rejected
                    callback();
                });
        } else {
            // make sure we continue with normal form submit, even when dynamics not properly initialised
            callback();
        }
    }

    /**
     * Loads the generic mappings from the server.
     */
    public static async loadGenericFieldMappings(): Promise<[]> {
        return Utils.getAPI(
            `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.resources/gc/webresources/json/form-mappings.json`
        ).catch((error) => {
            console.error(error);
            return new Promise(() => []);
        });
    }
}
