<template>
    <div class="relative">
        <slot :toggle="toggle" />
        <div v-if="visible" class="absolute left-0 top-full bg-white border border-black-200 rounded w-full md:w-auto md:min-w-60 max-w-full py-2 mt-2 z-10">
            <div class="absolute left-6 -top-1.25 w-2 h-2 border-l border-t border-black-200 bg-white transform rotate-45" />
            <slot name="items" :toggle="toggle">
                <div v-for="(label, value) in items"
                     :key="value"
                     class="text-para-s cursor-pointer text-black-900 px-4 py-2 hover:bg-primary-500 hover:text-white focus:bg-primary-500 focus:text-white"
                     @click="select(value)"
                     @keyup.enter="select(value)"
                >
                    {{ label }}
                </div>
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';

export default {
    props: {
        items: {type: Array as PropType<Array<Record<string, string>>>, default: () => []}
    },
    emits: ['selected'],
    data() {
        return {
            selected: '',
            visible: false
        };
    },
    mounted(): void {
        document.addEventListener('click', this.onOutsideClick);
    },
    beforeUnmount(): void {
        document.removeEventListener('click', this.onOutsideClick);
    },
    methods: {
        select(value: string): void {
            this.visible = false;
            this.selected = value;
            this.$emit('selected', this.selected);
        },
        toggle(): void {
            this.visible = !this.visible;
        },
        onOutsideClick(evt: Event) {
            let targetElement = evt.target as HTMLElement;
            do {
                if (targetElement === this.$el) {
                    return;
                }
                targetElement = targetElement.parentElement;
            } while (targetElement);
            this.visible = false;
        }
    }
};
</script>
