import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["aria-pressed", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-wrap gap-4", {'flex-col': $props.col || $props.colMobile, 'md:flex-row': $props.colMobile}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (key, value) => {
        return (_openBlock(), _createElementBlock("button", {
          key: value,
          type: "button",
          class: _normalizeClass(["bg-black-100 text-para-xs md:text-para-s font-semibold rounded focus:outline-none px-8 py-6 transition-all duration-300 ease-out", [$props.btnClasses, {'bg-black-900 text-white': $options.isSelected(value), 'flex-1': $props.grow || $props.growMobile, 'md:flex-none': $props.growMobile}]]),
          "aria-pressed": $options.isSelected(value),
          onClick: ($event: any) => ($options.toggle(value))
        }, _toDisplayString(key), 11, _hoisted_2))
      }), 128))
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.currentViews, (view, i) => {
      return _renderSlot(_ctx.$slots, view, { key: i })
    }), 128))
  ]))
}