<template>
    <li class="relative" :class="{'pt-16/9': rendition === 'landscape', 'pt-3/4': rendition === 'portrait'}">
        <div class="absolute top-0 left-0 w-full h-full perspective-1000">
            <div class="relative w-full h-full preserve-3d transition-transform duration-500 ease-in-out cursor-pointer group/1"
                 :class="{'rotate-y-180': !flipped}"
                 @click="flipped = !flipped"
            >
                <!-- front -->
                <div class="absolute top-0 left-0 bottom-0 right-0 backface-hidden z-2 rotate-y-180">
                    <div class="relative w-full h-full flex justify-center items-center">
                        <div class="absolute top-0 left-0 bottom-0 right-0"
                             :class="{
                                 'bg-gradient-to-b from-transparent to-black-900': showGradient,
                                 'via-transparent opacity-80': rendition === 'portrait',
                                 'opacity-65': rendition === 'landscape',
                             }"
                        />
                        <ResponsiveImage class="w-full h-full" :urls="imageUrls" />
                        <div class="absolute left-0 bottom-0 flex gap-4 items-center p-4">
                            <div class="flex flex-shrink-0 justify-center items-center bg-white rounded-full border border-black-500 group-hover/1:border-primary-500"
                                 :class="{'w-8 h-8': rendition === 'landscape', 'w-12 h-12': rendition === 'portrait'}"
                            >
                                <Icon name="flip" class="text-black-500 group-hover/1:text-primary-500 fill-current"
                                      :class="{'w-4 h-4': rendition === 'landscape', 'w-6 h-6': rendition === 'portrait'}"
                                />
                            </div>
                            <span class="text-white line-clamp-2" :class="{'text-para-xs': rendition === 'landscape'}">{{ title }}</span>
                        </div>
                    </div>
                </div>
                <!-- back -->
                <div class="absolute top-0 left-0 bottom-0 right-0 backface-hidden z-3 bg-black-50 transform rotate-0">
                    <div class="relative w-full h-full flex justify-center items-center px-4 xl:px-6 pt-4 xl:pt-6 pb-16">
                        <div class="text-center text-para-s xl:text-para-base max-w-narrow-content"
                             :class="{'line-clamp-4 xl:line-clamp-5 max-h-26 xl:max-h-32': rendition === 'landscape', 'line-clamp-15 max-h-90': rendition === 'portrait'}"
                             v-html="description"
                        />
                        <div class="absolute left-4 bottom-4 right-4 flex justify-between items-center">
                            <div class="flex justify-center items-center bg-white rounded-full border border-black-500 group-hover/1:border-primary-500"
                                 :class="{'w-8 h-8': rendition === 'landscape', 'w-12 h-12': rendition === 'portrait'}"
                            >
                                <Icon name="flip" class="text-black-500 group-hover/1:text-primary-500 fill-current"
                                      :class="{'w-4 h-4': rendition === 'landscape', 'w-6 h-6': rendition === 'portrait'}"
                                />
                            </div>
                            <slot name="link" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>
<script lang="ts">

import {PropType} from "vue";
import ResponsiveImage from "../base/ResponsiveImage.vue";
import Icon from "../base/Icon.vue";

export default {
    components: {Icon, ResponsiveImage},
    props: {
        rendition: {type: String, default: 'portrait', validator(value: string) {
            // The value must match one of these strings
            return ['portrait', 'landscape'].includes(value);
        }},
        imageUrls: {type: Object as PropType<Record<string, string>>, required: true},
        title: {type: String},
        description: {type: String},
        showGradient: {type: Boolean, default: true}
    },
    data() {
        return {
            flipped: false
        };
    }
};
</script>
