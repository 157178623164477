<template>
    <slot />
</template>

<script lang="ts">
import {screens} from '../../plugins/breakpoints';

export default {
    props: {
        hasTitles: {type: Boolean, default: true}
    },
    mounted(): void {
        window.addEventListener('resize', this.resize);
        this.resize();
    },
    beforeUnmount(): void {
        window.removeEventListener('resize', this.resize);
    },
    methods: {
        resize(): void {
            if (this.hasTitles) {
                const titles = Array.prototype.slice.call(this.$el.querySelectorAll('h4')) as HTMLHeadingElement[];
                titles.forEach(title => {
                    title.style.height = '';
                });
                if (window.innerWidth >= screens.md) {
                    const height = titles.reduce((acc, curr) => Math.max(acc, curr.clientHeight), 0);
                    titles.forEach(title => {
                        title.style.height = `${height}px`;
                    });
                }
            }
        }
    }
};
</script>
