<template>
    <div>
        <HotspotTemplate :json-map="jsonMap" :image="image" />
        <Transition v-for="modal in modals" :key="modal.id" name="fade">
            <InteractiveImageModal v-if="opened === modal.id" :modal="modal" @close="opened = ''" />
        </Transition>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import HotspotMap from '@lemonize/lz-hotspots-template/src/models/HotspotMap';
import InteractiveImageModal from "./InteractiveImageModal.vue";

export interface InteractiveModal {
    id: string;
    text: string;
    image: string;
    link: Link;
}

export interface Link {
    url: string;
    target: string;
    label: string;
}

export default {
    components: {InteractiveImageModal},
    props: {
        image: {type: String},
        jsonMap: {type: Object as PropType<HotspotMap>},
        modals: {type: Array as PropType<Array<InteractiveModal>>, default: () => []}
    },
    data() {
        return {
            opened: ''
        };
    },
    created(): void {
        // listen to custom event fired by hotspot-template
        window.addEventListener('__hsCustomEvent', (event: any) => {
            const id = event.detail.payload;
            if (id) {
                this.openModal(id);
            }
        });
    },
    methods: {
        openModal(id: string): void {
            // check if the id corresponds to one of the provided hotspots
            if (this.jsonMap.hotspots.findIndex(h => h.actions.click.payload === id) > -1) {
                this.opened = id;
            }
        }
    }
};
</script>
