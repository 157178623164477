import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-between mt-2 mb-6 md:mt-8 md:mb-20 print:hidden"
}
const _hoisted_2 = { class: "text-black-600 text-para-xs ml-4" }
const _hoisted_3 = { class: "text-para-s font-semibold" }
const _hoisted_4 = { class: "text-heading-2 md:text-heading-1" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "font-semibold text-para-lg" }
const _hoisted_7 = { class: "flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2" }
const _hoisted_8 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_9 = { class: "bullet" }
const _hoisted_10 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_11 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_12 = { class: "bullet" }
const _hoisted_13 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_14 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_15 = { class: "bullet" }
const _hoisted_16 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_17 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_18 = { class: "bullet" }
const _hoisted_19 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_20 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_21 = { class: "bullet" }
const _hoisted_22 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_23 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_24 = { class: "bullet" }
const _hoisted_25 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_26 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_27 = { class: "bullet" }
const _hoisted_28 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_29 = {
  key: 1,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_30 = { class: "bullet" }
const _hoisted_31 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_32 = { class: "font-semibold text-para-lg mt-16" }
const _hoisted_33 = { class: "flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2" }
const _hoisted_34 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_35 = { class: "bullet" }
const _hoisted_36 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_37 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_38 = { class: "bullet" }
const _hoisted_39 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_40 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_41 = { class: "bullet" }
const _hoisted_42 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_43 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_44 = { class: "bullet" }
const _hoisted_45 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_46 = { class: "font-semibold text-para-lg mt-16" }
const _hoisted_47 = { class: "flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2" }
const _hoisted_48 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_49 = { class: "bullet" }
const _hoisted_50 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_51 = {
  key: 1,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_52 = { class: "bullet" }
const _hoisted_53 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_54 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_55 = { class: "bullet" }
const _hoisted_56 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_57 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_58 = { class: "bullet" }
const _hoisted_59 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_60 = { class: "font-semibold text-para-lg mt-16" }
const _hoisted_61 = { class: "flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2" }
const _hoisted_62 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_63 = { class: "bullet" }
const _hoisted_64 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_65 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_66 = { class: "bullet" }
const _hoisted_67 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_68 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_69 = { class: "bullet" }
const _hoisted_70 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_71 = { class: "font-semibold text-para-lg mt-16" }
const _hoisted_72 = { class: "flex flex-col gap-4 text-para-s md:text-para-lg mt-4 md:mt-2" }
const _hoisted_73 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_74 = { class: "bullet" }
const _hoisted_75 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_76 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_77 = { class: "bullet" }
const _hoisted_78 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_79 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_80 = { class: "bullet" }
const _hoisted_81 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_82 = {
  key: 1,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_83 = { class: "bullet" }
const _hoisted_84 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_85 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_86 = { class: "bullet" }
const _hoisted_87 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_88 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_89 = { class: "bullet" }
const _hoisted_90 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_91 = {
  key: 2,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_92 = { class: "bullet" }
const _hoisted_93 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_94 = {
  key: 3,
  class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14"
}
const _hoisted_95 = { class: "bullet" }
const _hoisted_96 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_97 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_98 = { class: "bullet" }
const _hoisted_99 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_100 = { class: "grid grid-cols-1 md:grid-cols-2 print:grid-cols-2 md:gap-x-14" }
const _hoisted_101 = { class: "bullet" }
const _hoisted_102 = { class: "font-semibold ml-5 md:ml-0" }
const _hoisted_103 = { class: "font-semibold text-para-lg mt-16" }
const _hoisted_104 = ["innerHTML"]
const _hoisted_105 = ["innerHTML"]
const _hoisted_106 = { class: "text-para-s md:text-para-lg mt-10" }
const _hoisted_107 = { class: "font-semibold mt-10" }
const _hoisted_108 = { class: "mt-2" }
const _hoisted_109 = { class: "flex flex-col gap-8 md:flex-row md:justify-between md:items-center mt-10" }
const _hoisted_110 = { class: "text-para-s font-semibold" }
const _hoisted_111 = {
  key: 1,
  class: "order-1 md:order-2 text-error text-para-xs"
}
const _hoisted_112 = { class: "md:hidden border-t border-black-200 mt-12" }
const _hoisted_113 = { class: "text-para-s font-semibold" }
const _hoisted_114 = { key: 4 }
const _hoisted_115 = { class: "text-para-s md:text-para-lg mt-16" }
const _hoisted_116 = { class: "font-semibold" }
const _hoisted_117 = { class: "mt-2" }
const _hoisted_118 = { class: "text-para-s font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_VueFormCheckbox = _resolveComponent("VueFormCheckbox")!
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col prose-summary", { 'mt-12 md:mt-32': $options.participant.status === 'REGISTERED_COMPLETE' }])
  }, [
    ($options.participant.status !== 'REGISTERED_COMPLETE')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "flex items-center cursor-pointer w-fit",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.previousStep && $options.previousStep(...args)))
          }, [
            _createVNode(_component_Icon, {
              name: "chevron-left",
              class: "w-3 h-3 text-black-300 stroke-current"
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString($props.labels.backTo) + " " + _toDisplayString($props.labels.travelDetails), 1)
          ]),
          _createElementVNode("div", {
            class: "hidden md:flex items-center group cursor-pointer",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.toOverview && $options.toOverview(...args)))
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString($props.labels.personalOverview), 1),
            _createVNode(_component_ArrowButton, {
              direction: "person",
              class: "w-10 h-10"
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h2", _hoisted_4, _toDisplayString($props.labels.summary), 1),
    _createElementVNode("p", {
      class: "text-para-s md:text-heading-3 mt-4 md:mt-2 mb-10 md:mb-14",
      innerHTML: $props.labels.summaryDescription
    }, null, 8, _hoisted_5),
    _createElementVNode("span", _hoisted_6, _toDisplayString($props.labels.personalData), 1),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString($props.labels.firstName) + ":", 1),
        _createElementVNode("span", _hoisted_10, _toDisplayString($options.valueOrFallback($options.participant.firstName)), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("span", _hoisted_12, _toDisplayString($props.labels.lastName) + ":", 1),
        _createElementVNode("span", _hoisted_13, _toDisplayString($options.valueOrFallback($options.participant.lastName)), 1)
      ]),
      ($options.participant.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, _toDisplayString($props.labels.namePrefix) + ":", 1),
            _createElementVNode("span", _hoisted_16, _toDisplayString($options.valueOrFallback($options.participant.title)), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("span", _hoisted_18, _toDisplayString($props.labels.gender) + ":", 1),
        _createElementVNode("span", _hoisted_19, _toDisplayString($options.valueOrFallback($options.getGender($options.participant.gender))), 1)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("span", _hoisted_21, _toDisplayString($props.labels.company) + ":", 1),
        _createElementVNode("span", _hoisted_22, _toDisplayString($options.valueOrFallback($options.participant.company)), 1)
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("span", _hoisted_24, _toDisplayString($props.labels.mobilePhone) + ":", 1),
        _createElementVNode("span", _hoisted_25, _toDisplayString($options.valueOrFallback($options.participant.phone)), 1)
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("span", _hoisted_27, _toDisplayString($props.labels.mailAddress) + ":", 1),
        _createElementVNode("span", _hoisted_28, _toDisplayString($options.valueOrFallback($options.participant.mail)), 1)
      ]),
      ($options.participant.paMail)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createElementVNode("span", _hoisted_30, _toDisplayString($props.labels.paMail) + ":", 1),
            _createElementVNode("span", _hoisted_31, _toDisplayString($options.valueOrFallback($options.participant.paMail)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", _hoisted_32, _toDisplayString($props.labels.yourBookings), 1),
    _createElementVNode("div", _hoisted_33, [
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("span", _hoisted_35, _toDisplayString($props.labels.participation) + ":", 1),
        _createElementVNode("span", _hoisted_36, _toDisplayString($options.valueOrFallback($options.bookings)), 1)
      ]),
      _createElementVNode("div", _hoisted_37, [
        _createElementVNode("span", _hoisted_38, _toDisplayString($props.labels.accommodationCost) + ":", 1),
        _createElementVNode("span", _hoisted_39, _toDisplayString($options.isAccommodationCostCoverage ? $props.labels.yes : $props.labels.no), 1)
      ]),
      _createElementVNode("div", _hoisted_40, [
        _createElementVNode("span", _hoisted_41, _toDisplayString($props.labels.dietaryRestrictions) + ":", 1),
        _createElementVNode("span", _hoisted_42, _toDisplayString($options.valueOrFallback($options.dietaryRestrictions)), 1)
      ]),
      ($options.participant.dietaryRestrictionsOther)
        ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
            _createElementVNode("span", _hoisted_44, _toDisplayString($props.labels.other) + ":", 1),
            _createElementVNode("span", _hoisted_45, _toDisplayString($options.valueOrFallback($options.participant.dietaryRestrictionsOther)), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    ($options.participant.golfTournament)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("span", _hoisted_46, _toDisplayString($props.labels.golfTournament), 1),
          _createElementVNode("div", _hoisted_47, [
            ($options.participant.golfHandicap)
              ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                  _createElementVNode("span", _hoisted_49, _toDisplayString($props.labels.handicap) + ":", 1),
                  _createElementVNode("span", _hoisted_50, _toDisplayString($options.valueOrFallback($options.participant.golfHandicap)), 1)
                ]))
              : _createCommentVNode("", true),
            ($options.participant.golfClubMembership)
              ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                  _createElementVNode("span", _hoisted_52, _toDisplayString($props.labels.clubMember) + ":", 1),
                  _createElementVNode("span", _hoisted_53, _toDisplayString($options.valueOrFallback($options.participant.golfClubMembership)), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_54, [
              _createElementVNode("span", _hoisted_55, _toDisplayString($props.labels.clubRental) + ":", 1),
              _createElementVNode("span", _hoisted_56, _toDisplayString($options.valueOrFallback($options.getRental($options.participant.golfClubsRental))), 1)
            ]),
            _createElementVNode("div", _hoisted_57, [
              _createElementVNode("span", _hoisted_58, _toDisplayString($props.labels.clothingSize) + ":", 1),
              _createElementVNode("span", _hoisted_59, _toDisplayString($options.valueOrFallback($options.getSize($options.participant.clothingSize))), 1)
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    ($options.participant.bikeTour)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("span", _hoisted_60, _toDisplayString($props.labels.bikeTour), 1),
          _createElementVNode("div", _hoisted_61, [
            _createElementVNode("div", _hoisted_62, [
              _createElementVNode("span", _hoisted_63, _toDisplayString($props.labels.skillLevel) + ":", 1),
              _createElementVNode("span", _hoisted_64, _toDisplayString($options.valueOrFallback($options.getSkillLevel($options.participant.bikeSkillLevel))), 1)
            ]),
            _createElementVNode("div", _hoisted_65, [
              _createElementVNode("span", _hoisted_66, _toDisplayString($props.labels.height) + ":", 1),
              _createElementVNode("span", _hoisted_67, _toDisplayString($options.valueOrFallback($options.participant.height)), 1)
            ]),
            _createElementVNode("div", _hoisted_68, [
              _createElementVNode("span", _hoisted_69, _toDisplayString($props.labels.weight) + ":", 1),
              _createElementVNode("span", _hoisted_70, _toDisplayString($options.valueOrFallback($options.participant.weight)), 1)
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_71, _toDisplayString($props.labels.travelDetails), 1),
    _createElementVNode("div", _hoisted_72, [
      _createElementVNode("div", _hoisted_73, [
        _createElementVNode("span", _hoisted_74, _toDisplayString($props.labels.meansOFTravel) + ":", 1),
        _createElementVNode("span", _hoisted_75, _toDisplayString($options.valueOrFallback($options.getMeansOfTravel($options.participant.meansOfTravel))), 1)
      ]),
      ($options.participant.meansOfTravel === 'TRAIN' || $options.participant.meansOfTravel === 'AIRPLANE')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_76, [
              _createElementVNode("span", _hoisted_77, _toDisplayString($options.participant.meansOfTravel === 'TRAIN' ? $props.labels.transportationNeeded : $props.labels.shuttleNeeded) + ": ", 1),
              _createElementVNode("span", _hoisted_78, _toDisplayString($options.isShuttleNeeded ? $props.labels.yes : $props.labels.no), 1)
            ]),
            ($options.isShuttleNeeded)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  ($options.participant.meansOfTravel === 'AIRPLANE')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                        _createElementVNode("span", _hoisted_80, _toDisplayString($props.labels.arrivalFlightNo) + ":", 1),
                        _createElementVNode("span", _hoisted_81, _toDisplayString($options.valueOrFallback($options.participant.arrivalFlightNo)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  ($options.participant.meansOfTravel === 'AIRPLANE')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                        _createElementVNode("span", _hoisted_83, _toDisplayString($props.labels.arrivalFlightFrom) + ":", 1),
                        _createElementVNode("span", _hoisted_84, _toDisplayString($options.valueOrFallback($options.participant.arrivalFlightFrom)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_85, [
                    _createElementVNode("span", _hoisted_86, _toDisplayString($props.labels.arrivalDate) + ":", 1),
                    _createElementVNode("span", _hoisted_87, _toDisplayString($options.getSwissDate($options.participant.arrivalTime)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_88, [
                    _createElementVNode("span", _hoisted_89, _toDisplayString($props.labels.arrivalTime) + ":", 1),
                    _createElementVNode("span", _hoisted_90, _toDisplayString($options.getSwissTime($options.participant.arrivalTime)), 1)
                  ]),
                  ($options.participant.meansOfTravel === 'AIRPLANE')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_91, [
                        _createElementVNode("span", _hoisted_92, _toDisplayString($props.labels.departureFlightNo) + ":", 1),
                        _createElementVNode("span", _hoisted_93, _toDisplayString($options.valueOrFallback($options.participant.departureFlightNo)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  ($options.participant.meansOfTravel === 'AIRPLANE')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                        _createElementVNode("span", _hoisted_95, _toDisplayString($props.labels.departureFlightTo) + ":", 1),
                        _createElementVNode("span", _hoisted_96, _toDisplayString($options.valueOrFallback($options.participant.departureFlightTo)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_97, [
                    _createElementVNode("span", _hoisted_98, _toDisplayString($props.labels.departureDate) + ":", 1),
                    _createElementVNode("span", _hoisted_99, _toDisplayString($options.getSwissDate($options.participant.departureTime)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_100, [
                    _createElementVNode("span", _hoisted_101, _toDisplayString($props.labels.departureTime) + ":", 1),
                    _createElementVNode("span", _hoisted_102, _toDisplayString($options.getSwissTime($options.participant.departureTime)), 1)
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    ($options.participant.status !== 'REGISTERED_COMPLETE')
      ? (_openBlock(), _createBlock(_component_Form, {
          key: 3,
          class: "flex flex-col print:hidden",
          as: "div"
        }, {
          default: _withCtx(({ handleSubmit }) => [
            _createElementVNode("span", _hoisted_103, _toDisplayString($props.labels.bindingConfirmation), 1),
            _createVNode(_component_VueFormCheckbox, {
              name: "confirmation",
              required: "",
              class: "mt-4",
              "text-classes": "text-para-s md:text-para-base pl-6 md:pl-7",
              modelValue: $data.confirmation,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.confirmation) = $event))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: $props.labels.bindingConfirmationOption1 + '*'
                }, null, 8, _hoisted_104)
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_VueFormCheckbox, {
              name: "policyConfirmation",
              required: "",
              class: "mt-4",
              "text-classes": "text-para-s md:text-para-base pl-6 md:pl-7",
              modelValue: $data.policyConfirmation,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.policyConfirmation) = $event))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  innerHTML: $props.labels.bindingConfirmationOption2 + '*'
                }, null, 8, _hoisted_105)
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_106, [
              _createElementVNode("div", null, _toDisplayString($props.labels.requiredHint), 1),
              _createElementVNode("div", _hoisted_107, _toDisplayString($props.labels.bindingConfirmationHint) + ":", 1),
              _createElementVNode("div", _hoisted_108, _toDisplayString($props.labels.mail) + ": " + _toDisplayString($options.event.paMail), 1),
              _createElementVNode("div", null, _toDisplayString($props.labels.phone) + ": " + _toDisplayString($options.event.paPhone), 1),
              _createElementVNode("div", null, _toDisplayString($props.labels.mobile) + ": " + _toDisplayString($options.event.paMobile), 1)
            ]),
            _createElementVNode("div", _hoisted_109, [
              _createElementVNode("button", {
                class: "flex items-center order-2 md:order-1 group",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.print && $options.print(...args)))
              }, [
                _createElementVNode("span", _hoisted_110, _toDisplayString($props.labels.printPage), 1),
                _createVNode(_component_ArrowButton, {
                  class: "w-10 h-10 flex-shrink-0",
                  "show-ring-on-mobile": "",
                  direction: "print"
                })
              ]),
              (!!$options.participant.meansOfTravel && $options.participant.meansOfTravel !== 'UNKNOWN')
                ? (_openBlock(), _createBlock(_component_GcButton, {
                    key: 0,
                    class: "order-1 md:order-2",
                    label: $props.labels.bookBindingly,
                    onClick: ($event: any) => (handleSubmit($options.confirmBooking))
                  }, null, 8, ["label", "onClick"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_111, _toDisplayString($props.labels.noTravelData), 1))
            ]),
            _createElementVNode("div", _hoisted_112, [
              _createElementVNode("button", {
                type: "button",
                class: "flex items-center group cursor-pointer mt-6",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.toOverview && $options.toOverview(...args)))
              }, [
                _createElementVNode("span", _hoisted_113, _toDisplayString($props.labels.personalOverview), 1),
                _createVNode(_component_ArrowButton, {
                  direction: "person",
                  class: "w-10 h-10",
                  "show-ring-on-mobile": ""
                })
              ])
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_114, [
          _createElementVNode("div", _hoisted_115, [
            _createElementVNode("div", _hoisted_116, _toDisplayString($props.labels.bookedHint) + ":", 1),
            _createElementVNode("div", _hoisted_117, _toDisplayString($props.labels.mail) + ": " + _toDisplayString($options.event.paMail), 1),
            _createElementVNode("div", null, _toDisplayString($props.labels.phone) + ": " + _toDisplayString($options.event.paPhone), 1),
            _createElementVNode("div", null, _toDisplayString($props.labels.mobile) + ": " + _toDisplayString($options.event.paMobile), 1)
          ]),
          _createElementVNode("button", {
            class: "flex items-center group mt-10 print:hidden",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.print && $options.print(...args)))
          }, [
            _createElementVNode("span", _hoisted_118, _toDisplayString($props.labels.printPage), 1),
            _createVNode(_component_ArrowButton, {
              class: "w-10 h-10 flex-shrink-0",
              "show-ring-on-mobile": "",
              direction: "print"
            })
          ])
        ]))
  ], 2))
}