import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-5 bg-white rounded shadow-lg z-30 max-w-full" }
const _hoisted_2 = {
  key: 0,
  class: "text-para-xl font-semibold"
}
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "mx-auto w-max max-w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcButton = _resolveComponent("GcButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($data.hasConsent)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["relative", [{'bg-cover': $props.previewImageLink , 'bg-black-50' : !$props.previewImageLink}, $options.dynamicClasses]]),
          style: _normalizeStyle($options.previewImageLinkStyle)
        }, [
          _createElementVNode("div", _hoisted_1, [
            ($props.title)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString($props.title), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_GcButton, {
                  class: "my-3",
                  secondary: true,
                  label: $props.labels.settings,
                  url: $props.cookiePolicyPageLink
                }, null, 8, ["label", "url"]),
                _createVNode(_component_GcButton, {
                  label: $props.labels.accept,
                  color: "primary",
                  onClick: $options.updateConsent
                }, null, 8, ["label", "onClick"])
              ])
            ])
          ])
        ], 6))
  ]))
}