<template>
    <div
        class="map relative w-full h-80 md:h-120 xl:h-180 z-20 xl:z-auto"
        :class="{ 'xl:w-full': !fitMap, 'xl:w-3/4': fitMap }"
    >
        <google-map
            ref="mapRef"
            :api-key="apiKeyGM"
            :styles="mapStyles"
            :center="center"
            :zoom="zoom"
            :min-zoom="2"
            :max-zoom="15"
            class="w-full h-full"
            style="width: 100%; height: 100%"
            :street-view-control="false"
            :fullscreen-control="false"
            :map-type-control="false"
        >
            <marker-cluster
                :options="{
                    algorithm: clusterAlgorithm(),
                    renderer: clusterRenderer,
                }"
            >
                <custom-marker
                    v-for="(marker, i) in markers"
                    :key="i"
                    :options="{
                        position: marker.position,
                        anchorPoint: 'BOTTOM_CENTER',
                        offsetY: -40,
                    }"
                    :clickable="true"
                    :label="{
                        color: marker.label ? 'white' : 'transparent',
                        fontFamily:
                            'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
                        fontSize: '12px',
                        fontWeight: '100',
                        text: marker.label || '',
                    }"
                    @click="
                        !isOfficeMap
                            ? openLocation(marker)
                            : selectOffice(marker)
                    "
                >
                    <div
                        class="relative bg-white px-4 py-2 flex items-center justify-center border rounded-full text-para-xs"
                        :style="getMarkerStyle(marker)"
                    >
                        {{ marker.label || marker.area || "" }}
                        <div
                            class="absolute border-1 w-0.5 top-full h-10"
                            :style="{
                                backgroundColor: !isOfficeMap
                                    ? marker?.category.color
                                    : '#00928E',
                                borderColor: !isOfficeMap
                                    ? marker?.category.color
                                    : '#00928E',
                                left: 'calc(50% - 2px)',
                            }"
                        />
                    </div>
                </custom-marker>
            </marker-cluster>
        </google-map>
    </div>
</template>

<script lang="ts">
import styles from "./mapStyles.js";
import breakpoints from "../../plugins/breakpoints";
import { Algorithm } from "@googlemaps/markerclusterer/dist/algorithms/core";
import { GridAlgorithm } from "@googlemaps/markerclusterer";
import { Office } from "./WorldMap.vue";
import isEqual from "lodash.isequal";

const cluster = {
    textColor: "black",
    height: 38,
    fontFamily: "Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif",
    fontSize: "12px",
    fontWeight: "800",
    url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDMyIDQwIiBmaWxsPSJub25lIj4KICAgIDxjaXJjbGUgY3g9IjE2IiBjeT0iMTYiIHI9IjE1IiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSIjMDA5MjhFIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgIDxsaW5lIHgxPSIxNiIgeTE9IjMyIiB4Mj0iMTYiIHkyPSIzOSIgc3Ryb2tlPSIjMDA5MjhFIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4=",
    width: 32,
};

export default {
    props: {
        markers: { type: Array },
        singleMarker: { type: Object },
        regionOption: { type: Object },
        isOfficeMap: { type: Boolean, default: false },
        isWorldMap: { type: Boolean, default: false },
        hideCategories: { type: Boolean, default: false },
        selectedMarker: { type: Object, default: () => {} },
    },
    emits: ["open-location", "office-select"],
    data() {
        return {
            renderMarkers: true,
            currentBounds: {},
            zoom: 2,
            specialZoomLevels: {
                mobile: 1,
                tablet: 1.4,
                desktop: 2.5,
            },
            center: { lat: 0, lng: 0 },

            bounds: {},
            mapStyles: styles,
            apiKeyGM:
                document.body.dataset.gmapsApiKey ||
                "AIzaSyCSrasp7xoSYNhdBANpj51-QEtNjlH0MyY",
            reactiveMapRef: undefined,
            gridSize: 28,
            viewportPadding: 1,
            clusterRenderer: {
                render: (props) => {
                    const { count, position, markers } = props;
                    const clusterMarker = new google.maps.Marker({
                        position,
                        label: {
                            text: String(count),
                            color: cluster.textColor,
                            fontSize: cluster.fontSize,
                            fontFamily: cluster.fontFamily,
                            fontWeight: "800",
                        },
                        icon: {
                            url: cluster.url,
                            scaledSize: new google.maps.Size(
                                cluster.width,
                                cluster.height
                            ),
                            labelOrigin: new google.maps.Point(16, 16),
                        },
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    });

                    // Add click listener for zoom-in effect
                    clusterMarker.addListener("click", () => {
                        if (!this.$refs.mapRef.map) {
                            console.warn("Map instance not found");
                            return;
                        }

                        // Create bounds to fit all markers in the cluster
                        const bounds = new google.maps.LatLngBounds();
                        markers.forEach((marker) =>
                            bounds.extend(marker.getPosition())
                        );
                        this.$refs.mapRef.map.fitBounds(bounds);

                        // Optionally, smoothly zoom in to a target zoom level
                        const targetZoom = Math.min(
                            this.$refs.mapRef.map.getZoom() + 2,
                            15
                        ); // Define your target zoom level
                        this.smoothZoom(
                            this.$refs.mapRef.map,
                            targetZoom,
                            this.$refs.mapRef.map.getZoom()
                        );
                    });

                    return clusterMarker;
                },
            },
        };
    },
    computed: {
        responsiveZoom(): number {
            return this.specialZoomLevels[this.breakpoint];
        },
        breakpoint(): string {
            if (breakpoints.desktop) {
                return "desktop";
            }
            if (breakpoints.tablet) {
                return "tablet";
            } else {
                return "mobile";
            }
        },
        fitMap() {
            return (
                this.isOfficeMap || (!this.isOfficeMap && !this.hideCategories)
            );
        },
    },
    watch: {
        "reactiveMapRef.ready": {
            handler() {
                this.markersChanged();
            },
            deep: true, // deep watch, because property on referenced object
        },
        markers() {
            this.markersChanged();
        },
    },
    mounted() {
        this.reactiveMapRef = this.$refs.mapRef;
    },
    methods: {
        clusterAlgorithm(): Algorithm {
            return new GridAlgorithm({
                gridSize: this.gridSize,
                viewportPadding: this.viewportPadding,
            });
        },
        smoothZoom(map, targetZoom, currentZoom) {
            if (currentZoom >= targetZoom) return;

            google.maps.event.addListenerOnce(map, "zoom_changed", () => {
                this.smoothZoom(map, targetZoom, currentZoom + 1);
            });

            setTimeout(() => {
                map.setZoom(currentZoom + 1);
            }, 150);
        },
        openLocation(marker) {
            this.$emit("open-location", marker);
        },
        getMarkerStyle(marker) {
            return {
                borderColor: !this.isOfficeMap
                    ? marker.category.color
                    : "#00928E",
                backgroundColor:
                    this.selectedMarker === marker
                        ? !this.isOfficeMap
                            ? marker.category.color
                            : "#00928E"
                        : "white",
                color: this.selectedMarker === marker ? "white" : "black",
            };
        },
        selectOffice(office: Office) {
            this.$emit("office-select", office);
        },
        markersChanged() {
            this.renderMarkers = false;

            if (this.$refs.mapRef.ready) {
                if (this.markers.length) {
                    /* eslint-disable */
                    //@ts-ignore
                    const bounds = new google.maps.LatLngBounds();
                    this.markers.forEach((marker) =>
                        bounds.extend(marker.position)
                    );

                    // Check if the bounds didn't change
                    if (isEqual(bounds, this.currentBounds)) {
                        this.renderMarkers = true;
                    }

                    this.currentBounds = bounds;
                }
            }
        },
    },
};
</script>
