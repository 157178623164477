import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_2 = { class: "text-para-s font-semibold mb-4" }
const _hoisted_3 = { class: "flex flex-col md:flex-row gap-x-6 gap-y-2" }
const _hoisted_4 = { class: "flex-grow" }
const _hoisted_5 = { class: "flex justify-between mt-px" }
const _hoisted_6 = { class: "text-para-xs text-black-500" }
const _hoisted_7 = { class: "text-para-xs text-black-500" }
const _hoisted_8 = ["id"]
const _hoisted_9 = ["for"]
const _hoisted_10 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_11 = ["id", "aria-invalid", "aria-describedby"]
const _hoisted_12 = ["id"]
const _hoisted_13 = ["id"]
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "d-md-none" }
const _hoisted_16 = { class: "d-none d-md-inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_VueSlider = _resolveComponent("VueSlider")!
  const _component_Field = _resolveComponent("Field")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.type === $data.DutyFieldType.RANGE_SLIDER)
      ? (_openBlock(), _createBlock(_component_Field, {
          key: 0,
          modelValue: $data.model,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.model) = $event)),
          class: "w-full flex flex-col border border-black-100 rounded p-4 md:pr-28",
          rules: $options.rules,
          as: "div",
          name: $props.label
        }, {
          default: _withCtx(({ errors, field }) => [
            _createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_NumberInput, _mergeProps(field, {
                modelValue: $data.model,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => (($data.model) = $event)),
                  $options.updateSliderValue
                ],
                class: "appearance-none text-para-lg font-semibold focus:outline-none w-full md:w-28",
                name: $props.name,
                options: $options.rangeOptions,
                "aria-required": "true",
                "aria-label": $props.label,
                "aria-invalid": errors.length > 0,
                "aria-describedby": errors.length > 0 ? `${$props.name}-error` : ''
              }), null, 16, ["modelValue", "name", "options", "aria-label", "aria-invalid", "aria-describedby", "onUpdate:modelValue"]),
              _createElementVNode("div", _hoisted_4, [
                ($data.initialised)
                  ? (_openBlock(), _createBlock(_component_VueSlider, {
                      key: 0,
                      modelValue: $data.sliderModel,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.sliderModel) = $event)),
                      height: 2,
                      min: Math.min(...$options.dValues),
                      max: Math.max(...$options.dValues),
                      adsorb: true,
                      interval: $props.interval,
                      "process-style": {backgroundColor: '#00928e'},
                      "dot-style": {backgroundColor: '#00928e'},
                      tooltip: "none",
                      onChange: _cache[2] || (_cache[2] = (value) => $data.model = value),
                      onDragging: _cache[3] || (_cache[3] = (value) => $data.model = value)
                    }, null, 8, ["modelValue", "min", "max", "interval"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString($options._min), 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString($options._max), 1)
                ])
              ])
            ]),
            (errors.length > 0)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  id: `${$props.name}-error`,
                  class: "text-error text-para-xs mt-4"
                }, _toDisplayString(errors[0]), 9, _hoisted_8))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "rules", "name"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("label", {
            for: $props.name,
            class: "md:w-48"
          }, _toDisplayString($props.label), 9, _hoisted_9),
          _createElementVNode("div", _hoisted_10, [
            ($props.type === $data.DutyFieldType.DROPDOWN)
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: 0,
                  id: $props.name,
                  modelValue: $data.model,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.model) = $event)),
                  title: $data.model,
                  white: true,
                  disabled: $props.disabled,
                  class: "w-full min-w-40"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.dValues, (val, i) => {
                      return (_openBlock(), _createBlock(_component_DropdownItem, {
                        key: i,
                        value: `${$props.name}#${i}`,
                        label: `${val}`
                      }, null, 8, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["id", "modelValue", "title", "disabled"]))
              : ($props.type === $data.DutyFieldType.PERCENTAGE)
                ? (_openBlock(), _createBlock(_component_Field, {
                    key: 1,
                    modelValue: $data.model,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.model) = $event)),
                    vid: $props.name,
                    rules: { required: true, min: 0, max: 99 },
                    name: $props.name
                  }, {
                    default: _withCtx(({ field, errors }) => [
                      _createElementVNode("input", _mergeProps({ id: $props.name }, field, {
                        type: "number",
                        class: ["w-full border transition-colors cursor-pointer border-black-100 hover:text-black-900 bg-white rounded-b rounded-t px-4 placeholder-improvedContrast", [ errors.length > 0 ? 'border-error' : '']],
                        "aria-required": "true",
                        "aria-invalid": errors.length > 0,
                        "aria-describedby": errors.length > 0 ? `${$props.name}-error` : '',
                        min: "0",
                        max: "99",
                        placeholder: "enter a % value between 1-99"
                      }), null, 16, _hoisted_11),
                      (errors.length > 0)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            id: `${$props.name}-error`,
                            class: "text-error text-para-xs mt-1 md:mt-2"
                          }, _toDisplayString(errors[0]), 9, _hoisted_12))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue", "vid", "name"]))
                : ($props.type === $data.DutyFieldType.CHECKBOX)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _withDirectives(_createElementVNode("input", {
                        id: $props.name,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.model) = $event)),
                        type: "checkbox",
                        value: true,
                        class: "absolute opacity-0 text-black-900 text-para-xs md:text-para-s"
                      }, null, 8, _hoisted_13), [
                        [_vModelCheckbox, $data.model]
                      ]),
                      _createElementVNode("label", {
                        for: $props.name,
                        class: "inline-block relative pl-6 checkbox-label-calculator"
                      }, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString($props.label), 1),
                        _createElementVNode("span", _hoisted_16, " " + _toDisplayString($props.yesLabel), 1)
                      ], 8, _hoisted_14)
                    ], 64))
                  : ($props.type === $data.DutyFieldType.KVP_DROPDOWN)
                    ? (_openBlock(), _createBlock(_component_Dropdown, {
                        key: 3,
                        id: $props.name,
                        modelValue: $data.model,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.model) = $event)),
                        title: $props.name
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.values, (c) => {
                            return (_openBlock(), _createBlock(_component_DropdownItem, {
                              key: `${$props.name}#${c.value}`,
                              value: c.value,
                              label: c.label
                            }, null, 8, ["value", "label"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["id", "modelValue", "title"]))
                    : _createCommentVNode("", true)
          ])
        ], 64))
  ]))
}