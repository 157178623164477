import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container py-6 md:py-8" }
const _hoisted_2 = {
  key: 0,
  class: "text-heading-3 md:text-heading-2 mb-4"
}
const _hoisted_3 = { class: "md:-mx-0.5" }
const _hoisted_4 = {
  key: 0,
  "transition-duration": "0"
}
const _hoisted_5 = {
  key: 1,
  class: "-mx-4 md:-mx-10 xl:-mx-20"
}
const _hoisted_6 = { class: "flex flex-row justify-center space-x-3 text-black mt-9 space-x-4" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcSwiper = _resolveComponent("GcSwiper")!
  const _directive_masonry_tile = _resolveDirective("masonry-tile")!
  const _directive_masonry = _resolveDirective("masonry")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      ($props.showLabel)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString($props.labelTabSection), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        ($options.showTiles)
          ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (item, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: _normalizeClass(["w-full md:w-4/12 md:px-0.5", {'xl:w-3/12': !$props.inContentGroup}])
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(item.type), { data: item }, null, 8, ["data"]))
                ], 2)), [
                  [_directive_masonry_tile]
                ])
              }), 128))
            ])), [
              [_directive_masonry]
            ])
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_GcSwiper, {
                "slide-classes": $props.inContentGroup ? 'flex w-full md:w-4/12' : 'flex w-full md:w-4/12 xl:w-3/12',
                "container-classes": "px-4 md:px-10 xl:px-20",
                gap: 4,
                direction: "horizontal"
              }, _createSlots({
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (item, index) => {
                    return (_openBlock(), _createBlock(_resolveDynamicComponent(item.type), {
                      key: index,
                      data: item
                    }, null, 8, ["data"]))
                  }), 128))
                ]),
                _: 2
              }, [
                ($options.showPagination)
                  ? {
                      name: "pagination",
                      fn: _withCtx(({ index, total, teaser, go }) => [
                        _createElementVNode("ul", _hoisted_6, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(total, (dot, i) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: i,
                              class: _normalizeClass(["relative w-2 h-2 rounded cursor-pointer", [index === (i + 1) ? 'bg-primary-500 after:content after:w-4 after:h-4 after:rounded-full after:absolute after:-top-1 after:-left-1 after:border after:border-primary-200' : 'bg-black-200']]),
                              onClick: ($event: any) => (go(i))
                            }, null, 10, _hoisted_7))
                          }), 128))
                        ])
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032, ["slide-classes"])
            ]))
      ])
    ])
  ]))
}