import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "video-id", "player-id"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayButton = _resolveComponent("PlayButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["overflow-hidden relative w-full", [$props.fullScreen ? 'h-full' : `h-0 ${$options.padding}`]])
  }, [
    _createElementVNode("div", {
      id: $data.videoContainerId,
      "mi24-video-player": "",
      class: "object-cover object-center absolute top-0 left-0 w-full h-full",
      "video-id": $props.videoId,
      "player-id": $props.playerId,
      "config-type": "vmpro",
      "flash-path": "https://e.video-cdn.net/v2/",
      "api-url": "https://d.video-cdn.net/play"
    }, null, 8, _hoisted_1),
    ($data.firstPlay)
      ? (_openBlock(), _createBlock(_component_PlayButton, {
          key: 0,
          "tablet-size": $options.tabletPlayButtonSize,
          "desktop-size": $options.desktopPlayButtonSize,
          "aria-label": $options.$globalLabels.play,
          onClick: $options.playVideo
        }, null, 8, ["tablet-size", "desktop-size", "aria-label", "onClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      class: _normalizeClass(["object-cover object-center absolute top-0 left-0 w-full h-full z-5", {'hidden' : !$options.showPlaceHolderImage}]),
      src: $props.placeholderImageUrl,
      alt: ""
    }, null, 10, _hoisted_2),
    ($data.firstPlay && $props.fullScreen)
      ? _renderSlot(_ctx.$slots, "default", {
          key: 1,
          play: $options.playVideo
        })
      : _createCommentVNode("", true)
  ], 2))
}