import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-labelledby"]
const _hoisted_2 = { class: "w-full h-full" }
const _hoisted_3 = ["media", "srcset"]
const _hoisted_4 = ["src", "alt", "title"]
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GcLightbox = _resolveComponent("GcLightbox")!

  return (_openBlock(), _createElementBlock("figure", {
    class: _normalizeClass({'cursor-pointer': $options._showLightbox}),
    "aria-labelledby": $props.labelledBy
  }, [
    _createElementVNode("picture", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sources, (src, index) => {
        return (_openBlock(), _createElementBlock("source", {
          key: index,
          media: src.media,
          srcset: src.srcset
        }, null, 8, _hoisted_3))
      }), 128)),
      _createElementVNode("img", {
        class: _normalizeClass($props.imgClass),
        loading: "lazy",
        src: $options.originalUrl,
        alt: $options.alt,
        title: $props.title
      }, null, 10, _hoisted_4)
    ]),
    ($props.showCaption)
      ? (_openBlock(), _createElementBlock("figcaption", _hoisted_5, _toDisplayString($props.caption), 1))
      : _createCommentVNode("", true),
    ($options._showLightbox)
      ? (_openBlock(), _createBlock(_component_GcLightbox, {
          key: 1,
          modelValue: $data.lightboxVisible,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.lightboxVisible) = $event)),
          src: $options.wideUrl,
          caption: $props.caption,
          "alt-title": $options.alt,
          "hide-mobile-icon": $props.hideMobileLightboxIcon
        }, null, 8, ["modelValue", "src", "caption", "alt-title", "hide-mobile-icon"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}