<template>
    <Form v-slot="{ validate }" as="div">
        <div class="hidden md:flex items-center justify-end mt-2 mb-6 md:mt-8 md:mb-20">
            <button type="button"
                    class="flex items-center group cursor-pointer"
                    @click="toOverview"
            >
                <span class="text-para-s font-semibold">{{ labels.personalOverview }}</span>
                <ArrowButton direction="person" class="w-10 h-10" />
            </button>
        </div>
        <h2 class="text-heading-2 md:text-heading-1 mt-12 md:mt-0">{{ labels.personalData }}</h2>
        <p class="text-para-s md:text-para-xl mt-4 md:mt-2" v-html="labels.personalDataDescription" />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10 md:mt-14">
            <VueFormSelection name="gender"
                              :title="labels.gender"
                              :placeholder="labels.pleaseChoose"
                              type="select"
                              :labels="[
                                  labels.genderFemale + ':FEMALE',
                                  labels.genderMale + ':MALE',
                                  labels.unknown + ':UNKNOWN'
                              ]"
                              v-model="participant.gender"
                              @update:modelValue="onFocusChange(true, 'gender', participant.gender)"
            />
            <VueFormInput name="title"
                          :title="labels.namePrefix"
                          v-model="participant.title"
                          @blur="onFocusChange($event, 'title', participant.title)"
            />
            <VueFormInput name="firstName"
                          :title="labels.firstName"
                          :disabled="true"
                          v-model="participant.firstName"
            />
            <VueFormInput name="lastName"
                          :title="labels.lastName"
                          :disabled="true"
                          v-model="participant.lastName"
            />
            <VueFormInput name="company"
                          :title="labels.company"
                          :disabled="true"
                          v-model="participant.company"
            />
            <VueFormInput name="mail"
                          :title="labels.mailAddress"
                          :disabled="true"
                          type="email"
                          v-model="participant.mail"
            />
            <VueFormInput name="phone"
                          :title="labels.mobilePhone"
                          :description="labels.mobilePhoneHint"
                          :required="true"
                          :rules="{required: true}"
                          type="tel"
                          v-model="participant.phone"
                          @blur="onFocusChange($event, 'phone', participant.phone)"
            />
            <VueFormInput name="paMail"
                          :title="labels.paMail"
                          type="email"
                          :rules="{email: true}"
                          v-model="participant.paMail"
                          @blur="onFocusChange($event, 'paMail', participant.paMail)"
            />
        </div>
        <p class="text-para-s md:text-para-base mt-10 md:mt-16" v-html="labels.requiredHint" />
        <button type="button"
                class="flex flex-row-reverse items-center group w-full mt-8 md:mt-12"
                @click="onSubmit(validate)"
        >
            <ArrowButton class="w-10 h-10 flex-shrink-0" show-ring-on-mobile />
            <span class="font-semibold text-para-s line-clamp-1">{{ labels.next }}</span>
        </button>
        <div class="md:hidden border-t border-black-200 mt-12">
            <button type="button"
                    class="flex items-center group cursor-pointer mt-6"
                    @click="toOverview"
            >
                <span class="text-para-s font-semibold">{{ labels.personalOverview }}</span>
                <ArrowButton direction="person" class="w-10 h-10" show-ring-on-mobile />
            </button>
        </div>
    </Form>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { GolfEventLabels } from './GolfEventTypes';
import ArrowButton from "../base/ArrowButton.vue";
import VueFormSelection from "../form/VueFormSelection.vue";
import VueFormInput from "../form/VueFormInput.vue";
import { Form } from "vee-validate";

export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: {Form,  VueFormInput, VueFormSelection, ArrowButton},
    inject: ['participant', 'updateData', 'updateProperty', 'nextStep', 'toOverview'],
    props: {
        labels: {
            type: Object as PropType<GolfEventLabels>,
            required: true
        }
    },
    mounted(): void {
        this.$el.scrollIntoView({ behavior: 'smooth' });
    },
    methods: {
        onFocusChange(passed: boolean, name: string, value: any): void {
            if (passed) {
                this.updateProperty(name, value);
            }
        },
        onSubmit(validate: () => Promise<boolean>): void {
            validate().then((valid) => {
                if (valid) {
                    this.nextStep();
                }
            });
        }
    }
};
</script>
