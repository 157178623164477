import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full table-fixed" }
const _hoisted_2 = { class: "text-para-xs uppercase text-black-600 border-b border-black-900" }
const _hoisted_3 = {
  key: 0,
  scope: "col",
  class: "font-semibold text-left w-14 p-0 pb-2"
}
const _hoisted_4 = {
  scope: "col",
  class: "font-semibold text-left p-0 pb-2"
}
const _hoisted_5 = {
  scope: "col",
  class: "font-semibold text-right w-16 md:w-32 p-0 pb-2"
}
const _hoisted_6 = {
  scope: "col",
  class: "font-semibold text-right w-16 md:w-32 p-0 pb-2"
}
const _hoisted_7 = {
  key: 0,
  class: "p-0 pb-2"
}
const _hoisted_8 = { class: "p-0 pb-2" }
const _hoisted_9 = { class: "text-right p-0 pb-2" }
const _hoisted_10 = { class: "text-right p-0 pb-2" }
const _hoisted_11 = { class: "flex flex-col md:flex-row md:justify-end text-para-xs mt-2 md:mt-4" }
const _hoisted_12 = { class: "mr-1" }
const _hoisted_13 = { class: "font-semibold" }
const _hoisted_14 = { class: "font-semibold" }
const _hoisted_15 = { class: "border-t border-black-900 mt-6 pt-6" }
const _hoisted_16 = { class: "text-para-xs uppercase font-semibold mb-2 md:mb-4" }
const _hoisted_17 = { class: "flex flex-col md:flex-row gap-y-2 md:gap-x-4 xl:gap-x-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("thead", _hoisted_2, [
        _createElementVNode("tr", null, [
          (!$props.hideTrafficLight)
            ? (_openBlock(), _createElementBlock("th", _hoisted_3, _toDisplayString($props.labels.col1), 1))
            : _createCommentVNode("", true),
          _createElementVNode("th", _hoisted_4, _toDisplayString($props.labels.col2), 1),
          _createElementVNode("th", _hoisted_5, _toDisplayString($props.labels.col3), 1),
          _createElementVNode("th", _hoisted_6, _toDisplayString($props.labels.col4), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        _cache[0] || (_cache[0] = _createElementVNode("tr", null, [
          _createElementVNode("td", { class: "h-2 md:h-4 p-0" })
        ], -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (item, index) => {
          return (_openBlock(), _createElementBlock("tr", { key: index }, [
            (!$props.hideTrafficLight)
              ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["w-6 h-6", $options.getColor(item.value60)])
                  }, null, 2)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("td", _hoisted_8, _toDisplayString(item.label), 1),
            _createElementVNode("td", _hoisted_9, _toDisplayString(item.value5.toFixed(2)), 1),
            _createElementVNode("td", _hoisted_10, _toDisplayString(item.value60.toFixed(2)), 1)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("span", _hoisted_12, _toDisplayString($props.labels.dataCollected) + ":", 1),
      _createElementVNode("span", null, [
        _createElementVNode("span", null, _toDisplayString($props.labels.min5Time) + ": ", 1),
        _createElementVNode("span", _hoisted_13, _toDisplayString($options._fiveMinTime) + " - ", 1),
        _createElementVNode("span", null, _toDisplayString($props.labels.hourlyTime) + ": ", 1),
        _createElementVNode("span", _hoisted_14, _toDisplayString($options._hourlyTime), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("p", _hoisted_16, _toDisplayString($props.labels.legend), 1),
      _createElementVNode("ul", _hoisted_17, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.scale, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.min,
            class: "flex"
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["w-1 h-6 md:h-full mr-2", `bg-graph-${item.color}`])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["text-para-xs font-semibold bg-opacity-20 rounded-xl px-3 py-1", `bg-graph-${item.color}`])
            }, _toDisplayString(!!item.max ? `${item.min}-${item.max}` : `>= ${item.min}`) + " " + _toDisplayString(item.label), 3)
          ]))
        }), 128))
      ])
    ])
  ]))
}