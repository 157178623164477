import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "text-para-xs md:text-para-base font-semibold mb-2" }
const _hoisted_3 = { class: "relative w-24 h-24 md:w-32 md:h-32" }
const _hoisted_4 = {
  viewBox: "0 0 100 100",
  class: "transform -rotate-90"
}
const _hoisted_5 = ["r"]
const _hoisted_6 = ["r"]
const _hoisted_7 = { class: "absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center" }
const _hoisted_8 = { class: "text-para-xs" }
const _hoisted_9 = { class: "text-para-xs md:text-para-s my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createElementBlock("svg", _hoisted_4, [
        _createElementVNode("circle", {
          "stroke-width": "2",
          fill: "none",
          cx: "50",
          cy: "50",
          stroke: "#8FDCDA",
          r: $options.r
        }, null, 8, _hoisted_5),
        _createElementVNode("circle", {
          "stroke-width": "2",
          fill: "none",
          cx: "50",
          cy: "50",
          stroke: "#00928E",
          r: $options.r,
          style: _normalizeStyle($options.circleStyle)
        }, null, 12, _hoisted_6)
      ])),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Icon, {
          name: "wind-arrow",
          class: "w-8 h-8 md:w-10 md:h-10",
          style: _normalizeStyle({transform: `rotate(${$props.direction.toFixed(2)}deg)`})
        }, null, 8, ["style"]),
        _createElementVNode("span", _hoisted_8, _toDisplayString($props.direction) + "°", 1)
      ])
    ]),
    _createElementVNode("span", _hoisted_9, _toDisplayString($props.speed.toFixed(0)) + "km/h ", 1)
  ]))
}