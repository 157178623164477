<template>
    <ContentGroup type="tabs"
                  v-slot="{ index, type, showAll, itemsLength, componentUid }"
                  :in-content-group="inContentGroup"
    >
        <!-- Search bar -->
        <div class="bg-primary-50 my-8">
            <div :class="{'container': !inContentGroup}">
                <div class="py-6 md:py-8" :class="{'xl:w-2/3 mx-auto': !inContentGroup}">
                    <div class="relative w-full text-primary-500">
                        <input v-model="keyword"
                               type="text"
                               class="w-full rounded text-black-600 text-para-s focus:outline-none py-2 px-10 placeholder-improvedContrast"
                               :aria-label="labels.placeholder"
                               :placeholder="labels.placeholder"
                        >
                        <Icon name="search" class="absolute left-4 top-3 w-4 h-4 stroke-current" />
                        <div class="absolute right-4 top-3 cursor-pointer" @click="keyword = ''">
                            <Icon v-show="!!keyword" name="close" class="w-4 h-4 stroke-current" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContentGroupItem :title="labels.tabNewUsers" :index="0" :current-index="index" :component-uid="componentUid">
            <div :class="{'container': !inContentGroup}" class="section">
                <table class="table-fixed min-w-full">
                    <thead class="text-para-xs md:text-para-s text-left font-semibold border-b border-black-200">
                    <tr>
                        <th />
                        <th class="w-2/12 px-4 py-2">{{ labels.login }}</th>
                        <th class="w-3/12 px-4 py-2">{{ labels.email }}</th>
                        <th class="w-3/12 px-4 py-2">{{ labels.targetRoles }}</th>
                        <th class="w-4/12 px-4 py-2">
                            <div class="flex gap-8">
                                <span class="w-1/2 ">{{ labels.approve }}</span>
                                <span class="w-1/2">{{ labels.reject }}</span>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="text-para-xs md:text-para-s">
                    <template v-for="(user, index) in filteredUsers">
                        <template v-if="user && user.title && !isProcessed(user.id)">
                            <tr :key="index">
                                <td class="px-4 py-2 truncate cursor-pointer"
                                    @click="activeUser1 === user.id ? activeUser1 = '' : activeUser1 = user.id"
                                >
                                    <button type="button"
                                            class="py-3 px-3 rounded border border-transparent focus:outline-none bg-black-50 hover:bg-black-100 transition-colors duration-100 ease-out"
                                            :aria-label="activeUser1 === user.id ? $globalLabels.close : $globalLabels.open"
                                    >
                                        <Icon name="chevron-down"
                                              class="w-3 h-3 flex-shrink-0 transition-transform transform origin-center stroke-current"
                                              :class="{ 'rotate-180': activeUser1 === user.id }"
                                        />
                                    </button>
                                </td>
                                <td class="px-4 py-2 truncate cursor-pointer"
                                    @click="activeUser1 === user.id ? activeUser1 = '' : activeUser1 = user.id"
                                >
                                    {{ user.title }}
                                </td>
                                <td class="px-4 py-2 truncate">
                                    <a class="link" :href="'mailto:' + user.email">{{ user.email }}</a>
                                </td>
                                <td class="px-4 py-2">
                                    <Combobox v-model="selectedRoles[user.id]"
                                              :placeholder="labels.selectPlaceholder"
                                              :searchable="true"
                                              :search-label="labels.searchPlaceholder"
                                              :items="rolesItems"
                                    />
                                </td>
                                <td class="px-4 py-2">
                                    <div class="flex flex-wrap xl:flex-nowrap gap-x-8 gap-y-4">
                                        <GcButton class="w-full whitespace-nowrap" :disabled="!active"
                                                  :label="labels.approval" @click="approve(user.id)"
                                        />
                                        <GcButton class="w-full whitespace-nowrap" :disabled="!active"
                                                  :label="labels.reject" icon="close"
                                                  @click="reject(user.id, getRejectUrl(user.id))"
                                        />
                                    </div>
                                    <div v-if="error === 'approve-' + user.id"
                                         class="text-error text-para-xs mt-1 md:mt-2 w-full"
                                    >
                                        {{ labels.approveError }}
                                    </div>
                                    <div v-if="error === 'reject-' + user.id"
                                         class="text-error text-para-xs mt-1 md:mt-2 w-full"
                                    >
                                        {{ labels.rejectError }}
                                    </div>
                                </td>
                            </tr>
                            <tr :key="`${index}_detail`">
                                <td colspan="5">
                                    <CollapseTransition v-show="activeUser1 === user.id">
                                        <div class="px-4 pt-2 pb-4">
                                            <UserProperties :user="user" :properties="properties" />
                                        </div>
                                    </CollapseTransition>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
            </div>
        </ContentGroupItem>
        <content-group-item :title="labels.tabUserManagement" :index="1" :current-index="index"
                            :component-uid="componentUid">
            <div :class="{'container': !inContentGroup}" class="section">
                <table class="table-fixed min-w-full">
                    <thead class="text-para-xs md:text-para-s text-left font-semibold border-b border-black-200">
                    <tr>
                        <th></th>
                        <th class="w-2/12 px-4 py-2">{{ labels.login }}</th>
                        <th class="w-3/12 px-4 py-2">{{ labels.email }}</th>
                        <th class="w-5/12 px-4 py-2">{{ labels.targetRoles }}</th>
                        <th class="w-2/12 px-4 py-2">{{ labels.deletion }}</th>
                    </tr>
                    </thead>
                    <tbody class="text-para-xs md:text-para-s">
                    <template v-for="(user, index) in filteredEnabledUsers">
                        <template v-if="user && user.title && !isProcessed(user.id)">
                            <tr :key="index">
                                <td class="px-4 py-2 truncate cursor-pointer"
                                    @click="activeUser2 === user.id ? activeUser2 = '' : activeUser2 = user.id">
                                    <button type="button"
                                            class="py-3 px-3 rounded border border-transparent focus:outline-none bg-black-50 hover:bg-black-100 transition-colors duration-100 ease-out"
                                            :aria-label="activeUser2 === user.id ? $globalLabels.close : $globalLabels.open"
                                    >
                                        <Icon name="chevron-down"
                                              class="w-3 h-3 flex-shrink-0 transition-transform transform origin-center stroke-current"
                                              :class="{ 'rotate-180': activeUser2 === user.id }"/>
                                    </button>
                                </td>
                                <td class="px-4 py-2 truncate cursor-pointer"
                                    @click="activeUser2 === user.id ? activeUser2 = '' : activeUser2 = user.id">
                                    {{ user.title }}
                                </td>
                                <td class="px-4 py-2 truncate">
                                    <a class="link" :href="'mailto:' + user.email">{{ user.email }}</a>
                                </td>
                                <td class="px-4 py-2">
                                    <Combobox :placeholder="labels.selectPlaceholder"
                                              :searchable="true"
                                              :search-label="labels.searchPlaceholder"
                                              :items="rolesItems"
                                              v-model="selectedRoles[user.id]"
                                              :cta-label="labels.save"
                                              show-cta
                                              @cta="update(user.id)"/>
                                    <div v-if="error === 'update-' + user.id"
                                         class="text-error text-para-xs mt-1 md:mt-2 w-full">
                                        {{ labels.updateError }}
                                    </div>
                                </td>
                                <td class="px-4 py-2">
                                    <gc-button class="w-full whitespace-nowrap" :disabled="!active"
                                               :label="labels.delete" icon="close"
                                               @click="reject(user.id, getDeleteUrl(user.id))"/>
                                    <div v-if="error === 'reject-' + user.id"
                                         class="text-error text-para-xs mt-1 md:mt-2 w-full">
                                        {{ labels.rejectError }}
                                    </div>
                                </td>
                            </tr>
                            <tr :key="`${index}_detail`">
                                <td colspan="5">
                                    <collapse-transition v-show="activeUser2 === user.id">
                                        <div class="px-4 pt-2 pb-4">
                                            <user-properties :user="user" :properties="properties"/>
                                        </div>
                                    </collapse-transition>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
            </div>
        </content-group-item>
        <content-group-item v-if="!hideReApprovalTab" :title="labels.tabReapprovals" :index="2" :current-index="index"
                            :component-uid="componentUid">
            <div :class="{'container': !inContentGroup}" class="section">
                <table class="table-fixed min-w-full">
                    <thead class="text-para-xs md:text-para-s text-left font-semibold border-b border-black-200">
                    <tr>
                        <th class="w-3/12 px-4 py-2">{{ labels.login }}</th>
                        <th class="w-5/12 px-4 py-2">{{ labels.email }}</th>
                        <th class="w-4/12 px-4 py-2">
                            <div class="flex gap-8">
                                <span class="w-1/2 ">{{ labels.approve }}</span>
                                <span class="w-1/2">{{ labels.reject }}</span>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="text-para-xs md:text-para-s">
                    <template v-for="(user, index) in filteredUsersToBeReapproved">
                        <tr v-if="user && user.title && !isProcessed(user.id)" :key="index">
                            <td class="px-4 py-2 truncate">{{ user.title }}</td>
                            <td class="px-4 py-2 truncate">{{ user.email }}</td>
                            <td class="px-4 py-2">
                                <div class="flex flex-wrap xl:flex-nowrap gap-x-8 gap-y-4">
                                    <gc-button class="w-full whitespace-nowrap" :disabled="!active"
                                               :label="labels.approval" @click="reapprove(user.id)"/>
                                    <gc-button class="w-full whitespace-nowrap" :disabled="!active"
                                               :label="labels.reject" icon="close"
                                               @click="reject(user.id, getRejectReapproveUrl(user.id))"/>
                                </div>
                                <div v-if="error === 'approve-' + user.id"
                                     class="text-error text-para-xs mt-1 md:mt-2 w-full">
                                    {{ labels.approveError }}
                                </div>
                                <div v-if="error === 'reject-' + user.id"
                                     class="text-error text-para-xs mt-1 md:mt-2 w-full">
                                    {{ labels.rejectError }}
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </content-group-item>
    </ContentGroup>
</template>

<script lang="ts">
import { PropType } from 'vue';
import Utils from '../../utils/Utils';
import Enums from '../../utils/Enums';
import axios from 'axios';
import UserProperties from "./UserProperties.vue";
import Combobox from "../base/Combobox.vue";
import ContentGroupItem from "../contentGroup/ContentGroupItem.vue";
import Icon from "../base/Icon.vue";
import GcButton from "../base/GcButton.vue";
import ContentGroup from "../contentGroup/ContentGroup.vue";
import CollapseTransition from "../base/CollapseTransition.vue";

export interface User {
    id: string;
    title: string;
    email: string;
    contact: string;
    roles: string[];
    properties: Record<string, string>;
}

export interface Role {
    id: string;
    title: string;
}

export interface ApprovalLabels {
    placeholder: string;
    tabNewUsers: string;
    tabUserManagement: string;
    tabReapprovals: string;
    login: string;
    email: string;
    save: string;
    targetRoles: string;
    approve: string;
    reject: string;
    delete: string;
    deletion: string;
    approval: string;
    rejection: string;
    approveError: string;
    rejectError: string;
    updateError: string;
    searchPlaceholder: string;
    selectPlaceholder: string;
}

export default {
    components: {CollapseTransition, ContentGroup, GcButton, Icon, ContentGroupItem, Combobox, UserProperties},
    inject: ['$globalLabels'],
    props: {
        users: {type: Array as PropType<Array<User>>, required: true},
        enabledUsers: {type: Array as PropType<Array<User>>, required: true},
        usersToBeReapproved: {type: Array as PropType<Array<User>>, required: true},
        roles: {type: Array as PropType<Array<Role>>, required: true},
        labels: {type: Object as PropType<ApprovalLabels>, required: true},
        inContentGroup: {type: Boolean, default: false},
        hideReApprovalTab: {type: Boolean, default: false},
        properties: {
            type: Object as PropType<Record<string, string>>, default: () => {
            }
        },
        preselectRoles: {
            type: Object as PropType<Record<string, string>>, default: () => {
            }
        }
    },
    data() {
        return {
            keyword: '',
            active: true,
            selectedRoles: [],
            processedUsers: [],
            error: '',
            activeUser1: '',
            activeUser2: '',
        };
    },
    computed: {
        rolesItems(): Record<string, string> {
            const items = {};
            this.roles.forEach(r => {
                items[r.title] = r.title;
            });
            return items;
        },
        filteredUsers(): User[] {
            return this.filterUsers(this.users);
        },
        filteredEnabledUsers(): User[] {
            return this.filterUsers(this.enabledUsers);
        },
        filteredUsersToBeReapproved(): User[] {
            return this.filterUsers(this.usersToBeReapproved);
        }
    },
    mounted(): void {
        const roleList = this.roles.map(r => r.title);

        this.enabledUsers.forEach(u => {
            this.selectedRoles[u.id] = u.roles.filter(r => roleList.includes(r));
        });

        this.users.forEach(u => {
            this.selectedRoles[u.id] = this.getPreselectedRoles(u).filter(r => roleList.includes(r));
        });
    },
    methods: {
        filterUsers(users: User[]): User[] {
            if (!this.keyword) return users;
            return users.filter(user => {
                // check title, email and any custom properties
                return user.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0 ||
                    user.email.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0 ||
                    Object.keys(user.properties).some(key => user.properties[key].toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0);
            });
        },
        isProcessed(id: string): boolean {
            return this.processedUsers.indexOf(id) >= 0;
        },
        getApproveUrl(id: string) {
            return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/accept/${id}`;
        },
        getRejectUrl(id: string) {
            return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/reject/${id}`;
        },
        getReapproveUrl(id: string) {
            return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/reapprove/${id}`;
        },
        getRejectReapproveUrl(id: string) {
            return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/reject-reapproval/${id}`;
        },
        getDeleteUrl(id: string) {
            return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/delete/${id}`;
        },
        getUpdateUrl(id: string) {
            return `${Utils.getLocalStorage(Enums.STORAGE_KEY.CONTEXT_PATH)}/.rest/pur/update/${id}`;
        },
        getPreselectedRoles(user: User): string[] {
            if (!this.preselectRoles.prop) {
                return [];
            }
            const rolesStr = user.properties[this.preselectRoles.prop];
            if (!rolesStr) {
                return [];
            }
            return rolesStr.split('__')
                .map(r => this.preselectRoles.prefix + r);
        },
        async approve(id: string) {
            this.active = false;
            const data: Record<string, boolean> = {};
            if (this.selectedRoles[id]) {
                this.selectedRoles[id].forEach(r => {
                    data[r] = true;
                });
            }
            await axios.post(this.getApproveUrl(id), data)
                .then(() => {
                    this.processedUsers.push(id);
                })
                .catch(() => {
                    this.error = 'approve-' + id;
                })
                .finally(() => {
                    this.active = true;
                });
        },
        async reject(id: string, url: string) {
            this.active = false;
            await axios.post(url)
                .then(() => {
                    this.processedUsers.push(id);
                })
                .catch(() => {
                    this.error = 'reject-' + id;
                })
                .finally(() => {
                    this.active = true;
                });
        },
        async reapprove(id: string) {
            this.active = false;
            await axios.post(this.getReapproveUrl(id))
                .then(() => {
                    this.processedUsers.push(id);
                })
                .catch(() => {
                    this.error = 'approve-' + id;
                })
                .finally(() => {
                    this.active = true;
                });
        },
        async update(id: string) {
            this.active = false;
            const data: Record<string, boolean> = {};
            // first set all roles to false
            this.roles.forEach(r => {
                data[r.title] = false;
            });
            // then set all selected roles to true
            if (this.selectedRoles[id]) {
                this.selectedRoles[id].forEach(r => {
                    data[r] = true;
                });
            }
            await axios.post(this.getUpdateUrl(id), data)
                .then(() => {
                    // nothing to do here
                })
                .catch(() => {
                    this.error = 'update-' + id;
                })
                .finally(() => {
                    this.active = true;
                });
        }
    }
};
</script>
