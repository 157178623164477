import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-para-s font-semibold" }
const _hoisted_2 = { class: "whitespace-nowrap" }
const _hoisted_3 = { class: "whitespace-normal" }
const _hoisted_4 = {
  key: 0,
  "aria-hidden": "true"
}
const _hoisted_5 = {
  key: 0,
  class: "text-black-500 font-normal"
}
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    modelValue: $options.model[$props.name],
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($options.model[$props.name]) = $event)),
    name: $props.title,
    rules: $options.rules,
    as: "div",
    mode: "eager",
    disabled: $options.validationDisabled
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString($props.title), 1),
          ($props.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " *"))
            : _createCommentVNode("", true)
        ]),
        ($props.suffix)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($props.suffix), 1))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Dropdown, _mergeProps({ id: $props.name }, field, {
        modelValue: $options.model[$props.name],
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($options.model[$props.name]) = $event)),
        name: $props.name,
        title: $props.placeholder,
        value: $props.defaultValue,
        required: $props.required,
        error: errors.length > 0,
        "described-by": errors.length > 0 ? `${$props.name}-error` : '',
        white: true,
        searchable: $props.searchable,
        class: "w-full min-w-40"
      }), {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.options, (label, iValue, index) => {
            return (_openBlock(), _createBlock(_component_DropdownItem, {
              key: index,
              value: iValue,
              label: label
            }, null, 8, ["value", "label"]))
          }), 128))
        ]),
        _: 2
      }, 1040, ["id", "modelValue", "name", "title", "value", "required", "error", "described-by", "searchable"]),
      (errors.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            id: `${$props.name}-error`,
            class: "text-error text-para-xs mt-1 md:mt-2"
          }, _toDisplayString(errors[0]), 9, _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "name", "rules", "disabled"]))
}