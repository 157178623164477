<template>
    <div v-cloak
         v-show="navigationOpened"
         class="fixed left-0 top-0 right-0 bottom-0 backdrop-filter backdrop-blur z-20"
         @click="markAskClosed"
    />
</template>

<script lang="ts">
import {mapWritableState} from "pinia";
import {useNavigationStore} from "../../stores/navigation";

export default {
    computed: {
        ...mapWritableState(useNavigationStore, ['navigationOpened'])
    },
    methods: {
        markAskClosed() {
            this.navigationOpened = false;
        }
    }
};
</script>
