import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative w-full" }
const _hoisted_2 = { class: "relative w-full h-0 md:pt-114 pt-80 print:hidden" }
const _hoisted_3 = {
  key: 0,
  class: "container"
}
const _hoisted_4 = { class: "w-full xl:w-2/3 md:text-center mx-auto mt-12 md:mt-32" }
const _hoisted_5 = { class: "text-heading-2 md:text-heading-1" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-col text-para-s md:text-para-base mt-6 md:mt-4" }
const _hoisted_8 = { class: "font-black" }
const _hoisted_9 = {
  key: 1,
  class: "container"
}
const _hoisted_10 = { class: "w-full xl:w-2/3 md:text-center mx-auto mt-12 md:mt-32" }
const _hoisted_11 = { class: "text-heading-2 md:text-heading-1" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 2,
  class: "container"
}
const _hoisted_14 = { class: "w-full xl:w-2/3 md:text-center mx-auto mt-12 md:mt-32" }
const _hoisted_15 = { class: "text-heading-2 md:text-heading-1" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "flex flex-col text-para-s md:text-para-base mt-6 md:mt-4" }
const _hoisted_18 = { class: "font-black" }
const _hoisted_19 = {
  key: 3,
  class: "container"
}
const _hoisted_20 = { class: "flex justify-center w-full xl:w-2/3 mx-auto mt-12 md:mt-32" }
const _hoisted_21 = {
  key: 4,
  class: "container"
}
const _hoisted_22 = { class: "w-full xl:w-2/3 mx-auto" }
const _hoisted_23 = {
  key: 5,
  class: "container"
}
const _hoisted_24 = { class: "w-full xl:w-2/3 mx-auto mt-12 md:mt-32" }
const _hoisted_25 = { class: "text-heading-2 md:text-heading-1" }
const _hoisted_26 = { class: "text-para-s md:text-para-lg mt-4 md:mt-14" }
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 6,
  class: "container"
}
const _hoisted_29 = { key: 7 }
const _hoisted_30 = { class: "container" }
const _hoisted_31 = { class: "w-full xl:w-2/3 mx-auto mt-12 md:mt-32" }
const _hoisted_32 = { class: "text-heading-2 md:text-heading-1" }
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = { class: "bg-black-50 mt-12 md:mt-14" }
const _hoisted_35 = { class: "container" }
const _hoisted_36 = { class: "grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-8 w-full xl:w-2/3 mx-auto py-12 md:pb-18" }
const _hoisted_37 = { class: "text-para-s" }
const _hoisted_38 = { class: "text-para-s" }
const _hoisted_39 = { class: "text-para-s" }
const _hoisted_40 = { class: "w-8 min-w-8 h-8 md:w-10 md:min-w-10 md:h-10 flex justify-center items-center rounded-full transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 group-focus:bg-primary-500 group-focus:text-white group-focus:scale-110 bg-primary-50" }
const _hoisted_41 = { class: "text-para-s" }
const _hoisted_42 = {
  key: 8,
  class: "container"
}
const _hoisted_43 = { class: "flex justify-center w-full xl:w-2/3 mx-auto mt-12 md:mt-32" }
const _hoisted_44 = { class: "font-semibold text-para-lg md:text-para-xl" }
const _hoisted_45 = { class: "text-para-s md:text-para-lg mt-6 md:mt-5" }
const _hoisted_46 = { class: "grid grid-cols-1 md:grid-cols-2 gap-4 px-4 md:px-6 xl:px-32 pb-12 md:pb-24 mt-4 md:-mt-2 xl:-mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_GolfEventRegistrationDialog = _resolveComponent("GolfEventRegistrationDialog")!
  const _component_GolfEventSummary = _resolveComponent("GolfEventSummary")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_GcButton = _resolveComponent("GcButton")!
  const _component_ContentOverlay = _resolveComponent("ContentOverlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, $options.image)
      ])
    ]),
    ($data.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h2", _hoisted_5, _toDisplayString($props.labels.errorTitle), 1),
            _createElementVNode("p", {
              class: "text-para-s md:text-heading-3 mt-4 md:mt-12",
              innerHTML: $props.labels.errorHint
            }, null, 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString($data.theEvent.paName), 1),
              _createElementVNode("span", null, _toDisplayString($props.labels.mail) + ": " + _toDisplayString($data.theEvent.paMail), 1),
              _createElementVNode("span", null, _toDisplayString($props.labels.phone) + ": " + _toDisplayString($data.theEvent.paPhone), 1),
              _createElementVNode("span", null, _toDisplayString($props.labels.mobile) + ": " + _toDisplayString($data.theEvent.paMobile), 1)
            ])
          ])
        ]))
      : ($data.denied)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h2", _hoisted_11, _toDisplayString($props.labels.accessDenied), 1),
              _createElementVNode("p", {
                class: "text-para-s md:text-heading-3 mt-4 md:mt-14",
                innerHTML: $props.labels.accessDeniedDescription
              }, null, 8, _hoisted_12)
            ])
          ]))
        : ($options.registrationClosed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("h2", _hoisted_15, _toDisplayString($props.labels.registrationClosed), 1),
                _createElementVNode("p", {
                  class: "text-para-s md:text-heading-3 mt-4 md:mt-12",
                  innerHTML: $props.labels.registrationClosedHint
                }, null, 8, _hoisted_16),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString($data.theEvent.paName), 1),
                  _createElementVNode("span", null, _toDisplayString($props.labels.mail) + ": " + _toDisplayString($data.theEvent.paMail), 1),
                  _createElementVNode("span", null, _toDisplayString($props.labels.phone) + ": " + _toDisplayString($data.theEvent.paPhone), 1),
                  _createElementVNode("span", null, _toDisplayString($props.labels.mobile) + ": " + _toDisplayString($data.theEvent.paMobile), 1)
                ])
              ])
            ]))
          : ($data.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_LoadingSpinner, { class: "w-20 h-20" })
                ])
              ]))
            : ( $data.theParticipant.status === 'UNKNOWN' || $data.currentView === 'registration')
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_GolfEventRegistrationDialog, {
                      ref: "dialog",
                      labels: $props.labels,
                      onCancel: _cache[0] || (_cache[0] = ($event: any) => ($data.showCancelOverlay = true)),
                      onParticipate: $options.confirmParticipation
                    }, null, 8, ["labels", "onParticipate"])
                  ])
                ]))
              : ($data.theParticipant.status === 'CANCELLED')
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("h2", _hoisted_25, _toDisplayString($props.labels.cancellationConfirmation), 1),
                      _createElementVNode("p", _hoisted_26, _toDisplayString($props.labels.dear) + " " + _toDisplayString($data.theParticipant.firstName), 1),
                      _createElementVNode("p", {
                        class: "text-para-s md:text-para-lg mt-6",
                        innerHTML: $props.labels.cancellationConfirmationDescription
                      }, null, 8, _hoisted_27)
                    ])
                  ]))
                : ($data.theParticipant.status === 'REGISTERED_COMPLETE')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                      _createVNode(_component_GolfEventSummary, {
                        class: "w-full xl:w-2/3 mx-auto",
                        labels: $props.labels
                      }, null, 8, ["labels"])
                    ]))
                  : ($data.theParticipant.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("h2", _hoisted_32, _toDisplayString($props.labels.overview), 1),
                            _createElementVNode("p", {
                              class: "text-para-s md:text-para-xl mt-4 md:mt-2",
                              innerHTML: $props.labels.overviewDescription
                            }, null, 8, _hoisted_33)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("div", _hoisted_36, [
                              _createElementVNode("button", {
                                class: _normalizeClass(["flex md:flex-col items-center md:items-start gap-x-4 gap-y-3 py-6 px-4 md:p-6 group bg-white", {
                                'opacity-50': $data.theParticipant.registrationStep < 1,
                                'cursor-pointer': $data.theParticipant.registrationStep >= 1,
                            }]),
                                onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.goToRegistrationStep(1)))
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(["w-8 min-w-8 h-8 md:w-10 md:min-w-10 md:h-10 flex justify-center items-center rounded-full bg-primary-50", {
                                    'transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 group-focus:bg-primary-500 group-focus:text-white group-focus:scale-110':
                                        $data.theParticipant.registrationStep >= 1,
                                }])
                                }, [
                                  _createVNode(_component_Icon, {
                                    name: "edit2",
                                    class: "stroke-current flex-shrink-0 w-4 h-4"
                                  })
                                ], 2),
                                _createElementVNode("span", _hoisted_37, _toDisplayString($props.labels.editPersonalData), 1)
                              ], 2),
                              _createElementVNode("button", {
                                class: _normalizeClass(["flex md:flex-col items-center md:items-start gap-x-4 gap-y-3 py-6 px-4 md:p-6 group bg-white", {
                                'opacity-50': $data.theParticipant.registrationStep < 2,
                                'cursor-pointer': $data.theParticipant.registrationStep >= 2,
                            }]),
                                onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.goToRegistrationStep(2)))
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(["w-8 min-w-8 h-8 md:w-10 md:min-w-10 md:h-10 flex justify-center items-center rounded-full bg-primary-50", {
                                    'transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 group-focus:bg-primary-500 group-focus:text-white group-focus:scale-110':
                                        $data.theParticipant.registrationStep >= 2,
                                }])
                                }, [
                                  _createVNode(_component_Icon, {
                                    name: "edit2",
                                    class: "stroke-current flex-shrink-0 w-4 h-4"
                                  })
                                ], 2),
                                _createElementVNode("span", _hoisted_38, _toDisplayString($props.labels.editParticipation), 1)
                              ], 2),
                              _createElementVNode("button", {
                                class: _normalizeClass(["flex md:flex-col items-center md:items-start gap-x-4 gap-y-3 py-6 px-4 md:p-6 group bg-white", {
                                'opacity-50': $data.theParticipant.registrationStep < 3,
                                'cursor-pointer': $data.theParticipant.registrationStep >= 3,
                            }]),
                                onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.goToRegistrationStep(3)))
                              }, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(["w-8 min-w-8 h-8 md:w-10 md:min-w-10 md:h-10 flex justify-center items-center rounded-full bg-primary-50", {
                                    'transform transition-all group-hover:bg-primary-500 group-hover:text-white group-hover:scale-110 group-focus:bg-primary-500 group-focus:text-white group-focus:scale-110':
                                        $data.theParticipant.registrationStep >= 3,
                                }])
                                }, [
                                  _createVNode(_component_Icon, {
                                    name: "edit2",
                                    class: "stroke-current flex-shrink-0 w-4 h-4"
                                  })
                                ], 2),
                                _createElementVNode("span", _hoisted_39, _toDisplayString($props.labels.editTravelData), 1)
                              ], 2),
                              _createElementVNode("button", {
                                class: "flex md:flex-col items-center md:items-start gap-x-4 gap-y-3 py-6 px-4 md:p-6 group cursor-pointer bg-white",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => ($data.showCancelOverlay = true))
                              }, [
                                _createElementVNode("span", _hoisted_40, [
                                  _createVNode(_component_Icon, {
                                    name: "close",
                                    class: "stroke-current flex-shrink-0 w-4 h-4"
                                  })
                                ]),
                                _createElementVNode("span", _hoisted_41, _toDisplayString($props.labels.cancelParticipation), 1)
                              ])
                            ])
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_42, [
                        _createElementVNode("div", _hoisted_43, [
                          _createVNode(_component_LoadingSpinner, { class: "w-20 h-20" })
                        ])
                      ])),
    _createVNode(_component_ContentOverlay, {
      modelValue: $data.showCancelOverlay,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.showCancelOverlay) = $event)),
      narrow: "",
      onHide: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$refs.dialog ? (_ctx.$refs.dialog.yesNoModel = []) : undefined))
    }, {
      buttons: _withCtx(() => [
        _createElementVNode("div", _hoisted_46, [
          _createVNode(_component_GcButton, {
            class: "whitespace-nowrap",
            label: $props.labels.cancellationYes,
            secondary: "",
            onClick: $options.cancelParticipation
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_GcButton, {
            class: "whitespace-nowrap",
            label: $props.labels.cancellationNo,
            secondary: "",
            onClick: _cache[5] || (_cache[5] = ($event: any) => ($data.showCancelOverlay = false))
          }, null, 8, ["label"])
        ])
      ]),
      default: _withCtx(() => [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "h-4 md:h-14" }, null, -1)),
        _createElementVNode("h4", _hoisted_44, _toDisplayString($props.labels.cancellation), 1),
        _createElementVNode("p", _hoisted_45, _toDisplayString($props.labels.cancellationDescription), 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}