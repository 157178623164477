import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mr-2" }
const _hoisted_2 = { class: "w-4 h-5 text-white flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocioEconomicMapDetail = _resolveComponent("SocioEconomicMapDetail")!
  const _component_SocioEconomicMapEmbed = _resolveComponent("SocioEconomicMapEmbed")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SocioEconomicMapFiltering = _resolveComponent("SocioEconomicMapFiltering")!
  const _component_SocioEconomicMapLegend = _resolveComponent("SocioEconomicMapLegend")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative overflow-hidden xl:overflow-visible", { 'overflow-visible': $data.filteringOpen }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative flex flex-wrap xl:flex-row overflow-hidden xl:overflow-visible", { 'overflow-visible': $data.filteringOpen }])
    }, [
      _createVNode(_component_SocioEconomicMapDetail, {
        ref: "detail",
        "section-data": $props.sectionData,
        "section-disclaimer": $props.sectionDisclaimer,
        operations: $props.operations,
        region: $data.currentRegion,
        operation: $data.currentOperation,
        "download-factsheet-label": $options.translate('downloadFactsheet'),
        "operations-label": $options.translate('operations'),
        "show-more-label": $options.translate('showMore'),
        "show-less-label": $options.translate('showLess'),
        "scroll-effect-level": $data.scrollEffectLevel,
        onSetOperation: $options.setOperation
      }, null, 8, ["section-data", "section-disclaimer", "operations", "region", "operation", "download-factsheet-label", "operations-label", "show-more-label", "show-less-label", "scroll-effect-level", "onSetOperation"]),
      _withDirectives(_createElementVNode("div", {
        ref: "map",
        class: "map relative w-full order-1 md:order-2 md:w-2/3 xl:w-3/4 h-socio-economic-map md:h-120 xl:h-180",
        style: _normalizeStyle($data.specialMapClasses)
      }, [
        ($options.filteredOperations)
          ? (_openBlock(), _createBlock(_component_SocioEconomicMapEmbed, {
              key: 0,
              country: 'australia',
              "current-region": $data.currentRegion,
              "current-operation": $data.currentOperation,
              operations: $options.filteredOperations,
              "all-operations": $props.operations,
              "selected-marker": $data.selectedMarker,
              "region-option": $data.regionOption,
              onOpenFiltering: _cache[0] || (_cache[0] = ($event: any) => ($data.filteringOpen = true)),
              onMarkerClicked: $options.handleMarkerClicked,
              onMapInitialized: $options.makeMapVisible
            }, null, 8, ["current-region", "current-operation", "operations", "all-operations", "selected-marker", "region-option", "onMarkerClicked", "onMapInitialized"]))
          : _createCommentVNode("", true),
        (!$data.filteringOpen)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: "py-2 px-5 cursor-pointer rounded flex text-para-xs md:text-para-s items-center justify-center bg-black-900 text-white absolute top-5 left-5 md:right-auto md:left-8 md:bottom-auto md:top-8",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.openFiltering && $options.openFiltering(...args)))
            }, [
              _createElementVNode("span", _hoisted_1, _toDisplayString($options.translate('filter')) + " " + _toDisplayString($data.currentRegion !== null || $data.currentOperation !== null ? '(1)' : ''), 1),
              _createElementVNode("span", _hoisted_2, [
                _createVNode(_component_Icon, {
                  name: "filter",
                  class: "fill-current w-full h-full"
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_SocioEconomicMapFiltering, {
          operations: $props.operations,
          regions: $props.regions,
          "current-region": $data.currentRegion,
          "current-operation": $data.currentOperation,
          "region-label": $options.translate('region'),
          "reset-filter-label": $options.translate('resetFilter'),
          "operation-label": $options.translate('operation'),
          "search-label": $options.translate('search'),
          "filter-by-label": $options.translate('filterBy'),
          onCloseFiltering: _cache[2] || (_cache[2] = ($event: any) => ($data.filteringOpen = false)),
          onSetRegion: $options.setRegion,
          onSetOperation: $options.setOperation,
          onUnset: $options.unsetQuery
        }, null, 8, ["operations", "regions", "current-region", "current-operation", "region-label", "reset-filter-label", "operation-label", "search-label", "filter-by-label", "onSetRegion", "onSetOperation", "onUnset"]), [
          [_vShow, $data.filteringOpen]
        ])
      ], 4), [
        [_vShow, $data.showMap]
      ])
    ], 2),
    _createVNode(_component_SocioEconomicMapLegend, {
      operations: $props.operations,
      label: $options.translate('legend')
    }, null, 8, ["operations", "label"])
  ], 2))
}