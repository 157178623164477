import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  ref: "observable",
  class: "relative w-full h-full bg-white overflow-hidden mb-1 group border border-transparent hover:border-primary-500 transition-all duration-300 ease-out"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "px-6 pt-4 pb-6" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative min-h-30 overflow-hidden", [!$options.isVertical ? 'h-60 md:h-auto' : '']])
    }, [
      ($options.hasImage && $data.show)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: "",
            class: _normalizeClass(["object-cover w-full transform group-hover:scale-110 transition-all duration-300 ease-out", [!$options.isVertical ? 'h-full md:h-auto' : 'h-auto']]),
            src: $props.data.asset || $props.data.url,
            loading: "lazy",
            onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.imageLoaded && $options.imageLoaded(...args)))
          }, null, 42, _hoisted_2))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute left-0 top-0 w-full h-full group-hover:bg-none bg-gradient-to-b opacity-40 from-black-900 pointer-events-none" }, null, -1))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute left-6 top-6 right-6 flex flex-row text-para-xs group-hover:text-primary-500", $options.classes])
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("svg", {
        class: "w-4 h-4 fill-current mr-2 transition-all duration-300 ease-out",
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", { d: "M22.786 0H1.296C.557 0 0 .554 0 1.292v21.323C0 23.446.556 24 1.297 24h11.578v-9.323h-3.15v-3.6h3.15V8.4c0-3.138 1.945-4.8 4.631-4.8 1.297 0 2.501.092 2.779.185v3.23H18.34c-1.482 0-1.76.739-1.76 1.754v2.308h3.612l-.463 3.6h-3.15V24h6.114c.741 0 1.297-.554 1.297-1.292V1.292C24.083.554 23.527 0 22.786 0z" })
      ], -1)),
      ($props.data.accountName)
        ? (_openBlock(), _createElementBlock("h6", {
            key: 0,
            class: _normalizeClass([{'invisible': !$options.showChannel}, "flex-grow font-semibold transition-all duration-300 ease-out"])
          }, _toDisplayString($props.data.accountName), 3))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: _normalizeClass(["transition-all duration-300 ease-out", $options.differenceClasses])
      }, _toDisplayString($props.data.difference), 3)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", {
        class: "text-para-s tracking-tight line-clamp-6",
        innerHTML: $props.data.caption
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("a", {
      href: $props.data.link,
      class: "absolute left-0 right-0 top-0 bottom-0",
      "aria-label": $props.data.accountName,
      target: "_blank"
    }, null, 8, _hoisted_5)
  ], 512))
}