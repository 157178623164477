import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex justify-start" }
const _hoisted_2 = { class: "relative flex items-center gap-2" }
const _hoisted_3 = {
  id: "share_label",
  class: "text-para-xs"
}
const _hoisted_4 = { class: "absolute left-full flex items-center gap-2 ml-2" }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SharingIcon = _resolveComponent("SharingIcon")!
  const _component_share_network = _resolveComponent("share-network")!
  const _component_CollapseTransition = _resolveComponent("CollapseTransition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString($props.label), 1),
      _createElementVNode("button", {
        class: _normalizeClass(["flex justify-center items-center w-8 h-8 rounded-full cursor-pointer hover:bg-primary-600 hover:text-white focus:bg-primary-600 focus:text-white", {'bg-black-50 text-black-900': !$data.opened, 'bg-primary-600 text-white': $data.opened}]),
        "aria-labelledby": "share_label",
        tabindex: "0",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($data.opened = !$data.opened))
      }, [
        _createVNode(_component_Icon, {
          name: "social-share",
          class: "w-4 h-4 fill-current stroke-current"
        })
      ], 2),
      _createVNode(_component_CollapseTransition, { dimension: "width" }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.networks, (network) => {
              return (_openBlock(), _createElementBlock("li", {
                key: network.network,
                class: "group"
              }, [
                (network.isNetwork)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      "aria-label": network.label,
                      type: "button",
                      class: "block"
                    }, [
                      _createVNode(_component_share_network, {
                        network: network.network,
                        url: $props.pageUrl,
                        title: $props.pageTitle,
                        description: $props.description,
                        quote: $props.quote,
                        hashtags: $props.hashtags,
                        "twitter-user": network.twitterUser
                      }, {
                        default: _withCtx(({ share }) => [
                          _createVNode(_component_SharingIcon, {
                            network: network.network,
                            onClick: share
                          }, null, 8, ["network", "onClick"])
                        ]),
                        _: 2
                      }, 1032, ["network", "url", "title", "description", "quote", "hashtags", "twitter-user"])
                    ], 8, _hoisted_5))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      href: network.url,
                      "aria-label": network.label,
                      target: "_blank"
                    }, [
                      _createVNode(_component_SharingIcon, {
                        network: network.network
                      }, null, 8, ["network"])
                    ], 8, _hoisted_6))
              ]))
            }), 128))
          ], 512), [
            [_vShow, $data.opened]
          ])
        ]),
        _: 1
      })
    ])
  ]))
}