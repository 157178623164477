import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "relative container" }
const _hoisted_2 = { class: "xl:w-2/3 mx-auto bg-white py-4 md:py-6 px-2 md:px-28" }
const _hoisted_3 = ["onSubmit"]
const _hoisted_4 = {
  key: 0,
  class: "text-heading-3 md:text-heading-2 mt-10"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "text-black-600 text-para-xs ml-4" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col gap-6 w-full mb-4"
}
const _hoisted_8 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_9 = {
  for: "commodity",
  class: "md:w-48"
}
const _hoisted_10 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "text-para-s font-semibold" }
const _hoisted_13 = {
  key: 2,
  class: "flex flex-col gap-6 w-full mb-4"
}
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_16 = {
  for: "firstName",
  class: "md:w-48"
}
const _hoisted_17 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_18 = ["aria-invalid", "aria-describedby"]
const _hoisted_19 = {
  key: 1,
  id: "firstName-error",
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_20 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_21 = {
  for: "lastName",
  class: "md:w-48"
}
const _hoisted_22 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_23 = ["aria-invalid", "aria-describedby"]
const _hoisted_24 = {
  key: 1,
  id: "lastName-error",
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_25 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_26 = {
  for: "company",
  class: "md:w-48"
}
const _hoisted_27 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_28 = ["aria-invalid", "aria-describedby"]
const _hoisted_29 = {
  key: 1,
  id: "company-error",
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_30 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_31 = {
  for: "email",
  class: "md:w-48"
}
const _hoisted_32 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_33 = ["aria-invalid", "aria-describedby", "placeholder"]
const _hoisted_34 = {
  key: 1,
  id: "email-error",
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_35 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_36 = {
  for: "country",
  class: "md:w-48"
}
const _hoisted_37 = { class: "relative w-full max-w-full md:max-w-8/12" }
const _hoisted_38 = { class: "flex flex-col md:flex-row flex-wrap md:flex-nowrap gap-x-4 gap-y-2 justify-between md:items-center" }
const _hoisted_39 = { class: "flex relative w-full max-w-full md:ml-1/3 md:max-w-8/12" }
const _hoisted_40 = ["aria-invalid", "aria-describedby"]
const _hoisted_41 = ["innerHTML"]
const _hoisted_42 = {
  key: 0,
  id: "acceptPrivacy-error",
  class: "text-error text-para-xs mt-1 md:mt-2"
}
const _hoisted_43 = ["onClick"]
const _hoisted_44 = { class: "text-para-s font-semibold" }
const _hoisted_45 = {
  key: 1,
  class: "relative w-full py-11 flex flex-col"
}
const _hoisted_46 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_47 = { class: "flex flex-col py-4" }
const _hoisted_48 = {
  key: 0,
  class: "text-para-s text-error text-center mt-6"
}
const _hoisted_49 = { class: "text-black-600 text-para-xs ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiStepFormHeader = _resolveComponent("MultiStepFormHeader")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CalculatorInput = _resolveComponent("CalculatorInput")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Form, { as: "div" }, {
        default: _withCtx(({ handleSubmit }) => [
          ($data.step < 2)
            ? (_openBlock(), _createElementBlock("form", {
                key: 0,
                onSubmit: _withModifiers(($event: any) => (handleSubmit($options.submit)), ["prevent"])
              }, [
                ($props.title)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString($props.title), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "mt-4 mb-12 prose md:prose-md lg:prose-lg",
                  innerHTML: $props.intro
                }, null, 8, _hoisted_5),
                _createVNode(_component_MultiStepFormHeader, {
                  steps: $options.stepHeader,
                  "current-step": $data.step
                }, null, 8, ["steps", "current-step"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex items-center cursor-pointer w-fit mb-6", [ $options.showBackNavigation ? '' : 'invisible' ]]),
                  tabindex: "0",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.back && $options.back(...args))),
                  onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => ($options.back && $options.back(...args)), ["enter"]))
                }, [
                  _createVNode(_component_Icon, {
                    name: "chevron-left",
                    class: "w-3 h-3 text-black-300 stroke-current"
                  }),
                  _createElementVNode("span", _hoisted_6, _toDisplayString($props.labelBack), 1)
                ], 34),
                ($data.step === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("label", _hoisted_9, _toDisplayString($options.translate('commodity')), 1),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_Dropdown, {
                            id: "commodity",
                            modelValue: $data.commodity,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.commodity) = $event)),
                            title: $props.commodities.find(x => x.value === $data.commodity).label,
                            white: true,
                            disabled: $data.step > 0,
                            class: "w-full md:w-auto min-w-40"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.commodities, (c, i) => {
                                return (_openBlock(), _createBlock(_component_DropdownItem, {
                                  key: i,
                                  value: c.value,
                                  label: c.label
                                }, null, 8, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "title", "disabled"])
                        ])
                      ]),
                      _createVNode(_component_CalculatorInput, {
                        modelValue: $data.f80,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.f80) = $event)),
                        name: "f80",
                        values: $data.f80Values,
                        label: $options.translate('f80'),
                        type: $options.DutyFieldType.RANGE_SLIDER,
                        interval: 5
                      }, null, 8, ["modelValue", "values", "label", "type"]),
                      _createVNode(_component_CalculatorInput, {
                        modelValue: $data.p80,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($data.p80) = $event)),
                        name: "p80",
                        values: $data.p80Values,
                        dependency: x => x <= $data.f80,
                        label: $options.translate('p80'),
                        type: $options.DutyFieldType.RANGE_SLIDER,
                        interval: 1,
                        observable: $data.f80
                      }, null, 8, ["modelValue", "values", "dependency", "label", "type", "observable"]),
                      _createVNode(_component_CalculatorInput, {
                        modelValue: $data.throughput,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($data.throughput) = $event)),
                        name: "throughput",
                        values: $data.tphValues,
                        label: $options.translate('throughput'),
                        type: $options.DutyFieldType.RANGE_SLIDER,
                        interval: 1
                      }, null, 8, ["modelValue", "values", "label", "type"]),
                      _createElementVNode("button", {
                        class: "flex justify-end items-center group cursor-pointer appearance-none focus:outline-none",
                        onClick: _withModifiers(($event: any) => (handleSubmit($options.submit)), ["prevent"])
                      }, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString($props.labelNext), 1),
                        _createVNode(_component_ArrowButton, {
                          direction: "right",
                          class: "w-10 h-10"
                        })
                      ], 8, _hoisted_11)
                    ]))
                  : _createCommentVNode("", true),
                ($data.step === 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      ($props.sendReportDescription)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "mb-4",
                            innerHTML: $props.sendReportDescription
                          }, null, 8, _hoisted_14))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("label", _hoisted_16, _toDisplayString($props.labelFirstName), 1),
                        _createElementVNode("div", _hoisted_17, [
                          _createVNode(_component_Field, {
                            modelValue: $data.firstName,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($data.firstName) = $event)),
                            vid: "firstName",
                            name: $props.labelFirstName,
                            rules: "required"
                          }, {
                            default: _withCtx(({ field, errors, value, meta }) => [
                              _createElementVNode("input", _mergeProps({
                                id: "firstName",
                                type: "text",
                                class: ["w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white", [ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']],
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'firstName-error' : ''
                              }, field, { "data-vv-scope": "contact" }), null, 16, _hoisted_18),
                              ($options.getIcon(errors, meta.valid))
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: $options.getIcon(errors, meta.valid),
                                    class: "absolute w-4 h-10 md:h-11 right-4 top-px"
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(errors[0]), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "name"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("label", _hoisted_21, _toDisplayString($props.labelLastName), 1),
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_Field, {
                            modelValue: $data.lastName,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.lastName) = $event)),
                            vid: "lastName",
                            name: $props.labelLastName,
                            rules: "required"
                          }, {
                            default: _withCtx(({ field, errors, value, meta }) => [
                              _createElementVNode("input", _mergeProps({
                                id: "lastName",
                                type: "text",
                                class: ["w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white", [ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']],
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'lastName-error' : ''
                              }, field, { "data-vv-scope": "contact" }), null, 16, _hoisted_23),
                              ($options.getIcon(errors, meta.valid))
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: $options.getIcon(errors, meta.valid),
                                    class: "absolute w-4 h-10 md:h-11 right-4 top-px"
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(errors[0]), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "name"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("label", _hoisted_26, _toDisplayString($props.labelCompany), 1),
                        _createElementVNode("div", _hoisted_27, [
                          _createVNode(_component_Field, {
                            modelValue: $data.company,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($data.company) = $event)),
                            vid: "company",
                            rules: "required",
                            name: $props.labelCompany
                          }, {
                            default: _withCtx(({ field, errors, value, meta }) => [
                              _createElementVNode("input", _mergeProps({ id: "company" }, field, {
                                type: "text",
                                class: ["w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white", [ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']],
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'company-error' : '',
                                "data-vv-scope": "company"
                              }), null, 16, _hoisted_28),
                              ($options.getIcon(errors, meta.valid))
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: $options.getIcon(errors, meta.valid),
                                    class: "absolute w-4 h-10 md:h-11 right-4 top-px"
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(errors[0]), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "name"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("label", _hoisted_31, _toDisplayString($props.labelEmail), 1),
                        _createElementVNode("div", _hoisted_32, [
                          _createVNode(_component_Field, {
                            modelValue: $data.email,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($data.email) = $event)),
                            vid: "email",
                            name: $props.labelEmail,
                            rules: { required: true, email: true }
                          }, {
                            default: _withCtx(({ field, errors, value, meta }) => [
                              _createElementVNode("input", _mergeProps({ id: "email" }, field, {
                                type: "email",
                                class: ["w-full text-black-900 text-para-xs md:text-para-s border focus:border-primary-500 rounded focus:outline-none pr-10 py-3 md:py-2.5 pl-4 border-black-100 bg-white placeholder-improvedContrast", [ errors.length > 0 ? 'border-error' : meta.valid ? 'md:border-black-900' : '']],
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'email-error' : '',
                                "data-vv-scope": "contact",
                                placeholder: $props.labelEmailPlaceholder
                              }), null, 16, _hoisted_33),
                              ($options.getIcon(errors, meta.valid))
                                ? (_openBlock(), _createBlock(_component_Icon, {
                                    key: 0,
                                    name: $options.getIcon(errors, meta.valid),
                                    class: "absolute w-4 h-10 md:h-11 right-4 top-px"
                                  }, null, 8, ["name"]))
                                : _createCommentVNode("", true),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(errors[0]), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "name"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createElementVNode("label", _hoisted_36, _toDisplayString($props.labelCountry), 1),
                        _createElementVNode("div", _hoisted_37, [
                          _createVNode(_component_Dropdown, {
                            id: "country",
                            modelValue: $data.country,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($data.country) = $event)),
                            name: "country",
                            white: true,
                            disabled: $data.step !== 1,
                            searchable: true,
                            title: $options.countryOptions.find(x => x.value === $data.country) ? $options.countryOptions.find(x => x.value === $data.country).label : '',
                            class: "w-full md:w-auto min-w-40"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.countryOptions, (c, i) => {
                                return (_openBlock(), _createBlock(_component_DropdownItem, {
                                  key: i,
                                  value: c.value,
                                  label: c.label
                                }, null, 8, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "disabled", "title"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_38, [
                        _createElementVNode("div", _hoisted_39, [
                          _createVNode(_component_Field, {
                            modelValue: $data.model,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($data.model) = $event)),
                            vid: "acceptPrivacy",
                            rules: "required",
                            name: "labelPrivacy"
                          }, {
                            default: _withCtx(({ field, errors }) => [
                              _createElementVNode("input", _mergeProps({ id: "acceptPrivacy" }, field, {
                                value: true,
                                type: "checkbox",
                                "aria-required": "true",
                                "aria-invalid": errors.length > 0,
                                "aria-describedby": errors.length > 0 ? 'acceptPrivacy-error' : '',
                                "data-vv-scope": "contact",
                                class: "absolute opacity-0 text-black-900 text-para-xs md:text-para-s",
                                name: "labelPrivacy"
                              }), null, 16, _hoisted_40),
                              _createElementVNode("label", {
                                class: "inline-block relative pl-6 checkbox-label-calculator",
                                for: "acceptPrivacy",
                                innerHTML: $props.privacy
                              }, null, 8, _hoisted_41),
                              (errors.length > 0)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_42, _toDisplayString($props.errorAcceptPrivacy), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("button", {
                        class: "flex justify-end items-center group cursor-pointer appearance-none focus:outline-none",
                        onClick: _withModifiers(($event: any) => (handleSubmit($options.submit)), ["prevent"])
                      }, [
                        _createElementVNode("span", _hoisted_44, _toDisplayString($props.labelRequest), 1),
                        _createVNode(_component_ArrowButton, {
                          direction: "right",
                          class: "w-10 h-10"
                        })
                      ], 8, _hoisted_43)
                    ]))
                  : _createCommentVNode("", true)
              ], 40, _hoisted_3))
            : (_openBlock(), _createElementBlock("div", _hoisted_45, [
                ($props.title)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_46, _toDisplayString($props.title), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_47, [
                  ($data.httpError)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_48, _toDisplayString($data.httpError), 1))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("span", null, _toDisplayString($props.confirmation1), 1),
                        _createElementVNode("span", null, _toDisplayString($props.confirmation2), 1)
                      ], 64))
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex items-center cursor-pointer w-fit mt-4", [ $data.step === 0 ? 'hidden md:block md:invisible' : 'visible' ]]),
                  onClick: _cache[12] || (_cache[12] = ($event: any) => ($data.step--))
                }, [
                  _createVNode(_component_Icon, {
                    name: "chevron-left",
                    class: "w-3 h-3 text-black-300 stroke-current"
                  }),
                  _createElementVNode("span", _hoisted_49, _toDisplayString($props.labelBack), 1)
                ], 2)
              ]))
        ]),
        _: 1
      })
    ])
  ]))
}