import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "for"]
const _hoisted_2 = { class: "font-semibold text-para-xs md:text-para-s" }
const _hoisted_3 = {
  key: 0,
  "aria-hidden": "true",
  title: "required"
}
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id"]
const _hoisted_10 = ["role"]
const _hoisted_11 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_12 = { class: "font-semibold text-para-xs md:text-para-s" }
const _hoisted_13 = {
  key: 0,
  "aria-hidden": "true",
  title: "required"
}
const _hoisted_14 = ["id", "name", "type", "value", "aria-invalid", "aria-describedby"]
const _hoisted_15 = ["for"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Combobox = _resolveComponent("Combobox")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    ref: "field",
    modelValue: $data.model,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.model) = $event)),
    name: $props.title,
    rules: $options.rules,
    disabled: $props.disabled,
    as: "div"
  }, {
    default: _withCtx(({ field, errors, meta }) => [
      (!$props.hideTitle && ($props.type === 'select' || $props.type === 'combobox'))
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            id: `${$props.name}-label`,
            for: $data.labelFor
          }, [
            _createElementVNode("span", _hoisted_2, [
              _createTextVNode(_toDisplayString($props.title) + " ", 1),
              ($props.required)
                ? (_openBlock(), _createElementBlock("dfn", _hoisted_3, _toDisplayString($props.requiredSymbol), 1))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({'mt-2': !$props.title})
      }, [
        ($props.type === 'select')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Dropdown, _mergeProps(field, {
                modelValue: $data.model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.model) = $event)),
                name: $props.name,
                title: $props.placeholder,
                required: $props.required,
                error: errors.length > 0,
                "described-by": $options.getDescribedById(errors),
                passed: meta.validated && meta.valid,
                "text-classes": "text-para-xs md:text-para-s h-4 md:h-6",
                searchable: $props.searchable,
                "higher-menu": "",
                "default-value": $props.defaultValue,
                disabled: $props.disabled
              }), {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.options, (label, iValue, index) => {
                    return (_openBlock(), _createBlock(_component_DropdownItem, {
                      key: index,
                      "text-classes": "text-para-xs md:text-para-s",
                      value: iValue,
                      label: label
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 2
              }, 1040, ["modelValue", "name", "title", "required", "error", "described-by", "passed", "searchable", "default-value", "disabled"]),
              (errors.length > 0)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    id: `${$props.name}-error`,
                    class: "text-error text-para-xs mt-1 md:mt-2"
                  }, _toDisplayString(errors[0]), 9, _hoisted_5))
                : ($props.description)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      id: `${$props.name}-description`,
                      class: "text-para-xs mt-1 md:mt-2"
                    }, _toDisplayString($props.description), 9, _hoisted_6))
                  : _createCommentVNode("", true)
            ]))
          : ($props.type === 'combobox')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_Combobox, _mergeProps(field, {
                  modelValue: $data.model,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.model) = $event)),
                  name: $props.name,
                  items: $options.options,
                  placeholder: $props.placeholder,
                  searchable: $props.searchable,
                  "pill-items": $props.pillItems
                }), null, 16, ["modelValue", "name", "items", "placeholder", "searchable", "pill-items"]),
                (errors.length > 0)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      id: `${$props.name}-error`,
                      class: "text-error text-para-xs mt-1 md:mt-2"
                    }, _toDisplayString(errors[0]), 9, _hoisted_8))
                  : ($props.description)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        id: `${$props.name}-description`,
                        class: "text-para-xs mt-1 md:mt-2"
                      }, _toDisplayString($props.description), 9, _hoisted_9))
                    : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("fieldset", {
                key: 2,
                role: $props.type === 'radio' ? 'radiogroup' : '',
                class: _normalizeClass(["flex flex-col gap-y-2 gap-x-4 mt-2", {'md:flex-row flex-wrap': $props.horizontal}])
              }, [
                (!$props.hideTitle)
                  ? (_openBlock(), _createElementBlock("legend", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, [
                        _createTextVNode(_toDisplayString($props.title) + " ", 1),
                        ($props.required)
                          ? (_openBlock(), _createElementBlock("dfn", _hoisted_13, _toDisplayString($props.requiredSymbol), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.options, (label, iValue, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "flex flex-row items-center"
                  }, [
                    _createElementVNode("input", _mergeProps({ ref_for: true }, field, {
                      id: $options.isSingle? $props.name : `${$props.name}-${index}`,
                      name: $options.isSingle? $props.name : `${$props.name}-${index}`,
                      class: "absolute opacity-0 text-black-900 text-para-xs md:text-para-s",
                      type: $props.type,
                      value: iValue,
                      "aria-invalid": errors.length > 0,
                      "aria-describedby": errors.length > 0 ? `${$props.name}-error` : ''
                    }), null, 16, _hoisted_14),
                    _createElementVNode("label", {
                      for: $options.isSingle? $props.name : `${$props.name}-${index}`,
                      class: _normalizeClass(["relative", $props.type + '-label'])
                    }, [
                      _createElementVNode("span", {
                        class: "text-para-xs pl-5 md:pl-6",
                        innerHTML: label
                      }, null, 8, _hoisted_16)
                    ], 10, _hoisted_15)
                  ]))
                }), 128)),
                (errors.length > 0)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      id: `${$props.name}-error`,
                      class: "text-error text-para-xs mt-1 md:mt-2"
                    }, _toDisplayString(errors[0]), 9, _hoisted_17))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_10))
      ], 2)
    ]),
    _: 1
  }, 8, ["modelValue", "name", "rules", "disabled"]))
}