<template>
    <div>
        <slot :opened="opened" :toggle="toggle" />
    </div>
</template>

<script lang="ts">

export default {
    props: {
        currentUri: {type: String},
        targetUri: {type: String}
    },
    data() {
        return {
            opened: false
        };
    },
    computed: {
        isActiveOrTrail(): boolean {
            return !!this.targetUri && !!this.currentUri && this.currentUri.startsWith(this.targetUri);
        }
    },
    watch: {
        async opened(newVal: boolean) {
            if (newVal) {
                this.openParent();
            }
        }
    },
    created(): void {
        this.opened = this.isActiveOrTrail;
        if (this.opened) {
            this.openParent();
        }
    },
    methods: {
        toggle(): void {
            this.opened = !this.opened;
        },
        openParent(): void {
            // simple as is: if the nav item is opened, propagate it to parent navigation item (if applicable)
            // necessary since the isTrailOrActive won't work with sub sites
            if (typeof (this.$parent.$parent as any).opened === 'boolean' && !(this.$parent.$parent as any).opened) {
                (this.$parent.$parent as any).opened = true;
            }
        }
    }
};
</script>
