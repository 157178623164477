<template>
    <div
        class="relative flex flex-wrap xl:flex-nowrap xl:flex-row-reverse overflow-hidden xl:overflow-visible"
    >
        <MapDetail
            v-if="!showOfficeSection"
            :location="selectedMarker"
            :label-visit-website="labelVisitWebsite"
            :hide-categories="hideCategories"
            @close-location="closeLocation"
        />
        <MapEmbed
            :markers="mapData"
            :region-option="regionOption"
            :is-world-map="isWorldMap"
            :selected-marker="selectedMarker"
            :hide-categories="!showCategories"
            :is-office-map="dataSource === 'offices'"
            @open-location="openLocation"
            @office-select="setOffice"
        />
        <MapCategories
            v-if="showCategories"
            :categories="categories"
            :label-show-all="labelShowAll"
            :label-search="labelSearch"
            :label-back="labelBack"
            :search-entries="searchEntries"
            @filter-update="filter"
            @searched="openLocationByUUID"
        />
        <MapOffice
            v-if="showOfficeSection"
            :offices="points"
            :selected-office="selectedMarker"
            :label-select-office="labelSelectOffice"
            :label-phone="labelPhone"
            :label-mobile="labelMobile"
            :label-fax="labelFax"
            @office-select="setOffice"
        />
    </div>
</template>

<script lang="ts">
import Utils from "../../utils/Utils";
import MapOffice from "./MapOffice.vue";
import MapCategories from "./MapCategories.vue";
import MapEmbed from "./MapEmbed.vue";
import MapDetail from "./MapDetail.vue";
import {PropType} from "vue";

export interface Position {
    lat: number;
    lng: number;
}

export interface Office {
    position: Position;
    title: string;
    area: string;
    fullAddress: string;
    phoneNumber: string;
    mobileNumber: string;
    faxNumber: string;
    email: string;
    images: Record<string, string>;
    defaultOffice: boolean;
}

export default {
    components: { MapDetail, MapEmbed, MapCategories, MapOffice },
    props: {
        hideCategories: { type: Boolean, default: false },
        points: {type: Array as PropType<Array<Office>>, default: () => []},
        categories: { type: Object },
        region: { type: Array, default: () => [] },
        labelVisitWebsite: { type: String, default: "" },
        labelShowAll: { type: String, default: "" },
        isOfficeMap: { type: Boolean, default: false },
        dataSource: { type: String, default: "" },
        labelSelectOffice: { type: String, default: "" },
        labelPhone: { type: String, default: "" },
        labelMobile: { type: String, default: "" },
        labelFax: { type: String, default: "" },
        labelSearch: { type: String, default: "" },
        labelBack: { type: String, default: "" },
    },
    data() {
        return {
            mapData: [],
            markers: [],
            regionOption: {
                position: {
                    lat: null,
                    lng: null,
                },
                zoom: null,
            },
            isWorldMap: true,
            selectedMarker: null,
        };
    },
    computed: {
        filteredPoints(): any[] {
            return this.points.filter((maker) =>
                Utils.checkLatLng(maker.position)
            );
        },
        showOfficeSection(): boolean {
            return this.dataSource === "offices" && this.isOfficeMap;
        },
        showCategories(): boolean {
            return !this.hideCategories && !this.showOfficeSection;
        },
        searchEntries(): Record<string, string> {
            return Object.assign.apply(
                null,
                this.points.map((p) => {
                    return { [p.uuid]: p.label };
                })
            );
        },
    },
    created() {
        this.markers = this.filteredPoints;

        if (this.region.length) {
            /* eslint-disable */
            // @ts-ignore
            const { position: { lat, lng } = {}, zoom = 0 } = this.region[0];
            this.regionOption.zoom = zoom;
            this.regionOption.position.lat = lat as number;
            this.regionOption.position.lng = lng as number;
            this.mapData = this.filteredPoints;
            this.isWorldMap = false;
            /* eslint-enable */
        }

        this.mapData = this.filteredPoints;
    },
    methods: {
        openLocationByUUID(uuid: string): void {
            const marker: any = this.points.find((p) => p.uuid === uuid);
            if (marker) {
                this.mapData = [marker];
            }
        },
        openLocation(marker): void {
            Utils.toggleScrollLock("body", "mobile-only");
            this.selectedMarker = marker;
        },
        closeLocation(): void {
            Utils.toggleScrollLock("body", "mobile-only");
            this.selectedMarker = null;
        },
        filter(selectedCategories): void {
            const markers = this.points.map((point) => {
                return { ...point };
            });
            if (selectedCategories.length > 0) {
                const listOfMarkers = markers
                    .filter((marker) => {
                        let result = false;
                        selectedCategories.forEach((selectedCategory) => {
                            if (
                                selectedCategory.uuid === marker.category.uuid
                            ) {
                                result = true;
                            }
                            marker.category.subCategories.forEach(
                                (markerSubCategory) => {
                                    if (
                                        markerSubCategory.uuid ===
                                        selectedCategory.uuid
                                    ) {
                                        result = true;
                                    }
                                }
                            );
                        });

                        return result && Utils.checkLatLng(marker.position);
                    })
                    .map((marker) => {
                        return {
                            ...marker,
                            position: {
                                lat: marker.position.lat as number,
                                lng: marker.position.lng as number,
                            },
                        };
                    });
                this.mapData = listOfMarkers;
            } else {
                this.mapData = this.filteredPoints;
            }
        },
        setOffice(office: Office): void {
            this.mapData = this.points.filter(
                (point) => (point as Office).title === office.title
            );
            this.selectedMarker = office;
        },
    },
};
</script>
