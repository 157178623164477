<template>
    <div>
        <div class="relative">
            <table class="absolute left-0 top-0 w-full h-full table-fixed pointer-events-none -z-1">
                <thead class="text-para-xs uppercase text-black-600 border-b border-black-900">
                    <tr>
                        <th scope="col" class="w-24 md:w-40 p-0 pb-2">&#8205;</th>
                        <th :colspan="colspan" scope="col" class="p-0" />
                        <th scope="col" class="p-0 md:w-20" />
                    </tr>
                </thead>
                <tbody class="text-para-s md:text-para-base">
                    <tr>
                        <td class="p-0 h-2 md:h-4" />
                        <td v-for="num in 11" :key="num" class="border-l border-black-50 p-0"
                            :class="{'hidden md:table-cell': num % 2 === 0}"
                        />
                    </tr>
                    <tr v-for="(item, index) in data" :key="index">
                        <td class="p-0 pb-2 opacity-0 min-h-10">{{ item.label }}</td>
                        <td v-for="num in 11" :key="num" class="border-l border-black-50 p-0"
                            :class="{'hidden md:table-cell': num % 2 === 0}"
                        />
                    </tr>
                </tbody>
            </table>

            <table class="w-full table-fixed">
                <thead class="text-para-xs uppercase text-black-600 border-b border-black-900">
                    <tr>
                        <th scope="col" class="font-semibold p-0 pb-2 w-24 md:w-40" />
                        <th v-for="num in 10" :key="num" scope="col" class="font-semibold p-0 pb-2 text-left"
                            :class="{'hidden md:table-cell': num % 2 === 0}"
                        >
                            {{ (num - 1) * interval }}
                        </th>
                        <th scope="col" class="font-semibold p-0 pb-2 text-right md:w-20">
                            <div class="flex justify-between">
                                <span>{{ maxValue }}</span>
                                <span class="hidden md:inline">PPB</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="text-para-s md:text-para-base">
                    <tr>
                        <td class="h-2 md:h-4" />
                    </tr>
                    <tr v-for="(item, index) in data" :key="index">
                        <td class="p-0 pb-2">{{ item.label }}</td>
                        <td :colspan="colspan" class="p-0 pb-2">
                            <div class="relative h-4 bg-primary-500 group" :style="calcStyles(item.value5)">
                                <span
                                    class="absolute left-full -top-1 text-para-xs tracking-normal bg-primary-50 rounded-xl whitespace-nowrap hidden group-hover:inline px-3 py-1 ml-2 z-1"
                                >
                                    {{ item.value5.toFixed(2) }} {{ labels.min5Value }}
                                </span>
                            </div>
                            <div class="relative h-4 bg-black-200 group" :style="calcStyles(item.value60)">
                                <span
                                    class="absolute left-full -top-1 text-para-xs tracking-normal bg-black-50 rounded-xl whitespace-nowrap hidden group-hover:inline px-3 py-1 ml-2 z-1"
                                >
                                    {{ item.value60.toFixed(2) }} {{ labels.hourlyValue }}
                                </span>
                            </div>
                        </td>
                        <td class="text-para-xs text-right p-0 pb-2">
                            <div>{{ item.value5.toFixed(2) }}</div>
                            <div>{{ item.value60.toFixed(2) }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="flex flex-col md:flex-row md:justify-between md:items-center mt-2 md:mt-4">
            <div class="flex flex-col md:flex-row md:justify-end text-para-xs xl:ml-40">
                <span class="mr-1">{{ labels.dataCollected }}:</span>
                <span>
                    <span>{{ labels.min5Time }}: </span>
                    <span class="font-semibold">{{ _fiveMinTime }} - </span>
                    <span>{{ labels.hourlyTime }}: </span>
                    <span class="font-semibold">{{ _hourlyTime }}</span>
                </span>
            </div>
            <div class="flex gap-x-4 mt-4 md:mt-0">
                <div class="flex">
                    <div class="w-1 h-6 bg-primary-500 md:h-full mr-2" />
                    <div class="text-para-xs font-semibold bg-primary-50 rounded-xl px-3 py-1">
                        {{ labels.min5Value }}
                    </div>
                </div>
                <div class="flex">
                    <div class="w-1 h-6 bg-black-200 md:h-full mr-2" />
                    <div class="text-para-xs font-semibold bg-black-50 rounded-xl px-3 py-1">
                        {{ labels.hourlyValue }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import {DataPoint, MonitoringLabels} from './MonitoringGraph.vue';
import breakpoints from '../../plugins/breakpoints';

export default {
    props: {
        labels: {type: Object as PropType<MonitoringLabels>, required: true},
        data: {type: Array as PropType<Array<DataPoint>>, required: true},
        maxValue: {type: Number, default: 1000}
    },
    computed: {
        _fiveMinTime(): string {
            if (this.data.length > 0) {
                return this.formatDate(this.data[0].value5Time);
            }
            return '-';
        },
        _hourlyTime(): string {
            if (this.data.length > 0) {
                return this.formatDate(this.data[0].value60Time);
            }
            return '-';
        },
        colspan(): number {
            return breakpoints.mdAndUp ? 10 : 5;
        },
        interval(): number {
            return Math.floor(this.maxValue / 10);
        }
    },
    methods: {
        formatDate(dateStr: string): string {
            return dateStr.replace(/.*\s(\d{1,2}:\d{2}).*/, (a, b) => b);
        },
        calcStyles(value: number): Record<string, unknown> {
            const percentage = Math.min((Math.max(value, 0) / this.maxValue) * 100, 100);
            return {
                width: `${percentage}%`
            };
        }
    }
};
</script>
