import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "absolute top-1/2 transform -translate-y-1/2 left-0 right-0 flex flex-col md:flex-row border border-primary-500 bg-white rounded shadow-lg z-10" }
const _hoisted_2 = { class: "w-full md:w-5/12" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "relative w-full md:w-7/12 py-16 px-12" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["href", "target"]
const _hoisted_7 = { class: "max-w-full overflow-hidden block break-words" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ArrowButton = _resolveComponent("ArrowButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "w-full h-full object-cover",
        src: $props.modal.image,
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", {
        class: "absolute top-4 right-4 flex justify-center items-center w-8 h-8 rounded-full border border-primary-500 text-primary-500 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.close && $options.close(...args)))
      }, [
        _createVNode(_component_Icon, {
          name: "close",
          class: "w-4 h-4 stroke-current"
        })
      ]),
      _createElementVNode("div", {
        class: "prose md:prose-md xl:prose-xl",
        innerHTML: $props.modal.text
      }, null, 8, _hoisted_5),
      ($props.modal.link.url)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "inline-flex items-center text-para-xs font-semibold group max-w-full mt-4",
            href: $props.modal.link.url,
            target: $props.modal.link.target
          }, [
            _createElementVNode("span", _hoisted_7, _toDisplayString($props.modal.link.label), 1),
            _createVNode(_component_ArrowButton, { class: "w-10 h-10" })
          ], 8, _hoisted_6))
        : _createCommentVNode("", true)
    ])
  ]))
}