<template>
    <div>
        <div class="container py-6 md:py-8">
            <h2 v-if="showLabel" class="text-heading-3 md:text-heading-2 mb-4">
                {{ labelTabSection }}
            </h2>

            <div class="md:-mx-0.5">
                <ul v-if="showTiles"
                    v-masonry
                    transition-duration="0"
                >
                    <li v-for="(item, index) in data"
                        :key="index"
                        v-masonry-tile
                        class="w-full md:w-4/12 md:px-0.5"
                        :class="{'xl:w-3/12': !inContentGroup}"
                    >
                        <component :is="item.type" :data="item" />
                    </li>
                </ul>
                <div v-else class="-mx-4 md:-mx-10 xl:-mx-20">
                    <GcSwiper
                        :slide-classes="inContentGroup ? 'flex w-full md:w-4/12' : 'flex w-full md:w-4/12 xl:w-3/12'"
                        container-classes="px-4 md:px-10 xl:px-20"
                        :gap="4"
                        direction="horizontal"
                    >
                        <component :is="item.type" v-for="(item, index) in data" :key="index" :data="item" />

                        <template v-if="showPagination" #pagination="{ index, total, teaser, go }">
                            <ul class="flex flex-row justify-center space-x-3 text-black mt-9 space-x-4">
                                <li v-for="(dot, i) in total"
                                    :key="i"
                                    class="relative w-2 h-2 rounded cursor-pointer"
                                    :class="[index === (i + 1) ? 'bg-primary-500 after:content after:w-4 after:h-4 after:rounded-full after:absolute after:-top-1 after:-left-1 after:border after:border-primary-200' : 'bg-black-200']"
                                    @click="go(i)"
                                />
                            </ul>
                        </template>
                    </GcSwiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {PropType} from 'vue';
import debounce from 'lodash/debounce';
import breakpoints from '../../plugins/breakpoints';
import GcSwiper from "../base/GcSwiper.vue";
import Youtube from "./items/Youtube.vue";
import Facebook from "./items/Facebook.vue";
import Instagram from "./items/Instagram.vue";
import Rss from "./items/Rss.vue";
import Twitter from "./items/Twitter.vue";

export default {
    components: {GcSwiper, Facebook, Instagram, Rss, Twitter , Youtube},
    inject: ['isVertical'],
    props: {
        data: {type: Array as PropType<Array<Record<string, string>>>, default: () => []},
        showLabel: {type: Boolean, default: false},
        inContentGroup: {type: Boolean, default: false},
        visible: {type: Boolean, default: true},
        labelTabSection: {type: String},
        layout: {type: String}
    },
    data() {
        return {
            redraw: debounce(this.$redrawVueMasonry, 20)
        };
    },
    computed: {
        showTiles(): boolean {
            return this.isVertical || breakpoints.mdAndUp;
        },
        showPagination(): boolean {
            return !this.isVertical && breakpoints.mobile;
        }
    },
    watch: {
        async visible(newVal: boolean) {
            if (newVal) {
                this.redraw();
            }
        }
    },
    updated(): void {
        this.redraw();
    }
};
</script>
