<template>
    <div class="flex flex-col items-center">
        <span class="text-para-xs md:text-para-base font-semibold mb-2">{{ label }}</span>
        <div class="relative w-24 h-24 md:w-32 md:h-32">
            <svg viewBox="0 0 100 100" class="transform -rotate-90">
                <circle stroke-width="2" fill="none" cx="50" cy="50" stroke="#8FDCDA" :r="r" />
                <circle stroke-width="2" fill="none" cx="50" cy="50" stroke="#00928E" :r="r" :style="circleStyle" />
            </svg>
            <div
                class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center"
            >
                <Icon name="wind-arrow" class="w-8 h-8 md:w-10 md:h-10"
                      :style="{transform: `rotate(${direction.toFixed(2)}deg)`}"
                />
                <span class="text-para-xs">{{ direction }}°</span>
            </div>
        </div>
        <span class="text-para-xs md:text-para-s my-2">
            {{ speed.toFixed(0) }}km/h
        </span>
    </div>
</template>

<script lang="ts">

import Icon from "../base/Icon.vue";

const RADIUS = 49;
const CIRCUMFERENCE: number = 2 * Math.PI * RADIUS;

export default {
    components: {Icon},
    props: {
        label: {type: String, required: true},
        speed: {type: Number, required: true},
        direction: {type: Number, required: true},
        max: {type: Number, default: 100}
    },
    computed: {
        r(): number {
            return RADIUS;
        },
        circleStyle(): Record<string, unknown> {
            return {
                strokeDasharray: CIRCUMFERENCE,
                strokeDashoffset: CIRCUMFERENCE * (1 - (Math.min(this.speed, this.max) / this.max)),
                strokeLinecap: 'round'
            };
        }
    }
};
</script>
