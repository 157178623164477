import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollDown = _resolveComponent("ScrollDown")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bg-fixed bg-center bg-no-repeat bg-cover relative",
    style: _normalizeStyle({ backgroundImage: $options.imageUrl })
  }, [
    _renderSlot(_ctx.$slots, "default"),
    ($props.scrollToAnchor)
      ? (_openBlock(), _createBlock(_component_ScrollDown, {
          key: 0,
          "to-anchor": $props.scrollToAnchor
        }, null, 8, ["to-anchor"]))
      : _createCommentVNode("", true)
  ], 4))
}